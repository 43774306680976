/**
 * @file
 *
 * This file is for display components that are shared between {@link ListBox}
 * and {@link Menu}. Because those two components are rendered similarly, but
 * their ARIA properties and behavior, and states are different, we encapsulate
 * the style-setting for `<ul>` and `<li>` elements in these components.
 */

import type { Collection, Node as CollectionNode } from '@react-types/shared';
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual';
import cx from 'classnames';
import React from 'react';
import { useSeparator } from 'react-aria';

import { assertUnreachable } from '../../utils/types';

/**
 * "menu" shows the selected element(s) with a solid background.
 *
 * "control" uses either a checkbox or radio button, depending on whether this
 * list is multi-select or not.
 *
 * "checkmark" uses checkmarks.
 */
export type ListSelectionStyle = 'menu' | 'control' | 'checkmark' | 'none';

/**
 * Component to render a list. Used as a base for {@link ListBox} and
 * {@link Menu}. Has vertical scrolling, flex-1 to grow to full size, and
 * relative positioning so that the contents can be virtualized.
 *
 * Extra props are applied to the `<ul>` (which is also the target of the
 * `ref`).
 *
 * @see useListBox
 * @see useMenu
 */
export const ListView = React.forwardRef<
  HTMLUListElement,
  {
    selectionStyle?: ListSelectionStyle | undefined;
  } & React.HTMLAttributes<HTMLUListElement>
>(({ children, selectionStyle, className, ...domProps }, ref) => {
  return (
    <ul
      ref={ref}
      className={cx(
        'relative flex-1 overflow-y-auto border bg-white',
        className,
        { 'py-2': selectionStyle === 'checkmark' }
      )}
      {...domProps}
    >
      {children}
    </ul>
  );
});

ListView.displayName = 'ListView';

/**
 * Component to render an item within a {@link ListView}.
 *
 * Factored out to work with both {@link useListItem} and {@link useMenuItem}.
 *
 * Note that there is separate keyboard / hover behavior between list items and
 * menu items. In menus, the hover state and keyboard focus state is the same:
 * pressing down arrow moves the highlight the same way that moving the mouse
 * does.
 *
 * For list items, keyboard focus is instead shown with a focus ring, separate
 * from any mouse hovering effects.
 *
 * All extra props are applied to the `<li>`. Any `className` will override all
 * default styles (so probably don’t do that?).
 *
 * The `<li>` is associated with the passed-in ref.
 */
export const ListItemView = React.forwardRef<
  HTMLLIElement,
  {
    /** Element is currently selected/checked. */
    isSelected: boolean;
    /** Element is not selectable. */
    isDisabled: boolean;
    /**
     * Element is being hovered by the mouse, or has been moved to via the
     * keyboard on a menu. If left `undefined`, will show hover state based on
     * the CSS `hover` pseudo-class.
     */
    isHovered?: boolean;
    /**
     * Element has keyboard focus but isn’t “hovered.”
     */
    showFocusRing?: boolean;
    /**
     * How to render selection and hover states.
     */
    selectionStyle: ListSelectionStyle;
  } & React.HTMLAttributes<HTMLLIElement>
>(
  (
    {
      isSelected,
      isDisabled,
      isHovered,
      showFocusRing,
      selectionStyle,
      children,
      ...domProps
    },
    ref
  ) => {
    const disabledTextClass = 'text-gray';
    const enabledTextClass = 'text-black';

    const hoverClasses = 'bg-hover text-black';
    const cssHoverClasses = 'hover:bg-hover hover:text-black';
    const focusRingClassses = cx({
      'outline outline-2 outline-dotted -outline-offset-2 outline-accent':
        showFocusRing,
      'outline-none': !showFocusRing,
    });

    let commonClasses = 'text-base cursor-default ' + focusRingClassses;

    if (!isDisabled) {
      // Only hover if the element isn’t disabled.

      if (isHovered === undefined) {
        // If we’re not setting `isHovered` explicitly, use the browser/CSS
        // hover behavior. This is for cases like ListBox where hover and focus
        // are tracked separately. Menu ties them together.
        commonClasses += ' ' + cssHoverClasses;
      } else if (isHovered) {
        commonClasses += ' ' + hoverClasses;
      }
    }

    switch (selectionStyle) {
      // “none” has the same layout as “menu” but doesn’t ever show selection
      // indicators, so we can render them with the same case statement.
      case 'menu':
      case 'none':
        return (
          <li
            ref={ref}
            className={`${commonClasses} ${
              isDisabled
                ? disabledTextClass
                : isSelected && selectionStyle === 'menu'
                ? 'bg-hyperlink text-white'
                : enabledTextClass
            }  px-4 py-2`}
            {...domProps}
          >
            {children}
          </li>
        );

      case 'checkmark':
        return (
          <li
            ref={ref}
            className={`${commonClasses} ${
              isDisabled ? disabledTextClass : enabledTextClass
            } flex items-center py-2 pr-4`}
            {...domProps}
          >
            <div
              className="w-6 text-center"
              // Hidden because the `<li>` will already be described as
              // aria-selected/aria-checked.
              aria-hidden
            >
              {isSelected && '✓'}
            </div>
            <div className="flex-1">{children}</div>
          </li>
        );

      case 'control':
        return (
          <li
            ref={ref}
            className={`${commonClasses} ${
              isDisabled ? disabledTextClass : enabledTextClass
            } flex items-center py-2 pr-4`}
            {...domProps}
          >
            <div className="flex w-10 justify-around">
              {/* Makes a little checkbox w/ CSS */}
              {/* TODO(fiona): support a radio button for single-select */}
              <div
                // Hidden because the `<li>` will already be described as
                // aria-selected/aria-checked.
                aria-hidden
                className={`h-5 w-5 border border-gray px-1 py-0.5 text-center leading-none ${
                  isSelected ? 'bg-accent text-white' : ''
                }`}
              >
                {isSelected && '✓'}
              </div>
            </div>
            <div className="flex-1">{children}</div>
          </li>
        );

      default:
        assertUnreachable(selectionStyle);
    }
  }
);

ListItemView.displayName = 'ListItemView';

/**
 * Renders a group in a {@link ListView}, including a title and separator (if
 * the section is not the first).
 *
 * Will render its `children` in a nested `<ul>`.
 *
 * The collections of props for this element come from {@link useListBoxSection}
 * and {@link useMenuSection}.
 *
 * Unlike {@link ListView} and {@link ListItemView}, does not take arbitrary DOM
 * props or a ref because it is generating complex markup (rather than being a
 * simple wrapper around `<ul>` and `<li>`, respectively).
 */
export const ListSectionView: React.FunctionComponent<{
  /**
   * If this element is the first in the menu/box/whatever.
   *
   * Typically determined by comparing this item’s key with
   * `state.collection.getFirstKey()`.
   */
  isFirst: boolean;
  /**
   * Currently we don’t have a great way to position the group separator when
   * the list is virtualized, this option lets us just not render it.
   */
  hideSeparator?: boolean | undefined;
  /** Contents for the text heading. */
  heading: React.ReactNode;
  /**
   * Props for the heading itself. Typically an `id` so it can be referenced by
   * `groupProps`, and `aria-hidden` so that it only comes up describing the
   * group.
   */
  headingProps: React.HTMLAttributes<HTMLSpanElement>;
  /**
   * Props for the `<li>` that will contain the heading and nested list.
   *
   * Typically an `role="presentation"` so that it’s not treated like any
   * other non-section list items.
   *
   * May also contain virtualized item positioning styles.
   */
  itemProps: React.HTMLAttributes<HTMLLIElement>;
  itemRef?: React.Ref<HTMLLIElement> | undefined;
  /**
   * Props for the nested `<ul>` list of children.
   *
   * Typically `aria-labelledby` referencing the heading’s ID, along with
   * `role="group"`.
   */
  groupProps: React.HTMLAttributes<HTMLUListElement>;
}> = ({
  isFirst,
  hideSeparator,
  itemProps,
  itemRef,
  headingProps,
  groupProps,
  heading: title,
  children,
}) => {
  // Gets us `role="separator"`
  const { separatorProps } = useSeparator({ elementType: 'li' });

  return (
    <>
      {/* Add separator above if we’re not the first section */}
      {!isFirst && !hideSeparator && (
        <li
          {...separatorProps}
          className={cx(
            'mx-2 h-0 border-b border-b-gray',
            // If there’s no title, don’t add a margin bottom so we keep even
            // space between the items.
            { 'mb-2': !!title }
          )}
        />
      )}

      <li {...itemProps} ref={itemRef}>
        {title && (
          <span
            {...headingProps}
            // TODO(fiona): Share this with h4 tags?
            className="mt-2 block px-2 text-xs font-bold uppercase"
          >
            {title}
          </span>
        )}

        <ul {...groupProps}>{children}</ul>
      </li>
    </>
  );
};

export type VirtualizedItemProps = Pick<
  React.HTMLAttributes<HTMLElement>,
  'style'
> & {
  /**
   * The useVirtualizer measurement code looks for this data attribute to know
   * which element is being measured.
   */
  'data-index': number;
};

/**
 * Helper wrapper around the `Collection` that’s used by react-stately
 * components that adds `@tanstack/react-virtual` around it.
 *
 * Calls the renderItem method with appropriate values for positioning.
 */
export function VirtualizeCollection<T extends {}>(props: {
  /**
   * Ref for the element with `overflow: scroll` on it.
   */
  scrollingRef: React.RefObject<HTMLElement>;
  virtualizerRef?: React.MutableRefObject<Virtualizer<
    HTMLElement,
    Element
  > | null>;
  collection: Collection<CollectionNode<T>>;
  renderItem: (
    item: CollectionNode<T>,
    props: VirtualizedItemProps,
    measureElement: (el: HTMLElement | null) => void
  ) => React.ReactNode;
  itemHeight?: number | undefined;
  sectionHeight?: number | undefined;
}) {
  const {
    collection,
    scrollingRef,
    renderItem,
    itemHeight = 38,
    sectionHeight = 26,
    virtualizerRef,
  } = props;

  const listVirtualizer = useVirtualizer({
    count: collection.size,
    overscan: 15,
    getScrollElement: () => scrollingRef.current,
    estimateSize: (index) => {
      const item = collection.at(index)!;
      switch (item.type) {
        case 'section':
          return sectionHeight;
        case 'item':
          return itemHeight;
        default:
          return 0;
      }
    },
    getItemKey: (index) => collection.at(index)!.key,
  });

  React.useLayoutEffect(() => {
    if (virtualizerRef) {
      virtualizerRef.current = listVirtualizer;
    }

    return () => {
      if (virtualizerRef) {
        virtualizerRef.current = null;
      }
    };
  }, [virtualizerRef, listVirtualizer]);

  // HACK(fiona): Because of how ListBox nests its rendering of
  // `<VirtualizeCollection>` and `<ListView>`, when `useVirtualizer` calls
  // `getScrollElement` for the first time, the ref hasn’t been populated yet
  // and therefore nothing renders. This call to `measure` after first mount
  // gets the virtualizer to see the element.
  React.useEffect(() => {
    listVirtualizer.measure();
  }, [listVirtualizer]);

  return (
    <>
      {listVirtualizer.getVirtualItems().map((virtualItem) =>
        renderItem(
          collection.at(virtualItem.index)!,
          {
            // needed for measureElement
            'data-index': virtualItem.index,
            style: {
              position: 'absolute',
              transform: `translateY(${virtualItem.start}px)`,
              width: '100%',
            },
          },
          listVirtualizer.measureElement
        )
      )}

      {/* 
        We add a list item to take up the space in the parent <ul> since all of our items are positioned absolutely.
      */}
      <li
        aria-hidden
        style={{
          height: listVirtualizer.getTotalSize(),
          padding: 0,
          margin: 0,
        }}
      />
    </>
  );
}
