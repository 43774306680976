import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class AssignmentDateSelectionDisplay extends Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    dateType: PropTypes.oneOf(['early_vote', 'eday']),
    onEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.label = this.label.bind(this);
  }

  label() {
    const { dateType } = this.props;
    if (dateType === 'early_vote') return 'Early Vote Date';
    if (dateType === 'eday') return 'Election Date';
  }

  render() {
    const { date, onEdit } = this.props;

    return (
      <div className="assignment-date-selection">
        <div className="assignment-date-selection__label">{this.label()}</div>
        <div>
          <span className="assignment-date-selection__display">
            {moment(date, 'YYYY-MM-DD').format('dddd MMMM D, YYYY')}
          </span>
          <button
            className="assignment-date-selection__edit link-button"
            onClick={() => onEdit()}
          >
            Change
          </button>
        </div>
      </div>
    );
  }
}
