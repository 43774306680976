import _ from 'lodash';

import {
  IncidentIssueCategories,
  InquiryIssueCategories,
  State,
} from '../constants/';

import ApiClient from './api-client';
import {
  CountedResponse,
  CountySlug,
  DateString,
  Iso8601String,
  OffsetResponse,
} from './common';

const defaultDashParams = {
  size: 10,
};

export type ApiTicketSummaryGroupBy =
  | 'state'
  | 'county'
  | 'precinct'
  | 'location'
  // These last two are used in the Analytics view, not the Dashboard view.
  | 'category'
  | 'region';

export type ApiLineLengthCountsGroupBy = 'state' | 'county';

/**
 * Type for a summary of issues.
 *
 * The categories are used as keys that indicate how many issues have that
 * category type.
 */
export type ApiTicketSummaryResult = {
  [K in IncidentIssueCategories | InquiryIssueCategories]?: number;
} & {
  Name: string;
  id: string;
  Total: number;
};

export type ApiLineLengthWaitTime =
  | '3+ Hours'
  | '2 Hours'
  | '1 Hour'
  | '30 Minutes'
  | 'No Line'
  | 'Voting has completed';

export type ApiLineLengthRecord = {
  time: Iso8601String;
  wait_time: ApiLineLengthWaitTime;
};

/**
 * Values for line length counts.
 *
 * Will include County or State depending on the `group_by` parameter it’s
 * called with.
 */
export type ApiLineLengthCounts = {
  id: string;
  County?: string;
  State?: State;
  Total: number;

  '3+ Hours'?: number;
  '2 Hours'?: number;
  '1 Hour'?: number;
  '30 Minutes'?: number;
  'No Line'?: number;
  'Voting has completed'?: number;
};

/**
 * Values for hour-by-hour line length. The API returns one of these for each
 * location within a county that has checkin records.
 */
export type ApiLineLengthByHour = {
  id: number;
  county_slug: string;
  last_checkin: ApiLineLengthRecord;
  /**
   * This can be null if we don’t have any wait time–specific checkins for
   * the location.
   */
  longest_line: ApiLineLengthRecord | null;
  /** Name of the location. */
  Location: string;
  City: string;
  County: string;
  State: string;

  '5 AM - 6 AM'?: ApiLineLengthWaitTime;
  '6 AM - 7 AM'?: ApiLineLengthWaitTime;
  '7 AM - 8 AM'?: ApiLineLengthWaitTime;
  '8 AM - 9 AM'?: ApiLineLengthWaitTime;
  '9 AM - 10 AM'?: ApiLineLengthWaitTime;
  '10 AM - 11 AM'?: ApiLineLengthWaitTime;
  '11 AM - 12 PM'?: ApiLineLengthWaitTime;
  '12 PM - 1 PM'?: ApiLineLengthWaitTime;
  '1 PM - 2 PM'?: ApiLineLengthWaitTime;
  '2 PM - 3 PM'?: ApiLineLengthWaitTime;
  '3 PM - 4 PM'?: ApiLineLengthWaitTime;
  '4 PM - 5 PM'?: ApiLineLengthWaitTime;
  '5 PM - 6 PM'?: ApiLineLengthWaitTime;
  '6 PM - 7 PM'?: ApiLineLengthWaitTime;
  '7 PM - 8 PM'?: ApiLineLengthWaitTime;
  '8 PM - 9 PM'?: ApiLineLengthWaitTime;
  '9 PM - 10 PM'?: ApiLineLengthWaitTime;
  '10 PM - 11 PM'?: ApiLineLengthWaitTime;
  '11 PM - 12 AM'?: ApiLineLengthWaitTime;
  '12 AM - 1 AM'?: ApiLineLengthWaitTime;
  '1 AM - 2 AM'?: ApiLineLengthWaitTime;
  '2 AM - 3 AM'?: ApiLineLengthWaitTime;
  '3 AM - 4 AM'?: ApiLineLengthWaitTime;
  '4 AM - 5 AM'?: ApiLineLengthWaitTime;
};

export function getSummaryList(query: {
  group_by: ApiTicketSummaryGroupBy;
  ordering?: string;
  size: number;
  offset?: number;
  query_election_id?: number;
  state?: State;
  county?: CountySlug;
  precinct?: number;
  from_permanent?: 'True' | 'False';
  requires_followup?: 'True' | 'False';
  date_gte?: DateString;
  date_lte?: DateString;
}) {
  const params = _.assign({}, defaultDashParams, query);
  return ApiClient<CountedResponse<ApiTicketSummaryResult>>(
    'ticket_reports/',
    'GET',
    params
  );
}

export function getLineLengthCount(query: {
  group_by: ApiLineLengthCountsGroupBy;
  ordering?: string;
  state?: State;
  county?: CountySlug;

  checkin_time__gte: Iso8601String;
  checkin_time__lt: Iso8601String;

  size: number;
  offset: number;
}) {
  const params = _.assign({}, defaultDashParams, query);
  return ApiClient<OffsetResponse<'line_lengths', ApiLineLengthCounts>>(
    'line_length_count/',
    'GET',
    params
  );
}

export function getLineLengthByHour(query: {
  ordering?: string;
  state?: State;
  county?: CountySlug;

  tz: string;
  checkin_time__gte: Iso8601String;
  checkin_time__lt: Iso8601String;

  size: number;
  offset: number;
}) {
  const params = _.assign({}, defaultDashParams, query);
  return ApiClient<
    OffsetResponse<'length_by_hour', ApiLineLengthByHour> & {
      hour_headers: (keyof ApiLineLengthByHour)[];
    }
  >('line_length_by_hour/', 'GET', params);
}

export default {
  getSummaryList,
  getLineLengthCount,
  getLineLengthByHour,
};
