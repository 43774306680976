import { Query } from 'history';
import keyMirror from 'key-mirror';
import _ from 'lodash';
import moment from 'moment';

import {
  FILTERS_EXISTING,
  userViewTypes,
  State,
  UserViewType,
  UserRole,
} from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import fluxStore from '../../modules/flux-store';

import mapStateToUserFilters from './map-state-to-user-filters';

const { ASSIGNMENT_NOTIFY } = userViewTypes;

const USER_INDEX_DEFAULTS = {
  view: ASSIGNMENT_NOTIFY,
} as const;

const USER_INDEX_VIEW_PARAMS = {
  ASSIGNMENT_NOTIFY: 'assignments',
} as const;

export const USER_DETAIL_VIEW_PARAMS = keyMirror({
  assignments: null,
  details: null,
});

function _getUserDefaults(): Pick<
  FiltersBySection['USER'],
  'assignment_state' | 'dates'
> {
  const { getState } = fluxStore;
  const currentUser = getState().user.currentUser.userData;

  return {
    assignment_state:
      (currentUser?.get('assignment_state') as State | null | undefined) ??
      undefined,
    dates: moment().format('YYYY-MM-DD'),
  };
}

function _getActiveView(query: Query) {
  const { view } = query;

  switch (view) {
    case USER_INDEX_VIEW_PARAMS.ASSIGNMENT_NOTIFY:
    default:
      return ASSIGNMENT_NOTIFY;
  }
}

export const USER_DEFAULT_FILTERS = keyMirror({
  assignment_state: null,
});

export function mapQueryParamsToUserFilters(
  query: Query
): FiltersBySection['USER'] {
  const view = _getActiveView(query);

  if (query['filters'] === FILTERS_EXISTING) {
    const fromState = mapStateToUserFilters(fluxStore.getState());

    const syncedFilters = {
      assignment_state: fromState.get('assignment_state') as State | undefined,
      tags: fromState.get('tags'),
      role: fromState.get('role') as UserRole | undefined,
    };

    const fromQuery = _.omit(query, ['filters']);

    return {
      ...syncedFilters,
      ...fromQuery,
      ...{ view },
    };
  }

  const userDefaults = _.omit(_getUserDefaults(), ['dates']);

  return {
    ...userDefaults,
    // TODO(fiona): This deserves to be validated
    ...query,
    ...{ view },
  };
}

export function mapQueryParamsToUserDetailFilters(
  query: Query
): FiltersBySection['USER'] {
  const userDefaults = _getUserDefaults();

  return {
    ...{ view: USER_DETAIL_VIEW_PARAMS.details },
    ...userDefaults,
    // TODO(fiona): validate
    ...query,
  };
}

export function mapUserFiltersToQueryParams(filters: FiltersBySection['USER']) {
  const omit: Array<keyof FiltersBySection['USER']> = [];

  // as since Object.keys is string[]
  (
    Object.keys(USER_INDEX_DEFAULTS) as Array<keyof typeof USER_INDEX_DEFAULTS>
  ).forEach((k) => {
    if (filters[k] === USER_INDEX_DEFAULTS[k]) {
      omit.push(k);
    }
  });
  const query: Query = _.omit(filters, omit);

  if (query['view']) {
    // Need to convert from uppercase to nicer lowercase version for the URL.
    query['view'] = USER_INDEX_VIEW_PARAMS[query['view'] as UserViewType];
  }

  return query;
}
