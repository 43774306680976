import type { DOMAttributes } from '@react-types/shared';
import React from 'react';
import { mergeProps } from 'react-aria';

/**
 * Common component to display red text with an error icon to its left.
 */
export const FormError: React.FunctionComponent<DOMAttributes> = ({
  children,
  ...props
}) => (
  <div
    {...mergeProps(props, {
      className: 'items-top flex gap-1 text-sm text-red-700',
    })}
  >
    <span className="material-icons text-base leading-none">error</span>
    {children}
  </div>
);
