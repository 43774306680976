import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { userViewTypes, lbjAppSections } from '../../../constants';
import RIT from '../../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';

const { ASSIGNMENT_NOTIFY } = userViewTypes;
const { USER } = lbjAppSections;

@connect((state) => {
  const { user, filters } = state;
  const assignmentNotificationBadgeCount = user
    .getIn(['allUserIds', ASSIGNMENT_NOTIFY, 'userIds'])
    .count();

  return {
    assignmentNotificationBadgeCount,
    userListViewType: filters.getIn([USER, 'view']),
  };
})
@withRouting
export default class UserViewFilters extends Component {
  static propTypes = {
    assignmentNotificationBadgeCount: PropTypes.number.isRequired,
    userListViewType: PropTypes.oneOf([ASSIGNMENT_NOTIFY]),
    ...WithRoutingPropTypes,
  };

  getQueryParams(query, view) {
    return _.omit(_.assign({}, query, { view }), ['size', 'offset']);
  }

  render() {
    const { assignmentNotificationBadgeCount, location, userListViewType } =
      this.props;
    const { pathname, search } = location;
    const query = searchToQuery(search);

    return (
      <ul className="lbj-view-filters">
        <li>
          <Link
            to={{
              pathname,
              search: queryToSearch(this.getQueryParams(query, 'assignments')),
            }}
            className={cx({
              'is-active': userListViewType === ASSIGNMENT_NOTIFY,
            })}
          >
            All Users
            {RIT(assignmentNotificationBadgeCount > 0, () => {
              return (
                <span className="email-notification-badge">
                  {assignmentNotificationBadgeCount}
                </span>
              );
            })}
          </Link>
        </li>
      </ul>
    );
  }
}
