import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class HiddenInput extends Component {
  static propTypes = {
    ctaText: PropTypes.node,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { ctaText, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div>
        {RIT(isOpen, () => children)}
        {RIT(!isOpen, () => {
          return (
            <div className="lbj-hidden-input">
              <a
                href="#"
                className="lbj-search-magnifier-link a-float-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isOpen: true });
                }}
              />

              <span>{ctaText}</span>
            </div>
          );
        })}
      </div>
    );
  }
}
