import keyMirror from 'key-mirror';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { issueCategories, lbjAppSections } from '../../../constants';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import mapStateToAnalyticsFilters from '../../../utils/analytics/map-state-to-analytics-filters';
import { ANALYTICS_VIEW_PARAMS } from '../../../utils/analytics/query-params';
import RIT from '../../../utils/render-if-truthy';
import Autocomplete from '../../presentational/form/autocomplete';
import Datetime from '../../presentational/form/datetime';
import Select from '../../presentational/form/select';
import Sidebar from '../../presentational/lbj/sidebar';
import CountyFilterContainer from '../form/county-filter-container';
import PrecinctFilterContainer from '../form/precinct-filter-container';
import StateFilterContainer from '../form/state-filter-container';

const { ANALYTICS } = lbjAppSections;

@connect((state) => {
  const electionList = state.lbj.getIn(['elections', 'listData']);
  const isFetchingElections = state.lbj.getIn([
    'elections',
    'requestIsPending',
  ]);

  return {
    electionList,
    filters: mapStateToAnalyticsFilters(state),
    isFetchingElections,
  };
})
export default class AnalyticsFilterSidebar extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    electionList: PropTypes.object,
    filters: PropTypes.object.isRequired,
    isFetchingElections: PropTypes.bool.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onMultiFilterChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onDateChange = this.onDateChange.bind(this);
    this.onElectionChange = this.onElectionChange.bind(this);
    this.onViewByCategory = this.onViewByCategory.bind(this);
  }

  onDateChange({ error, target }) {
    const { onFilterChange } = this.props;
    const { name, value } = target;

    const date = value && !error ? moment(value).format('YYYY-MM-DD') : null;

    onFilterChange({
      target: {
        name: name,
        value: date,
      },
    });
  }

  onViewByCategory() {
    const { onMultiFilterChange } = this.props;
    onMultiFilterChange([
      {
        name: 'category',
        value: '',
      },
      { name: 'group_by', value: 'category' },
    ]);
  }

  onElectionChange({ target }) {
    const { dispatch, filters, onFilterChange } = this.props;
    const { name, value } = target;
    const { getPrecinctListAsync } = lbjActionCreators;
    if (value) {
      if (filters.get('county')) {
        dispatch(
          getPrecinctListAsync({
            county: filters.get('county'),
            query_election_id: value,
          })
        );
      }
    }
    onFilterChange({
      target: {
        name,
        value,
      },
    });
  }

  getElectionChoices() {
    const { electionList } = this.props;

    return !electionList.isEmpty()
      ? electionList.reduce((choices, election) => {
          choices[election.get('id')] = election.get('name');
          return choices;
        }, {})
      : {};
  }

  renderViewByFilter() {
    const { filters } = this.props;
    const { onFilterChange } = this.props;

    return (
      <Select
        name="column"
        title="View By"
        value={filters.get('column')}
        choices={{
          status: 'Status',
          scope: 'Number of voters affected',
          priority: 'Priority',
        }}
        onChange={onFilterChange}
      />
    );
  }

  renderDateFilters() {
    const { filters } = this.props;

    return (
      <div>
        <Datetime
          name="date_gte"
          title="Start Date"
          type="date"
          value={filters.get('date_gte')}
          dateFormat="MMMM Do, YYYY"
          onChange={this.onDateChange}
        />
        <Datetime
          name="date_lte"
          title="End Date"
          type="date"
          value={filters.get('date_lte')}
          dateFormat="MMMM Do, YYYY"
          onChange={this.onDateChange}
        />
      </div>
    );
  }

  renderStateFilter() {
    const { onFilterChange } = this.props;

    return (
      <StateFilterContainer
        appSection={ANALYTICS}
        includeNational={false}
        onChange={onFilterChange}
      />
    );
  }

  renderCategoryFilter() {
    const categoryChoices = Object.assign(
      {},
      keyMirror(issueCategories.inquiry),
      keyMirror(issueCategories.incident)
    );
    const { filters } = this.props;
    const { onFilterChange } = this.props;

    return (
      <Autocomplete
        name="category"
        title="Category"
        value={filters.get('category')}
        choices={categoryChoices}
        multi={false}
        onChange={onFilterChange}
        clearable
      />
    );
  }

  renderCountyFilter() {
    const { onFilterChange } = this.props;

    return (
      <CountyFilterContainer appSection={ANALYTICS} onChange={onFilterChange} />
    );
  }

  renderElectionFilter() {
    const { filters, isFetchingElections } = this.props;

    return (
      <Autocomplete
        name="query_election_id"
        title="Election"
        defaultValue={filters.get('query_election_id')}
        onChange={this.onElectionChange}
        choices={this.getElectionChoices()}
        isLoading={isFetchingElections}
      />
    );
  }

  renderIssuesByCategorySidebar() {
    const { onFilterChange, filters } = this.props;
    const groupBy = filters.get('group_by');

    return (
      <Sidebar title="Filters" collapsible>
        {RIT(groupBy === 'sub_category', () => {
          return (
            <div className="lbj-column-content">
              <div className="button-container">
                <button
                  type="button"
                  className="c-button-large c-button-secondary"
                  onClick={this.onViewByCategory}
                >
                  Return to Category View
                </button>
              </div>
            </div>
          );
        })}
        <div className="lbj-column-content">
          {this.renderDateFilters()}
          {RIT(groupBy === 'sub_category', () => this.renderCategoryFilter())}
          {this.renderStateFilter()}
          {this.renderCountyFilter()}
          {this.renderElectionFilter()}
          <PrecinctFilterContainer
            appSection={ANALYTICS}
            onChange={onFilterChange}
          />
        </div>
      </Sidebar>
    );
  }

  renderIssuesByRegionSidebar() {
    return (
      <Sidebar title="Filters" collapsible>
        <div className="lbj-column-content">
          {this.renderDateFilters()}
          {this.renderStateFilter()}
          {this.renderElectionFilter()}
        </div>
      </Sidebar>
    );
  }

  renderIssueTablesSidebar() {
    return (
      <Sidebar title="Filters" collapsible>
        <div className="lbj-column-content">
          {this.renderViewByFilter()}
          {this.renderDateFilters()}
          {this.renderStateFilter()}
          {this.renderElectionFilter()}
        </div>
      </Sidebar>
    );
  }

  render() {
    const { filters } = this.props;

    if (filters.get('view') === ANALYTICS_VIEW_PARAMS.issues_by_region) {
      return this.renderIssuesByRegionSidebar();
    }

    if (filters.get('view') === ANALYTICS_VIEW_PARAMS.issue_tables) {
      return this.renderIssueTablesSidebar();
    }

    return this.renderIssuesByCategorySidebar();
  }
}
