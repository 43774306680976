import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import Datetime from 'react-datetime';

import { InputProps, inputDefaultProps } from '../../../decorators/input-props';

export default class DatetimeInput extends React.Component<
  InputProps<string | moment.Moment | Date, string> & {
    dateFormat: string | boolean;
    timeFormat: string | boolean;
  }
> {
  static defaultProps = {
    ...inputDefaultProps,
    type: 'datetime',
    dateFormat: 'MMM. Do, YYYY',
    timeFormat: 'h:mma z',
  };

  // Pass the name and value to onChange in a mock "event" object
  handleChange(selectedTime: string | moment.Moment | React.ChangeEvent) {
    const { name, onChange } = this.props;
    const argIsString = typeof selectedTime === 'string';

    if (onChange) {
      onChange({
        target: {
          name,
          value: argIsString
            ? selectedTime
            : // Not sure what causes Datetime to pass a ChangeEvent
              // but its type says that it might. We assert that it won’t.
              (selectedTime as moment.Moment).format(),
        },
        error: argIsString ? 'Invalid time format' : null,
      });
    }
  }

  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return <label htmlFor={name}>{title}</label>;
  }

  renderInput() {
    const {
      name,
      onChange,
      value,
      defaultValue,
      type,
      dateFormat,
      timeFormat,
      disabled,
      ...rest
    } = this.props;

    const dateFormatVal = type === 'time' ? false : dateFormat;
    const timeFormatVal = type === 'date' ? false : timeFormat;

    return (
      <Datetime
        onChange={this.handleChange.bind(this)}
        dateFormat={dateFormatVal}
        timeFormat={timeFormatVal}
        inputProps={{ disabled: disabled }}
        // We have to be a little goofy with these due to --exactOptionalPropertyTypes
        {...(value === undefined ? {} : { value })}
        {...(defaultValue === undefined ? {} : { defaultValue })}
        {...rest}
      />
    );
  }

  renderError(error: string, key: number) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  render() {
    const { name, errors, type, disabled } = this.props;
    const className = cx(
      'lbj-input',
      'lbj-input-datetime',
      `lbj-input-${type}`,
      `lbj-input-datetime-${name}`,
      {
        'is-disabled': disabled,
      }
    );

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
        {errors?.map((error, key) => this.renderError(error, key))}
      </div>
    );
  }
}
