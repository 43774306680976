import useSWR from 'swr';

import { State } from '../../constants';
import { CountySlug } from '../../services/common';
import * as LbjService from '../../services/lbj-shared-service';
import { pruneFilters } from '../../utils/filters/common';

/**
 * Loads counties. If regions are selected, filters the counties down to just
 * what’s in those regions.
 *
 * If `state` is null or `regions` is empty then returns [].
 */
export function useCounties(state: State | null, region?: number[]) {
  return useSWR(
    ['LbjService#getCounties', { state, region: region?.join(',') }] as const,
    async ([_, params]) => {
      if (!params.state || params.region === '') {
        return [];
      }

      return (await LbjService.getCounties(params.state, params.region))
        .counties;
    }
  ).data;
}

/**
 * Returns locations for the given election ID, filtered to counties if
 * provided.
 *
 * If `electionId` is undefined or `counties` is empty then returns [].
 */
export function useLocations(
  electionId: number | undefined,
  {
    counties,
    precinctId,
  }: {
    counties?: CountySlug[] | undefined;
    precinctId?: number | undefined;
  } = {}
) {
  return useSWR(
    [
      'LbjService#getLocations',
      {
        query_election_id: electionId,
        county: counties?.join(','),
        precinct: precinctId,
      },
    ] as const,

    async ([_, params]) => {
      // If county is undefined, that’s fine. Get all for election. But if it’s
      // the empty array, then short-circuit and return [].
      if (params.query_election_id === undefined || params.county === '') {
        return [];
      }

      return (
        await LbjService.getLocations({
          ...pruneFilters(params),
          // re-assignment because the type of `params` doesn’t know that
          // query_election_id is defined (from the above check).
          query_election_id: params.query_election_id,
        })
      ).locations;
    }
  ).data;
}

/**
 * Returns precincts for the given election ID, filtered to counties if
 * provided.
 *
 * If `electionId` is undefined or `counties` is empty then returns [].
 */
export function usePrecincts(
  electionId: number | undefined,
  {
    counties,
    locationId,
  }: {
    counties?: CountySlug[] | undefined;
    locationId?: number | undefined;
  } = {}
) {
  return useSWR(
    [
      'LbjService#getPrecincts',
      {
        query_election_id: electionId,
        county: counties?.join(','),
        location: locationId,
      },
    ] as const,

    async ([_, params]) => {
      // If county is undefined, that’s fine. Get all for election. But if it’s
      // the empty array, then short-circuit and return [].
      if (params.query_election_id === undefined || params.county === '') {
        return [];
      }

      return (
        await LbjService.getPrecincts({
          ...pruneFilters(params),
          // re-assignment because the type of `params` doesn’t know that
          // query_election_id is defined (from the above check).
          query_election_id: params.query_election_id,
        })
      ).precincts;
    }
  ).data;
}
/**
 * Hook that loads state-by-state VoPro contact information.
 */
export function useStateContactInfo() {
  return useSWR(['LbjService#getStateContactInfo'], async (_) => {
    return await LbjService.getStateContactInfo();
  }).data;
}

/**
 * Returns a location with the given locationId.
 */
export function usePollingLocation(locationId: string | null | undefined) {
  return useSWR(
    [
      'LbjService#getLocation',
      {
        locationId,
      },
    ] as const,

    async ([_, params]) => {
      /* 
        Returns undefined if:
       - if the locationId is an empty string
       - if there is no locationId
       - if the locationId string does not parse to a number
      */
      if (
        params.locationId === '' ||
        !params.locationId ||
        Number.isNaN(parseInt(params.locationId))
      ) {
        return undefined;
      }
      return await LbjService.getLocation(parseInt(params.locationId));
    }
  ).data;
}
