import cx from 'classnames';
import { Query } from 'history';
import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { assignmentViewTypes, State } from '../../../constants';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import { FiltersBySection } from '../../../modules/filters/action-creators';
import { AppState, toAppDispatch } from '../../../modules/flux-store';
import { actionCreators as userActionCreators } from '../../../modules/user';
import { CurrentUser } from '../../../modules/user/action-creators';
import { ApiCounty } from '../../../services/lbj-shared-service';
import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import { mapAssignmentFiltersToQueryParams } from '../../../utils/assignment/query-params';
import RIT from '../../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingProps,
} from '../../../utils/routing-provider';
import { MapFromJs } from '../../../utils/types';
import mapStateToLbjPermissions from '../../../utils/user/map-state-to-lbj-permissions';
import { PageHeader } from '../../common';

import ExportModal from '../../presentational/assignment/assignment-export-modal';
import FilterBar from '../../presentational/assignment/assignment-filter-bar';

import ViewTypeControl from '../../presentational/assignment/assignment-view-type-control';

import BoardOfElectionsListContainer from './assignment-boe-list-container';
import BoilerListContainer from './assignment-boiler-list-container';
import AssignmentDateSelection from './assignment-date-selection';
import EditorPanel from './assignment-editor-panel';
import EditorPanelV2 from './assignment-editor-panel-v2';

import PeopleListContainerV2 from './assignment-people-list-container-v2';
import PollingListContainerV2 from './assignment-polling-list-container-v2';

import AssignmentToastContainer from './assignment-toast-container';

const {
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
} = assignmentViewTypes;

class AssignmentIndexV2 extends React.Component<
  {
    dispatch?: Dispatch<any> | undefined;
    filters: Immutable.Map<string, any>;
    countyList: Immutable.List<MapFromJs<ApiCounty>> | undefined;
    currentUserData: MapFromJs<CurrentUser>;
    assignmentEditorIsOpen: boolean;
    isExporting: boolean;
    canExport: boolean;
  } & WithRoutingProps,
  {
    showExportAssignmentsModal: boolean;
  }
> {
  constructor(props: AssignmentIndexV2['props']) {
    super(props);

    this.state = {
      showExportAssignmentsModal: false,
    };

    this.handleCloseExportAssignmentsModal =
      this.handleCloseExportAssignmentsModal.bind(this);
    this.handleExportAssignments = this.handleExportAssignments.bind(this);
    this.onExportAssignmentsClick = this.onExportAssignmentsClick.bind(this);
    this.renderExportAssignmentsModal =
      this.renderExportAssignmentsModal.bind(this);
  }

  onExportAssignmentsClick() {
    this.setState({
      showExportAssignmentsModal: true,
    });
  }

  onFilterChange(e: {
    target: { name: string; value: string | null | undefined };
  }) {
    const { name: filterAttr, value: filterVal } = e.target;
    const { location, navigate } = this.props;
    const { search, pathname } = location;
    const query = searchToQuery(search);
    const toBeOmitted = this.getOmittedFilters(filterAttr);
    const newQueryParams: Query = _.omit(query, toBeOmitted);

    if (filterVal) {
      newQueryParams[filterAttr] = filterVal;
    } else {
      delete newQueryParams[filterAttr];
    }

    navigate({ pathname, search: queryToSearch(newQueryParams) });
  }

  onFilterSidebarOpen() {
    const dispatch = toAppDispatch(this.props.dispatch);
    const { closeEditorPanel } = assignmentActionCreators;

    dispatch(closeEditorPanel());
  }

  getOmittedFilters(filterAttr: string) {
    if (filterAttr === 'state' || filterAttr === 'assignment_state') {
      return ['size', 'offset', 'county'];
    }

    return ['offset', 'size'];
  }

  download(url: string) {
    const state = this.props.filters.get('assignment_state');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = `${state}-assignments.csv`;
      link.href = url;
      link.click();
      document.body.removeChild(link);
    } else {
      location.replace(url);
    }
  }

  handleCloseExportAssignmentsModal() {
    this.setState({
      showExportAssignmentsModal: false,
    });
  }

  async handleExportAssignments() {
    const { currentUserData, filters, isExporting } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { updateUserAsync } = userActionCreators;
    const { getAssignmentExportAsync, showToast } = assignmentActionCreators;
    const csvFilters = _.assign({}, filters.toJS(), {
      include_assignments: true,
    });

    if (isExporting) {
      return;
    }

    await dispatch(
      updateUserAsync(
        currentUserData.get('id'),
        { signed_eula: true },
        currentUserData.get('id')
      )
    );

    const assignmentCsvResultAction = await dispatch(
      getAssignmentExportAsync(csvFilters)
    );

    if (assignmentCsvResultAction.data.status === 'SUCCESS') {
      this.download(assignmentCsvResultAction.data.url);
      this.handleCloseExportAssignmentsModal();
    } else if (assignmentCsvResultAction.data.status === 'ERROR') {
      const toastData = {
        type: 'error' as const,
        message: `There was an error exporting that information.`,
      };
      dispatch(showToast(toastData));
    }
  }

  handleFilterStateChanges(state: AppState) {
    const { filters } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { getAssignmentListForViewTypeAsync } = assignmentActionCreators;
    const filterPayload = mapStateToAssignmentFilters(state).toJS();

    return dispatch(
      getAssignmentListForViewTypeAsync(filters.get('view'), filterPayload)
    );
  }

  generateHeaderMarkup(
    assignmentViews: Array<{
      label: string;
      filters: FiltersBySection['ASSIGNMENT'];
    }>
  ) {
    const { location } = this.props;
    const { pathname } = location;

    return (
      <>
        <ViewTypeControl>
          {assignmentViews.map(({ label, filters }) => {
            const query = _.omit(
              mapAssignmentFiltersToQueryParams(filters),
              ['size', 'offset', 'location'] // switching views always resets pagination
            );

            return (
              <li key={label}>
                <Link
                  to={{ pathname, search: queryToSearch(query) }}
                  className={cx({
                    'is-active':
                      filters.view === this.props.filters.get('view'),
                  })}
                >
                  {label}
                </Link>
              </li>
            );
          })}
        </ViewTypeControl>

        <AssignmentDateSelection />
      </>
    );
  }

  renderExportAssignmentsModal() {
    const { isExporting } = this.props;

    return (
      <ExportModal
        isPending={isExporting}
        isVisible={this.state.showExportAssignmentsModal}
        onAccept={this.handleExportAssignments}
        onRequestClose={this.handleCloseExportAssignmentsModal}
        type={'assignments'}
      />
    );
  }

  renderPollingHeaderContent() {
    const { filters } = this.props;
    return this.generateHeaderMarkup([
      {
        label: 'Locations',
        filters: Object.assign({}, filters.toJS(), { view: LOCATIONS }),
      },
      {
        label: 'Precincts',
        filters: Object.assign({}, filters.toJS(), { view: PRECINCTS }),
      },
    ]);
  }

  renderBoilerHotlineHeaderContent() {
    const { filters } = this.props;
    return this.generateHeaderMarkup([
      {
        label: 'Boiler Rooms',
        filters: Object.assign({}, filters.toJS(), { view: BOILER_ROOMS }),
      },
      {
        label: 'Hotline Centers',
        filters: Object.assign({}, filters.toJS(), { view: HOTLINES }),
      },
    ]);
  }

  renderHeaderTitle() {
    const { filters } = this.props;

    switch (filters.get('view')) {
      case PRECINCTS:
      case LOCATIONS:
        return 'Poll Assignments';

      case BOILER_ROOMS:
      case HOTLINES:
        return 'Boiler/Hotline';

      case BOARDS_OF_ELECTIONS:
        return 'Board of Elections';

      case PEOPLE:
        return 'People Assignments';

      default:
        return '';
    }
  }

  renderHeaderContent() {
    const { filters, canExport } = this.props;

    switch (filters.get('view')) {
      case PRECINCTS:
      case LOCATIONS:
        return this.renderPollingHeaderContent();

      case BOILER_ROOMS:
      case HOTLINES:
        return this.renderBoilerHotlineHeaderContent();

      case BOARDS_OF_ELECTIONS:
        return <AssignmentDateSelection />;

      case PEOPLE:
        return (
          <>
            <AssignmentDateSelection />
            {canExport && (
              <button
                className="lbj-csv-export lbj-button-blue"
                onClick={this.onExportAssignmentsClick}
              >
                Download Assignment Data
              </button>
            )}
          </>
        );
    }
  }

  renderListContainer() {
    const { filters } = this.props;

    const currentView = filters.get('view');

    switch (currentView) {
      case PRECINCTS:
      case LOCATIONS:
        return <PollingListContainerV2 currentView={currentView} />;

      case BOILER_ROOMS:
      case HOTLINES:
        return <BoilerListContainer />;

      case BOARDS_OF_ELECTIONS:
        return <BoardOfElectionsListContainer />;

      case PEOPLE:
        return <PeopleListContainerV2 />;
    }
  }

  render() {
    const { filters, assignmentEditorIsOpen } = this.props;
    const { showExportAssignmentsModal } = this.state;
    const useV2 = [LOCATIONS, PEOPLE].includes(filters.get('view'));

    return (
      <div className="lbj-page-wrapper assignments-v2">
        <PageHeader title={this.renderHeaderTitle()}>
          {this.renderHeaderContent()}
        </PageHeader>

        <div className="assignments-filter-wrapper">
          <FilterBar
            filters={filters}
            onFilterChange={this.onFilterChange.bind(this)}
          />
        </div>

        <div className="lbj-page-columns">
          <div className="lbj-main">
            <AssignmentToastContainer />
            {this.renderListContainer()}
          </div>

          {useV2 ? (
            <EditorPanelV2
              // Force remount of everything when the view changes.
              key={filters.get('view')}
              assignmentView={filters.get('view')}
              panelIsOpen={assignmentEditorIsOpen}
            />
          ) : (
            <EditorPanel
              // Force remount of everything when the view changes.
              key={filters.get('view')}
              assignmentView={filters.get('view')}
              panelIsOpen={assignmentEditorIsOpen}
            />
          )}
        </div>
        {RIT(showExportAssignmentsModal, this.renderExportAssignmentsModal)}
      </div>
    );
  }
}

export default connect((state: AppState) => {
  const { assignment, lbj, user } = state;
  const assignmentEditorIsOpen = assignment.assignmentEditor.isOpen;
  const filters = mapStateToAssignmentFilters(state);
  const { canExport } = mapStateToLbjPermissions(state);

  // `as State` to make TS happy. It’s not defined that’s okay; `get` will
  // return null/undefined and `listData` will be undefined.
  const countyList = lbj.counties.get(filters.get('state') as State)?.listData;

  const currentUserData = user.currentUser.userData!;

  const isExporting =
    assignment.assignmentExport.requestIsPending ||
    user.updatingUser.requestIsPending;

  return {
    assignmentEditorIsOpen,
    canExport,
    countyList,
    currentUserData,
    filters,
    isExporting,
  };
})(withRouting(AssignmentIndexV2));
