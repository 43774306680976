import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class BoardOfElectionsDetails extends Component {
  static propTypes = {
    boardOfElections: PropTypes.object.isRequired,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    boardOfElections: Immutable.Map({}),
  };

  render() {
    const { showHeader, boardOfElections } = this.props;

    return (
      <div>
        {RIT(showHeader, () => {
          return (
            <div className="lbj-column-label lbj-column-divider">
              <h4 className="assignment-details-title-text">
                {boardOfElections.get('name')}
              </h4>
            </div>
          );
        })}

        <div className="lbj-column-content">
          <ul className="lbj-detail-list">
            <li>
              <strong>Address: </strong>
              {boardOfElections.get('address') || 'N/A'}
            </li>
            <li>
              <strong>City: </strong>
              {boardOfElections.get('city') || 'N/A'}
            </li>
            <li>
              <strong>State: </strong>
              {boardOfElections.get('state') || 'N/A'}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
