import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../constants';
import { AppState } from '../../modules/flux-store';
import { DetailedUser } from '../../modules/user/action-creators';
import { MapFromJs } from '../../utils/types';

import UserDetailContainer from './user-detail-container';

const { PROFILE } = lbjAppSections;

class UnconnectedProfileContainer extends Component<{
  user: MapFromJs<DetailedUser>;
  filters: Immutable.Map<string, string | null | undefined>;
  userIsLoading: boolean;
}> {
  render() {
    const { user, filters, userIsLoading } = this.props;

    return (
      <UserDetailContainer
        user={user}
        filters={filters}
        userIsLoading={userIsLoading}
        headerText="My Account"
        disableCancel={user.get('role') === 'none'}
      />
    );
  }
}

export default connect(
  (state: AppState): UnconnectedProfileContainer['props'] => {
    const { user } = state;
    const filters = state.filters.get(PROFILE);

    return {
      // The profile UI works for both the current user and loaded users;
      // we need to do this cast because of Immutables
      user: user.currentUser.userData as MapFromJs<DetailedUser>,
      userIsLoading: user.currentUser.requestIsPending,
      filters,
    };
  }
)(UnconnectedProfileContainer);
