/* eslint no-console: 0 */
import React from 'react';

import { solveMipProblem } from '../../utils/mip-solver/cbc-solver';

/**
 * Silly page to trigger CBC’s solver.
 */
const CbcPage: React.FunctionComponent = () => {
  const [cbcIsSolving, setCbcIsSolving] = React.useState(false);

  const [objectiveValue, setObjectiveValue] = React.useState<number | null>(
    null
  );

  const onClick = async () => {
    setCbcIsSolving(true);

    console.log('Loading problem…');
    // This is just a toy problem to show the binary working. We’ve also tried
    // it in the browser with a much larger problem, but that would be wasteful
    // to check in to git.
    console.log('Solving problem…');
    const solution = await solveMipProblem(
      {
        goal: 'maximize',
        booleans: [
          { name: 'Vol2~Loc1', coefficient: 18 },
          { name: 'Vol3~Loc1', coefficient: 23 },
          { name: 'Vol8~Loc1', coefficient: 70 },
          { name: 'Vol3~Loc2', coefficient: 58 },
          { name: 'Vol6~Loc2', coefficient: 93 },
          { name: 'Vol2~Loc3', coefficient: 35 },
          { name: 'Vol3~Loc5', coefficient: 70 },
          { name: 'Vol4~Loc5', coefficient: 19 },
          { name: 'Vol6~Loc5', coefficient: 85 },
          { name: 'Vol8~Loc5', coefficient: 99 },
          { name: 'Vol2~Loc6', coefficient: 8 },
          { name: 'Vol3~Loc6', coefficient: 40 },
          { name: 'Vol6~Loc6', coefficient: 48 },
          { name: 'Vol7~Loc6', coefficient: 37 },
          { name: 'Vol2~Loc7', coefficient: 41 },
          { name: 'Vol8~Loc7', coefficient: 38 },
          { name: 'Vol2~Loc8', coefficient: 99 },
          { name: 'Vol8~Loc8', coefficient: 27 },
          { name: 'Vol5~Loc9', coefficient: 19 },
          { name: 'Vol3~Loc10', coefficient: 60 },
          { name: 'Vol4~Loc10', coefficient: 30 },
          { name: 'Vol7~Loc10', coefficient: 49 },
          { name: 'Vol9~Loc10', coefficient: 44 },
        ],
        constraints: [
          {
            variableNames: [
              'Vol2~Loc1',
              'Vol2~Loc3',
              'Vol2~Loc6',
              'Vol2~Loc7',
              'Vol2~Loc8',
            ],
            maximum: 1,
          },
          {
            variableNames: [
              'Vol3~Loc1',
              'Vol3~Loc2',
              'Vol3~Loc5',
              'Vol3~Loc6',
              'Vol3~Loc10',
            ],
            maximum: 1,
          },
          {
            variableNames: ['Vol4~Loc5', 'Vol4~Loc10'],
            maximum: 1,
          },
          {
            variableNames: ['Vol5~Loc9'],
            maximum: 1,
          },
          {
            variableNames: ['Vol6~Loc2', 'Vol6~Loc5', 'Vol6~Loc6'],
            maximum: 1,
          },
          {
            variableNames: ['Vol7~Loc6', 'Vol7~Loc10'],
            maximum: 1,
          },
          {
            variableNames: ['Vol8~Loc1', 'Vol8~Loc5', 'Vol8~Loc7', 'Vol8~Loc8'],
            maximum: 1,
          },
          {
            variableNames: ['Vol9~Loc10'],
            maximum: 1,
          },
          {
            variableNames: ['Vol2~Loc1', 'Vol3~Loc1', 'Vol8~Loc1'],
            maximum: 1,
          },
          {
            variableNames: ['Vol3~Loc2', 'Vol6~Loc2'],
            maximum: 1,
          },
          {
            variableNames: ['Vol2~Loc3'],
            maximum: 1,
          },
          {
            variableNames: ['Vol3~Loc5', 'Vol4~Loc5', 'Vol6~Loc5', 'Vol8~Loc5'],
            maximum: 1,
          },
          {
            variableNames: ['Vol2~Loc6', 'Vol3~Loc6', 'Vol6~Loc6', 'Vol7~Loc6'],
            maximum: 1,
          },
          {
            variableNames: ['Vol2~Loc7', 'Vol8~Loc7'],
            maximum: 1,
          },
          {
            variableNames: ['Vol2~Loc8', 'Vol8~Loc8'],
            maximum: 1,
          },
          {
            variableNames: ['Vol5~Loc9'],
            maximum: 1,
          },
          {
            variableNames: [
              'Vol3~Loc10',
              'Vol4~Loc10',
              'Vol7~Loc10',
              'Vol9~Loc10',
            ],
            maximum: 1,
          },
        ],
      },
      {
        allowableGap: 0,
      }
    );

    console.log(solution);
    setObjectiveValue(solution.objectiveValue);
    setCbcIsSolving(false);
  };

  return (
    <div className="flex h-screen w-screen  flex-col items-center justify-center p-8">
      <button
        className="border-2 border-solid border-black p-4 text-legacy-2xl text-black"
        onClick={onClick}
        disabled={cbcIsSolving}
      >
        Solve!
      </button>

      {objectiveValue !== null && (
        <div className="text-legacy-xl">Objective Value: {objectiveValue}</div>
      )}
    </div>
  );
};

export default CbcPage;
