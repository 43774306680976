export default function (state) {
  const { user } = state;
  const userElections = user.getIn([
    'currentUser',
    'userData',
    'user_elections',
  ]);
  const currentElectionId = user.getIn([
    'currentUser',
    'currentUserElection',
    'election',
    'id',
  ]);

  const elections = {};
  userElections.forEach((userElection) => {
    const electionId = userElection.getIn(['election', 'id']);
    const electionRole = userElection.get('role');
    if (
      electionId &&
      electionId !== currentElectionId &&
      (electionRole === 'vpd' || electionRole === 'deputy_vpd')
    ) {
      elections[electionId] = userElection.getIn(['election', 'name']);
    }
  });
  return elections;
}
