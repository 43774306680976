import cx from 'classnames';
import React, { Component } from 'react';
import Select from 'react-select';
import * as ReactSelect from 'react-select';

import { inputDefaultProps, InputProps } from '../../../decorators/input-props';
import RIT from '../../../utils/render-if-truthy';

/**
 * Wrapper around `react-select` for making an auto-complete, multi-select box.
 *
 * `value` prop should be a comma-joined string of values.
 */
export default class Autocomplete<TValue extends string> extends Component<
  InputProps<TValue | null, TValue | null> &
    Pick<
      ReactSelect.ReactSelectProps<TValue | null>,
      | 'valueRenderer'
      | 'optionRenderer'
      | 'filterOptions'
      | 'filterOption'
      | 'clearable'
      | 'multi'
      | 'resetValue'
      | 'isLoading'
      | 'onInputChange'
    > & {
      choices: Partial<{ [k in TValue]: string | { name: string } }>;
      preferredChoices?: TValue[];
      alphaSort?: boolean;
      /**
       * Change handler sent directly to react-select. If not provided, we call
       * `onChange` instead with our fake event objects.
       */
      handleChange?: ReactSelect.OnChangeHandler<TValue>;
      className?: string | undefined;
    }
> {
  static defaultProps = {
    ...inputDefaultProps,
    type: 'filter',
  };

  // Pass the name and value to onChange in a mock "event" object
  handleChange(value: TValue) {
    const { name, onChange, resetValue } = this.props;

    if (typeof value === 'undefined') {
      value = resetValue;
    }

    if (onChange) {
      onChange({
        target: {
          name,
          value,
        },
      });
    }
  }

  sortByLabel(
    optA: ReactSelect.Option<TValue>,
    optB: ReactSelect.Option<TValue>
  ) {
    const labelA = (
      typeof optA.label === 'string' ? optA.label : (optA.label as any).name
    ).toLowerCase();
    const labelB = (
      typeof optB.label === 'string' ? optB.label : (optB.label as any).name
    ).toLowerCase();

    const labelANum = parseInt(labelA, 10);
    const labelBNum = parseInt(labelB, 10);

    if (isNaN(labelANum) || isNaN(labelBNum)) {
      return labelA.localeCompare(labelB);
    }

    return labelANum - labelBNum;
  }

  sortByPreferred(
    optA: ReactSelect.Option<TValue>,
    optB: ReactSelect.Option<TValue>
  ) {
    const { preferredChoices, alphaSort } = this.props;

    // Using "!" here because this function is only called if `preferredChoices`
    // is specified.

    if (
      preferredChoices!.indexOf(optA.value!) !== -1 &&
      preferredChoices!.indexOf(optB.value!) === -1
    ) {
      return -1;
    } else if (
      preferredChoices!.indexOf(optA.value!) === -1 &&
      preferredChoices!.indexOf(optB.value!) !== -1
    ) {
      return 1;
    }

    if (alphaSort) {
      return this.sortByLabel(optA, optB);
    }

    return 0;
  }

  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return <label htmlFor={name}>{title}</label>;
  }

  renderInput() {
    const {
      choices,
      preferredChoices,
      alphaSort,
      name,
      errors,
      defaultValue,
      resetValue,
      handleChange,
      ...rest
    } = this.props;
    const changeHandler: (value: TValue) => void =
      handleChange || this.handleChange.bind(this);
    let options: ReactSelect.Option<TValue>[] = [];

    for (const choice in choices) {
      if (choices[choice]) {
        options.push({
          // HACK(fiona): this can be an object w/ a “name” field. See renderOption.
          label: choices[choice] as any,
          value: choice,
        });
      }
    }

    // Sort alphabetically
    if (alphaSort) {
      options = options.sort(this.sortByLabel.bind(this));
    }

    // Display "preferred" choices first
    if (preferredChoices && preferredChoices.length) {
      options = options.sort(this.sortByPreferred.bind(this));
    }

    return (
      <Select<TValue | null>
        id={name}
        options={options}
        // "as any" to allow us to pass null, which we’ve been doing, but the
        // types don’t like it so much.
        value={defaultValue as any}
        resetValue={resetValue}
        optionRenderer={this.renderOption.bind(this)}
        {...rest}
        // “as any” because we’re in “simpleValue” mode
        onChange={changeHandler as any}
        simpleValue
        inputProps={{
          autoCorrect: 'off',
          autoCapitalize: 'off',
        }}
      />
    );
  }

  renderOption: ReactSelect.OptionRendererHandler<TValue | null> = (option) => {
    const { preferredChoices } = this.props;
    const label =
      typeof option.label === 'string'
        ? option.label
        : (option.label as any).name;
    const isPreferred =
      preferredChoices && preferredChoices.length
        ? preferredChoices.indexOf(option.value!) !== -1
        : false;
    const className = isPreferred ? 'is-preferred' : '';

    return <span className={className}>{label}</span>;
  };

  renderError(error: string, key: string | number) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  renderContent() {
    return <div className="lbj-input-content">{this.props.children}</div>;
  }

  render() {
    const { name, errors, type } = this.props;
    const typeClass = type ? 'lbj-input-autocomplete-' + type : '';
    const className = cx(
      'lbj-input',
      'lbj-input-autocomplete',
      `lbj-input-${name}`,
      typeClass
    );

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
        {errors?.map((error, key) => this.renderError(error, key))}
        {RIT(this.props.children, this.renderContent.bind(this))}
      </div>
    );
  }
}
