import cx from 'classnames';
import { Query } from 'history';
import moment from 'moment';
import React from 'react';
import { Link, Location } from 'react-router-dom';

import { PageTitle } from '../../components/common';
import {
  RenderOnMobile,
  RenderOnDesktop,
} from '../../components/layout/RenderOnDevice';
import Autocomplete from '../../components/presentational/form/autocomplete';
import CategorySelect from '../../components/presentational/form/category-select';
import Input from '../../components/presentational/form/input';
import RadioInput from '../../components/presentational/form/radio';
import TextArea from '../../components/presentational/form/textarea';
import ToggleInput from '../../components/presentational/form/toggle';
import { SelectableUser } from '../../components/presentational/form/user-autocomplete';

import LoadingOverlay from '../../components/presentational/lbj/loading';
import Toast from '../../components/presentational/lbj/toast';
import {
  callerRelationships,
  IssueClass,
  issuePriorities,
  issueScopes,
  voteStatuses,
  State,
  IssueStatus,
} from '../../constants';
import { AppDispatch } from '../../modules/flux-store';
import { actionCreators as issueActionCreators } from '../../modules/issue';
import {
  DocumentUploadData,
  ExistingIssueInProgress,
  IssueErrorData,
  NewIssueInProgress,
  PhotoUploadData,
} from '../../modules/issue/reducers';
import { ToastData, ToastRecord } from '../../modules/toast';
import {
  ApiIssue,
  ApiIssueDocument,
  ApiIssuePhoto,
} from '../../services/issue-service';
import { ApiBoilerRoom, ApiElection } from '../../services/lbj-shared-service';
import { ApiCurrentUser } from '../../services/user-service';
import { getIssueNavigation } from '../../utils/issue/issue-navigation';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';
import RIT from '../../utils/render-if-truthy';
import { queryToSearch, searchToQuery } from '../../utils/routing-provider';
import { MapFromJs } from '../../utils/types';
import {
  getEnabledIssueFields,
  roleMayAddToIssue,
} from '../../utils/user/map-state-to-lbj-permissions';

import IssuesListByPhoneNumber from './IssuesListByPhoneNumber';
import IssueAssignmentContainer from './issue-assignment-container';
import IssueComments from './issue-comments';
import IssueDocuments from './issue-documents';
import IssueHistory from './issue-history';
import IssueLocationContainer from './issue-location-container';
import IssueObserver from './issue-observer';
import IssuePhotos, { PhotoUploadBlob } from './issue-photos';
import IssueStructuredVoterAddress from './issue-structured-voter-address';

export default class IssueDetailForm extends React.Component<
  {
    issueQueryParams: Query;
    issueElectionId: number | null;
    currentUserData: MapFromJs<ApiCurrentUser>;
    onInputChange: (event: {
      target: {
        name: string;
        value: string | boolean | null | undefined | number;
      };
    }) => void;
    onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCommentAdd: () => void;
    commentText: string;
    errorData: IssueErrorData;
    documentUploadIsPending: boolean;
    photoUploadIsPending: boolean;
    isStateReadOnly: boolean;
    isVoterImpactedReadOnly: boolean;
    isDescriptionReadOnly: boolean;
    requestIsPending: boolean;
    formType: 'poll_observer' | 'hotline';
    requiredFields: Immutable.List<keyof ApiIssue>;
    numCategories: number;
    setNumCategories: (num: number) => void;
    toastData: ToastRecord;
    commonActions: CommonIssueFormActions;
    featureFlags: string[];
  } & (
    | {
        action: 'create';
        issueData: NewIssueInProgress;
        isFormTypeVisible: boolean;
        onFormTypeToggle: (event: React.MouseEvent) => void;
        addToIssueRecord: (phoneNumber: string, issueId: number) => void;
        onIssueSubmit: (status: IssueStatus) => void;
        onSetFields: (changes: {
          [field in keyof NewIssueInProgress]?: any;
        }) => void;
        electionIsTest: boolean;
        onPhotoAdd: (photo: PhotoUploadData) => void;
        onPhotoDelete: (photo: PhotoUploadData) => void;
        onDocumentAdd: (document: DocumentUploadData) => void;
        onDocumentDelete: (document: DocumentUploadData) => void;
      }
    | {
        action: 'edit';
        issueData: IssueInProgressPayload;
        location: Location;
        existingID: string;
        isFetching: boolean;
        issueInProgress: MapFromJs<ExistingIssueInProgress>;
        isFormTypeVisible: false;
        onClaimIssue: () => void;
        onEscalateIssue: () => void;
        onReplyAdd: (parentId: number, replyText: string) => void;
        isSubscribed: () => boolean;
        /** Toggles whether the issue is resolved. */
        onResolveButtonClick: () => void;
        onIssueSave: () => void;
        onSubscribeButtonClick: (
          updateAction: 'UPDATE' | 'VIEW_ONLY_UPDATE'
        ) => void;
        historyIsLoading: boolean;
        canSeeQa: boolean;
        onPhotoAdd: (photo: PhotoUploadBlob) => void;
        onPhotoDelete: (photo: ApiIssuePhoto) => void;
        onDocumentAdd: (document: File) => void;
        onDocumentDelete: (document: ApiIssueDocument) => void;
        boilerRoomList: Immutable.List<MapFromJs<ApiBoilerRoom>>;
        electionList: Immutable.List<MapFromJs<ApiElection>>;
        preloadedUsers: SelectableUser[];
        isPriorityVisible: boolean;
        isObserverVisible: boolean;
        isObserverReadOnly: boolean;
        onSetFields: (changes: {
          [field in keyof ExistingIssueInProgress]?: any;
        }) => void;
      }
  ),
  {
    showDetailedVoterInfo: boolean;
    availableCallerRelationships: Partial<typeof callerRelationships>;
    requiredErrors: IssueErrorData;
  }
> {
  constructor(props: IssueDetailForm['props']) {
    super(props);

    this.state = {
      showDetailedVoterInfo: false,
      availableCallerRelationships: callerRelationships,
      requiredErrors: {},
    };
  }

  componentDidMount() {
    if (typeof $ !== 'undefined') {
      $('html').addClass('legacy-bg-gray');
    }
  }

  componentWillUnmount() {
    if (typeof $ !== 'undefined') {
      $('html').removeClass('legacy-bg-gray');
    }
  }

  onClickSearch(onGetIssueListByPhoneNumber: () => void) {
    const { issueData } = this.props;
    if ((issueData.voter_phone_number?.length ?? 0) >= 5) {
      onGetIssueListByPhoneNumber();
    }
  }

  /**
   * If the call says that they’re impacted, removes the “who was
   * impacted?” question as on option from later in the form.
   */
  onCallerVoterImpacted(
    onSetFields: (changes: any) => void,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.value === 'yes') {
      this.setState({ availableCallerRelationships: callerRelationships });

      onSetFields({
        caller_relationship: 'voter',
        caller_voter_impacted: 'yes',
      });
    } else {
      const modifiedCallerRelationships: Partial<typeof callerRelationships> = {
        ...callerRelationships,
      };

      delete modifiedCallerRelationships.voter;

      this.setState({
        availableCallerRelationships: modifiedCallerRelationships,
      });

      onSetFields({
        caller_relationship: null,
        caller_voter_impacted: 'no',
      });
    }
  }

  onSetType(
    onSetFields: (changes: {
      [field in keyof NewIssueInProgress]?: any;
    }) => void,
    type: IssueClass
  ) {
    if (type === this.props.issueData.type) {
      return;
    }

    onSetFields({
      type: type,
      category: null,
      sub_category: null,
      category_2: null,
      sub_category_2: null,
      category_3: null,
      sub_category_3: null,
    });

    this.props.setNumCategories(1);
  }

  getIssueScopes() {
    const { action } = this.props;

    if (action === 'edit') {
      return issueScopes;
    }

    const { none, ...rest } = issueScopes;
    return rest;
  }

  validateAndRun(onValid: () => void) {
    const errors = this.checkRequired();
    if (Object.keys(errors).length === 0) {
      onValid();
    } else {
      this.props.commonActions.showToast({
        type: 'error',
        message: 'Some required fields were not filled out. See below.',
      });
    }
    this.setState({ requiredErrors: errors });
  }

  checkRequired() {
    const errors: IssueErrorData = {};
    for (const field of this.props.requiredFields) {
      // "as any" because of field differences between new and existing issues.
      if (!(this.props.issueData as any)[field]) {
        errors[field] = ['This field is required.'];
      }
    }
    return errors;
  }

  renderSubscribeButton(props: EditIssueProps) {
    // TODO: how to handle view_only un/subscribe to issue
    const isSubscribed = props.isSubscribed();
    const buttonLabel = isSubscribed ? 'Unsubscribe' : 'Subscribe to Issue';
    const currentUserRole = props.currentUserData.get('role');
    const subscribeAction =
      currentUserRole === 'view_only' ? 'VIEW_ONLY_UPDATE' : 'UPDATE';
    return (
      <a href="#" onClick={() => props.onSubscribeButtonClick(subscribeAction)}>
        {buttonLabel}
      </a>
    );
  }

  renderResolveButton(props: EditIssueProps) {
    const { status } = props.issueData;
    const isResolved = status === 'resolved';
    const buttonLabel = isResolved ? 'Mark as Unresolved' : 'Mark as Resolved';
    const buttonClassName = cx('submit-resolved', {
      'is-resolved': isResolved,
    });

    return (
      <button
        name="resolve_button"
        type="button"
        onClick={() => props.onResolveButtonClick()}
        className={buttonClassName}
      >
        {buttonLabel}
      </button>
    );
  }

  renderExistingIssueTitle(props: EditIssueProps) {
    const {
      existingID,
      issueData,
      requestIsPending,
      issueQueryParams,
      isFetching,
      location,
      currentUserData,
    } = props;
    const {
      status,
      from_permanent: fromPermanent,
      category,
      category_2,
      category_3,
      sub_category,
      sub_category_2,
      sub_category_3,
    } = issueData;
    const className = status === 'resolved' ? 'is-resolved' : '';
    const titleText = `Issue #${existingID !== 'undefined' ? existingID : ''}`;
    const userRole = currentUserData.get('role');

    // We want issues that only appear on the /results page to link back there
    // on “cancel changes.” Note that issues that have Results/Vote Tally as
    // just _one_ of their category/subcategory pairs will appear on both the
    // /results page _and_ the /issues page. Those in theory are rare. Since we
    // have to choose one, we pick the /issues page for those to return to.

    const isResultsIssue =
      category === 'Results' && category_2 === '' && category_3 === '';

    const isVoteTallyIssue =
      sub_category === 'Vote Tally' &&
      sub_category_2 === '' &&
      sub_category_3 === '';

    const issueListPath =
      isResultsIssue && isVoteTallyIssue ? '/results' : '/issues';

    const issueLinkQueryParams = { ...issueQueryParams };
    // This was added by mapStateToIssueFilters for the API, but we don’t want
    // it to appear in the query string.
    delete issueLinkQueryParams['exclude_category'];

    return (
      <div className="detail-issue-header">
        {RIT(isFetching || requestIsPending, () => (
          <LoadingOverlay />
        ))}
        <div className="line-2">
          {getIssueNavigation(searchToQuery(location.search))}
        </div>
        <div className="line-3">
          <PageTitle className={className}>{titleText}</PageTitle>
          <span className="title-time">
            {RIT(fromPermanent, () => (
              <span className="perm-notice">from Permanent Hotline</span>
            ))}
            {moment(issueData.report_time).format('MMM. Do, YYYY h:mma')}
          </span>
          {userRole === 'view_only' ? (
            <div className="submit-buttons">
              {this.renderSubscribeButton(props)}
            </div>
          ) : (
            <div className="submit-buttons">
              <Link to="/issues/new">Start New Issue</Link>
              {this.renderSubscribeButton(props)}
              <Link
                to={{
                  pathname: issueListPath,
                  search: queryToSearch(issueLinkQueryParams),
                }}
              >
                Cancel Changes
              </Link>
              <RenderOnDesktop>
                {this.renderExistingIssueSubmitAndResolveButtons(props)}
              </RenderOnDesktop>
            </div>
          )}
        </div>
        <RenderOnDesktop>
          {this.renderExistingIssueToastData(props)}
        </RenderOnDesktop>
      </div>
    );
  }

  renderExistingIssueSubmitAndResolveButtons(props: EditIssueProps) {
    return (
      <div className="new-issue-header">
        <div className="submit-buttons">
          <button
            className="submit-review"
            type="button"
            onClick={() => this.validateAndRun(() => props.onIssueSave())}
          >
            Submit Changes
          </button>
          {this.renderResolveButton(props)}
        </div>
      </div>
    );
  }

  renderExistingIssueToastData(props: EditIssueProps) {
    const {
      toastData,
      commonActions: { dismissToast },
    } = props;

    return <Toast toastData={toastData} onDismiss={dismissToast} />;
  }

  renderNewIssueTitle(props: NewIssueProps) {
    const { formType, isFormTypeVisible, requestIsPending, onFormTypeToggle } =
      props;

    return (
      <div>
        <div className="new-issue-header">
          {RIT(requestIsPending, () => (
            <LoadingOverlay />
          ))}
          <PageTitle>New Issue</PageTitle>
          {RIT(isFormTypeVisible, () => (
            <a href="#" onClick={onFormTypeToggle}>
              {formType === 'hotline'
                ? 'Go to Poll Observer Form'
                : 'Go to Hotline Worker Form'}
            </a>
          ))}
          <RenderOnDesktop>{this.renderNewIssueButtons(props)}</RenderOnDesktop>
        </div>
        <RenderOnDesktop>{this.renderNewIssueToastData(props)}</RenderOnDesktop>
      </div>
    );
  }

  renderNewIssueButtons(props: NewIssueProps) {
    const { issueData } = props;

    const submitForReviewDisabled =
      issueData.type === 'inquiry' && issueData.category === 'Results';

    return (
      <div className="submit-buttons">
        <button
          className="submit-review"
          type="button"
          disabled={submitForReviewDisabled}
          onClick={() => this.validateAndRun(() => props.onIssueSubmit('open'))}
        >
          Submit for Follow Up
        </button>
        <button
          className="submit-resolved"
          type="button"
          onClick={() =>
            this.validateAndRun(() => props.onIssueSubmit('resolved'))
          }
        >
          Submit as Resolved
        </button>
      </div>
    );
  }

  renderNewIssueToastData(props: NewIssueProps) {
    const {
      toastData,
      commonActions: { dismissToast },
    } = props;

    return <Toast toastData={toastData} onDismiss={dismissToast} />;
  }

  renderIssueTypeCard() {
    const {
      action,
      issueElectionId,
      currentUserData,
      onInputChange,
      issueData,
      isStateReadOnly,
      isDescriptionReadOnly,
      errorData,
      formType,
      numCategories,
      setNumCategories,
    } = this.props;
    const noun = formType === 'hotline' ? 'caller' : 'voter';

    const errors = {
      ...this.state.requiredErrors,
      ...errorData,
    };

    return (
      <div className="hotline-card">
        <div className="header">
          {action === 'create' && <span className="number">2</span>}
          Issue Type
          {action === 'create' && (
            <>
              &nbsp;<span className="req-indicator">*</span>
            </>
          )}
        </div>
        {action === 'create' && formType === 'hotline' && (
          <div className="microcopy">
            A Question is when a caller is seeking info and is usually resolved
            in one call. An Incident is when something has gone wrong when
            trying to vote and usually requires follow up.
          </div>
        )}
        {action === 'create' && formType === 'poll_observer' && (
          <div className="microcopy">
            A Question is when a voter is seeking info and is usually resolved
            immediately. An Incident is when something has gone wrong when
            trying to vote and usually requires follow up.
          </div>
        )}
        <div className={cx('issue-type', { editing: action === 'edit' })}>
          <span
            className={cx('issue-type-opt', {
              'issue-type-opt-selected': issueData.type === 'inquiry',
              clickable: action === 'create',
              disabled: issueData.type !== 'inquiry' && action === 'edit',
            })}
            onClick={() =>
              this.props.action === 'create' &&
              this.onSetType(this.props.onSetFields, 'inquiry')
            }
          >
            Question
          </span>
          <span
            className={cx('issue-type-opt', {
              'issue-type-opt-selected': issueData.type === 'incident',
              clickable: action === 'create',
              disabled: issueData.type !== 'incident' && action === 'edit',
            })}
            onClick={() =>
              this.props.action === 'create' &&
              this.onSetType(this.props.onSetFields, 'incident')
            }
          >
            Incident
          </span>
        </div>
        {RIT(errors.type, () => (
          <p className="c-error-message">Please select an issue type.</p>
        ))}
        {RIT(issueData.type, () => (
          <div>
            <div className="subheading">
              Select category and subcategory types
            </div>
            {action === 'create' && (
              <div className="microcopy">
                Find the issue that matches the {noun}’s issue. To select, type
                to search or scroll. If a {noun} has more than one issue, you
                can report up to 3 different issues by selecting “Add another
                category”.
              </div>
            )}
            <div className="spacer" />
            <CategorySelect
              issueData={issueData}
              isDescriptionReadOnly={isDescriptionReadOnly}
              onChange={onInputChange}
              errors={errors}
              numCategories={numCategories}
              setNumCategories={setNumCategories}
            />
            <hr />
            {action === 'create' && formType === 'hotline' && (
              <div className="script">
                I need a few more details to help us keep track of issues.
              </div>
            )}
            {formType === 'hotline' && (
              <div>
                <Autocomplete
                  title="What is your relationship to the voter?"
                  name="caller_relationship"
                  placeholder=""
                  onChange={onInputChange}
                  value={issueData.caller_relationship ?? ''}
                  choices={this.state.availableCallerRelationships}
                  errors={errors.caller_relationship}
                  disabled={issueData.caller_voter_impacted === 'yes'}
                  required={this.props.requiredFields.includes(
                    'caller_relationship'
                  )}
                />
              </div>
            )}
            <RadioInput
              title="Was the impacted voter able to vote?"
              name="vote_status"
              value={issueData.vote_status}
              onChange={onInputChange}
              errors={errors.vote_status}
              choices={voteStatuses}
              disabled={isDescriptionReadOnly}
              required={this.props.requiredFields.includes('vote_status')}
            />
            <Autocomplete
              title="How many voters are affected?"
              name="scope"
              type="select"
              placeholder=""
              choices={this.getIssueScopes()}
              defaultValue={issueData.scope ?? ''}
              errors={errors.scope}
              onChange={onInputChange}
              disabled={isDescriptionReadOnly}
              required={this.props.requiredFields.includes('scope')}
            />
            {RIT(
              action === 'create' &&
                formType === 'hotline' &&
                issueData.type === 'incident',
              () => (
                <div className="script">
                  I’d like to get some information about where the issue
                  occurred.
                </div>
              )
            )}
            <IssueLocationContainer
              requiredFields={this.props.requiredFields}
              issueData={issueData}
              issueElectionId={issueElectionId}
              isStateReadOnly={isStateReadOnly}
              errors={errors}
              onInputChange={onInputChange}
              formType={formType}
              actions={this.props.commonActions}
              existingCounty={
                this.props.action === 'edit'
                  ? this.props.issueInProgress.get('county')?.toJS() ?? null
                  : null
              }
              existingLocation={
                this.props.action === 'edit'
                  ? this.props.issueInProgress.get('location')?.toJS() ?? null
                  : null
              }
              existingPrecinct={
                this.props.action === 'edit'
                  ? this.props.issueInProgress.get('precinct')?.toJS() ?? null
                  : null
              }
              currentUserRole={currentUserData.get('role')}
              currentUserRelated={
                currentUserData
                  .get('related')
                  .toJS() as ApiCurrentUser['related']
              }
              locationIsReadOnly={
                !getEnabledIssueFields(
                  currentUserData.get('role'),
                  currentUserData.get('id'),
                  this.props.action === 'edit'
                    ? this.props.issueInProgress
                    : null
                ).includes('location')
              }
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      action,
      onInputChange,
      issueData,
      errorData,
      onPhotoAdd,
      onPhotoDelete,
      photoUploadIsPending,
      onDocumentAdd,
      onDocumentDelete,
      documentUploadIsPending,
      commentText,
      onCommentChange,
      isVoterImpactedReadOnly,
      isDescriptionReadOnly,
      onCommentAdd,
      formType,
      commonActions,
      onSetFields,
      issueElectionId,
      currentUserData,
    } = this.props;
    const errors: any = Object.assign(this.state.requiredErrors, errorData);
    const userRole = currentUserData.get('role');
    const canAddToIssue = roleMayAddToIssue(userRole);
    return (
      <div id="issues-hotline-new">
        {this.props.action === 'create'
          ? this.renderNewIssueTitle(this.props)
          : this.renderExistingIssueTitle(this.props)}
        {this.props.action === 'create' && this.props.electionIsTest && (
          <div className="issue-warning">
            This is a training election. If this is a real election issue make
            sure to switch to the live election.
          </div>
        )}
        {this.props.action === 'edit' && userRole === 'view_only' && (
          <div className="mt-2 bg-neutral-100 p-2 font-bold">
            This issue is in view-only mode.
          </div>
        )}
        <div className="col-set">
          <div className="col">
            <div className="hotline-card">
              <div className="header">
                {action === 'create' && <span className="number">1</span>}
                {formType === 'hotline'
                  ? 'Caller Information'
                  : 'Voter Information'}
              </div>
              {action === 'create' && formType === 'hotline' && (
                <div className="script">
                  Hi! Can I get some basic information in case we get
                  disconnected?
                </div>
              )}
              {formType === 'hotline' && (
                <>
                  <Input
                    name="voter_phone_number"
                    title="Phone number"
                    placeholder="123 - 555 - 5555"
                    value={issueData.voter_phone_number || ''}
                    onChange={onInputChange}
                    errors={errors.voter_phone_number}
                    disabled={isVoterImpactedReadOnly}
                  />

                  {this.props.action === 'create' && (
                    <>
                      <IssuesListByPhoneNumber
                        addToIssueRecord={this.props.addToIssueRecord}
                        formIsDirty={this.props.commentText !== ''}
                        phoneNumber={issueData.voter_phone_number || ''}
                        ticketElectionId={issueElectionId}
                        state={issueData.state || ''}
                      />
                      <hr />
                    </>
                  )}
                </>
              )}
              <Input
                name="voter_first_name"
                title={
                  formType === 'hotline'
                    ? "Caller's First Name"
                    : "Voter's First Name"
                }
                placeholder=""
                value={issueData.voter_first_name || ''}
                onChange={onInputChange}
                errors={errors.voter_first_name}
                disabled={isVoterImpactedReadOnly}
              />
              <Input
                name="voter_last_name"
                title={
                  formType === 'hotline'
                    ? "Caller's Last Name"
                    : "Voter's Last Name"
                }
                placeholder=""
                value={issueData.voter_last_name || ''}
                onChange={onInputChange}
                errors={errors.voter_last_name}
                disabled={isVoterImpactedReadOnly}
              />
              {formType === 'poll_observer' && (
                <Input
                  name="voter_phone_number"
                  title="Phone number"
                  placeholder="123 - 555 - 5555"
                  value={issueData.voter_phone_number || ''}
                  onChange={onInputChange}
                  errors={errors.voter_phone_number}
                  disabled={isVoterImpactedReadOnly}
                />
              )}
              {this.props.action === 'create' && formType === 'hotline' && (
                <div>
                  <RadioInput
                    name="caller_voter_impacted"
                    title="Is the caller the voter who is impacted?"
                    value={issueData.caller_voter_impacted}
                    onChange={this.onCallerVoterImpacted.bind(
                      this,
                      this.props.onSetFields
                    )}
                    choices={{ yes: 'Yes', no: 'No' }}
                    showLabel
                    disabled={isVoterImpactedReadOnly}
                  />
                </div>
              )}
            </div>
            <div className="hotline-card desktop">
              <div
                className="header subheading link"
                onClick={() =>
                  this.setState({
                    showDetailedVoterInfo: !this.state.showDetailedVoterInfo,
                  })
                }
              >
                Detailed Voter Information
                {this.state.showDetailedVoterInfo ? (
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 15l7-7 7 7"></path>
                  </svg>
                ) : (
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 9l-7 7-7-7"></path>
                  </svg>
                )}
              </div>
              <div className="microcopy">
                Add the voter’s registration address
              </div>
              <div
                className={cx({ hidden: !this.state.showDetailedVoterInfo })}
              >
                {action === 'create' && formType === 'hotline' && (
                  <div className="script">
                    What is the address where the voter is registered to vote?
                    This will help me check their registration.
                  </div>
                )}
                {(issueData.voter_address?.length ?? 0) > 0 && (
                  <TextArea
                    key="voter_address"
                    name="voter_address"
                    title="Registration address"
                    placeholder=""
                    value={issueData.voter_address || ''}
                    onChange={onInputChange}
                    errors={errors.voter_address}
                    disabled={isVoterImpactedReadOnly}
                  />
                )}
                <IssueStructuredVoterAddress
                  issueData={issueData}
                  onInputChange={onInputChange}
                  onSetFields={onSetFields}
                  formType={formType}
                  // isVoterImpactedReadOnly refers to whether
                  // the voter data fields are enabled.
                  // if isVoterImpactedReadOnly is true,
                  // the issue voter fields are not enabled,
                  // and canUpdateVoterAddress is false.
                  canUpdateVoterAddress={!isVoterImpactedReadOnly}
                />
              </div>
            </div>
            {action === 'edit' && this.renderIssueTypeCard()}
          </div>
          {action === 'create' && (
            <div className="col">{this.renderIssueTypeCard()}</div>
          )}
          <div className="col">
            <div className="hotline-card mobile">
              <div
                className="header subheading link"
                onClick={() =>
                  this.setState({
                    showDetailedVoterInfo: !this.state.showDetailedVoterInfo,
                  })
                }
              >
                Detailed Voter Information
                {this.state.showDetailedVoterInfo ? (
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 15l7-7 7 7"></path>
                  </svg>
                ) : (
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 9l-7 7-7-7"></path>
                  </svg>
                )}
              </div>
              <div className="microcopy">
                Add the voter’s registration address
              </div>
              <div
                className={cx({ hidden: !this.state.showDetailedVoterInfo })}
              >
                {action === 'create' && formType === 'hotline' && (
                  <div className="script">
                    What is the address where the voter is registered to vote?
                    This will help me check their registration.
                  </div>
                )}
                {(issueData.voter_address?.length ?? 0) > 0 && (
                  <TextArea
                    key="voter_address"
                    name="voter_address"
                    title="Registration address"
                    placeholder=""
                    value={issueData.voter_address || ''}
                    onChange={onInputChange}
                    errors={errors.voter_address}
                    disabled={isVoterImpactedReadOnly}
                  />
                )}
                <IssueStructuredVoterAddress
                  issueData={issueData}
                  onInputChange={onInputChange}
                  onSetFields={onSetFields}
                  formType={formType}
                  // isVoterImpactedReadOnly refers to whether
                  // the voter data fields are enabled.
                  // if isVoterImpactedReadOnly is true,
                  // the issue voter fields are not enabled,
                  // and canUpdateVoterAddress is false.
                  canUpdateVoterAddress={!isVoterImpactedReadOnly}
                />
              </div>
            </div>
            <div className="hotline-card">
              <div className="header">
                {action === 'create' && <span className="number">3</span>}Notes
                & Details
              </div>
              <div className="subheading">Notes</div>
              <div className="microcopy">
                {action === 'create' &&
                  formType === 'hotline' &&
                  `Use this space to describe the caller's issue. `}
                These notes are visible to anyone with permission to read this
                issue. Include any information needed for follow up.
              </div>
              <IssueComments
                // We assume that when creating a new issue there won’t be
                // existing comments to render, so we set to [] since the new
                // issue in progress’s type for comments doesn’t work with our
                // components.
                comments={action === 'edit' ? issueData.comments : []}
                onCommentAdd={onCommentAdd}
                // We assume that when creating a new issue there won’t be
                // existing comments, so there won’t be anything to reply to.
                onReplyAdd={
                  this.props.action === 'edit'
                    ? this.props.onReplyAdd
                    : undefined
                }
                onCommentChange={onCommentChange}
                commentText={commentText || ''}
                title={this.props.action === 'edit' ? 'Add Note' : ''}
                isAddForm={this.props.action === 'edit'}
                canAddNote={canAddToIssue}
              />
              <Input
                name="voter_email"
                title={
                  formType === 'hotline'
                    ? "Caller's Email Address"
                    : "Voter's Email Address"
                }
                placeholder={
                  formType === 'hotline' ? 'If the caller prefers email' : ''
                }
                value={issueData.voter_email || ''}
                onChange={onInputChange}
                disabled={isVoterImpactedReadOnly}
                errors={errors.voter_email}
              />
              <div className="subheading">Attachments</div>
              {action === 'create' ? (
                // These blocks are very similar, but have completely different
                // types due to the conditional on action.
                <>
                  <IssuePhotos
                    isNewIssue={true}
                    photos={issueData.photos}
                    onPhotoAdd={onPhotoAdd}
                    onPhotoDelete={onPhotoDelete}
                    uploadIsPending={photoUploadIsPending}
                    canAddPhoto={canAddToIssue}
                  />
                  <IssueDocuments
                    isNewIssue={true}
                    documents={issueData.documents}
                    onDocumentAdd={onDocumentAdd}
                    onDocumentDelete={onDocumentDelete}
                    uploadIsPending={documentUploadIsPending}
                    canAddDocument={canAddToIssue}
                  />
                </>
              ) : (
                <>
                  <IssuePhotos
                    isNewIssue={false}
                    photos={issueData.photos}
                    onPhotoAdd={onPhotoAdd}
                    onPhotoDelete={onPhotoDelete}
                    uploadIsPending={photoUploadIsPending}
                    canAddPhoto={canAddToIssue}
                  />
                  <IssueDocuments
                    isNewIssue={false}
                    documents={issueData.documents}
                    onDocumentAdd={onDocumentAdd}
                    onDocumentDelete={onDocumentDelete}
                    uploadIsPending={documentUploadIsPending}
                    canAddDocument={canAddToIssue}
                  />
                </>
              )}
            </div>
            {action === 'create' && (
              <RenderOnMobile>
                <div className="new-issue-header">
                  {this.renderNewIssueButtons(this.props)}
                  {this.renderNewIssueToastData(this.props)}
                </div>
              </RenderOnMobile>
            )}
          </div>
          {this.props.action === 'edit' && (
            <div className="col">
              <div className="hotline-card">
                <div className="header">Other Issue Details</div>
                {this.props.isPriorityVisible && (
                  <Autocomplete
                    type="select"
                    name="priority"
                    title="Priority"
                    choices={issuePriorities}
                    value={this.props.issueData.priority ?? ''}
                    onChange={onInputChange}
                    disabled={isDescriptionReadOnly}
                    errors={errors.priority}
                  />
                )}
                {this.props.isObserverVisible && (
                  <IssueObserver
                    issueData={this.props.issueData}
                    errors={errors}
                    onInputChange={onInputChange}
                    readOnly={this.props.isObserverReadOnly}
                    preloadedUsers={this.props.preloadedUsers}
                  />
                )}
                <IssueAssignmentContainer
                  onClaimIssue={this.props.onClaimIssue}
                  onEscalateIssue={this.props.onEscalateIssue}
                  onInputChange={onInputChange}
                  issueData={this.props.issueData}
                  errors={errors}
                  preloadedUsers={this.props.preloadedUsers}
                  actions={commonActions}
                  boilerRoomList={this.props.boilerRoomList}
                  electionList={this.props.electionList}
                  currentUserRole={this.props.currentUserData.get('role')}
                />
                {this.props.canSeeQa && (
                  <div className="spacer">
                    <hr />
                    <ToggleInput
                      title="QA Review? (BFP Only)"
                      name="qa_reviewed"
                      checked={this.props.issueData.qa_reviewed || false}
                      onChange={onInputChange}
                      errors={errors.qa_reviewed}
                    />
                  </div>
                )}
              </div>
              <div>
                <RenderOnMobile>
                  {this.renderExistingIssueSubmitAndResolveButtons(this.props)}
                  {this.renderExistingIssueToastData(this.props)}
                </RenderOnMobile>
              </div>

              <div className="hotline-card">
                <div className="header">Issue History</div>
                <IssueHistory
                  issueInProgress={this.props.issueInProgress}
                  historyIsLoading={this.props.historyIsLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

/** The props type for IssueDetailForm, assuming we’re in create mode. */
type NewIssueProps = IssueDetailForm['props'] & {
  action: 'create';
};

/** The props type for IssueDetailForm, assuming we’re in edit mode. */
type EditIssueProps = IssueDetailForm['props'] & {
  action: 'edit';
};

export type CommonIssueFormActions = {
  /**
   * Called when a new election is selected to update the issue election, which
   * is stored separately in Redux.
   */
  updateIssueElection(electionId: number): void;
  /**
   * Called when the chosen state changes and we need to find a new election ID
   * to associate the issue with.
   */
  issueStateChanged(state: State | null): void;

  showToast(data: ToastData): void;
  dismissToast(): void;
};

export function makeCommonIssueFormActions(
  dispatch: AppDispatch
): CommonIssueFormActions {
  return {
    updateIssueElection(electionId) {
      const { setIssueElection } = issueActionCreators;
      dispatch(setIssueElection(electionId));
    },

    issueStateChanged(state) {
      const { getIssueElectionAsync } = issueActionCreators;
      dispatch(getIssueElectionAsync(state ?? ''));
    },

    showToast(data: ToastData) {
      const { showToast } = issueActionCreators;
      dispatch(showToast(data));
    },

    dismissToast() {
      const { dismissToast } = issueActionCreators;
      dispatch(dismissToast());
    },
  };
}
