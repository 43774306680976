import * as Immutable from 'immutable';

import {
  AssignmentViewType,
  BoilerRoomLevel,
  IncidentIssueCategories,
  InquiryIssueCategories,
  IssuePriority,
  IssueScope,
  IssueSource,
  IssueStatus,
  IssueViewType,
  State,
  UserRole,
  VoteStatus,
  WaitTime,
} from '../../constants';
import { CountySlug, DateString } from '../../services/common';
import { AnalyticsView } from '../../utils/analytics/query-params';
import { MapFromJs } from '../../utils/types';
import { AppState, AppThunk } from '../flux-store';
import { CurrentUser } from '../user/action-creators';

import actionTypes from './action-types';

const { ADD_FILTER, REMOVE_FILTER, SET_CURRENT_USER_DEFAULTS, SET_FILTERS } =
  actionTypes;

// "any" so we don’t have to deal with type parameters. This is safeish enough
// because we only create these through the functions below that ensure they’re
// typechecked.
export type Action =
  | AddFilterAction<any, any>
  | RemoveFilterAction<any, any>
  | SetFiltersAction<any>
  | SetCurrentUserDefaultsAction;

/**
 * Type of filters allowed for each app section.
 *
 * Currently fuzzy because this was just copied from `initialState`.
 *
 * TODO(fiona): This should all be cleaned up (along with the map* functions in
 * util) to make it clear:
 *
 *  - What values flow from query parameters
 *  - What values are stored in state
 *  - What values are derived
 *  - What values are needed for API requests
 *
 * The current code is fairly fast-and-loose with all of that, and does almost
 * no validation of the values coming in from query parameters.
 *
 * One consistency is that all of the truthy values in these filters appear to
 * be strings.
 */
export type FiltersBySection = {
  ISSUE: {
    view?: IssueViewType;

    state?: State | null | undefined;
    county?: CountySlug | '';
    query_election_id?: string | null;
    ordering?: '-id';
    group_by?: 'precinct';
    from_permanent?: 'True' | '';
    requires_followup?: 'True' | '';
    region?: string;
    status?: IssueStatus | '';
    precinct?: string | undefined;
    location?: string | undefined;
    priority?: `${IssuePriority}` | '';
    /** Only settable during a national election. */
    qa_reviewed?: '';
    category?: InquiryIssueCategories | IncidentIssueCategories | '';

    // These other category bits are referenced in mapStateToIssueFilters but
    // filtering may not work like this anymore.
    sub_category?: string;
    category_2?: InquiryIssueCategories | IncidentIssueCategories | '';
    sub_category_2?: string;
    category_3?: InquiryIssueCategories | IncidentIssueCategories | '';
    sub_category_3?: string;

    source?: IssueSource;
    type__in?: string;
    scope?: IssueScope;
    voter_name?: string;
    voter_phone_number?: string;
    owner?: string;
    owner__isnull?: 'True' | '';
    boiler_room?: string;
    created_by?: string;
    offset?: `${number}`;
    size?: `${number}`;
    user?: string;
    search_term?: string;
    wait_time?: WaitTime;
    watchers?: string;
    vote_status?: VoteStatus;
    boiler_room__level?: BoilerRoomLevel;

    us_house?: string;
    state_house?: string;
    state_senate?: string;

    start_date?: string;
    end_date?: string;

    // These two are derived and may only be necessary for the API
    incident_time__gte?: string; // ISO8601 | ''
    incident_time__lt?: string; // ISO8601 | ''

    // Used when we request the issues index since these are shown on the
    // results page instead.
    exclude_category?: string;
  };
  ASSIGNMENT: {
    view: AssignmentViewType;

    dates?: DateString;
    state?: State | null | undefined;
    county?: CountySlug | '';
    assignment_state?: State | null | undefined;
    tier?: string;
    location_exists?: 'true' | '';
    name?: string;
    offset?: string;
    size?: string;
    precinct?: string;
    location?: string;
    coverage?: string;
    has_checked_in_assignments?: 'checkin' | 'checkout' | 'true' | 'false' | '';
    name_prefix?: string;
    tags?: string;
    assigned?: string;
  };
  CHECKINS: {
    state?: State | null;
    county?: CountySlug | '';
  };
  DASHBOARD: {};
  ANALYTICS: {
    date_lte?: DateString;
    date_gte?: DateString | null;
    state?: State | null | undefined;
    county?: CountySlug | '';
    ordering?: 'Name';
    group_by?: 'category' | 'region';
    query_election_id?: string | null;
    view?: AnalyticsView;
    precinct?: string;
    category?: string;
    subcategory?: string;
    column?: string;
  };
  PROFILE: {
    dates?: DateString;
    assignment_state?: State | '';
  };
  USER: {
    // TODO(fiona): Figure out what’s going on with 'ASSIGNMENT_NOTIFY' being
    // unlike the others.
    view: 'details' | 'assignments' | 'ASSIGNMENT_NOTIFY';

    assignment_state?: State | '' | undefined;
    state?: State | '' | undefined;
    county?: CountySlug;
    region?: string;
    assigned?: 'true' | 'false' | '';
    search?: string;
    role?: UserRole | undefined;
    tags?: string | undefined;
    size?: string;
    offset?: string;
    invitation_status?: string;
    has_received_availability_survey?: 'true' | 'false' | '';
    has_checked_in?: 'true' | 'false' | '';
    has_received_training_invite?: 'true' | 'false' | '';
    needs_assignment_email?: 'true' | 'false' | '';

    // This is referenced in user/query-params but unclear if it’s being used
    dates?: string;

    currently_assigned_to_boiler_room?: string;
  };
  NOTIFICATIONS: {
    state?: State | '' | undefined;
    county?: CountySlug;
    size?: string;
    offset?: string;
    created_date__lte?: string;
    seen?: string;
    acked?: string;
  };
};

export type AddFilterAction<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
> = {
  type: typeof ADD_FILTER;
  data: {
    filterAttr: A;
    filterVal: FiltersBySection[S][A];
    appSection: S;
  };
};

/**
 * Sets a filter to the given value.
 */
export function addFilter<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
>(
  filterAttr: A,
  filterVal: FiltersBySection[S][A],
  appSection: S
): AddFilterAction<S, A> {
  return {
    type: ADD_FILTER,
    data: {
      filterAttr,
      filterVal,
      appSection,
    },
  };
}

export type RemoveFilterAction<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
> = {
  type: typeof REMOVE_FILTER;
  data: {
    filterAttr: A;
    appSection: S;
  };
};

/**
 * Deletes a filter value completely.
 */
export function removeFilter<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
>(filterAttr: A, appSection: S): RemoveFilterAction<S, A> {
  return {
    type: REMOVE_FILTER,
    data: {
      filterAttr,
      appSection,
    },
  };
}

export type SetCurrentUserDefaultsAction = {
  type: typeof SET_CURRENT_USER_DEFAULTS;
  data: {
    currentUser: MapFromJs<CurrentUser>;
  };
};

/**
 * Updates the filters based on a new current user.
 *
 * In practice, this means updating every `state` or `assignment_state` filter
 * to match the user’s `assignment_state`.
 *
 * TODO(fiona): This should switch to be specifically about states and also
 * derived from the currently-selected election rather than the deprecated
 * `assignment_state` and also we should get rid of the filters module anyway.
 */
export function setCurrentUserDefaults(
  currentUser: MapFromJs<CurrentUser>
): SetCurrentUserDefaultsAction {
  return {
    type: SET_CURRENT_USER_DEFAULTS,
    data: {
      currentUser,
    },
  };
}

export type SetFiltersAction<S extends keyof FiltersBySection> = {
  type: typeof SET_FILTERS;
  data: {
    appSection: S;
    filters: Immutable.Map<
      keyof FiltersBySection[S],
      // All of the filter values are strings or falsey
      string | null | undefined
    >;
  };
};

/**
 * Bulk-updates filters for a given app section.
 *
 * These are merged over the initial filter state values, then set for the app
 * section.
 */
export function setFilters<S extends keyof FiltersBySection>(
  filters: Partial<FiltersBySection[S]>,
  appSection: S
): SetFiltersAction<S> {
  return {
    type: SET_FILTERS,
    data: {
      appSection,
      filters: Immutable.fromJS(filters) as Immutable.Map<
        keyof FiltersBySection[S],
        string | null | undefined
      >,
    },
  };
}

/**
 * Sets filters for the app section based on the query params, merging with
 * initial state defaults as necessary. Once all parts of the app are syncing
 * filter state with browser history, we can ditch a lot of the filter
 * setting/removal methods.
 */
export function setFiltersAsync<S extends keyof FiltersBySection>(
  filters: Partial<FiltersBySection[S]>,
  appSection: S
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(setFilters(filters, appSection));

      resolve(getState());
    });
  };
}

export function addFilterAsync<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
>(
  filterAttr: A,
  filterVal: FiltersBySection[S][A],
  appSection: S
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(addFilter(filterAttr, filterVal, appSection));

      resolve(getState());
    });
  };
}

export function removeFilterAsync<S extends keyof FiltersBySection>(
  filterAttr: keyof FiltersBySection[S],
  appSection: S
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(removeFilter(filterAttr, appSection));

      resolve(getState());
    });
  };
}
