import moment from 'moment';
import React from 'react';

import {
  State,
  UserRole,
  WaitTime,
  WAIT_TIME_CHOICES,
  WAIT_TIME_DISPLAY,
} from '../../../constants';
import { ToastRecord } from '../../../modules/toast';
import { ApiCheckinWithLocation } from '../../../services/checkin-service';
import { PageHeader } from '../../common';
import { DetailedUserWithAssignments } from '../../hooks/user-data';
import RadioInput from '../form/radio';
import SelectInput, { SEPARATOR_VALUE } from '../form/select';
import UserAutocomplete from '../form/user-autocomplete';
import LoadingOverlay from '../lbj/loading';
import Toast from '../lbj/toast';

import LocationAutocomplete, {
  LocationAutocompleteDispatch,
  LocationAutocompleteState,
} from './location-autocomplete';

/** User roles that are allowed to check in other people. */
export const CHECK_IN_OTHERS_ROLES = [
  'boiler_room_user',
  'boiler_room_leader',
  'deputy_vpd',
  'vpd',
];

type YesNoUnknown = 'yes' | 'no' | 'unknown';

const YES_NO_UNKNOWN_CHOICES = {
  yes: 'Yes',
  no: 'No',
  unknown: 'Not sure',
};

/**
 * We use a special value called '-unset-' rather than '' because <SelectInput>
 * is not principled about differentiating between undefined and '' so passing
 * '' makes it uncontrolled (meaning we can never programmatically reset it).
 */
export const UNSET_VALUE = '-unset-';

/**
 * State of the non-location, non-user parts of the checkin form. Uses a type
 * union to enforce that the questions may only be answered when the `waitTime`
 * is “complete”.
 */
export type CheckinFormState =
  | {
      waitTime: Exclude<WaitTime, 'complete'> | typeof UNSET_VALUE;
      isCountingFinished: null;
      isResultsAnnounced: null;
    }
  | {
      waitTime: 'complete';
      isCountingFinished: YesNoUnknown | null;
      isResultsAnnounced: YesNoUnknown | null;
    };

/**
 * We thought Florida didn’t want this, but it turns out they do?
 *
 * Leaving the mechanisms in place for now in case anyone asks.
 *
 * https://democrats.atlassian.net/browse/LBJ-284
 */
const NO_NEW_FEATURES_STATES: State[] = [];

/**
 * Updated version of the checkin page that also handles checkout and other
 * post-voting questions.
 */
const CheckinForm: React.FunctionComponent<{
  /** True if the form is currently submitting. */
  isSubmitting: boolean;

  /** Role of the logged-in user. */
  currentUserRole: UserRole;

  /**
   * The user being checked in.
   *
   * Poll observers aren’t allowed to change this and will always check in as
   * themselves, but VPDs and others are allowed to change this to make checkins
   * for other users.
   */
  selectedUserId: number | null;
  setSelectedUserId: (userId: number | null) => void;

  /**
   * The loaded version of selectedUserId. We need this so we can show the
   * user’s assigned location(s) as options in the location picker.
   *
   * The assignments should be filtered to the current day.
   *
   * Use `undefined` if it’s still loading, `null` if there is no current
   * selected user.
   */
  selectedUser: DetailedUserWithAssignments | null | undefined;

  /**
   * Most recent check-in for the selected user, filtered to the current day.
   */
  lastCheckin: ApiCheckinWithLocation | null | undefined;

  formState: CheckinFormState;
  updateFormState: ((updates: Partial<CheckinFormState>) => void) | null;

  /**
   * Submits a check-in for the current form state. Should return true if
   * successful, false if error.
   *
   * Is `null` if the current state is invalid.
   */
  submitCheckIn: ((isCheckOut: boolean) => void) | null;

  toastData: ToastRecord;
  onDismissToast: () => void;

  locationAutocompleteState: LocationAutocompleteState;
  locationAutocompleteDispatch: LocationAutocompleteDispatch;

  now?: moment.Moment;
}> = ({
  isSubmitting,
  currentUserRole,

  selectedUserId,
  setSelectedUserId,
  selectedUser,

  submitCheckIn,
  lastCheckin,
  toastData,
  onDismissToast,

  locationAutocompleteState,
  locationAutocompleteDispatch,

  formState: { isCountingFinished, isResultsAnnounced, waitTime },
  updateFormState: updateFormState,

  now = moment(),
}) => {
  const mayCheckInOthers = CHECK_IN_OTHERS_ROLES.includes(currentUserRole);

  const onSubmit = submitCheckIn
    ? (isCheckOut: boolean, ev: React.MouseEvent) => {
        ev.preventDefault();
        submitCheckIn(isCheckOut);
      }
    : undefined;

  // We allow for updates if there already was a checkin today and it is from
  // the same location.
  const isUpdateOfCheckin =
    lastCheckin?.type === 'checkin' &&
    lastCheckin.location.id === locationAutocompleteState.location?.id;

  const isLoadingData = selectedUser === undefined || lastCheckin === undefined;

  const hideNewFeatures = !!(
    locationAutocompleteState.selectedState &&
    NO_NEW_FEATURES_STATES.includes(locationAutocompleteState.selectedState)
  );

  return (
    <div className="lbj-page-wrapper">
      <PageHeader title="Check-in" />

      {isSubmitting && <LoadingOverlay text="Saving…" />}
      {!isSubmitting && isLoadingData && <LoadingOverlay />}

      {lastCheckin &&
        (() => {
          const hoursAgo = now.diff(moment(lastCheckin.time), 'hours');
          const minutesAgo = now.diff(moment(lastCheckin.time), 'minutes');

          return (
            <div className="checkinSection">
              <div className="checkinHeader">
                Last{' '}
                {lastCheckin.type === 'checkin' ? 'checked in' : 'checked out'}{' '}
                {hoursAgo > 0 ? `${hoursAgo}h` : `${Math.max(1, minutesAgo)}m`}{' '}
                ago:
              </div>
              <div className="checkinInfo">
                {lastCheckin.location.name}
                {' — '}
                {moment(lastCheckin.time).format('h:mma')}
                <br />
                {lastCheckin.wait_time !== 'unknown' && (
                  <i>{WAIT_TIME_DISPLAY[lastCheckin.wait_time] || '-'}</i>
                )}
              </div>
            </div>
          );
        })()}

      <form className="checkinSection">
        <Toast toastData={toastData} onDismiss={onDismissToast} />

        {mayCheckInOthers && (
          <UserAutocomplete
            title="Observer"
            userId={selectedUserId}
            setUserId={setSelectedUserId}
            preloadedUsers={[selectedUser]}
            required
          />
        )}

        <LocationAutocomplete
          state={locationAutocompleteState}
          dispatch={locationAutocompleteDispatch}
        />

        <SelectInput
          title="How long is the current wait time?"
          name="wait-time"
          onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
            updateFormState?.({
              waitTime: ev.target.value as WaitTime | '-unset-',
            })
          }
          value={waitTime}
          choices={makeWaitTimeSelectChoices(hideNewFeatures)}
          required
          disabled={updateFormState === null}
        />

        {waitTime === 'complete' && (
          <>
            <RadioInput
              title="Has the polling place finished counting ballots?"
              name="counting"
              value={isCountingFinished}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                updateFormState?.({
                  isCountingFinished: ev.currentTarget.value as YesNoUnknown,
                })
              }
              choices={YES_NO_UNKNOWN_CHOICES}
              required
              disabled={updateFormState === null}
            />

            <RadioInput
              title="Has the polling place announced results?"
              name="results"
              value={isResultsAnnounced}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                updateFormState?.({
                  isResultsAnnounced: ev.currentTarget.value as YesNoUnknown,
                })
              }
              choices={YES_NO_UNKNOWN_CHOICES}
              required
              disabled={updateFormState === null}
            />
          </>
        )}

        <button
          className="checkinButton c-button-wide c-button-secondary c-button-large"
          disabled={!onSubmit}
          type="submit"
          onClick={onSubmit?.bind(null, false)}
        >
          {isUpdateOfCheckin ? 'Update' : 'Check In'}
        </button>

        {isUpdateOfCheckin && !hideNewFeatures && (
          <button
            className="checkinButton c-button-wide c-button-primary c-button-large"
            disabled={!onSubmit}
            type="submit"
            onClick={onSubmit?.bind(null, true)}
          >
            Update and Check Out
          </button>
        )}
      </form>
    </div>
  );
};

/**
 * Based on {@link WAIT_TIME_CHOICES} but adds separators between “-” and line
 * length options, and another before the “voting has finished” option.
 */
function makeWaitTimeSelectChoices(hideNewFeatures: boolean) {
  const { complete, unknown, ...choices } = WAIT_TIME_CHOICES;

  let choiceOptions: { [key: string]: string } = {
    '-unset-': '-',
    sep1: SEPARATOR_VALUE,
    ...choices,
    unknown,
  };

  if (!hideNewFeatures) {
    choiceOptions = {
      ...choiceOptions,
      sep2: SEPARATOR_VALUE,
      complete,
    };
  }

  return choiceOptions;
}

export default CheckinForm;
