import * as Immutable from 'immutable';
import moment from 'moment';
import React from 'react';

import { assignmentViewTypes, lbjAppSections } from '../../../constants';
import CountyFilterContainer from '../../containers/form/county-filter-container';
import StateFilterContainer from '../../containers/form/state-filter-container';
import TagPicker from '../../containers/form/tag-picker-container';
import Autocomplete from '../form/autocomplete';
import DebouncedInput from '../form/debounced-input';
import Select from '../form/select';
import Toggle from '../form/toggle';

import CalendarFilter from './assignment-calendar-filter';

const { ASSIGNMENT } = lbjAppSections;
const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
} = assignmentViewTypes;

export default class FilterBar extends React.Component<{
  filters: Immutable.Map<string, string | null | undefined>;
  onFilterChange: (ev: {
    target: { name: string; value: string | null | undefined };
  }) => void;
}> {
  onTagsChange(selectedTags: Immutable.Set<string>) {
    this.props.onFilterChange({
      target: { name: 'tags', value: selectedTags.join(',') },
    });
  }

  renderPeopleFilters() {
    const { filters, onFilterChange } = this.props;
    const tags = filters.get('tags');
    const allTagsList = tags ? tags.split(',') : [];
    const dateString = moment(filters.get('dates')).format('MMM Do');

    return (
      <div className="assignments-filter-container">
        <div className="assignments-filter-option option-lg">
          <DebouncedInput
            title="Search by volunteer"
            name="name_prefix"
            placeholder="Find a person"
            defaultValue={filters.get('name_prefix')}
            onChange={onFilterChange}
          />
        </div>

        {/* <div className="assignments-filter-option">
          <StateFilterContainer
            includeNational
            name="assignment_state"
            title="Assignment State"
            appSection={lbjAppSections.ASSIGNMENT}
            onChange={onFilterChange} />
        </div> */}

        <div className="assignments-filter-option">
          <TagPicker
            title="Volunteer Roles"
            name="availability"
            onChange={this.onTagsChange.bind(this)}
            onlyTypes={['availability']}
            defaultValue={filters.get('tags')}
            allTags={allTagsList}
          />
        </div>

        <div className="assignments-filter-option">
          <Autocomplete
            name="assigned"
            title={`Assigned on ${dateString}`}
            value={filters.get('assigned') ?? ''}
            onChange={onFilterChange}
            choices={{
              true: 'Yes',
              false: 'No',
            }}
          />
        </div>

        <div className="assignments-filter-option">
          <TagPicker
            title="Languages"
            name="languages"
            onChange={this.onTagsChange.bind(this)}
            onlyTypes={['language']}
            defaultValue={filters.get('tags')}
            allTags={allTagsList}
          />
        </div>

        <div className="assignments-filter-option">
          <TagPicker
            title="Tags"
            name="tag"
            onChange={this.onTagsChange.bind(this)}
            onlyTypes={['other', 'capability', 'travel_distance']}
            defaultValue={filters.get('tags')}
            allTags={allTagsList}
          />
        </div>

        <div className="assignments-filter-option">
          {this.renderCheckinFilter()}
        </div>

        {/* <div className="assignments-filter-option">
          {this.renderCalendarFilter()}
        </div> */}
      </div>
    );
  }

  renderPrecinctFilters() {
    const { filters, onFilterChange } = this.props;

    return (
      <div className="assignments-filter-container">
        <div className="assignments-filter-option option-lg">
          {this.renderSearchByPrecinctFilter()}
        </div>

        <div className="assignments-filter-option option-lg">
          {this.renderCountyFilter()}
        </div>

        <div className="assignments-filter-option">
          <Toggle
            name="location_exists"
            title="With Precinct Locations"
            checked={!!filters.get('location_exists')}
            onChange={onFilterChange}
          />
        </div>
      </div>
    );
  }

  renderBoilerFilters() {
    const { filters, onFilterChange } = this.props;

    return (
      <div className="assignments-filter-container">
        <div className="assignments-filter-option option-lg">
          <DebouncedInput
            title="Find a boiler room"
            name="name"
            placeholder="Find an boiler room"
            defaultValue={filters.get('name')}
            onChange={onFilterChange}
          />
        </div>
        <div className="assignments-filter-option">
          <StateFilterContainer
            includeNational
            appSection={ASSIGNMENT}
            onChange={onFilterChange}
          />
        </div>
        {/* <div className="assignments-filter-option">
          {this.renderCalendarFilter()}
        </div> */}
      </div>
    );
  }

  renderBoardOfElectionsFilters() {
    const onFilterChange = this.props.onFilterChange;

    return (
      <div className="assignments-filter-container">
        <div className="assignments-filter-option">
          <StateFilterContainer
            includeNational
            appSection={ASSIGNMENT}
            onChange={onFilterChange}
          />
        </div>

        <div className="assignments-filter-option">
          {this.renderCountyFilter()}
        </div>

        {/* <div className="assignments-filter-option">
          {this.renderCalendarFilter()}
        </div> */}
      </div>
    );
  }

  renderLocationFilters() {
    const { filters, onFilterChange } = this.props;

    return (
      <div className="assignments-filter-container">
        <div className="assignments-filter-option option-lg">
          {this.renderSearchByLocationFilter()}
        </div>

        <div className="assignments-filter-option option-lg">
          {this.renderCountyFilter()}
        </div>

        <div className="assignments-filter-option">
          <Autocomplete
            name="tier"
            title="Tier"
            defaultValue={filters.get('tier') ?? ''}
            onChange={onFilterChange}
            multi
            choices={{
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            }}
          />
        </div>

        <div className="assignments-filter-option">
          <Autocomplete
            name="coverage"
            title="Coverage"
            defaultValue={filters.get('coverage') ?? ''}
            onChange={onFilterChange}
            multi
            choices={{
              unknown: 'Unknown',
              none: 'None',
              partial: 'Partial',
              full_rover: 'Full Rover',
              full: 'Full',
            }}
          />
        </div>

        <div className="assignments-filter-option">
          {this.renderCheckinFilter()}
        </div>

        {/* <div className="assignments-filter-option">
          {this.renderCalendarFilter()}
        </div> */}
      </div>
    );
  }

  renderCheckinFilter() {
    const { filters, onFilterChange } = this.props;

    return (
      <Select
        name="has_checked_in_assignments"
        title="Check-in status"
        onChange={onFilterChange}
        value={filters.get('has_checked_in_assignments')}
        choices={{
          '': '-',
          checkin: 'Checked in',
          checkout: 'Checked out',
          false: 'Not checked in or out',
        }}
      />
    );
  }

  renderCalendarFilter() {
    const { filters, onFilterChange } = this.props;

    return (
      <CalendarFilter
        filters={filters}
        onFilterChange={onFilterChange}
        multiDay={false}
      />
    );
  }

  renderCountyFilter() {
    const onFilterChange = this.props.onFilterChange;

    return (
      <CountyFilterContainer
        appSection={lbjAppSections.ASSIGNMENT}
        onChange={onFilterChange}
      />
    );
  }

  renderSearchByLocationFilter() {
    const { filters, onFilterChange } = this.props;

    return (
      <DebouncedInput
        title="Search by Location"
        name="name"
        type="text"
        placeholder="Find a location"
        defaultValue={filters.get('name')}
        onChange={onFilterChange}
      />
    );
  }

  renderSearchByPrecinctFilter() {
    const { filters, onFilterChange } = this.props;

    return (
      <DebouncedInput
        title="Search by Precinct"
        name="name"
        type="text"
        placeholder="Find a precinct"
        defaultValue={filters.get('name')}
        onChange={onFilterChange}
      />
    );
  }

  render() {
    const { filters } = this.props;

    switch (filters.get('view')) {
      case PEOPLE:
        return this.renderPeopleFilters();

      case PRECINCTS:
        return this.renderPrecinctFilters();

      case BOILER_ROOMS:
      case HOTLINES:
        return this.renderBoilerFilters();

      case BOARDS_OF_ELECTIONS:
        return this.renderBoardOfElectionsFilters();

      case LOCATIONS:
      default:
        return this.renderLocationFilters();
    }
  }
}
