import React from 'react';

import TextArea from '../../components/presentational/form/textarea';
import { ApiExpandedIssueComment } from '../../services/issue-service';
import nestComments, { FindReplyResult } from '../../utils/issue/nest-comments';

import IssueComment from './issue-comment';

const MAX_COMMENT_DEPTH = 2; // FIXME where do constants like this belong?

export default class IssueComments extends React.Component<
  {
    comments: ApiExpandedIssueComment[] | undefined;
    commentText: string | undefined;
    onCommentChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    onCommentAdd: () => void;
    onReplyAdd: ((parentId: number, commentText: string) => void) | undefined;
    /**
     * True if the form is being used to add a comment (e.g. on the edit form)
     * vs. just being a notes text box.
     */
    isAddForm: boolean;
    maxCommentDepth?: number | undefined;
    placeholder?: string | undefined;
    title: string;
    /**
     * False if the user is view-only (very limited issue permissions),
     * versus other user types.
     */
    canAddNote: boolean;
  },
  { commentText: string }
> {
  constructor(props: IssueComments['props']) {
    super(props);
    this.renderCommentList = this.renderCommentList.bind(this);
    this.state = {
      commentText: '',
    };
  }

  canMakeAdditionalReply(depth: number) {
    const { canAddNote } = this.props;
    return (
      depth < (this.props.maxCommentDepth ?? MAX_COMMENT_DEPTH) && canAddNote
    );
  }

  renderCommentList(comments: FindReplyResult[], depth: number) {
    return (
      <div className="issue-comments-list">
        {comments.map((c, i) => (
          <IssueComment
            renderCommentList={this.renderCommentList}
            canReplyTo={this.canMakeAdditionalReply(depth)}
            onReplyAdd={this.props.onReplyAdd}
            key={i}
            depth={depth}
            replies={c.replies}
            {...c.comment}
          />
        ))}
      </div>
    );
  }

  render() {
    const {
      comments,
      commentText,
      onCommentChange,
      onCommentAdd,
      isAddForm,
      placeholder,
      title,
      canAddNote,
    } = this.props;
    const nestedComments = nestComments(comments ?? []);

    return (
      <div className="issue-comments">
        {this.renderCommentList(nestedComments, 0)}

        {canAddNote && (
          <div className="issue-comments-input">
            <TextArea
              name="comment"
              title={title}
              placeholder={placeholder || ''}
              value={commentText}
              onChange={onCommentChange}
            />

            {isAddForm && (
              <button
                type="button"
                className="lbj-comment-button"
                onClick={() => onCommentAdd()}
              >
                Add Note
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}
