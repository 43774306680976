/*
 * Utilities for serializing into CPLEX LP format and parsing out CBC’s bespoke
 * text output.
 *
 * These types represent the subset of the LP format that we are actually using
 * in auto-assignment, which is why the only variables allowed are booleans and
 * the only constraints are max constraints.
 */

/**
 * Definition of a mixed-integer linear programming problem.
 */
export type LpProblem = {
  goal: 'minimize' | 'maximize';
  constraints: LpMaximumConstraint[];
  booleans: LpBooleanVariable[];
};

/**
 * Definition of a maximum constraint. The sum of the values of the provided
 * variables must be less than or equal to the maximum value.
 */
export type LpMaximumConstraint = {
  variableNames: string[];
  maximum: number;
};

/**
 * Definition of a boolean variable.
 */
export type LpBooleanVariable = {
  name: string;

  /** The value this variable has in the objective function for the problem. */
  coefficient: number;
};

/**
 * A result parsed from the CBC solver upon successful solve.
 */
export type LpSolution = {
  /**
   * The value of the objective function for the solution.
   */
  objectiveValue: number;

  /**
   * Information about the variables’ values in the chosen solution.
   */
  variables: LpSolutionValue[];
};

/**
 * Calculated value for a particular variable in an LP solution.
 *
 * Note that for variables with a value of 0 this data structure may or may not
 * be provided in {@link LpSolution}.
 */
export type LpSolutionValue = {
  /** Index of the variable, in the order provided to the solver. */
  index: number;

  /** Name of the variable. */
  name: string;

  /** Value the solver chose for the variable. */
  value: number;

  /** Coefficient for the variable in the original score function. */
  coefficient: number;
};

/**
 * Serializes an {@link LpProblem} into a string in CPLEX LP format, which is
 * the input format for the CBC solver.
 *
 * We use `.join('\n + ')` in here, with the newline, because CBC has a maximum
 * line length (and some of these constraints can get quite long.)
 *
 * @see https://web.mit.edu/lpsolve/doc/CPLEX-format.htm
 */
export function serializeLpProblem(problem: LpProblem): string {
  return `${problem.goal === 'maximize' ? 'Maximize' : 'Minimize'}
${problem.booleans
  .map(({ name, coefficient }) => `${coefficient} ${name}`)
  .join('\n  + ')}

Subject To
${problem.constraints
  .filter(({ variableNames: variables }) => variables.length > 0)
  .map(
    ({ variableNames: variables, maximum }) =>
      `${variables.join('\n  + ')} <= ${maximum}`
  )
  .join('\n')}

Binary
${problem.booleans.map(({ name }) => name).join('\n')}

End
`;
}

/**
 * Regexp to parse the objective value from the first line of the CBC solution
 * output.
 */
const OBJECTIVE_VALUE_REGEXP = /objective value (-?[0-9.]*)$/;

/**
 * Parses the result from the CBC solver.
 *
 * It’s in columns, but the spaces between those columns are inconsistent
 * (they’re chosen to make neat columns when viewed in an editor).
 *
 * The first column is the variable’s index, the second is the name, the third
 * is the value, and the 4th is the coefficient for that variable in the
 * objective. (We return all 4 columns for the sake of completely representing
 * the output, but the values of the coefficients are just parroting back out
 * what was provided in the original problem.)
 *
 * Depending on the number of variables, CBC may or may not output variables
 * with a value of 0 so make sure to check the value, rather than assuming
 * that all variables in the returned {@link LpSolution} are true.
 */
export function parseLpSolution(solutionTxt: string): LpSolution {
  /*
   solutionTxt is expected to look something like:

Optimal - objective value 24361.00000000
     10 LU~2994~2113               1                      85
     17 LU~2995~1822               1                      88
     35 LU~2996~2113               1                      66
     79 LU~2997~2071               1                      96
    130 LU~2998~2165               1                      91
…
  */
  const [firstLine, ...lines] = solutionTxt.split(/\r?\n/);

  if (!firstLine) {
    throw new Error('solutionTxt was empty');
  }

  const objectiveValueMatch = firstLine.match(OBJECTIVE_VALUE_REGEXP);

  if (!objectiveValueMatch) {
    throw new Error(
      `First line of solution had unexpected format: "${firstLine}"`
    );
  }

  const objectiveValue = parseFloat(objectiveValueMatch[1]!);

  return {
    objectiveValue,
    variables: lines
      .map((l) => l.trim())
      // Filter out blank lines for safety in case there’s one at the end.
      .filter((l) => !!l)
      .map<LpSolutionValue>((l) => {
        // There’s inconsistent whitespace between the columns on each row,
        // hence the \s+ regexp.
        const [indexStr, name, valueStr, coefficientStr] = l.split(/\s+/);

        if (!indexStr || !name || !valueStr || !coefficientStr) {
          throw new Error(`Malformed solution line: "${l}"`);
        }

        const index = parseInt(indexStr);
        const value = parseFloat(valueStr);
        const coefficient = parseFloat(coefficientStr);

        if (Number.isNaN(value) || Number.isNaN(coefficient)) {
          throw new Error(
            `Unexpected value ("${valueStr}") or coefficient ("${coefficient}") in: ${l}`
          );
        }

        return {
          index,
          name,
          value,
          coefficient,
        };
      }),
  };
}
