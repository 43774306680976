import { LbjAppSection, State } from '../../constants';
import { AppState } from '../../modules/flux-store';
import {
  CountyListRecord,
  CountyListRecordFactory,
} from '../../modules/lbj/reducers';

export default function mapStateToCounties(
  state: Pick<AppState, 'lbj' | 'filters'>,
  appSection: LbjAppSection,
  stateFilterKey = 'state'
): CountyListRecord | undefined {
  const { lbj, filters } = state;
  const stateFilter = filters.getIn([appSection, stateFilterKey]) as
    | State
    | undefined;

  // We never store counties w/o a state, so this case just returns empty.
  if (!stateFilter) {
    return CountyListRecordFactory();
  }

  // We allow this to return undefined so that callers can tell it hasn’t been
  // loaded and, if so, load it.
  return lbj.counties.get(stateFilter);
}
