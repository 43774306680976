import type { PressEvent } from '@react-types/shared';
import cx from 'classnames';
import React from 'react';
import { AriaButtonProps, mergeProps, useButton, useHover } from 'react-aria';

export type ActionButtonType = 'primary' | 'secondary' | 'toolbar';

type ActionButtonProps<T extends React.ElementType> = {
  buttonRef?: React.RefObject<HTMLElement> | undefined;
  role?: ActionButtonType | undefined;
  /** Props that are spread onto the <button> (or whatever) element. */
  elementProps?: React.ButtonHTMLAttributes<HTMLElement> | undefined;
  onPress?: ((e: PressEvent) => void) | undefined;
  isDisabled?: boolean;
  isSelected?: boolean;
  /** If true, the button gets width: 100%. */
  full?: boolean;
  /**
   * Name of a material-icons icon to use.
   *
   * TODO(fiona): Find a TypeScript list of all available icons.
   */
  icon?: string;
  iconPosition?: 'start' | 'end';
  iconClassName?: string;
} & Omit<AriaButtonProps<T>, 'onPress'>;

/**
 * Standard sort of push button.
 */
export function ActionButton<T extends React.ElementType = 'button'>({
  children,
  buttonRef,
  role = 'primary',
  full = false,
  elementProps = {},
  isDisabled = false,
  icon,
  iconPosition = 'start',
  iconClassName = '',
  onPress,
  isSelected = false,
  ...props
}: ActionButtonProps<T>) {
  const localRef = React.useRef<HTMLElement | null>(null);
  buttonRef = buttonRef ?? localRef;

  const { hoverProps, isHovered } = useHover({});
  const { buttonProps, isPressed } = useButton(
    {
      ...props,
      isDisabled,
      // weird little thing to work around our more stringent TypeScript
      // undefined semantics
      ...(onPress ? { onPress } : {}),
    },
    buttonRef
  );

  const renderAsPressed = isSelected || isPressed || props['aria-expanded'];

  const iconEl = icon && (
    <span
      className={cx('material-icons leading-none', iconClassName, {
        'text-lg': role === 'primary' || role === 'secondary',
        'text-xl': role === 'toolbar',
        'text-hyperlink': role === 'toolbar' && renderAsPressed,
        'text-gray-500': role === 'toolbar' && !renderAsPressed,
      })}
      aria-hidden
    >
      {icon}
    </span>
  );

  const Element = props.elementType ?? 'button';
  return (
    <Element
      ref={buttonRef as any}
      {...mergeProps(buttonProps, hoverProps)}
      {...elementProps}
      className={cx(
        elementProps.className,

        'flex cursor-default items-center',
        'whitespace-nowrap leading-none',

        // Need shadow-none to override default focus ring
        'shadow-none focus-visible:outline-dotted focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-hyperlink',

        'h-[2.25rem] border-solid',

        {
          'gap-3 rounded-lg border-2 px-4 text-sm font-bold uppercase':
            role === 'primary' || role === 'secondary',
          'gap-1 rounded border px-2 text-sm font-normal': role === 'toolbar',
        },

        {
          'w-full': full,
          'justify-between': icon,
          'justify-around': !icon,
        },

        // adds a slight “depressed” feeling, distinguishes between hover
        { 'pt-0.5': renderAsPressed },

        isDisabled
          ? {
              'border-neutral-300 bg-neutral-300 text-white':
                role === 'primary',
              'border-neutral-300 bg-white text-neutral-300':
                role === 'secondary',
              'border-gray-300 bg-white text-gray-300': role === 'toolbar',
            }
          : renderAsPressed || isHovered
          ? {
              'border-accent bg-accent text-white':
                role === 'primary' || role === 'secondary',
              'border-hyperlink bg-[#D9F1FF] text-gray-700': role === 'toolbar',
            }
          : {
              'border-hyperlink bg-hyperlink text-white': role === 'primary',
              'border-hyperlink bg-white text-hyperlink': role === 'secondary',
              'border-gray-300 bg-white text-gray-700': role === 'toolbar',
            }
      )}
    >
      {iconPosition === 'start' && iconEl}
      {children}
      {iconPosition === 'end' && iconEl}
    </Element>
  );
}
