import { Type } from '@sinclair/typebox';
import * as Immutable from 'immutable';

/**
 * Utility type for sommething we can call `await` on. Opposite of `Awaited`.
 */
export type Awaitable<T> = T | PromiseLike<T>;

/**
 * Does a deep merge of two types, with the latter overwriting the properties of
 * the former. Recurs into objects. Important to be able to switch, say, a
 * “user” property that’s deeply nested from a number to an object.
 */
export type DeepMerge2<T1, T2> = T1 extends object
  ? T2 extends object
    ? {
        // If they’re both objects, go through keys of T1, merging if they
        // appear in T2, otherwise picking T1’s type.
        [K in keyof T1]: K extends keyof T2 ? DeepMerge2<T1[K], T2[K]> : T1[K];
      } & Omit<T2, keyof T1> // Add in the properties from T2 that weren’t in T1
    : T2
  : T2;

/**
 * Extension of DeepMerge2 that works on any number of types.
 */
// Derived from: https://gist.github.com/acomagu/b50c4a5a8d9d5956bf9fc77ed1ed25ac
export type DeepMerge<T extends unknown[]> = {
  one: T[0];
  more: T extends [infer First, ...infer Rest]
    ? DeepMerge2<First, DeepMerge<Rest>>
    : never; // if we’re in the “more” case then we know that the above extends/infer will succeed.
}[T extends [unknown, unknown, ...unknown[]] ? 'more' : 'one'];

/**
 * Given an object whose values are objects, returns a union of all of those
 * inner objects’ keys.
 *
 * This works differently from `keyof T[keyof T]`, which would return the
 * _intersection_ of all of the nested keys.
 *
 * Soundness-wise, this isn’t hugely useful in the long term, but while we’re
 * still hand-waving some things as we convert to TS it can be good to have some
 * scoping in between `never` (the true intersection) and `string` (which gives
 * us no help).
 */
export type NestedKeyOf<T, K = keyof T> = K extends keyof T
  ? keyof T[K]
  : never;

/**
 * Allows all values of a map to also be `undefined`.
 *
 * Useful now that `--exactOptionalPropertyTypes` means that `Partial` just
 * makes fields optional, but if they’re provided they have to have their
 * previous types.
 */
export type Undefinable<T> = {
  [P in keyof T]: T[P] | undefined;
};

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

/**
 * Type that can be used to represent one of our API responses as run through
 * {@link Immutable.fromJS}.
 *
 * Allows for null/undefined and passes those through (like what fromJs() does).
 */
export type MapFromJs<T extends { [key: string]: unknown } | null | undefined> =
  T extends null
    ? null
    : T extends undefined
    ? undefined
    : Immutable.Map<keyof T, any>;

/**
 * Wrapper around {@link Immutable.Map} that limits its keys to the keys passed
 * in the `obj` param.
 *
 * Useful as a halfway between {@link Immutable.Map} and
 * {@link Immutable.Record}.
 */
export function mapOf<T extends { [key: string]: unknown }>(obj: T) {
  return Immutable.Map<keyof T, any>(obj as any);
}

/**
 * Helper to ensure that a line of code, particularly the `default` of a
 * `switch` statement, is unreachable (meaning that the switch statement is
 * exhaustive).
 */
export function assertUnreachable(x: never): never {
  throw new Error(`Unexpected switch case reached: ${x}`);
}

/**
 * Helper function that returns a {@link Promise} that never resolves. Useful
 * for async generator functions that get to an error state that should never
 * return.
 *
 * Unfortunately, due to https://github.com/microsoft/TypeScript/issues/34955
 * TypeScript does not consider `await` of a `Promise<never>` in reachability,
 * so this function should be paired with {@link neverReturn} to convince TS
 * that code is truly unreachable.
 */
export async function neverResolve(): Promise<never> {
  await new Promise<never>(() => {});
  throw new Error('Couldn’t have gotten here');
}

/**
 * Function that just throws an error, so that anything after it will be
 * considered unreachable.
 *
 * Sometimes used after an `await neverResolve()` which _should_ never continue,
 * but TypeScript doesn’t enforce that.
 */
export function neverReturn(): never {
  throw new Error('Got somewhere we shouldn’t have');
}

/**
 * TypeBox helper to make an enum out of an array of distinct string literals.
 *
 * Be sure when you use this to hover over the created type to check that TS is
 * seeing the separate literals and hasn’t collapsed them into `string[]`.
 *
 * TODO(fiona): Add const to this parameter when we upgrade TypeScript to 5.x.
 *
 * @see: https://devblogs.microsoft.com/typescript/announcing-typescript-5-0/#const-type-parameters
 */
export function StringEnumType<T extends string[]>(values: readonly [...T]) {
  const literals = values.map((value) => Type.Literal(value));

  return Type.Union(literals);
}
