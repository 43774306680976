import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class AddButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.string.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    classes: 'lbj-add-button',
  };

  render() {
    const { onClick, title, classes } = this.props;

    return (
      <button className={classes} type="button" title={title} onClick={onClick}>
        +
      </button>
    );
  }
}
