import moment from 'moment';
import React from 'react';

import { SelectableUser } from '../../components/presentational/form/user-autocomplete';

import {
  BoilerRoomLevel,
  ElectionType,
  IssueClass,
  issueClasses,
  issuePriorities,
  IssuePriority,
  IssueScope,
  issueScopes,
  IssueSource,
  issueSources,
  IssueStatus,
  issueStatuses,
  IssueViewType,
  issueViewTypes,
  State,
  states,
  US_NATIONAL_STATE_CODE,
  VoteStatus,
  voteStatuses,
} from '../../constants';
import boilerRoomLevels from '../../constants/boiler-room-levels';
import { CountySlug, Iso8601String } from '../../services/common';

import * as IssueService from '../../services/issue-service';
import * as LbjService from '../../services/lbj-shared-service';
import * as SentryService from '../../services/sentry-service';
import UrlClient from '../../services/url-client';
import { ApiUser } from '../../services/user-service';
import { pruneFilters } from '../../utils/filters/common';
import { usePolling } from '../../utils/hooks';
import { mapIssueListToUpdateCursor } from '../../utils/issue/polling-updates';
import {
  MakeQueryFieldsFn,
  QueryFieldBuilders,
  QueryFields,
  UpdateQueryStateFn,
} from '../../utils/query-state';

import { FilterLabelsFor } from './active-filters';

/**
 * Common data used to populate the filters sidebar.
 *
 * If lists are null it’s because the user is not filtering on enough to load
 * them. (E.g. we don’t list counties unless a state is chosen.)
 */
export type IssuesListLbjData = {
  elections: Pick<LbjService.ApiElection, 'id' | 'name' | 'state'>[];
  regions: LbjService.ApiRegion[] | null;
  counties: LbjService.ApiCounty[] | null;
  precincts: Pick<LbjService.ApiPrecinct, 'id' | 'name'>[] | null;
  locations: Pick<LbjService.ApiLocation, 'id' | 'name'>[] | null;
  districts: LbjService.ApiCongressionalDistrictResponseMap | null;
  boilerRooms: Pick<LbjService.ApiBoilerRoom, 'id' | 'name'>[];
};

export const BOILER_ROOOM_LEVEL_LABELS = {
  national: 'National',
  state: 'State',
  regional: 'Regional',
  hotline: 'Hotline',
};

/**
 * Filter definition common to issues and results pages.
 */
export type IssuesListFilters = {
  offset: number;
  size: number;

  view: IssueViewType;
  ordering: string;

  search_term: string;
  voter_name: string;
  voter_phone_number: string;

  created_by: number | undefined;
  owner: number | undefined;
  /** "observer" of the issue */
  user: number | undefined;
  watchers: number | undefined;

  state: State | undefined;
  query_election_id: number | undefined;

  boiler_room: number[];
  boiler_room__level: BoilerRoomLevel | 'hotline' | undefined;

  // We are being very pedantic here and specifically having this intermediate
  // state be an array of numbers, even though everywhere else treats it as a
  // single comma-separated string.
  region: number[];
  county: CountySlug[];

  precinct: number | undefined;
  location: number[];

  us_house: number[];
  state_house: number[];
  state_senate: number[];

  start_date: Date | undefined;
  end_date: Date | undefined;

  status: IssueStatus | undefined;
  source: IssueSource | undefined;
  type__in: IssueClass | undefined;
  vote_status: VoteStatus | undefined;
  scope: IssueScope[];
  priority: IssuePriority | undefined;
  qa_reviewed: boolean | undefined;

  owner__isnull: true | undefined;
  from_permanent: true | undefined;
  requires_followup: true | undefined;

  category: string[];
  sub_category: string[];
  exclude_category: string | undefined;
};

export function makeIssuesListFiltersQueryFields(
  currentElection: LbjService.ApiElection,
  /**
   * Optional function to override some of the builders. Used to keep the
   * results page locked to only Results/Vote Tally category and sub-category.
   */
  overrideFn?: (
    q: QueryFieldBuilders
  ) => Partial<QueryFields<IssuesListFilters>>
): MakeQueryFieldsFn<IssuesListFilters> {
  return (q) => ({
    offset: q.number(0),
    size: q.number(IssueService.DEFAULT_ISSUE_LIST_SIZE),

    view: q.oneOf(issueViewTypes, 'ALL'),
    ordering: q.string('-id'),

    search_term: q.string(''),
    voter_name: q.string(''),
    voter_phone_number: q.string(''),

    created_by: q.number(),
    owner: q.number(),
    user: q.number(),
    watchers: q.number(),

    state:
      currentElection.state === US_NATIONAL_STATE_CODE
        ? q.state({ ignoreUs: true })
        : q.silent(currentElection.state),

    query_election_id:
      currentElection.type === 'permanent'
        ? q.number()
        : q.silent(currentElection.id),

    boiler_room: q.listOf(q.number(), []),
    boiler_room__level: q.oneOf([...boilerRoomLevels, 'hotline']),

    // We are being very pedantic here and specifically having this intermediate
    // state be an array of numbers, even though everywhere else treats it as a
    // single comma-separated string.
    region: q.listOf(q.number(), []),
    county: q.listOf(q.county(), []),

    precinct: q.number(),
    location: q.listOf(q.number(), []),

    us_house: q.listOf(q.number(), []),
    state_house: q.listOf(q.number(), []),
    state_senate: q.listOf(q.number(), []),

    start_date: q.date('YYYY-MM-DD'),
    end_date: q.date('YYYY-MM-DD'),

    status: q.oneOf(issueStatuses),
    source: q.oneOf(issueSources),
    type__in: q.oneOf(issueClasses),
    vote_status: q.oneOf(voteStatuses),
    scope: q.listOf(q.oneOf(issueScopes), []),
    priority: q.oneOf(issuePriorities),
    qa_reviewed: q.boolean(),

    owner__isnull: q.byMap({ True: true }),
    from_permanent: q.byMap({ True: true }),
    requires_followup: q.byMap({ True: true }),

    category: q.listOf(q.string(), []),
    sub_category: q.listOf(q.string(), []),
    exclude_category: q.silent(undefined),

    ...overrideFn?.(q),
  });
}

export type IssuesListFilterUpdater = UpdateQueryStateFn<IssuesListFilters>;

/**
 * Wrapper around updateFiltersQuery that maintains dependent filter behavior.
 * E.g. if the state changes, we remove the election filter.
 *
 * This assumes (which is a correct assumption right now) that updates are not
 * changing together. I.e. we’re not setting both the state and an election that
 * is for that state at the same time (through this code path).
 *
 * @param elections List of available elections. Used to know whether or not
 * applying a state change should clear the currently-filtered election.
 */
export function makeUpdateFiltersWithDependencies(
  elections: Pick<LbjService.ApiElection, 'id' | 'state' | 'name'>[],
  filters: IssuesListFilters,
  updateQueryFilters: UpdateQueryStateFn<IssuesListFilters>
): IssuesListFilterUpdater {
  // TODO(fiona): When we add owner search, make sure that it’s exclusive with
  // “owner__isnull”.
  //
  // if (name === 'owner__isnull') { toBeOmitted.push('owner');
  // }
  //
  // if (name === 'owner') {
  //   toBeOmitted.push('owner__isnull');
  // }

  return (updates) => {
    // copy so we can modify
    updates = { ...updates };

    if ('state' in updates) {
      // If “state” changes, remove query election ID unless we’re pretty sure
      // query_election_id is pointing to an election in the same state.
      const election =
        filters.query_election_id &&
        elections?.find((e) => e.id === filters.query_election_id);

      if (!(election && election.state === updates.state)) {
        updates.query_election_id = undefined;
      }

      updates.county = undefined;
      updates.state_house = undefined;
      updates.state_senate = undefined;
      updates.us_house = undefined;
    }

    if ('query_election_id' in updates) {
      // Region is based on the query election id, so if it changes we reset the
      // region filter.
      updates.region = undefined;
      updates.precinct = undefined;
      updates.location = undefined;
      updates.boiler_room = undefined;
    }

    if ('region' in updates) {
      // TODO(fiona): It would be cute to preserve whatever counties still match
      // the new region, though we’d need to apply that update _after_ we load
      // all of the counties for the combined region.
      //
      // In the meantime, since region filters counties, if it changes we delete
      // them. This matches the /issues page behavior.
      updates.county = undefined;
    }

    if ('county' in updates) {
      updates.precinct = undefined;
      updates.location = undefined;
    }

    updateQueryFilters(updates);
  };
}

/**
 * Loads an {@link IssuesListLbjData} object based on the current values of
 * filters.
 */
export async function loadIssuesListLbjData(
  filters: IssuesListFilters
): Promise<IssuesListLbjData> {
  const electionsResp = filters.state
    ? LbjService.getElections(filters.state)
    : LbjService.getElections();

  const regionsResp = filters.query_election_id
    ? LbjService.getRegions({
        query_election_id: filters.query_election_id,
      })
    : null;

  const countiesResp = filters.state
    ? LbjService.getCounties(
        filters.state,
        filters.region.length > 0 ? filters.region.join(',') : undefined
      )
    : null;

  /**
   * Precincts from the given election ID and counties. Both are required.
   *
   * Note that if there are more that 1,000 precincts the server won’t return
   * any. (Thanks, LA County.)
   */
  const precinctsResp =
    filters.query_election_id && filters.county.length > 0
      ? LbjService.getPrecincts({
          query_election_id: filters.query_election_id,
          county: filters.county.join(','),
        })
      : null;

  const locationsResp = filters.query_election_id
    ? LbjService.getLocations({
        query_election_id: filters.query_election_id,
        county:
          filters.county.length > 0 ? filters.county.join(',') : undefined,
      })
    : null;

  const districtsResp = filters.state
    ? LbjService.getCongressionalDistricts({ state: filters.state })
    : null;

  const boilerRoomsResp = LbjService.getBoilerRooms({
    query_election_id: filters.query_election_id,
    state: filters.state,
    size: 1000,
  });

  return {
    elections: (await electionsResp).results,
    regions: regionsResp && (await regionsResp).regions,
    counties: countiesResp && (await countiesResp).counties,
    precincts: precinctsResp && (await precinctsResp).precincts,
    locations: locationsResp && (await locationsResp).locations,
    districts: districtsResp && (await districtsResp).districts,
    boilerRooms: (await boilerRoomsResp).boiler_rooms,
  };
}

/**
 * Converts our filters to the API params that will be sent when requesting both
 * the list of issues and to find if there are new issues to load.
 *
 * This looks a little pedantic because historically, query parameters on the
 * URL were passed pretty directly to the backend as API query parameters. This
 * function introduces a seam that disconnects those two concerns.
 *
 * There’s a bit of a safety/clarity/bug trade-off here that we don’t
 * automatically turn query params into API params, which means if you add a new
 * filter you do have to modify this function to send it to the API. Which is
 * something one might forget to do. But, this makes it very clear and explicit
 * what _is_ getting passed to the API.
 *
 * Does not include size/offset since those are not shared between the API list,
 * the update checker, and the CSV exporter.
 */
export function filtersToIssueListApiParams(
  filters: IssuesListFilters
): IssueService.ApiIssueListParams {
  let ordering;

  // The "ordering" sort filter is an alias for these three columns.
  switch (filters.ordering) {
    case 'description':
      ordering = 'type,category,sub_category';
      break;
    case '-description':
      ordering = '-type,-category,-sub_category';
      break;
    default:
      ordering = filters.ordering;
  }

  return pruneFilters({
    ordering,

    created_by: filters.created_by,
    search_term: filters.search_term,
    voter_name: filters.voter_name,
    voter_phone_number: filters.voter_phone_number,

    boiler_room: filters.boiler_room.join(','),
    boiler_room__level: filters.boiler_room__level,

    state: filters.state,
    query_election_id: filters.query_election_id,

    county__regions: filters.region.join(','),
    county: filters.county.join(','),
    precinct: filters.precinct?.toString(),
    location: filters.location.join(','),

    us_house: filters.us_house.join(','),
    state_house: filters.state_house.join(','),
    state_senate: filters.state_senate.join(','),

    source: filters.source,
    status: filters.status,
    type__in: filters.type__in,
    vote_status: filters.vote_status,
    scope: filters.scope.join(','),
    priority: filters.priority,
    qa_reviewed: filters.qa_reviewed?.toString() as
      | 'true'
      | 'false'
      | undefined,

    owner: filters.owner,
    user: filters.user,
    watchers: filters.watchers,

    owner__isnull: filters.owner__isnull ? ('True' as const) : undefined,
    from_permanent: filters.from_permanent ? ('True' as const) : undefined,
    requires_followup: filters.requires_followup
      ? ('True' as const)
      : undefined,

    category: filters.category.join(','),
    sub_category: filters.sub_category.join(','),
    exclude_category: filters.exclude_category,

    // Need the ISO8601 formats for these, since the version from the query
    // string is just YYYY-MM-DD. Note how the end date is converted to the
    // start of the next day because `end_date` is inclusive but
    // `incident_time__lt` is not.
    incident_time__gte:
      filters.start_date &&
      (moment(filters.start_date)
        .startOf('day')
        .toISOString() as Iso8601String),

    incident_time__lt:
      filters.end_date &&
      (moment(filters.end_date)
        .startOf('day')
        .add(1, 'day')
        .toISOString() as Iso8601String),
  });
}

/**
 * Creates the {@link FilterLabelsFor} object for our filter labels. These
 * depend on the current values of {@link ResultsLbjData} so that the IDs in the
 * query params can be looked up into their names.
 *
 * The result of this should be passed into {@link makeActiveFilterLabels}
 * (along with the current values of the filters) to generate the labels.
 *
 * TODO(fiona): This might be better as just a component, rather than making
 * FilterLabelsFor a thing.
 */
export function makeIssuesListFilterLabels(
  currentElectionState: State,
  currentElectionType: ElectionType,
  {
    elections,
    regions,
    counties,
    precincts,
    locations,
    districts,
    boilerRooms,
    preloadedUsers,
  }: IssuesListLbjData & { preloadedUsers: SelectableUser[] }
): FilterLabelsFor<IssuesListFilters> {
  const findPreloadedUserForLabel = (id: number) => {
    const user = preloadedUsers.find((u) => u.id === id);
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return `Unknown user ${id}`;
    }
  };

  return {
    start_date: (d) => `Start Date: ${moment(d).format('MMM D, YYYY')}`,
    end_date: (d) => `End Date: ${moment(d).format('MMM D, YYYY')}`,

    status: (s) => `Status: ${issueStatuses[s]}`,
    source: (s) => `Source: ${issueSources[s]}`,

    // We don’t show the state filter label if it’s not possible to change the
    // state.
    state: (s) => (s !== currentElectionState ? `State: ${states[s]}` : null),

    query_election_id: (id) => {
      // We don’t show the election filter label if it’s not possible to change the
      // election, which can only be done if we’re looking at a permanent election.
      if (currentElectionType !== 'permanent') {
        return null;
      }

      const election = elections.find((election) => election.id === id);

      if (election) {
        return `Election: ${election.name}`;
      } else {
        return `Election: ${id}`;
      }
    },

    region: (ids) => {
      // We more-or-less assume that `regions` has loaded if there are any
      // filters on the region, so we can be a bit fast-and-loose as far as not
      // making pretty missing states for this.
      if (ids.length === 0 || !regions) {
        return null;
      }

      const regionString = regions
        .filter((r) => ids.includes(r.id))
        .map((r) => r.name)
        .join('; ');

      return `Region: ${regionString}`;
    },

    county: (slugs) => {
      if (slugs.length === 0 || !counties) {
        return null;
      }

      const countyString = counties
        .filter((c) => c.slug !== '' && slugs.includes(c.slug))
        .map((c) => c.name)
        .join('; ');

      return `County: ${countyString}`;
    },

    precinct: (precinctId) => {
      if (!precinctId || !precincts) {
        return null;
      }

      const precinct = precincts.find((p) => p.id === precinctId);
      return `Precinct: ${precinct?.name ?? precinctId}`;
    },

    boiler_room: (boilerRoomIds) => {
      if (boilerRoomIds.length === 0) {
        return null;
      }

      const boilerRoomString = boilerRooms
        .filter((br) => boilerRoomIds.includes(br.id))
        .map((br) => br.name)
        .join('; ');

      return `Boiler Room: ${boilerRoomString}`;
    },

    location: (locationIds) => {
      if (locationIds.length === 0 || !locations) {
        return null;
      }

      const locationString = locations
        .filter((loc) => locationIds.includes(loc.id))
        .map((loc) => loc.name)
        .join('; ');

      return `Location: ${locationString}`;
    },

    us_house: (districtIds) => {
      if (districtIds.length === 0 || !districts?.us_house) {
        return null;
      }

      const districtString = districts.us_house
        .filter((d) => districtIds.includes(d.id))
        .map((d) => d.name)
        .join('; ');

      return `U.S. House: ${districtString}`;
    },

    state_house: (districtIds) => {
      if (districtIds.length === 0 || !districts?.state_house) {
        return null;
      }

      const districtString = districts.state_house
        .filter((d) => districtIds.includes(d.id))
        .map((d) => d.name)
        .join('; ');

      return `State House: ${districtString}`;
    },

    state_senate: (districtIds) => {
      if (districtIds.length === 0 || !districts?.state_senate) {
        return null;
      }

      const districtString = districts.state_senate
        .filter((d) => districtIds.includes(d.id))
        .map((d) => d.name)
        .join('; ');

      return `State Senate: ${districtString}`;
    },

    type__in: (type) => issueClasses[type],
    category: (categories) =>
      categories.length === 0 ? null : `Category: ${categories.join('; ')}`,
    priority: (priority) => `${issuePriorities[priority]} Priority`,
    owner__isnull: () => 'Unclaimed',
    owner: (id) => `Assignee: ${findPreloadedUserForLabel(id)}`,
    user: (id) => `Reporter: ${findPreloadedUserForLabel(id)}`,
    boiler_room__level: (level) =>
      `Boiler Room Level: ${BOILER_ROOOM_LEVEL_LABELS[level]}`,
    from_permanent: () => 'From Permanent Hotline',
    qa_reviewed: (reviewed) => (reviewed ? 'QA Reviewed' : 'Not QA Reviewed'),
    vote_status: (status) => `Vote Status: ${voteStatuses[status]}`,
    scope: (scopes) =>
      scopes.length === 0
        ? null
        : `Scope: ${scopes.map((s) => issueScopes[s]).join('; ')}`,
  };
}

/**
 * Hook to poll for if the issues list has new values and, if so, call the
 * `refetch` callback to update them.
 *
 * Pulled into its own function for clarity.
 */
export function useUpdateListPolling({
  currentUser,
  params,
  results,
  refetch,
  onFirstPage,
}: {
  currentUser: Pick<ApiUser, 'role' | 'id'>;
  params: IssueService.ApiIssueListParams;
  results: IssueService.IssueListApiIssue[];
  refetch: () => void;
  onFirstPage: boolean;
}) {
  usePolling(
    async () => {
      const updateCursor = mapIssueListToUpdateCursor(results);

      if (!updateCursor) {
        return;
      }

      const res = await IssueService.getIssuesLastUpdated(
        currentUser,
        updateCursor,
        params
      );

      if (res.needs_update) {
        refetch();
      }

      return res.polling_interval;
    },
    {
      // We don’t poll for new issues unless the user is on the first page.
      active: onFirstPage,
    }
  );
}

/**
 * Hook that returns a function that starts CSV download of issues based on the
 * provided query params.
 *
 * The hook will tell the backend to generate the CSV, then poll until it’s
 * available. Once it’s ready, it will resolve its Promise with the URL to the
 * CSV file.
 *
 * @returns A boolean for whether or not CSV export is currently happening, and
 * a function that takes a current user data object and the query params and
 * starts the export.
 *
 * TODO(fiona): Would be nice to use a different async structure than Promises;
 * possibly return an EventEmitter of some kind? Keeping as-is for compatibility
 * with the issue list csv export and `<IssueListExport/>`.
 */
export function useIssueCsvExport(): [
  boolean,
  (
    currentUser: Pick<ApiUser, 'role' | 'id'>,
    /** Params that would be sent to get the issues list */
    listApiParams: IssueService.ApiIssueListParams
  ) => Promise<null | string>
] {
  // We do this as a refcount since technically the startCsvExport function can
  // be called multiple times, though that doesn’t seem to happen in the UI.
  const [csvExportCount, setCsvExportCount] = React.useState(0);

  const startCsvExport = React.useCallback(
    async (
      currentUser: Pick<ApiUser, 'role' | 'id'>,
      listApiParams: IssueService.ApiIssueListParams
    ) => {
      try {
        setCsvExportCount((c) => c + 1);

        // Tells the backend to start a CSV export process.
        const { get_url, head_url } = await IssueService.getIssuesExportAsync(
          currentUser,
          listApiParams
        );

        // The URLs we’ve gotten from the backend are not necessarily ready,
        // they’re just where the data _will_ be eventually. We loop on HEAD
        // requests until they succeed, then we know we can tell our caller that
        // they can download the CSV.
        await UrlClient(head_url, 'HEAD', { retries: 60 });

        return get_url;
      } catch (e: any) {
        SentryService.captureException(e, {
          type: 'results',
          method: 'useCsvExport',
        });

        return null;
      } finally {
        setCsvExportCount((c) => c - 1);
      }
    },
    []
  );

  return [csvExportCount > 0, startCsvExport];
}
