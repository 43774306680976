import { State } from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';

// These are the default notification filters, as well as the filters used
// for the notification icon on the top right of the screen.
export default function getDefaultNotificationFilters(
  state: AppState
): FiltersBySection['NOTIFICATIONS'] {
  const currentUser = state.user.currentUser.userData;

  return {
    state:
      (currentUser?.get('assignment_state') as State | null | undefined) ??
      undefined,
  };
}
