import cx from 'classnames';
import React from 'react';

import LoadingOverlay from '../../components/presentational/lbj/loading';
import { DocumentUploadData } from '../../modules/issue/reducers';
import { ApiIssueDocument } from '../../services/issue-service';

const KB = 1000;
const MB = 1000 * KB;

export default class IssueDocuments extends React.Component<
  {
    uploadIsPending: boolean;
    canAddDocument: boolean;
  } & (
    | {
        isNewIssue: true;
        documents: DocumentUploadData[] | undefined | null;
        onDocumentAdd: (document: DocumentUploadData) => void;
        onDocumentDelete: (document: DocumentUploadData) => void;
      }
    | {
        isNewIssue: false;
        documents: ApiIssueDocument[] | undefined | null;
        onDocumentAdd: (document: File) => void;
        onDocumentDelete: (document: ApiIssueDocument) => void;
      }
  )
> {
  handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    const { isNewIssue, onDocumentAdd } = this.props;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (!isNewIssue) {
        onDocumentAdd(file);
      } else {
        const result = reader.result;

        if (typeof result === 'string') {
          onDocumentAdd({
            filename: file.name,
            filesize: file.size,
            header: result.split(',')[0]!,
            data: result.split(',')[1]!,
          });
        }
      }
    };
    reader.readAsDataURL(file);
  }

  renderFilesize(document: DocumentUploadData) {
    let size, unit;

    if (document.filesize / MB > 1) {
      unit = 'MB';
      size = document.filesize / MB;
    } else {
      unit = 'KB';
      size = document.filesize / KB;
    }

    if (size > 10) {
      size = Math.floor(size);
    } else {
      size = (Math.round(size * 10) / 10).toFixed(1);
    }

    return `(${size} ${unit})`;
  }

  renderFilename(documentUrl: string) {
    const withoutParams = documentUrl.substring(0, documentUrl.indexOf('?'));
    return withoutParams.substring(withoutParams.lastIndexOf('/') + 1);
  }

  renderNewIssueDocument(
    onDocumentDelete: (doc: DocumentUploadData) => void,
    document: DocumentUploadData,
    idx: number
  ) {
    const tooLarge = document.filesize > 5 * MB;
    const errorMessage = tooLarge ? (
      <span className="errorMessage">Must be {'<'} 5 MB</span>
    ) : null;
    return (
      <div
        key={`document-${idx}`}
        className={cx('document-preview', { error: tooLarge })}
      >
        <span className="filename">{document.filename}</span>
        <span className="filesize">{this.renderFilesize(document)}</span>
        {errorMessage}
        <span className="links">
          <a className="remove" onClick={() => onDocumentDelete(document)}>
            <img
              className="removeIcon"
              src={require('../../../img/close_black.svg')}
            />
          </a>
        </span>
      </div>
    );
  }

  renderDocument(
    onDocumentDelete: (doc: ApiIssueDocument) => void,
    document: ApiIssueDocument,
    idx: number
  ) {
    const documentUrl = document.document;
    return (
      <div key={`document-${idx}`} className="document-preview">
        <span className="filename">
          <a href={documentUrl} target="_blank" rel="noreferrer">
            {this.renderFilename(documentUrl)}
          </a>
        </span>
        <span className="links">
          <a className="remove" onClick={() => onDocumentDelete(document)}>
            <img
              className="removeIcon"
              src={require('../../../img/close_black.svg')}
            />
          </a>
        </span>
      </div>
    );
  }

  renderDocuments() {
    const { isNewIssue, documents, uploadIsPending, onDocumentDelete } =
      this.props;
    const spinner = !documents ? <LoadingOverlay /> : null;

    const placeholderDocument = uploadIsPending ? (
      <div className="gallery-document-link pending">
        <div className={cx('gallery-document')} />
      </div>
    ) : null;

    if (!documents) {
      return <div className="issue-document-gallery empty">{spinner}</div>;
    }

    if (!documents.length) {
      return <div className="empty">{spinner}</div>;
    }

    let error = null;
    if (isNewIssue) {
      const cumulativeFileSize = documents.reduce((a, b) => a + b.filesize, 0);
      if (cumulativeFileSize > 9.5 * MB) {
        error = (
          <div className="documents-error">
            You have exceeded the available request size. Please consider
            uploading smaller pdfs or adding pdfs one by one.
          </div>
        );
      }
    }

    return (
      <div className={cx({ 'issue-document-gallery': !isNewIssue })}>
        {placeholderDocument}
        {isNewIssue &&
          documents.map(
            this.renderNewIssueDocument.bind(this, onDocumentDelete)
          )}
        {!isNewIssue &&
          documents.map(this.renderDocument.bind(this, onDocumentDelete))}
        {error}
        {spinner}
      </div>
    );
  }

  renderInput() {
    const label = this.props.isNewIssue ? 'Add a PDF' : 'Upload PDF';
    return (
      <div className="file-upload">
        + {label}
        <input
          className="file-upload-input"
          type="file"
          accept="application/pdf"
          onChange={this.handleFileSelect.bind(this)}
        />
      </div>
    );
  }

  render() {
    const { canAddDocument } = this.props;
    return (
      <div className="issue-documents">
        {this.renderDocuments()}
        {canAddDocument && this.renderInput()}
      </div>
    );
  }
}
