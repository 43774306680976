/**
 * Returns a formatted phone number for display.
 *   Sample input: 5551234007
 *   Sample output: (555) 123-4007
 */
export function formatPhoneNumber(phone: string | null) {
  // Convert to a string and strip non-numerical characters
  phone = ('' + phone).replace(/[^\d]/g, '');
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return null;
}
