import React from 'react';

import UserAutocomplete, {
  SelectableUser,
} from '../../components/presentational/form/user-autocomplete';
import { LbjInputEvent } from '../../decorators/input-props';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';

export default class IssueObserver extends React.Component<{
  issueData: IssueInProgressPayload;
  errors: { user?: string[] };
  preloadedUsers: SelectableUser[];
  onInputChange: (ev: LbjInputEvent<string | null>) => void;
  readOnly: boolean;
}> {
  render() {
    const { issueData, errors, onInputChange, readOnly, preloadedUsers } =
      this.props;

    return (
      <fieldset className="observer-fields">
        <legend>Observer</legend>
        <div className="row">
          <div className="col-medium-6 col-small-12">
            <UserAutocomplete
              name="user"
              preloadedUsers={preloadedUsers}
              title="Observer"
              userId={issueData.user ? Number(issueData.user) : null}
              setUserId={(id) =>
                onInputChange({
                  target: { name: 'user', value: id ? id.toString() : null },
                })
              }
              errors={errors.user}
              disabled={readOnly}
            />
          </div>
          {/* TODO(fiona): Delete this? Need to make sure it doesn’t break layout. */}
          <div className="observer-details col-medium-6 col-small-12"></div>
        </div>
      </fieldset>
    );
  }
}
