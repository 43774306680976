import type {
  FocusableProps,
  Validation,
  AriaLabelingProps,
  LabelableProps,
  InputBase,
  TextInputBase,
  DOMProps,
  FocusableDOMProps,
} from '@react-types/shared';
import React from 'react';
import { Item } from 'react-stately';

import { State, states, US_NATIONAL_STATE_CODE } from '../../constants';

import { Select } from '../form';

/**
 * `<Select>` box for states.
 */
export const StateSelect: React.FunctionComponent<
  {
    selectedKey?: State | null | undefined;
    defaultSelectedKey?: State | undefined;
    onSelectionChange?: (value: State) => void;
    includeUsNational?: boolean;
  } & FocusableProps &
    Validation &
    AriaLabelingProps &
    InputBase &
    TextInputBase &
    LabelableProps &
    DOMProps &
    FocusableDOMProps
> = ({
  selectedKey,
  defaultSelectedKey,
  onSelectionChange,
  includeUsNational = false,
  label = 'State',
  placeholder = 'Choose state…',
  ...props
}) => {
  return (
    <Select
      label={label}
      placeholder={placeholder}
      {...(selectedKey !== undefined ? { selectedKey } : {})}
      {...(defaultSelectedKey !== undefined ? { defaultSelectedKey } : {})}
      onSelectionChange={(key) => onSelectionChange?.(key as State)}
      items={(Object.keys(states) as State[])
        .filter((abbr) => abbr !== US_NATIONAL_STATE_CODE || includeUsNational)
        .map((abbr) => ({
          id: abbr,
          name: `${abbr} - ${states[abbr]}`,
        }))}
      {...props}
    >
      {(item) => <Item>{item.name}</Item>}
    </Select>
  );
};
