import {
  requestStatuses,
  State,
  US_NATIONAL_STATE_CODE,
} from '../../constants';
import { CountySlug } from '../../services/common';

import * as LbjSharedService from '../../services/lbj-shared-service';
import {
  ListRequestData,
  ListResponse,
} from '../../utils/lbj/list-request-state-handler';
import { AppThunk } from '../flux-store';

import actionTypes from './action-types';

const {
  GET_CONGRESSIONAL_DISTRICT_LIST,
  GET_COUNTY_LIST,
  GET_ELECTION_LIST,
  GET_LOCATION_LIST,
  GET_PRECINCT_LIST,
  GET_REGION_LIST,
  GET_BOILER_ROOM_LIST,
  GET_BOARDS_OF_ELECTIONS_LIST,
  GET_USER_TAG_LIST,
  GET_NEARBY_LOCATIONS,
} = actionTypes;

const { PENDING, SUCCESS, ERROR } = requestStatuses;

export type LbjAction =
  | GetCongressionalDistrictListAction
  | GetCountyListAction
  | GetElectionListAction
  | GetLocationListAction
  | GetPrecinctListAction
  | GetRegionListAction
  | GetBoilerRoomListAction
  | GetBoardsOfElectionsListAction
  | GetUserTagListAction
  | GetNearbyLocationsAction;

type DistrictDataListResponse = {
  offset: number;
  size: number;
  districtData: LbjSharedService.ApiCongressionalDistrictResponseMap;
};

export type GetCongressionalDistrictListAction = {
  type: typeof GET_CONGRESSIONAL_DISTRICT_LIST;
  data:
    | {
        status: typeof PENDING;
        state: State;
      }
    | {
        status: typeof SUCCESS;
        state: State;
        listResponse: DistrictDataListResponse;
      }
    | {
        status: typeof ERROR;
        state: State;
        error: unknown;
      };
};

function requestCongressionalDistrictList(
  state: State
): GetCongressionalDistrictListAction {
  return {
    type: GET_CONGRESSIONAL_DISTRICT_LIST,
    data: {
      status: PENDING,
      state,
    },
  };
}

function receiveCongressionalDistrictList(
  listResponse: DistrictDataListResponse,
  state: State
): GetCongressionalDistrictListAction {
  return {
    type: GET_CONGRESSIONAL_DISTRICT_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      state,
    },
  };
}

function errorReceivingCongressionalDistrictList(
  error: unknown,
  state: State
): GetCongressionalDistrictListAction {
  return {
    type: GET_CONGRESSIONAL_DISTRICT_LIST,
    data: {
      status: ERROR,
      state,
      error,
    },
  };
}

export function getCongressionalDistrictListAsync(filters: {
  state: State | '';
  county?: CountySlug;
  precinct?: string | undefined;
}): AppThunk<void | Promise<unknown>> {
  const { state } = filters;

  return (dispatch) => {
    if (!state || state === US_NATIONAL_STATE_CODE) {
      return;
    }

    dispatch(requestCongressionalDistrictList(state));

    LbjSharedService.getCongressionalDistricts({ ...filters, state }).then(
      ({ districts, offset, size }) => {
        const districtsResponse = {
          offset,
          size,
          districtData: districts,
        };

        return dispatch(
          receiveCongressionalDistrictList(districtsResponse, state)
        );
      },
      (error) => dispatch(errorReceivingCongressionalDistrictList(error, state))
    );
  };
}

export type GetCountyListAction = {
  type: typeof GET_COUNTY_LIST;
  data: ListRequestData<
    LbjSharedService.ApiCounty & LbjSharedService.WithApiCountyRegions
  > & { state: State };
};

export function requestCountyList(state: State): GetCountyListAction {
  return {
    type: GET_COUNTY_LIST,
    data: {
      status: PENDING,
      state,
    },
  };
}

export function receiveCountyList(
  listResponse: ListResponse<
    LbjSharedService.ApiCounty & LbjSharedService.WithApiCountyRegions
  >,
  state: State
): GetCountyListAction {
  return {
    type: GET_COUNTY_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      state,
    },
  };
}

export function errorReceivingCountyList(state: State): GetCountyListAction {
  return {
    type: GET_COUNTY_LIST,
    data: {
      status: ERROR,
      state,
    },
  };
}

export function getCountyListAsync(
  stateCode: State | '',
  region?: string
): AppThunk<void | Promise<unknown>> {
  return (dispatch) => {
    if (!stateCode || stateCode === US_NATIONAL_STATE_CODE) {
      return;
    }

    dispatch(requestCountyList(stateCode));

    LbjSharedService.getCounties(stateCode, region).then(
      ({ offset, counties, size }) => {
        const countiesResponse = {
          offset,
          size,
          listData: counties,
        };

        return dispatch(receiveCountyList(countiesResponse, stateCode));
      },
      () => dispatch(errorReceivingCountyList(stateCode))
    );
  };
}

export type GetElectionListAction = {
  type: typeof GET_ELECTION_LIST;
  data: ListRequestData<LbjSharedService.ApiElection> & { state: State | '' };
};

function requestElectionList(state: State | ''): GetElectionListAction {
  return {
    type: GET_ELECTION_LIST,
    data: {
      status: PENDING,
      state,
    },
  };
}

function receiveElectionList(
  listResponse: ListResponse<LbjSharedService.ApiElection>,
  state: State | ''
): GetElectionListAction {
  return {
    type: GET_ELECTION_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      state,
    },
  };
}

function errorReceivingElectionList(state: State | ''): GetElectionListAction {
  return {
    type: GET_ELECTION_LIST,
    data: {
      status: ERROR,
      state,
    },
  };
}

export function getElectionListAsync(stateCode: State | ''): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestElectionList(stateCode));

    LbjSharedService.getElections(stateCode).then(
      ({ results, count }) => {
        const electionsResponse = {
          size: count,
          offset: 0,
          listData: results,
        };

        return dispatch(receiveElectionList(electionsResponse, stateCode));
      },
      () => dispatch(errorReceivingElectionList(stateCode))
    );
  };
}

export type GetLocationListAction = {
  type: typeof GET_LOCATION_LIST;
  data: ListRequestData<LbjSharedService.ApiLocation> & {
    county: string;
    keypath: ['locations'] | undefined;
  };
};

function requestLocationList(
  county: string = '',
  keypath: ['locations'] | undefined
): GetLocationListAction {
  return {
    type: GET_LOCATION_LIST,
    data: {
      status: PENDING,
      county,
      keypath,
    },
  };
}

function receiveLocationList(
  listResponse: ListResponse<LbjSharedService.ApiLocation>,
  county: string = '',
  keypath: ['locations'] | undefined
): GetLocationListAction {
  return {
    type: GET_LOCATION_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      county,
      keypath,
    },
  };
}

function errorReceivingLocationList(
  county: string = '',
  keypath: ['locations'] | undefined
): GetLocationListAction {
  return {
    type: GET_LOCATION_LIST,
    data: {
      status: ERROR,
      county,
      keypath,
    },
  };
}

export function getLocationListAsync(
  filters: {
    county?: string | undefined;
    query_election_id?: string;
  } = {},
  keypath?: ['locations'] | undefined
): AppThunk<void> {
  return (dispatch) => {
    const { county } = filters;
    dispatch(requestLocationList(county, keypath));

    LbjSharedService.getLocations(filters).then(
      ({ offset, locations, size }) => {
        const locationsResponse = {
          offset,
          size,
          listData: locations,
        };

        return dispatch(
          receiveLocationList(locationsResponse, county, keypath)
        );
      },
      () => dispatch(errorReceivingLocationList(county, keypath))
    );
  };
}

export type GetPrecinctListAction = {
  type: typeof GET_PRECINCT_LIST;
  data: ListRequestData<LbjSharedService.ApiPrecinct> & {
    county: string;
    keypath: ['precincts'] | undefined;
  };
};

function requestPrecinctList(
  county: string = '',
  keypath: ['precincts'] | undefined
): GetPrecinctListAction {
  return {
    type: GET_PRECINCT_LIST,
    data: {
      status: PENDING,
      county,
      keypath,
    },
  };
}

function receivePrecinctList(
  listResponse: ListResponse<LbjSharedService.ApiPrecinct>,
  county: string = '',
  keypath: ['precincts'] | undefined
): GetPrecinctListAction {
  return {
    type: GET_PRECINCT_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      county,
      keypath,
    },
  };
}

function errorReceivingPrecinctList(
  county: string = '',
  keypath: ['precincts'] | undefined
): GetPrecinctListAction {
  return {
    type: GET_PRECINCT_LIST,
    data: {
      status: ERROR,
      county,
      keypath,
    },
  };
}

export function getPrecinctListAsync(
  filters: {
    county?: string;
    query_election_id?: string;
    size?: number;
  } = {},
  keypath?: ['precincts'] | undefined
): AppThunk<void> {
  return (dispatch) => {
    const { county } = filters;
    dispatch(requestPrecinctList(county, keypath));

    LbjSharedService.getPrecincts(filters).then(
      ({ offset, precincts, size }) => {
        const precinctsResponse = {
          offset,
          size,
          listData: precincts,
        };

        return dispatch(
          receivePrecinctList(precinctsResponse, county, keypath)
        );
      },
      () => dispatch(errorReceivingPrecinctList(county, keypath))
    );
  };
}

export type GetRegionListAction = {
  type: typeof GET_REGION_LIST;
  data: ListRequestData<LbjSharedService.ApiRegion> & { state: State };
};

function requestRegionList(state: State): GetRegionListAction {
  return {
    type: GET_REGION_LIST,
    data: {
      status: PENDING,
      state,
    },
  };
}

function receiveRegionList(
  listResponse: ListResponse<LbjSharedService.ApiRegion>,
  state: State
): GetRegionListAction {
  return {
    type: GET_REGION_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      state,
    },
  };
}

function errorReceivingRegionList(state: State): GetRegionListAction {
  return {
    type: GET_REGION_LIST,
    data: {
      status: ERROR,
      state,
    },
  };
}

export function getRegionListAsync(
  filters: {
    state?: State | '';
    query_election_id?: string | undefined;
  } = {}
): AppThunk<void> {
  return (dispatch) => {
    const stateCode = filters.state;
    const election = filters.query_election_id;

    if (!stateCode || stateCode === US_NATIONAL_STATE_CODE) {
      return;
    }

    dispatch(requestRegionList(stateCode));

    if (!election) {
      const regionsResponse = {
        offset: 0,
        size: 0,
        listData: [],
      };

      return dispatch(receiveRegionList(regionsResponse, stateCode));
    }

    LbjSharedService.getRegions(filters).then(
      ({ offset, regions, size }) => {
        const regionsResponse = {
          offset,
          size,
          listData: regions,
        };

        return dispatch(receiveRegionList(regionsResponse, stateCode));
      },
      () => dispatch(errorReceivingRegionList(stateCode))
    );
  };
}

export type GetBoilerRoomListAction = {
  type: typeof GET_BOILER_ROOM_LIST;
  data: ListRequestData<LbjSharedService.ApiBoilerRoom>;
};

function requestBoilerRoomList(): GetBoilerRoomListAction {
  return {
    type: GET_BOILER_ROOM_LIST,
    data: {
      status: PENDING,
    },
  };
}

function receiveBoilerRoomList(
  listResponse: ListResponse<LbjSharedService.ApiBoilerRoom>
): GetBoilerRoomListAction {
  return {
    type: GET_BOILER_ROOM_LIST,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingBoilerRoomList(): GetBoilerRoomListAction {
  return {
    type: GET_BOILER_ROOM_LIST,
    data: {
      status: ERROR,
    },
  };
}

export function getBoilerRoomListAsync(filters: {
  query_election_id?: string | number | undefined | null;
  size?: number;
  state?: State;
}): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestBoilerRoomList());

    LbjSharedService.getBoilerRooms(filters).then(
      ({ offset, boiler_rooms, size }) => {
        const boilerRoomsResponse = {
          offset,
          size,
          listData: boiler_rooms,
        };

        return dispatch(receiveBoilerRoomList(boilerRoomsResponse));
      },
      () => dispatch(errorReceivingBoilerRoomList())
    );
  };
}

export type GetBoardsOfElectionsListAction = {
  type: typeof GET_BOARDS_OF_ELECTIONS_LIST;
  data: ListRequestData<LbjSharedService.ApiBoardOfElections>;
};

function requestBoardOfElectionsList(): GetBoardsOfElectionsListAction {
  return {
    type: GET_BOARDS_OF_ELECTIONS_LIST,
    data: {
      status: PENDING,
    },
  };
}

function receiveBoardOfElectionsList(
  listResponse: ListResponse<LbjSharedService.ApiBoardOfElections>
): GetBoardsOfElectionsListAction {
  return {
    type: GET_BOARDS_OF_ELECTIONS_LIST,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingBoardOfElectionsList(): GetBoardsOfElectionsListAction {
  return {
    type: GET_BOARDS_OF_ELECTIONS_LIST,
    data: {
      status: ERROR,
    },
  };
}

export function getBoardOfElectionsListAsync(filters: {
  name?: string;
}): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestBoardOfElectionsList());

    LbjSharedService.getBoardsOfElections(filters).then(
      ({ offset, boards_of_elections, size }) => {
        const boardsOfElectionsResponse = {
          offset,
          size,
          listData: boards_of_elections,
        };

        return dispatch(receiveBoardOfElectionsList(boardsOfElectionsResponse));
      },
      () => dispatch(errorReceivingBoardOfElectionsList())
    );
  };
}

export type GetUserTagListAction = {
  type: typeof GET_USER_TAG_LIST;
  data: ListRequestData<LbjSharedService.ApiUserTag>;
};

function requestUserTagList(): GetUserTagListAction {
  return {
    type: GET_USER_TAG_LIST,
    data: {
      status: PENDING,
    },
  };
}

function receiveUserTagList(
  listResponse: ListResponse<LbjSharedService.ApiUserTag>
): GetUserTagListAction {
  return {
    type: GET_USER_TAG_LIST,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingUserTagList(): GetUserTagListAction {
  return {
    type: GET_USER_TAG_LIST,
    data: {
      status: ERROR,
    },
  };
}

export function getUserTagListAsync(): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestUserTagList());

    LbjSharedService.getUserTags().then(
      ({ offset, tags, size }) => {
        const tagsResponse = {
          offset,
          size,
          listData: tags,
        };

        return dispatch(receiveUserTagList(tagsResponse));
      },
      () => dispatch(errorReceivingUserTagList())
    );
  };
}

export type GetNearbyLocationsAction = {
  type: typeof GET_NEARBY_LOCATIONS;
  data: ListRequestData<LbjSharedService.ApiNearbyLocation>;
};

function requestNearbyLocations(): GetNearbyLocationsAction {
  return {
    type: GET_NEARBY_LOCATIONS,
    data: {
      status: PENDING,
    },
  };
}

function receiveNearbyLocations(
  listResponse: ListResponse<LbjSharedService.ApiNearbyLocation>
): GetNearbyLocationsAction {
  return {
    type: GET_NEARBY_LOCATIONS,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingNearbyLocations(): GetNearbyLocationsAction {
  return {
    type: GET_NEARBY_LOCATIONS,
    data: {
      status: ERROR,
    },
  };
}

export function getNearbyLocationsAsync(
  locationId: number
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestNearbyLocations());

    return LbjSharedService.getNearbyLocations(locationId).then(
      ({ nearby_locations, size, offset }) => {
        const listResponse = {
          listData: nearby_locations,
          size,
          offset,
        };
        dispatch(receiveNearbyLocations(listResponse));
      },
      () => dispatch(errorReceivingNearbyLocations())
    );
  };
}

export function getCountyForZip(zip: string = ''): AppThunk<Promise<unknown>> {
  return () => {
    return LbjSharedService.getCountyForZip(zip);
  };
}
