export default {
  inquiry: {
    'ADA accessibility': [
      'What are accessibility standards for voting',
      'Other',
    ],
    'Early Voting': [
      'When to vote early',
      'Where to vote early',
      'Eligibility',
      'Other',
    ],
    'Election Day Ballots': ['Where to find sample ballot', 'Other'],
    Other: ['Other'],
    'Polling Location': [
      'Find my polling place',
      'Polling place hours',
      'Reporting incorrect polling location',
      'Other',
    ],
    'Provisional Ballots': ['How to cure a provisional ballot', 'Other'],
    Results: ['Vote Tally', 'Other'],
    Subversion: ['Subversion'],
    'Volunteer Opportunities': ['Campaign', 'State Party', 'Other'],
    'Vote by Mail': [
      "Didn't submit ballot on time",
      'Other',
      'Deadline',
      'How to vote by mail',
      'Status of VBM ballot',
      'Wants to vote at polls',
      'Other',
      'How to cure an absentee ballot',
    ],
    'Voter Challenges / Intimidation': [
      'Reporting incorrect voting info',
      'Other',
    ],
    'Voter ID Related': [
      'ID needed to register to vote',
      'ID needed to vote by mail',
      'ID needed to in person early/absentee vote',
      'ID need to vote on Election Day',
      "How to get ID if voter doesn't have one",
      'Other',
    ],
    'Voter Registration': [
      'Deadline',
      'Registration Status',
      'How to register',
      'Eligibility',
      'Changing Address',
      'Changing Name',
      'Other',
    ],
    Voting: [
      'Overseas or military voter',
      'Party affiliation required for voting in the Primary',
      'Request for ride to the polls',
      'Other',
    ],
  },

  incident: {
    'ADA accessibility': [
      'Location not ADA accessible',
      'Accessible restrooms/facilities unavailable',
      'Accessible parking spaces unavailable',
      'Accessible machine unavailable',
      'Accessible machine malfunctioning',
      'Voter not allowed to vote curbside',
      'Other',
    ],
    'Election Day Ballots': [
      'Improper Ballot Storage',
      'Not enough regular ballots',
      'Not enough paper backup/emergency ballots',
      'Other',
      'Error on Paper Ballots',
      'Confusion related to ballot design',
    ],
    'Official Poll Workers': [
      'Directing voters to wrong site',
      'Voters in line at closing not allowed to vote',
      'Providing incorrect ID guidance',
      'Failure to provide voter assistance',
      'Obstructing poll observer activity',
      'Slow check-in',
      'Other',
    ],
    'Polling Location': [
      'Inadequate safety procedures or equipment',
      'Voter was not allowed to bring person to assist them',
      'Location closed',
      'Location opened late',
      'Location rendered unusable due to power outage or other issue',
      'Lack of parking',
      'Signage problems',
      'Electioneering',
      'Long Lines',
      'Location changed with insufficient notice',
      'Other',
      'Poll opening procedures were not followed',
      'Poll closing procedures were not followed',
      'Absentee ballot drop off refused',
    ],
    'Provisional Ballots': [
      'Absentee Ballot Issue',
      'Ballot rejected',
      'Does not appear to be registered',
      'Eligibility/Challenge',
      'ID Issue',
      'Inactive',
      'Not enough provisional ballots',
      'Out of Precinct - Curable',
      'Out of Precinct - Not Curable',
      'Poll Worker refusing to provide ballot',
      'Proof of Residency Issue',
      'Registration information issues',
      'Wrong Party Registration',
      'Other',
    ],
    Registration: [
      'Registered at different polling place',
      'Not on pollbook',
      "Tried to update registration but it wasn't processed",
      'Data missing from pollbook',
      'Listed as inactive',
      'Purged from voting rolls',
      'Problem due to name change/other issue',
      'Problem due to alleged conviction',
      'Other',
    ],
    Results: [
      'Canvass irregularities',
      'Ballot - Rejected',
      'Ballot - Counted',
      'Ballot - Provisional or Set Aside',
      'Other',
    ],
    Subversion: [
      'Count disruption or interference',
      'Subversion Disinformation',
      'Election equipment tampering',
      'Poll worker or Election official harassment',
      'Certification interference or certification refusal',
      'Other',
    ],
    'Vote by Mail': [
      'Refusing to cancel ballot',
      'Ballot application issue',
      'Ballot application rejected',
      'Application approved but ballot never received by voter',
      'Ballot rejected',
      'Error on VBM Ballot',
      'Ballot returned but not processed',
      'Ballot returned to sender',
      'Ballot sent to wrong address',
      'Dropbox not accessible or secure',
      'Voter unable to return ballot',
      'Other',
    ],
    'Vote Counting Site': [
      'Voter Ineligible',
      'No Signature',
      'Signature Matching',
      'Identification Missing',
      'Other Envelope Problem',
      'Ballot Late',
      'Voter Eligibility Challenge',
      'Witness Issue',
      'Other Problem',
    ],
    'Voter Challenges / Intimidation': [
      'Misinformation',
      'Unhelpful law enforcement presence',
      'Voter intimidation inside polling place',
      'Voter intimidation outside polling place',
      'Physical altercation',
      'Non-English language materials/support not provided',
      'Other',
    ],
    'Voting Equipment and Machines': [
      'Voting Machine Malfunction',
      'Electronic pollbook malfunction',
      'Printer malfunction',
      'Not enough voting machines',
      'Not enough other supplies or equipment',
      'Not enough privacy booths',
      'Other',
    ],
  },
} as const;
