import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

@connect((state) => {
  const { lbj } = state;

  return {
    nearbyLocations: lbj.getIn(['nearby_locations', 'listData']),
  };
})
export default class GoogleMapContainer extends Component {
  static propTypes = {
    googleMaps: PropTypes.object.isRequired,
    center: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    nearbyLocations: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: Immutable.Map({}),
    };

    this.mapEl = React.createRef();
    this.infoWindowContent = React.createRef();
  }

  componentDidMount() {
    this.renderMap();
  }

  componentWillUpdate(nextProps) {
    if (
      this.renderedMap &&
      this.centerMarker &&
      nextProps.center !== this.props.center
    ) {
      this.centerMarker.setMap(null);
      this.panToCenter(nextProps.center);
    }

    if (
      this.renderedMap &&
      nextProps.nearbyLocations !== this.props.nearbyLocations
    ) {
      this.nearbyMarkers.forEach((marker) => marker.setMap(null));
      this.renderNearbyLocations(nextProps.nearbyLocations);
    }
  }

  componentDidUpdate() {
    if (this.state.selectedLocation && this.infoWindowContent.current) {
      this.infoWindow.setContent(this.infoWindowContent.current);
    }
  }

  componentWillUnmount() {
    this.renderedMap = null;
  }

  panToCenter(center) {
    const { googleMaps } = this.props;
    const iconSrc = require('../../../../img/star.svg');
    const [lng, lat] = center.toJS();
    this.centerMarker = new googleMaps.Marker({
      position: { lat, lng },
      icon: iconSrc,
    });
    this.centerMarker.setMap(this.renderedMap);
    this.renderedMap.panTo({ lat, lng });
  }

  handleInfoWindow(marker, map, infoWindow, location) {
    marker.addListener('click', () => {
      this.setState({ selectedLocation: location });
      this.infoWindow.open(map, marker);
    });
  }

  renderInfoWindow() {
    const { selectedLocation } = this.state;

    return (
      <div className="assignment-info-window">
        <h4 className="font-bold">{selectedLocation.get('name')}</h4>
      </div>
    );
  }

  renderNearbyLocations(nearbyLocations) {
    const { googleMaps } = this.props;

    const iconSrc = require('../../../../img/flag.svg');
    this.nearbyMarkers = [];

    nearbyLocations.forEach((location) => {
      const [lng, lat] = location.get('coordinates').toJS();
      const marker = new googleMaps.Marker({
        position: { lat, lng },
        icon: iconSrc,
      });
      marker.setMap(this.renderedMap);
      this.handleInfoWindow(
        marker,
        this.renderedMap,
        this.infoWindow,
        location
      );
      this.nearbyMarkers.push(marker);
    });
  }

  renderMap() {
    const { googleMaps } = this.props;
    const [lng, lat] = this.props.center.toJS();
    const center = new googleMaps.LatLng(lat, lng);
    this.renderedMap = new googleMaps.Map(this.mapEl.current, {
      center: center,
      scrollwheel: false,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: true,
    });
    this.infoWindow = new googleMaps.InfoWindow({
      content: this.infoWindowContent.current,
    });
    this.renderNearbyLocations(this.props.nearbyLocations);
    this.panToCenter(this.props.center);
  }

  render() {
    const flagSrc = require('../../../../img/flag.svg');
    const starSrc = require('../../../../img/star.svg');

    return (
      <div>
        <div ref={this.mapEl} id="map" className="assignment-map" />
        <div className="is-hidden">
          <div ref={this.infoWindowContent}>{this.renderInfoWindow()}</div>
        </div>
        <div className="assignment-map-legend row">
          <div className="col-small-12 col-6">
            <img src={starSrc} />
            This polling location
          </div>
          <div className="col-small-12 col-6">
            <img src={flagSrc} />
            Nearby polling locations
          </div>
        </div>
      </div>
    );
  }
}
