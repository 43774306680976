import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';
import { filterFilters } from '../filters/common';

function filterNotificationsFilters<
  OutK extends keyof FiltersBySection['NOTIFICATIONS']
>(state: AppState, keys: OutK[]) {
  return filterFilters(state.filters.NOTIFICATIONS, keys);
}

/**
 * Returns the relevant notifications filters.
 */
export default function mapStateToNotificationsFilters(state: AppState) {
  return filterNotificationsFilters(state, [
    'state',
    'county',
    'size',
    'offset',
    'created_date__lte',
    'seen',
    'acked',
  ]);
}
