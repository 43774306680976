import { requestStatuses } from '../../constants';
import * as EmailService from '../../services/email-service';
import { AppThunk } from '../flux-store';

import actionTypes from './action-types';

const {
  SEND_EMAIL,
  ADD_USERS_TO_QUEUE,
  REMOVE_USERS_FROM_QUEUE,
  RESET_EMAIL_RECEIPT,
  ADD_ALL_USERS_TO_QUEUE,
  REMOVE_ALL_USERS_FROM_QUEUE,
} = actionTypes;
const { PENDING, SUCCESS, ERROR } = requestStatuses;

export type Action =
  | AddUsersToQueueAction
  | RemoveUsersFromQueueAction
  | SendEmailAction
  | ResetEmailReceiptAction
  | AddAllUsersToQueueAction
  | RemoveAllUsersFromQueueAction;

export type AddUsersToQueueAction = {
  type: typeof ADD_USERS_TO_QUEUE;
  data: {
    userIds: number[];
  };
};

export function addUserToQueue(userId: number): AddUsersToQueueAction {
  return {
    type: ADD_USERS_TO_QUEUE,
    data: {
      userIds: [userId],
    },
  };
}

export type RemoveUsersFromQueueAction = {
  type: typeof REMOVE_USERS_FROM_QUEUE;
  data: {
    userIds: number[];
  };
};

export function removeUserFromQueue(
  userId: number
): RemoveUsersFromQueueAction {
  return {
    type: REMOVE_USERS_FROM_QUEUE,
    data: {
      userIds: [userId],
    },
  };
}

export type SendEmailAction = {
  type: typeof SEND_EMAIL;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        emailReceipt: { [key: string]: string };
      }
    | { status: typeof ERROR };
};

function startEmailRequest(): SendEmailAction {
  return {
    type: SEND_EMAIL,
    data: {
      status: PENDING,
    },
  };
}

function successfullyEmailRecipients(emailReceipt: {
  [key: string]: string;
}): SendEmailAction {
  return {
    type: SEND_EMAIL,
    data: {
      status: SUCCESS,
      emailReceipt,
    },
  };
}

function errorEmailingRecipients(): SendEmailAction {
  return {
    type: SEND_EMAIL,
    data: {
      status: ERROR,
    },
  };
}

export type ResetEmailReceiptAction = {
  type: typeof RESET_EMAIL_RECEIPT;
};

export function resetEmailReceipt(): ResetEmailReceiptAction {
  return {
    type: RESET_EMAIL_RECEIPT,
  };
}

/**
 * This action _sets_ the queue to the provided list, rather than adding to it.
 */
export type AddAllUsersToQueueAction = {
  type: typeof ADD_ALL_USERS_TO_QUEUE;
  data: {
    userIds: number[];
  };
};

export function addAllUsersToQueue(
  userIds: number[]
): AddAllUsersToQueueAction {
  return {
    type: ADD_ALL_USERS_TO_QUEUE,
    data: {
      userIds,
    },
  };
}

export type RemoveAllUsersFromQueueAction = {
  type: typeof REMOVE_ALL_USERS_FROM_QUEUE;
};

export function removeAllUsersFromQueue(): RemoveAllUsersFromQueueAction {
  return {
    type: REMOVE_ALL_USERS_FROM_QUEUE,
  };
}

export function emailUsersAsync(
  template: string,
  emailPayload: {
    [key: string]: string;
  }
): AppThunk<void> {
  return (dispatch) => {
    dispatch(startEmailRequest());

    EmailService.emailUsers(template, emailPayload).then(
      () => dispatch(successfullyEmailRecipients(emailPayload)),
      () => dispatch(errorEmailingRecipients())
    );
  };
}
