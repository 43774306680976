import React from 'react';
import { AriaTextFieldProps, chain } from 'react-aria';
import { TreeState } from 'react-stately';

import { useLazyIterable } from '../../utils/hooks';

import { Menu, MenuProps } from './Menu';
import { TextField } from './TextField';

/**
 * Version of {@link Menu} that includes a filter box on the top.
 *
 * Pressing down arrow from the text box moves focus to the top element of the
 * menu. Pressing enter in the menu triggers the action of the hovered element.
 *
 * Uses the react-stately Collection interface for specifying the items: either
 * pass {@link Section} and {@link Item} components as children, or pass an
 * array of objects in the `items` prop and pass a render function as the only
 * child.
 *
 * @see https://react-spectrum.adobe.com/react-stately/collections.html
 */
export function FilteredMenu<T extends {}>({
  filterFieldProps,
  filterItem,
  items,
  children,
  onOpenChange,
  noMatchMessage,
  contentWidthPx,
  ...menuProps
}: Omit<MenuProps<T>, 'menuRenderer'> & {
  filterFieldProps: AriaTextFieldProps;
  /**
   * Given a filter value and an item, should return true if the item matches
   * the filter value.
   */
  filterItem: (value: string, item: T) => boolean;
  contentWidthPx?: number | undefined;
  noMatchMessage: string;
}): React.ReactElement {
  const filterInputRef = React.useRef<HTMLInputElement>(null);
  const [filterValue, setFilterValue] = React.useState('');

  // Lazy since this isn’t needed until the menu is opened.
  const filteredItems = useLazyIterable(
    () =>
      [...(items ?? [])].filter((item) => filterItem(filterValue.trim(), item)),
    // We purposely don’t depend on `filterItem`, just the items and the input
    // value.
    [items, filterValue]
  );

  // We need to get access to the list state so we can hover elements after the
  // filter value changes, so we have this ref that gets assigned by our
  // contentRenderer.
  const listStateRef = React.useRef<TreeState<T> | null>(null);

  React.useEffect(() => {
    // When the filterValue changes, automatically focus the element if there’s
    // only one, but clear the focus if there are more.
    //
    // We do this in a useEffect rather than in the onKeyDown handler because we
    // need to wait until the re-render to have the re-filtered list.
    if (listStateRef.current) {
      if (listStateRef.current.collection.size === 1) {
        const firstKey = listStateRef.current.collection.getFirstKey() ?? null;
        listStateRef.current.selectionManager.setFocusedKey(firstKey);
      } else {
        listStateRef.current.selectionManager.setFocusedKey(null);
      }
    }
  }, [filterValue]);

  return (
    <Menu
      {...menuProps}
      emptyFallbackEl={
        <li className="py-2 text-center text-base italic text-gray-700">
          {noMatchMessage}
        </li>
      }
      items={filteredItems}
      onOpenChange={chain(onOpenChange, (isOpen: boolean) => {
        if (isOpen) {
          setFilterValue('');

          // We need this to override the default behavior that puts the focus
          // on the menu list itself when the popover opens.
          window.setImmediate(() => filterInputRef.current?.focus());
        }
      })}
      contentRenderer={(listViewEl, menuTriggerState, listState) => {
        listStateRef.current = listState;

        return (
          <div
            className="flex max-h-[40vh] flex-1 flex-col overflow-hidden"
            style={{ width: contentWidthPx }}
          >
            <TextField
              inputRef={filterInputRef}
              icon="search"
              variant="menu"
              {...filterFieldProps}
              value={filterValue}
              onChange={setFilterValue}
              // Arrowing down from the text box should go to the menu.
              //
              // TODO(fiona): Would be nice for arrowing up from the list
              // to go back up but that would be a mess to implement right
              // now. (And the default `useComboBox` doesn’t do it
              // anyway.)
              onKeyDown={chain(
                filterFieldProps.onKeyDown,
                (ev: React.KeyboardEvent) => {
                  switch (ev.key) {
                    case 'ArrowDown': {
                      // Move focus to the first element in the menu.
                      const firstKey =
                        listState.collection.getFirstKey() ?? null;

                      if (firstKey !== null) {
                        listState.selectionManager.setFocused(true);
                        listState.selectionManager.setFocusedKey(firstKey);

                        ev.preventDefault();
                      }
                      break;
                    }

                    case 'Escape':
                      // Close the menu.
                      menuTriggerState.close();
                      ev.preventDefault();
                      break;

                    case 'Enter': {
                      // “Action” the focused row. We have to do this by using
                      // `onAction` directly, as there’s no function on the
                      // selection manager or collection to programmatically
                      // “action” a key.
                      const focusedKey =
                        listState.selectionManager.focusedKey ?? null;

                      if (focusedKey !== null) {
                        menuProps.onAction?.(focusedKey);
                        if (menuProps.closeOnSelect !== false) {
                          menuTriggerState.close();
                        }

                        ev.preventDefault();
                      }
                      break;
                    }
                  }
                }
              )}
            />

            {listViewEl}
          </div>
        );
      }}
    >
      {children}
    </Menu>
  );
}
