import _ from 'lodash';
import React from 'react';

import {
  ApiElection,
  ApiLocationTierConfiguration,
} from '../../services/lbj-shared-service';

import { ActionButton } from './ActionButton';
import { IconButton } from './IconButton';
import { NumberField } from './NumberField';

const Tier: React.FunctionComponent<{
  tier: ApiLocationTierConfiguration;
  edayHasShiftChangeTime: boolean;
  index: number;
  prevTier: ApiLocationTierConfiguration | undefined;
  nextTier: ApiLocationTierConfiguration | undefined;
  replaceTier: (index: number, newTier: ApiLocationTierConfiguration) => void;
  removeTier: (index: number) => void;
}> = (props) => {
  const {
    tier,
    edayHasShiftChangeTime,
    index,
    prevTier,
    nextTier,
    replaceTier,
    removeTier,
  } = props;

  return (
    <>
      <div className="flex">
        <div className="flex items-center justify-evenly gap-x-4 font-bold">
          <IconButton
            icon={'cancel'}
            onPress={() => {
              removeTier(index);
            }}
            aria-label="delete tier"
          />
          Tier {index + 1}
        </div>
        <div className="flex flex-1 items-center justify-evenly">
          <NumberField
            value={tier.state_rank_gte}
            aria-label="tier start"
            minValue={prevTier ? prevTier.state_rank_gte + 1 : 1}
            maxValue={tier.state_rank_lte}
            isDisabled={tier.state_rank_gte === 1}
            inputClassName="w-[105px]"
            onChange={(val) => {
              replaceTier(index, {
                ...tier,
                state_rank_gte: val,
              });
              if (prevTier) {
                replaceTier(index - 1, {
                  ...prevTier,
                  state_rank_lte: val - 1,
                });
              }
            }}
          />
          -
          <NumberField
            value={tier.state_rank_lte}
            aria-label="tier end"
            minValue={tier.state_rank_gte}
            maxValue={
              nextTier ? nextTier.state_rank_lte - 1 : Number.MAX_SAFE_INTEGER
            }
            inputClassName="w-[105px]"
            onChange={(val) => {
              replaceTier(index, {
                ...tier,
                state_rank_lte: val,
              });
              if (nextTier) {
                replaceTier(index + 1, {
                  ...nextTier,
                  state_rank_gte: val + 1,
                });
              }
            }}
          />
        </div>
        <div className="flex flex-1 items-center justify-evenly">
          <NumberField
            label={edayHasShiftChangeTime && 'Morning shifts'}
            labelClassName={edayHasShiftChangeTime ? '-mt-5' : ''}
            value={tier.eday_am_shift_count ?? 1}
            aria-label={
              edayHasShiftChangeTime
                ? 'default morning shift count on election day'
                : 'all day shift count on election day'
            }
            minValue={0}
            maxValue={10}
            inputClassName="w-[105px]"
            onChange={(val) => {
              if (edayHasShiftChangeTime) {
                // if eday_shift_change_time exists
                // only apply value to eday_am_shift_count
                // a separate field will track eday_pm_shift_count
                replaceTier(index, {
                  ...tier,
                  eday_am_shift_count: val,
                });
              } else {
                // if there is no eday_shift_change_time
                // only one shift count input field appears
                // apply value to both am and pm
                // eday shift counts
                replaceTier(index, {
                  ...tier,
                  eday_am_shift_count: val,
                  eday_pm_shift_count: val,
                });
              }
            }}
          />
          {edayHasShiftChangeTime && (
            <>
              <NumberField
                label="Afternoon Shifts"
                labelClassName={edayHasShiftChangeTime ? '-mt-5' : ''}
                value={tier.eday_pm_shift_count ?? 1}
                aria-label="default afternoon shift count - election day"
                minValue={0}
                maxValue={10}
                inputClassName="w-[105px]"
                onChange={(val) => {
                  replaceTier(index, {
                    ...tier,
                    eday_pm_shift_count: val,
                  });
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const TierBuilder: React.FunctionComponent<{
  election: ApiElection;
  onTierBuilderChange: (tiers: ApiLocationTierConfiguration[]) => void;
  edayHasShiftChangeTime: boolean;
}> = (props) => {
  const { election, onTierBuilderChange, edayHasShiftChangeTime } = props;
  const locationTierConfiguration = election.location_tier_configuration;

  const [tiers, setTiers] = React.useState(locationTierConfiguration);

  const isLoadedRef = React.useRef(false);

  React.useEffect(() => {
    if (isLoadedRef.current == true) {
      onTierBuilderChange(tiers);
    } else {
      isLoadedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiers]);

  const addTier = () => {
    if (tiers.length > 0) {
      setTiers((tiers) => {
        const min = _.last(tiers)?.state_rank_lte as number; // will have a number value if tiers isn't empty
        return tiers.concat({
          state_rank_gte: min + 1,
          state_rank_lte: min + 10,
          eday_am_shift_count: 1, // default to 1 am eday shift
          eday_pm_shift_count: 1, // default to 1 pm eday shift
        });
      });
    } else {
      setTiers(() => [
        {
          state_rank_gte: 1,
          state_rank_lte: 10,
          eday_am_shift_count: 1, // default to 1 am eday shift
          eday_pm_shift_count: 1, // default to 1 pm eday shift
        },
      ]);
    }
  };

  const removeTier = (index: number) => {
    setTiers((tiers) => {
      const tiersCopy = tiers.slice();
      // if there's a next tier, merge the deleted tier with the next tier;
      // otherwise (ie, the tier is the last tier), just delete it
      if (tiers[index + 1]) {
        const currMin = tiers[index]!.state_rank_gte;
        const nextMax = tiers[index + 1]!.state_rank_lte;
        const mergedTier = {
          state_rank_gte: currMin,
          state_rank_lte: nextMax,
          eday_am_shift_count: 1, // default to 1 am eday shift
          eday_pm_shift_count: 1, // default to 1 pm eday shift
        };

        tiersCopy[index] = mergedTier; // replace current tier
        tiersCopy.splice(index + 1, 1); // delete next tier
      } else {
        tiersCopy.splice(index, 1);
      }
      return tiersCopy;
    });
  };

  const replaceTier = (
    index: number,
    newTier: ApiLocationTierConfiguration
  ) => {
    setTiers((tiers) => {
      const tiersCopy = tiers.slice();
      tiersCopy[index] = newTier;
      return tiersCopy;
    });
  };

  const TierBuilderHeaders = (
    <div className="mb-2 flex">
      <div className="basis-1/4"></div>
      <div className="flex-1 justify-evenly font-bold">Location Rankings</div>
      <div className="flex-1 justify-evenly font-bold">
        Default # of Election Day Shifts
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      {tiers.length >= 1 && TierBuilderHeaders}
      {tiers.map((tier, index) => {
        return (
          <div key={'tier_' + index} className="relative">
            <Tier
              tier={tier}
              index={index}
              prevTier={tiers[index - 1]}
              nextTier={tiers[index + 1]}
              replaceTier={replaceTier}
              removeTier={removeTier}
              edayHasShiftChangeTime={edayHasShiftChangeTime}
            />
            <div className="mb-4" />
          </div>
        );
      })}
      <div className="flex-none">
        <ActionButton
          icon="add_circle"
          role="secondary"
          onPress={() => {
            addTier();
          }}
        >
          Add tier
        </ActionButton>
      </div>
    </div>
  );
};
