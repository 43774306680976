import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TimeForm from '../../presentational/assignment/assignment-time-form';

@connect((state) => {
  const { assignment } = state;
  const assignmentData = assignment.getIn([
    'assignmentEditor',
    'updatedFields',
  ]);

  return {
    assignmentId: assignmentData.get('id'),
  };
})
export default class ShiftPickerContainer extends Component {
  static propTypes = {
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    assignmentDate: PropTypes.string.isRequired,
    assignmentId: PropTypes.number,
    onShiftChange: PropTypes.func.isRequired,
  };

  render() {
    const { startTime, endTime, assignmentDate, assignmentId, onShiftChange } =
      this.props;

    return (
      <TimeForm
        startTime={startTime}
        endTime={endTime}
        assignmentDate={assignmentDate}
        assignmentId={assignmentId}
        onInputChange={onShiftChange}
      />
    );
  }
}
