import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { assignmentViewTypes } from '../../../constants';
import { PaginationWrapper } from '../../../decorators/paginated-component';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import { AppState, toAppDispatch } from '../../../modules/flux-store';
import { ApiUserWithAssignments } from '../../../services/assignment-service';
import { mapStateToObserverFilters } from '../../../utils/assignment/map-state-to-assignment-filters';
import mapStateToPagination, {
  PaginationData,
} from '../../../utils/lbj/map-state-to-pagination';
import { MapFromJs } from '../../../utils/types';

import PersonItemV2 from '../../presentational/assignment/assignment-person-item-v2';
import EmptyAlert from '../../presentational/lbj/empty-alert';
import LoadingOverlay from '../../presentational/lbj/loading';

const { PEOPLE } = assignmentViewTypes;

const PeopleListContainerV2: React.FunctionComponent<{
  dispatch?: Dispatch<any>;
  filters: ReturnType<typeof mapStateToObserverFilters>;
  listData: Immutable.List<MapFromJs<ApiUserWithAssignments>>;
  listRequestIsPending: boolean;
  listRequestErred: boolean;
  paginationData: PaginationData;
  now?: moment.Moment;
}> = (props) => {
  const {
    listData,
    listRequestIsPending,
    paginationData,
    filters,
    now = moment(),
  } = props;
  const dispatch = toAppDispatch(props.dispatch);

  return (
    <PaginationWrapper
      paginationData={paginationData}
      setPage={(page) => {
        const { getAssignmentListByPersonAsync } = assignmentActionCreators;
        const filterPayload = { ...page, ...filters.toJS() };

        window.scrollTo(0, 0);

        return dispatch(getAssignmentListByPersonAsync(filterPayload));
      }}
    >
      <div className="lbj-column-content assignment-list-container-v2">
        {listRequestIsPending && <LoadingOverlay />}

        {listData.isEmpty() && !listRequestIsPending ? (
          <EmptyAlert
            header="There are no people"
            description="Make sure you've selected a state on the left or change existing filters"
          />
        ) : (
          <table className="assignment-list-table">
            <thead>
              <tr>
                <th className="user">Name</th>
                <th className="volunteer-roles">Volunteer Roles</th>
                <th className="assigned">Assigned</th>
                <th className="assignments">Assignment Location</th>
                <th className="checkin">Check-in Status</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((person) => {
                return (
                  <PersonItemV2
                    person={person}
                    key={person.get('id')}
                    now={now}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </PaginationWrapper>
  );
};

export default connect(
  (state: AppState): React.ComponentProps<typeof PeopleListContainerV2> => {
    const { assignment } = state;

    const paginationData = mapStateToPagination(assignment, [
      'assignmentIndex',
      PEOPLE,
    ]);

    // This is what `mapStateToAssignmentFilters` will call given that our
    // current view is going to be `PEOPLE`.
    const filters = mapStateToObserverFilters(state);

    const peopleListRequest = assignment.assignmentIndex.PEOPLE;

    return {
      filters,
      listData: peopleListRequest.listData,
      listRequestIsPending: peopleListRequest.requestIsPending,
      listRequestErred: peopleListRequest.requestErred,
      paginationData,
    };
  }
)(PeopleListContainerV2);
