import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DayPicker from 'react-day-picker';

import Modal from '../../presentational/lbj/modal';

export default class AssignmentDateModal extends Component {
  static propTypes = {
    available: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.isDisabled = this.isDisabled.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(day, { disabled }) {
    if (!disabled) {
      const asStr = moment(day).format('YYYY-MM-DD');
      this.props.onChange(asStr);
    }
  }

  getDayPicker() {
    const { view, selected } = this.props;
    const showCalendarWithDisabledDays =
      view === 'LOCATIONS' || view === 'PEOPLE';

    if (showCalendarWithDisabledDays) {
      return (
        <DayPicker
          disabledDays={this.isDisabled}
          initialMonth={moment(selected).toDate()}
          onDayClick={this.onChange}
          selectedDays={this.isSelected}
        />
      );
    }

    return (
      <DayPicker
        initialMonth={moment(selected).toDate()}
        onDayClick={this.onChange}
        selectedDays={this.isSelected}
      />
    );
  }

  /**
   * All non-available, non-*selected* days are "disabled"
   **/
  isDisabled(day) {
    const { available, selected } = this.props;
    const asStr = moment(day).format('YYYY-MM-DD');
    return !(asStr === selected || available.includes(asStr));
  }

  isSelected(day) {
    const asStr = moment(day).format('YYYY-MM-DD');
    return asStr === this.props.selected;
  }

  render() {
    const { onCancel } = this.props;
    return (
      <Modal dismissable headerText="Change Date" onClose={onCancel}>
        {this.getDayPicker()}
      </Modal>
    );
  }
}
