import cx from 'classnames';
import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections, METERS_TO_MILES_RATIO } from '../../../constants';
import { actionCreators } from '../../../modules/user';
import {
  convertMetersToMiles,
  formatDistanceFromMiles,
} from '../../../utils/assignment/distance';

import RIT from '../../../utils/render-if-truthy';

import EditorSearchResults from './assignment-editor-search-results';
import UserDetailsV2 from './assignment-user-details-v2';
import UserSearchFormV2 from './assignment-user-search-form-v2';

@connect((state) => {
  const { assignment } = state;
  const { ASSIGNMENT } = lbjAppSections;
  const filters = state.filters.get(ASSIGNMENT);
  const userResults = state.user.getIn(['users', 'autocomplete']);
  const assignmentType = assignment.getIn([
    'assignmentEditor',
    'assignmentType',
  ]);
  const updatedFields = assignment.getIn(['assignmentEditor', 'updatedFields']);
  const currentElection = state.user.getIn([
    'currentUser',
    'currentUserElection',
  ]);
  const voteDays = currentElection
    ? currentElection.getIn(['election', 'days'])
    : Immutable.List([]);
  const shiftDate = filters.get('dates');

  return {
    filters,
    updatedFields,
    userList: userResults.get('listData'),
    isLoadingList: userResults.get('requestIsPending'),
    assignmentType,
    voteDays,
    shiftDate,
  };
})
export default class FindUserFormV2 extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    county: PropTypes.object,
    geoFilterByLocation: PropTypes.number,
    geoFilterByPrecinct: PropTypes.number,
    includePlaceStatus: PropTypes.bool,
    filters: PropTypes.object,
    updatedFields: PropTypes.object.isRequired,
    user: PropTypes.object,
    userList: PropTypes.object,
    isLoadingList: PropTypes.bool,
    assignmentType: PropTypes.string,
    onEditAssignmentData: PropTypes.func,
    assignmentState: PropTypes.string.isRequired,
    voteDays: PropTypes.object.isRequired,
    shiftDate: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showOutOfRange: false,
    };
    this.lastSearch = {};

    this.onSearchForUsers = this.onSearchForUsers.bind(this);
    this.onShowOutOfRange = this.onShowOutOfRange.bind(this);
  }

  onSearchForUsers(filters) {
    const { dispatch } = this.props;
    const { getUserAutocompleteListAsync } = actionCreators;
    const filterPayload = _.assign(this.getDefaultSearchFilters(), filters);

    dispatch(getUserAutocompleteListAsync(filterPayload));
    this.lastSearch = filterPayload;
  }

  onShowOutOfRange(showOutOfRange) {
    this.setState({ showOutOfRange });
  }

  onGetMoreSearchResults({ offset }) {
    const { dispatch } = this.props;
    const { getUserAutocompleteListAsync } = actionCreators;
    const filterPayload = _.assign(this.lastSearch, { offset, size: 25 });

    dispatch(getUserAutocompleteListAsync(filterPayload, true));
  }

  getDefaultSearchFilters() {
    const { assignmentState, shiftDate } = this.props;

    return _.pickBy(
      {
        size: 25,
        expand: 'related,pii',
        assignment_state: assignmentState,
        dates: shiftDate,
      },
      (val) => !!val
    );
  }

  renderSearchForm() {
    const {
      county,
      assignmentType,
      assignmentState,
      voteDays,
      shiftDate,
      geoFilterByLocation,
    } = this.props;

    return (
      <UserSearchFormV2
        onSearchForUsers={this.onSearchForUsers}
        geoFilterByLocation={geoFilterByLocation}
        onShowOutOfRange={this.onShowOutOfRange}
        shiftDate={shiftDate}
        county={county}
        voteDays={voteDays}
        stateFilter={assignmentState}
        assignmentType={assignmentType}
      />
    );
  }

  renderUserResult(user, key) {
    const { assignmentState, onEditAssignmentData } = this.props;
    const userName = `${user.get('last_name')}, ${user.get('first_name')}`;
    const distanceVal = convertMetersToMiles(user.get('distance'));
    const distance = formatDistanceFromMiles(distanceVal);
    const maxDistanceVal = user.get('max_distance_miles');
    const maxDistance = formatDistanceFromMiles(maxDistanceVal);
    const hasConcurrentAssignment =
      user.has('related') && !user.get('related').isEmpty();
    const outOfState =
      user.get('state') && user.get('state') !== assignmentState;
    const outOfRange =
      distanceVal && maxDistanceVal && distanceVal > maxDistanceVal;
    const containerClassName = cx(`assignment-results-max-distance`, {
      'assignment-out-of-range': outOfRange,
    });
    return (
      <li onClick={onEditAssignmentData.bind(this, { user })} key={key}>
        <span className="assignment-results-username">{userName}</span>
        {RIT(distance || hasConcurrentAssignment || outOfState, () => {
          return (
            <span className="assignment-results-disclaimer">
              {RIT(outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    Out of state
                  </span>
                );
              })}

              {RIT(distance && !outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    {distance}
                  </span>
                );
              })}

              {RIT(maxDistance, () => {
                return (
                  <span className={containerClassName}>
                    (max {maxDistance})
                  </span>
                );
              })}

              {RIT(hasConcurrentAssignment, () => {
                return (
                  <span className="assignment-results-alert">
                    (assigned during this time)
                  </span>
                );
              })}
            </span>
          );
        })}
      </li>
    );
  }

  renderSearchResults() {
    const { isLoadingList } = this.props;
    const { showOutOfRange } = this.state;
    let { userList } = this.props;
    if (!showOutOfRange) {
      userList = userList.filter((user) => {
        return (
          !user.get('distance') ||
          !user.get('max_distance_miles') ||
          Math.floor(user.get('distance') / METERS_TO_MILES_RATIO) <
            user.get('max_distance_miles')
        );
      });
    }

    return (
      <div>
        <EditorSearchResults
          listData={userList}
          isLoadingList={isLoadingList}
          onGetMoreSearchResults={this.onGetMoreSearchResults.bind(this)}
          listElementRenderer={this.renderUserResult.bind(this)}
        />
      </div>
    );
  }

  renderUserDetails() {
    return (
      <div className="selected-volunteer">
        <UserDetailsV2 user={this.props.user} />
      </div>
    );
  }

  renderBackButton(assignemntDataToClear) {
    return (
      <button
        className="assignment-button__change link-button"
        onClick={this.props.onEditAssignmentData.bind(
          this,
          assignemntDataToClear
        )}
      >
        Change Volunteer
      </button>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className="assignment-find-form-v2">
        {!user && (
          <div>
            <h5 className="font-bold">Select Volunteer</h5>
            <p>
              Use sorting and filters to refine the list. By default, the list
              is sorted by closest volunteer to the location.
            </p>
          </div>
        )}

        {RIT(!user, this.renderSearchForm.bind(this))}
        {RIT(!user, this.renderSearchResults.bind(this))}
        {RIT(user, this.renderUserDetails.bind(this))}

        {RIT(user, this.renderBackButton.bind(this, { user: null }))}
      </div>
    );
  }
}
