import React from 'react';

const LoadingOverlay: React.FunctionComponent<{ text?: string }> = ({
  text = 'Loading…',
}) => (
  <div className="lbj-loading-overlay">
    <div className="lbj-loading-content">
      <div className="lbj-loading-icon" />
      <p>{text}</p>
    </div>
  </div>
);

export default LoadingOverlay;
