import React from 'react';

import { Item } from 'react-stately';

import { ActionButton, Menu } from '../../components/form';

import type { AssignmentPageUxDispatch } from './LocationAssignmentPage';
import type { AssignmentLoadingStatus } from './assignment-state';

/**
 * Button bar for “live mode,” includes buttons for popping up modals and
 * entering bulk editing mode.
 */
const AssignmentLiveModeButtons: React.FunctionComponent<{
  pageUxDispatch: AssignmentPageUxDispatch;
  assignmentStateLoadingStatus: AssignmentLoadingStatus;
  isSavingAutoAssignments: boolean;
}> = ({
  pageUxDispatch,
  assignmentStateLoadingStatus,
  isSavingAutoAssignments,
}) => {
  return (
    <div className="flex flex-1 flex-col items-end gap-2">
      <div className="flex items-center gap-2">
        <ActionButton
          onPress={() => pageUxDispatch({ type: 'START_BULK_EDIT_SHIFTS' })}
          role="secondary"
          isDisabled={assignmentStateLoadingStatus.status !== 'done'}
        >
          Add / Remove Shifts
        </ActionButton>

        <Menu
          selectionStyle="menu"
          trigger={(props, ref) => (
            <ActionButton
              buttonRef={ref}
              role="secondary"
              icon="arrow_drop_down"
              iconPosition="end"
              {...props}
            >
              Upload CSV
            </ActionButton>
          )}
          isDisabled={assignmentStateLoadingStatus.status !== 'done'}
          onAction={(key) => {
            if (key === 'rankings') {
              pageUxDispatch({ type: 'OPEN_METADATA_MODAL' });
            } else if (key === 'availability') {
              pageUxDispatch({ type: 'OPEN_VOLUNTEER_AVAILABILITY_MODAL' });
            }
          }}
        >
          <Item key="rankings">Upload Location Rankings…</Item>
          <Item key="availability">Upload Volunteer Availability…</Item>
        </Menu>

        <ActionButton
          onPress={() => pageUxDispatch({ type: 'OPEN_AUTO_ASSIGNMENT_MODAL' })}
          role="secondary"
          icon="smart_toy"
          iconPosition="start"
          isDisabled={
            assignmentStateLoadingStatus.status !== 'done' ||
            isSavingAutoAssignments
          }
        >
          Suggest Assignments
        </ActionButton>
      </div>
    </div>
  );
};

export default AssignmentLiveModeButtons;
