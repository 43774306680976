import React from 'react';

import EmptyAlert from '../../../components/presentational/lbj/empty-alert';
import {
  PaginatedPage,
  PaginationWrapper,
} from '../../../decorators/paginated-component';
import { ApiLineLengthCounts } from '../../../services/dashboard-service';
import { PaginationData } from '../../../utils/lbj/map-state-to-pagination';

const LINE_LENGTH_HEADING_LABELS = {
  threePlusHours: '3+ Hours',
  twoHours: '2 Hours',
  oneHour: '1 Hour',
  thirtyMins: '30 Minutes',
  noLine: 'No Line',
  completed: 'Voting has completed',
} as const;

export default class DashboardLineLengthsTable extends React.Component<{
  paginationData: PaginationData;
  setPage: (page: PaginatedPage) => void;

  results: ApiLineLengthCounts[];

  viewByValue: 'County' | 'State';

  sortSlug: string;
  sortDescending: boolean;
  toggleSort: (slug: string) => void;
}> {
  getSortAttributes(slug: keyof ApiLineLengthCounts) {
    const { sortSlug, sortDescending, toggleSort } = this.props;

    if (slug === sortSlug) {
      return {
        'data-sort': sortDescending ? 'DESC' : 'ASC',
        onClick: () => toggleSort(slug),
      };
    } else {
      return {
        'data-sort': '',
        onClick: () => toggleSort(slug),
      };
    }
  }

  renderLineLengthRow(place: ApiLineLengthCounts, key: string) {
    const { viewByValue } = this.props;
    const { threePlusHours, twoHours, oneHour, thirtyMins, noLine, completed } =
      LINE_LENGTH_HEADING_LABELS;

    return (
      <tr key={key}>
        <td>{place[viewByValue]}</td>

        <td>{place[threePlusHours] || 0}</td>

        <td>{place[twoHours] || 0}</td>

        <td>{place[oneHour] || 0}</td>

        <td>{place[thirtyMins] || 0}</td>

        <td>{place[noLine] || 0}</td>

        <td>{place[completed] || 0}</td>

        <td>{place['Total']}</td>
      </tr>
    );
  }

  renderTableHeaderCell(slug: keyof ApiLineLengthCounts) {
    return (
      <th data-slug={slug} {...this.getSortAttributes(slug)}>
        {slug}
      </th>
    );
  }

  render() {
    const { paginationData, setPage, results, viewByValue } = this.props;
    const { threePlusHours, twoHours, oneHour, thirtyMins, noLine, completed } =
      LINE_LENGTH_HEADING_LABELS;

    return (
      <PaginationWrapper paginationData={paginationData} setPage={setPage}>
        <div className="lbj-table-wrapper">
          <table className="lbj-table lbj-issue-list-table dashboard-table">
            <thead>
              <tr>
                {this.renderTableHeaderCell(viewByValue)}
                {this.renderTableHeaderCell(threePlusHours)}
                {this.renderTableHeaderCell(twoHours)}
                {this.renderTableHeaderCell(oneHour)}
                {this.renderTableHeaderCell(thirtyMins)}
                {this.renderTableHeaderCell(noLine)}
                {this.renderTableHeaderCell(completed)}
                {this.renderTableHeaderCell('Total')}
              </tr>
            </thead>

            <tbody>
              {results.map((r) => this.renderLineLengthRow(r, r.id))}
            </tbody>
          </table>

          {results.length === 0 && (
            <EmptyAlert
              header="There are no line length reports to show."
              description="Update the filters to check on another query."
            />
          )}
        </div>
      </PaginationWrapper>
    );
  }
}
