import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../constants';
import { AppState } from '../../modules/flux-store';
import { DetailedUser } from '../../modules/user/action-creators';
import { MapFromJs } from '../../utils/types';

import UserDetailContainer from './user-detail-container';

const { USER } = lbjAppSections;

class EditUserContainer extends Component<{
  user: MapFromJs<DetailedUser>;
  userIsLoading: boolean;
  filters: Immutable.Map<string, string | null | undefined>;
}> {
  render() {
    const { user, userIsLoading, filters } = this.props;
    const headerText = user
      ? `${user.get('first_name')} ${user.get('last_name')}`
      : '';
    const disableCancel = user ? user.get('role') === 'none' : true;

    return (
      <UserDetailContainer
        user={user}
        userIsLoading={userIsLoading}
        filters={filters}
        headerText={headerText}
        disableCancel={disableCancel}
      />
    );
  }
}

export default connect(
  (
    state: AppState
  ): Pick<EditUserContainer['props'], 'filters' | 'user' | 'userIsLoading'> => {
    const { user, filters } = state;

    return {
      filters: filters.get(USER),
      user: user.getIn(['userDetails', 'userData']) as MapFromJs<DetailedUser>,
      userIsLoading: user.userDetails.requestIsPending,
    };
  }
)(EditUserContainer);
