import PropTypes from 'prop-types';
import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

/**
 * The props added by {@link withRouting}.
 */
export type WithRoutingProps = {
  location: Location;
  navigate: NavigateFunction;
};

/**
 * For legacy JS components, the PropTypes for {@link withRouting}.
 */
export const WithRoutingPropTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

/**
 * Helper HOC that uses the react-router hooks for location and navigate and
 * passes them in as props to its underlying component.
 *
 * As components move from class components to function components, just use the
 * hooks directly.
 */
export const withRouting = <P extends object>(
  Component: React.ComponentType<P & WithRoutingProps>
): React.FunctionComponent<P> =>
  function WithRoutingWrapper(props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <Component {...props} location={location} navigate={navigate} />;
  };

/**
 * Parses the search string part of a {@link Location} with
 * {@link URLSearchParams} and converts it to an object, much like the old
 * `query` property from previous versions of React Router / React Router Redux.
 */
export function searchToQuery(search: string) {
  return Object.fromEntries(new URLSearchParams(search));
}

/**
 * Converts a query object of string -> string mappings to a URL search value
 * using {@link URLSearchParams}.
 *
 * This can then get passed as the `search` property to _e.g._ `navigate`.
 *
 * Removes `null` and `undefined` values since those exist in our filters but
 * should not be put into URLs.
 */
export function queryToSearch(query: { [key: string]: any }) {
  const params = new URLSearchParams();

  for (const key in query) {
    if (query[key] !== null && query[key] !== undefined) {
      params.set(key, query[key].toString());
    }
  }

  return params.toString();
}
