import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { assignmentAvailabilityTags } from '../../../constants';
import {
  isExcluded,
  convertToExcludedTag,
  extractExcludedTagName,
  getExcludedTags,
  getIncludedTags,
} from '../../../utils/user/tags';
import TagPicker from '../../containers/form/tag-picker-container';
import Checkbox from '../form/checkbox';
import DebouncedInput from '../form/debounced-input';

export default class UserSearchFormV2 extends Component {
  static propTypes = {
    county: PropTypes.object,
    stateFilter: PropTypes.string,
    shiftDate: PropTypes.string.isRequired,
    assignmentType: PropTypes.string,
    onSearchForUsers: PropTypes.func.isRequired,
    onShowOutOfRange: PropTypes.func.isRequired,
    voteDays: PropTypes.object.isRequired,
    geoFilterByLocation: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      availability_checked: false,
      sortUsersByName: false,
      showFilters: false,
      showSearch: false,
    };

    this.searchFilters = {
      tags: [], // VPDs are not regularly using user tags so none should appear by default
    };

    this.onFiltersClick = this.onFiltersClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onShowOutOfRange = this.onShowOutOfRange.bind(this);
    this.searchForSortedUsers = this.searchForSortedUsers.bind(this);
    this.onNameSortButtonClick = this.onNameSortButtonClick.bind(this);
    this.onDistanceSortButtonClick = this.onDistanceSortButtonClick.bind(this);
    this.onTagPickerChange = this.onTagPickerChange.bind(this);
    this.onStateInputChange = this.onStateInputChange.bind(this);
    this.onCountyInputChange = this.onCountyInputChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    this.searchForSortedUsers();
  }

  componentDidUpdate(prevProps) {
    const currentSelectedLocationId = this.props.geoFilterByLocation;
    const previousSelectedLocationId = prevProps.geoFilterByLocation;

    if (currentSelectedLocationId !== previousSelectedLocationId) {
      this.searchForSortedUsers();
    }
  }

  onFiltersClick() {
    this.setState({
      showFilters: !this.state.showFilters,
      showSearch: false,
    });
  }

  onSearchClick() {
    this.setState({
      showFilters: false,
      showSearch: !this.state.showSearch,
    });
  }

  onSearchChange({ target }) {
    const searchFilters = this.searchFilters;
    searchFilters.name_prefix = target.value;

    this.searchFilters = searchFilters;
    this.searchForSortedUsers();
  }

  onShowOutOfRange({ target }) {
    const { onShowOutOfRange } = this.props;

    let checked = false;
    if (target.checked) {
      checked = true;
    }
    this.setState({ showOutOfRange: checked });
    onShowOutOfRange(checked);
  }

  onTagPickerChange(selectedTags) {
    // don't eliminate excluded tags unless its inversion is in the
    // selected tags
    this.searchFilters.tags.forEach((tag) => {
      if (
        isExcluded(tag) &&
        !selectedTags.toJS().includes(extractExcludedTagName(tag))
      ) {
        selectedTags.push(tag);
      }
    });
    this.searchFilters.tags = selectedTags.toJS();

    this.searchForSortedUsers();
  }

  onTagPickerExclude(selectedTags) {
    const invertedTags = selectedTags
      .toJS()
      .map((tag) => convertToExcludedTag(tag));
    this.searchFilters.tags.forEach((tag) => {
      if (
        (!isExcluded(tag) &&
          !invertedTags.includes(convertToExcludedTag(tag))) ||
        assignmentAvailabilityTags.includes(tag)
      ) {
        invertedTags.push(tag);
      }
    });
    this.searchFilters.tags = invertedTags;

    this.searchForSortedUsers();
  }

  onOnlyAvailableChange({ target }) {
    if (target.checked) {
      this.searchFilters.tags = this.searchFilters.tags.concat(
        assignmentAvailabilityTags
      );
      this.setState({ availability_checked: true });
    } else {
      this.searchFilters.tags = this.searchFilters.tags.filter(
        (tag) => !assignmentAvailabilityTags.includes(tag)
      );
      this.setState({ availability_checked: false });
    }
    this.searchForSortedUsers();
  }

  onCountyInputChange({ target }) {
    const { name } = target;
    const { county } = this.props;

    if (target.checked) {
      this.searchFilters[name] = county.get('slug');
    } else {
      delete this.searchFilters[name];
    }

    this.searchForSortedUsers();
  }

  onStateInputChange({ target }) {
    const { stateFilter } = this.props;

    if (target.checked) {
      this.searchFilters.state = stateFilter;
    } else {
      delete this.searchFilters.state;
    }

    this.searchForSortedUsers();
  }

  onNameSortButtonClick() {
    this.setState({ sortUsersByName: true }, () => {
      this.searchForSortedUsers();
    });
  }

  onDistanceSortButtonClick() {
    this.setState({ sortUsersByName: false }, () => {
      this.searchForSortedUsers();
    });
  }

  getDefaultVolRoleTag(shiftDate, assignmentType, voteDays) {
    // TODO: handle this better, but for now satisfy
    //       product req to by default filter users based
    //       on their assigned availability role.
    const isElectionDay = voteDays
      .filter((d) => !d.get('is_early_vote'))
      .map((d) => d.get('date'))
      .contains(shiftDate);

    switch (assignmentType) {
      case 'hotline_center':
      case 'hotline_manager':
        if (isElectionDay) {
          return ['election_day_hotline_worker_volunteer'];
        }

        return ['early_vote_hotline_worker_volunteer'];

      case 'boiler_room':
        if (isElectionDay) {
          return ['election_day_boiler_room_volunteer'];
        }

        return ['early_vote_boiler_room_volunteer'];

      case 'board_of_elections':
      case 'poll':
      default:
        if (isElectionDay) {
          return ['election_day_poll_observer_volunteer'];
        }

        return ['early_vote_poll_observer_volunteer'];
    }
  }

  searchForSortedUsers() {
    const { sortUsersByName } = this.state;

    if (!sortUsersByName) {
      const { geoFilterByLocation } = this.props;
      const updatedSearchFilters = _.assign(this.searchFilters, {
        sort_from_location: geoFilterByLocation,
      });

      this.props.onSearchForUsers(updatedSearchFilters);
    }

    if (sortUsersByName) {
      // omitting sort_from_location, as it keeps getting appended to the filters.
      // check to see if there's a way around this.
      const filtersWithoutLocation = _.omit(this.searchFilters, [
        'sort_from_location',
      ]);
      const updatedSearchFilters = _.assign(filtersWithoutLocation, {
        sort_by_name: 'asc',
      });

      this.props.onSearchForUsers(updatedSearchFilters);
    }
  }

  shouldBeChecked(filter) {
    return _.includes(Object.keys(this.searchFilters), filter);
  }

  searchFilters = {};

  renderFilters() {
    const { county } = this.props;

    return (
      <div className="assignment-filters">
        <TagPicker
          title="Tags"
          name="tag"
          onChange={this.onTagPickerChange}
          excludes={[]}
          defaultValue={getIncludedTags(this.searchFilters.tags)}
        />

        <TagPicker
          title="Tags to exclude"
          name="excluded_tags"
          onChange={this.onTagPickerExclude.bind(this)}
          excludes={[]}
          defaultValue={getExcludedTags(this.searchFilters.tags)}
        />

        <Checkbox
          showLabel
          title="Available all day"
          name="availablity"
          checked={this.state.availability_checked}
          onChange={this.onOnlyAvailableChange.bind(this)}
        />

        <Checkbox
          showLabel
          title="Show out of range"
          name="distance"
          id="distance" // TODO: delete this line and change the test's selector
          checked={this.state.showOutOfRange}
          onChange={this.onShowOutOfRange}
        />

        <Checkbox
          showLabel
          title="Registered in state"
          name="state"
          checked={this.shouldBeChecked('state')}
          onChange={this.onStateInputChange}
        />

        {county && (
          <Checkbox
            showLabel
            title="Registered in county"
            name="county"
            checked={this.shouldBeChecked('county')}
            onChange={this.onCountyInputChange}
          />
        )}
      </div>
    );
  }

  renderSearch() {
    return (
      <div className="assignment-search">
        <DebouncedInput
          title="Find a user"
          name="name_prefix"
          placeholder="Name"
          onChange={this.onSearchChange}
        />
      </div>
    );
  }

  render() {
    const { showFilters, showSearch, sortUsersByName } = this.state;

    return (
      <div>
        <div className="search-form-controls">
          <div className="search-form-controls__sort">
            <label>Sort by</label>
            <button
              className={`link-button${
                !sortUsersByName ? ' link-button--active' : ''
              }`}
              onClick={this.onDistanceSortButtonClick}
            >
              Distance
            </button>
            |
            <button
              className={`link-button${
                sortUsersByName ? ' link-button--active' : ''
              }`}
              onClick={this.onNameSortButtonClick}
            >
              A-Z
            </button>
          </div>

          <div className="search-form-controls__toggle">
            <button
              className={`link-button--with-icon${
                showFilters ? ' toggle-on' : ''
              }`}
              onClick={this.onFiltersClick}
            >
              <span className="material-icons">filter_list</span>Filters
            </button>
            <button
              className={`link-button--with-icon${
                showSearch ? ' toggle-on' : ''
              }`}
              onClick={this.onSearchClick}
            >
              <span className="material-icons">search</span>Search
            </button>
          </div>
        </div>

        {showFilters && this.renderFilters()}
        {showSearch && this.renderSearch()}
      </div>
    );
  }
}
