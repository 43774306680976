import * as Immutable from 'immutable';
import moment from 'moment';
import React from 'react';

import {
  ApiLocationHours,
  ExpandApiLocationHours,
} from '../../services/assignment-service';
import { DateString, TimeString } from '../../services/common';
import { ApiLocation } from '../../services/lbj-shared-service';
import { MapFromJs } from '../types';

/**
 * Returns a matching {@link ApiLocationHours} entry for the location.
 *
 * We only trust the structured hours if they’re not tracking the election
 * default.
 */
export function trustedLocationHoursForDate(
  location: MapFromJs<ApiLocation & ExpandApiLocationHours>,
  date: DateString
) {
  const hours: Immutable.List<MapFromJs<ApiLocationHours>> = location.get(
    'hours',
    Immutable.List<MapFromJs<ApiLocationHours>>([])
  );

  const hoursForDate = hours.find(
    (hour) =>
      hour.get('date') === date &&
      hour.get('tracks_election_default') === 'none'
  );

  return hoursForDate ?? null;
}

/**
 * Returns a text string for the location’s hours. If they are structured and
 * “trusted,” meaning they are specifically set rather than tracking the
 * election’s defaults, we build a string out of them. Otherwise we use the
 * freeform `hoursText` value.
 *
 * If `includeTextHours` is true, we append the text hours value as an
 * italicized element. This is useful so that the user can see that the
 * presented hours are structured and not the election defaults.
 */
export function hoursAsText(
  location: MapFromJs<ApiLocation & ExpandApiLocationHours>,
  date: DateString,
  includeTextHours: boolean
): React.ReactElement {
  const hours = trustedLocationHoursForDate(location, date);
  const textHours: string = location.get('text_hours') ?? '';

  let structuredHours = '';

  if (hours) {
    if (hours.get('closed')) {
      structuredHours = 'Closed';
    } else {
      const renderTime = (time: TimeString) =>
        moment(`${date}T${time}`).format('h:mma');

      structuredHours = `${renderTime(hours.get('open_time'))}–${renderTime(
        hours.get('close_time')
      )}`;
    }
  }

  if (structuredHours && textHours && includeTextHours) {
    return (
      <span>
        {structuredHours}
        <br /> <i>({textHours})</i>
      </span>
    );
  } else if (structuredHours) {
    return <>{structuredHours}</>;
  } else {
    return <>{textHours}</>;
  }
}
