import authCookie from '../utils/auth-cookie';

export function authenticateRequest(xhr: JQuery.jqXHR) {
  const cookie = authCookie.get();

  if (cookie) {
    xhr.setRequestHeader('Authorization', `Token ${cookie.token}`);
  }
}

export const TEST_BASE_URL = 'http://api.lbj.test';

export function getLbjBaseUrl() {
  if (process.env['NODE_ENV'] === 'test') {
    return TEST_BASE_URL + '/';
  } else {
    // We know it will be defined because of webpack.config.js
    return process.env['API_URL']!;
  }
}
