import * as Immutable from 'immutable';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { actionCreators } from '../../../modules/assignment';
import { AppState, toAppDispatch } from '../../../modules/flux-store';
import { ApiUserWithAssignments } from '../../../services/assignment-service';
import mapUserToAssignments, {
  UserMappedAssignment,
} from '../../../utils/assignment/map-user-to-assignments';
import { MapFromJs } from '../../../utils/types';
import { formatPhoneNumber } from '../../../utils/user/phone-numbers';

import AssignmentTimelineV2 from './assignment-timeline-v2';

/**
 * "Availability" and "Volunteer Roles" are the same thing -- a filtered list
 * of user tags
 */
export function deriveAvailability(
  { lbj }: Pick<AppState, 'lbj'>,
  person: MapFromJs<ApiUserWithAssignments>
) {
  const tagsByTagName = Immutable.Map(
    lbj.tags.listData.map((t) => [t.get('name'), t])
  );

  const tagNames = person.get('tags') as Immutable.List<string>;

  const availability = tagNames
    .filter((name) => tagsByTagName.getIn([name, 'type']) === 'availability')
    .map((name) => tagsByTagName.getIn([name, 'display_name']) as string)
    .sort()
    .toArray();

  return availability.length ? availability : ['None'];
}

const PersonItemV2: React.FunctionComponent<{
  dispatch?: Dispatch<any>;
  availability: string[];
  person: MapFromJs<ApiUserWithAssignments>;
  isShowingDetails: boolean;
  now: moment.Moment;
}> = (props) => {
  const { person, availability, isShowingDetails, now } = props;
  const dispatch = toAppDispatch(props.dispatch);

  const showPersonDetails = () => {
    const { showDetails } = actionCreators;
    const assignments = mapUserToAssignments(person);

    dispatch(
      showDetails(
        {
          user: person,
        },
        assignments.isEmpty()
      )
    );
  };

  /**
   * AssignmentTimelineV2 works in plain JS so we convert to it and memoize,
   * since the conversion may be expensive.
   */
  const assignments = React.useMemo(
    () => mapUserToAssignments(person).toJS() as UserMappedAssignment[],
    [person]
  );

  return (
    <tr
      onClick={showPersonDetails}
      className={isShowingDetails ? 'selected' : ''}
    >
      <td className="user">
        <strong>{`${person.get('first_name')} ${person.get(
          'last_name'
        )}`}</strong>
        <br />
        {formatPhoneNumber(person.get('phone_number'))}
      </td>
      <td className="volunteer-roles">
        <ul className="list-unstyled">
          {availability.map((t) => (
            <li key={t}>{t}</li>
          ))}
        </ul>
      </td>
      <td className="assigned">{assignments.length === 0 ? 'No' : 'Yes'}</td>
      {/* Span two columns to accommodate the check-in status display */}
      <td colSpan={2}>
        {assignments.map((assignment) => (
          <AssignmentTimelineV2
            key={assignment.id}
            assignmentData={assignment}
            showCheckinStatus
            now={now}
          />
        ))}
      </td>
    </tr>
  );
};

export default connect(
  (
    { assignment, lbj }: AppState,
    { person }: Pick<React.ComponentProps<typeof PersonItemV2>, 'person'>
  ): Omit<React.ComponentProps<typeof PersonItemV2>, 'person' | 'now'> => {
    const isShowingDetails =
      assignment.assignmentEditor.parentResource.getIn(['user', 'id']) ===
      person.get('id');

    return {
      isShowingDetails,
      availability: deriveAvailability({ lbj }, person),
    };
  }
)(PersonItemV2);
