import React from 'react';

import CheckinContainer from '../components/containers/checkin/checkin-container';

import { RequireRole } from './app';

/**
 * Since the V2 container is essentially unconnected, we just need to check user
 * authorization.
 */
export const CheckInRoute: React.FunctionComponent = () => (
  <RequireRole
    allowedRoles={[
      'vpd',
      'deputy_vpd',
      'boiler_room_leader',
      'boiler_room_user',
      'hotline_manager',
      'hotline_worker',
      'poll_observer',
    ]}
  >
    <CheckinContainer />;
  </RequireRole>
);
