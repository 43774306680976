import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class OptionalField extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    label: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    };
  }

  showInput() {
    this.setState({
      hidden: false,
    });
  }

  renderButton() {
    const { label } = this.props;
    return (
      <button
        className="optional-field-button"
        type="button"
        onClick={this.showInput.bind(this)}
      >
        + {label}
      </button>
    );
  }

  renderField() {
    return this.props.children;
  }

  render() {
    const childProps = this.props.children.props;
    const isOpen =
      childProps.value || childProps.defaultValue ? true : !this.state.hidden;

    const wrapperClassName = cx('optional-field-wrapper', {
      'is-open': isOpen,
    });
    const fieldClassName = cx('optional-field');

    return (
      <div className={wrapperClassName}>
        {RIT(this.state.hidden, this.renderButton.bind(this))}
        <div className={fieldClassName}>{this.props.children}</div>
      </div>
    );
  }
}
