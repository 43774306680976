import $ from 'jquery';

function _is2xxStatusCode(statusCode: number) {
  return Math.floor(statusCode / 100) === 2;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function UrlClient(
  url: string,
  method = 'GET',
  options: {
    timeout?: number;
    retries?: number;
  } = {}
) {
  if (!url) throw new Error('UrlClient requires url');

  const retries = options.retries !== undefined ? options.retries : 0;

  return new Promise<string>((resolve, reject) => {
    const ajaxOptions: JQuery.AjaxSettings = {
      timeout: options.timeout || 30000,
      method,
      complete: (response) => {
        const responseData = response.responseText;
        const statusCode = response.status;

        if (_is2xxStatusCode(statusCode)) {
          resolve(responseData);
        } else if (response.statusText === 'timeout') {
          reject({ timeout: 'A request timed out' });
        } else {
          if (retries > 0) {
            sleep(5000).then(() => {
              UrlClient(url, method, { retries: retries - 1 }).then(
                (data) => resolve(data),
                (failure) => reject(failure)
              );
            });
          } else {
            reject(responseData || `${response.status} ${response.statusText}`);
          }
        }
      },
    };
    $.ajax(url, ajaxOptions);
  });
}
