export default {
  'one-voter': 'One voter affected',
  'multiple-voters': 'Multiple voters affected',
  'polling-place': 'Polling place affected',
  precinct: 'Precinct affected',
  county: 'County affected',
  region: 'Region affected',
  state: 'State affected',
  none: 'No voters affected (inaccurate report)',
};
