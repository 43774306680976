import _ from 'lodash';
import React from 'react';

import { SelectableUser } from '../../components/presentational/form/user-autocomplete';
import Sidebar from '../../components/presentational/lbj/sidebar';
import { issueViewTypes, State } from '../../constants';

import { IssuesIndexPermissions } from './issue-index';
import * as Filters from './issues-list-filters';
import {
  IssuesListFilters,
  IssuesListFilterUpdater,
  IssuesListLbjData,
} from './issues-list-utils';

const IssueFilterSidebar: React.FunctionComponent<
  {
    filters: IssuesListFilters;
    updateFilters: IssuesListFilterUpdater;
    permissions: Pick<
      IssuesIndexPermissions,
      'canFilterByUser' | 'canUseElectionSpecificFilters'
    >;
    currentElectionState: State;
    preloadedUsers: SelectableUser[];
  } & IssuesListLbjData
> = ({
  filters,
  updateFilters,
  permissions,
  currentElectionState,
  boilerRooms,
  counties,
  elections,
  districts,
  locations,
  precincts,
  regions,
  preloadedUsers,
}) => {
  const isAssigneeView = filters.view === issueViewTypes.ASSIGNED_TO_ME;
  const isBoilerRoomView = filters.view === issueViewTypes.BOILER_ROOM;

  const filterProps = { filters, updateFilters };

  return (
    <Sidebar title="FILTER / SEARCH" collapsible>
      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.SearchTermFilter {...filterProps} />
        <Filters.VoterSearchFilters {...filterProps} />
      </div>

      <div className="lbj-column-label lbj-divider">
        <h4>Election and Boiler Room Filters</h4>
      </div>

      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.ElectionFilter {...filterProps} elections={elections} />

        {!isBoilerRoomView && permissions.canUseElectionSpecificFilters && (
          <Filters.BoilerRoomFilter
            {...filterProps}
            boilerRooms={boilerRooms}
          />
        )}

        <Filters.BoilerRoomLevelFilter {...filterProps} />
      </div>

      <div className="lbj-column-label lbj-divider">
        <h4>Location Filters</h4>
      </div>

      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.StateRegionCountyFilters
          {...filterProps}
          currentElectionState={currentElectionState}
          counties={counties}
          regions={regions}
        />

        {permissions.canUseElectionSpecificFilters && (
          <>
            <Filters.PrecinctLocationFilters
              {...filterProps}
              locations={locations}
              precincts={precincts}
            />

            <Filters.DistrictFilters {...filterProps} districts={districts} />
          </>
        )}
      </div>

      <div className="lbj-column-label lbj-divider">
        <h4>Other Filters</h4>
      </div>

      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.StartEndDateFilters {...filterProps} />
        <Filters.StatusFilter {...filterProps} />
        <Filters.SourceFilter {...filterProps} />
        <Filters.IssueClassFilter {...filterProps} />
        <Filters.VoteStatusScopesFilters {...filterProps} />
        <Filters.PriorityFilter {...filterProps} />

        {currentElectionState === 'US' && (
          <Filters.QaReviewedFilter {...filterProps} />
        )}

        {!isAssigneeView && permissions.canFilterByUser && (
          <>
            <Filters.UnclaimedIssuesFilter {...filterProps} />
            <Filters.AssigneeFilter
              {...filterProps}
              preloadedUsers={preloadedUsers}
            />
          </>
        )}

        {permissions.canFilterByUser && (
          <Filters.ReporterFilter
            {...filterProps}
            preloadedUsers={preloadedUsers}
          />
        )}

        <Filters.CategoryFilter {...filterProps} />
        <Filters.FromPermanentFilter {...filterProps} />
        <Filters.RequiresFollowupFilter {...filterProps} />
      </div>
    </Sidebar>
  );
};

export default IssueFilterSidebar;
