import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import AssignmentTimeline from '../assignment/assignment-timeline';

export default class UserAssignmentForm extends Component {
  static propTypes = {
    isCurrentUser: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    assignmentDate: PropTypes.string.isRequired,
    currentElection: PropTypes.object.isRequired,
  };

  mapAssignments() {
    const { user } = this.props;

    return user.get('related').map((relatedObject) => {
      const assignment = relatedObject.get('assignment');

      return assignment
        .set('precinct', relatedObject.get('precinct'))
        .set('location', relatedObject.get('location'))
        .set('boiler_room', relatedObject.get('boiler_room'))
        .set('board_of_elections', relatedObject.get('board_of_elections'));
    });
  }

  renderAssignments(assignments) {
    return (
      <div className="col-small-9">
        {assignments.map((assignment, key) => {
          return (
            <div key={key}>
              <AssignmentTimeline
                assignmentData={assignment}
                isExistingAssignment
                showTimesInLabel
                showLabelAsCaption
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderEmptyState() {
    const { user, currentElection, isCurrentUser } = this.props;

    const contactEmail = currentElection?.get('contact_email');

    return (
      <div className="col-small-9">
        <p>{user.get('first_name')} has no assignments on this day.</p>

        {/* Only show 0-state helper message if user is looking at own page. */}
        {isCurrentUser && (
          <div
            className="c-alert-info c-alert-with-paragraphs c-alert-with-icon"
            style={{ marginTop: '20px' }}
          >
            <div className="c-alert-icon">
              <img src="../../../../img/info-blue.svg" alt="" />
            </div>

            <div className="c-alert-content">
              <p>Your assignments may not be loaded yet.</p>

              {contactEmail ? (
                <>
                  <p>
                    For assistance with your account or assignments, contact
                    your State Voter Protection Coordinator at{' '}
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
                  </p>
                  <p>
                    For technical support contact{' '}
                    <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>.
                  </p>
                </>
              ) : (
                <p>
                  For assistance with your account, assignments, or technical
                  support, contact{' '}
                  <a href={`mailto:lbj-help@dnc.org`}>lbj-help@dnc.org</a>.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { assignmentDate } = this.props;
    const assignments = this.mapAssignments();
    const hasNoAssignments = assignments.isEmpty();

    return (
      <div className="row">
        <div className="col-small-3">
          <strong>{moment(assignmentDate).format('MMMM Do')}</strong>
        </div>

        {RIT(hasNoAssignments, this.renderEmptyState.bind(this))}
        {RIT(!hasNoAssignments, this.renderAssignments.bind(this, assignments))}
      </div>
    );
  }
}
