import keyMirror from 'key-mirror';

export const ISSUE_NOTIF_TYPES = keyMirror({
  details_changed: null,
  assigned: null,
  resolved: null,
  reopened: null,
  comment: null,
  photo: null,
  escalation: null,
  deescalation: null,
});

export default {
  [ISSUE_NOTIF_TYPES.details_changed]: 'Details Changed',
  [ISSUE_NOTIF_TYPES.assigned]: 'Assigned',
  [ISSUE_NOTIF_TYPES.resolved]: 'Resolved',
  [ISSUE_NOTIF_TYPES.reopened]: 'Re-Opened',
  [ISSUE_NOTIF_TYPES.comment]: 'New Comment',
  [ISSUE_NOTIF_TYPES.photo]: 'Photo Added',
  [ISSUE_NOTIF_TYPES.escalation]: 'Escalated',
  [ISSUE_NOTIF_TYPES.deescalation]: 'De-escalated',
};
