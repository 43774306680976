import $ from 'jquery';

import { authenticateRequest, getLbjBaseUrl } from './api-utils';
import * as LocalStoreService from './local-store-service';

export default function UploadClient<T = unknown>(
  pathname: string,
  data: { [key: string]: string | Blob } = {}
) {
  if (!pathname) throw new Error('UploadClient requires pathname');

  const query: JQuery.PlainObject = {};

  const userElectionId = LocalStoreService.getCurrentUserElectionId();
  if (typeof userElectionId === 'number') {
    query['user_election_id'] = userElectionId;
  }

  const electionId = LocalStoreService.getCurrentElectionId();
  if (typeof electionId === 'number') {
    query['election_id'] = electionId;
  }

  return new Promise<T>((resolve, reject) => {
    const queryParams = $.param(query);

    const requestUrl = `${getLbjBaseUrl()}${pathname}?${queryParams}`;
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      // We know data[k] will exist because we’re using Object.keys.
      formData.append(k, data[k]!);
    });

    const ajaxOptions: JQuery.AjaxSettings = {
      timeout: 15000,
      processData: false,
      contentType: false,
      dataType: 'json',
      method: 'POST',
      data: formData,
      beforeSend: authenticateRequest,
      complete: (response) => {
        const { responseJSON, status } = response;
        if (status === 200 || status === 201) {
          resolve(responseJSON);
        } else {
          reject(responseJSON);
        }
      },
    };

    $.ajax(requestUrl, ajaxOptions);
  });
}
