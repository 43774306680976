import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import Datetime from '../form/datetime';

export default class AssignmentTimeForm extends Component {
  static propTypes = {
    assignmentId: PropTypes.number,
    assignmentDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {
        start_time: null,
        end_time: null,
      },
    };
  }

  onChange({ target, error }) {
    const { name, value } = target;
    const { onInputChange } = this.props;
    const timeData = {};

    if (error) {
      this.storeError(name, error);
      timeData[name] = null;
    } else {
      const offset = moment.parseZone(value).utcOffset();
      const updatedTime = moment.utc(value).add(offset, 'minutes');

      timeData[name] = updatedTime.format('HH:mm:ss');
      this.clearError(name);
    }

    onInputChange(timeData);
  }

  getDefaultTime(timeString) {
    const { assignmentDate } = this.props;

    return moment(`${assignmentDate}T${timeString}`).format('hh:mm a');
  }

  clearError(fieldName) {
    const { errors } = this.state;

    errors[fieldName] = null;
    this.setState({ errors });
  }

  storeError(fieldName, error) {
    const { errors } = this.state;

    errors[fieldName] = error;
    this.setState({ errors });
  }

  renderError(errorMsg) {
    return <span className="c-error-message">{errorMsg}</span>;
  }

  render() {
    const { startTime, endTime } = this.props;
    const { errors } = this.state;

    const endValue = this.getDefaultTime(endTime);
    const startValue = this.getDefaultTime(startTime);

    return (
      <div className="assignment-time-form">
        <div className="row">
          <div className="col-small-6">
            <Datetime
              name="start_time"
              title="Start Time"
              type="time"
              dateFormat={false}
              timeFormat="LT"
              viewMode="time"
              onChange={this.onChange.bind(this)}
              value={startValue}
              inputProps={{
                placeholder: '8:00 am',
              }}
              timeConstraints={{ minutes: { step: 15 } }}
            />
            {RIT(errors.start_time, () => this.renderError(errors.start_time))}
          </div>
          <div className="col-small-6">
            <Datetime
              name="end_time"
              title="End Time"
              type="time"
              dateFormat={false}
              timeFormat="LT"
              viewMode="time"
              onChange={this.onChange.bind(this)}
              value={endValue}
              inputProps={{
                placeholder: '10:00 pm',
              }}
              timeConstraints={{ minutes: { step: 15 } }}
            />
            {RIT(errors.end_time, () => this.renderError(errors.end_time))}
          </div>
        </div>
      </div>
    );
  }
}
