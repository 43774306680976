import { Type, type Static } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

import type { Immutable } from 'immer';

import type { DateString, TimeString } from '../../../services/common';
import type {
  ApiVolunteerAvailabilityGroup,
  NewApiVolunteerAvailability,
} from '../../../services/volunteer-availability-service';
import { StringEnumType } from '../../../utils/types';
import type { AssignmentUser } from '../assignment-state';

import { RowParseResult } from './VolunteerAvailabilityDialogResults';

/**
 * Options found in the “Status” field of the VAN signup CSV.
 *
 * May not be exhaustive.
 */
export const VanSignupStatusSchema = StringEnumType([
  'Completed',
  'No Show',
  'Declined',
  'Scheduled',
  'Confirmed',
]);

export type VanSignupStatus = Static<typeof VanSignupStatusSchema>;

/**
 * JSONSchema / TypeBox type for the VAN rows we expect to see.
 *
 * Does not enforce string formats, we end up doing that separately where
 * necessary to parse into {@link ParsedVanSignupRow}.
 */
export const VanSignupRowSchema = Type.Object({
  VanID: Type.String(),
  Event: Type.String(),
  /** Format is M/DD/YYYY */
  Date: Type.String(),
  /** Format is "8:00 AM - 4:30 PM" */
  Time: Type.String(),
  Location: Type.String(),
  /** Format is last, first */
  Name: Type.String(),
  /** Format is (###) ###-#### */
  Phone: Type.String(),
  /** Format is (###) ###-#### */
  'Cell Phone': Type.String(),
  Email: Type.String(),
  Role: Type.String(),
  Status: Type.Union([VanSignupStatusSchema, Type.Literal('')]),
});

/**
 * Type for the CSV rows we get from a VAN signup CSV export.
 */
export type VanSignupRow = Static<typeof VanSignupRowSchema>;

/**
 * VAN signup row after we’ve internally re-formatted it.
 */
export type ParsedVanSignupRow = {
  VanID: number | null;
  Event: string;
  /** Original format is M/DD/YYYY */
  Date: DateString;
  /**
   * Original format is "8:00 AM - 4:30 PM"
   *
   * We make this an array to support when we’ve combined separate signups and
   * someone has multiple times during the same day.
   */
  Time: [TimeString, TimeString][];
  Location: string;
  /** Format is last, first */
  Name: string;
  /** Format is (###) ###-#### */
  Phone: string;
  /** Format is (###) ###-#### */
  'Cell Phone': string;
  Email: string;
  Role: string;
  Status: VanSignupStatus | '';
};

/**
 * The fields we need to see as CSV columns for {@link VanSignupRowSchema}.
 */
export const VAN_SIGNUP_ROW_FIELDS = Object.keys(VanSignupRowSchema.properties);

/**
 * Takes a given row and returns the {@link ParsedVanSignupRow}, if it checks
 * out.
 */
export function parseVanSignupRow(row: {
  [key: string]: string;
}): RowParseResult<ParsedVanSignupRow> {
  if (!Value.Check(VanSignupRowSchema, row)) {
    return {
      type: 'failure',
      message: [...Value.Errors(VanSignupRowSchema, row)]
        .map(
          ({ path, message, value }) =>
            `${path.substring(1)}: ${message} (got "${value}")`
        )
        .join(', '),
    };
  }

  const parsedDate = parseVanSignupDate(row.Date);
  const parsedTime = parseVanSignupTime(row.Time);

  if (!parsedDate) {
    return { type: 'failure', message: `Unsupported date format: ${row.Date}` };
  }

  if (!parsedTime) {
    return {
      type: 'failure',
      message: `Unsupported time range format: ${row.Time}`,
    };
  }

  return {
    type: 'success',
    row: {
      ...row,
      VanID: row.VanID ? parseInt(row.VanID) : null,
      Date: parsedDate,
      Time: [parsedTime],
    },
  };
}

/** M/DD/YYYY */
const VAN_DATE_REGEXP = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/;

/** H:MM AM */
const SINGLE_VAN_TIME_REGEXP = /^([0-9]{1,2}):([0-9]{2}) (AM|PM)$/;

/**
 * Parses VAN’s M/DD/YYYY into our ISO8601 YYYY-MM-DD.
 */
export function parseVanSignupDate(dateStr: string): DateString | null {
  const dateMatch = dateStr.match(VAN_DATE_REGEXP);

  if (!dateMatch) {
    return null;
  } else {
    return `${dateMatch[3]}-${dateMatch[1]!.padStart(
      2,
      '0'
    )}-${dateMatch[2]!.padStart(2, '0')}` as DateString;
  }
}

/**
 * Parses VAN’s "8:00 AM - 4:30 PM" into two ISO8601 24 time values.
 */
export function parseVanSignupTime(
  timeStr: string
): [TimeString, TimeString] | null {
  const timeStrs = timeStr.split(' - ');

  if (timeStrs.length !== 2) {
    return null;
  }

  const matchA = timeStrs[0]!.match(SINGLE_VAN_TIME_REGEXP);
  const matchB = timeStrs[1]!.match(SINGLE_VAN_TIME_REGEXP);

  if (!matchA || !matchB) {
    return null;
  }

  return [
    timeBitsToTimeString(matchA[1]!, matchA[2]!, matchA[3]!),
    timeBitsToTimeString(matchB[1]!, matchB[2]!, matchB[3]!),
  ];
}

/**
 * Little helper for converting 12h string pieces into a 24h ISO8601 string.
 */
function timeBitsToTimeString(
  hoursStr: string,
  minutesStr: string,
  amPm: string
): TimeString {
  let hours = parseInt(hoursStr);

  if (hours === 12) {
    if (amPm === 'AM') {
      hours = 0;
    }
  } else if (amPm === 'PM') {
    hours += 12;
  }

  return `${hours.toString().padStart(2, '0')}:${minutesStr}:00` as TimeString;
}

/**
 * Looks for multiple signups on the same day from the same person and combines
 * them, then simplifies the combinations.
 *
 * We need this function because the VAN signup spreadsheet will have one row
 * per signup, meaning that the same person can have multiple rows for the same
 * day if they signed up for more than one shift.
 */
export function mergeVanSignupTimes(
  rows: ParsedVanSignupRow[]
): ParsedVanSignupRow[] {
  const rowsByVolunteer: { [vanIdOrEmail: string]: ParsedVanSignupRow[] } = {};

  // Collect all of the rows by volunteer. We prefer Van ID but support cases
  // where it’s blank for hand-rolled CSVs.
  for (const r of rows) {
    if (r.VanID) {
      const arr = rowsByVolunteer[r.VanID] ?? [];
      arr.push(r);
      rowsByVolunteer[r.VanID] = arr;
    } else {
      const arr = rowsByVolunteer[r.Email] ?? [];
      arr.push(r);
      rowsByVolunteer[r.Email] = arr;
    }
  }

  // For each volunteer’s entries, we bring together separate rows that are on
  // the same date, then combine the times within them.
  for (const volunteerKey of Object.keys(rowsByVolunteer)) {
    const combinedRows = [...rowsByVolunteer[volunteerKey]!];

    // Sort everything for this volunteer by date so that we can loop through
    // and chomp the rows in front of us.
    combinedRows.sort((r1, r2) => {
      if (r1.Date < r2.Date) {
        return -1;
      } else if (r1.Date > r2.Date) {
        return 1;
      } else {
        return 0;
      }
    });

    // - 1 because we’re doing a one-el lookahead in the loop
    for (let i = 0; i < combinedRows.length - 1; ++i) {
      const row = combinedRows[i]!;
      let nextRow = combinedRows[i + 1];

      const combinedTimes = [...row.Time];

      // Pull all future rows in, so long as their dates match.
      while (nextRow && nextRow.Date === row.Date) {
        combinedTimes.push(...nextRow.Time);
        combinedRows.splice(i + 1, 1);
        // This will be the next row (or undefined if we hit the end) because we
        // just spliced out the previous next row.
        nextRow = combinedRows[i + 1];
      }

      // Since we’ve finished collecting all the times in this row, we can
      // coallesce them and merge adjacent time ranges together.
      row.Time = coallesceTimeRanges(combinedTimes);
    }

    rowsByVolunteer[volunteerKey] = combinedRows;
  }

  return Object.values(rowsByVolunteer).flat(1);
}

/**
 * Takes a list of time ranges and combines ones that are consecutive or
 * overlap.
 *
 * _E.g._: [["08:00:00", "12:00:00"], ["12:00:00", "18:00:00"]] transforms to
 * [["08:00:00", "18:00:00"]]
 */
export function coallesceTimeRanges(
  times: [TimeString, TimeString][]
): [TimeString, TimeString][] {
  if (times.length < 2) {
    return times;
  }

  // Copy because we’ll be modifying.
  times = [...times];

  // Sort by start time.
  times.sort((t1, t2) => {
    if (t1[0] < t2[0]) {
      return -1;
    } else if (t1[0] > t2[0]) {
      return 1;
    } else {
      return 0;
    }
  });

  // - 1 because we’re doing a one-el lookahead in the loop
  for (let i = 0; i < times.length - 1; ++i) {
    const timeRange = times[i]!;
    let nextTimeRange = times[i + 1];

    // Merge time ranges where the end of the first equals or is after the start
    // of the 2nd.
    while (nextTimeRange && timeRange[1] >= nextTimeRange[0]) {
      if (nextTimeRange[1] > timeRange[1]) {
        timeRange[1] = nextTimeRange[1];
      }

      // Remove the time range we just incorporated and get the next one.
      times.splice(i + 1, 1);
      nextTimeRange = times[i + 1];
    }
  }

  return times;
}

export function matchVanSignupRowsToUsers(
  rows: ParsedVanSignupRow[],
  usersById: Immutable<Map<number, AssignmentUser>>
): {
  matched: [ParsedVanSignupRow, number][];
  unmatched: ParsedVanSignupRow[];
} {
  const userIdsByEmail = new Map<string, number>();
  const userIdsByVanId = new Map<number, number>();

  for (const user of usersById.values()) {
    userIdsByEmail.set(user.email.toLowerCase(), user.id);

    if (user.van_id) {
      userIdsByVanId.set(user.van_id, user.id);
    }
  }

  const matched: [ParsedVanSignupRow, number][] = [];
  const unmatched: ParsedVanSignupRow[] = [];

  for (const row of rows) {
    if (row.VanID && userIdsByVanId.has(row.VanID)) {
      matched.push([row, userIdsByVanId.get(row.VanID)!]);
    } else if (userIdsByEmail.has(row.Email)) {
      matched.push([row, userIdsByEmail.get(row.Email)!]);
    } else {
      unmatched.push(row);
    }
  }

  return { matched, unmatched };
}

/**
 * Given a set of signup rows (typically those that we couldn’t match to
 * volunteers), returns unique user records, sorted by name.
 */
export function uniqueVanUsersFromSignups(
  rows: ParsedVanSignupRow[]
): Pick<ParsedVanSignupRow, 'Name' | 'Email' | 'VanID'>[] {
  // This uniques the rows by VanID or Email.
  const rowsByUniqueId = new Map(rows.map((r) => [r.VanID || r.Email, r]));

  const uniqueUsers = [...rowsByUniqueId.values()].map(
    ({ Name, Email, VanID }) => ({ Name, Email, VanID })
  );

  uniqueUsers.sort((u1, u2) => {
    if (u1.Name < u2.Name) {
      return -1;
    } else if (u1.Name > u2.Name) {
      return 1;
    } else {
      return 0;
    }
  });

  return uniqueUsers;
}

/**
 * Converts our VAN signup row to a {@link NewApiVolunteerAvailability}, using
 * the provided user ID.
 */
export function vanSignupToAvailability(
  availabilityGroup: ApiVolunteerAvailabilityGroup,
  row: ParsedVanSignupRow,
  userId: number
): NewApiVolunteerAvailability {
  return {
    user_id: userId,
    date: row.Date,

    time: 'custom',
    custom_times: row.Time,

    allowed_location_cities: availabilityGroup.default_allowed_location_cities,
    allowed_location_county_slugs:
      availabilityGroup.default_allowed_location_county_slugs,
    respect_travel_distance_tag:
      availabilityGroup.default_respect_travel_distance_tag,
  };
}
