import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.string,
    dismissable: PropTypes.bool.isRequired,
    headerText: PropTypes.node.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => {},
  };

  onModalCloseLinkClick(e) {
    e.preventDefault();
    this.props.onClose();
  }

  renderModalBackground() {
    const { dismissable, onClose } = this.props;

    if (dismissable) {
      return <div className="lbj-modal-background" onClick={onClose} />;
    }

    return <div className="lbj-modal-background" />;
  }

  renderModalHeader() {
    const { dismissable, headerText } = this.props;

    return (
      <header className="lbj-modal-header" id="lbj-modal-header">
        {headerText}

        {RIT(dismissable, () => {
          return (
            <a
              href="#"
              onClick={this.onModalCloseLinkClick.bind(this)}
              className="lbj-modal-close a-float-right"
            />
          );
        })}
      </header>
    );
  }

  render() {
    const { children, classes } = this.props;
    const className = cx('lbj-modal-wrapper', classes);

    return (
      <div className={className}>
        {this.renderModalBackground()}
        <div
          className="lbj-modal-content"
          role="dialog"
          aria-labelledby="lbj-modal-header"
        >
          {this.renderModalHeader()}

          {children}
        </div>
      </div>
    );
  }
}
