import * as Immutable from 'immutable';
import moment from 'moment';

import {
  issueViewTypes,
  lbjAppSections,
  assignmentViewTypes,
  userViewTypes,
  State,
} from '../../constants';
import { CountySlug, DateString } from '../../services/common';
import { NestedKeyOf } from '../../utils/types';
import { AppAction } from '../flux-store';

import {
  RemoveFilterAction,
  FiltersBySection,
  SetFiltersAction,
  SetCurrentUserDefaultsAction,
  AddFilterAction,
} from './action-creators';
import actionTypes from './action-types';

const { ISSUE, ASSIGNMENT, CHECKINS, ANALYTICS, PROFILE, USER, NOTIFICATIONS } =
  lbjAppSections;

/**
 * These are paths through {@link FiltersState}, which, because it’s a 2-level
 * structure, tend to be tuples where the first arg is an {@link LbjAppSection}
 * and the second arg is a filter attribute under that section.
 */
type FiltersStatePath = Immutable.List<
  keyof FiltersBySection | NestedKeyOf<FiltersBySection>
>;

/**
 * Type wrapper around making a path that ensures that the first part of the
 * path matches the keys we’re using for our filter state, and the second part
 * is an attribute that’s valid within that key.
 */
function makePath<S extends keyof FiltersBySection>(
  section: S,
  attr: keyof FiltersBySection[S]
): FiltersStatePath {
  // We repeat the type here so that TS doesn’t expand section/attr to "string"
  return Immutable.List<keyof FiltersBySection | NestedKeyOf<FiltersBySection>>(
    [section, attr as any]
  );
}

/**
 * Map that groups “paths” in the state that should be modified together. If any
 * path in one of the lists changes, all of the paths should be modified.
 *
 * The keys to this map are here to label the groups.
 */
const SYNCED_PATHS = Immutable.Map<unknown, Immutable.List<FiltersStatePath>>()
  .set(
    'state',
    Immutable.List([
      makePath(ISSUE, 'state'),
      makePath(ASSIGNMENT, 'assignment_state'),
      makePath(ASSIGNMENT, 'state'),
      makePath(CHECKINS, 'state'),
      makePath(ANALYTICS, 'state'),
      makePath(USER, 'assignment_state'),
    ])
  )
  .set(
    'county',
    Immutable.List([
      makePath(ISSUE, 'county'),
      makePath(ASSIGNMENT, 'county'),
      makePath(CHECKINS, 'county'),
      makePath(ANALYTICS, 'county'),
    ])
  );

// The values in this Record are all Immutable.Map since we need to allow
// somewhat arbitrary keys right now.
export class FiltersState extends Immutable.Record({
  ISSUE: Immutable.Map<string, string | null | undefined>({
    state: null,
    query_election_id: null,
    ordering: '-id',
    group_by: 'precinct',
    view: issueViewTypes.ALL,
  }),
  ASSIGNMENT: Immutable.Map<string, string | null | undefined>({
    view: assignmentViewTypes.LOCATIONS,
    dates: moment().format('YYYY-MM-DD') as DateString,
  }),
  CHECKINS: Immutable.Map<string, string | null | undefined>(),
  DASHBOARD: Immutable.Map<string, string | null | undefined>({}),
  ANALYTICS: Immutable.Map<string, string | null | undefined>({
    date_lte: moment().format('YYYY-MM-DD') as DateString,
    date_gte: null,
    state: null,
    ordering: 'Name',
    group_by: 'category',
  }),
  PROFILE: Immutable.Map<string, string | null | undefined>({
    dates: moment().format('YYYY-MM-DD') as DateString,
    assignment_state: '',
  }),
  USER: Immutable.Map<string, string | null | undefined>({
    assignment_state: '',
    state: '',
    view: userViewTypes.ASSIGNMENT_NOTIFY,
  }),
  NOTIFICATIONS: Immutable.Map<string, string | null | undefined>({
    state: '',
  }),
}) {}

export const initialState = new FiltersState();

/**
 * Given a path that’s being changed, returns a sequence of all paths that
 * should be changed in the same way. Used to synchronize county or state
 * changes across views.
 */
function getPathsToModify(path: FiltersStatePath) {
  // By default only modify the path that’s passed in.
  let toModify:
    | Immutable.List<FiltersStatePath>
    | Immutable.Seq<unknown, FiltersStatePath> = Immutable.List([path]);

  SYNCED_PATHS.forEach((paths) => {
    const pathSet = paths!.toSet();

    // If the path we’re modifying is in the group, then modify the whole group.
    if (pathSet.contains(path)) {
      toModify = pathSet.toSeq();

      // Stops iteration.
      return false;
    }
  });

  return toModify;
}

function handleCurrentUserFilters(
  state: FiltersState,
  data: SetCurrentUserDefaultsAction['data']
) {
  const { currentUser } = data;

  // TODO(fiona): Type this as ApiUser['assignment_state']
  const assignmentState = currentUser.get('assignment_state');

  return state
    .setIn(makePath(ISSUE, 'state'), assignmentState)
    .setIn(makePath(ASSIGNMENT, 'state'), assignmentState)
    .setIn(makePath(ASSIGNMENT, 'assignment_state'), assignmentState)
    .setIn(makePath(ANALYTICS, 'state'), assignmentState)
    .setIn(makePath(USER, 'state'), assignmentState)
    .setIn(makePath(USER, 'assignment_state'), assignmentState)
    .setIn(makePath(PROFILE, 'assignment_state'), assignmentState)
    .setIn(makePath(NOTIFICATIONS, 'state'), assignmentState);
}

/**
 * Returns `true` if the given {@link CountySlug} value is for the given
 * {@link State}.
 */
function countyMatchesState(
  countyValue: CountySlug | '',
  stateValue: State | ''
) {
  if (!countyValue) {
    return true;
  }

  if (!stateValue) {
    return false;
  }

  // ! is safe because split’s return value will always have at least one
  // element
  const countyState = countyValue.split('-')[0]!;
  return stateValue.toLowerCase() === countyState.toLowerCase();
}

/**
 * Given the current Redux state of the filters, if the provided path is
 * indicating that a `state` filter value is changing, this will reset any
 * equivalent `county` filter value at the same time.
 */
function clearCountyAsNeeded(state: FiltersState, path: FiltersStatePath) {
  const filterAttr = path.last();

  if (filterAttr !== 'state' && filterAttr !== 'assignment_state') {
    return state;
  }

  const countyPath = path.set(-1, 'county');
  if (
    !countyMatchesState(
      state.getIn(countyPath) as CountySlug,
      state.getIn(path) as State
    )
  ) {
    return state.deleteIn(countyPath);
  }

  return state;
}

function handleFilterAdd<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
>(state: FiltersState, data: AddFilterAction<S, A>['data']) {
  const { appSection, filterAttr, filterVal } = data;

  const toModify = getPathsToModify(makePath(appSection, filterAttr));

  let newState = state;
  toModify.forEach((path) => {
    newState = newState.setIn(path!, filterVal);
    newState = clearCountyAsNeeded(newState, path!);
  });
  return newState;
}

function handleFilterDelete<
  S extends keyof FiltersBySection,
  A extends keyof FiltersBySection[S]
>(state: FiltersState, data: RemoveFilterAction<S, A>['data']) {
  const { appSection, filterAttr } = data;

  const toModify = getPathsToModify(makePath(appSection, filterAttr));

  let newState = state;
  toModify.forEach((path) => {
    newState = newState.deleteIn(path!);
    newState = clearCountyAsNeeded(newState, path!);
  });
  return newState;
}

function handleFilterSet<S extends keyof FiltersBySection>(
  state: FiltersState,
  data: SetFiltersAction<S>['data']
) {
  const { appSection, filters } = data;

  // This is a merge, so it preserves any other filters in place.
  //
  // "as any" because the keys don’t line up.
  const updatedSectionState = initialState
    .get(appSection)
    // filters is an I.Map of string -> string.
    .merge(/*4.1 SAFE*/ filters as Immutable.Map<any, any>);

  // update the app section
  let newState = state.setIn([appSection], updatedSectionState);

  // update any synced paths
  updatedSectionState.forEach((filterVal, filterAttr) => {
    getPathsToModify(
      makePath(appSection, filterAttr! as keyof FiltersBySection[S])
    ).forEach((path) => {
      newState = newState.setIn(path!, filterVal);
    });
  });

  // update any clearable dependencies
  updatedSectionState.forEach((_, filterAttr) => {
    getPathsToModify(
      makePath(appSection, filterAttr! as keyof FiltersBySection[S])
    ).forEach((path) => {
      newState = clearCountyAsNeeded(newState, path!);
    });
  });

  return newState;
}

export default function filters(state = initialState, action: AppAction) {
  const { ADD_FILTER, REMOVE_FILTER, SET_CURRENT_USER_DEFAULTS, SET_FILTERS } =
    actionTypes;

  switch (action.type) {
    case ADD_FILTER:
      return handleFilterAdd(state, action.data);

    case REMOVE_FILTER:
      return handleFilterDelete(state, action.data);

    case SET_CURRENT_USER_DEFAULTS:
      return handleCurrentUserFilters(state, action.data);

    case SET_FILTERS:
      return handleFilterSet(state, action.data);

    default:
      return state;
  }
}
