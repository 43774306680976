import type {
  AriaLabelingProps,
  CollectionStateBase,
  HelpTextProps,
  InputBase,
  LabelableProps,
  Node,
  MultipleSelection,
} from '@react-types/shared';
import cx from 'classnames';
import React from 'react';
import { useTagGroup } from 'react-aria';
import { useListState, Item } from 'react-stately';

import { ComboBoxMenuButton } from './ComboBox';
import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import { Menu } from './Menu';
import { Tag } from './Tag';

/**
 * Renders a list of items as a combination of popup menu and tag group. There
 * is not a react-aria hook that handles this case directly, so we cobble it
 * together from a tag group for rendering the tags (and handling keyboard
 * navigation) and a menu for showing the available tag options.
 *
 * Uses the selection props/callbacks for determining what tags are shown, to
 * match the behavior of {@link ComboBox} and {@link Select}.
 *
 * Use the standard `react-aria` Collections interface to pass the available
 * tags (_i.e._ `items` prop or `<Item>` children).
 */
export function TagComboBox<T extends object>({
  // Pull out selection props explicitly to keep them from `useTagGroup`.
  selectedKeys,
  onSelectionChange,
  defaultSelectedKeys,
  placeholder,
  ...props
}: CollectionStateBase<T> &
  LabelableProps &
  AriaLabelingProps &
  HelpTextProps &
  InputBase &
  Omit<MultipleSelection, 'selectionMode'> & {
    placeholder?: string | undefined;
  }): React.ReactElement {
  // This useListState parses out the `items` prop and children to get a
  // complete collection of the available tags. We use this list state’s
  // selection state for this control.
  const state = useListState({
    selectedKeys: selectedKeys!,
    selectionMode: 'multiple',
    onSelectionChange: onSelectionChange!,
    defaultSelectedKeys: defaultSelectedKeys!,
    ...props,
  });

  /**
   * onRemove passed to our `useTagGroup` so that clicking “remove” on a `<Tag>`
   * deselects it at the control level.
   */
  const onRemove = (removedKeys: Set<React.Key>) => {
    state.selectionManager.setSelectedKeys(
      [...state.selectionManager.selectedKeys].filter(
        (k) => !removedKeys.has(k)
      )
    );
  };

  // We filter just down to the active nodes for rendering the Tag group, but
  // also return them in the order that maches inputValue so they show up the
  // same way they were selected by the user.
  const filterSelectedItemsToTagGroup = React.useCallback(
    (nodes: Iterable<Node<T>>) => {
      const nodesByKeys: { [k: React.Key]: Node<T> } = {};

      for (const node of nodes) {
        nodesByKeys[node.key] = node;
      }

      return [...state.selectionManager.selectedKeys]
        .filter((k) => k in nodesByKeys)
        .map((k) => nodesByKeys[k]!);
    },
    [state.selectionManager.selectedKeys]
  );

  /**
   * All of the items that are not currently selected, to be shown in the
   * dropdown menu.
   */
  const unselectedItems = React.useMemo(
    () =>
      [...state.collection].filter(
        (item) => !state.selectionManager.selectedKeys.has(item.key)
      ),
    [state.collection, state.selectionManager.selectedKeys]
  );

  // This useListState also parses the complete list of tags from the props, but
  // we apply the filter so that only selected tags make it into the collection.
  // This will then be rendered by `useTagGroup`.
  const tagGroupState = useListState({
    ...props,
    selectionMode: 'none',
    filter: filterSelectedItemsToTagGroup,
  });

  const gridRef = React.useRef(null);
  const menuTriggerRef = React.useRef<HTMLButtonElement | null>(null);

  const { gridProps, labelProps, descriptionProps, errorMessageProps } =
    useTagGroup(
      {
        ...props,
        ...(!props.isReadOnly ? { onRemove } : {}),
      },
      tagGroupState,
      gridRef
    );

  return (
    <div className="flex flex-col gap-2">
      {typeof props.label === 'string' ? (
        <FormLabel type="span" {...labelProps}>
          {props.label}
        </FormLabel>
      ) : (
        props.label
      )}

      <div className="flex self-stretch">
        <div
          {...gridProps}
          ref={gridRef}
          className={cx(
            'flex min-h-[40px] flex-1 flex-wrap gap-2 rounded-l border-2  border-gray-300 px-2 py-1',
            {
              // If we’re read-only we don’t render the right buton, which
              // usually takes care of both the right border and the rounded
              // edges.
              'border-r-0': !props.isReadOnly,
              'rounded-r': props.isReadOnly,
              'bg-white': !props.isDisabled,
              'cursor-not-allowed bg-gray-200': props.isDisabled,
            }
          )}
          onClick={(ev) => {
            // We want clicking on the empty parts of the box (or the
            // placeholder) to open up the menu, but if the user clicks on a
            // `<Tag>` rendered within we don’t want to open the menu.
            if (
              ev.target === gridRef.current ||
              (ev.target as HTMLElement).getAttribute('data-placeholder')
            ) {
              menuTriggerRef.current?.click();
            }
          }}
        >
          {[...tagGroupState.collection].map((item) => (
            <Tag
              key={item.key}
              item={item}
              state={tagGroupState}
              size="small"
            />
          ))}

          {tagGroupState.collection.size === 0 && placeholder && (
            <span
              className="self-center text-base italic text-gray-700"
              data-placeholder="true"
            >
              {placeholder}
            </span>
          )}
        </div>

        {!props.isReadOnly && (
          <Menu
            triggerRef={menuTriggerRef}
            trigger={(triggerProps, ref) => (
              <ComboBoxMenuButton
                ref={ref}
                isDisabled={!!props.isDisabled}
                {...triggerProps}
              />
            )}
            /*
            Keeps the menu open after each
            selection so that it’s clear
            you can select multiple items
            */
            selectionMode="multiple"
            selectionStyle="menu"
            isDisabled={
              // don’t show menu if there are no items in it
              unselectedItems.length === 0 || !!props.isDisabled
            }
            onAction={(key) => state.selectionManager.select(key)}
          >
            {unselectedItems.map((node) => (
              <Item key={node.key}>{node.rendered}</Item>
            ))}
          </Menu>
        )}
      </div>

      {props.description && (
        <div {...descriptionProps}>{props.description}</div>
      )}

      {props.errorMessage && (
        <FormError {...errorMessageProps}>{props.errorMessage}</FormError>
      )}
    </div>
  );
}
