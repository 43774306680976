/**
 * Returns a new set that’s the combination of all the elements from the
 * provided sets.
 */
export function union<T>(...sets: Iterable<T>[]): Set<T> {
  const outSet = new Set<T>();

  for (const set of sets) {
    for (const el of set) {
      outSet.add(el);
    }
  }

  return outSet;
}

/**
 * Intersects several sets of elements. Always returns a new Set, even if it was
 * called on only a single Set. If called on no Sets, returns an empty Set.
 */
export function intersect<T>(
  firstSet?: ReadonlySet<T>,
  ...restSets: ReadonlySet<T>[]
): Set<T> {
  if (!firstSet) {
    return new Set();
  } else if (restSets.length === 0) {
    return new Set(firstSet);
  } else {
    const restIntersection = intersect(...restSets);
    const intersection = new Set<T>();

    for (const el of firstSet) {
      if (restIntersection.has(el)) {
        intersection.add(el);
      }
    }

    return intersection;
  }
}

/**
 * Returns a new Set that is all of the elements of `firstSet` that are not
 * present in any of the `restSets`.
 */
export function difference<T>(
  firstSet: ReadonlySet<T>,
  ...restSets: ReadonlySet<T>[]
): Set<T> {
  const outSet = new Set<T>();
  const combinedSet = union(...restSets);

  for (const el of firstSet) {
    if (!combinedSet.has(el)) {
      outSet.add(el);
    }
  }

  return outSet;
}
