export default [
  'Lawyers Bound for Justice',
  'Lonely Bureaucrats Juggling',
  'Lightfooted Badminton Jammers',
  'Legally Badass Judges',
  'Leggings Beat Jeggings',
  'Little Bouncing Jockey',
  'Laconic Baristas Jabbering',
  'Loyal Barristers Justifying',
  'Lazy Bankers Jousting',
  'Let’s Buy Jack-o’-lanterns',
  'Leftover Blueberry Jello',
  'Luscious Beautiful Java',
  'Left-Brained Jackfruit',
  'Lefty Bongo Jamboree',
  'Luxurious Bee Jelly',
  'Lads Buying Jorts',
  'Lesbians Being Gorjus',
  'Lucy Barfing Judiciously',
  'Love for Biden, Joe',
];
