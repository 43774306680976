import { GoogleCredentialResponse, GoogleLogin } from '@react-oauth/google';
import cx from 'classnames';
import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { PageTitle } from '../../components/common';

import { useStateContactInfo } from '../../components/hooks/lbj-data';
import Input from '../../components/presentational/form/input';
import SelectInput, {
  SEPARATOR_VALUE,
} from '../../components/presentational/form/select';
import { State } from '../../constants';
import STATES, { isState } from '../../constants/states';
import * as LoginService from '../../services/login-service';
import { captureException } from '../../services/sentry-service';
import { useStateWithDeps } from '../../utils/hooks';
import { isValidEmail } from '../../utils/user/emails';

import AuthUiModal from './AuthUiModal';

type StateOrWesteros = State | typeof LoginService.WESTEROS_STATE_CODE;

/**
 * Email sending state for the login modal.
 *
 * Many of these options have their own `email` property, which is distinct from
 * the `email` prop that’s passed into the component, since the latter is
 * controlling the current form state and may have changed since email was sent.
 */
export type LoginModalState =
  | { status: 'default' }
  | {
      status: 'emailSending';
      sendingEmail: string;
      sendingState: StateOrWesteros;
    }
  | {
      status: 'emailSent';
      sentEmail: string;
      sentState: StateOrWesteros;
      contactEmail: string | null;
    }
  | {
      status: 'emailError';
      code: LoginService.ApiSendLoginEmailErrorCode | null;
      message: string;
      erroredEmail: string;
      erroredState: StateOrWesteros;
      contactEmail: string | null;
    }
  | {
      status: 'alternateEmailAvailable';
      contactEmail: string | null;
      originalEmail: string;
      originalState: StateOrWesteros;
      elidedEmail: string;
      emailToken: string;
    }
  | {
      status: 'alternateEmailSending';
      contactEmail: string | null;
      originalEmail: string;
      originalState: StateOrWesteros;
      elidedEmail: string;
      emailToken: string;
    }
  | {
      status: 'googleEmailError';
    };

type SendLoginEmailFunc = (toSend: {
  /** If emailToken is provided, this is the elided email. */
  email: string;
  state: StateOrWesteros;
  emailToken?: string;
}) => void;

/**
 * Wrapper around `<LoginModalView>` to manage the state and API requests.
 *
 * Looks for `login_email` and `login_state` query parameters to use as
 * defaults.
 */
const LoginFormPage: React.FunctionComponent = () => {
  const [params] = useSearchParams();

  const defaultState = params.get('login_state');
  const defaultEmail = params.get('login_email') ?? '';

  const [state, setState] = React.useState<StateOrWesteros | ''>(
    isState(defaultState) ? defaultState : ''
  );

  const [email, setEmail] = React.useState(defaultEmail);

  const [loginState, setLoginState] = useStateWithDeps<LoginModalState>(
    {
      status: 'default',
    },
    // If the selected state changes we reset the loginState so that errors are
    // removed.
    [state]
  );

  // We fall back to an empty contact list because we don’t want to block login
  // on the success of this API call. We’re okay with the race condition of it
  // appearing since in normal circumstances it _should_ succeed by the time the
  // user has typed their email address in and hit submit.
  const contactInfoMap = useStateContactInfo() || {};

  // Small ref to prevent double-calls to the endpoint.
  const sendingLoginEmailRef = React.useRef(false);

  const sendLoginEmail: SendLoginEmailFunc = async ({
    email,
    state,
    emailToken,
  }) => {
    if (!email || sendingLoginEmailRef.current) {
      return;
    }

    try {
      sendingLoginEmailRef.current = true;

      setLoginState((prevLoginState) => {
        if (prevLoginState.status === 'alternateEmailAvailable') {
          return {
            ...prevLoginState,
            // These two states share the same data, since we’re showing a
            // complex error message.
            status: 'alternateEmailSending',
          };
        } else {
          return {
            status: 'emailSending',
            sendingEmail: email,
            sendingState: state,
          };
        }
      });

      const contactEmail =
        (state !== LoginService.WESTEROS_STATE_CODE &&
          contactInfoMap[state]?.contact) ||
        null;

      const result = await LoginService.sendLoginEmail(
        email,
        state,
        emailToken
      );

      if (result.status === 'success') {
        setLoginState({
          status: 'emailSent',
          sentEmail: email,
          sentState: state,
          contactEmail,
        });
      } else if (result.status === 'error') {
        const { error } = result;

        if (error.code === 'ALTERNATE_EMAIL') {
          setLoginState({
            status: 'alternateEmailAvailable',
            contactEmail,
            elidedEmail: error.email,
            emailToken: error.emailToken,
            originalEmail: email,
            originalState: state,
          });
        } else {
          setLoginState({
            status: 'emailError',
            contactEmail,
            erroredEmail: email,
            erroredState: state,
            code: error.code ?? null,
            message:
              error.errorMessage ??
              'There was an error logging in to your account.',
          });
        }
      }
    } finally {
      sendingLoginEmailRef.current = false;
    }
  };

  return (
    <LoginModalView
      state={state}
      email={email}
      setState={setState}
      setEmail={setEmail}
      loginState={loginState}
      sendLoginEmail={sendLoginEmail}
      tryDifferentEmail={() => {
        setLoginState({ status: 'default' });
        setEmail('');
      }}
      googleLoginFailed={(resp) => {
        // The exception method and error name
        //  distinguish between a Google problem
        // and a Google user LBJ-BE can't authenticate
        captureException('LBJ BE - google authentication error', {
          type: 'auth',
          method: 'googleLogin',
          details: resp.non_field_errors,
        });
        setLoginState({
          status: 'googleEmailError',
        });
      }}
      logGoogleErrorToSentry={(resp) => {
        // The exception method and error name
        //  distinguish between a Google problem
        // and a Google user LBJ-BE can't authenticate
        captureException('Google side - google authentication error', {
          type: 'auth',
          method: 'googleLogin-OnError',
          // unclear what the error response might be; capture the whole response
          details: resp,
        });
      }}
      clearGoogleStatus={() => {
        setLoginState({
          status: 'default',
        });
      }}
    />
  );
};

function getStateName(state: StateOrWesteros): string {
  if (state === LoginService.WESTEROS_STATE_CODE) {
    return 'National';
  } else {
    return STATES[state];
  }
}

export const LoginModalView: React.FunctionComponent<{
  state: StateOrWesteros | '';
  email: string;
  setState: (state: StateOrWesteros) => void;
  setEmail: (email: string) => void;
  loginState: LoginModalState;
  sendLoginEmail: SendLoginEmailFunc;
  tryDifferentEmail: () => void;
  googleClientId?: string;
  googleLoginFailed: (resp?: any) => void;
  logGoogleErrorToSentry: (resp?: any) => void;
  clearGoogleStatus: () => void;
}> = ({
  state,
  setState,
  email,
  setEmail,
  loginState,
  sendLoginEmail,
  tryDifferentEmail,
  googleLoginFailed,
  clearGoogleStatus,
  logGoogleErrorToSentry,
}) => {
  const emailIsValid = isValidEmail(email);
  const navigate = useNavigate();
  // Used so we don’t show an error box while you’re typing.
  const [emailIsFocused, setEmailIsFocused] = React.useState(false);

  const stateSelectChoices = makeStateSelectChoices();

  const onSubmit =
    email && state && emailIsValid
      ? (ev: React.FormEvent) => {
          ev.preventDefault();

          sendLoginEmail({ email, state });
        }
      : undefined;

  return (
    <AuthUiModal>
      <PageTitle>Login</PageTitle>

      {loginState.status === 'emailSent' && (
        <>
          <p>
            Your login email has been sent to{' '}
            <strong>{loginState.sentEmail}</strong>.
          </p>

          <p>
            Check your promotions or spam folders if you do not see the login
            email.
          </p>

          <div className="c-alert-info">
            {loginState.contactEmail ? (
              <>
                <p>
                  For assistance with your login, account, or assignments,
                  contact the {getStateName(loginState.sentState)} Voter
                  Protection Coordinator at{' '}
                  <strong>
                    <a href={`mailto:${loginState.contactEmail}`}>
                      {loginState.contactEmail}
                    </a>
                  </strong>
                  .
                </p>

                <p>
                  For technical support, your State Voter Protection Coordinator
                  can submit a request for help.
                </p>
              </>
            ) : (
              <p>
                For assistance with your login, account, or assignments, contact{' '}
                <strong>
                  <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>
                </strong>
                .
              </p>
            )}
          </div>
        </>
      )}

      {loginState.status === 'emailError' && (
        <div className="c-alert-error">
          {(() => {
            switch (loginState.code) {
              case 'EMAIL_NOT_FOUND':
                return (
                  <>
                    <p>
                      We couldn’t find an account for{' '}
                      <strong>{loginState.erroredEmail}</strong>.
                    </p>
                    <p>
                      {loginState.contactEmail ? (
                        <>
                          Please check for typos, try a different email, or
                          contact the {getStateName(loginState.erroredState)}{' '}
                          Voter Protection Coordinator at{' '}
                          <strong>
                            <a href={`mailto:${loginState.contactEmail}`}>
                              {loginState.contactEmail}
                            </a>
                          </strong>
                          .
                        </>
                      ) : (
                        <>
                          Please check for typos, try a different email, or
                          contact{' '}
                          <strong>
                            <a href="mailto:lbj-help@dnc.org">
                              lbj-help@dnc.org
                            </a>
                          </strong>
                          .
                        </>
                      )}
                    </p>
                  </>
                );

              // Messaging is the same whether the user has no elections at all
              // or if they aren’t on the state that they chose.
              case 'NO_ELECTIONS':
              case 'WRONG_STATE':
                return (
                  <>
                    <p>
                      {/* We use stateSelectChoices here to match the select box. */}
                      The LBJ account <strong>{loginState.erroredEmail}</strong>{' '}
                      has not been activated for{' '}
                      <strong>
                        {stateSelectChoices[loginState.erroredState]}
                      </strong>
                      .
                    </p>
                    <p>
                      {loginState.contactEmail ? (
                        <>
                          For assistance, please contact the{' '}
                          {getStateName(loginState.erroredState)} Voter
                          Protection Coordinator at{' '}
                          <strong>
                            <a href={`mailto:${loginState.contactEmail}`}>
                              {loginState.contactEmail}
                            </a>
                          </strong>
                          .
                        </>
                      ) : (
                        <>
                          For assistance, please contact{' '}
                          <strong>
                            <a href="mailto:lbj-help@dnc.org">
                              lbj-help@dnc.org
                            </a>
                          </strong>
                          .
                        </>
                      )}
                    </p>
                  </>
                );

              default:
                return loginState.message;
            }
          })()}
        </div>
      )}

      {loginState.status === 'googleEmailError' && (
        <div className="c-alert-error">
          <p>
            It may be that we could not find a user with that Google Account.
            Please try a different account, or enter your email address in the
            form below.
          </p>

          <p>
            If you’re still having trouble, reach out to your state’s volunteer
            coordinator or email{' '}
            <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>.
          </p>
        </div>
      )}

      {(loginState.status === 'alternateEmailAvailable' ||
        loginState.status === 'alternateEmailSending') && (
        <>
          <div className="c-alert-error">
            <p>
              We couldn’t find an account for{' '}
              <strong>{loginState.originalEmail}</strong>.
            </p>
          </div>

          <p>
            But, we found a different LBJ email address that might be yours:{' '}
            <strong>{loginState.elidedEmail}</strong>.
          </p>

          <p>
            If that address looks familiar, we can send a login link there.
            (Some characters are hidden to protect user privacy.)
          </p>

          <p style={{ margin: '1.75em 0 2.5em' }}>
            <button
              type="submit"
              disabled={loginState.status === 'alternateEmailSending'}
              className="c-button-large c-button-wide c-button-secondary"
              onClick={(ev) => {
                ev.preventDefault();
                sendLoginEmail({
                  email: loginState.elidedEmail,
                  state: loginState.originalState,
                  emailToken: loginState.emailToken,
                });
              }}
            >
              Send Login Link
            </button>
          </p>

          <p>
            {loginState.contactEmail ? (
              <>
                If you don’t recognize that address, please check for typos, try
                a different email, or contact the{' '}
                {getStateName(loginState.originalState)} Voter Protection
                Coordinator at{' '}
                <strong>
                  <a href={`mailto:${loginState.contactEmail}`}>
                    {loginState.contactEmail}
                  </a>
                </strong>
                .
              </>
            ) : (
              <>
                If you don’t recognize that address, please check for typos, try
                a different email, or contact{' '}
                <strong>
                  <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>
                </strong>
                .
              </>
            )}
          </p>

          <p style={{ marginTop: '1em' }}>
            <a
              className="lbj-link"
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                tryDifferentEmail();
              }}
            >
              &larr; Back to login form
            </a>
          </p>
        </>
      )}

      {loginState.status !== 'emailSent' &&
        loginState.status !== 'alternateEmailAvailable' &&
        loginState.status !== 'alternateEmailSending' && (
          <>
            <form onSubmit={onSubmit}>
              <label htmlFor="state">
                Select the location where you plan to volunteer:
              </label>

              <SelectInput
                name="state"
                id="state"
                required
                disabled={loginState.status === 'emailSending'}
                emptyVal=""
                emptyLabel="Select a state or location…"
                className={cx({ 'lbj-select-show_placeholder': state === '' })}
                choices={stateSelectChoices}
                value={state}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
                  setState(ev.currentTarget.value as State)
                }
              />

              <label htmlFor="email">
                Enter the email address associated with your LBJ account:
              </label>

              <Input
                className={cx('login-email-input', {
                  // Don’t display a red border for “invalid” unless we’re not
                  // focused and there’s some data there.
                  invalid: !emailIsValid && !emailIsFocused && email !== '',
                })}
                name="email"
                id="email"
                type="email"
                required
                value={email}
                disabled={loginState.status === 'emailSending'}
                placeholder="text@example.com"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(ev.currentTarget.value);
                }}
                onFocus={() => {
                  setEmailIsFocused(true);
                }}
                onBlur={() => {
                  setEmailIsFocused(false);
                }}
              />

              <div className="user-detail-form-section">
                <button
                  type="submit"
                  disabled={!onSubmit || loginState.status === 'emailSending'}
                  className="c-button-large c-button-wide c-button-secondary"
                >
                  {loginState.status === 'emailSending'
                    ? 'Sending Email…'
                    : 'Send Login Link'}
                </button>
              </div>
            </form>

            <hr className="lbj-modal-line" />

            <p>
              Is your LBJ volunteering account connected to your Google account?
            </p>
            <div className="lbj-modal-checkbox lbj-modal-google-login">
              <GoogleLogin
                onSuccess={async (resp: GoogleCredentialResponse) => {
                  const googleToken = resp.credential;
                  if (googleToken) {
                    try {
                      await LoginService.loginWithGoogleToken({
                        googleToken,
                      });
                      navigate('/');
                    } catch (e) {
                      googleLoginFailed(e);
                    }
                  }
                }}
                onError={logGoogleErrorToSentry}
                text="signin_with"
                type="standard"
                size="large"
                theme="outline"
                shape="rectangular"
                width={400}
                click_listener={clearGoogleStatus}
              />
            </div>
          </>
        )}
    </AuthUiModal>
  );
};

function makeStateSelectChoices(): { [val: string]: string } {
  const { US, ...rest } = STATES;

  return {
    US: 'National Hotline',
    westeros: 'Training Election (Westeros)',
    line1: SEPARATOR_VALUE,
    ...rest,
  };
}

export default LoginFormPage;
