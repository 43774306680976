import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class Checkbox extends Component {
  static propTypes = {
    showLabel: PropTypes.bool,
    title: PropTypes.string,
  };

  render() {
    const { showLabel, title, ...rest } = this.props;
    const className = cx('lbj-checkbox-wrapper', {
      'show-label': showLabel,
    });

    return (
      <div className={className}>
        <label>
          <input {...rest} type="checkbox" className="lbj-checkbox" />
          <span className="lbj-checkbox-indicator" />
          {RIT(showLabel, () => {
            return <span className="lbj-checkbox-title">{title}</span>;
          })}
        </label>
      </div>
    );
  }
}
