import React from 'react';

import { numberToStringWithCommas } from '../../../utils/strings';
import { AssignmentLocation } from '../assignment-state';

export const LOCATION_ROW_HEIGHT_PX = 74;

const LocationRowHeader: React.FunctionComponent<{
  location: Omit<AssignmentLocation, 'hours'>;
}> = ({ location }) => (
  <div className="w-full px-2 text-gray-700">
    <div className="flex w-full items-baseline gap-2">
      <div className="w-8 flex-shrink-0 text-right text-sm font-bold">
        {location.state_rank && numberToStringWithCommas(location.state_rank)}
      </div>

      <div className="flex flex-1 justify-between overflow-hidden">
        <h3
          className="overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-bold"
          title={location.name}
        >
          {location.name}
        </h3>

        {location.coordinates.length === 0 && (
          <span
            className="material-icons cursor-pointer text-base text-error"
            title="Geolocation not found. Cannot auto-assign to this location."
          >
            near_me_disabled
          </span>
        )}
      </div>
    </div>

    {/* ml-10 here to account for rank width and gap */}
    <div className="ml-10 overflow-hidden">
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-base leading-tight">
        {location.address}, {location.city} <br />
        {location.county.name} County
      </div>
    </div>
  </div>
);

export default LocationRowHeader;
