import moment from 'moment';
import React from 'react';

import { TextButton } from '../../components/form';

import {
  AssignmentLoadingStatus,
  LOADING_STAGE_TO_NOUN,
} from './assignment-state';

/**
 * Displays a “last loaded” line in the assignment page header, with a link to
 * refresh data.
 */
const AssignmentLoadingStatusLine: React.FunctionComponent<{
  lastLoadTime: null | Date;
  assignmentStateLoadingStatus: AssignmentLoadingStatus;
  reloadServerState: () => void;

  /**
   * Pass in a value for “now” for the relative date calculations. Used in
   * Storybook.
   */
  nowForTest?: Date;
}> = ({
  assignmentStateLoadingStatus,
  lastLoadTime,
  nowForTest,
  reloadServerState,
}) => {
  // re-render every minute to keep the “loaded X minutes ago” updated
  const [_, setForceRerender] = React.useState({});
  React.useEffect(() => {
    const interval = setInterval(() => setForceRerender({}), 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-sm italic leading-none">
      {assignmentStateLoadingStatus.status === 'initial' && (
        <span>Loading…</span>
      )}

      {assignmentStateLoadingStatus.status === 'loading' && (
        <span>
          Loading {LOADING_STAGE_TO_NOUN[assignmentStateLoadingStatus.stage]}…
        </span>
      )}

      {assignmentStateLoadingStatus.status === 'done' &&
        lastLoadTime !== null && (
          <span>
            Locations and volunteers loaded{' '}
            {moment(lastLoadTime).from(nowForTest ?? new Date())}.
          </span>
        )}

      {assignmentStateLoadingStatus.status === 'error' && (
        <span className="text-error">
          <span className="material-icons align-bottom text-sm leading-none">
            error
          </span>{' '}
          Unable to load volunteers and locations
        </span>
      )}

      {(assignmentStateLoadingStatus.status === 'done' ||
        assignmentStateLoadingStatus.status === 'error') && (
        <span>
          {' '}
          (
          <TextButton
            size="unset"
            className="font-normal italic"
            onPress={() => {
              reloadServerState();
            }}
          >
            refresh
          </TextButton>
          )
        </span>
      )}
    </div>
  );
};

export default AssignmentLoadingStatusLine;
