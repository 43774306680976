import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

import PrecinctCongressionalItem from './assignment-precinct-congressional-item';

export default class PrecinctDetails extends Component {
  static propTypes = {
    precinct: PropTypes.object.isRequired,
    location: PropTypes.object,
    children: PropTypes.node,
  };

  formatPrecinctHours(location) {
    const date = location.getIn(['hours', 0, 'date']);
    const openTime = location.getIn(['hours', 0, 'open_time']);
    const closeTime = location.getIn(['hours', 0, 'close_time']);

    if (openTime && closeTime) {
      return `${moment(`${date}T${openTime}`).format('h:mm a')} - ${moment(
        `${date}T${closeTime}`
      ).format('h:mm a')} on ${date}`;
    }
    return null;
  }

  render() {
    const { precinct, children, location } = this.props;
    const precinctHours = location ? this.formatPrecinctHours(location) : null;

    return (
      <div className="lbj-column-content">
        <ul className="lbj-detail-list">
          <li className="with-icon icon-rank">
            {precinct.get('tier') ? `T${precinct.get('tier')}` : 'N/A'}
          </li>

          {RIT(!!location, () => (
            <li className="with-icon icon-location">
              {location.get('address')}, {location.get('city')}{' '}
              {location.getIn(['county', 'state'])} {location.get('zipcode')}
            </li>
          ))}

          {RIT(!!precinctHours, () => (
            <li className="with-icon icon-clock">{precinctHours}</li>
          ))}

          {RIT(!!(location && location.get('text_hours')), () => (
            <li className="with-icon icon-calendar">
              {location.get('text_hours')}
            </li>
          ))}

          <PrecinctCongressionalItem precinct={precinct} />
        </ul>
        {children}
      </div>
    );
  }
}
