import * as Immutable from 'immutable';

import { requestStatuses } from '../../constants';
import { ApiTicketNotification } from '../../services/notifications-service';
import {
  makeListRequestRecordObj,
  updateListRequestRecord,
} from '../../utils/lbj/list-request-state-handler';
import { AppAction } from '../flux-store';

import {
  GetNotificationsExistAction,
  GetUnackedLocationChangeCountAction,
  GetUnseenNotificationCount,
} from './action-creators';

import actionTypes from './action-types';

const { SUCCESS, ERROR } = requestStatuses;

const {
  GET_LOCATION_CHANGES,
  GET_UNACKED_LOCATION_CHANGE_COUNT,
  GET_NOTIFICATIONS_EXIST,
  GET_UNSEEN_NOTIF_COUNT,
  GET_ISSUE_NOTIFICATIONS,
  STORE_POLLING_INTERVAL,
  BACK_OFF_POLLING_INTERVAL,
} = actionTypes;

export const DEFAULT_POLLING_INTERVAL = 30000;

export class NotificationsState extends Immutable.Record({
  location: Immutable.Record({
    newNotificationsExist: false,
    newNotificationsRequestErred: false,

    unseenCount: 0,
    unseenCountRequestErred: false,
    unackedCount: 0,

    changeList: makeListRequestRecordObj<{}>(),
  })(),

  issue: Immutable.Record({
    unseenCount: 0,
    unseenCountRequestErred: false,

    changeList: makeListRequestRecordObj<ApiTicketNotification>(),
  })(),

  pollingInterval: DEFAULT_POLLING_INTERVAL,
}) {}

export const initialState = new NotificationsState();

function handleLocationNotificationsExist(
  state: NotificationsState,
  data: GetNotificationsExistAction['data']
) {
  switch (data.status) {
    case SUCCESS:
      return state.update('location', (location) =>
        location.set('newNotificationsExist', data.notificationsExist)
      );

    case ERROR:
      // On the one hand, this is never cleared. On the other, it’s never read.
      return state.update('location', (location) =>
        location.set('newNotificationsRequestErred', true)
      );

    default:
      return state;
  }
}

function handleUnseenNotifications(
  state: NotificationsState,
  data: GetUnseenNotificationCount['data']
): NotificationsState {
  switch (data.status) {
    case SUCCESS:
      return state
        .update('issue', (issue) =>
          issue
            .set('unseenCount', data.counts.tickets)
            .set('unseenCountRequestErred', false)
        )
        .update('location', (location) =>
          location
            .set('unseenCount', data.counts.location_change)
            .set('unseenCountRequestErred', false)
        );

    case ERROR:
      return state
        .update('issue', (issue) => issue.set('unseenCountRequestErred', true))
        .update('location', (location) =>
          location.set('unseenCountRequestErred', true)
        );

    default:
      return state;
  }
}

function handleUnackedLocationChangeCount(
  state: NotificationsState,
  data: GetUnackedLocationChangeCountAction['data']
) {
  return state.update('location', (location) =>
    location.set('unackedCount', data.count)
  );
}

export default function notifications(
  state: NotificationsState = initialState,
  action: AppAction
): NotificationsState {
  switch (action.type) {
    case GET_LOCATION_CHANGES:
      return state.update('location', (location) =>
        location.update('changeList', updateListRequestRecord(action.data))
      );

    case GET_NOTIFICATIONS_EXIST:
      return handleLocationNotificationsExist(state, action.data);

    case GET_ISSUE_NOTIFICATIONS:
      return state.update('issue', (issue) =>
        issue.update('changeList', updateListRequestRecord(action.data))
      );

    case GET_UNSEEN_NOTIF_COUNT:
      return handleUnseenNotifications(state, action.data);

    case GET_UNACKED_LOCATION_CHANGE_COUNT:
      return handleUnackedLocationChangeCount(state, action.data);

    case STORE_POLLING_INTERVAL: {
      const interval = action.data.pollingInterval || DEFAULT_POLLING_INTERVAL;
      return state.set('pollingInterval', interval);
    }

    case BACK_OFF_POLLING_INTERVAL: {
      const currentInterval =
        state.get('pollingInterval') || DEFAULT_POLLING_INTERVAL;
      return state.set('pollingInterval', 2 * currentInterval);
    }

    default:
      return state;
  }
}
