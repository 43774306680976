import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Select from './select';

export default class TrainingInput extends Component {
  static propTypes = {
    tags: PropTypes.object.isRequired,
    isFilter: PropTypes.bool.isRequired,
    onTagsChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFilter: false,
  };

  onTrainingValueChange({ target }) {
    const { tags, onTagsChange } = this.props;
    const { value } = target;
    const updatableTags = [
      'training_scheduled',
      'training_complete',
      '~training_scheduled',
      '~training_complete',
    ];
    let updatedTags = tags.filterNot((tag) => _.includes(updatableTags, tag));

    if (value) {
      updatedTags = updatedTags.push(...value.split(','));
    }

    onTagsChange(updatedTags);
  }

  getDefaultTrainingValue() {
    const tags = this.props.tags.toJS();

    if (_.includes(tags, 'training_complete')) {
      return 'training_complete';
    }

    if (
      _.includes(tags, 'training_scheduled') &&
      _.includes(tags, '~training_complete')
    ) {
      return 'training_scheduled,~training_complete';
    }

    if (_.includes(tags, 'training_scheduled')) {
      return 'training_scheduled';
    }

    if (
      _.includes(tags, '~training_scheduled') ||
      _.includes(tags, '~training_complete')
    ) {
      return '~training_scheduled,~training_complete';
    }

    return '';
  }

  getChoices() {
    const { isFilter } = this.props;

    if (isFilter) {
      return {
        '': '-',
        'training_scheduled,~training_complete': 'Training Scheduled',
        training_complete: 'Trained',
        '~training_scheduled,~training_complete': 'Untrained',
      };
    }

    return {
      '': '-',
      training_scheduled: 'Training Scheduled',
      training_complete: 'Trained',
    };
  }

  render() {
    return (
      <Select
        name="training"
        title="Training"
        choices={this.getChoices()}
        value={this.getDefaultTrainingValue()}
        onChange={this.onTrainingValueChange.bind(this)}
      />
    );
  }
}
