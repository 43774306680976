import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

import { toAppDispatch } from '../../../modules/flux-store';

import EmptyAlert from './empty-alert';
import Nav from './nav';

const NotFound: React.FunctionComponent<{ dispatch?: Dispatch<any> }> = ({
  dispatch,
}) => {
  const location = useLocation();

  // Due to how S3 bucketing works with cloudfront, going to
  // https://lbj.stage.dnc.org/storybook/ leads to this 404, rendered by the
  // root index.html, rather than showing /storybook/index.html.
  //
  // Since it’s easy to forget this subtlety, we automatically redirect to the
  // right place if it comes up.
  React.useEffect(() => {
    if (
      location.pathname === '/storybook' ||
      location.pathname === '/storybook/'
    ) {
      window.location.replace('/storybook/index.html');
    }
  }, [location.pathname]);

  return (
    <div className="lbj-404">
      <Nav
        dispatch={toAppDispatch(dispatch)}
        currentUser={null}
        currentUserElection={null}
        onLogoutClick={() => {}}
        onNotificationPopoverClose={() => {}}
        onNotificationPopoverOpen={() => {}}
        withCaptiveNav
      />

      <EmptyAlert
        header="Not Found"
        description="Either this page doesn't exist or you're not allowed to access it"
      />
    </div>
  );
};

export default connect()(NotFound);
