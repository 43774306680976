import moment from 'moment';
import React from 'react';
import useSWR from 'swr';

import * as CheckinService from '../../services/checkin-service';
import { DateString } from '../../services/common';
import * as UserService from '../../services/user-service';
import { pruneFilters } from '../../utils/filters/common';

export type UserListFilters = {
  currentlyAssignedToBoilerRoom?: string | number | undefined;
  namePrefix?: string | undefined;
};

/**
 * Loads a user list from LBJ for the given filters.
 *
 * Uses the expanded PII version of users so that callers have access to _e.g._
 * email addresses.
 *
 * Pass `beLaggy` as an option for this hook to return the previous value while
 * it’s loading the new one. Good for auto-complete, where it can cover up a
 * flash of unloaded content.
 *
 * Pass `disabled` to just return `[]` without making any requests.
 */
export function useUserListWithPii(
  { namePrefix, currentlyAssignedToBoilerRoom }: UserListFilters,
  {
    beLaggy = false,
    disabled = false,
  }: { beLaggy?: boolean; disabled?: boolean } = {}
) {
  // Copying over to avoid extra properties from leaking to getUserList.
  const filters: UserService.ApiGetUserListFilters<undefined> = pruneFilters({
    name_prefix: namePrefix,
    currently_assigned_to_boiler_room: currentlyAssignedToBoilerRoom,
  });

  return useSWR(
    [
      'UserService#getUserList',
      // TODO(fiona): may need to allow expands to be an option… right now at
      // least all of the user autocomplete UIs are limited to folks > poll
      // observer/hotline worker.
      { ...filters, size: 25, expands: 'pii' },
      disabled,
    ] as const,
    async ([_, param, disabled]) => {
      if (disabled) {
        return [];
      }

      return (await UserService.getUserList<'pii'>(param)).users;
    },
    { keepPreviousData: beLaggy }
  ).data;
}

export type UserDetailFilters = {
  /**
   * Filters assignments down to just those from these dates.
   */
  dates?: DateString[] | undefined;
};

export type DetailedUserWithAssignments = UserService.ApiUser &
  UserService.WithApiUserCurrentBoilerRooms &
  UserService.ExpandApiUserPii &
  UserService.ExpandApiUserRelated;

/**
 * Hook to load a user with their PII and assigments.
 */
export function useDetailedUserWithAssignments(
  id: number | null,
  { dates }: UserDetailFilters = {}
): DetailedUserWithAssignments | null | undefined {
  // Copied to filter down to known parameters.
  const filters = {
    dates: dates?.join(','),
  };

  return useSWR(
    [
      'UserService#getUserDetails',
      id,
      { ...filters, expand: 'pii,related' },
    ] as const,
    async ([_, id, param]) => {
      if (id === null) {
        return null;
      }

      return (await UserService.getUserDetails<'pii,related'>(id, param)).user;
    }
  ).data;
}

export type UseLatestCheckinActions = {
  /** Reloads to get the latest checkin. */
  refresh: () => void;
};

/**
 * Loads checkins for the given user ID and returns the most recent one that
 * matches the current day.
 *
 * Note that the `userId` must refer to the same user as is currently logged in,
 * as the backend enforces that permission.
 *
 * Returns a {@link UseLatestCheckinActions} object so that the most recent
 * checkin can be reloaded after a new one is made.
 */
export function useLatestCheckinFromDay(
  userId: number | null,
  now: moment.Moment
): [
  CheckinService.ApiCheckinWithLocation | undefined | null,
  UseLatestCheckinActions
] {
  const { data, mutate } = useSWR(
    ['CheckinService#listCheckins', userId] as const,
    async ([_, userId]) => {
      if (userId === null) {
        return null;
      }

      return (
        (await CheckinService.listCheckins(userId)).checkins.filter((c) =>
          moment(c.time).isSame(now, 'day')
        )[0] ?? null
      );
    }
  );

  const actions = React.useMemo<UseLatestCheckinActions>(
    () => ({
      refresh: () => {
        // Causes SWR to re-validate.
        mutate();
      },
    }),
    [mutate]
  );

  return [data, actions];
}
