import React from 'react';

import { ApiIssuePhoto } from '../../services/issue-service';

export default class PhotoLightbox extends React.Component<
  {
    isVisible: boolean;
    photo: ApiIssuePhoto;
    onRequestClose: () => void;
    onRequestDelete: (photo: ApiIssuePhoto) => void;
  },
  { isConfirmingDelete: boolean }
> {
  constructor(props: PhotoLightbox['props']) {
    super(props);
    this.state = { isConfirmingDelete: false };
  }

  componentDidUpdate(prevProps: PhotoLightbox['props']) {
    if (this.props.isVisible && !prevProps.isVisible) {
      // If we’re re-opening, get rid of delete confirmation.
      this.setState({ isConfirmingDelete: false });
    }
  }

  handleClose() {
    this.props.onRequestClose();
  }

  handleDelete() {
    this.props.onRequestDelete(this.props.photo);
  }

  showConfirmDelete() {
    this.setState({ isConfirmingDelete: true });
  }

  hideConfirmDelete() {
    this.setState({ isConfirmingDelete: false });
  }

  renderConfirmation() {
    if (!this.state.isConfirmingDelete) {
      return null;
    }

    return (
      <div className="confirmDelete">
        <div className="instructions">
          Are you sure you want to delete this photo?
        </div>
        <div className="buttons">
          <button
            onClick={this.handleDelete.bind(this)}
            className="confirm c-button-primary"
          >
            Yes, delete
          </button>
          <button
            onClick={this.hideConfirmDelete.bind(this)}
            className="cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { isVisible, photo } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <div className="lightboxBackground lbj-modal-background">
        <div className="lightboxBody">
          <div className="lightboxButtons">
            <a
              className="lightboxLink deleteLink"
              onClick={this.showConfirmDelete.bind(this)}
            >
              <img
                className="trashIcon"
                src={require('../../../img/trash.svg')}
              />
              Delete Photo
            </a>
            <a className="lightboxLink" onClick={this.handleClose.bind(this)}>
              <img
                className="closeIcon"
                src={require('../../../img/X_white.png')}
              />
            </a>
          </div>
          <a
            className="photoLink"
            target="_blank"
            href={photo.photo}
            rel="noreferrer"
          >
            <img className="lightboxPhoto" src={photo.photo} />
          </a>
          {this.renderConfirmation()}
        </div>
        <div />
      </div>
    );
  }
}
