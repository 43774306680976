import cx from 'classnames';
import React from 'react';
import Select, { Option } from 'react-select';

import { ApiElection, ApiLocation } from '../../../services/lbj-shared-service';
import { useChanges, useStateWithDeps } from '../../../utils/hooks';
import {
  LocationPickerAction,
  LocationPickerState,
  useLocationPicker,
} from '../../hooks/location-picker';
import CountySelect, { countiesToChoices } from '../form/county-select';
import StateSelect from '../form/state-select';

/**
 * Used in Location picker to expand from “preferred” to “all” locations.
 */
const EXPAND_OPTION = { EXPAND_OPTION: 'EXPAND_OPTION' };

type LocationSelectOption = ApiLocation | typeof EXPAND_OPTION;

/**
 * Component to render a picker of polling locations. By default, offers just
 * the choices from the `preferredLocations` property. If the user selects “More
 * locations…” from the bottom of that list, it shows a county picker and, if
 * the election is national, a state picker as well.
 *
 * Designed to work with the `useLocationAutocomplete` hook, which provides the
 * appropriate state and dispatch functions.
 *
 * Derived from expandable-location-autocomplete, which is a connected
 * component.
 */
const LocationAutocomplete: React.FunctionComponent<{
  state: LocationAutocompleteState;
  dispatch: LocationAutocompleteDispatch;
}> = ({
  state: {
    selectedState,
    selectedCounty,
    counties,
    locations,
    mayChangeState,
    showLocationsByCountyPicker,
    location,
  },
  dispatch,
}) => {
  // copied from expandable-location-autocomplete
  const className = cx(
    'lbj-input',
    'lbj-input-autocomplete',
    'lbj-input-autocomplete-select',
    'lbj-input-expandable-location',
    'lbj-input-location'
  );

  const options: Option<LocationSelectOption>[] = (locations ?? []).map(
    (loc) => ({ value: loc })
  );

  if (!showLocationsByCountyPicker) {
    options.push({ value: EXPAND_OPTION });
  }

  // For the selected value, we try to find the selected location in the list of
  // locations by ID. If it’s not there, we make a separate option for it.
  const value = location
    ? options.find(
        (opt) => !isExpandOption(opt.value) && opt.value!.id === location.id
      ) ?? { value: location }
    : undefined;

  return (
    <div className={className}>
      {showLocationsByCountyPicker && (
        <>
          {/*
           We only show the State select if it’s a National election.
           Otherwise it’s just wasted space, and we’re optimizing for
           mobile.
         */}
          {mayChangeState && (
            <StateSelect
              required
              className="locationStateFilter"
              includeNational={false}
              value={selectedState}
              onChange={(ev) =>
                dispatch({
                  type: 'SET_STATE',
                  state: ev.target.value,
                })
              }
            />
          )}

          <CountySelect
            required
            className="locationCountyFilter"
            counties={countiesToChoices(counties)}
            value={selectedCounty}
            onChange={(ev) => {
              dispatch({
                type: 'SET_COUNTY',
                county: ev.target.value,
              });
            }}
            disabled={!counties || counties.length === 0}
          />
        </>
      )}

      <label htmlFor="location">
        Location&nbsp;<span className="req-indicator">*</span>
      </label>

      <Select
        id="location"
        placeholder="-"
        value={value}
        options={options}
        optionRenderer={(option) => {
          if (isExpandOption(option.value)) {
            return (
              <div className="locationOption expandOption">
                Other locations…
              </div>
            );
          }

          const { address, city, county, name, zipcode } = option.value!;

          return (
            <div className="locationOption">
              <div>
                <span className="name">{name}</span>
                <span className="details">{county.name}</span>
              </div>
              <div className="address">
                {`${address}, ${city}, ${county.state} ${zipcode}`}
              </div>
            </div>
          );
        }}
        valueRenderer={(option) =>
          isExpandOption(option.value) ? null : (
            <span>{option.value!.name}</span>
          )
        }
        filterOptions={(options, query) =>
          options.filter((option) =>
            matchesLocationSelectOption(query.toLowerCase(), option.value!)
          )
        }
        onChange={(option: Option<LocationSelectOption> | null) => {
          if (isExpandOption(option?.value)) {
            dispatch({ type: 'SHOW_LOCATIONS_BY_COUNTY_PICKER' });
          } else {
            dispatch({
              type: 'SET_LOCATION',
              location: option && option.value!,
            });
          }
        }}
        required
        disabled={!locations}
        inputProps={{
          autoCorrect: 'off',
          autoCapitalize: 'off',
        }}
      />
    </div>
  );
};

/**
 * Given a string query, returns true if the given location matches that query,
 * by looking for it in the concatenated location string.
 *
 * Used when filtering the autocomplete list.
 */
function matchesLocationSelectOption(
  query: string,
  option: LocationSelectOption
) {
  if (isExpandOption(option)) {
    return true;
  }

  const { address, city, county, name, zipcode } = option;
  const text = [name, address, city, zipcode, county.state, county.name].join(
    ' '
  );

  return text.toLowerCase().indexOf(query) >= 0;
}

/**
 * Type predicate for our `EXPAND_OPTION`, which is a special marker object for
 * the “Other locations…” menu option.
 *
 * Used repeatedly to differentiate that menu option from the others, which are
 * ApiLocations.
 */
function isExpandOption(
  option: LocationSelectOption | undefined
): option is typeof EXPAND_OPTION {
  return option === EXPAND_OPTION;
}

/**
 * Expands on {@link LocationPickerState} to include the current location and
 * also a boolean that indicates if we’re showing the county picker.
 *
 * {@link useLocationAutocomplete} overrides {@link LocationPickerState}
 * slightly in that will return its `preferredLocations` in the `locations`
 * property if `showLocationsByCountyPicker` is false.
 *
 * This is its own hook rather than being rolled into {@link useLocationPicker}
 * since the latter may be useful for rendering lists of locations without
 * selecting a specific one.
 */
export type LocationAutocompleteState = LocationPickerState & {
  /**
   * The selected location. This is not guaranteed to be in the list of
   * locations. We save it as an object rather than an ID so that we can render
   * it even if it no longer appears in the list of locations.
   */
  location: ApiLocation | null;

  /**
   * If true, the user has elected to pick a location by state/county rather
   * than from the list of preferred locations. Will also be true by default if
   * there are no preferred locations available.
   */
  showLocationsByCountyPicker: boolean;
};

export type LocationAutocompleteAction =
  | LocationPickerAction
  | { type: 'SET_LOCATION'; location: ApiLocation | null }
  | { type: 'SHOW_LOCATIONS_BY_COUNTY_PICKER' };

export type LocationAutocompleteDispatch = (
  action: LocationAutocompleteAction
) => void;

/**
 * Hook for the logic behind `<LocationAutocomplete>`’s state.
 *
 * It’s a bit complicated because the preferred locations, searched locations,
 * selected location, and whether or not we’re showing the picker are all
 * intertwined.
 *
 * This isolates that out and makes it directly testable (rather than inferring
 * behavior through manipulating {@link LocationAutocomplete}).
 */
export function useLocationAutocomplete(
  /**
   * Currently-selected election from the nav. Used to scope which locations
   * appear.
   */
  election: Pick<ApiElection, 'id' | 'state'>,

  /**
   * List of locations to present as a short list of options to the user. If
   * this is empty or if the user chooses “More locations…” then this hook
   * switches over to loading all locations by state/county via
   * {@link useLocationPicker}.
   */
  preferredLocations: ApiLocation[] | null | undefined,

  /**
   * Pass dependencies that will cause the location ID to synchronously reset.
   *
   * _E.g._ the user ID.
   */
  deps: unknown[]
): [LocationAutocompleteState, LocationAutocompleteDispatch] {
  const [locationPickerState, locationPickerDispatch] =
    useLocationPicker(election);

  /** If there’s exactly one preferred location, default to it. */
  const defaultLocation =
    preferredLocations?.length === 1 ? preferredLocations[0]! : null;

  const [location, setLocation] = useStateWithDeps<ApiLocation | null>(
    defaultLocation,
    deps
  );

  // Initializes to true only if we _know_ there are no preferred locations
  //
  // Note that it’s unusual for preferredLocations to be defined when this
  // component mounts because the user’s assignments and recent checkin load
  // asynchronously.
  //
  // We address this in the useChanges call below.
  const [showLocationsByCountyPicker, setShowLocationsByCountyPicker] =
    useStateWithDeps(preferredLocations?.length === 0, deps);

  // If the state, county, or state of the expand picker change, we clear the
  // location out to `null`.
  //
  // We don’t do this with useStateWithDeps because we explicitly want to reset
  // to null here rather than its potential default of a single preferred
  // location.
  useChanges(
    (_prevState, _prevCounty, _prevShowLocationsByCountyPicker) => {
      setLocation(null);
    },
    [
      locationPickerState.selectedState,
      locationPickerState.selectedCounty,
      showLocationsByCountyPicker,
    ] as const
  );

  // When the preferred locations finally load, updates
  // showLocationsByCountyPicker based on whether or not there are any preferred
  // locations. Also sets the location if there’s only one.
  //
  // This doesn’t change the picker if a location is set purely for safety
  // (messing with the user’s chosen option is something that should be done
  // delicately), though in practice a location won’t be set until after
  // preferredLocations has loaded anyway. We just don’t formally enforce that
  // invariant.
  useChanges(
    (prevPreferredLocations) => {
      if (!prevPreferredLocations && preferredLocations && location === null) {
        setShowLocationsByCountyPicker(preferredLocations.length === 0);
        setLocation(defaultLocation);
      }
    },
    [preferredLocations]
  );

  const locations = showLocationsByCountyPicker
    ? locationPickerState.locations
    : preferredLocations;

  const state = React.useMemo<LocationAutocompleteState>(() => {
    return {
      ...locationPickerState,

      // This purposely overrides the value from locationPickerState, since we
      // might be returning preferredLocations.
      locations,

      location,
      showLocationsByCountyPicker,
    };
  }, [locationPickerState, locations, location, showLocationsByCountyPicker]);

  const dispatch = React.useCallback<LocationAutocompleteDispatch>(
    (action) => {
      switch (action.type) {
        case 'SHOW_LOCATIONS_BY_COUNTY_PICKER':
          setShowLocationsByCountyPicker(true);
          break;

        case 'SET_LOCATION':
          setLocation(action.location);
          break;

        default:
          locationPickerDispatch(action);
          break;
      }
    },
    [setLocation, setShowLocationsByCountyPicker, locationPickerDispatch]
  );

  return [state, dispatch];
}

export default LocationAutocomplete;
