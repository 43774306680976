import { lbjAppSections } from '../../constants';

const { ASSIGNMENT } = lbjAppSections;

export default function (state) {
  const { assignment, filters } = state;
  const currentView = filters.getIn([ASSIGNMENT, 'view']);

  return {
    listData: assignment.getIn(['assignmentIndex', currentView, 'listData']),
    listRequestIsPending: assignment.getIn([
      'assignmentIndex',
      currentView,
      'requestIsPending',
    ]),
    listRequestErred: assignment.getIn([
      'assignmentIndex',
      currentView,
      'requestErred',
    ]),
  };
}
