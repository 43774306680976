import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import RIT from '../../../utils/render-if-truthy';
import EditorSearchResults from '../../presentational/assignment/assignment-editor-search-results';
import PollingSearchForm from '../../presentational/assignment/assignment-polling-search-form';
import PollingPlaceTag from '../../presentational/assignment/assignment-polling-tag';

@connect((state, ownProps) => {
  const { lbj } = state;
  const precinctResults = lbj.get('precincts');
  const locationResults = lbj.get('locations');
  let countyData;

  if (ownProps.assignmentState) {
    countyData =
      lbj.getIn(['counties', ownProps.assignmentState]) || Immutable.Map({});
  } else {
    countyData = lbj.get('counties');
  }

  return {
    counties: countyData.get('listData'),
    isLoadingPrecincts: precinctResults.get('requestIsPending'),
    isLoadingLocations: locationResults.get('requestIsPending'),
    precinctList: precinctResults.get('listData'),
    locationList: locationResults.get('listData'),
  };
})
export default class FindPollingForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    chooseLocationFirst: PropTypes.bool,
    counties: PropTypes.object,
    isLoadingPrecincts: PropTypes.bool.isRequired,
    isLoadingLocations: PropTypes.bool.isRequired,
    precinctList: PropTypes.object.isRequired,
    locationList: PropTypes.object.isRequired,
    precinct: PropTypes.object,
    location: PropTypes.object,
    assignmentState: PropTypes.string,
    onEditAssignmentData: PropTypes.func.isRequired,
    shiftDate: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.lastPrecinctSearch = {};
    this.lastLocationSearch = {};
    this.state = {
      chooseLocationFirst: !!props.chooseLocationFirst,
    };
  }

  componentWillMount() {
    const { location, precinct, assignmentState } = this.props;
    const precinctId = precinct ? precinct.get('id') : null;
    const locationId = location ? location.get('id') : null;

    this.onSearchForPrecincts({}, locationId);
    this.onSearchForLocations({}, precinctId);
    this.getCountiesForState(assignmentState);
  }

  componentDidUpdate(prevProps) {
    const { location, precinct, assignmentState } = this.props;

    if (prevProps.location !== location) {
      this.onSearchForPrecincts({}, this.getLocationId());
    }

    if (prevProps.precinct !== precinct) {
      this.onSearchForLocations({}, this.getPrecinctId());
    }

    if (prevProps.assignmentState !== assignmentState) {
      this.getCountiesForState(assignmentState);
    }
  }

  onSearchForPrecincts(additionalParams = {}, locationId) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const basePayload = _.assign(
      this.getDefaultSearchFilters(),
      additionalParams
    );
    const filterPayload = _.assign(
      basePayload,
      locationId ? { location: locationId } : {}
    );

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
    this.lastPrecinctSearch = filterPayload;
  }

  onSearchForLocations(additionalParams = {}, precinctId) {
    const { dispatch } = this.props;
    const { getLocationListAsync } = lbjActionCreators;
    const basePayload = _.assign(
      this.getDefaultSearchFilters(),
      additionalParams
    );
    const filterPayload = _.assign(
      basePayload,
      precinctId ? { precinct: precinctId } : {}
    );

    dispatch(getLocationListAsync(filterPayload, ['locations']));
    this.lastLocationSearch = filterPayload;
  }

  onGetMorePrecinctResults({ offset }) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastPrecinctSearch, {
      offset,
      size: 25,
    });

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
  }

  onGetMoreLocationResults({ offset }) {
    const { dispatch } = this.props;
    const { getLocationListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastLocationSearch, {
      offset,
      size: 25,
    });

    dispatch(getLocationListAsync(filterPayload, ['locations']));
  }

  getDefaultSearchFilters() {
    const { assignmentState, shiftDate } = this.props;

    return {
      state: assignmentState,
      dates: shiftDate,
      size: 25,
    };
  }

  getCountiesForState(state) {
    const { dispatch } = this.props;
    const { getCountyListAsync } = lbjActionCreators;

    dispatch(getCountyListAsync(state));
  }

  getCountyOptions() {
    const { counties } = this.props;

    if (counties) {
      return counties.reduce((choices, county) => {
        choices[county.get('slug')] = county.get('name');
        return choices;
      }, {});
    }

    return {};
  }

  getPrecinctId() {
    const { precinct } = this.props;
    return precinct ? precinct.get('id') : null;
  }

  getLocationId() {
    const { location } = this.props;
    return location ? location.get('id') : null;
  }

  renderResult(resourceType, resource, key) {
    const { onEditAssignmentData } = this.props;

    return (
      <li
        onClick={() => {
          const assignmentData = {};

          assignmentData[resourceType] = resource;
          onEditAssignmentData(assignmentData);
        }}
        key={key}
      >
        {resource.get('name')}
      </li>
    );
  }

  renderPrecinctResults() {
    const { precinctList, isLoadingPrecincts } = this.props;

    return (
      <div className="lbj-column-content">
        <EditorSearchResults
          listData={precinctList}
          isLoadingList={isLoadingPrecincts}
          onGetMoreSearchResults={this.onGetMorePrecinctResults.bind(this)}
          listElementRenderer={(precinct, key) =>
            this.renderResult('precinct', precinct, key)
          }
        />
      </div>
    );
  }

  renderLocationResults() {
    const { locationList, isLoadingLocations } = this.props;

    return (
      <div className="lbj-column-content">
        <EditorSearchResults
          listData={locationList}
          isLoadingList={isLoadingLocations}
          onGetMoreSearchResults={this.onGetMoreLocationResults.bind(this)}
          listElementRenderer={(location, key) =>
            this.renderResult('location', location, key)
          }
        />
      </div>
    );
  }

  renderPrecinctSearch() {
    const { location, precinct } = this.props;
    let hiddenInputTitle = 'Select a precinct';

    if (!location && !precinct) {
      hiddenInputTitle = (
        <span>
          {hiddenInputTitle}
          <a
            href="#"
            className="assignment-toggle-search-type"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ chooseLocationFirst: true });
            }}
          >
            Location
          </a>
        </span>
      );
    }

    return (
      <div className="lbj-column-content">
        <PollingSearchForm
          hiddenInputTitle={hiddenInputTitle}
          title="Precincts"
          counties={this.getCountyOptions()}
          showCounty={!location || !precinct}
          showTier={!precinct}
          onChange={(searchFilters) => {
            this.onSearchForPrecincts(searchFilters, this.getLocationId());
          }}
        />
      </div>
    );
  }

  renderLocationSearch() {
    const { precinct, location } = this.props;
    let hiddenInputTitle = 'Select a location';

    if (!location && !precinct) {
      hiddenInputTitle = (
        <span>
          {hiddenInputTitle}
          <a
            href="#"
            className="assignment-toggle-search-type"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ chooseLocationFirst: false });
            }}
          >
            Precinct
          </a>
        </span>
      );
    }

    return (
      <div className="lbj-column-content">
        <PollingSearchForm
          hiddenInputTitle={hiddenInputTitle}
          title="Locations"
          counties={this.getCountyOptions()}
          showCounty={!location && !precinct}
          showTier={!precinct}
          onChange={(searchFilters) => {
            this.onSearchForLocations(searchFilters, this.getPrecinctId());
          }}
        />
      </div>
    );
  }

  renderLocationTag() {
    const { location, onEditAssignmentData } = this.props;

    return (
      <div className="lbj-column-content">
        <PollingPlaceTag
          onRemoveTag={() => {
            onEditAssignmentData({ location: null });
          }}
        >
          <strong>Location: {location.get('name')}</strong>
        </PollingPlaceTag>
      </div>
    );
  }

  render() {
    const { precinct, location } = this.props;
    const { chooseLocationFirst } = this.state;
    const blankPollingPlace = !precinct && !location;

    return (
      <div>
        <div className="lbj-column-label">
          <h4>Polling Place</h4>
        </div>

        {/* search forms */}
        {RIT(
          chooseLocationFirst && blankPollingPlace,
          this.renderLocationSearch.bind(this)
        )}
        {RIT(
          !chooseLocationFirst && blankPollingPlace,
          this.renderPrecinctSearch.bind(this)
        )}
        {RIT(!location && precinct, this.renderLocationSearch.bind(this))}
        {RIT(location && !precinct, this.renderPrecinctSearch.bind(this))}

        {/* search results */}
        {RIT(
          chooseLocationFirst && blankPollingPlace,
          this.renderLocationResults.bind(this)
        )}
        {RIT(
          !chooseLocationFirst && blankPollingPlace,
          this.renderPrecinctResults.bind(this)
        )}
        {RIT(!location && precinct, this.renderLocationResults.bind(this))}
        {RIT(location && !precinct, this.renderPrecinctResults.bind(this))}

        {/* polling place details */}
        {RIT(!!location, this.renderLocationTag.bind(this))}
      </div>
    );
  }
}
