import keyMirror from 'key-mirror';

export default keyMirror({
  GET_CONGRESSIONAL_DISTRICT_LIST: null,
  GET_COUNTY_LIST: null,
  GET_ELECTION_LIST: null,
  GET_LOCATION_LIST: null,
  GET_PRECINCT_LIST: null,
  GET_REGION_LIST: null,
  GET_BOILER_ROOM_LIST: null,
  GET_BOARDS_OF_ELECTIONS_LIST: null,
  GET_USER_TAG_LIST: null,
  GET_NEARBY_LOCATIONS: null,
});
