import * as Immutable from 'immutable';

export type ToastActions = ShowToastAction | DismissToastAction;

export const SHOW_TOAST = 'SHOW_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';

export type ToastData = {
  type: 'success' | 'error' | '';
  message: string;
  url?: string | undefined;
};

export type ShowToastAction = {
  type: typeof SHOW_TOAST;
  data: ToastData;
};

export function showToast(toastData: ToastData): ShowToastAction {
  return {
    type: SHOW_TOAST,
    data: toastData,
  };
}

export type DismissToastAction = {
  type: typeof DISMISS_TOAST;
};

export function dismissToast(): DismissToastAction {
  return {
    type: DISMISS_TOAST,
  };
}

export class ToastRecord extends Immutable.Record<ToastData>({
  type: '',
  message: '',
  url: undefined,
}) {}
