import { FORM_ERROR } from 'final-form';
import { Immutable } from 'immer';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';

import {
  ActionButton,
  Checkbox,
  FormError,
  FormLabel,
  TextField,
} from '../../../components/form';
import type {
  ApiVolunteerAvailability,
  ApiVolunteerAvailabilityGroup,
  NewApiVolunteerAvailabilityGroup,
} from '../../../services/volunteer-availability-service';
import {
  requiredValidation,
  adaptTextFieldRenderProps,
  adaptCheckboxRenderProps,
} from '../../../utils/form';
import { assertUnreachable, Awaitable } from '../../../utils/types';
import {
  DEFAULT_LOCATION_FILTERS,
  LocationFilterOptions,
} from '../assignment-utils';
import {
  MunicipalityFilters,
  LocationMunicipalities,
} from '../location-table/LocationFilter';

/**
 * Form-specific encoding of a {@Link NewApiVolunteerAvailabilityGroup} to make
 * it easier to re-use the location filter UI’s municipalities control.
 */
export type GroupFormValues = {
  name: string;

  /** We adapt to this so we can re-use our existing component. */
  municipalities: LocationFilterOptions['municipalities'];

  /**
   * Note that this is the inverse of "default_respect_distance_travel_tag".
   * Inverted because that’s how we want to present the option as a checkbox.
   */
  ignoreDistanceTag: boolean;
};

/**
 * Save function compatible with Final-Form: returns undefined on success, or an
 * object of form errors on failure. May throw on network errors.
 *
 * (Note we expect that any validation errors match the
 * {@link NewApiVolunteerAvailabilityGroup} fields, not
 * {@link GroupFormValues}). See {@link apiErrorMessagesToForm}.
 */
export type SaveAvailabiltyGroupFn = (
  groupId: number | null,
  group: Immutable<NewApiVolunteerAvailabilityGroup>
) => Awaitable<
  | void
  | undefined
  | {
      [key in
        | keyof NewApiVolunteerAvailabilityGroup
        | typeof FORM_ERROR]?: string[];
    }
>;

export type DeleteAvailabiltyGroupFn = (groupId: number) => Awaitable<void>;

/**
 * Component for editing the volunteer group, embedded in the
 * {@link VolunteerAvailabilityDialog}.
 *
 * Use a key to re-render this form when the availability group changes.
 */
const AvailabilityGroupForm: React.FunctionComponent<{
  /** Existing group, if one was chosen. */
  availabilityGroup: ApiVolunteerAvailabilityGroup | null;
  municipalities: LocationMunicipalities;
  availabilityByUserId: Immutable<Map<number, ApiVolunteerAvailability[]>>;

  /** Save the group, or return form error objects. */
  saveAvailabilityGroup: SaveAvailabiltyGroupFn;

  /** Called when the Cancel button is clicked. */
  onCancel: () => void;

  /**
   * Called after a successful save (or if Next is clicked without the form
   * changing)
   */
  onNext: () => void;
}> = ({
  availabilityGroup,
  availabilityByUserId,
  municipalities,
  onCancel,
  onNext,
  saveAvailabilityGroup,
}) => {
  const initialValues = React.useMemo<GroupFormValues>(
    () =>
      availabilityGroup
        ? apiGroupToFormFields(availabilityGroup)
        : {
            name: '',
            municipalities: { type: 'all' },
            ignoreDistanceTag: false,
          },
    [availabilityGroup]
  );

  /**
   * Number of individual availability records that match the currently-selected
   * group’s id.
   */
  const currentGroupRecordCount = React.useMemo(() => {
    if (availabilityGroup?.id === undefined) {
      return 0;
    } else {
      return [...availabilityByUserId.values()].flatMap((arr) =>
        arr.filter((a) => a.availability_group_id === availabilityGroup.id)
      ).length;
    }
  }, [availabilityByUserId, availabilityGroup?.id]);

  return (
    <Form<GroupFormValues, GroupFormValues>
      initialValues={initialValues}
      onSubmit={async (values, form) => {
        // We don’t want to do an API call if the existing setup for the form is
        // fine. Note we technically don’t need to avoid this case mistakenly
        // triggering for new groups because if the new group form is pristine
        // then there will be a required value error on the name field.
        if (form.getState().pristine) {
          onNext();

          // HACK(fiona): Final Form gets stuck in “submitting” unless there is
          // a delay before onSubmit resolves.
          return new Promise((resolve) =>
            setTimeout(() => resolve(undefined), 1)
          );
        }

        const result = await saveAvailabilityGroup(
          availabilityGroup?.id ?? null,
          groupFormFieldsToApi(values)
        );

        if (result) {
          return apiErrorMessagesToForm(result);
        } else {
          onNext();
          return undefined;
        }
      }}
    >
      {({ values, form: formApi, submitting, submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <Field<GroupFormValues['name']>
              name="name"
              validate={requiredValidation('Name')}
            >
              {(fieldProps) => (
                <TextField
                  label="Group Name"
                  isRequired
                  maxLength={1000}
                  {...adaptTextFieldRenderProps(fieldProps)}
                />
              )}
            </Field>

            <Field<GroupFormValues['municipalities']>
              name="municipalities"
              validate={validateMunicipalitiesNotEmpty}
            >
              {({ input, meta }) => (
                <div className="flex w-96 flex-col gap-2">
                  <FormLabel id="municipality-filter-label">
                    Allowed Assignment Locations
                  </FormLabel>

                  <MunicipalityFilters
                    aria-labelledby="municipality-filter-label"
                    locationFilters={{ municipalities: input.value }}
                    municipalities={municipalities}
                    setLocationFilters={(updater) => {
                      const updatedMunicipalities = updater(
                        DEFAULT_LOCATION_FILTERS
                      ).municipalities;

                      input.onChange(updatedMunicipalities);

                      // Small hack. We unset this because it doesn’t make sense
                      // to ignore distance if you’re also not limiting to
                      // particular municipalities.
                      if (updatedMunicipalities.type === 'all') {
                        formApi.change('ignoreDistanceTag', false);
                      }
                    }}
                  />

                  {((meta.touched && meta.error) || meta.submitError) && (
                    // The MunicipalityFilters don’t expose onFocus/onBlur, so
                    // meta.touched will only be set to true if someone tries to
                    // submit. For our UX, that’s fine. You just won’t see the
                    // required error until you try to submit.
                    <FormError>{meta.error || meta.submitError}</FormError>
                  )}
                </div>
              )}
            </Field>

            <Field<GroupFormValues['ignoreDistanceTag']>
              name="ignoreDistanceTag"
              type="checkbox"
            >
              {(fieldProps) => (
                <Checkbox
                  {...adaptCheckboxRenderProps(fieldProps)}
                  isDisabled={values.municipalities.type === 'all'}
                >
                  Ignore volunteers’ travel limitations for these assignments
                </Checkbox>
              )}
            </Field>

            {availabilityGroup && (
              <div>
                <FormLabel>Last Uploaded</FormLabel>
                <br />
                {availabilityGroup.last_uploaded ? (
                  <>
                    {moment(availabilityGroup.last_uploaded).format('LLLL')} (
                    {currentGroupRecordCount} records)
                  </>
                ) : (
                  <i>This group has not yet been uploaded</i>
                )}
              </div>
            )}

            {submitError && <FormError>{submitError}</FormError>}

            <div className="flex justify-end gap-2">
              <ActionButton role="secondary" onPress={onCancel}>
                Cancel
              </ActionButton>

              <ActionButton type="submit" isDisabled={submitting}>
                Next
              </ActionButton>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AvailabilityGroupForm;

/**
 * Converts a {@link ApiVolunteerAvailabilityGroup} to {@link GroupFormValues}
 * to initialize the form.
 */
function apiGroupToFormFields(
  availabilityGroup: ApiVolunteerAvailabilityGroup
): GroupFormValues {
  return {
    name: availabilityGroup.name,

    // While the backend can store limitations for both cities and counties, but
    // our UI only allows one being set at a time.
    municipalities:
      availabilityGroup.default_allowed_location_cities !== null
        ? {
            type: 'cities',
            cities: availabilityGroup.default_allowed_location_cities,
          }
        : availabilityGroup.default_allowed_location_county_slugs !== null
        ? {
            type: 'counties',
            countySlugs:
              availabilityGroup.default_allowed_location_county_slugs,
          }
        : { type: 'all' },

    ignoreDistanceTag: !availabilityGroup.default_respect_travel_distance_tag,
  };
}

/**
 * Converts a {@link GroupFormValues} to a {@link ApiVolunteerAvailabilityGroup}
 * for saving.
 */
function groupFormFieldsToApi(
  values: GroupFormValues
): Immutable<NewApiVolunteerAvailabilityGroup> {
  return {
    name: values.name,

    // TODO(fiona): Support these
    van_event_id: null,
    van_state: null,

    default_allowed_location_cities:
      values.municipalities.type === 'cities'
        ? values.municipalities.cities
        : null,

    default_allowed_location_county_slugs:
      values.municipalities.type === 'counties'
        ? values.municipalities.countySlugs
        : null,

    // The UI doesn’t allow ignoring distance if the type is "all", but we
    // enforce it here again to be certain.
    default_respect_travel_distance_tag:
      values.municipalities.type === 'all' ? true : !values.ignoreDistanceTag,
  };
}

/**
 * Ensures that if a by-county or by-city limitation is chosen that at least one
 * county or city has been specified, respectively.
 */
function validateMunicipalitiesNotEmpty(
  val: LocationFilterOptions['municipalities']
): string[] | undefined {
  switch (val.type) {
    case 'all':
      return undefined;

    case 'cities':
      return val.cities.length === 0
        ? ['You must specify at least one city']
        : undefined;

    case 'counties':
      return val.countySlugs.length === 0
        ? ['You must specify at least one county']
        : undefined;

    default:
      assertUnreachable(val);
  }
}

/**
 * Handles conversion from any server-side error messages to our form.
 */
function apiErrorMessagesToForm(errors: {
  [key in keyof ApiVolunteerAvailabilityGroup | typeof FORM_ERROR]?: string[];
}): { [key in keyof GroupFormValues | typeof FORM_ERROR]?: string[] } {
  const out: { [key in keyof GroupFormValues | typeof FORM_ERROR]?: string[] } =
    {};

  if (errors[FORM_ERROR]) {
    out[FORM_ERROR] = errors[FORM_ERROR];
  }

  if (errors.name) {
    out.name = errors.name;
  }

  if (errors.default_respect_travel_distance_tag) {
    out.ignoreDistanceTag = errors.default_respect_travel_distance_tag;
  }

  if (
    errors.default_allowed_location_cities ||
    errors.default_allowed_location_county_slugs
  ) {
    out.municipalities = [
      ...(errors.default_allowed_location_cities ?? []),
      ...(errors.default_allowed_location_county_slugs ?? []),
    ];
  }

  return out;
}
