import UploadClient from './upload-client';

export function emailUsers(
  template: string,
  emailPayload: {
    [key: string]: string | Blob;
  }
) {
  return UploadClient(`users/email/${template}/`, emailPayload);
}

export default {
  emailUsers,
};
