import cx from 'classnames';
import * as Immutable from 'immutable';
import React from 'react';
import { Link, NavLink as ReactRouterNavLink } from 'react-router-dom';

import standsFor from '../../../constants/lbj-stands-for';
import { AppDispatch } from '../../../modules/flux-store';
import { ImmutableCurrentUserElection } from '../../../modules/user/reducers';
import * as LocalStoreService from '../../../services/local-store-service';
import {
  ApiCurrentUser,
  ApiElectionBanner,
} from '../../../services/user-service';
import { MapFromJs } from '../../../utils/types';
import NotificationPopover from '../../containers/notifications/notification-popover';

import ElectionBanners from './ElectionBanners';
import ElectionSwitcher, { ImmutableUserElection } from './election-switcher';
import NavLink from './nav-link';

/**
 * Used by our React Router <NavLink>s to add our `.is-active` class to an
 * (optional) existing class name when the link is active.
 */
const addIsActiveClassName =
  (className = '') =>
  ({ isActive }: { isActive: boolean; isPending: boolean }) =>
    cx(className, { 'is-active': isActive });

export default class Nav extends React.Component<
  {
    dispatch: AppDispatch;
    currentUserElection: ImmutableCurrentUserElection | null;
    currentUser: MapFromJs<ApiCurrentUser> | null;
    onLogoutClick: () => void;
    withCaptiveNav?: boolean | undefined;
    checkinAlert?: boolean;
    onNotificationPopoverOpen: () => void;
    onNotificationPopoverClose: () => void;
    unseenNotificationCount?: number;
    locationChangeCount?: number;
    userShouldSeeLocationNotifications?: boolean;
    /**
     * Pass true to disable the <NotificationPopover>, which is connected and
     * therefore can’t easily render in Storybook.
     */
    disableNotificationPopoverForTest?: boolean;
  },
  {
    showMobileNav: boolean;
    showNotificationPopover: boolean;
  }
> {
  constructor(props: Nav['props']) {
    super(props);

    this.onChoseUserElection = this.onChoseUserElection.bind(this);
    this.onDismissMobileNav = this.onDismissMobileNav.bind(this);
    this.onMobileToggleClick = this.onMobileToggleClick.bind(this);
    this.onNotificationIconClick = this.onNotificationIconClick.bind(this);
    this.onNotificationPopoverClose =
      this.onNotificationPopoverClose.bind(this);
    this.renderLoggedInNav = this.renderLoggedInNav.bind(this);
    this.renderMobileFooter = this.renderMobileFooter.bind(this);
    this.renderMobileTakeover = this.renderMobileTakeover.bind(this);

    this.state = {
      showMobileNav: false,
      showNotificationPopover: false,
    };
  }

  onChoseUserElection(userElection: ImmutableUserElection) {
    const newURI =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    LocalStoreService.updateUserElectionIds(
      userElection.get('id'),
      userElection.getIn(['election', 'id']) as number
    );
    LocalStoreService.purgeCache();
    window.history.pushState({ path: newURI }, '', newURI);
    window.location.reload();
  }

  onMobileToggleClick(e: React.MouseEvent) {
    e.preventDefault();
    const { showMobileNav } = this.state;

    this.setState({ showMobileNav: !showMobileNav });
  }

  onDismissMobileNav() {
    this.setState({ showMobileNav: false });
  }

  onNotificationIconClick(e: React.MouseEvent) {
    e.preventDefault();
    const { showNotificationPopover } = this.state;
    const { onNotificationPopoverOpen, onNotificationPopoverClose } =
      this.props;
    const newOpenState = !showNotificationPopover;

    if (newOpenState) {
      onNotificationPopoverOpen();
    } else {
      onNotificationPopoverClose();
    }

    this.setState({ showNotificationPopover: !showNotificationPopover });
  }

  onNotificationPopoverClose() {
    this.setState({ showNotificationPopover: false });
    this.props.onNotificationPopoverClose();
  }

  renderAssignmentNavItem(viewOnly: boolean = false) {
    return (
      <li>
        <NavLink to="/assignments" className="has-lbj-dropdown">
          Assign
        </NavLink>
        <ul className="lbj-sub-nav">
          <li>
            <NavLink
              to={{ pathname: '/assignments/', query: { view: 'locations' } }}
            >
              Polls
            </NavLink>
          </li>
          <li>
            <NavLink to={{ pathname: '/assign/locations' }} noFilters>
              Polls (beta)
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: '/assignments/',
                query: { view: 'board_of_elections' },
              }}
            >
              Board of Elections
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: '/assignments/',
                query: { view: 'boiler_rooms' },
              }}
            >
              Boiler/Hotline
            </NavLink>
          </li>
          {!viewOnly && (
            <li>
              <NavLink
                to={{ pathname: '/assignments/', query: { view: 'people' } }}
              >
                People
              </NavLink>
            </li>
          )}
        </ul>
      </li>
    );
  }

  renderInviteNavItem() {
    return (
      <li>
        <NavLink to="/invite" className="has-lbj-dropdown">
          Users
        </NavLink>
        <ul className="lbj-sub-nav">
          <li>
            <NavLink to={{ pathname: '/invite', query: {} }}>All Users</NavLink>
          </li>
          <li>
            <NavLink to={{ pathname: '/new_user', query: {} }} noFilters>
              Add New User
            </NavLink>
          </li>
        </ul>
      </li>
    );
  }

  renderCheckinNavItem() {
    return (
      <li>
        <ReactRouterNavLink to="/checkin" className={addIsActiveClassName()}>
          Check In
        </ReactRouterNavLink>
      </li>
    );
  }

  renderIssuesNavItem(viewOnly: boolean = false) {
    return (
      <li>
        <NavLink to="/issues" className="has-lbj-dropdown">
          Issues
        </NavLink>
        <ul className="lbj-sub-nav">
          <li>
            <NavLink to={{ pathname: '/issues' }}>Issues</NavLink>
          </li>
          <li>
            <NavLink to={{ pathname: '/results' }} noFilters>
              Results
            </NavLink>
          </li>
          {!viewOnly && (
            <li>
              <NavLink to={{ pathname: '/issues/new' }} noFilters>
                New Issue
              </NavLink>
            </li>
          )}
        </ul>
      </li>
    );
  }

  renderDashboardNavItem() {
    return (
      <li>
        <NavLink to="/dashboard">Dashboard</NavLink>
      </li>
    );
  }

  renderAnalyticsNavItem() {
    return (
      <li>
        <NavLink to="/analytics">Analytics</NavLink>
      </li>
    );
  }

  renderNotificationBadge() {
    const {
      locationChangeCount,
      unseenNotificationCount,
      userShouldSeeLocationNotifications,
    } = this.props;

    if (
      !!locationChangeCount &&
      !unseenNotificationCount &&
      userShouldSeeLocationNotifications
    ) {
      return <span className="notification-count" />;
    }

    if (unseenNotificationCount) {
      return (
        <span className="notification-count">{unseenNotificationCount}</span>
      );
    }
  }

  renderSettingsNavItem() {
    return (
      <li>
        <ReactRouterNavLink
          to="/election-settings"
          className={addIsActiveClassName(
            'lbj-nav-settings-link material-icons flex items-center px-6 text-lg'
          )}
        >
          settings
        </ReactRouterNavLink>
      </li>
    );
  }

  renderNotificationsNavItem() {
    const { disableNotificationPopoverForTest } = this.props;
    const { showNotificationPopover } = this.state;

    return (
      <li>
        <a
          href="#"
          className="lbj-nav-notifications-link alert-inactive"
          onClick={this.onNotificationIconClick}
        >
          {this.renderNotificationBadge()}
        </a>
        {!disableNotificationPopoverForTest && (
          <NotificationPopover
            isOpen={showNotificationPopover}
            onClose={this.onNotificationPopoverClose}
          />
        )}
      </li>
    );
  }

  renderProfileNavItem() {
    const { onLogoutClick } = this.props;

    return (
      <li>
        <ReactRouterNavLink
          to="/profile"
          className={addIsActiveClassName(
            'lbj-nav-profile-link has-lbj-dropdown'
          )}
        />
        <ul className="lbj-sub-nav right-of-page">
          <li>
            <Link to="/profile" onClick={this.onDismissMobileNav}>
              My Account
            </Link>
          </li>
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onLogoutClick();
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </li>
    );
  }

  renderVPDNav() {
    return (
      <ul className="lbj-nav-list">
        {this.renderInviteNavItem()}
        {this.renderAssignmentNavItem()}
        {this.renderCheckinNavItem()}
        {this.renderIssuesNavItem()}
        {this.renderAnalyticsNavItem()}
        {this.renderDashboardNavItem()}
        {this.renderSettingsNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderBoilerRoomLeaderNav() {
    return (
      <ul className="lbj-nav-list">
        {this.renderInviteNavItem()}
        {this.renderAssignmentNavItem()}
        {this.renderCheckinNavItem()}
        {this.renderIssuesNavItem()}
        {this.renderAnalyticsNavItem()}
        {this.renderDashboardNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderBoilerRoomNav() {
    return (
      <ul className="lbj-nav-list">
        {this.renderAssignmentNavItem()}
        {this.renderCheckinNavItem()}
        {this.renderIssuesNavItem()}
        {this.renderAnalyticsNavItem()}
        {this.renderDashboardNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderHotlineNav() {
    return (
      <ul className="lbj-nav-list">
        {this.renderIssuesNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderPollObserverNav() {
    return (
      <ul className="lbj-nav-list">
        {this.renderCheckinNavItem()}
        {this.renderIssuesNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderViewOnlyNav() {
    const viewOnly = true;
    return (
      <ul className="lbj-nav-list">
        {this.renderAssignmentNavItem(viewOnly)}
        {this.renderIssuesNavItem(viewOnly)}
        {this.renderAnalyticsNavItem()}
        {this.renderDashboardNavItem()}
        {this.renderNotificationsNavItem()}
        {this.renderProfileNavItem()}
      </ul>
    );
  }

  renderNoPermissionsNav() {
    return <ul className="lbj-nav-list">{this.renderProfileNavItem()}</ul>;
  }

  renderLoggedInNav(currentUser: MapFromJs<ApiCurrentUser>) {
    const userRole = currentUser.get('role');
    if (userRole === 'vpd' || userRole === 'deputy_vpd') {
      return this.renderVPDNav();
    }

    if (userRole === 'boiler_room_leader') {
      return this.renderBoilerRoomLeaderNav();
    }

    if (userRole === 'boiler_room_user') {
      return this.renderBoilerRoomNav();
    }

    if (userRole === 'hotline_manager' || userRole === 'hotline_worker') {
      return this.renderHotlineNav();
    }

    if (userRole === 'poll_observer') {
      return this.renderPollObserverNav();
    }

    if (userRole === 'view_only') {
      return this.renderViewOnlyNav();
    }

    return this.renderNoPermissionsNav();
  }

  renderMobileFooter() {
    const { checkinAlert } = this.props;
    return (
      <ul className="lbj-nav-footer">
        <li className="lbj-nav-footer-item">
          <ReactRouterNavLink
            to="/checkin"
            className={addIsActiveClassName(
              cx('lbj-nav-footer-link', 'icon-checkmark', {
                'alert-active': checkinAlert,
              })
            )}
          >
            Check-in
          </ReactRouterNavLink>
        </li>
        <li className="lbj-nav-footer-item">
          <ReactRouterNavLink
            to={{ pathname: '/issues/new', search: 'mobile=1' }}
            className={addIsActiveClassName('lbj-nav-footer-link icon-plus')}
          >
            New issue
          </ReactRouterNavLink>
        </li>
        <li className="lbj-nav-footer-item">
          <ReactRouterNavLink
            to="/notifications"
            className={addIsActiveClassName('lbj-nav-footer-link icon-bell')}
          >
            Alerts
          </ReactRouterNavLink>
        </li>
        <li className="lbj-nav-footer-item">
          <a
            href="#"
            className="lbj-nav-footer-link"
            onClick={this.onMobileToggleClick}
          >
            <span className="icon-hamburger">
              <span />
              <span />
              <span />
            </span>
            More
          </a>
        </li>
      </ul>
    );
  }

  renderMobileTakeover() {
    const { onLogoutClick } = this.props;

    return (
      <div className="lbj-nav-takeover">
        <a
          className="lbj-nav-takeover-dismiss"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.onDismissMobileNav();
          }}
        />
        <ul>
          <li>
            <ReactRouterNavLink
              to="/checkin"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              Check-in
            </ReactRouterNavLink>
          </li>
          <li>
            <ReactRouterNavLink
              to="/issues/new"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              New issue
            </ReactRouterNavLink>
          </li>
          <li>
            <ReactRouterNavLink
              to="/notifications"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              Alerts
            </ReactRouterNavLink>
          </li>
          <li>
            <ReactRouterNavLink
              to="/issues"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              My Issues
            </ReactRouterNavLink>
          </li>
          <li>
            <ReactRouterNavLink
              to="/profile?view=assignments"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              My assignments
            </ReactRouterNavLink>
          </li>
          <li>
            <ReactRouterNavLink
              to="/profile?view=details"
              className={addIsActiveClassName('lbj-nav-takeover-link')}
              onClick={this.onDismissMobileNav}
            >
              My account
            </ReactRouterNavLink>
          </li>
          <li>
            <a
              className="lbj-nav-takeover-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onLogoutClick();
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { dispatch, currentUserElection, currentUser, withCaptiveNav } =
      this.props;
    const { showMobileNav } = this.state;
    const isInTrainingMode =
      currentUserElection && currentUserElection.getIn(['election', 'is_test']);
    const className = cx('lbj-nav', {
      'is-training-mode': isInTrainingMode,
      'is-showing-mobile': showMobileNav,
    });

    const userElections = currentUser
      ? currentUser
          .get('user_elections')
          .filter((userElection: ImmutableUserElection) => {
            return (
              userElection.get('active') &&
              (userElection.getIn(['election', 'active']) ||
                userElection.get('role') === 'vpd' ||
                userElection.get('role') === 'deputy_vpd')
            );
          })
      : Immutable.Map();

    const banners =
      (currentUser &&
        (currentUser.get('banners')?.toJS() as ApiElectionBanner[])) ||
      [];

    const navTitleAlt = standsFor[Math.floor(Math.random() * standsFor.length)];

    return (
      <>
        <nav className={className}>
          <Link to="/" title={navTitleAlt}>
            <div className="lbj-logo-wrapper a-float-left">
              <img className="lbj-logo" src="../../../../img/logo.svg" />
            </div>
          </Link>

          <ElectionSwitcher
            dispatch={dispatch}
            userElections={userElections}
            currentUserElection={currentUserElection}
            onChoseUserElection={this.onChoseUserElection}
          />

          {!withCaptiveNav && (
            <div className="lbj-nav-list-wrapper">
              {currentUser && this.renderLoggedInNav(currentUser)}
            </div>
          )}

          {currentUser && !withCaptiveNav && this.renderMobileFooter()}
          {showMobileNav && this.renderMobileTakeover()}
        </nav>

        <ElectionBanners banners={banners} />
      </>
    );
  }
}
