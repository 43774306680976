import type {
  AriaLabelingProps,
  DOMProps,
  FocusableDOMProps,
  FocusableProps,
  InputBase,
  LabelableProps,
  TextInputBase,
  Validation,
} from '@react-types/shared';
import React from 'react';
import { Item } from 'react-stately';

import { State } from '../../constants';
import { CountySlug } from '../../services/common';
import { useChanges } from '../../utils/hooks';
import { ComboBox } from '../form';
import { useCounties } from '../hooks/lbj-data';

/**
 * `<ComboBox>` that uses the {@link useCounties} hook to show counties for a
 * given state.
 */
export const CountySelect: React.FunctionComponent<
  {
    state: State | null;
    selectedKey?: CountySlug | null | undefined;
    defaultSelectedKey?: CountySlug | undefined;
    onSelectionChange?: (slug: CountySlug | null) => void;
  } & FocusableProps &
    Validation &
    AriaLabelingProps &
    InputBase &
    TextInputBase &
    LabelableProps &
    DOMProps &
    FocusableDOMProps
> = ({
  label = 'County',
  isDisabled,
  state,
  selectedKey,
  defaultSelectedKey,
  onSelectionChange,
  ...props
}) => {
  const counties = useCounties(state);

  // Clear the current county when the state changes.
  useChanges(
    (_deps) => {
      onSelectionChange?.(null);
    },
    [state]
  );

  return (
    <ComboBox
      label={label}
      defaultItems={counties ?? []}
      isDisabled={!!(state === null || isDisabled)}
      shouldCloseOnBlur
      menuTrigger="focus"
      {...(selectedKey !== undefined ? { selectedKey } : {})}
      {...(defaultSelectedKey !== undefined ? { defaultSelectedKey } : {})}
      onSelectionChange={(key) => onSelectionChange?.(key as CountySlug)}
      {...props}
    >
      {({ slug, name }) => <Item key={slug}>{name}</Item>}
    </ComboBox>
  );
};
