import React from 'react';

import { states, US_NATIONAL_STATE_CODE, State } from '../../../constants';

import Autocomplete from './autocomplete';

export default class StateSelect extends React.Component<
  {
    includeNational: boolean;
    includeTerritories?: boolean;
    name: string;
    title: string;
  } & Omit<React.ComponentProps<typeof Autocomplete<State>>, 'name' | 'choices'>
> {
  static defaultProps = {
    name: 'state',
    title: 'State',
  };

  render() {
    const { name, includeNational, includeTerritories, ...rest } = this.props;

    const choices: Partial<typeof states> = Object.assign({}, states);

    if (!includeNational) {
      delete choices[US_NATIONAL_STATE_CODE];
    }

    if (!includeTerritories) {
      delete choices['AS'];
      delete choices['DA'];
      delete choices['GU'];
      delete choices['MP'];
      delete choices['PR'];
      delete choices['VI'];
    }

    return <Autocomplete<State> name={name} choices={choices} {...rest} />;
  }
}
