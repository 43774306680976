import cx from 'classnames';
import React from 'react';
import {
  AriaRadioGroupProps,
  AriaRadioProps,
  useRadio,
  useRadioGroup,
} from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';

import { FormError } from './FormError';
import { FormLabel } from './FormLabel';

/**
 * Context that {@link Radio} components will read. Typically provided by
 * {@link RadioGroup}.
 */
const RadioContext = React.createContext<RadioGroupState>({
  selectedValue: null,
  name: '',
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  setSelectedValue: function () {
    throw new Error('Function not implemented.');
  },
  lastFocusedValue: null,
  setLastFocusedValue: function () {
    throw new Error('Function not implemented.');
  },
  validationState: 'valid',
});

/**
 * Grouping for radio buttons. Expects its chlidren to be {@link Radio}
 * components, and provides a {@link RadioContext} for them.
 */
export const RadioGroup: React.FunctionComponent<AriaRadioGroupProps> = ({
  orientation = 'vertical',
  ...props
}) => {
  const { children, label, description, errorMessage, validationState } = props;

  const state = useRadioGroupState(props);

  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup({ orientation, ...props }, state);

  return (
    <div
      {...radioGroupProps}
      className={cx('flex gap-2', {
        'flex-col': orientation === 'vertical',
      })}
    >
      {typeof label === 'string' ? (
        <FormLabel type="span" isRequired={props.isRequired} {...labelProps}>
          {label}
        </FormLabel>
      ) : (
        props.label
      )}

      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>

      {description && <div {...descriptionProps}>{description}</div>}

      {errorMessage && validationState === 'invalid' && (
        <FormError {...errorMessageProps}>{errorMessage}</FormError>
      )}
    </div>
  );
};

/**
 * Radio button. Must be rendered within a {@link RadioGroup} component so it
 * can access a current {@link RadioContext}.
 */
export const Radio: React.FunctionComponent<AriaRadioProps> = (props) => {
  const { children } = props;
  const state = React.useContext(RadioContext);
  const ref = React.useRef(null);
  const { inputProps } = useRadio(props, state, ref);

  return (
    <label className="flex leading-none">
      {/* TODO(fiona): Style this non-browser? */}
      <input {...inputProps} ref={ref} />

      {children}
    </label>
  );
};
