import { FormState } from 'final-form';
import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

import UserForm, { UserFormValues } from '../../../pages/profile/UserForm';

import { ApiUserTag } from '../../../services/lbj-shared-service';
import { ApiCurrentUser } from '../../../services/user-service';

import { MapFromJs } from '../../../utils/types';
import { PageTitle2 } from '../../common';
import { ActionButton } from '../../form';

/**
 * Form that collects required user information at login.
 */
const SurveyUserDetails: React.FunctionComponent<{
  currentUser: MapFromJs<ApiCurrentUser>;
  tagData: Immutable.List<MapFromJs<ApiUserTag>>;
  /** True if poll observer availability info is required. */
  requirePollObserverInfo: boolean;
  isUpdatingUser: boolean;
  updatingUserErred: boolean;
  onSubmit: (
    fields: Immutable.Map<string, string | boolean | Immutable.List<string>>
  ) => void;
  errorMsg: string | undefined;
}> = ({
  currentUser,
  tagData,
  requirePollObserverInfo,
  onSubmit,
  updatingUserErred,
  errorMsg,
  isUpdatingUser,
}) => {
  // We snapshot the user when this is mounted and use that to populate the form
  // going forward. In this UI we don’t need to handle it changing, since a
  // successful update will lead to a page navigation.
  const initialUser = React.useRef(currentUser.toJS() as ApiCurrentUser);

  const [{ invalid, submitFailed }, setFormState] = React.useState<
    Pick<FormState<UserFormValues>, 'invalid' | 'submitFailed'>
  >({ invalid: false, submitFailed: false });

  return (
    <div className="reset-2023 mx-auto my-8 flex max-w-2xl flex-col items-stretch gap-10">
      <div className="flex max-w-lg flex-col gap-4 self-center px-4 text-center">
        <PageTitle2>Welcome to LBJ!</PageTitle2>

        <p className="w-80 text-base leading-normal">
          Please double check that all of the information in your profile is
          up-to-date. <br />
          Form fields marked with{' '}
          <span className="font-bold text-red-700">*</span> are required.
        </p>
      </div>

      {updatingUserErred && (
        <div className="c-alert-error">
          {errorMsg || 'We were unable to save your information.'}
        </div>
      )}

      <UserForm
        id="user-form"
        isEditingSelf
        showAddressFields={currentUser.get('role') === 'poll_observer'}
        showExperienceFields={
          currentUser.get('role') === 'poll_observer' && requirePollObserverInfo
        }
        showInPersonVolunteeringFields={
          currentUser.get('role') === 'poll_observer' && requirePollObserverInfo
        }
        requireAddressFields
        requireExperienceFields
        requireInPersonVolunteeringFields
        formSubscription={{ invalid: true, submitFailed: true }}
        onFormChange={({ invalid, submitFailed }) =>
          setFormState({ invalid, submitFailed })
        }
        onSubmit={(updates) => onSubmit(Immutable.fromJS(updates) as any)}
        tagData={tagData.toJS() as ApiUserTag[]}
        user={initialUser.current}
      />

      {invalid && submitFailed && (
        <div className="border-2 border-yellow-700 bg-yellow-300 p-2">
          Please correct the errors on this form and submit again.
        </div>
      )}

      {errorMsg && (
        <div className="border-2 border-red-700 bg-red-300 p-2">{errorMsg}</div>
      )}

      <div className="mx-4 flex flex-col items-stretch">
        <ActionButton
          role="primary"
          type="submit"
          elementProps={{ form: 'user-form' }}
          isDisabled={!!isUpdatingUser}
        >
          Save and Continue
        </ActionButton>
      </div>
    </div>
  );
};

export default SurveyUserDetails;
