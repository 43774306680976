import { Query } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import {
  LoaderFunctionArgs,
  Navigate,
  NavigateFunction,
  RouteObject,
  useLoaderData,
} from 'react-router-dom';

import { UserRole } from '../constants';
import * as filterModule from '../modules/filters';
import * as filterActionCreators from '../modules/filters/action-creators';
import { AppState, AppStore } from '../modules/flux-store';
import { CurrentUser } from '../modules/user/action-creators';
import * as userActionCreators from '../modules/user/action-creators';
import { ImmutableCurrentUserElection } from '../modules/user/reducers';
import { ApiElection } from '../services/lbj-shared-service';
import * as LocalStorageService from '../services/local-store-service';
import * as LoginService from '../services/login-service';
import { setUserContext } from '../services/sentry-service';
import { ApiCurrentUser } from '../services/user-service';
import { Awaitable, MapFromJs } from '../utils/types';

/**
 * Short for “renderer route.” (Abbreviated name so that this scans well in the
 * routes list.)
 *
 * Wraps React Router’s {@link RouteObject} definition to add type checking to
 * the loader pattern.
 *
 * This takes an object that matches {@link RouteObject} except instead of
 * `element` it has a `renderer` function. We use TypeScript to ensure that the
 * argument to the `renderer` matches the awaited type of the `loader` function.
 *
 * This uses {@link LoaderDataWrapper} in order to hide the use of React
 * Router’s {@link useLoaderData}.
 *
 * Overall this ergonomically addresses the two biggest design flaws with loader
 * functions, that their return values are not provided in a type-checked way
 * and that they’re only available through a hook without any seams for testing.
 */
export function rrte<T extends object>(
  route: Omit<RouteObject, 'element' | 'loader'> & {
    renderer: (props: T) => React.ReactElement;
    loader: (arg: LoaderFunctionArgs) => Awaitable<T>;
  }
): RouteObject {
  const { renderer, index, children, ...routeRest } = route;

  const out = {
    ...routeRest,
    element: <LoaderDataWrapper renderer={renderer} />,
  };

  // Slight shenanigans because RouteObject is a discriminated union. “index”
  // routes are not allowed to have children.
  return index
    ? {
        index,
        ...out,
      }
    : children
    ? {
        children,
        ...out,
      }
    : {
        ...out,
      };
}

/**
 * Typesafe wrapper around {@link useLoaderData}. Allows us to both put a type
 * on the data that comes out of `loader` as well as keep our `Page` components
 * from depending on `useLoaderData` during tests.
 */
export function LoaderDataWrapper<T>({
  renderer,
}: {
  renderer: (props: T) => React.ReactElement;
}): React.ReactElement {
  const props = useLoaderData() as T;

  return renderer(props);
}

/**
 * @see UserNotAvailableError
 */
export type UserNotAvailableReason = 'NOT_LOGGED_IN' | 'USER_LOADING_ERROR';

/**
 * @see UserNotReadyError
 */
export type UserNotReadyReason =
  | 'NO_ELECTIONS'
  | 'MISSING_EULA'
  | 'NOT_AUTHORIZED';

/**
 * Exceptions thrown by {@link loadCurrentUser} indicating why a user is not
 * able to be loaded.
 */
export class UserNotAvailableError extends Error {
  readonly reason;

  constructor(reason: UserNotAvailableReason) {
    super();

    this.reason = reason;
  }
}

/**
 * Exceptions thrown by {@link loadCurrentUser} indicating why a logged in user
 * is not “ready” for LBJ and shouldn’t have access to the whole UI.
 *
 * Unlike {@link UserNotAvailableError}, we do have a user object and possibly a
 * current user election in this case.
 */
export class UserNotReadyError extends Error {
  readonly reason;
  readonly currentUser;
  readonly currentUserElection;

  constructor(
    reason: UserNotReadyReason,
    currentUser: MapFromJs<ApiCurrentUser>,
    currentUserElection: ImmutableCurrentUserElection | null
  ) {
    super();

    this.reason = reason;
    this.currentUser = currentUser;
    this.currentUserElection = currentUserElection;
  }
}

/**
 * Call this from React Router loaders to get the current user and current user
 * election from the Redux store. If they’re not available, will either load
 * them or throw an exception.
 *
 * There are two types of exceptions that this code can throw:
 *
 * * {@link UserNotAvailableError} for when the user is logged out or there’s a
 *   backend error trying to get the current user data.
 * * {@link UserNotReadyError} for when the user is logged in, but their account
 *   is in a state that means they’re not ready for the app (e.g. haven’t signed
 *   the EULA).
 *
 * This function does not throw if the user hasn’t confirmed their email, as
 * that is handled via a redirect in LoggedInApp’s loader.
 *
 * @see UserAuthBoundary
 */
export async function loadCurrentUser(
  fluxStore: AppStore,
  opts: { allowedRoles?: UserRole[] } = {}
): Promise<{
  currentUser: MapFromJs<ApiCurrentUser>;
  currentUserElection: ImmutableCurrentUserElection;
}> {
  let currentUser: MapFromJs<ApiCurrentUser> | null = null;
  let currentUserElection: ImmutableCurrentUserElection | null = null;
  let currentElection: MapFromJs<ApiElection> | null = null;

  try {
    if (!LoginService.isLoggedIn()) {
      // We could throw a redirect here instead, but are instead doing a more
      // typed error to separate the concerns of “there’s no one logged in” from
      // “what do we do if no one is logged in?”
      throw new UserNotAvailableError('NOT_LOGGED_IN');
    }

    // We have to use the global flux store here because this is all happening
    // outside of the component rendering hierarchy.
    let { user } = fluxStore.getState();

    currentUser = user.currentUser.userData;
    currentUserElection = user.currentUser.currentUserElection;
    currentElection =
      user.currentUser.currentUserElection?.get('election') ?? null;

    if (
      !currentUser ||
      (currentUserElection &&
        currentUserElection.get('id') !==
          LocalStorageService.getCurrentUserElectionId()) ||
      (currentElection &&
        currentElection.get('id') !==
          LocalStorageService.getCurrentElectionId())
    ) {
      // The local storage check is designed to catch if the user has changed
      // their election in another tab and now loads a new route in this one.
      //
      // See: https://democrats.atlassian.net/browse/LBJ-87

      let requestedElectionId: number | null = null;

      // This is just a failsafe in case there is something strange with the
      // logic or behavior. We want to make sure we’re not caught forever trying
      // to establish the right election.
      let loopCount = 0;

      do {
        loopCount++;

        // OK. This one’s a good one.
        //
        // The /current_user/ endpoint is _mostly_ just about the authenticated
        // LBJUser, but certain fields are actually dependent on the specific
        // election being requested. These are the fields that are stored on /
        // related to UserElection, in particular role, assignment_state, and
        // tags.
        //
        // Nevertheless, on either the first request from a _brand new browser_,
        // or when the last time the browser was used the user was in an
        // election that they don’t have access to anymore, the backend doesn’t
        // know which election to use for these fields. It has to wait to get
        // the /current_user/ response back first, then it grabs the first
        // active one from the list. (See `pickCurrentUserElection` called from
        // the reducer.)
        //
        // For role and assignment_state, it is able to patch the currentUser
        // state value with data from the election that it selected. But
        // /current_user/ doesn’t serialize all _tags_ for the user’s elections,
        // so it can’t do that for them.
        //
        // This means that, for the first /current_user/ request on a fresh
        // browser, the tags property of the currentUser will _always_ be [].
        // This causes a problem when, since the user is nice and fresh, the
        // first thing they need to do is confirm their email address or
        // availability preferences via the survey, which PUTs back a user
        // object derived from the tagless one in state.
        //
        // Which leads to https://democrats.atlassian.net/browse/LBJ-892 and
        // deleting all of a user’s tags. (And keeping them from being restored
        // via VAN Sync.)
        //
        // Since changing the semantics of /current_user/ is out-of-scope right
        // now (8 days before eday 2024) we instead opt to detect this situation
        // and, now that the frontend has chosen an election / user_election
        // that it’s showing, re-fetch /current_user/ in that context so that we
        // have the proper tags.
        //
        // We do this by looking for a change in the locally-stored election ID.
        // If loading the current user causes that value to change, we know that
        // the tags value on currentUser will be wrong and _not_ match the
        // election the UI is showing, so we run the fetch again.
        //
        // This should take _at most_ 2 total requests (initial and catch-up).
        // Since there is a lot of logic to run each time, we opt to structure
        // this as a loop, and we have a safety valve that bails if it happens
        // more than 3 times so we’re not stuck with a bunch of users DDoSing us
        // trying to stablize their elections.
        requestedElectionId = LocalStorageService.getCurrentElectionId();

        // TODO(fiona): handle de-duping this if nested routes call
        // `loadCurrentUser` simultaneously.
        await fluxStore.dispatch(userActionCreators.getCurrentUserAsync());
        user = fluxStore.getState().user;

        // getCurrentUserAsync resolves in both success and failure cases, so we
        // need to check the store to see the status.
        if (user.currentUser.requestErred || !user.currentUser.userData) {
          throw new UserNotAvailableError('USER_LOADING_ERROR');
        }

        currentUser = user.currentUser.userData;
        currentUserElection = user.currentUser.currentUserElection;
        currentElection = currentUserElection?.get('election') ?? null;
      } while (
        // If the requested election ID matches the current election ID then we
        // know that tags is correct and we can move on.
        (currentElection?.get('id') ?? null) !== requestedElectionId ||
        loopCount > 2
      );

      // Sets up internal defaults for things like filtering by state.
      //
      // TODO(fiona): This should be handled at the page level.
      fluxStore.dispatch(
        filterActionCreators.setCurrentUserDefaults(currentUser)
      );
    }

    if (!currentUserElection) {
      throw new UserNotReadyError(
        'NO_ELECTIONS',
        currentUser,
        currentUserElection
      );
    } else if (!currentUser.get('signed_eula')) {
      throw new UserNotReadyError(
        'MISSING_EULA',
        currentUser,
        currentUserElection
      );
    } else if (
      opts.allowedRoles &&
      !opts.allowedRoles.includes(currentUser.get('role'))
    ) {
      throw new UserNotReadyError(
        'NOT_AUTHORIZED',
        currentUser,
        currentUserElection
      );
    }

    return { currentUser, currentUserElection };
  } finally {
    // Update Sentry, regardless of whether things succeeded or not.
    setUserContext(currentUser ? { id: currentUser.get('id').toString() } : {});
  }
}

export function storeAppFilters(
  fluxStore: AppStore,
  queryParams: Query,
  appSection: keyof filterActionCreators.FiltersBySection
) {
  const { dispatch } = fluxStore;
  const { setFiltersAsync } = filterModule.actionCreators;
  return dispatch(setFiltersAsync(queryParams, appSection));
}

/**
 * Returns the path to redirect a user to if they’re unauthorized for the place
 * they’ve gotten to.
 */
export function findHome(currentUser: MapFromJs<CurrentUser> | null) {
  if (!currentUser) {
    return '/';
  }

  const issueRoles: UserRole[] = [
    'vpd',
    'deputy_vpd',
    'boiler_room_leader',
    'boiler_room_user',
    'hotline_manager',
    'hotline_worker',
    'poll_observer',
    'view_only',
  ];

  const homeRoles: UserRole[] = ['poll_observer'];

  if (homeRoles.includes(currentUser.get('role'))) {
    return '/home';
  }

  if (issueRoles.includes(currentUser.get('role'))) {
    return '/issues';
  } else {
    return '/profile';
  }
}

/**
 * For restricted routes, redirect users back to root if they stumble upon areas
 * they should not.
 *
 * TODO(fiona): Make this throw a 403 and handle the redirect in an error
 * boundary.
 *
 * @return flag to indicate that the route handler should stop execution.
 */
export function redirectUnauthorizedUser(
  fluxStore: AppStore,
  navigate: NavigateFunction,
  allowedRoles: UserRole[] = ['vpd', 'deputy_vpd']
) {
  const { getState } = fluxStore;
  const currentUser = getState().user.currentUser.userData;

  if (!currentUser || !allowedRoles.includes(currentUser.get('role'))) {
    navigate(findHome(currentUser));
    return true;
  }

  return false;
}

/**
 * Wrapper component that renders its children if the user has one of the
 * allowed roles. Otherwise redirects away.
 */
const UnconnectedRequireRole: React.FunctionComponent<{
  currentUser: MapFromJs<ApiCurrentUser> | null;
  allowedRoles: UserRole[];
}> = ({ currentUser, allowedRoles, children }) => {
  if (!currentUser) {
    return <Navigate to="/" />;
  } else if (!allowedRoles.includes(currentUser.get('role'))) {
    return <Navigate to={findHome(currentUser)} />;
  } else {
    return <>{children}</>;
  }
};

export const RequireRole = connect((state: AppState) => ({
  currentUser: state.user.currentUser.userData,
}))(UnconnectedRequireRole);

/**
 * Component that directs users to the appropriate home page
 */
export const HomeRouteRedirector: React.FunctionComponent<{
  currentUser: MapFromJs<ApiCurrentUser>;
}> = ({ currentUser }) => {
  return <Navigate to={findHome(currentUser)} />;
};

/**
 * Route loader for {@link HomeRouteRedirector}.
 */
export async function loadHomeRouteRedirector(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof HomeRouteRedirector>> {
  const { currentUser } = await loadCurrentUser(fluxStore);

  return {
    currentUser,
  };
}
