import type { CheckboxProps } from '@react-types/checkbox';
import type {
  HelpTextProps,
  TextInputDOMProps,
  Validation,
} from '@react-types/shared';
import type { TextFieldProps } from '@react-types/textfield';
import { FieldMetaState, FieldRenderProps } from 'react-final-form';

/**
 * Calls all provided validators in order, returning the first one that errors.
 */
export function chainValidations<T>(
  ...validators: Array<(value: T) => any>
): (value: T) => any {
  return (value) => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }

    return undefined;
  };
}

/**
 * Simple validator function to ensure a form field is filled in.
 */
export function requiredValidation(
  label: string
): (value: string | null) => string | undefined {
  return (value) => {
    if (!value?.trim()) {
      return `${label} is required.`;
    } else {
      return undefined;
    }
  };
}

/**
 * Converts the final-form {@link FieldRenderProps} to react-aria props for text
 * fields.
 */
export function adaptTextFieldRenderProps({
  input,
  meta,
}: FieldRenderProps<string | null>): TextFieldProps & TextInputDOMProps {
  return {
    name: input.name,
    value: input.value ?? '',

    // Type hacks here because Final Form is expecting events with HTMLElement
    // targets, while react-aria only types to Element targets. Since we know
    // they are HTMLElement targets, these casts are safe.
    onBlur: (input.onBlur as TextFieldProps['onBlur'])!,
    onFocus: (input.onFocus as TextFieldProps['onFocus'])!,
    onChange: input.onChange,

    ...adaptFieldMetaErrors(meta),
  };
}

/**
 * Converts the final-form {@link FieldRenderProps} to react-aria props for
 * checkboxes.
 *
 * Note that you’ll need to pass `type="checkbox"` to `<Field>` so that
 * final-form provides a `checked` value.
 */
export function adaptCheckboxRenderProps({
  input,
  meta,
}: FieldRenderProps<boolean>): CheckboxProps & HelpTextProps {
  return {
    isSelected: input.checked ?? false,

    onBlur: (input.onBlur as CheckboxProps['onBlur'])!,
    onFocus: (input.onFocus as CheckboxProps['onFocus'])!,
    onChange: input.onChange,

    ...adaptFieldMetaErrors(meta),
  };
}

/**
 * Common helper to convert final-form errors to the `validationState` and
 * `errorMessage` props that react-aria uses.
 *
 * Only passes the errors if `touched` is true so that we don’t show errors
 * until the user interacts with the form element, or submits the form.
 */
export function adaptFieldMetaErrors(
  meta: FieldMetaState<unknown>
): HelpTextProps & Validation {
  return meta.touched
    ? {
        validationState: meta.invalid ? 'invalid' : 'valid',
        errorMessage:
          meta.error ||
          // submitErrors are errors added by the submit handler, presumably
          // from server responses. We show them until the field gets modified.
          (!meta.modifiedSinceLastSubmit ? meta.submitError : undefined),
      }
    : {};
}
