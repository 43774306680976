import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators } from '../../../modules/assignment';

import mapStateToStartEndTime from '../../../utils/assignment/map-state-to-start-end-time';

import AssignmentRelatedObject from './assignment-related-object';

@connect((state, ownProps) => {
  // TODO: replace shiftDate val to not be dependent on filters
  const { assignment, filters } = state;
  const { boilerRoom } = ownProps;
  const assignmentEditor = assignment.get('assignmentEditor');
  const isShowingDetails =
    assignmentEditor.getIn(['parentResource', 'boiler_room', 'id']) ===
    boilerRoom.get('id');
  const shiftDate = filters.getIn([lbjAppSections.ASSIGNMENT, 'dates']);
  const { startTime, endTime } = mapStateToStartEndTime(state, shiftDate);

  return {
    isShowingDetails,
    isAssigning: isShowingDetails && assignmentEditor.get('isAssigning'),
    pendingAssignment: assignmentEditor.getIn(['parentResource', 'assignment']),
    shiftDate,
    startTime,
    endTime,
  };
})
export default class BoilerItem extends Component {
  static propTypes = {
    boilerRoom: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    isShowingDetails: PropTypes.bool.isRequired,
    isAssigning: PropTypes.bool.isRequired,
    pendingAssignment: PropTypes.object,
    defaultAssignmentType: PropTypes.string.isRequired,
    shiftDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  };

  onShowResourceDetails() {
    const { dispatch, boilerRoom } = this.props;
    const { showParentResourceDetails } = actionCreators;

    dispatch(showParentResourceDetails({ boiler_room: boilerRoom }));
  }

  onOpenNewAssignmentEditor() {
    const {
      dispatch,
      boilerRoom,
      defaultAssignmentType,
      shiftDate,
      startTime,
      endTime,
    } = this.props;
    const { openNewAssignmentEditor } = actionCreators;

    dispatch(
      openNewAssignmentEditor(
        {
          boiler_room: boilerRoom,
        },
        defaultAssignmentType,
        shiftDate,
        startTime,
        endTime
      )
    );
  }

  onOpenExistingAssignmentEditor(assignment) {
    const { dispatch, boilerRoom } = this.props;
    const { openExistingAssignmentEditor } = actionCreators;

    dispatch(
      openExistingAssignmentEditor(
        {
          boiler_room: boilerRoom,
          assignment,
        },
        assignment.get('type')
      )
    );
  }

  getAssignments() {
    const { boilerRoom } = this.props;

    return boilerRoom
      .get('related')
      .filter((related) => related.has('assignment'))
      .map((related) => related.get('assignment'));
  }

  render() {
    const { boilerRoom, isShowingDetails, isAssigning, pendingAssignment } =
      this.props;

    return (
      <AssignmentRelatedObject
        label={boilerRoom.get('name')}
        assignments={this.getAssignments()}
        isShowingDetails={isShowingDetails}
        isAssigning={isAssigning}
        pendingAssignment={pendingAssignment}
        onShowResourceDetails={this.onShowResourceDetails.bind(this)}
        onOpenNewAssignmentEditor={this.onOpenNewAssignmentEditor.bind(this)}
        onOpenExistingAssignmentEditor={(assignment) =>
          this.onOpenExistingAssignmentEditor(assignment)
        }
      />
    );
  }
}
