import { Query } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';

/* Get a formatted URL for an issue link */
export function getIssueUrl(
  issuesIdList: string[],
  issue: string,
  index: number
) {
  const baseUrl = '/issues';
  return `${baseUrl}/${issue}?issues=${issuesIdList}&index=${index}`;
}

/* Given any query parameters, return some navigation links */
export function getIssueNavigation(query: Query = {}) {
  // If we received both a list of issue IDs and an index into that for the current
  // issue, we can determine navigation
  const links = [];

  if ('issues' in query && 'index' in query) {
    const issuesIdList = (query['issues'] as string).split(',');

    if (issuesIdList.length > 1) {
      const index = parseInt(query['index'] as string, 10);
      const nextIndex = index + 1;
      const prevIndex = index - 1;

      if (prevIndex >= 0) {
        // We know this exists because of index check
        const prevIssue = issuesIdList[prevIndex]!;
        const prevUrl = getIssueUrl(issuesIdList, prevIssue, prevIndex);
        links.push(
          <Link
            className="lbj-issue-prev c-page-back"
            to={prevUrl}
            key={prevIndex}
          >
            Previous Issue
          </Link>
        );
      }
      if (nextIndex < issuesIdList.length) {
        // We know this exists because of index check
        const nextIssue = issuesIdList[nextIndex]!;
        const nextUrl = getIssueUrl(issuesIdList, nextIssue, nextIndex);
        links.push(
          <Link
            className="lbj-issue-next c-page-next"
            to={nextUrl}
            key={nextIndex}
          >
            Next Issue
          </Link>
        );
      }
    }
  }
  return links;
}
