import cx from 'classnames';
import React, { Component } from 'react';

import inputProps from '../../../decorators/input-props';

@inputProps() /* eslint-disable react/prop-types */
export default class TextArea extends Component {
  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return <label htmlFor={name}>{title}</label>;
  }

  renderInput() {
    const {
      name,
      title,
      placeholder,
      type,
      required,
      disabled,
      value,
      defaultValue,
      onChange,
    } = this.props;

    return (
      <textarea
        id={name}
        name={name}
        title={title}
        placeholder={placeholder}
        type={type}
        required={required}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          e.persist();
          return onChange(e);
        }}
      />
    );
  }

  render() {
    const { name } = this.props;
    const className = cx(
      'lbj-input',
      'lbj-input-textarea',
      `lbj-input-${name}`
    );

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
      </div>
    );
  }
}
