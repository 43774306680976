import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToDefaultShiftTimes from '../../../utils/assignment/map-state-to-default-shift-times';
import Datetime from '../../presentational/form/datetime';

const TIME_FORMAT = 'h:mm a';

/**
 * This component is a quick rehash of assignment-shift-picker-container.jsx
 * and assignment-time-form.jsx -- combining some of their old logic with the
 * new functionality from the Assignments UX redesign.
 *
 * TODO: Fix some behaviors, particularly:
 *   -- If you type in invalid times and then click on a shift button, one of
 *      the two fields does not get updated.
 *   -- If you click on a shift button to change the fields and then click
 *      on the Edit (pencil) button for the same assignment that you're
 *      currently editing, the time fields do not get repopulated.
 *
 * TODO: Clean up unnecessary props or inconsistent state names.
 */
@connect((state, ownProps) => {
  let { defaultStartTime, defaultChangeTime, defaultEndTime } =
    mapStateToDefaultShiftTimes(state);

  // Slight hack to modify the start and end shift times to match those of the
  // location, rather than the election.
  if (ownProps.hours && ownProps.hours.get('closed') === false) {
    defaultStartTime = ownProps.hours.get('open_time');
    defaultEndTime = ownProps.hours.get('close_time');
  }

  return {
    defaultStartTime,
    defaultChangeTime,
    defaultEndTime,
  };
})
export default class ShiftPickerContainerV2 extends Component {
  static propTypes = {
    assignmentDate: PropTypes.string.isRequired,
    defaultStartTime: PropTypes.string.isRequired,
    defaultChangeTime: PropTypes.string.isRequired,
    defaultEndTime: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    onShiftChange: PropTypes.func.isRequired,
    /** MapFromJs<ApiLocationHours> | null | undefined */
    hours: PropTypes.object,
    textHours: PropTypes.element,
  };

  constructor(props) {
    super(props);
    const { startTime, endTime } = this.props;

    this.state = {
      startTime,
      endTime,
      errors: {
        start_time: null,
        end_time: null,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onMorningClick = this.onMorningClick.bind(this);
    this.onAfternoonClick = this.onAfternoonClick.bind(this);
    this.onAllDayClick = this.onAllDayClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { startTime, endTime } = this.props;
    if (prevProps.startTime !== startTime || prevProps.endTime !== endTime) {
      this.updateTimes(startTime, endTime);
    }
  }

  onChange({ target, error }) {
    const { name, value } = target;
    const { onShiftChange } = this.props;
    const timeData = {};

    if (error) {
      this.storeError(name, error);
      timeData[name] = null;
    } else {
      const offset = moment.parseZone(value).utcOffset();
      const updatedTime = moment.utc(value).add(offset, 'minutes');
      timeData[name] = updatedTime.format('HH:mm:ss');
      this.clearError(name);
    }
    onShiftChange(timeData);
  }

  onMorningClick() {
    const { defaultStartTime, defaultChangeTime } = this.props;
    this.updateTimes(defaultStartTime, defaultChangeTime);
  }

  onAfternoonClick() {
    const { defaultEndTime, defaultChangeTime } = this.props;
    this.updateTimes(defaultChangeTime, defaultEndTime);
  }

  onAllDayClick() {
    const { defaultStartTime, defaultEndTime } = this.props;
    this.updateTimes(defaultStartTime, defaultEndTime);
  }

  getDefaultTime(timeString) {
    const { assignmentDate } = this.props;
    return moment(`${assignmentDate}T${timeString}`).format(TIME_FORMAT);
  }

  updateTimes(startTime, endTime) {
    const { onShiftChange } = this.props;
    onShiftChange({
      start_time: startTime,
      end_time: endTime,
    });
    this.setState({
      startTime,
      endTime,
      errors: {
        start_time: null,
        end_time: null,
      },
    });
  }

  clearError(fieldName) {
    const { errors } = this.state;
    errors[fieldName] = null;
    this.setState({ errors });
  }

  storeError(fieldName, error) {
    const { errors } = this.state;
    errors[fieldName] = error;
    this.setState({ errors });
  }

  renderError(errorMsg) {
    return <span className="c-error-message">{errorMsg}</span>;
  }

  render() {
    const { textHours } = this.props;
    const { startTime, endTime, errors } = this.state;
    const endValue = this.getDefaultTime(endTime);
    const startValue = this.getDefaultTime(startTime);

    return (
      <div className="assignment-time-form-v2">
        <div className="default-shifts">
          <label>Shifts</label>
          <button className="link-button" onClick={this.onMorningClick}>
            Morning
          </button>
          |
          <button className="link-button" onClick={this.onAfternoonClick}>
            Afternoon
          </button>
          |
          <button className="link-button" onClick={this.onAllDayClick}>
            All Day
          </button>
        </div>

        <div className="time-fields">
          <label>Time</label>
          <div className="field">
            <Datetime
              name="start_time"
              type="time"
              timeFormat={TIME_FORMAT}
              onChange={this.onChange}
              value={startValue}
              inputProps={{ placeholder: startValue }}
            />
            {errors.start_time && this.renderError(errors.start_time)}
          </div>
          <div>to</div>
          <div className="field">
            <Datetime
              name="end_time"
              type="time"
              timeFormat={TIME_FORMAT}
              onChange={this.onChange}
              value={endValue}
              inputProps={{ placeholder: endValue }}
            />
            {errors.end_time && this.renderError(errors.end_time)}
          </div>
        </div>

        {textHours && (
          <div className="time-fields time-field-note">
            <label>Hours</label>

            <div>{textHours}</div>
          </div>
        )}
      </div>
    );
  }
}
