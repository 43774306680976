import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import RIT from '../../../utils/render-if-truthy';
import EditorSearchResults from '../../presentational/assignment/assignment-editor-search-results';
import PollingSearchForm from '../../presentational/assignment/assignment-polling-search-form';

@connect((state) => {
  const { lbj } = state;
  const precinctResults = lbj.get('precincts');

  return {
    isLoadingPrecincts: precinctResults.get('requestIsPending'),
    precinctList: precinctResults.get('listData'),
  };
})
export default class FindPrecinctForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoadingPrecincts: PropTypes.bool.isRequired,
    precinctList: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    precinct: PropTypes.object,
    onEditAssignmentData: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.onSearchForPrecincts();
  }

  componentDidUpdate(prevProps) {
    const previousLocationId = prevProps.location.get('id');
    const currentLocationId = this.props.location.get('id');

    this.onLocationChangeUpdatePrecincts(previousLocationId, currentLocationId);
  }

  onLocationChangeUpdatePrecincts(previousLocationId, currentLocationId) {
    if (previousLocationId !== currentLocationId) {
      this.onSearchForPrecincts();
    }
  }

  onGetMorePrecinctResults({ offset }) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastSearch, { offset, size: 25 });

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
  }

  onSearchForPrecincts(additionalParams = {}) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const filterPayload = _.assign(
      this.getDefaultSearchFilters(),
      additionalParams
    );

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
    this.lastSearch = filterPayload;
  }

  getDefaultSearchFilters() {
    const { location } = this.props;

    return {
      location: location.get('id'),
      size: 25,
    };
  }

  getPrecinctLabel(precinct) {
    return `${precinct.get('name')} - ${precinct.get('tier') || '(no tier)'}`;
  }

  renderResult(precinct, key) {
    const { onEditAssignmentData } = this.props;

    return (
      <li
        onClick={() => {
          onEditAssignmentData({ precinct });
        }}
        key={key}
      >
        {this.getPrecinctLabel(precinct)}
      </li>
    );
  }

  renderPrecinctResults() {
    const { precinctList, isLoadingPrecincts } = this.props;

    return (
      <EditorSearchResults
        listData={precinctList}
        isLoadingList={isLoadingPrecincts}
        onGetMoreSearchResults={this.onGetMorePrecinctResults.bind(this)}
        listElementRenderer={(precinct, key) =>
          this.renderResult(precinct, key)
        }
      />
    );
  }

  renderPrecinctSearch() {
    return (
      <PollingSearchForm
        hiddenInputTitle={'Search for a precinct'}
        title="Precincts"
        showCounty={false}
        showTier
        onChange={this.onSearchForPrecincts.bind(this)}
      />
    );
  }

  render() {
    const { precinct } = this.props;

    return (
      <div className="assignment-find-precinct-form">
        {RIT(!precinct, this.renderPrecinctSearch.bind(this))}
        {RIT(!precinct, this.renderPrecinctResults.bind(this))}
      </div>
    );
  }
}
