import { lbjAppSections } from '../../constants';

const { USER } = lbjAppSections;

function getNotificationToolbarText() {
  return 'Email Users';
}

export default function (state) {
  const { email, filters } = state;
  const userListViewType = filters.getIn([USER, 'view']);

  return {
    emailQueue: email.get('queue'),
    isEmailingUsers: email.getIn(['emailRequest', 'requestIsPending']),
    erredEmailingUsers: email.getIn(['emailRequest', 'requestErred']),
    emailReceipt: email.get('emailReceipt'),
    toolbarText: getNotificationToolbarText(userListViewType),
  };
}
