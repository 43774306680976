import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppStore } from '../../modules/flux-store';
import { loadCurrentUser } from '../../route-handlers/app';

import { getUserAssignments } from '../../services/assignment-service';
import { ApiUser } from '../../services/user-service';
import { UserMappedAssignment } from '../../utils/assignment/map-user-to-assignments';
import { MapFromJs } from '../../utils/types';

import VolunteerLandingView from './volunteer-landing-view';

const VolunteerLandingContainer: React.FunctionComponent<{
  dispatch?: Dispatch<any> | undefined;
  assignments: Array<UserMappedAssignment>;
  user: MapFromJs<ApiUser>;
}> = (props) => {
  const { assignments, user } = props;

  return <VolunteerLandingView assignments={assignments} user={user} />;
};

export default connect(() => {
  return {};
})(VolunteerLandingContainer);

/**
 * Loads the data necessary for {@link VolunteerLandingContainer}.
 *
 * This includes a list of elections belonging to the current user.
 */
export async function loadHomePage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof VolunteerLandingContainer>> {
  const { currentUser } = await loadCurrentUser(fluxStore);
  const currentUserId = currentUser.get('id');
  const { assignments } = await getUserAssignments(currentUserId);

  return {
    assignments: assignments,
    user: currentUser as MapFromJs<ApiUser>, // casts ApiCurrentUser to ApiUser
  };
}
