export default [
  '#00aef3',
  '#00589c',
  '#e31818',
  '#ffd300',
  '#00a87d',
  '#a4288c',
  '#0f2446',
  '#00aef3',
  '#841618',
  '#f15822',
  '#29763b',
  '#4d2073',
];
