import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  issuePriorities,
  issueScopes,
  issueStatuses,
} from '../../../constants';

export default class AnalyticsIssueTables extends Component {
  static propTypes = {
    column: PropTypes.string.isRequired,
    issueData: PropTypes.object.isRequired,
  };

  getColumnMap() {
    const { column } = this.props;

    switch (column) {
      case 'priority':
        return Object.assign(issuePriorities, { not_set: 'No priority' });
      case 'scope':
        return Object.assign(issueScopes, { not_set: 'No scope' });
      case 'status':
      default:
        return issueStatuses;
    }
  }

  renderColumnHeader(header) {
    return <th>{header.replace(' affected', '')}</th>;
  }

  renderRegionCell(columnKey, region) {
    return <td>{region.get(columnKey, 0)}</td>;
  }

  renderRegionRow(region, key) {
    const columnMap = this.getColumnMap();

    return (
      <tr key={key}>
        <td>{region.get('Name')}</td>
        {Object.keys(columnMap).map((columnKey) =>
          this.renderRegionCell(columnKey, region)
        )}
        <td>{region.get('Total')}</td>
      </tr>
    );
  }

  render() {
    const { issueData } = this.props;
    const columnMap = this.getColumnMap();

    return (
      <table className="lbj-table">
        <thead>
          <tr>
            <th>Region</th>
            {Object.values(columnMap).map((header) =>
              this.renderColumnHeader(header)
            )}
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {issueData.map((region, key) => this.renderRegionRow(region, key))}
        </tbody>
      </table>
    );
  }
}
