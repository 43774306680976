import _ from 'lodash';

import { requestStatuses, State, UserRole } from '../../constants';
import { PaginatedResponse } from '../../services/common';
import * as IssueService from '../../services/issue-service';
import * as RavenService from '../../services/sentry-service';
import { AppThunk } from '../flux-store';
import { showToast, dismissToast } from '../toast';

import actionTypes from './action-types';

const {
  GET_ISSUE,
  CREATE_ISSUE,
  ESCALATE_ISSUE,
  UPDATE_ISSUE,
  GET_ISSUE_COMMENTS,
  CREATE_ISSUE_COMMENT,
  GET_ISSUE_HISTORY,
  RESET_ISSUE_IN_PROGRESS,
  GET_ISSUE_DOCUMENTS,
  UPLOAD_ISSUE_DOCUMENT,
  GET_ISSUE_PHOTOS,
  UPLOAD_ISSUE_PHOTO,
  GET_ISSUE_ELECTION,
  SET_ISSUE_ELECTION,
} = actionTypes;

const { PENDING, SUCCESS, ERROR } = requestStatuses;

export type Action =
  | GetIssueAction
  | GetIssueCommentsAction
  | CreateIssueCommentAction
  | CreateIssueAction
  | EscalateIssueAction
  | UpdateIssueAction
  | GetIssueHistoryAction
  | ResetIssueInProgressAction
  | GetIssueDocumentsAction
  | UploadIssueDocumentAction
  | GetIssuePhotosAction
  | UploadIssuePhotoAction
  | GetIssueElectionAction
  | SetIssueElectionAction;

export type GetIssueAction = {
  type: typeof GET_ISSUE;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        issueResponse: IssueService.DetailedApiIssue;
      }
    | { status: typeof ERROR };
};

function requestIssue(): GetIssueAction {
  return {
    type: GET_ISSUE,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssue(ticket: IssueService.DetailedApiIssue): GetIssueAction {
  return {
    type: GET_ISSUE,
    data: {
      status: SUCCESS,
      issueResponse: ticket,
    },
  };
}

function errorReceivingIssue(): GetIssueAction {
  return {
    type: GET_ISSUE,
    data: {
      status: ERROR,
    },
  };
}

export function getIssueAsync(issueId: number): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestIssue());

    return IssueService.getIssue(issueId).then(
      (ticket) => {
        // const issueResponse = mapResponseToIssue(ticket);
        return dispatch(receiveIssue(ticket));
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'issue',
          method: 'getIssueAsync',
        });
        return dispatch(errorReceivingIssue());
      }
    );
  };
}

export type IssueCommentsResponse = Awaited<
  ReturnType<typeof IssueService.getIssueComments>
>;

export type GetIssueCommentsAction = {
  type: typeof GET_ISSUE_COMMENTS;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        issueCommentsResponse: IssueCommentsResponse;
      }
    | { status: typeof ERROR };
};

function requestIssueComments(): GetIssueCommentsAction {
  return {
    type: GET_ISSUE_COMMENTS,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssueComments(
  issueCommentsResponse: IssueCommentsResponse
): GetIssueCommentsAction {
  return {
    type: GET_ISSUE_COMMENTS,
    data: {
      status: SUCCESS,
      issueCommentsResponse,
    },
  };
}

function errorReceivingIssueComments(): GetIssueCommentsAction {
  return {
    type: GET_ISSUE_COMMENTS,
    data: {
      status: ERROR,
    },
  };
}

export function getIssueCommentsAsync(
  issueId: number | string
): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestIssueComments());

    return IssueService.getIssueComments({
      ticket: issueId,
    }).then(
      (comments) => {
        return dispatch(receiveIssueComments(comments));
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'issue',
          method: 'getIssueCommentsAsync',
        });
        return dispatch(errorReceivingIssueComments());
      }
    );
  };
}

export type CreateIssueCommentAction = {
  type: typeof CREATE_ISSUE_COMMENT;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        /**
         * This is the comment as it was sent, not as it was returned from the
         * server.
         */
        commentData: IssueService.ApiNewIssueComment;
      }
    | { status: typeof ERROR };
};

function requestCreateIssueComment(): CreateIssueCommentAction {
  return {
    type: CREATE_ISSUE_COMMENT,
    data: {
      status: PENDING,
    },
  };
}

function successfullyCreateIssueComment(
  commentData: IssueService.ApiNewIssueComment
): CreateIssueCommentAction {
  return {
    type: CREATE_ISSUE_COMMENT,
    data: {
      status: SUCCESS,
      commentData,
    },
  };
}

function errorCreatingIssueComment(): CreateIssueCommentAction {
  return {
    type: CREATE_ISSUE_COMMENT,
    data: {
      status: ERROR,
    },
  };
}

export function createIssueCommentAsync(
  commentData: IssueService.ApiNewIssueComment
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestCreateIssueComment());

    return IssueService.createIssueComment(commentData).then(
      () => dispatch(successfullyCreateIssueComment(commentData)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'createIssueCommentAsync',
        });
        // error isn’t read in the UI code
        return dispatch(errorCreatingIssueComment());
      }
    );
  };
}

export type IssueError =
  | string
  | ({ error_message?: string } & {
      [field in keyof IssueService.ApiIssue]?: string[];
    });

export type IssueCrudData =
  | { status: typeof PENDING }
  | {
      status: typeof SUCCESS;
      /**
       * Not read by the reducer, but used by callers.
       *
       * See `handleIssueCRUD` functions.
       */
      issueResponse: {
        id: number | string;
        location?: number | null;
      };
    }
  | {
      status: typeof ERROR;
      errorData: IssueError;
    };

export type CreateIssueAction = {
  type: typeof CREATE_ISSUE;
  data: IssueCrudData;
};

function requestCreateIssue(): CreateIssueAction {
  return {
    type: CREATE_ISSUE,
    data: {
      status: PENDING,
    },
  };
}

function successfullyCreateIssue(
  issueResponse: IssueService.ApiIssue
): CreateIssueAction {
  return {
    type: CREATE_ISSUE,
    data: {
      status: SUCCESS,
      issueResponse,
    },
  };
}

function errorCreatingIssue(errorData: IssueError): CreateIssueAction {
  return {
    type: CREATE_ISSUE,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

/**
 * @returns A `Promise` for the success or error action that resulted from the
 * API call. See `handleIssueCRUD` calls, which do read this value.
 */
export function createIssueAsync(
  issueData: IssueService.ApiNewIssue
): AppThunk<Promise<CreateIssueAction>> {
  return (dispatch) => {
    dispatch(requestCreateIssue());

    return IssueService.createIssue(issueData).then(
      (issue) => dispatch(successfullyCreateIssue(issue)),
      (error) => {
        let ravenError = error;
        if (error) {
          try {
            ravenError = JSON.stringify(error);
            if (
              ravenError.indexOf('Incidents need a county') >= 0 ||
              ravenError.indexOf('This field is required.') >= 0
            ) {
              ravenError = 'New Issue Validation Error';
            }
          } catch (err) {
            // Oh well.
          }
        }
        RavenService.captureException(ravenError, {
          type: 'issue',
          method: 'createIssueAsync',
        });
        return dispatch(errorCreatingIssue(error));
      }
    );
  };
}

export type EscalateIssueAction = {
  type: typeof ESCALATE_ISSUE;
  data: IssueCrudData;
};

function requestEscalateIssue(): EscalateIssueAction {
  return {
    type: ESCALATE_ISSUE,
    data: {
      status: PENDING,
    },
  };
}

function successfullyEscalateIssue(issueResponse: IssueService.ApiIssue) {
  return {
    type: ESCALATE_ISSUE,
    data: {
      status: SUCCESS,
      issueResponse,
    },
  };
}

function errorEscalatingIssue(errorData: IssueError) {
  return {
    type: ESCALATE_ISSUE,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

export function escalateIssueAsync(
  id: number
): AppThunk<Promise<EscalateIssueAction>> {
  return (dispatch) => {
    dispatch(requestEscalateIssue());

    return IssueService.escalateIssue(id).then(
      (success) => dispatch(successfullyEscalateIssue(success)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'escalateIssueAsync',
        });
        return dispatch(errorEscalatingIssue(error));
      }
    );
  };
}

export type UpdateIssueAction = {
  type: typeof UPDATE_ISSUE;
  data: IssueCrudData;
};

function requestUpdateIssue(): UpdateIssueAction {
  return {
    type: UPDATE_ISSUE,
    data: {
      status: PENDING,
    },
  };
}

function successfullyUpdateIssue(issueResponse: {
  id: number | string;
}): UpdateIssueAction {
  return {
    type: UPDATE_ISSUE,
    data: {
      status: SUCCESS,
      issueResponse,
    },
  };
}

function errorUpdatingIssue(errorData: IssueError): UpdateIssueAction {
  return {
    type: UPDATE_ISSUE,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

export function updateIssueAsync(
  issueData: Partial<
    Omit<IssueService.ApiIssue, 'id'> & {
      comments?: IssueService.ApiIssueComment[];
    }
  > & { id: string } & IssueService.ApiIssueActionUpdates
): AppThunk<Promise<UpdateIssueAction>> {
  const { id } = issueData;

  return (dispatch) => {
    dispatch(requestUpdateIssue());

    delete issueData.comments;

    return IssueService.updateIssue(id, issueData).then(
      () => dispatch(successfullyUpdateIssue(issueData)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'updateIssueAsync',
        });
        return dispatch(errorUpdatingIssue(error));
      }
    );
  };
}

export function updateViewOnlySubscribeIssueAsync(
  issueData: { id: string } & IssueService.ApiIssueActionUpdates
): AppThunk<Promise<UpdateIssueAction>> {
  const { id } = issueData;

  return (dispatch) => {
    dispatch(requestUpdateIssue());

    return IssueService.updateViewOnlySubscribeIssue(id, issueData).then(
      () => dispatch(successfullyUpdateIssue(issueData)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'updateViewOnlySubscribeIssueAsync',
        });
        return dispatch(errorUpdatingIssue(error));
      }
    );
  };
}

export type GetIssueHistoryAction = {
  type: typeof GET_ISSUE_HISTORY;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        issueHistory: IssueService.ApiIssueHistory[];
      }
    | { status: typeof ERROR };
};

function requestIssueHistory(): GetIssueHistoryAction {
  return {
    type: GET_ISSUE_HISTORY,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssueHistory(
  issueHistory: IssueService.ApiIssueHistory[]
): GetIssueHistoryAction {
  return {
    type: GET_ISSUE_HISTORY,
    data: {
      status: SUCCESS,
      issueHistory,
    },
  };
}

function errorReceivingIssueHistory(): GetIssueHistoryAction {
  return {
    type: GET_ISSUE_HISTORY,
    data: {
      status: ERROR,
    },
  };
}

export function getIssueHistoryAsync(
  userRole: UserRole,
  issueId: number
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestIssueHistory());

    return IssueService.getIssueHistory(userRole, issueId).then(
      ({ results }) => {
        return dispatch(receiveIssueHistory(results));
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'issue',
          method: 'getIssueHistoryAsync',
        });
        return dispatch(errorReceivingIssueHistory());
      }
    );
  };
}

export type ResetIssueInProgressAction = {
  type: typeof RESET_ISSUE_IN_PROGRESS;
};

export function resetIssueInProgressData(): ResetIssueInProgressAction {
  return {
    type: RESET_ISSUE_IN_PROGRESS,
  };
}

export type GetIssueDocumentsAction = {
  type: typeof GET_ISSUE_DOCUMENTS;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        issueDocumentsResponse: PaginatedResponse<IssueService.ApiIssueDocument>;
      }
    | { status: typeof ERROR };
};

function requestIssueDocuments(): GetIssueDocumentsAction {
  return {
    type: GET_ISSUE_DOCUMENTS,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssueDocuments(
  issueDocumentsResponse: PaginatedResponse<IssueService.ApiIssueDocument>
): GetIssueDocumentsAction {
  return {
    type: GET_ISSUE_DOCUMENTS,
    data: {
      status: SUCCESS,
      issueDocumentsResponse,
    },
  };
}

function errorReceivingIssueDocuments(): GetIssueDocumentsAction {
  return {
    type: GET_ISSUE_DOCUMENTS,
    data: {
      status: ERROR,
    },
  };
}

export function getIssueDocumentsAsync(
  issueId: number
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestIssueDocuments());

    return IssueService.getIssueDocuments({
      ticket: issueId,
    }).then(
      (response) => dispatch(receiveIssueDocuments(response)),
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'issue',
          method: 'getIssueDocumentsAsync',
        });
        return dispatch(errorReceivingIssueDocuments());
      }
    );
  };
}

export type UploadIssueDocumentAction = {
  type: typeof UPLOAD_ISSUE_DOCUMENT;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS }
    | {
        status: typeof ERROR;
        errorData: Object;
      };
};

function requestUploadIssueDocument(): UploadIssueDocumentAction {
  return {
    type: UPLOAD_ISSUE_DOCUMENT,
    data: {
      status: PENDING,
    },
  };
}

function successfullyUploadIssueDocument(
  documentData: IssueService.ApiIssueDocument
): AppThunk<void> {
  return (dispatch) => {
    const { ticket } = documentData;
    dispatch(getIssueDocumentsAsync(ticket));
    dispatch({
      type: UPLOAD_ISSUE_DOCUMENT,
      data: {
        status: SUCCESS,
      },
    });
  };
}

function errorUploadingIssueDocument(
  errorData: Object
): UploadIssueDocumentAction {
  return {
    type: UPLOAD_ISSUE_DOCUMENT,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

export function uploadIssueDocumentAsync(documentData: {
  ticket: string;
  document: File;
}): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestUploadIssueDocument());

    return IssueService.uploadIssueDocument(documentData).then(
      (response) => dispatch(successfullyUploadIssueDocument(response)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'uploadIssueDocumentAsync',
        });
        return errorUploadingIssueDocument(error);
      }
    );
  };
}

/**
 * Deletes the given document, then reloads the documents for the document’s
 * ticket.
 *
 * Does not have any delete-specific actions since no reducer is interested.
 */
export function deleteIssueDocumentAsync(
  documentData: IssueService.ApiIssueDocument
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    return IssueService.deleteIssueDocument(documentData).then(
      () =>
        // Refresh documents now that one is deleted
        dispatch(getIssueDocumentsAsync(documentData.ticket)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'deleteIssueDocumentAsync',
        });
      }
    );
  };
}

export type GetIssuePhotosAction = {
  type: typeof GET_ISSUE_PHOTOS;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        issuePhotosResponse: PaginatedResponse<IssueService.ApiIssuePhoto>;
      }
    | { status: typeof ERROR };
};

function requestIssuePhotos(): GetIssuePhotosAction {
  return {
    type: GET_ISSUE_PHOTOS,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssuePhotos(
  issuePhotosResponse: PaginatedResponse<IssueService.ApiIssuePhoto>
): GetIssuePhotosAction {
  return {
    type: GET_ISSUE_PHOTOS,
    data: {
      status: SUCCESS,
      issuePhotosResponse,
    },
  };
}

function errorReceivingIssuePhotos(): GetIssuePhotosAction {
  return {
    type: GET_ISSUE_PHOTOS,
    data: {
      status: ERROR,
    },
  };
}

export function getIssuePhotosAsync(
  issueId: number
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestIssuePhotos());

    return IssueService.getIssuePhotos({
      ticket: issueId,
    }).then(
      (response) => dispatch(receiveIssuePhotos(response)),
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'issue',
          method: 'getIssuePhotosAsync',
        });
        return dispatch(errorReceivingIssuePhotos());
      }
    );
  };
}

export type UploadIssuePhotoAction = {
  type: typeof UPLOAD_ISSUE_PHOTO;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS }
    | {
        status: typeof ERROR;
        errorData: Object;
      };
};

function requestUploadIssuePhoto(): UploadIssuePhotoAction {
  return {
    type: UPLOAD_ISSUE_PHOTO,
    data: {
      status: PENDING,
    },
  };
}

function successfullyUploadIssuePhoto(
  photoData: IssueService.ApiIssuePhoto
): AppThunk<void> {
  return (dispatch) => {
    const { ticket } = photoData;
    dispatch(getIssuePhotosAsync(ticket));
    dispatch({
      type: UPLOAD_ISSUE_PHOTO,
      data: {
        status: SUCCESS,
      },
    });
  };
}

function errorUploadingIssuePhoto(errorData: Object): UploadIssuePhotoAction {
  return {
    type: UPLOAD_ISSUE_PHOTO,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

export function uploadIssuePhotoAsync(photoData: {
  ticket: string;
  photo: Blob;
}): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestUploadIssuePhoto());

    return IssueService.uploadIssuePhoto(photoData).then(
      (response) => dispatch(successfullyUploadIssuePhoto(response)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'uploadIssuePhotoAsync',
        });
        return errorUploadingIssuePhoto(error);
      }
    );
  };
}

/**
 * Deletes the given photo, then reloads the issue’s photos.
 *
 * Does not generate any deletion-specific actions because there are no reducers
 * for them.
 */
export function deleteIssuePhotoAsync(
  photoData: IssueService.ApiIssuePhoto
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    return IssueService.deleteIssuePhoto(photoData).then(
      () =>
        // Refresh photos now that one is deleted
        dispatch(getIssuePhotosAsync(photoData.ticket)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'deleteIssuePhotoAsync',
        });
      }
    );
  };
}

export type GetIssueElectionAction = {
  type: typeof GET_ISSUE_ELECTION;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        electionId: number;
      }
    | {
        status: typeof ERROR;
        errorData: Object;
      };
};

function requestGetIssueElection(): GetIssueElectionAction {
  return {
    type: GET_ISSUE_ELECTION,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssueElection(electionId: number): GetIssueElectionAction {
  return {
    type: GET_ISSUE_ELECTION,
    data: {
      status: SUCCESS,
      electionId,
    },
  };
}

function errorGettingIssueElection(errorData: Object): GetIssueElectionAction {
  return {
    type: GET_ISSUE_ELECTION,
    data: {
      status: ERROR,
      errorData,
    },
  };
}

export function getIssueElectionAsync(
  state: State | '' = ''
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestGetIssueElection());

    return IssueService.getIssueElection(state).then(
      (response) => dispatch(receiveIssueElection(response.election_id)),
      (error) => {
        RavenService.captureException(error, {
          type: 'issue',
          method: 'getIssueElectionAsync',
        });
        return errorGettingIssueElection(error);
      }
    );
  };
}

export type SetIssueElectionAction = {
  type: typeof SET_ISSUE_ELECTION;
  data: {
    electionId: number;
  };
};

export function setIssueElection(electionId: number): SetIssueElectionAction {
  return {
    type: SET_ISSUE_ELECTION,
    data: { electionId },
  };
}

export const internalActionCreators = {
  requestIssue,
  receiveIssue,
  errorReceivingIssue,
  requestIssueComments,
  receiveIssueComments,
  errorReceivingIssueComments,
  requestCreateIssueComment,
  successfullyCreateIssueComment,
  errorCreatingIssueComment,
  requestCreateIssue,
  successfullyCreateIssue,
  errorCreatingIssue,
  requestUpdateIssue,
  successfullyUpdateIssue,
  errorUpdatingIssue,
};

export { showToast, dismissToast };
