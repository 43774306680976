/* eslint-disable react/no-string-refs */

import * as Immutable from 'immutable';
import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';

import { PageHeader } from '../../components/common';

import UserDetailSidebar from '../../components/presentational/user/user-detail-sidebar';
import UserDisableModal from '../../components/presentational/user/user-disable-modal';
import { State } from '../../constants';
import { AppState, toAppDispatch } from '../../modules/flux-store';
import { CountyListRecord } from '../../modules/lbj/reducers';
import { ToastData, ToastRecord } from '../../modules/toast';
import { actionCreators as userActionCreators } from '../../modules/user';
import { DetailedUser } from '../../modules/user/action-creators';
import { ApiUserTag } from '../../services/lbj-shared-service';
import { ApiUserElection } from '../../services/user-service';
import RIT from '../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingProps,
} from '../../utils/routing-provider';
import { MapFromJs } from '../../utils/types';
import mapStateToLbjPermissions from '../../utils/user/map-state-to-lbj-permissions';

import UserDetailForm from './UserDetailForm';
import type { UserUpdates } from './UserForm';
import UserDetailAssignmentContainer from './user-detail-assignment-container';

const USER_EXPANDERS = 'related,pii' as const;

class UnconnectedUserDetailContainer extends Component<
  {
    dispatch?: Dispatch<any> | undefined;
    currentUser: MapFromJs<DetailedUser>;
    currentElection: Immutable.Map<keyof ApiUserElection, any> | null;
    isEditingSelf: boolean;
    userHasAdminPermissions: boolean;
    counties: Immutable.Map<State, CountyListRecord>;
    filters: Immutable.Map<string, string | null | undefined>;
    user: MapFromJs<DetailedUser> | null;
    tagData: Immutable.List<MapFromJs<ApiUserTag>>;
    toastData: ToastRecord;
    userIsLoading: boolean;
    headerText: string;
    disableCancel: boolean;
  } & WithRoutingProps,
  {
    formIsDirty: boolean;
    showDeleteModal: boolean;
    /**
     * The user values sent to the form. Saved in state because updating it
     * resets the form.
     */
    formUser: DetailedUser | null;
  }
> {
  constructor(props: UnconnectedUserDetailContainer['props']) {
    // TODO: better type specification
    super(props);

    this.state = {
      formIsDirty: false,
      showDeleteModal: false,
      formUser: (!props.userIsLoading && (props.user?.toJS() as any)) || null,
    };
  }

  componentDidUpdate() {
    // If we haven’t filled in formUser in our state, either because the user
    // hasn’t loaded yet or we reset it after a successful save, populate it now
    // if the user has loaded and exists.
    if (!this.props.userIsLoading && this.props.user && !this.state.formUser) {
      this.setState({ formUser: this.props.user.toJS() as any });
    }
  }

  onUpdateUser(userUpdates: UserUpdates) {
    const { user, filters, currentUser } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { updateUserAsync } = userActionCreators;
    const filterDates = filters.get('dates');
    const queryParams = {
      expand: USER_EXPANDERS,
      ...(filterDates && { dates: filterDates }),
    };

    if (!user) {
      return;
    }

    dispatch(
      updateUserAsync(
        user.get('id'),
        userUpdates,
        currentUser.get('id'),
        queryParams
      )
    ).then(this.handleToast.bind(this));
  }

  onCancelChanges() {
    const { navigate } = this.props;

    navigate('/invite');
  }

  onDateFilterChange({ target }: any) {
    // TODO: better type specification
    const { value } = target;
    const { navigate, location } = this.props;
    const { pathname, search } = location;
    const query = searchToQuery(search);

    navigate({
      pathname,
      search: queryToSearch(_.assign({}, query, { dates: value })),
    });
  }

  disableUser() {
    const { user, currentUser } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { updateUserAsync } = userActionCreators;

    if (!user) {
      return;
    }

    dispatch(
      updateUserAsync(
        user.get('id'),
        { enabled: false },
        currentUser.get('id'),
        { expand: USER_EXPANDERS }
      )
    );
    this.hideDeleteModal();
  }

  enableUser() {
    const { user, currentUser } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { updateUserAsync } = userActionCreators;

    if (!user) {
      return;
    }

    dispatch(
      updateUserAsync(
        user.get('id'),
        { enabled: true },
        currentUser.get('id'),
        { expand: USER_EXPANDERS }
      )
    );
  }

  /**
   * TODO(fiona): This should be called `onUpdateUserComplete` or something
   * along those lines.
   */
  handleToast(state: AppState) {
    const { user } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { showToast } = userActionCreators;
    const updatingUser = state.user.get('updatingUser');
    const requestErred = updatingUser.get('requestErred');

    if (!user) {
      return;
    }

    const toastData: ToastData = {
      type: 'success',
      message: `You successfully updated ${user.get('first_name')}'s profile.`,
    };

    if (requestErred) {
      toastData.type = 'error';
      const error = updatingUser.get('error').toJS() as any;

      if (error.detail) {
        toastData.message = `Update failed: ${error.detail}.`;
      } else if (error.info) {
        // error.info holds ValidationError messages from lbj-backend
        toastData.message = `Update failed: ${error.info}.`;
      } else {
        toastData.message = `There was an error updating ${user.get(
          'first_name'
        )}'s profile.`;
      }

      if (error.existing_user_id) {
        toastData.message += ' Click to view.';
        toastData.url = `/users/${error.existing_user_id}/?view=details`;
      }
    } else {
      this.setState({
        formIsDirty: false,
        // This is very slightly jank… we want to reset the form to have 100%
        // fresh values from the server. But, because of how the updates flow
        // through the Redux store after saving, it’s not super obvious how to
        // get them in a way that works for both user detail and profile.
        //
        // So, we just set to null, knowing that our `onComponentUpdated` will
        // set this back to the value from props if it’s available and loaded.
        //
        // In testing, it seems we can assume that the updated user is in the
        // Redux store at that point and has been passed in to our props.
        formUser: null,
      });
    }

    dispatch(showToast(toastData));
  }

  showDeleteModal() {
    this.setState({
      showDeleteModal: true,
    });
  }

  hideDeleteModal() {
    this.setState({
      showDeleteModal: false,
    });
  }

  renderDisableModal() {
    return (
      <UserDisableModal
        user={this.props.user}
        onSubmit={this.disableUser.bind(this)}
        onCancel={this.hideDeleteModal.bind(this)}
      />
    );
  }

  renderHeader() {
    const { location, headerText, currentUser } = this.props;
    const { pathname } = location;
    const userRole = currentUser.get('role');
    const { view } = searchToQuery(location.search) ?? 'details';

    return (
      <PageHeader title={headerText}>
        <ul className="lbj-view-filters">
          <li>
            <NavLink
              to={{
                pathname,
                search: queryToSearch({ view: 'details' }),
              }}
              className={view === 'details' ? 'is-active' : ''}
            >
              User Details
            </NavLink>
          </li>
          {userRole !== 'view_only' && (
            <li>
              <NavLink
                to={{
                  pathname,
                  search: queryToSearch({ view: 'assignments' }),
                }}
                className={view === 'assignments' ? 'is-active' : ''}
              >
                Assignments
              </NavLink>
            </li>
          )}
        </ul>
      </PageHeader>
    );
  }

  renderMainContent(user: MapFromJs<DetailedUser>, formUser: DetailedUser) {
    const {
      location,
      currentUser,
      currentElection,
      tagData,
      filters,
      userHasAdminPermissions,
      isEditingSelf,
      toastData,
    } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const { view } = searchToQuery(location.search);

    switch (view) {
      case 'assignments':
        return (
          <UserDetailAssignmentContainer
            filters={filters}
            currentUserId={currentUser.get('id')}
            user={user}
            currentElection={currentElection}
          />
        );

      case 'details':
      default:
        return (
          <UserDetailForm
            id="profile-form"
            user={formUser}
            tagData={tagData.toJS() as ApiUserTag[]}
            isEditingSelf={isEditingSelf}
            hasAdminPermissions={userHasAdminPermissions}
            onSubmit={this.onUpdateUser.bind(this)}
            onRestoreUser={this.enableUser.bind(this)}
            setFormIsDirty={(formIsDirty) => this.setState({ formIsDirty })}
            toastData={toastData}
            dismissToast={() => dispatch(userActionCreators.dismissToast())}
          />
        );
    }
  }

  render() {
    const {
      user,
      userIsLoading,
      filters,
      location,
      disableCancel,
      userHasAdminPermissions,
      isEditingSelf,
    } = this.props;
    const { formIsDirty, showDeleteModal } = this.state;

    return (
      <div className="lbj-page-wrapper overflow-y-clip">
        {RIT(showDeleteModal, this.renderDisableModal.bind(this))}
        {RIT(user, this.renderHeader.bind(this))}

        <div className="flex flex-1 flex-col items-stretch overflow-y-clip lg:flex-row">
          <UserDetailSidebar
            filters={filters}
            view={searchToQuery(location.search)['view'] || 'details'}
            onCancelChanges={this.onCancelChanges.bind(this)}
            disableSave={!formIsDirty}
            onDateFilterChange={this.onDateFilterChange.bind(this)}
            disableCancel={disableCancel}
            isEditingSelf={isEditingSelf}
            userHasAdminPermissions={userHasAdminPermissions}
            userIsDisabled={!user?.get('enabled')}
            showDeleteModal={this.showDeleteModal.bind(this)}
          />

          <div className="flex flex-1 flex-col overflow-auto">
            {!userIsLoading &&
              user &&
              this.state.formUser &&
              this.renderMainContent(user, this.state.formUser)}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (
    state: AppState,
    ownProps: Pick<UnconnectedUserDetailContainer['props'], 'user'>
  ): Pick<
    UnconnectedUserDetailContainer['props'],
    | 'currentUser'
    | 'currentElection'
    | 'isEditingSelf'
    | 'userHasAdminPermissions'
    | 'counties'
    | 'tagData'
    | 'toastData'
  > => {
    const { user, lbj } = state;
    const currentUser = user.currentUser.userData as MapFromJs<DetailedUser>;
    const currentElection = user.getIn([
      'currentUser',
      'currentUserElection',
      'election',
    ]) as Immutable.Map<keyof ApiUserElection, any> | null;
    const { hasAdminPermissions } = mapStateToLbjPermissions(state);
    const isEditingSelf = ownProps['user']
      ? parseInt(currentUser?.get('id'), 10) ===
        parseInt(ownProps['user'].get('id'), 10)
      : false;

    return {
      currentUser,
      currentElection,
      isEditingSelf,
      userHasAdminPermissions: hasAdminPermissions,
      counties: lbj.counties,
      tagData: lbj.tags.listData,
      toastData: user.toastData,
    };
  }
)(withRouting(UnconnectedUserDetailContainer));
