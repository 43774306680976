import cx from 'classnames';
import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import {
  convertMetersToMiles,
  formatDistanceFromMiles,
} from '../../../utils/assignment/distance';
import { hoursAsText } from '../../../utils/assignment/location-hours';
import RIT from '../../../utils/render-if-truthy';
import EditorSearchResults from '../../presentational/assignment/assignment-editor-search-results';
import PollingSearchFormV2 from '../../presentational/assignment/assignment-polling-search-form-v2';
import PollingPlaceTag from '../../presentational/assignment/assignment-polling-tag';

@connect((state, ownProps) => {
  const { lbj, filters } = state;
  const precinctResults = lbj.get('precincts');
  const locationResults = lbj.get('locations');
  const shiftDate = filters.getIn([lbjAppSections.ASSIGNMENT, 'dates']);
  let countyData;

  if (ownProps.assignmentState) {
    countyData =
      lbj.getIn(['counties', ownProps.assignmentState]) || Immutable.Map({});
  } else {
    countyData = lbj.get('counties');
  }

  return {
    counties: countyData.get('listData'),
    isLoadingPrecincts: precinctResults.get('requestIsPending'),
    isLoadingLocations: locationResults.get('requestIsPending'),
    precinctList: precinctResults.get('listData'),
    locationList: locationResults.get('listData'),
    shiftDate,
  };
})
export default class FindPollingFormV2 extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    chooseLocationFirst: PropTypes.bool,
    counties: PropTypes.object,
    isLoadingPrecincts: PropTypes.bool.isRequired,
    isLoadingLocations: PropTypes.bool.isRequired,
    precinctList: PropTypes.object.isRequired,
    locationList: PropTypes.object.isRequired,
    precinct: PropTypes.object,
    location: PropTypes.object,
    assignmentState: PropTypes.string,
    onEditAssignmentData: PropTypes.func.isRequired,
    shiftDate: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.lastPrecinctSearch = {};
    this.lastLocationSearch = {};
    this.state = {
      chooseLocationFirst: !!props.chooseLocationFirst,
    };
  }

  componentWillMount() {
    const { location, precinct, assignmentState, user } = this.props;
    const userId = user.get('id');
    const precinctId = precinct ? precinct.get('id') : null;
    const locationId = location ? location.get('id') : null;

    this.onSearchForPrecincts({}, locationId, { sort_from_user: userId });
    this.onSearchForLocations({}, precinctId, { sort_from_user: userId });
    this.getCountiesForState(assignmentState);
  }

  componentDidUpdate(prevProps) {
    const { location, precinct, assignmentState, user } = this.props;
    const userId = user.get('id');

    if (prevProps.location !== location) {
      this.onSearchForPrecincts({}, this.getLocationId(), {
        sort_from_user: userId,
      });
    }

    if (prevProps.precinct !== precinct) {
      this.onSearchForLocations({}, this.getPrecinctId(), {
        sort_from_user: userId,
      });
    }

    if (prevProps.user.get('id') !== userId) {
      this.onSearchForPrecincts({}, this.getLocationId(), {
        sort_from_user: userId,
      });
      this.onSearchForLocations({}, this.getPrecinctId(), {
        sort_from_user: userId,
      });
    }

    if (prevProps.assignmentState !== assignmentState) {
      this.getCountiesForState(assignmentState);
    }
  }

  onSearchForPrecincts(additionalParams = {}, locationId, sortParams) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const basePayload = _.assign(
      this.getDefaultSearchFilters(),
      additionalParams
    );
    const filterPayload = _.assign(
      basePayload,
      locationId ? { location: locationId } : {},
      sortParams
    );

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
    this.lastPrecinctSearch = filterPayload;
  }

  onSearchForLocations(additionalParams = {}, precinctId, sortParams) {
    const { dispatch } = this.props;
    const { getLocationListAsync } = lbjActionCreators;
    const basePayload = _.assign(
      this.getDefaultSearchFilters(),
      additionalParams
    );
    const filterPayload = _.assign(
      basePayload,
      precinctId ? { precinct: precinctId } : {},
      sortParams,
      { boe__isnull: true }
    );

    dispatch(getLocationListAsync(filterPayload, ['locations']));
    this.lastLocationSearch = filterPayload;
  }

  onGetMorePrecinctResults({ offset }) {
    const { dispatch } = this.props;
    const { getPrecinctListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastPrecinctSearch, {
      offset,
      size: 25,
    });

    dispatch(getPrecinctListAsync(filterPayload, ['precincts']));
  }

  onGetMoreLocationResults({ offset }) {
    const { dispatch } = this.props;
    const { getLocationListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastLocationSearch, {
      offset,
      size: 25,
    });

    dispatch(getLocationListAsync(filterPayload, ['locations']));
  }

  getDefaultSearchFilters() {
    const { assignmentState, shiftDate } = this.props;

    return {
      state: assignmentState,
      dates: shiftDate,
      size: 25,
      expand: 'hours',
    };
  }

  getCountiesForState(state) {
    const { dispatch } = this.props;
    const { getCountyListAsync } = lbjActionCreators;

    dispatch(getCountyListAsync(state));
  }

  getCountyOptions() {
    const { counties } = this.props;

    if (counties) {
      return counties.reduce((choices, county) => {
        choices[county.get('slug')] = county.get('name');
        return choices;
      }, {});
    }

    return {};
  }

  getPrecinctId() {
    const { precinct } = this.props;
    return precinct ? precinct.get('id') : null;
  }

  getLocationId() {
    const { location } = this.props;
    return location ? location.get('id') : null;
  }

  renderResult(resourceType, resource, key) {
    const { assignmentState, onEditAssignmentData, user, shiftDate } =
      this.props;
    const distanceVal = convertMetersToMiles(resource.get('distance'));
    const distance = formatDistanceFromMiles(distanceVal);
    const maxDistanceVal = user.get('max_distance_miles');
    const maxDistance = formatDistanceFromMiles(maxDistanceVal);
    const outOfState =
      user.get('state') && user.get('state') !== assignmentState;
    const outOfRange =
      distanceVal && maxDistanceVal && distanceVal > maxDistanceVal;
    const containerClassName = cx(`assignment-results-max-distance`, {
      'assignment-out-of-range': outOfRange,
    });

    return (
      <li
        onClick={() => {
          const assignmentData = {};
          assignmentData[resourceType] = resource;
          onEditAssignmentData(assignmentData);
        }}
        key={key}
      >
        <span className="assignment-results-name">{resource.get('name')}</span>
        {resourceType === 'location' && (
          <div className="assignment-results-disclaimer">
            {hoursAsText(resource, shiftDate, false)}
          </div>
        )}
        {RIT(distance || outOfRange || outOfState, () => {
          return (
            <div className="assignment-results-disclaimer">
              {RIT(outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    Out of state for volunteer
                  </span>
                );
              })}

              {RIT(distance && !outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    {distance}
                  </span>
                );
              })}

              {RIT(outOfRange, () => {
                return (
                  <span className={containerClassName}>
                    (exceeds volunteer’s max travel of {maxDistance})
                  </span>
                );
              })}
            </div>
          );
        })}
      </li>
    );
  }

  renderPrecinctResults() {
    const { precinctList, isLoadingPrecincts } = this.props;

    return (
      <div>
        <EditorSearchResults
          listData={precinctList}
          isLoadingList={isLoadingPrecincts}
          onGetMoreSearchResults={this.onGetMorePrecinctResults.bind(this)}
          listElementRenderer={(precinct, key) =>
            this.renderResult('precinct', precinct, key)
          }
        />
      </div>
    );
  }

  renderLocationResults() {
    const { locationList, isLoadingLocations } = this.props;

    return (
      <div>
        <EditorSearchResults
          listData={locationList}
          isLoadingList={isLoadingLocations}
          onGetMoreSearchResults={this.onGetMoreLocationResults.bind(this)}
          listElementRenderer={(location, key) =>
            this.renderResult('location', location, key)
          }
        />
      </div>
    );
  }

  renderPrecinctSearch() {
    const { location, precinct, user } = this.props;
    const userId = user.get('id');

    return (
      <div>
        <div className="search-form-controls">
          <div className="search-form-controls__show">
            <label>Show</label>
            <button
              className="link-button"
              onClick={() => this.setState({ chooseLocationFirst: true })}
            >
              Locations
            </button>
            |
            <button className="link-button link-button--active">
              Precincts
            </button>
          </div>
        </div>
        <PollingSearchFormV2
          title="Find a Precinct"
          counties={this.getCountyOptions()}
          showCounty={!location || !precinct}
          showTier={!precinct}
          onChange={(searchFilters) => {
            this.onSearchForPrecincts(searchFilters, this.getLocationId(), {
              sort_from_user: userId,
            });
          }}
          onNameSortButtonClick={(searchFilters) => {
            this.onSearchForPrecincts(searchFilters, this.getLocationId(), {
              sort_by_name: 'asc',
            });
          }}
        />
      </div>
    );
  }

  renderLocationSearch() {
    const { precinct, location, user } = this.props;
    const userId = user.get('id');

    return (
      <div>
        <div className="search-form-controls">
          <div className="search-form-controls__show">
            <label>Show</label>
            <button className="link-button link-button--active">
              Locations
            </button>
            |
            <button
              className="link-button"
              onClick={() => this.setState({ chooseLocationFirst: false })}
            >
              Precincts
            </button>
          </div>
        </div>
        <PollingSearchFormV2
          title="Find a Location"
          counties={this.getCountyOptions()}
          showCounty={!location && !precinct}
          showTier={!precinct}
          onChange={(searchFilters) => {
            this.onSearchForLocations(searchFilters, this.getPrecinctId(), {
              sort_from_user: userId,
            });
          }}
          onNameSortButtonClick={(searchFilters) => {
            this.onSearchForLocations(searchFilters, this.getLocationId(), {
              sort_by_name: 'asc',
            });
          }}
        />
      </div>
    );
  }

  renderLocationTag() {
    const { location, onEditAssignmentData } = this.props;

    return (
      <div>
        <PollingPlaceTag
          onRemoveTag={() => {
            onEditAssignmentData({ location: null });
          }}
        >
          <strong>Location: {location.get('name')}</strong>
          <br />
          {location.get('address')}, {location.get('city')}{' '}
          {location.getIn(['county', 'state'])} {location.get('zipcode')}
        </PollingPlaceTag>
      </div>
    );
  }

  render() {
    const { precinct, location } = this.props;
    const { chooseLocationFirst } = this.state;
    const blankPollingPlace = !precinct && !location;

    return (
      <div className="assignment-find-form-v2">
        {blankPollingPlace && (
          <div>
            <h5 className="font-bold">Select Location</h5>
            <p>
              Use sorting and filters to refine the list. By default, the list
              is sorted by closest location to the volunteer.
            </p>
          </div>
        )}

        {/* search forms */}
        {RIT(
          chooseLocationFirst && blankPollingPlace,
          this.renderLocationSearch.bind(this)
        )}
        {RIT(
          !chooseLocationFirst && blankPollingPlace,
          this.renderPrecinctSearch.bind(this)
        )}
        {RIT(!location && precinct, this.renderLocationSearch.bind(this))}
        {RIT(location && !precinct, this.renderPrecinctSearch.bind(this))}

        {/* search results */}
        {RIT(
          chooseLocationFirst && blankPollingPlace,
          this.renderLocationResults.bind(this)
        )}
        {RIT(
          !chooseLocationFirst && blankPollingPlace,
          this.renderPrecinctResults.bind(this)
        )}
        {RIT(!location && precinct, this.renderLocationResults.bind(this))}
        {RIT(location && !precinct, this.renderPrecinctResults.bind(this))}

        {/* polling place details */}
        {RIT(!!location, this.renderLocationTag.bind(this))}
      </div>
    );
  }
}
