import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { assignmentViewTypes } from '../../../constants';
import paginatedComponent from '../../../decorators/paginated-component';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import mapStateToListData from '../../../utils/assignment/map-state-to-list-data';
import mapStateToPagination from '../../../utils/lbj/map-state-to-pagination';

import RIT from '../../../utils/render-if-truthy';
import BoardOfElectionsItem from '../../presentational/assignment/assignment-boe-item';
import EmptyAlert from '../../presentational/lbj/empty-alert';
import LoadingOverlay from '../../presentational/lbj/loading';

const { BOARDS_OF_ELECTIONS } = assignmentViewTypes;

@connect((state) => {
  const { assignment } = state;
  const paginationData = mapStateToPagination(assignment, [
    'assignmentIndex',
    BOARDS_OF_ELECTIONS,
  ]);
  const { listData, listRequestIsPending, listRequestErred } =
    mapStateToListData(state);
  const filters = mapStateToAssignmentFilters(state);

  return {
    filters,
    listData,
    listRequestIsPending,
    listRequestErred,
    paginationData,
  };
})
@paginatedComponent((props, page) => {
  const { dispatch, filters } = props;
  const { getAssignmentListForBoardsOfElectionsAsync } =
    assignmentActionCreators;
  const filterPayload = _.assign(page, filters.toJS());

  return dispatch(getAssignmentListForBoardsOfElectionsAsync(filterPayload));
})
export default class BoardOfElectionsListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    listData: PropTypes.object.isRequired,
    listRequestIsPending: PropTypes.bool.isRequired,
    listRequestErred: PropTypes.bool.isRequired,
    paginationData: PropTypes.object.isRequired,
  };

  renderEmptyState() {
    const headerText = 'boards of elections';

    return (
      <EmptyAlert
        header={`There are no ${headerText} here.`}
        description="Make sure you've selected a state on the left or change existing filters"
      />
    );
  }

  render() {
    const { listData, listRequestIsPending, listRequestErred } = this.props;

    return (
      <div className="lbj-column-content">
        {RIT(listRequestIsPending, () => (
          <LoadingOverlay />
        ))}

        <div className="assignment-list-view-content">
          {RIT(
            listData.isEmpty() && !listRequestIsPending && !listRequestErred,
            this.renderEmptyState.bind(this)
          )}

          {listData.map((boardOfElections, key) => {
            return (
              <BoardOfElectionsItem
                boardOfElections={boardOfElections}
                key={key}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
