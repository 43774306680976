import React, { HTMLProps } from 'react';

import TextArea from '../../components/presentational/form/textarea';

export default class IssueReply extends React.Component<
  {
    parentId: number;
    onReplySubmit: (parentId: number, commentText: string) => void;
    onReplyCancel: () => void;
    commentText?: string | undefined;
  },
  {
    commentText: string;
  }
> {
  constructor(props: IssueReply['props']) {
    super(props);

    this.state = {
      commentText: props.commentText ?? '',
    };
  }

  onReplyChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({
      commentText: value,
    });
  }

  onReplySubmit() {
    if (this.textEntered()) {
      // Truthy ok here, we just want some comment
      this.props.onReplySubmit(this.props.parentId, this.state.commentText);
    }
  }

  textEntered() {
    return this.state.commentText;
  }

  render() {
    const opts: Pick<HTMLProps<HTMLButtonElement>, 'disabled'> = {};
    if (!this.textEntered()) {
      opts.disabled = true;
    }

    return (
      <div className="issue-comments-input issue-comments-reply">
        <TextArea
          name="comment"
          placeholder="Replying to the note above..."
          value={this.state.commentText}
          onChange={this.onReplyChange.bind(this)}
        />
        <button
          type="button"
          className="lbj-comment-button"
          {...opts}
          onClick={this.onReplySubmit.bind(this)}
        >
          Add reply
        </button>
        <button
          type="button"
          className="lbj-reply-cancel"
          onClick={this.props.onReplyCancel}
        >
          Cancel
        </button>
      </div>
    );
  }
}
