import React from 'react';
import { AriaButtonProps, useButton } from 'react-aria';

import { assertUnreachable } from '../../utils/types';

/**
 * A button that looks like a link (no background or borders).
 */
export const TextButton: React.FunctionComponent<
  {
    buttonRef?: React.RefObject<HTMLButtonElement> | undefined;
    isSelected?: boolean;
    title?: string | undefined;
    icon?: string | undefined;
    /** Use "unset" to inherit styles from around the button. */
    size?: 'default' | 'small' | 'unset' | 'large' | undefined;
    className?: string | undefined;
  } & AriaButtonProps<'button'>
> = ({
  children,
  isSelected,
  title,
  icon,
  size = 'default',
  className,
  buttonRef,
  ...props
}) => {
  // We need a ref for `useButton`, so either re-use the one given us, or make a
  // new one.
  const localRef = React.useRef<HTMLButtonElement | null>(null);
  buttonRef = buttonRef ?? localRef;

  const { buttonProps } = useButton(props, buttonRef);

  let sizeClasses: string;
  let iconSizeClasses: string;
  const style: React.CSSProperties = {};

  switch (size) {
    case 'default':
      sizeClasses = 'text-base';
      iconSizeClasses = 'text-lg';
      break;

    case 'small':
      sizeClasses = 'text-xs uppercase leading-0';
      iconSizeClasses = 'text-base';
      break;

    case 'large':
      sizeClasses = 'text-xl';
      iconSizeClasses = 'text-2xl';
      break;

    case 'unset':
      sizeClasses = '';
      iconSizeClasses = '';

      // We need to do this with style because Tailwind doesn’t have “unset”
      // support for font sizes.
      style.fontSize = 'unset';
      style.lineHeight = 'unset';
      break;

    default:
      assertUnreachable(size);
  }

  const textColorClass = isSelected
    ? 'text-black'
    : props.isDisabled
    ? 'text-gray'
    : 'text-accent';

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      className={`cursor-default bg-transparent align-baseline font-bold ${textColorClass} ${sizeClasses} ${
        className ?? ''
      } inline-flex items-center gap-1`}
      title={title}
      style={style}
    >
      {icon && (
        <span className={`material-icons ${iconSizeClasses}`}>{icon}</span>
      )}
      {children}
    </button>
  );
};
