import type { HelpTextProps } from '@react-types/shared';
import React from 'react';

import { useCheckbox, AriaCheckboxProps, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';

import { FormError } from './FormError';

/**
 * Simple check box.
 */
export const Checkbox: React.FunctionComponent<
  AriaCheckboxProps & HelpTextProps
> = (props) => {
  const { children } = props;
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);

  return (
    <div className="flex flex-col gap-2">
      <label className="flex items-center gap-2 text-base leading-none">
        <VisuallyHidden>
          <input {...inputProps} ref={ref} />
        </VisuallyHidden>

        <div
          className={`flex h-4 w-4 shrink-0 items-center justify-center rounded-sm border-2 ${
            props.isDisabled
              ? 'border-gray-300 text-gray-300'
              : 'border-primary text-primary'
          }`}
          aria-hidden
        >
          {props.isIndeterminate ? (
            <span className="material-icons text-xs font-bold">remove</span>
          ) : state.isSelected ? (
            <span className="material-icons text-xs font-bold">check</span>
          ) : null}
        </div>

        {children}
      </label>

      {props.errorMessage && (
        // left-margin to align with the checkbox
        <FormError className="ml-0.25">{props.errorMessage}</FormError>
      )}
    </div>
  );
};
