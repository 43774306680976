import useSWR from 'swr';

import { State } from '../../constants';
import * as IssueService from '../../services/issue-service';
import * as LbjService from '../../services/lbj-shared-service';

/**
 * Given a current election (that a user has selected from nav) and a chosen
 * state (e.g. from a dropdown menu), finds the election ID that should be used
 * for that state.
 *
 * If the election and the state match, the election’s ID is returned. But if
 * the election is the National election, looks up the right state-specific
 * election to use.
 *
 * Returns `undefined` if it’s determining the election and `null` if it can’t
 * find it.
 *
 * Note: callers need to support this being asynchronous. Even if the current
 * election is a state election, the response during the first render will be
 * `undefined`.
 */
export function useStateSpecificElectionId(
  election: Pick<LbjService.ApiElection, 'id' | 'state'>,
  selectedState: State | null
): number | null | undefined {
  // we pass everything in to the callback since we need it to determine if an
  // API call is necessary
  return useSWR(
    [
      'IssueService#getIssueElection',
      { electionId: election.id, electionState: election.state, selectedState },
    ] as const,
    async ([_, params]) => {
      if (params.selectedState === null || params.selectedState === 'US') {
        return null;
      } else if (params.electionState === 'US') {
        return (await IssueService.getIssueElection(params.selectedState))
          .election_id;
      } else {
        return selectedState === params.electionState
          ? params.electionId
          : null;
      }
    }
  ).data;
}
