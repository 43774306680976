import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import AssignmentDateModal from '../../presentational/assignment/assignment-date-modal';
import AssignmentDateSelectionDisplay from '../../presentational/assignment/assignment-date-selection-display';

export class AssignmentDateSelectionView extends Component {
  static propTypes = {
    availableDates: PropTypes.arrayOf(
      PropTypes.shape({
        is_early_vote: PropTypes.bool.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    selectedDate: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    ...WithRoutingPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.currentDateType = this.currentDateType.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * When we change the selected date, we need to update the route
   **/
  onChange(dateStr) {
    const { location, navigate } = this.props;
    const { pathname, search } = location;
    const query = searchToQuery(search);
    const newQuery = { ...query, dates: dateStr };
    this.setState({ editing: false });
    navigate({ pathname, search: queryToSearch(newQuery) });
  }

  currentDateType() {
    for (const available of this.props.availableDates) {
      if (available.date === this.props.selectedDate) {
        return available.is_early_vote ? 'early_vote' : 'eday';
      }
    }
  }

  render() {
    const { availableDates, selectedDate, view } = this.props;

    return (
      <div style={{ display: 'inline' }}>
        <AssignmentDateSelectionDisplay
          date={selectedDate}
          dateType={this.currentDateType()}
          onEdit={() => this.setState({ editing: true })}
        />
        {this.state.editing && (
          <AssignmentDateModal
            available={availableDates.map((d) => d.date)}
            onCancel={() => this.setState({ editing: false })}
            onChange={(dateStr) => this.onChange(dateStr)}
            selected={selectedDate}
            view={view}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  const filters = mapStateToAssignmentFilters(state);
  const availableDates = state.user
    .getIn(['currentUser', 'currentUserElection', 'election', 'days'], List())
    .toJS();

  return {
    availableDates,
    selectedDate: filters.get('dates'),
    view: filters.get('view'),
  };
})(withRouting(AssignmentDateSelectionView));
