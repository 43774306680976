import {
  CalendarDate,
  DateValue,
  createCalendar,
  getWeeksInMonth,
} from '@internationalized/date';
import type { DOMAttributes, FocusableElement } from '@react-types/shared';
import cx from 'classnames';
import React from 'react';
import {
  AriaButtonProps,
  AriaCalendarGridProps,
  CalendarProps,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useLocale,
} from 'react-aria';
import {
  CalendarState,
  RangeCalendarState,
  useCalendarState,
} from 'react-stately';

import { TextButton } from './TextButton';

/**
 * Calendar picker for a single date.
 *
 * @see https://react-spectrum.adobe.com/react-aria/useCalendar.html
 */
export function Calendar({
  emphasizedDates,
  monthCount = 1,
  ...props
}: CalendarProps<DateValue> & {
  /** Dates that will be rendered in bold. */
  emphasizedDates?: DateValue[] | undefined;
  /** Number of months to show side-by-side. */
  monthCount?: number;
}) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    visibleDuration: { months: monthCount },
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <CalendarView
      state={state}
      calendarProps={calendarProps}
      prevButtonProps={prevButtonProps}
      nextButtonProps={nextButtonProps}
      title={title}
      emphasizedDates={emphasizedDates}
      monthCount={monthCount}
    />
  );
}

/**
 * Inner calendar view shared by {@link Calendar} and {@link RangeCalendar}.
 */
export const CalendarView = React.forwardRef<
  HTMLDivElement,
  {
    state: CalendarState | RangeCalendarState;
    calendarProps: DOMAttributes<FocusableElement>;
    prevButtonProps: AriaButtonProps<'button'>;
    nextButtonProps: AriaButtonProps<'button'>;
    title: string;
    emphasizedDates: DateValue[] | undefined;
    monthCount: number;
  }
>(
  (
    {
      calendarProps,
      prevButtonProps,
      title,
      nextButtonProps,
      state,
      emphasizedDates,
      monthCount,
    },
    ref
  ) => {
    /**
     * An array of {@link monthCount} length. For mapping over for calendar
     * components.
     */
    const monthArr = Array(monthCount).fill(null);

    return (
      <div {...calendarProps} className=" text-gray-700" ref={ref}>
        <div className="flex justify-between pb-2">
          <TextButton {...prevButtonProps} className="h-fit">
            <span className="material-icons">chevron_left</span>
          </TextButton>
          <h2 className="font-bold">{title}</h2>
          <TextButton {...nextButtonProps}>
            <span className="material-icons">chevron_right</span>
          </TextButton>
        </div>

        <div className="flex items-start gap-4">
          {monthArr.map((_, idx) => (
            <CalendarGrid
              key={idx}
              state={state}
              emphasizedDates={emphasizedDates}
              startDate={state.visibleRange.start.add({ months: idx })}
            />
          ))}
        </div>
      </div>
    );
  }
);

CalendarView.displayName = 'CalendarView';

/**
 * Component to render a single month of days. Used by {@link CalendarView}.
 *
 * Can pass a `startDate` prop to offset this from the default `visibleRange` of
 * the calendar state. Used for showing more than one month side-by-side.
 */
function CalendarGrid({
  state,
  emphasizedDates,
  ...props
}: {
  state: CalendarState | RangeCalendarState;
  emphasizedDates?: DateValue[] | undefined;
} & AriaCalendarGridProps) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of
  // rows.
  const weeksInMonth = getWeeksInMonth(
    props.startDate ?? state.visibleRange.start,
    locale
  );

  return (
    // w-9 (2.25rem) per each of 7 columns
    <table {...gridProps} className="my-0 w-[15.75rem] table-fixed">
      <thead {...headerProps}>
        <tr className="border-0 bg-white">
          {weekDays.map((day, index) => (
            <th key={index} className="border-0 text-center">
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex} className="border-0 bg-white">
            {state
              .getDatesInWeek(weekIndex, props.startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    isEmphasized={
                      !!emphasizedDates?.find((d) => d.compare(date) === 0)
                    }
                  />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

/**
 * Component to render a single day in a {@link CalendarGrid}.
 */
function CalendarCell({
  state,
  date,
  isEmphasized = false,
}: {
  state: CalendarState | RangeCalendarState;
  date: CalendarDate;
  isEmphasized?: boolean | undefined;
}) {
  const ref = React.useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isUnavailable,
    isDisabled,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const isSelectable = !isUnavailable && !isDisabled;

  /**
   * True if this is the first (or only) date in the selection. Used to render a
   * rounded background on the left.
   */
  const isSelectionStart =
    // `highlightedRange` is a prop of RangeCalendarState
    'highlightedRange' in state
      ? state.highlightedRange?.start?.toString() === date.toString()
      : isSelected;

  /**
   * True if this is the last (or only) date in the selection. Used to render a
   * rounded background on the right.
   */
  const isSelectionEnd =
    // `highlightedRange` is a prop of RangeCalendarState
    'highlightedRange' in state
      ? state.highlightedRange?.end?.toString() === date.toString()
      : isSelected;

  return (
    <td {...cellProps} className="border-0 p-0">
      {/* This <div> will be the clickable “button” for the date. */}
      <div
        {...buttonProps}
        ref={ref}
        className={cx('flex h-8 py-0.5', {
          // cursor-defaut to override that [role=button] gets a pointer cursor.
          'cursor-default text-gray-500': !isSelectable,
          'font-bold': isEmphasized && isSelectable,
          // Prevent this padding if we need a selection background to extend
          // to the left.
          'pl-1': !(isSelected && !isSelectionStart),
          // Prevent this padding if we need a selection background to extend
          // to the left.
          'pr-1': !(isSelected && !isSelectionEnd),
          hidden: isOutsideVisibleRange,
        })}
      >
        {/* Inner div for the roundy background. */}
        <div
          className={cx(
            'flex flex-1 items-center justify-center self-stretch',
            {
              'hover:bg-accent hover:bg-opacity-40': isSelectable,
              'bg-accent font-bold text-white': isSelected,
              // We always render as rounded unless we need to extend the
              // selection background in one direction or the other.
              'rounded-l-full': !(isSelected && !isSelectionStart),
              'rounded-r-full': !(isSelected && !isSelectionEnd),
              'pl-1': isSelected && !isSelectionStart,
              'pr-1': isSelected && !isSelectionEnd,
            }
          )}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
