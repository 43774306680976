import _ from 'lodash';
import React from 'react';
import {
  Link,
  LoaderFunctionArgs,
  Location,
  useLocation,
  useNavigation,
  useRevalidator,
} from 'react-router-dom';

import { PageTitle } from '../../components/common';

import { SelectableUser } from '../../components/presentational/form/user-autocomplete';
import LoadingOverlay from '../../components/presentational/lbj/loading';
import Toast, { useToast } from '../../components/presentational/lbj/toast';
import { AppStore } from '../../modules/flux-store';
import { ToastData, ToastRecord } from '../../modules/toast';
import { loadCurrentUser } from '../../route-handlers/app';
import { PaginatedResponse } from '../../services/common';
import * as IssueService from '../../services/issue-service';
import { ApiElection } from '../../services/lbj-shared-service';
import * as UserService from '../../services/user-service';
import { ApiCurrentUser } from '../../services/user-service';
import { PaginationData } from '../../utils/lbj/map-state-to-pagination';
import { orderingToSortProps } from '../../utils/list-helpers';
import {
  parseRequestQueryState,
  useRouterQueryState,
} from '../../utils/query-state';
import {
  LbjPermissions,
  roleMayCreateIssue,
  roleMayExport,
  roleMayFilterByUser,
  roleMayUseElectionSpecificFilters,
} from '../../utils/user/map-state-to-lbj-permissions';

import ActiveFilters from './active-filters';
import IssueFilterSidebar from './issue-filter-sidebar';
import IssueList from './issue-list';
import { UnconnectedIssueListExport } from './issue-list-export';
import ViewTypeControl from './issue-list-view-type-control';
import {
  filtersToIssueListApiParams,
  IssuesListFilters,
  IssuesListFilterUpdater,
  IssuesListLbjData,
  loadIssuesListLbjData,
  makeIssuesListFilterLabels,
  makeIssuesListFiltersQueryFields,
  makeUpdateFiltersWithDependencies,
  useIssueCsvExport,
  useUpdateListPolling,
} from './issues-list-utils';

export type IssuesIndexPermissions = Pick<
  LbjPermissions,
  | 'canUseElectionSpecificFilters'
  | 'canExport'
  | 'canFilterByUser'
  | 'canCreateIssue'
>;

function makeIssuesIndexFiltersQueryFields(currentElection: ApiElection) {
  return makeIssuesListFiltersQueryFields(currentElection, (q) => ({
    // We keep results/vote tally issues only appearing on the results page.
    exclude_category: q.silent('Results|Vote Tally'),
  }));
}

export const IssuesIndexWithState: React.FunctionComponent<
  {
    currentUser: Pick<ApiCurrentUser, 'id' | 'role' | 'current_boiler_rooms'>;
    currentUserElection: ApiCurrentUser['user_elections'][0];
    issueListResponse: PaginatedResponse<IssueService.IssueListApiIssue>;
    preloadedUsers: SelectableUser[];
  } & IssuesListLbjData
> = ({
  currentUser,
  currentUserElection,
  issueListResponse,
  elections,
  counties,
  districts,
  locations,
  precincts,
  regions,
  boilerRooms,
  preloadedUsers,
}) => {
  const location = useLocation();
  const { state: navigationState } = useNavigation();
  const [toastData, { showToast, dismissToast }] = useToast();

  const currentElection = currentUserElection.election;

  const [
    filters,
    updateQueryFilters,
    {
      reset: resetQueryFilters,
      paramsFromUpdates: queryFilterParamsFromUpdates,
    },
  ] = useRouterQueryState<IssuesListFilters>(
    makeIssuesIndexFiltersQueryFields(currentElection)
  );

  // These are filters and other values that should apply both to loading the
  // list of issues and to polling for new issues.
  const commonListApiParams = filtersToIssueListApiParams(filters);

  // Used to trigger react-router to reload our data if the issues list updates.
  const revalidator = useRevalidator();

  useUpdateListPolling({
    currentUser,
    params: commonListApiParams,
    results: issueListResponse.results,
    refetch: () => revalidator.revalidate(),
    onFirstPage: filters.offset === 0,
  });

  const updateFilters = makeUpdateFiltersWithDependencies(
    elections,
    filters,
    updateQueryFilters
  );

  const [isCsvExporting, startCsvExport] = useIssueCsvExport();

  const permissions: IssuesIndexPermissions = {
    canExport: roleMayExport(currentUser.role),
    canUseElectionSpecificFilters: roleMayUseElectionSpecificFilters(
      currentUser.role
    ),
    canFilterByUser: roleMayFilterByUser(currentUser.role),
    canCreateIssue: roleMayCreateIssue(currentUser.role),
  };

  return (
    <IssuesIndexView
      location={location}
      currentUser={currentUser}
      currentElection={currentElection}
      filters={filters}
      updateFilters={updateFilters}
      clearFilters={resetQueryFilters}
      filtersToSearchParams={(filters) =>
        // We use resetAll since we don’t want to carry over any current filter
        // values for the URLs this function will generate.
        queryFilterParamsFromUpdates(filters, true)
      }
      issueListResponse={issueListResponse}
      permissions={permissions}
      isCsvExporting={isCsvExporting}
      startCsvExport={() => startCsvExport(currentUser, commonListApiParams)}
      toastData={toastData}
      showToast={showToast}
      dismissToast={dismissToast}
      counties={counties}
      districts={districts}
      elections={elections}
      locations={locations}
      precincts={precincts}
      regions={regions}
      boilerRooms={boilerRooms}
      preloadedUsers={preloadedUsers}
      listRequestIsPending={navigationState === 'loading'}
    />
  );
};

export const IssuesIndexView: React.FunctionComponent<
  {
    location: Location;
    currentUser: Pick<ApiCurrentUser, 'role' | 'id' | 'current_boiler_rooms'>;
    currentElection: ApiElection;
    filters: IssuesListFilters;
    updateFilters: IssuesListFilterUpdater;
    clearFilters: () => void;
    filtersToSearchParams: (
      filters: Partial<IssuesListFilters>
    ) => URLSearchParams;
    issueListResponse: PaginatedResponse<IssueService.IssueListApiIssue>;
    permissions: IssuesIndexPermissions;
    isCsvExporting: boolean;
    startCsvExport: () => Promise<string | null>;
    toastData: ToastRecord;
    showToast: (d: ToastData) => void;
    dismissToast: () => void;
    listRequestIsPending: boolean;
    preloadedUsers: SelectableUser[];
  } & IssuesListLbjData
> = ({
  location,
  currentUser,
  currentElection,
  filters,
  updateFilters,
  clearFilters,
  filtersToSearchParams,
  issueListResponse,
  permissions,
  isCsvExporting,
  startCsvExport,
  toastData,
  showToast,
  dismissToast,
  elections,
  regions,
  counties,
  precincts,
  locations,
  districts,
  boilerRooms,
  preloadedUsers,
  listRequestIsPending,
}) => {
  const currentElectionState = currentElection.state;
  const resultsLabels = makeIssuesListFilterLabels(
    currentElectionState,
    currentElection.type,
    {
      elections,
      regions,
      counties,
      precincts,
      locations,
      districts,
      boilerRooms,
      preloadedUsers,
    }
  );

  const paginationData = new PaginationData({
    isFirstPage: issueListResponse.previous === null,
    isLastPage: issueListResponse.next === null,
    listIsEmpty: issueListResponse.results.length === 0,
    offset: filters.offset,
    size: filters.size,
  });

  return (
    <div className="lbj-page-wrapper">
      <div className="lbj-page-columns">
        <IssueFilterSidebar
          permissions={permissions}
          currentElectionState={currentElectionState}
          filters={filters}
          updateFilters={updateFilters}
          elections={elections}
          regions={regions}
          counties={counties}
          precincts={precincts}
          locations={locations}
          districts={districts}
          boilerRooms={boilerRooms}
          preloadedUsers={preloadedUsers}
        />

        <div className="issue-main">
          <Toast toastData={toastData} onDismiss={() => dismissToast()} />

          <div className="issue-header">
            <PageTitle>Issues</PageTitle>

            <UnconnectedIssueListExport
              canExport={permissions.canExport}
              isExporting={isCsvExporting}
              showToast={showToast}
              startExport={startCsvExport}
            />
            {permissions.canCreateIssue && (
              <Link
                className="lbj-nav-button lbj-header-button"
                to="/issues/new"
              >
                New Issue
              </Link>
            )}
          </div>

          <hr />

          <ActiveFilters
            filters={filters}
            labels={resultsLabels}
            onClearAll={() => clearFilters()}
            onClearFilter={(key) => updateFilters({ [key]: undefined })}
          />

          <ViewTypeControl
            listViewType={filters.view}
            currentUser={currentUser}
            location={location}
            currentIssueCount={issueListResponse.count}
            filtersToSearchParams={filtersToSearchParams}
          />

          <div className="lbj-main">
            <IssueList
              issues={issueListResponse.results}
              filterParams={filtersToSearchParams(filters)}
              listRequestIsPending={listRequestIsPending}
              paginationData={paginationData}
              paginationAction={({ offset, size }) =>
                updateFilters({ offset, size })
              }
              {...orderingToSortProps(filters, updateFilters)}
            />

            {listRequestIsPending && <LoadingOverlay />}
          </div>
        </div>
      </div>
    </div>
  );
};

export async function loadIssuesIndexPage(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<React.ComponentProps<typeof IssuesIndexWithState>> {
  const { currentUser, currentUserElection } = await loadCurrentUser(
    fluxStore,
    {
      allowedRoles: [
        'vpd',
        'deputy_vpd',
        'boiler_room_leader',
        'boiler_room_user',
        'hotline_manager',
        'hotline_worker',
        'poll_observer',
        'view_only',
      ],
    }
  );

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const filters = parseRequestQueryState<IssuesListFilters>(
    request,
    makeIssuesIndexFiltersQueryFields(currentElection)
  );

  const issueListResp = IssueService.getIssues(
    {
      id: currentUser.get('id'),
      role: currentUser.get('role'),
    },
    {
      offset: filters.offset,
      size: filters.size,
      ...filtersToIssueListApiParams(filters),
    }
  );

  const filteredUserIds = [filters.owner, filters.user].filter(
    (id): id is number => id !== undefined
  );

  const preloadedUsersResp =
    filteredUserIds.length > 0
      ? UserService.getUserList({ id: filteredUserIds.join(',') }).then(
          ({ users }) => users
        )
      : [];

  return {
    currentUser: currentUser.toJS() as ApiCurrentUser,
    currentUserElection:
      currentUserElection.toJS() as ApiCurrentUser['user_elections'][0],
    issueListResponse: await issueListResp,
    ...(await loadIssuesListLbjData(filters)),
    preloadedUsers: await preloadedUsersResp,
  };
}
