import cx from 'classnames';
import React from 'react';
import { AriaTextFieldProps, useTextField } from 'react-aria';

import { FormError } from './FormError';
import { FormLabel } from './FormLabel';

export function TextField({
  icon,
  iconPosition = 'start',
  inputRef,
  inputClassName,
  variant = 'default',
  ...props
}: AriaTextFieldProps & {
  /**
   * Name of a material-icons icon to use.
   *
   * TODO(fiona): Find a TypeScript list of all available icons.
   */
  icon?: string;
  iconPosition?: 'start' | 'end';
  inputRef?: React.RefObject<HTMLInputElement> | undefined;
  inputClassName?: string | undefined;
  /** Use 'small' for toolbars and such. */
  variant?: 'default' | 'small' | 'menu';
}) {
  const { label } = props;
  const localInputRef = React.useRef(null);
  inputRef = inputRef ?? localInputRef;
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    props,
    inputRef
  );

  const iconEl = icon && (
    <span
      className="material-icons absolute text-xl leading-none text-gray-500"
      style={iconPosition === 'start' ? { left: 6 } : { right: 6 }}
      aria-hidden
    >
      {icon}
    </span>
  );

  return (
    <div className="flex flex-col items-stretch gap-2">
      {typeof label === 'string' ? (
        <FormLabel type="label" {...labelProps} isRequired={props.isRequired}>
          {label}
        </FormLabel>
      ) : (
        label
      )}

      <div className="relative flex items-center">
        {iconEl}

        <input
          {...inputProps}
          ref={inputRef}
          className={cx(
            'block px-2 py-0 text-gray-700 shadow-none',
            'placeholder:not-italic placeholder:text-inherit placeholder:text-gray-500',
            inputClassName,
            {
              'border-2 text-base': variant === 'default',
              'h-[36px] min-h-0 border text-sm':
                variant === 'small' || variant === 'menu',
              'rounded-b-none rounded-t-lg border-b-0 valid:border-gray-200':
                variant === 'menu',
              rounded: variant !== 'menu',
              'pl-8': icon && iconPosition === 'start',
              'pr-8': icon && iconPosition === 'end',
              'bg-gray-300': props.isDisabled,
              'bg-white': !props.isDisabled,
              'border-red-700': props.validationState === 'invalid',
              'valid:border-gray-300':
                props.validationState !== 'invalid' && variant !== 'menu',
              'invalid:border-red-700': props.validationState !== 'invalid',
            }
          )}
        />
      </div>

      {props.errorMessage && (
        <FormError {...errorMessageProps}>{props.errorMessage}</FormError>
      )}
    </div>
  );
}
