import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import Modal from '../lbj/modal';

export default class UserDisableModal extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
  };

  render() {
    const { user, errorMessage } = this.props;

    return (
      <Modal
        dismissable
        headerText="Disable this user"
        onClose={this.props.onCancel}
      >
        <div className="confirm-delete-user">
          <div className="row disable-modal-text">
            {RIT(errorMessage, () => {
              return (
                <div className="lbj-toast lbj-toast-error">{errorMessage}</div>
              );
            })}
            Really disable {user.get('first_name')} {user.get('last_name')}’s
            LBJ profile? This will permanently delete all of their assignments
            and remove them from LBJ for this election.
          </div>
          <div className="row">
            <div className="col-small-6">
              <button
                className="c-button-large disable-modal-button lbj-button-red"
                onClick={this.props.onSubmit}
              >
                Disable
              </button>
            </div>
            <div className="col-small-6">
              <button
                className="c-button-large disable-modal-button lbj-button-gray"
                onClick={this.props.onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
