import * as Immutable from 'immutable';

import { ApiTicketSummaryResult } from '../../services/dashboard-service';
import {
  makeListRequestRecordObj,
  updateListRequestRecord,
} from '../../utils/lbj/list-request-state-handler';
import { AppAction } from '../flux-store';

import actionTypes from './action-types';

export class AnalyticsState extends Immutable.Record({
  incidentAnalytics: makeListRequestRecordObj<ApiTicketSummaryResult>(),
}) {}

const initialState = new AnalyticsState({});

export default function dashboard(
  state: AnalyticsState = initialState,
  action: AppAction
): AnalyticsState {
  const { GET_INCIDENT_ANALYTICS } = actionTypes;

  switch (action.type) {
    case GET_INCIDENT_ANALYTICS:
      return state.update(
        'incidentAnalytics',
        updateListRequestRecord(action.data)
      );

    default:
      return state;
  }
}
