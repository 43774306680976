import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

/**
 * Unicode line.
 *
 * If this is the “choice” name it gets rendered as a disabled item.
 *
 * You can combine several of these to make a line as long as you need.
 */
export const SEPARATOR_VALUE = '────────';

export default class SelectInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    choices: PropTypes.object,
    errors: PropTypes.array,
    className: PropTypes.string,
    emptyVal: PropTypes.string,
    emptyLabel: PropTypes.string,
  };

  static defaultProps = {
    errors: [],
  };

  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return <label htmlFor={name}>{title}</label>;
  }

  renderChoices() {
    const { choices, emptyVal, emptyLabel } = this.props;
    const optionElements = [];
    let choice;

    if (emptyVal !== undefined) {
      optionElements.push(
        <option key={emptyVal} value={emptyVal} hidden={!emptyLabel} disabled>
          {emptyLabel}
        </option>
      );
    }

    for (choice in choices) {
      if (choices[choice]) {
        optionElements.push(
          <option
            key={choice}
            value={choice}
            disabled={choices[choice].startsWith(SEPARATOR_VALUE[0])}
          >
            {choices[choice]}
          </option>
        );
      }
    }

    return optionElements;
  }

  renderInput() {
    const {
      name,
      title,
      type,
      required,
      disabled,
      readOnly,
      value,
      defaultValue,
      onChange,
      emptyVal,
    } = this.props;
    // Need the check against emptyVal because if it’s the empty string we still
    // want this component to be controlled rather than be uncontrolled w/
    // defaultValue.
    const valueProp =
      value || (emptyVal !== undefined && value === emptyVal)
        ? { value }
        : { defaultValue };

    return (
      <div className="lbj-select-wrapper">
        <select
          id={name}
          name={name}
          title={title}
          type={type}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          {...valueProp}
          onChange={onChange}
        >
          {this.renderChoices()}
        </select>
      </div>
    );
  }

  renderError(error, key) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  render() {
    const { name, errors } = this.props;
    const passedClassName = this.props.className;
    const className = cx(
      'lbj-input',
      'lbj-input-select',
      'lbj-input-' + name,
      passedClassName
    );

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
        {RIT(errors.length, () => {
          return errors.map((error, key) => this.renderError(error, key));
        })}
      </div>
    );
  }
}
