import type { Immutable } from 'immer';
import _ from 'lodash';

import ApiClient from './api-client';
import {
  CountySlug,
  DateString,
  Iso8601String,
  OffsetResponse,
  TimeString,
} from './common';

/**
 * Model for volunteer availability groups.
 */
export type ApiVolunteerAvailabilityGroup = {
  id: number;
  name: string;
  van_state: string | null;
  van_event_id: string | null;
  default_allowed_location_cities: string[] | null;
  default_allowed_location_county_slugs: CountySlug[] | null;
  default_respect_travel_distance_tag: boolean;
  last_uploaded: Iso8601String | null;
  created_at: Iso8601String;
  last_updated: Iso8601String;
};

export type NewApiVolunteerAvailabilityGroup = Omit<
  ApiVolunteerAvailabilityGroup,
  'id' | 'last_uploaded' | 'created_at' | 'last_updated'
>;

export type ApiVolunteerAvailabilityTimeOption =
  | 'am'
  | 'pm'
  | 'all_day'
  | 'custom';

/**
 * Model for a person’s availability on a current day.
 */
export type ApiVolunteerAvailability = {
  user_id: number;
  date: DateString;
  availability_group_id: number | null;
  time: ApiVolunteerAvailabilityTimeOption;
  custom_times: Array<[TimeString, TimeString]> | null;
  allowed_location_cities: string[] | null;
  allowed_location_county_slugs: string[] | null;
  respect_travel_distance_tag: boolean;
  created_at: Iso8601String;
  last_updated: Iso8601String;
};

/**
 * Type for creating volunteer availability entries.
 *
 * `availability_group_id` comes from the URL path, and the datetimes don’t get
 * set by the client.
 */
export type NewApiVolunteerAvailability = Omit<
  ApiVolunteerAvailability,
  'availability_group_id' | 'created_at' | 'last_updated'
>;

/**
 * Returns a list of all of the volunteer availability groups on the election.
 */
export function getAvailabilityGroupList(filters: {
  size: number;
  offset: number;
}) {
  return ApiClient<
    OffsetResponse<'availability_groups', ApiVolunteerAvailabilityGroup>
  >('availability_groups/', 'GET', filters);
}

/**
 * Creates a new availability group for this election.
 *
 * `name` must be provided and unique in the election.
 */
export function createAvailabilityGroup(
  newGroup: Immutable<NewApiVolunteerAvailabilityGroup>
) {
  return ApiClient<ApiVolunteerAvailabilityGroup>(
    'availability_groups/',
    'POST',
    {},
    { raiseDjangoRestValidationErrors: true },
    newGroup
  );
}

/**
 * Updates an availability group.
 */
export function updateAvailabilityGroup(
  groupId: number,
  updatedGroup: Immutable<NewApiVolunteerAvailabilityGroup>
) {
  return ApiClient<ApiVolunteerAvailabilityGroup>(
    `availability_groups/${groupId}/`,
    'PUT',
    {},
    { raiseDjangoRestValidationErrors: true },
    updatedGroup
  );
}

/**
 * Deletes an availability group.
 */
export function deleteAvailabilityGroup(groupId: number) {
  return ApiClient<ApiVolunteerAvailabilityGroup>(
    `availability_groups/${groupId}/`,
    'DELETE',
    {},
    { raiseDjangoRestValidationErrors: true }
  );
}

/**
 * Returns all of the availability entries for the entire election.
 */
export function getAvailabilityList(filters: { size: number; offset: number }) {
  return ApiClient<OffsetResponse<'availability', ApiVolunteerAvailability>>(
    'availability/',
    'GET',
    filters
  );
}

/**
 * Uploads a list of availability entries to a given availability group.
 * Replaces all existing members of the group.
 */
export function uploadAvailability(
  groupId: number,
  availability: NewApiVolunteerAvailability[]
) {
  return ApiClient<{ availability: ApiVolunteerAvailability[] }>(
    `availability_groups/${groupId}/upload/`,
    'POST',
    {},
    { raiseDjangoRestValidationErrors: true },
    { availability }
  );
}

export default {
  getAvailabilityGroupList,
  createAvailabilityGroup,
  updateAvailabilityGroup,
  deleteAvailabilityGroup,
  getAvailabilityList,
  uploadAvailability,
};
