import { parseTime } from '@internationalized/date';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';

import { DateBadge } from '../../../components/common';
import {
  ActionButton,
  FormLabel,
  Radio,
  RadioGroup,
  TextButton,
  TextField,
  TimeField,
} from '../../../components/form';
import { ModalDialog } from '../../../components/layout';

import {
  ApiLocationHours,
  ApiLocationHourUpdates,
} from '../../../services/assignment-service';
import { ApiElection } from '../../../services/lbj-shared-service';

import { useStateWithDeps } from '../../../utils/hooks';
import { Awaitable } from '../../../utils/types';

import { AssignmentLocation } from '../assignment-state';
import { extractTextHours, shiftCountsForLocation } from '../assignment-utils';

const EditHoursModal: React.FunctionComponent<{
  hours: ApiLocationHours;
  location: AssignmentLocation;
  locationHasAssignments: boolean;
  election: Pick<
    ApiElection,
    | 'election_date'
    | 'eday_shift_start_time'
    | 'eday_shift_end_time'
    | 'eday_shift_change_time'
    | 'ev_shift_start_time'
    | 'ev_shift_end_time'
    | 'ev_shift_change_time'
    | 'location_tier_configuration'
  >;
  doUpdate: (updates: ApiLocationHourUpdates) => Awaitable<void | string>;
  doClose: () => void;
}> = ({
  hours,
  location,
  locationHasAssignments,
  election,
  doClose,
  doUpdate,
}) => {
  const [initialValues] = useStateWithDeps(hours, [location.id, hours.date]);

  const isElectionDay = hours.date === election.election_date;

  const [defaultAmShiftCount, defaultPmShiftCount] = shiftCountsForLocation(
    location,
    { am_shift_count: null, pm_shift_count: null },
    election.location_tier_configuration
  );

  return (
    <ModalDialog
      title="Configure Shifts and Times"
      aboveCenter
      showClose
      doClose={doClose}
    >
      <Form<ApiLocationHours, ApiLocationHours>
        initialValues={initialValues}
        onSubmit={async (values) => {
          // TODO(fiona): shift times
          return await doUpdate(
            values.closed
              ? {
                  date: hours.date,
                  location_id: location.id,
                  closed: true,
                  tracks_election_default: 'none',
                }
              : {
                  date: hours.date,
                  location_id: location.id,
                  closed: false,
                  tracks_election_default: 'none',
                  open_time: values.open_time,
                  shift_change_time: values.shift_change_time,
                  close_time: values.close_time,
                  am_shift_count: values.am_shift_count ?? defaultAmShiftCount,
                  pm_shift_count: values.pm_shift_count ?? defaultPmShiftCount,
                }
          );
        }}
      >
        {({
          handleSubmit,
          values,
          form: formApi,
          pristine,
          submitting,
          hasSubmitErrors,
        }) => {
          const isOpen = !values.closed;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex w-[560px] flex-col gap-6 p-2"
            >
              <div className="flex gap-4">
                <DateBadge shiftDate={hours.date} />
                <div className="flex flex-1 flex-col gap-1">
                  <div className="font-bold">{location.name}</div>

                  {location.state_rank !== null && (
                    // TODO(fiona): Include tier info
                    <div>
                      <strong>Location Rank:</strong> #{location.state_rank}
                    </div>
                  )}

                  <div>
                    <strong>Voting Hours:</strong>{' '}
                    {extractTextHours(location, false)}
                  </div>
                </div>

                <div className="flex w-28 gap-2 self-center">
                  <Field<ApiLocationHours['closed']> name="closed">
                    {({ input }) => (
                      <RadioGroup
                        aria-label="Location Status"
                        name="closed"
                        value={input.value.toString()}
                        onChange={(val) => input.onChange(val === 'true')}
                      >
                        <Radio value="false">Open</Radio>
                        <Radio value="true" isDisabled={locationHasAssignments}>
                          Closed
                        </Radio>
                      </RadioGroup>
                    )}
                  </Field>
                  {/**
                   * Final Form only considers registered fields when
                   * calculating pristine/dirty, so we provide a non-rendering
                   * Field component for shift_change_time to register it
                   * so calls to formApi.change('shift_change_time', ...)
                   * make the form dirty (aka not pristine)
                   *
                   * Source:
                   * https://github.com/final-form/final-form/issues/169#issuecomment-430939734
                   */}
                  <Field name="shift_change_time">{() => null}</Field>
                </div>
              </div>

              {isOpen && (
                <div className="flex flex-col gap-4 ">
                  {values.shift_change_time !== null ? (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                          <FormLabel>Morning Shift</FormLabel>

                          <TextButton
                            size="small"
                            onPress={() => {
                              formApi.change('shift_change_time', null);
                            }}
                          >
                            Switch to All Day Shift
                          </TextButton>
                        </div>

                        <ShiftInputs
                          startName="open_time"
                          endName="shift_change_time"
                          countName="am_shift_count"
                          defaultCount={defaultAmShiftCount}
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <FormLabel>Afternoon Shift</FormLabel>
                        <ShiftInputs
                          startName="shift_change_time"
                          endName="close_time"
                          countName="pm_shift_count"
                          defaultCount={defaultPmShiftCount}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-between">
                        <FormLabel>All-Day Shift</FormLabel>

                        <TextButton
                          size="small"
                          onPress={() => {
                            formApi.change(
                              'shift_change_time',
                              initialValues.shift_change_time || '12:00:00'
                            );
                          }}
                        >
                          Switch to morning/afternoon shifts
                        </TextButton>
                      </div>

                      <ShiftInputs
                        startName="open_time"
                        endName="close_time"
                        countName="am_shift_count"
                        defaultCount={defaultAmShiftCount}
                      />
                    </div>
                  )}
                </div>
              )}

              {locationHasAssignments && (
                <div className="border-2 border-yellow-700 bg-yellow-300 p-2">
                  Changing shift times will not affect existing assignments.
                </div>
              )}

              {hasSubmitErrors && (
                <div className="bg-red-300 p-2">
                  There was an error saving the updates.
                </div>
              )}

              <div className="flex flex-row-reverse gap-2">
                <ActionButton
                  role="primary"
                  type="submit"
                  isDisabled={pristine || submitting}
                >
                  Save
                </ActionButton>
                <ActionButton
                  role="secondary"
                  isDisabled={hours.tracks_election_default !== 'none'}
                  onPress={() =>
                    doUpdate({
                      date: hours.date,
                      closed: false,
                      location_id: location.id,
                      tracks_election_default: isElectionDay ? 'eday' : 'ev',
                    })
                  }
                >
                  Revert to Default
                </ActionButton>
                <ActionButton role="secondary" onPress={() => doClose()}>
                  Cancel
                </ActionButton>

                <div className="flex-1" />
              </div>
            </form>
          );
        }}
      </Form>
    </ModalDialog>
  );
};

export default EditHoursModal;

/**
 * Renders form fields for start/end shift times and # of volunteers.
 */
const ShiftInputs: React.FunctionComponent<{
  startName: 'open_time' | 'shift_change_time';
  endName: 'shift_change_time' | 'close_time';
  countName: 'am_shift_count' | 'pm_shift_count';
  defaultCount: number;
}> = ({ startName, endName, countName, defaultCount }) => (
  <div className="flex items-center gap-1">
    <Field<ApiLocationHours[typeof startName]> name={startName}>
      {({ input }) => (
        <TimeField
          aria-label="Shift Start Time"
          value={input.value ? parseTime(input.value) : null}
          onChange={(time) =>
            input.onChange(
              time === null ? null : moment(time).format('HH:mm:ss')
            )
          }
          onFocus={input.onFocus as any}
          onBlur={input.onBlur as any}
        />
      )}
    </Field>
    –
    <Field<ApiLocationHours[typeof endName]> name={endName}>
      {({ input }) => (
        <TimeField
          aria-label="Shift End Time"
          value={input.value ? parseTime(input.value) : null}
          onChange={(time) => {
            input.onChange(
              time === null ? null : moment(time).format('HH:mm:ss')
            );
          }}
          onFocus={input.onFocus as any}
          onBlur={input.onBlur as any}
        />
      )}
    </Field>
    <div className="flex-1" />
    <Field<ApiLocationHours[typeof countName]> name={countName}>
      {({ input }) => (
        <div className="flex items-center gap-2">
          <div className="w-12">
            <TextField
              aria-label="Volunteer Count"
              type="number"
              inputMode="numeric"
              inputClassName="text-right"
              name={input.name}
              defaultValue={input.value?.toString() || defaultCount.toString()}
              onChange={(newValue) =>
                input.onChange(newValue === '' ? null : parseInt(newValue))
              }
              onFocus={input.onFocus as any}
              onBlur={input.onBlur as any}
            />
          </div>{' '}
          Volunteers
        </div>
      )}
    </Field>
    <div className="flex-1" />
  </div>
);
