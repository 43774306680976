import { parseDate } from '@internationalized/date';
import cx from 'classnames';
import { castDraft, produce } from 'immer';
import moment from 'moment';
import React from 'react';
import { Item } from 'react-stately';

import {
  ActionButton,
  IconButton,
  Menu,
  RangeCalendar,
  TextButton,
  TextField,
} from '../../../components/form';

import { Dialog, Popover } from '../../../components/layout';
import { DATE_STRING_FORMAT } from '../../../services/common';

import {
  ApiElectionDay,
  ApiLocationTierConfiguration,
  ApiUserTag,
} from '../../../services/lbj-shared-service';

import type {
  AssignmentViewState,
  CalendarMovement,
  TableViewMode,
} from '../LocationAssignmentPage';

import { ShiftBox } from './LocationDayCell';
import LocationFilter, { LocationMunicipalities } from './LocationFilter';
import LocationGroupAndSort from './LocationGroupAndSort';
import PersonFilter from './PersonFilter';
import PersonGroupAndSort from './PersonGroupAndSort';
import { iconForDistanceMi } from './location-table-utils';

const TABLE_VIEW_MODES: TableViewMode[] = ['locations', 'people'];

const TABLE_VIEW_ICONS: { [mode in TableViewMode]: string } = {
  locations: 'where_to_vote',
  people: 'group',
};

const TABLE_VIEW_LABELS: { [mode in TableViewMode]: string } = {
  locations: 'Locations',
  people: 'People',
};

const LocationTableToolbar: React.FunctionComponent<{
  electionDays: ApiElectionDay[];
  visibleElectionDays: ApiElectionDay[];
  changeVisibleElectionDays: (movement: CalendarMovement) => void;
  setVisibleElectionDays: (idx: number, length: number) => void;
  maxVisibleElectionDays: number;
  municipalities: LocationMunicipalities;
  maxRanking: number | null;
  locationTierConfiguration: ApiLocationTierConfiguration[];
  userLanguageTagInfos: ApiUserTag[];
  assignmentViewState: AssignmentViewState;
  setAssignmentViewState: React.Dispatch<
    React.SetStateAction<AssignmentViewState>
  >;
  hasLastSuggestedAssignments: boolean;
}> = ({
  electionDays,
  visibleElectionDays,
  changeVisibleElectionDays,
  setVisibleElectionDays,
  maxVisibleElectionDays,
  municipalities,
  locationTierConfiguration,
  userLanguageTagInfos,
  maxRanking,
  assignmentViewState,
  setAssignmentViewState,
  hasLastSuggestedAssignments,
}) => {
  // For these comparisons it’s important to go w/ the list of days rather than
  // doing date math, since the days can be non-contiguous when EV ends earlier
  // than right before eday.
  const canGoBack = visibleElectionDays[0]?.date !== electionDays[0]?.date;

  const canGoForward =
    visibleElectionDays[visibleElectionDays.length - 1]?.date !==
    electionDays[electionDays.length - 1]?.date;

  return (
    <div className="flex items-center gap-2">
      <Menu
        trigger={(props, ref) => (
          <ActionButton
            buttonRef={ref}
            {...props}
            role="toolbar"
            icon={TABLE_VIEW_ICONS[assignmentViewState.mode]}
          >
            View: <strong>{TABLE_VIEW_LABELS[assignmentViewState.mode]}</strong>
          </ActionButton>
        )}
        selectionMode="single"
        selectionStyle="checkmark"
        selectedKeys={[assignmentViewState.mode]}
        onSelectionChange={(keys) =>
          typeof keys !== 'string' &&
          setAssignmentViewState(
            produce((draft) => {
              draft.mode = [...keys][0]! as TableViewMode;
            })
          )
        }
      >
        {TABLE_VIEW_MODES.map((mode) => (
          <Item key={mode} textValue={TABLE_VIEW_LABELS[mode]}>
            <div className="flex items-center gap-2">
              <span className="material-icons text-lg leading-none text-gray-700">
                {TABLE_VIEW_ICONS[mode]}
              </span>
              <span>{TABLE_VIEW_LABELS[mode]}</span>
            </div>
          </Item>
        ))}
      </Menu>

      {assignmentViewState.mode === 'locations' && (
        <>
          <LocationGroupAndSort
            groupBy={assignmentViewState.locations.groupBy}
            sortBy={assignmentViewState.locations.sortBy}
            setGroupBy={(groupBy) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.locations.groupBy = groupBy;
                })
              )
            }
            setSortBy={(sortBy) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.locations.sortBy = sortBy;
                })
              )
            }
            // TODO(fiona): disable ranking-related options if no ranking available
            disabledGroupByOptions={[]}
            disabledSortByOptions={[]}
          />

          <LocationFilter
            locationFilters={assignmentViewState.locations.filters}
            setLocationFilters={(filtersUpdater) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.locations.filters = castDraft(
                    filtersUpdater(draft.locations.filters)
                  );
                })
              )
            }
            locationTierConfiguration={locationTierConfiguration}
            municipalities={municipalities}
            maxRanking={maxRanking}
            hasLastSuggestedAssignments={hasLastSuggestedAssignments}
          />
        </>
      )}

      {assignmentViewState.mode === 'people' && (
        <>
          <PersonGroupAndSort
            groupBy={assignmentViewState.people.groupBy}
            sortBy={assignmentViewState.people.sortBy}
            setGroupBy={(groupBy) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.people.groupBy = groupBy;
                })
              )
            }
            setSortBy={(sortBy) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.people.sortBy = sortBy;
                })
              )
            }
            disabledGroupByOptions={[]}
            disabledSortByOptions={[]}
          />

          <PersonFilter
            userLanguageTagInfos={userLanguageTagInfos}
            personFilters={assignmentViewState.people.volunteerFilters}
            setPersonFilters={(filtersUpdater) =>
              setAssignmentViewState(
                produce((draft) => {
                  draft.people.volunteerFilters = castDraft(
                    filtersUpdater(draft.people.volunteerFilters)
                  );
                })
              )
            }
          />
        </>
      )}

      <div className="w-48">
        <TextField
          placeholder="Search"
          aria-label="Filter Locations"
          onChange={(searchValue) =>
            setAssignmentViewState(
              produce((draft) => {
                draft.searchValue = searchValue;
              })
            )
          }
          icon="search"
          iconPosition="start"
          variant="small"
        />
      </div>

      <div className="mx-4 flex">
        <IconButton
          onPress={() => changeVisibleElectionDays('prevPage')}
          icon="keyboard_double_arrow_left"
          isDisabled={!canGoBack || visibleElectionDays.length === 1}
        />
        <IconButton
          onPress={() => changeVisibleElectionDays('prevDay')}
          icon="chevron_left"
          isDisabled={!canGoBack}
        />

        <DateRangeButton
          electionDays={electionDays}
          visibleElectionDays={visibleElectionDays}
          maxVisibleElectionDays={maxVisibleElectionDays}
          setVisibleElectionDays={setVisibleElectionDays}
        />

        <IconButton
          onPress={() => changeVisibleElectionDays('nextDay')}
          icon="chevron_right"
          isDisabled={!canGoForward}
        />
        <IconButton
          onPress={() => changeVisibleElectionDays('nextPage')}
          icon="keyboard_double_arrow_right"
          isDisabled={!canGoForward || visibleElectionDays.length === 1}
        />
      </div>

      <Popover
        type="menu"
        placement="bottom start"
        trigger={(props, ref) => (
          <IconButton buttonRef={ref} icon="help" {...props} />
        )}
      >
        <div className="flex w-[600px] flex-col gap-6 rounded-sm border border-gray-300 bg-white p-4 text-base">
          <div
            className="grid items-start gap-6"
            style={{ gridTemplateColumns: '1fr 1fr' }}
          >
            <div className="flex flex-col gap-6">
              <div
                className="grid items-center gap-2"
                style={{ gridTemplateColumns: '24px 1fr' }}
              >
                <h3 className="col-span-2 font-bold">Shifts</h3>
                <ShiftBox shift={null} />
                Unassigned shift
                <ShiftBox shift={{ source: 'server' }} />
                Shift assignment
                <ShiftBox shift={{ source: 'auto' }} />
                Suggested shift assignment
                <div className="material-icons text-center text-xl text-gray-500">
                  block
                </div>
                Open location, no shifts
              </div>

              <div
                className="grid items-center gap-2"
                style={{ gridTemplateColumns: '24px 1fr' }}
              >
                <h3 className="col-span-2 font-bold">Volunteer Info</h3>
                <span className="material-icons">military_tech</span>
                Experienced observer
                <span className="material-icons">gavel</span>
                Legal community member
                <span className="material-icons">garage</span>
                Has car access
                <span className="material-icons">chat</span>
                Language(s) spoken (besides English)
              </div>
            </div>

            <div
              className="grid items-center gap-2"
              style={{ gridTemplateColumns: '24px 1fr' }}
            >
              <h3 className="col-span-2 font-bold">Distances (up to…)</h3>
              <span className="material-icons">{iconForDistanceMi(1)}</span>
              1mi
              <span className="material-icons">{iconForDistanceMi(10)}</span>
              10mi
              <span className="material-icons">{iconForDistanceMi(25)}</span>
              25mi
              <span className="material-icons">{iconForDistanceMi(50)}</span>
              50mi
              <span className="material-icons">{iconForDistanceMi(100)}</span>
              100mi
              <span className="material-icons">{iconForDistanceMi(250)}</span>
              250mi
              <span className="material-icons">{iconForDistanceMi(500)}</span>
              …and beyond
              <span className="material-icons text-yellow-900">taxi_alert</span>
              Close to volunteer’s maximum
            </div>
          </div>

          <div className="text-center text-sm italic">
            Click on a grid cell to see shift details and edit assignments.
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default LocationTableToolbar;

/**
 * Button for choosing the date range that we display.
 *
 * Shows the current date range as a label. When clicked, opens a popover with a
 * date range picker.
 */
const DateRangeButton: React.FunctionComponent<{
  electionDays: ApiElectionDay[];
  visibleElectionDays: ApiElectionDay[];
  /** Limit the selected range by this number of days */
  maxVisibleElectionDays: number;
  setVisibleElectionDays: (idx: number, length: number) => void;
}> = ({
  electionDays,
  visibleElectionDays,
  maxVisibleElectionDays,
  setVisibleElectionDays,
}) => {
  const visibleStartDateStr =
    visibleElectionDays.length > 0
      ? moment(visibleElectionDays[0]!.date, DATE_STRING_FORMAT).format('M/D')
      : null;

  const visibleEndDateStr =
    // We check _greater_ than one because if it’s just 1 then that’s already
    // covered by visibleStartDateStr.
    visibleElectionDays.length > 1
      ? moment(
          visibleElectionDays[visibleElectionDays.length - 1]!.date,
          DATE_STRING_FORMAT
        ).format('M/D')
      : null;

  return (
    <Popover
      type="menu"
      trigger={(props, ref) => (
        <TextButton
          {...props}
          buttonRef={ref}
          size="unset"
          className={cx('mx-1 flex cursor-pointer font-bold text-gray-700', {
            // Make things narrower if we’re not showing a full range, so
            // there isn’t a gap between the calendar icon and the date.
            'w-[8rem]': !!visibleEndDateStr,
            'w-[5rem]': !visibleEndDateStr,
          })}
        >
          <div className="material-icons text-gray-500">date_range</div>

          {/* flex and alignment shenanigans to keep this as a fixed width with centered contents so that
          things don’t bounce around as you move through dates with different widths */}
          <div
            className={cx('flex-1', {
              'text-right': !!visibleEndDateStr,
              'text-center': !visibleEndDateStr,
            })}
          >
            {visibleStartDateStr}
          </div>

          {visibleEndDateStr && (
            <>
              <div>&nbsp;–&nbsp;</div>
              <div className="flex-1 text-left">{visibleEndDateStr}</div>
            </>
          )}
        </TextButton>
      )}
    >
      <Dialog className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
        <RangeCalendar
          allowsNonContiguousRanges
          monthCount={2}
          minValue={parseDate(electionDays[0]!.date)}
          maxValue={parseDate(electionDays[electionDays.length - 1]!.date)}
          value={{
            start: parseDate(visibleElectionDays[0]!.date),
            end: parseDate(
              visibleElectionDays[visibleElectionDays.length - 1]!.date
            ),
          }}
          // The only way we have to limit the size of the range chosen is to
          // dynamically make unavailable all dates farther away than the limit.
          isDateUnavailable={(date, anchorDate) => {
            const dateIdx = electionDays.findIndex(
              (day) => day.date === date.toString()
            );

            if (dateIdx === -1) {
              return true;
            } else if (anchorDate === null) {
              return false;
            } else {
              const anchorDateIdx = electionDays.findIndex(
                (day) => day.date === anchorDate.toString()
              );

              return (
                Math.abs(dateIdx - anchorDateIdx) >= maxVisibleElectionDays
              );
            }
          }}
          pageBehavior="single"
          onChange={({ start, end }) => {
            const startIdx = electionDays.findIndex(
              (day) => day.date === start.toString()
            );

            const endIdx = electionDays.findIndex(
              (day) => day.date === end.toString()
            );

            // They both should be found, but check for safety.
            if (startIdx >= 0 && endIdx >= 0) {
              setVisibleElectionDays(startIdx, endIdx - startIdx + 1);
            }
          }}
          emphasizedDates={electionDays
            .filter((d) => !d.is_early_vote)
            .map((d) => parseDate(d.date))}
        />
      </Dialog>
    </Popover>
  );
};
