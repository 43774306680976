import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

import {
  useCounties,
  useLocations,
  usePrecincts,
} from '../../components/hooks/lbj-data';

import { State, UserRole } from '../../constants';
import { LbjInputEvent } from '../../decorators/input-props';
import { NewIssueInProgress } from '../../modules/issue/reducers';
import { CountySlug } from '../../services/common';
import {
  ApiCounty,
  ApiLocation,
  ApiPrecinct,
} from '../../services/lbj-shared-service';
import { ApiCurrentUser } from '../../services/user-service';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';

import { CommonIssueFormActions } from './issue-detail-form';
import IssueLocation from './issue-location';

const IssueLocationContainer: React.FunctionComponent<{
  // While this is in theroy on issueData, we ask for it explicitly since the
  // parent containers pull it out of the `issueElectionRequest` state.
  issueElectionId: number | null;
  existingLocation: ApiLocation | null;
  existingPrecinct: ApiPrecinct | null;
  existingCounty: ApiCounty | null;
  locationIsReadOnly: boolean;
  currentUserRole: UserRole;
  currentUserRelated: ApiCurrentUser['related'] | undefined;
  issueData: IssueInProgressPayload | NewIssueInProgress;
  isStateReadOnly: boolean;
  onInputChange: (
    ev: LbjInputEvent<string | number | null | undefined>
  ) => void;
  errors: { [key: string]: any };
  requiredFields: Immutable.List<string>;
  formType: 'poll_observer' | 'hotline';
  actions: Pick<CommonIssueFormActions, 'issueStateChanged'>;
}> = ({
  onInputChange,
  issueElectionId,
  issueData,
  currentUserRelated,
  currentUserRole,
  errors,
  formType,
  isStateReadOnly,
  locationIsReadOnly,
  requiredFields,
  existingLocation,
  existingPrecinct,
  existingCounty,
  actions,
}) => {
  const onStateChange = ({ target }: LbjInputEvent<State | null>) => {
    // We have to report up state changes because they may trigger a change in
    // election ID.
    actions.issueStateChanged(target.value);

    onInputChange({ target });

    // if state changes, these other things need to reset, too
    onInputChange({ target: { name: 'county', value: '' } });
    onInputChange({ target: { name: 'location', value: '' } });
    onInputChange({ target: { name: 'precinct', value: '' } });
  };

  const onCountyChange = ({ target }: LbjInputEvent<CountySlug | null>) => {
    onInputChange({ target });

    // if county changes, these other things need to reset, too
    onInputChange({ target: { name: 'location', value: '' } });
    onInputChange({ target: { name: 'precinct', value: '' } });
  };

  const countyList = useCounties(issueData.state || null);

  // For locations and precincts, we pass [] so we don’t load anything if no
  // county is specified.

  const locationList = useLocations(issueElectionId ?? undefined, {
    counties: issueData.county ? [issueData.county] : [],
    precinctId: issueData.precinct ? Number(issueData.precinct) : undefined,
  });

  const precinctList = usePrecincts(issueElectionId ?? undefined, {
    counties: issueData.county ? [issueData.county] : [],
    locationId: issueData.location ? Number(issueData.location) : undefined,
  });

  return (
    <IssueLocation
      issueData={issueData}
      errors={errors}
      onInputChange={onInputChange}
      onPrecinctChange={onInputChange}
      onLocationChange={onInputChange}
      onStateChange={onStateChange}
      onCountyChange={onCountyChange}
      currentUserRole={currentUserRole}
      currentUserRelated={currentUserRelated}
      locationList={locationList ?? []}
      precinctList={precinctList ?? []}
      countyList={countyList ?? []}
      existingLocation={
        // Only pass the “existing” value if it still matches the
        // currently-selected location.
        issueData.location === existingLocation?.id.toString()
          ? existingLocation
          : null
      }
      existingPrecinct={
        issueData.precinct === existingPrecinct?.id.toString()
          ? existingPrecinct
          : null
      }
      existingCounty={
        issueData.county === existingCounty?.slug ? existingCounty : null
      }
      locationIsReadOnly={locationIsReadOnly}
      isStateReadOnly={isStateReadOnly}
      requiredFields={requiredFields}
      formType={formType}
    />
  );
};

export default IssueLocationContainer;
