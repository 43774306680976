import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Autocomplete from '../form/autocomplete';
import CountySelect from '../form/county-select';
import DebouncedInput from '../form/debounced-input';

export default class PollingSearchFormV2 extends Component {
  static propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    counties: PropTypes.object,
    showCounty: PropTypes.bool,
    showTier: PropTypes.bool,
    onNameSortButtonClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchFields: {},
      sortBy: 'distance',
      showFilters: false,
      showSearch: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onNameSortButtonClick = this.onNameSortButtonClick.bind(this);
    this.onFiltersClick = this.onFiltersClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
  }

  onFiltersClick() {
    this.setState({
      showFilters: !this.state.showFilters,
      showSearch: false,
    });
  }

  onSearchClick() {
    this.setState({
      showFilters: false,
      showSearch: !this.state.showSearch,
    });
  }

  onChange({ target }) {
    const { onChange } = this.props;
    const { name, value } = target;
    const { searchFields } = this.state;

    if (name === 'distance') {
      this.setState({ sortBy: 'distance' });
    }

    if (!value) {
      delete searchFields[name];
    } else {
      searchFields[name] = value;
    }

    onChange(searchFields);

    this.setState({ searchFields });
  }

  onNameSortButtonClick() {
    const { searchFields } = this.state;
    const { onNameSortButtonClick } = this.props;

    onNameSortButtonClick(searchFields);
    this.setState({ searchFields, sortBy: 'name' });
  }

  renderFilters() {
    const { counties, showTier, showCounty } = this.props;
    const { searchFields } = this.state;
    return (
      <div className="assignment-filters">
        {showCounty && (
          <CountySelect
            defaultValue={searchFields.county}
            choices={counties}
            onChange={this.onChange}
          />
        )}
        {showTier && (
          <Autocomplete
            name="tier"
            title="Precinct Tier"
            defaultValue={searchFields.tier}
            onChange={this.onChange}
            multi
            choices={{
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            }}
          />
        )}
      </div>
    );
  }

  renderSearch() {
    const { title } = this.props;
    return (
      <div className="assignment-search">
        <DebouncedInput name="name" title={title} onChange={this.onChange} />
      </div>
    );
  }

  render() {
    const { showFilters, showSearch, sortBy } = this.state;
    return (
      <div>
        <div className="search-form-controls">
          <div className="search-form-controls__sort">
            <label>Sort by</label>
            <button
              name="distance"
              className={`link-button${
                sortBy === 'distance' ? ' link-button--active' : ''
              }`}
              onClick={this.onChange}
            >
              Distance
            </button>
            |
            <button
              className={`link-button${
                sortBy === 'name' ? ' link-button--active' : ''
              }`}
              onClick={this.onNameSortButtonClick}
            >
              A-Z
            </button>
          </div>
          <div className="search-form-controls__toggle">
            <button
              className={`link-button--with-icon${
                showFilters ? ' toggle-on' : ''
              }`}
              onClick={this.onFiltersClick}
            >
              <span className="material-icons">filter_list</span>Filters
            </button>
            <button
              className={`link-button--with-icon${
                showSearch ? ' toggle-on' : ''
              }`}
              onClick={this.onSearchClick}
            >
              <span className="material-icons">search</span>Search
            </button>
          </div>
        </div>

        {showFilters && this.renderFilters()}
        {showSearch && this.renderSearch()}
      </div>
    );
  }
}
