import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actionCreators } from '../../../modules/notifications';
import mapStateToPagination from '../../../utils/lbj/map-state-to-pagination';
import RIT from '../../../utils/render-if-truthy';
import {
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import mapStateToLbjPermissions from '../../../utils/user/map-state-to-lbj-permissions';
import Pagination from '../../presentational/lbj/pagination';
import IssueNotificationItem from '../../presentational/notifications/issue-notification-item';
import NotificationListWrapper from '../../presentational/notifications/notification-list-wrapper';

@connect((state) => {
  const { notifications } = state;
  const issueNotifications = notifications.getIn([
    'issue',
    'changeList',
    'listData',
  ]);
  const unackedLocationChangeCount = notifications.getIn([
    'location',
    'unackedCount',
  ]);
  const { userShouldSeeLocationNotifications } =
    mapStateToLbjPermissions(state);

  return {
    issueNotifications,
    notificationListIsEmpty:
      issueNotifications.isEmpty() && !unackedLocationChangeCount,
    isLoadingNotifications: notifications.getIn([
      'issue',
      'changeList',
      'requestIsPending',
    ]),
    paginationData: mapStateToPagination(notifications, [
      'issue',
      'changeList',
    ]),
    userShouldSeeLocationNotifications,
    unackedLocationChangeCount,
  };
})
@withRouting
export default class NotificationPopover extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    issueNotifications: PropTypes.object.isRequired,
    notificationListIsEmpty: PropTypes.bool.isRequired,
    isLoadingNotifications: PropTypes.bool.isRequired,
    unackedLocationChangeCount: PropTypes.number.isRequired,
    userShouldSeeLocationNotifications: PropTypes.bool.isRequired,
    paginationData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    ...WithRoutingPropTypes,
  };

  onAcknowledgeIssueNotification(notification) {
    const { dispatch, onClose, navigate } = this.props;
    const { ackIssueNotifications } = actionCreators;

    dispatch(ackIssueNotifications([notification.get('id')]));
    navigate(`/issues/${notification.get('ticket')}`);
    onClose();
  }

  onGetNextPage() {
    const { dispatch } = this.props;
    const { getIssueNotificationsAsync } = actionCreators;

    dispatch(getIssueNotificationsAsync(this.getPage()));
  }

  onGetPreviousPage() {
    const { dispatch } = this.props;
    const { getIssueNotificationsAsync } = actionCreators;

    dispatch(getIssueNotificationsAsync(this.getPage(false)));
  }

  getPage(isNextPage = true) {
    const { paginationData } = this.props;
    const size = parseInt(paginationData.get('size'), 10);
    const offsetDifference = isNextPage ? size : size * -1;

    return {
      offset: parseInt(paginationData.get('offset'), 10) + offsetDifference,
      size,
    };
  }

  renderPlaceNotification() {
    const { unackedLocationChangeCount, onClose, navigate } = this.props;

    return (
      <div
        onClick={() => {
          navigate('/notifications/locations');
          onClose();
        }}
        className="notification-item is-shrunken"
      >
        <div className="row">
          <div className="col-small-10">
            <span className="location-count">
              {unackedLocationChangeCount} location issues
            </span>{' '}
            need your attention.
          </div>

          <div className="col-small-2">
            <span className="lbj-caret-forward" />
          </div>
        </div>
      </div>
    );
  }

  renderIssueNotification(notification, key) {
    return (
      <IssueNotificationItem
        key={key}
        notification={notification}
        onAcknowledgeNotification={this.onAcknowledgeIssueNotification.bind(
          this
        )}
        isPopoverView
      />
    );
  }

  render() {
    const {
      issueNotifications,
      isOpen,
      notificationListIsEmpty,
      isLoadingNotifications,
      unackedLocationChangeCount,
      paginationData,
      onClose,
      userShouldSeeLocationNotifications,
    } = this.props;
    const className = cx('notification-popover', {
      'is-open': isOpen,
    });
    const shouldRenderPlaceNotification =
      userShouldSeeLocationNotifications &&
      !!unackedLocationChangeCount &&
      paginationData.get('isFirstPage');

    return (
      <div>
        {RIT(isOpen, () => (
          <div className="notification-popover-bg" onClick={onClose} />
        ))}
        <div className={className}>
          <div className="notification-popover-label">
            <Link
              className="lbj-more-detail-link a-float-right"
              to="/notifications"
              onClick={onClose}
            >
              See all
            </Link>
            <h4 className="font-bold">Notifications</h4>
          </div>
          <div className="notification-list-wrapper">
            <NotificationListWrapper
              notificationListIsEmpty={notificationListIsEmpty}
              isLoadingNotifications={isLoadingNotifications}
            >
              {RIT(
                shouldRenderPlaceNotification,
                this.renderPlaceNotification.bind(this)
              )}
              {issueNotifications.map(this.renderIssueNotification.bind(this))}

              <Pagination
                paginationData={paginationData}
                onGetNextPage={this.onGetNextPage.bind(this)}
                onGetPreviousPage={this.onGetPreviousPage.bind(this)}
              />
            </NotificationListWrapper>
          </div>
        </div>
      </div>
    );
  }
}
