import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { hoursAsText } from '../../../utils/assignment/location-hours';

export default class LocationDateHours extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
  };

  renderElectionDate() {
    const { date } = this.props;

    return moment(date).format('MMMM Do, YYYY');
  }

  render() {
    const { location, date } = this.props;

    const hoursStr = hoursAsText(location, date, true);

    return (
      <div>
        <ul className="lbj-detail-list">
          <li>{this.renderElectionDate()}</li>
          {hoursStr && <li>{hoursStr}</li>}
        </ul>
      </div>
    );
  }
}
