import { ApiExpandedIssueComment } from '../../services/issue-service';

export type FindReplyResult = {
  comment: ApiExpandedIssueComment;
  replies: FindReplyResult[];
};

function findReplies(
  comment: ApiExpandedIssueComment,
  comments: ApiExpandedIssueComment[]
) {
  // Get all replies to this comment
  const res: FindReplyResult = {
    comment: comment,
    replies: [],
  };

  for (const item of comments) {
    if (comment.id === item.parent) {
      res.replies.push(findReplies(item, comments));
    }
  }

  return res;
}

/**
 * Given a list of comments, return a list of objects as:
 *
 * ```
 *   const comments = [
 *   {
 *     comment: theComment,
 *     replies: theReplies
 *   }
 * ]
 * ```
 *
 * Replies with replies should be similarly nested:
 *
 * ```
 * const comments = [
 *   {
 *     comment: theComment,
 *     replies: [
 *       {
 *         comment: theReply,
 *         replies: theRepliesToReplies
 *       }
 *     ]
 *   }
 * ]
 * ```
 */

export default function nestComments(comments: ApiExpandedIssueComment[]) {
  const res = [];

  // Extract all the top-level comments
  const top = comments.filter((item) => item.parent === null);

  for (const c of top) {
    res.push(findReplies(c, comments));
  }

  return res;
}
