import React from 'react';

import { PaginationData } from '../../../utils/lbj/map-state-to-pagination';
import { TextButton } from '../../form/TextButton';

export default class Pagination extends React.Component<{
  paginationData: PaginationData;
  onGetPreviousPage: () => void;
  onGetNextPage: () => void;
}> {
  render() {
    const { paginationData } = this.props;
    const isFirstPage = paginationData.isFirstPage;
    const isLastPage = paginationData.isLastPage;

    return (
      <div className="my-6 flex justify-center gap-6 text-xl font-bold">
        <TextButton
          onPress={this.props.onGetPreviousPage}
          isDisabled={isFirstPage}
          size="large"
        >
          Back
        </TextButton>
        <TextButton
          onPress={this.props.onGetNextPage}
          isDisabled={isLastPage}
          size="large"
        >
          Next
        </TextButton>
      </div>
    );
  }
}
