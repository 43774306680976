import $ from 'jquery';

import { getNewVersionIsAvailable } from '../utils/notifications/map-state-to-app-notifications';

import trackGoogleAnalyticsEvent, {
  RawAnalyticsEvent,
} from './analytics-service';
import { authenticateRequest, getLbjBaseUrl } from './api-utils';
import * as LocalStoreService from './local-store-service';

export class ApiNotFoundError extends Error {
  constructor(description: string) {
    super(description);
  }
}

/**
 * Body format returned by Django REST Framework on validation errors.
 *
 * Keys are form fields, value is array of error messages.
 */
export type DjangoRestValidationErrorMessages = {
  [key: string]: string[];
};

/**
 * Thrown on 400 responses from the Django REST Framework, if
 * raiseDjangoRestValidationErrors=true is passed to QueryClient’s options.
 *
 * These errors have a common reponse format,
 * {@link DjangoRestValidationErrorMessages}.
 */
export class DjangoRestValidationError extends Error {
  errorMessagesByField: DjangoRestValidationErrorMessages;

  constructor(errorMessagesByField: DjangoRestValidationErrorMessages) {
    super();

    this.errorMessagesByField = errorMessagesByField;
  }
}

function _is2xxStatusCode(statusCode: number) {
  return Math.floor(statusCode / 100) === 2;
}

/**
 * Makes a request to the LBJ backend with the given parameters.
 *
 * If `useCache` is passed in `options`, uses a localStorage cache for the
 * values, with the given TTL.
 *
 * Always adds `election_id` and `user_election_id` to every request, even if
 * they’re not relevant.
 *
 * Typed to return a Promise of `unknown` by default so that callers have to
 * specify a type parameter to get anything useful.
 *
 * @see LocalStoreService
 */
export default function ApiClient<T = unknown>(
  pathname: string,
  method = 'GET',
  query: JQuery.PlainObject = {},
  options: {
    contentType?: string;
    useCache?: boolean;
    ttl?: number;
    timeout?: number;
    dataType?: string;
    /**
     * If true, assumes that 400 responses are validation errors from the Django
     * REST Framework, and raises the {@link DjangoRestValidationError}
     * exception to provide those errors.
     */
    raiseDjangoRestValidationErrors?: boolean;
  } = {},
  data: JQuery.PlainObject | null = {},
  service = 'lbj'
): Promise<T> {
  if (!pathname) throw new Error('ApiClient requires pathname');

  // TODO(fiona): duplicate query object for safety

  // Trim leading/trailing whitespace from all params
  for (const key of Object.keys(query)) {
    query[key] =
      query[key] !== null && query[key] !== undefined
        ? query[key].toString().trim()
        : null;
  }

  // We pass the user election id and election id to all APIs
  // for convenience, instead of having to thread them through a lot of
  // endpoints. TODO: We probably only want to pass user_election_id
  const userElectionId = LocalStoreService.getCurrentUserElectionId();
  if (typeof userElectionId === 'number') {
    query['user_election_id'] = userElectionId;
  }

  const electionId = LocalStoreService.getCurrentElectionId();
  if (typeof electionId === 'number') {
    query['election_id'] = electionId;
  }

  let basepath = '';
  if (service === 'lbj') {
    basepath = getLbjBaseUrl();
  }

  if (basepath === '') {
    throw new Error('Unsupported API Client service: ' + service);
  }

  return new Promise<T>((resolve, reject) => {
    const queryParams = $.param(query);

    let ajaxData = null;
    if (options.contentType && options.contentType !== 'application/json') {
      ajaxData = data;
    } else {
      ajaxData = JSON.stringify(data);
    }

    const requestUrl = `${basepath}${pathname}?${queryParams}`;
    const { useCache, ttl } = options;

    if (useCache) {
      const response = LocalStoreService.get<T>(requestUrl);

      if (response) {
        return resolve(response);
      }
    }

    const ajaxOptions: JQuery.AjaxSettings = {
      timeout: options.timeout || 30000,
      contentType: options.contentType || 'application/json',
      dataType: options.dataType || 'json',
      method,
      data: ajaxData ?? undefined,
      beforeSend: (xhr) => {
        authenticateRequest(xhr);
        if (useCache) {
          xhr.setRequestHeader('cache-control', 'cache');
        }
      },
      complete: (response) => {
        const gaArgs: RawAnalyticsEvent = {
          category: 'API Request',
          action: `${pathname}`,
          label: '',
        };
        const gaEventName = 'api_request_status';

        const apiVersion = response.getResponseHeader('API-Version')!;
        const feVersion = response.getResponseHeader('FE-Version') || '0.0.0';
        const currentlyStoredVersion =
          LocalStoreService.getCurrentFEVersion() || '0.0.0';

        // bust cache if the API version has been updated
        if (apiVersion !== LocalStoreService.getCurrentAPIVersion()) {
          LocalStoreService.purgeCache();
          LocalStoreService.updateAPIVersion(apiVersion);
        }

        // Check for a new FE version but only if the version increases
        if (getNewVersionIsAvailable(feVersion, currentlyStoredVersion)) {
          LocalStoreService.updateFEVersion(feVersion);
        }

        let responseData;
        if (!options.dataType || options.dataType === 'json') {
          responseData = response.responseJSON;
        } else {
          responseData = response.responseText;
        }
        const statusCode = response.status;

        if (_is2xxStatusCode(statusCode)) {
          gaArgs.label = 'SUCCESS';
          trackGoogleAnalyticsEvent(gaEventName, gaArgs);

          if (useCache) {
            LocalStoreService.set(requestUrl, responseData, ttl);
          }

          resolve(responseData);
        } else if (response.statusText === 'timeout') {
          gaArgs.label = 'TIMEOUT';
          trackGoogleAnalyticsEvent(gaEventName, gaArgs);
          reject({ timeout: 'A request timed out' });
        } else if (
          response.statusText === 'Forbidden' &&
          responseData.error === 'not authorized'
        ) {
          // 'not authorized' is the specific failure we see if the failure was
          // in the auth middleware due to LbjUser.can_log_in returning false.
          // User is disabled or has no active elections.
          reject({ authorization: 'user cannot log in' });
        } else if (statusCode === 404) {
          reject(
            new ApiNotFoundError(
              responseData?.['detail'] ?? response.statusText
            )
          );
        } else if (
          statusCode === 400 &&
          options.raiseDjangoRestValidationErrors
        ) {
          gaArgs.label = 'VALIDATION_ERROR';
          trackGoogleAnalyticsEvent(gaEventName, gaArgs);
          reject(new DjangoRestValidationError(responseData));
        } else {
          gaArgs.label = 'ERROR';
          trackGoogleAnalyticsEvent(gaEventName, gaArgs);
          reject(responseData || `${response.status} ${response.statusText}`);
        }
      },
    };
    $.ajax(requestUrl, ajaxOptions);
  });
}
