import { assertUnreachable } from '../types';

import {
  CbcOptions,
  CbcWorkerRequest,
  CbcWorkerResponse,
} from './cbc-solver-worker';
import {
  LpProblem,
  LpSolution,
  parseLpSolution,
  serializeLpProblem,
} from './lp-problem';

/**
 * Function that spins up a web worker to solve a particular MIP problem.
 *
 * If there is an error running the web worker or solving the problem, this
 * rejects with the exception.
 *
 * This terminates the web worker after it completes, since the startup time for
 * a web worker is very small relative to the problem solving, and we don’t gain
 * anything keeping it running between solves.
 */
export async function solveMipProblem(
  problem: LpProblem,
  options: CbcOptions
): Promise<LpSolution> {
  /* eslint-disable no-console */
  console.time('serialize-problem');
  const problemLp = serializeLpProblem(problem);
  console.timeEnd('serialize-problem');

  console.time('worker-startup');
  const worker = new Worker(new URL('./cbc-solver-worker', import.meta.url), {
    name: 'cbc',
  });

  try {
    return await new Promise<LpSolution>((resolve, reject) => {
      worker.onmessage = (ev: MessageEvent<CbcWorkerResponse>) => {
        switch (ev.data.type) {
          case 'READY': {
            console.timeEnd('worker-startup');
            // We need to wait for the “READY” message before we can send the
            // problem, because until then the CBC binary hasn’t loaded yet.
            const solveRequest: CbcWorkerRequest = {
              type: 'SOLVE',
              problemLp,
              options,
            };
            console.time('worker-solve');
            worker.postMessage(solveRequest);
            break;
          }

          case 'ERROR':
            console.timeEnd('worker-solve');
            reject(ev.data.exception ?? new Error(ev.data.message));
            break;

          case 'SOLUTION':
            console.timeEnd('worker-solve');
            resolve(parseLpSolution(ev.data.solution));
            break;

          default:
            assertUnreachable(ev.data);
        }
      };
    });
  } finally {
    // Make sure we’re cleaned up.
    worker.terminate();
  }
  /* eslint-enable no-console */
}
