import cx from 'classnames';
import moment from 'moment';
import React from 'react';

import { userRoles } from '../../constants';
import { ApiUser } from '../../services/user-service';
import { FindReplyResult } from '../../utils/issue/nest-comments';
import RIT from '../../utils/render-if-truthy';

import IssueReply from './issue-reply';

export default class IssueComment extends React.Component<
  {
    renderCommentList: (
      replies: FindReplyResult[],
      depth: number
    ) => React.ReactNode;
    onReplyAdd: ((parentId: number, commentText: string) => void) | undefined;
    text: string;
    timestamp: string;
    commenter: ApiUser;
    id: number;
    canReplyTo: boolean | undefined;
    replies: FindReplyResult[] | undefined;
    depth: number;
    label?: string;
    replying?: boolean | undefined;
  },
  {
    replying: boolean;
  }
> {
  constructor(props: IssueComment['props']) {
    super(props);
    this.state = {
      replying: !!props.replying,
    };
  }
  onReplyToggle() {
    this.setState({
      replying: !this.state.replying,
    });
  }
  onReplyOpen() {
    this.setState({
      replying: true,
    });
  }
  onReplySubmit(parentId: number, commentText: string) {
    this.props.onReplyAdd?.(parentId, commentText);
    this.onReplyClose();
  }

  onReplyClose() {
    this.setState({
      replying: false,
    });
  }

  getLabel() {
    let labelName = '';
    if (this.props.commenter.last_name || this.props.commenter.first_name) {
      labelName = `by ${this.props.commenter.first_name || ''} ${
        this.props.commenter.last_name || ''
      }`;
    }
    const labelTime = moment(this.props.timestamp).format(
      'MMM. Do, YYYY @ h:mma'
    );
    const displayRole = userRoles[this.props.commenter.role];
    const labelRole = displayRole ? `[${displayRole}]` : ''; // If the role is unknown or not found, omit this
    return (
      this.props.label || (
        <p className="issue-comment-label">
          <span className="issue-comment-time">{labelTime}</span>{' '}
          <span className="issue-comment-name">{labelName}</span>{' '}
          <span className="issue-comment-role">{labelRole}</span>
        </p>
      )
    );
  }

  render() {
    const label = this.getLabel();

    return (
      <div key={this.props.id}>
        <div
          className={cx([
            'issue-comment',
            'issue-comment-depth-' + this.props.depth,
          ])}
        >
          <div className="issue-comment-body">
            <p>{this.props.text}</p>
          </div>

          {RIT(label, () => {
            return (
              <div className="issue-comment-meta">
                <div>{label}</div>
              </div>
            );
          })}

          {RIT(this.props.canReplyTo, () => (
            <button
              type="button"
              className="lbj-replyto-button"
              onClick={this.onReplyToggle.bind(this)}
            >
              Reply
            </button>
          ))}

          {RIT(this.state && this.state.replying, () => (
            <IssueReply
              parentId={this.props.id}
              onReplySubmit={this.onReplySubmit.bind(this)}
              onReplyCancel={this.onReplyClose.bind(this)}
            />
          ))}
        </div>

        {!!this.props.replies?.length &&
          this.props.renderCommentList(
            this.props.replies,
            this.props.depth + 1
          )}
      </div>
    );
  }
}
