import React from 'react';
import { useSWRConfig } from 'swr';

/**
 * Component that automatically clears the SWR cache if the current election ID
 * changes, since most of our API calls implicitly depend on those IDs, which
 * are automatically after SWR builds the request.
 */
const ClearCache: React.FunctionComponent<{
  electionId: number | null;
  userElectionId: number | null;
}> = ({ electionId, userElectionId }) => {
  const { cache } = useSWRConfig();

  React.useEffect(() => {
    return () => (cache as Map<unknown, unknown>).clear();
  }, [cache, electionId, userElectionId]);

  return null;
};

export default ClearCache;
