import _ from 'lodash';

import { requestStatuses, UserViewType } from '../../constants';
import * as LocalStoreService from '../../services/local-store-service';
import * as RavenService from '../../services/sentry-service';
import * as UserService from '../../services/user-service';
import {
  ListRequestData,
  ListResponse,
} from '../../utils/lbj/list-request-state-handler';
import { DeepMerge } from '../../utils/types';
import { FiltersBySection } from '../filters/action-creators';
import { AppState, AppThunk } from '../flux-store';
import { showToast, dismissToast } from '../toast';

import actionTypes from './action-types';

const { PENDING, SUCCESS, ERROR } = requestStatuses;

const {
  UPDATE_USER,
  GET_USER_COUNT,
  GET_USER_LIST,
  GET_ALL_USER_IDS,
  GET_CURRENT_USER,
  GET_USER_DETAILS,
  CLEAR_USER_DETAILS,
  BULK_TAG_USERS,
  CLONE_USERS,
  DISABLE_USERS,
} = actionTypes;

export type Action =
  | GetUserCountAction
  | GetUserListAction
  | UpdateUserAction
  | GetCurrentUserAction
  | GetAllUserIdsAction
  | GetUserDetailsAction
  | ClearUserDetailsAction
  | BulkTagUsersAction
  | CloneUsersAction
  | DisableUsersAction;

const BULK_TAG_GENERIC_ERROR = [
  'There was an issue with the bulk tag request.',
  'Please check the selected emails and try again.',
].join(' ');

const DISABLE_USERS_GENERIC_ERROR = [
  'There was an issue with the disable users request.',
  'Please check the selected emails and try again.',
].join(' ');

type UserKeypath = ['users', 'index'] | ['users', 'autocomplete'];

export type GetUserCountAction = {
  type: typeof GET_USER_COUNT;
  data:
    | {
        status: typeof PENDING;
      }
    | {
        status: typeof SUCCESS;
        count: number;
      }
    | {
        status: typeof ERROR;
      };
};

export function requestUserCount(): GetUserCountAction {
  return {
    type: GET_USER_COUNT,
    data: { status: PENDING },
  };
}

export function receiveUserCount(count: number): GetUserCountAction {
  return {
    type: GET_USER_COUNT,
    data: {
      status: SUCCESS,
      count,
    },
  };
}

export function errorReceivingUserCount(): GetUserCountAction {
  return {
    type: GET_USER_COUNT,
    data: { status: ERROR },
  };
}

export function getUserCountAsync(
  filters: Partial<FiltersBySection['USER']>
): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestUserCount());

    UserService.getUserCount(filters).then(
      ({ count }) => {
        return dispatch(receiveUserCount(count));
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'getUserCountAsync',
        });
        return dispatch(errorReceivingUserCount());
      }
    );
  };
}

export type GetUserListAction = {
  type: typeof GET_USER_LIST;
  data: ListRequestData<
    UserService.ApiUser,
    {
      mergeResults: boolean;
    }
  > & {
    keypath: UserKeypath;
  };
};

export function requestUserList(keypath: UserKeypath): GetUserListAction {
  return {
    type: GET_USER_LIST,
    data: {
      status: PENDING,
      keypath,
    },
  };
}

export function receiveUserList(
  listResponse: ListResponse<UserService.ApiUser>,
  mergeResults: boolean,
  keypath: UserKeypath
): GetUserListAction {
  return {
    type: GET_USER_LIST,
    data: {
      status: SUCCESS,
      listResponse,
      mergeResults,
      keypath,
    },
  };
}

export function errorReceivingUserList(
  keypath: UserKeypath
): GetUserListAction {
  return {
    type: GET_USER_LIST,
    data: {
      status: ERROR,
      keypath,
    },
  };
}

function handleUserListAsyncAction(
  filters: DeepMerge<
    [
      Partial<FiltersBySection['USER']>,
      {
        expand?: string;
        size?: number | string;
      }
    ]
  >,
  mergeResults: boolean,
  keypath: UserKeypath
): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestUserList(keypath));

    UserService.getUserList(filters).then(
      ({ offset, users, size }) => {
        // type of "users" here is very much dependent on how “expand” came
        // through.
        return dispatch(
          receiveUserList(
            { offset, size, listData: users },
            mergeResults,
            keypath
          )
        );
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'handleUserListAsyncAction',
        });
        return dispatch(errorReceivingUserList(keypath));
      }
    );
  };
}

export function getUserListAsync(
  filters: Partial<FiltersBySection['USER']> = {}
) {
  const filterPayload = {
    ...filters,
    expand: 'emails_received,pii',
    size: 25,
  };

  return handleUserListAsyncAction(filterPayload, false, ['users', 'index']);
}

export function getUserAutocompleteListAsync(
  // It is unlikely that this has “expand” set.
  filters: Partial<FiltersBySection['USER']> & { id?: string } = {},
  mergeResults = false
) {
  return handleUserListAsyncAction(filters, mergeResults, [
    'users',
    'autocomplete',
  ]);
}

export type ModifyUserError = {
  error?: string;
  detail?: string;
  differences?: {
    [field: string]: string;
  };
};

export type UpdateUserAction = {
  type: typeof UPDATE_USER;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS; user: UserService.ApiUser }
    | { status: typeof ERROR; error: ModifyUserError };
};

export function startUpdatingUser(): UpdateUserAction {
  return {
    type: UPDATE_USER,
    data: {
      status: PENDING,
    },
  };
}

export function successfullyUpdateUser(
  user: UserService.ApiUser
): UpdateUserAction {
  return {
    type: UPDATE_USER,
    data: {
      status: SUCCESS,
      user,
    },
  };
}

export function errorUpdatingUser(error: ModifyUserError): UpdateUserAction {
  return {
    type: UPDATE_USER,
    data: {
      status: ERROR,
      error,
    },
  };
}

export function updateUserAsync(
  userId: number,
  updatedDetails: Partial<
    Omit<UserService.ApiUser & UserService.ExpandApiUserPii, 'id'>
  >,
  /**
   * Need to know the current user ID because if we’re updating that user then
   * we need to expand more data in order to save it in the `currentUser` state.
   */
  currentUserId: number,
  /**
   * Expand is only ever called with "related,pii". We also allow this other
   * option on the type for setting filters.expand below when currentUserId
   * lines up.
   */
  filters: {
    expand?: 'related,pii' | 'related,pii,elections.pii';
    dates?: string;
  } = {}
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    dispatch(startUpdatingUser());

    return new Promise((resolve) => {
      if (currentUserId === userId) {
        filters.expand = 'related,pii,elections.pii';
      }

      return UserService.updateUser(userId, updatedDetails, filters).then(
        ({ user }) => {
          dispatch(successfullyUpdateUser(user));
          resolve(getState());
        },
        (error) => {
          let ravenError = error;
          if (error && error.detail === 'User already exists') {
            ravenError = error.detail;
          }
          RavenService.captureException(ravenError, {
            type: 'user',
            method: 'updateUserAsync',
          });
          dispatch(errorUpdatingUser(error));
          resolve(getState());
        }
      );
    });
  };
}

// TODO(fiona): Remove the `CurrentUser` type in favor of `ApiCurrentUser`
export type CurrentUser = UserService.ApiCurrentUser;

export type GetCurrentUserAction = {
  type: typeof GET_CURRENT_USER;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        currentUser: CurrentUser;
        currentUserElectionId: number | null;
      }
    | { status: typeof ERROR };
};

export function requestCurrentUser(): GetCurrentUserAction {
  return {
    type: GET_CURRENT_USER,
    data: {
      status: PENDING,
    },
  };
}

export function receiveCurrentUser(
  currentUser: CurrentUser,
  currentUserElectionId: number | null
): GetCurrentUserAction {
  return {
    type: GET_CURRENT_USER,
    data: {
      status: SUCCESS,
      currentUser,
      currentUserElectionId,
    },
  };
}

export function errorReceivingCurrentUser(): GetCurrentUserAction {
  return {
    type: GET_CURRENT_USER,
    data: {
      status: ERROR,
    },
  };
}

export function getCurrentUserAsync(
  query: { dates?: string } = {}
): AppThunk<Promise<AppState>> {
  return async (dispatch, getState) => {
    dispatch(requestCurrentUser());

    try {
      const { user } = await UserService.getCurrentUser(query);

      dispatch(
        receiveCurrentUser(user, LocalStoreService.getCurrentUserElectionId())
      );
    } catch (error) {
      RavenService.captureException(error as any, {
        type: 'user',
        method: 'getCurrentUserAsync',
      });

      dispatch(errorReceivingCurrentUser());
    }

    return getState();
  };
}

export type GetAllUserIdsAction = {
  type: typeof GET_ALL_USER_IDS;
  data:
    | {
        status: typeof PENDING;
        userViewType: UserViewType;
      }
    | {
        status: typeof SUCCESS;
        userViewType: UserViewType;
        userIds: number[];
      }
    | {
        status: typeof ERROR;
        userViewType: UserViewType;
      };
};

export function requestAllUserIds(
  userViewType: UserViewType
): GetAllUserIdsAction {
  return {
    type: GET_ALL_USER_IDS,
    data: {
      status: PENDING,
      userViewType,
    },
  };
}

export function receiveAllUserIds(
  userIds: number[],
  userViewType: UserViewType
): GetAllUserIdsAction {
  return {
    type: GET_ALL_USER_IDS,
    data: {
      status: SUCCESS,
      userIds,
      userViewType,
    },
  };
}

export function errorReceivingAllUserIds(
  userViewType: UserViewType
): GetAllUserIdsAction {
  return {
    type: GET_ALL_USER_IDS,
    data: {
      status: ERROR,
      userViewType,
    },
  };
}

export function getAllUserIdsAsync(
  filters: Partial<FiltersBySection['USER']>,
  userViewType: UserViewType
): AppThunk<void> {
  return (dispatch) => {
    const filterPayload = {
      ...filters,
      size: 100000,
      offset: 0,
      only: 'id' as const,
      email__isnull: false,
    };

    dispatch(requestAllUserIds(userViewType));

    UserService.getUserList(filterPayload).then(
      ({ users }) => {
        // This code supports only=id returning just an array of numbers.
        // Unclear if that was a transition that never happened.
        //
        // Right now it returns objects with just an `id` property.
        const userIds = users.map((v) => (typeof v === 'object' ? v.id : v));

        dispatch(receiveAllUserIds(userIds, userViewType));
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'getAllUserIdsAsync',
        });
        return dispatch(errorReceivingAllUserIds(userViewType));
      }
    );
  };
}

/**
 * Wrapper function for {@link UserService.getUserDetails} that specifies a
 * value for the `Expands` type parameter so we can derive the type of the
 * `ApiUser` that it returns.
 */
const getUserDetails = (
  userId: number,
  params: { expand: string; dates: string }
) => UserService.getUserDetails<'pii,related'>(userId, params);

export type DetailedUser = Awaited<ReturnType<typeof getUserDetails>>['user'];

export type GetUserDetailsAction = {
  type: typeof GET_USER_DETAILS;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        // TODO(fiona): Sometimes this has related_emails on it, too
        userData: DetailedUser;
      }
    | { status: typeof ERROR };
};

export function requestUserDetails(): GetUserDetailsAction {
  return {
    type: GET_USER_DETAILS,
    data: {
      status: PENDING,
    },
  };
}

export function receiveUserDetails(
  userData: DetailedUser
): GetUserDetailsAction {
  return {
    type: GET_USER_DETAILS,
    data: {
      status: SUCCESS,
      userData,
    },
  };
}

export function errorReceivingUserDetails(): GetUserDetailsAction {
  return {
    type: GET_USER_DETAILS,
    data: {
      status: ERROR,
    },
  };
}

export function getUserDetailsAsync(
  userId: number,
  params: {
    expand: 'related,pii' | 'related,pii,emails_received';
    dates: string;
  }
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    dispatch(requestUserDetails());

    return new Promise((resolve) => {
      return getUserDetails(userId, params).then(
        ({ user }) => {
          dispatch(receiveUserDetails(user));
          resolve(getState());
        },
        (error) => {
          RavenService.captureException(error, {
            type: 'user',
            method: 'getUserDetailsAsync',
          });
          dispatch(errorReceivingUserDetails());
          resolve(getState());
        }
      );
    });
  };
}

export type ClearUserDetailsAction = {
  type: typeof CLEAR_USER_DETAILS;
};

export function clearUserDetails(): ClearUserDetailsAction {
  return {
    type: CLEAR_USER_DETAILS,
  };
}

export type BulkTagUsersAction = {
  type: typeof BULK_TAG_USERS;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS }
    | { status: typeof ERROR };
};

export function requestBulkTagging(): BulkTagUsersAction {
  return {
    type: BULK_TAG_USERS,
    data: { status: PENDING },
  };
}

export function bulkTagSuccess(): BulkTagUsersAction {
  return {
    type: BULK_TAG_USERS,
    data: { status: SUCCESS },
  };
}

export function bulkTagError(): BulkTagUsersAction {
  return {
    type: BULK_TAG_USERS,
    data: { status: ERROR },
  };
}

export function bulkTagUsers(
  userIds: number[],
  tags: string[]
): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestBulkTagging());
    UserService.bulkTagUsers(userIds, tags).then(
      () => {
        dispatch(bulkTagSuccess());
        const message =
          userIds.length === 1
            ? 'Successfully tagged 1 user'
            : `Successfully tagged ${userIds.length} users`;
        dispatch(showToast({ type: 'success', message }));
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'bulkTagUsers',
        });
        dispatch(bulkTagError());
        dispatch(showToast({ type: 'error', message: BULK_TAG_GENERIC_ERROR }));
      }
    );
  };
}

export type CloneUsersAction = {
  type: typeof CLONE_USERS;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS }
    | { status: typeof ERROR };
};

export function requestCloningUsers(): CloneUsersAction {
  return {
    type: CLONE_USERS,
    data: { status: PENDING },
  };
}

export function cloneUsersSuccess(): CloneUsersAction {
  return {
    type: CLONE_USERS,
    data: { status: SUCCESS },
  };
}

export function cloneUsersError(): CloneUsersAction {
  return {
    type: CLONE_USERS,
    data: { status: ERROR },
  };
}

export function cloneUsers(
  userIds: number[],
  cloneToElectionId: number
): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestCloningUsers());
    UserService.cloneUsers(userIds, cloneToElectionId).then(
      (userResults) => {
        dispatch(cloneUsersSuccess());

        const cloned = userResults.cloned_users;
        const already = userResults.already_existing_users;
        if (cloned.length > 0) {
          let message =
            cloned.length === 1
              ? 'Successfully added 1 user.'
              : `Successfully added ${cloned.length} users.`;
          if (already.length > 0) {
            message =
              already.length === 1
                ? `${message} 1 user was already part of the election.`
                : `${message} ${already.length} users were already part of the election.`;
          }
          dispatch(showToast({ type: 'success', message }));
        } else if (already.length > 0) {
          const message =
            already.length === 1
              ? `User was already part of the election.`
              : `Users were already part of the election.`;
          dispatch(showToast({ type: 'success', message }));
        } else {
          const message =
            'Something went wrong. Please reach out to lbj-backend@dnc.org for help.';
          dispatch(showToast({ type: 'error', message }));
        }
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'cloneUsers',
        });
        dispatch(cloneUsersError());
      }
    );
  };
}

export type DisableUsersAction = {
  type: typeof DISABLE_USERS;
  data:
    | { status: typeof PENDING }
    | { status: typeof SUCCESS }
    | { status: typeof ERROR };
};

export function requestDisablingUsers(): DisableUsersAction {
  return {
    type: DISABLE_USERS,
    data: { status: PENDING },
  };
}

export function disableUsersSuccess(): DisableUsersAction {
  return {
    type: DISABLE_USERS,
    data: { status: SUCCESS },
  };
}

export function disableUsersError(): DisableUsersAction {
  return {
    type: DISABLE_USERS,
    data: { status: ERROR },
  };
}

export function disableUsers(userIds: number[]): AppThunk<void> {
  return (dispatch) => {
    dispatch(requestDisablingUsers());
    UserService.disableUsers(userIds).then(
      () => {
        dispatch(disableUsersSuccess());
        const message =
          userIds.length === 1
            ? 'Successfully disabled 1 user'
            : `Successfully disabled ${userIds.length} users`;
        dispatch(showToast({ type: 'success', message }));
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'user',
          method: 'disableUsers',
        });
        dispatch(disableUsersError());
        dispatch(
          showToast({ type: 'error', message: DISABLE_USERS_GENERIC_ERROR })
        );
      }
    );
  };
}

export { showToast, dismissToast };
