import React from 'react';

export * from './DateBadge';
// TODO(fiona): Add these back in after we clear out __Rewire__, which is not
// playing well with the export *s.
//
// export * from './StateSelect';
// export * from './CountySelect';

/**
 * Big, bold title of a page.
 */
export const PageTitle: React.FunctionComponent<{
  className?: string | undefined;
}> = ({ className, children }) => (
  <h1
    className={
      'text-legacy-2xl font-bold leading-5' + (className ? ` ${className}` : '')
    }
  >
    {children}
  </h1>
);

/**
 * Big, bold title of a page. Same as {@link PageTitle} but without weird
 * leading, so preferred for new pages.
 */
export const PageTitle2: React.FunctionComponent<{
  className?: string | undefined;
  uiVersion?: 'legacy' | '2023';
}> = ({ className, uiVersion = 'legacy', children }) => (
  <h1
    className={
      'leading-0 font-bold' +
      (className ? ` ${className}` : '') +
      (uiVersion === '2023' ? ' text-2xl text-gray-700' : ' text-legacy-2xl')
    }
  >
    {children}
  </h1>
);

/**
 * Renders our common page header.
 */
export const PageHeader: React.FunctionComponent<{
  /** Title. If a string, automatically wraps in {@link PageTitle}. */
  title: string | React.ReactElement;
  className?: string;
}> = ({ title, className, children }) => (
  <header className={`lbj-header ${className ?? ''}`}>
    {typeof title === 'string' ? <PageTitle>{title}</PageTitle> : title}

    {children}
  </header>
);
