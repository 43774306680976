import * as Immutable from 'immutable';
import React from 'react';

import { UserListFilters } from '../../components/hooks/user-data';

import Autocomplete from '../../components/presentational/form/autocomplete';
import UserAutocomplete, {
  SelectableUser,
} from '../../components/presentational/form/user-autocomplete';
import { UserRole } from '../../constants';
import { LbjInputEvent } from '../../decorators/input-props';
import { ApiBoilerRoom, ApiElection } from '../../services/lbj-shared-service';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';
import RIT from '../../utils/render-if-truthy';
import { MapFromJs } from '../../utils/types';
import {
  roleMayAssignIssue,
  roleMayEditBoilerRoom,
  roleMayEditElection,
  roleMayEscalateIssue,
} from '../../utils/user/map-state-to-lbj-permissions';

export default class IssueAssignment extends React.Component<{
  currentUserRole: UserRole;
  onBoilerRoomSelect: (param: LbjInputEvent<string | null | undefined>) => void;
  onElectionSelect: (param: LbjInputEvent<string | null | undefined>) => void;
  onClaimIssue: () => void;
  onEscalateIssue: () => void;
  onInputChange: (param: LbjInputEvent<string | number | null>) => void;
  issueData: Pick<
    IssueInProgressPayload,
    'boiler_room' | 'issue_election_id' | 'owner'
  >;
  errors: { [key: string]: string[] };
  boilerRoomList: Immutable.List<MapFromJs<ApiBoilerRoom>>;
  electionList: Immutable.List<MapFromJs<ApiElection>>;
  preloadedUsers: SelectableUser[];
}> {
  getBoilerRoomChoices() {
    const { boilerRoomList } = this.props;
    const boilerRoomChoices = !boilerRoomList.isEmpty()
      ? boilerRoomList.reduce<{ [id: string]: string }>(
          (choices, boilerRoom) => {
            choices[boilerRoom.get('id')] = boilerRoom.get('name');
            return choices;
          },
          {}
        )
      : {};

    return boilerRoomChoices;
  }

  getElectionChoices() {
    const { electionList } = this.props;
    return !electionList.isEmpty()
      ? electionList.reduce<{ [id: string]: string }>((choices, election) => {
          choices[election.get('id')] = election.get('name');
          return choices;
        }, {})
      : {};
  }

  render() {
    const {
      currentUserRole,
      onInputChange,
      onBoilerRoomSelect,
      onElectionSelect,
      onClaimIssue,
      onEscalateIssue,
      issueData,
      errors,
      electionList,
      preloadedUsers,
    } = this.props;

    const canAssignIssue = roleMayAssignIssue(currentUserRole);
    const canEditBoilerRoom = roleMayEditBoilerRoom(currentUserRole);
    const canEditElection = roleMayEditElection(currentUserRole);
    const isEscalateVisible = roleMayEscalateIssue(currentUserRole);

    const userSearchFilters: UserListFilters = issueData.boiler_room
      ? {
          currentlyAssignedToBoilerRoom: issueData.boiler_room,
        }
      : {};
    const electionChoices = this.getElectionChoices();
    return (
      <fieldset className="details-fields">
        <legend>Assignment</legend>
        {RIT(canEditElection, () => {
          return (
            <div className="row">
              <div className={`col-medium-6 col-small-12`}>
                <Autocomplete
                  name="issue_election_id"
                  title="Election"
                  onChange={onElectionSelect}
                  choices={electionChoices}
                  value={issueData.issue_election_id || ''}
                  disabled={electionList.size < 2}
                  errors={errors['issue_election_id']}
                />
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className={`col-medium-6 col-small-12`}>
            <UserAutocomplete
              name="owner"
              title="Assignee"
              preloadedUsers={preloadedUsers}
              setUserId={(id) =>
                onInputChange({ target: { name: 'owner', value: id ?? null } })
              }
              userId={issueData.owner ? Number(issueData.owner) : null}
              disabled={!canAssignIssue}
              externalFilters={userSearchFilters}
              errors={errors['owner']}
            />
          </div>
          {RIT(canEditBoilerRoom, () => {
            return (
              <div className={`col-medium-6 col-small-12`}>
                <Autocomplete
                  type="select"
                  name="boiler_room"
                  title="Boiler room"
                  placeholder="Automatic"
                  choices={this.getBoilerRoomChoices()}
                  value={issueData.boiler_room}
                  onChange={onBoilerRoomSelect}
                  errors={errors['boiler_room']}
                />
              </div>
            );
          })}
        </div>

        {(isEscalateVisible || canAssignIssue) && (
          <>
            <hr />

            <div className="row" style={{ paddingBottom: '1em' }}>
              <div className={`col-medium-6 col-small-12`}>
                {RIT(canAssignIssue, () => {
                  return (
                    <div>
                      <p>
                        <button
                          className="issue-claim-button"
                          type="button"
                          onClick={() => onClaimIssue()}
                          disabled={!!issueData.owner}
                        >
                          Claim this issue
                        </button>
                      </p>
                    </div>
                  );
                })}
              </div>

              {RIT(isEscalateVisible, () => {
                return (
                  <div className={`col-medium-6 col-small-12`}>
                    <p>
                      <button
                        className="issue-escalate-button"
                        type="button"
                        onClick={() => onEscalateIssue()}
                      >
                        Escalate without reassigning
                      </button>
                    </p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </fieldset>
    );
  }
}
