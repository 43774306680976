import { Loader } from '@googlemaps/js-api-loader';

const LOADER = new Loader({
  // This key is public so it’s okay to check in.
  apiKey: 'AIzaSyAYqE-wnHszDg1Ud9UUJeex8E-BbKrs6Cg',
  version: 'weekly',
  language: 'en-US',
});

/**
 * Loads Google library code for making maps. Includes the maps and marker
 * libraries.
 *
 * It’s safe to call this repeatedly, the de-duping is handled under the hood.
 */
export async function loadGoogleMaps(): Promise<
  google.maps.CoreLibrary & google.maps.MapsLibrary & google.maps.MarkerLibrary
> {
  const coreP = LOADER.importLibrary('core');
  const mapsP = LOADER.importLibrary('maps');
  const markers = LOADER.importLibrary('marker');

  return {
    ...(await coreP),
    ...(await mapsP),
    ...(await markers),
  };
}

/**
 * Loads Google library code for the places service.
 *
 * It’s safe to call this repeatedly, the de-duping is handled under the hood.
 */
export async function loadGoogleMapsPlaces(): Promise<
  google.maps.CoreLibrary & google.maps.PlacesLibrary
> {
  const coreP = LOADER.importLibrary('core');
  const placesP = LOADER.importLibrary('places');

  return {
    ...(await coreP),
    ...(await placesP),
  };
}
