import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { analyticsColors } from '../../../constants';

export default class AnalyticsIncidents extends Component {
  static propTypes = {
    groupBy: PropTypes.string.isRequired,
    incidentsData: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onBarClick = this.onBarClick.bind(this);
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this);
  }

  onBarClick({ name }) {
    const { groupBy, onFilterChange } = this.props;

    if (groupBy === 'category') {
      onFilterChange([
        {
          name: 'category',
          value: name,
        },
        { name: 'group_by', value: 'sub_category' },
      ]);
    } else {
      onFilterChange([
        {
          name: 'category',
          value: '',
        },
        { name: 'group_by', value: 'category' },
      ]);
    }
  }

  renderCustomizedLabel(props) {
    const { x, y, width, height, value } = props;
    const padding = 10;

    return (
      <g>
        <Text
          angle={315}
          x={x + width / 2}
          y={y + height + padding}
          width={200}
          textAnchor="end"
          verticalAnchor="start"
        >
          {value}
        </Text>
      </g>
    );
  }

  render() {
    const { incidentsData } = this.props;

    return (
      <ResponsiveContainer width="80%" height="100%">
        <BarChart
          data={incidentsData.toJS()}
          margin={{ top: 20, right: 0, left: 0, bottom: 150 }}
        >
          <XAxis tick={false} tickLine={false} dataKey="name" />
          <YAxis allowDecimals={false} dataKey="value" />
          <Tooltip />
          <Bar dataKey="value" fill="#00aef3" onClick={this.onBarClick}>
            {incidentsData.toJS().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={analyticsColors[index % 12]} />
            ))}
            <LabelList dataKey="name" content={this.renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
