import type { FocusableElement } from '@react-types/shared';
import React from 'react';

/**
 * Component for a standard “label in a form describing an element”.
 *
 * Can either render a <label> or a <span>.
 *
 * Adds a red asterisk if the field is required.
 */
export const FormLabel: React.FunctionComponent<
  React.AllHTMLAttributes<FocusableElement> & {
    isRequired?: boolean | undefined;
    type?: 'label' | 'span';
  }
> = ({ isRequired, children, type: Element = 'span', ...props }) => (
  <Element {...props} className="m-0 text-sm font-bold text-gray-700">
    {children}
    {isRequired && (
      <span className="font-bold text-red-700" aria-hidden>
        &nbsp;*
      </span>
    )}
  </Element>
);
