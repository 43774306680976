import * as Immutable from 'immutable';

import { requestStatuses } from '../../constants';
import { MapFromJs } from '../types';

const { PENDING, SUCCESS, ERROR } = requestStatuses;

export type ListResponse<T> = {
  offset: number;
  size: number;
  listData: T[];
};

export type ListRequestError = {
  detail: string;
};

/**
 * Common data type for actions that supply lists of type `T`.
 *
 * The optional `S` type parameter can be used to add more fields to the
 * “success” case.
 */
export type ListRequestData<T, S = {}> =
  | {
      status: typeof PENDING;
    }
  | ({
      status: typeof SUCCESS;
      listResponse: ListResponse<T>;
    } & S)
  | {
      status: typeof ERROR;
      error?: ListRequestError;
    };

export type ListRequestRecordFields<T> = {
  requestIsPending: boolean;
  requestErred: boolean;
  error: Immutable.Map<string, string>;
  offset: undefined | number;
  size: undefined | number;
  listData: Immutable.List<T>;
};

export type ListRequestRecord<T> = Immutable.RecordOf<
  ListRequestRecordFields<T>
>;

export const DEFAULT_LIST_REQUEST_RECORD_FIELDS: Omit<
  ListRequestRecordFields<unknown>,
  'listData'
> = {
  requestIsPending: false,
  requestErred: false,
  error: Immutable.Map<string, string>(),
  offset: undefined as undefined | number,
  size: undefined as undefined | number,
};

/**
 * This makes a Record class for list requests of the given type.
 */
export function makeListRequestRecord<T extends { [key: string]: any }>() {
  return Immutable.Record<ListRequestRecordFields<MapFromJs<T>>>({
    ...DEFAULT_LIST_REQUEST_RECORD_FIELDS,
    listData: Immutable.List<MapFromJs<T>>(),
  });
}

/**
 * This makes an instantiation of a class from {@link makeListRequestRecord}.
 */
export function makeListRequestRecordObj<T extends { [key: string]: any }>() {
  return makeListRequestRecord<T>()();
}

/**
 * Creates an updater function to update an {@link Immutable.Record} that
 * matches {@link ListRequestRecord}, based on the {@link ListRequestData}.
 *
 * Preferrable to the previous `handleListRequestPlainJs` because it doesn’t
 * need a `keypath`, which makes it hard to statically type.
 */
export function updateListRequestRecord<T extends { [key: string]: any }>(
  data: ListRequestData<T>
) {
  return <R extends ListRequestRecordFields<MapFromJs<T>>>(
    record: Immutable.RecordOf<R>
  ): Immutable.RecordOf<R> => {
    // Because of the way the "R" generic type is wrapped by Record, we can’t
    // use `record.merge` in these cases and instead have to explicitly call
    // `set`.
    //
    // The use of `extends` that makes this dance necessary is so that the
    // record we update can extend `ListRequestRecordFields` with additional
    // fields (like `hourHeaders` in the dashboard reducer). (Unfortunately we
    // can’t use subclassing because the `class` syntax for making `Record`
    // types doesn’t allow for type parameters, which we need for `listData`.)
    switch (data.status) {
      case PENDING:
        return record
          .set('requestIsPending', true)
          .set('requestErred', false)
          .set('error', Immutable.Map({}));

      case SUCCESS:
        return record
          .set('offset', data.listResponse.offset)
          .set('size', data.listResponse.size)
          .set(
            'listData',
            Immutable.fromJS(data.listResponse.listData) as Immutable.List<
              MapFromJs<T>
            >
          )
          .set('requestIsPending', false)
          .set('requestErred', false)
          .set('error', Immutable.Map({}));

      case ERROR:
        return record
          .set('requestIsPending', false)
          .set('requestErred', true)
          .set(
            'error',
            data.error
              ? Immutable.Map({ detail: data.error.detail })
              : Immutable.Map({})
          );

      default:
        return record;
    }
  };
}

/**
 * Creates an updater function to update an {@link Immutable.Map} that matches
 * {@link ListRequestRecord}, based on the {@link ListRequestData}.
 *
 * Used for the few cases in the LBJ module where an {@link Immutable.Map} is
 * needed to both hold these field values and then also contains county keys
 * that have their own list records.
 *
 * This body is exactly the same as {@link updateListRequestRecord} but
 * unfortunately getting the types to line up so the same function could work
 * with both is not feasible.
 */
export function updateListRequestMap<T extends { [key: string]: any }>(
  data: ListRequestData<T>
) {
  return (map: Immutable.Map<string, any>): Immutable.Map<string, any> => {
    switch (data.status) {
      case PENDING:
        return map
          .set('requestIsPending', true)
          .set('requestErred', false)
          .set('error', Immutable.Map({}));

      case SUCCESS:
        return map
          .set('offset', data.listResponse.offset)
          .set('size', data.listResponse.size)
          .set(
            'listData',
            Immutable.fromJS(data.listResponse.listData) as Immutable.List<
              MapFromJs<T>
            >
          )
          .set('requestIsPending', false)
          .set('requestErred', false)
          .set('error', Immutable.Map({}));

      case ERROR:
        return map
          .set('requestIsPending', false)
          .set('requestErred', true)
          .set(
            'error',
            data.error
              ? Immutable.Map({ detail: data.error.detail })
              : Immutable.Map({})
          );

      default:
        return map;
    }
  };
}
