import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
  Store,
  Dispatch,
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import * as analyticsModule from './analytics';
import * as assignmentModule from './assignment';
import * as emailModule from './email';
import * as filtersModule from './filters';
import * as issueModule from './issue';
import * as lbjModule from './lbj';
import * as notificationsModule from './notifications';
import { ToastActions } from './toast';
import * as userModule from './user';

export type AppState = {
  assignment: assignmentModule.State;
  issue: issueModule.State;
  lbj: lbjModule.State;
  filters: filtersModule.State;
  email: emailModule.State;
  user: userModule.State;
  analytics: analyticsModule.State;
  notifications: notificationsModule.State;
};

// TODO(fiona): Add to this as more modules are converted to TS.
export type AppAction =
  | analyticsModule.Action
  | assignmentModule.Action
  | emailModule.Action
  | filtersModule.Action
  | issueModule.Action
  | lbjModule.Action
  | notificationsModule.Action
  | userModule.Action
  | ToastActions;

export type AppThunk<R> = ThunkAction<R, AppState, undefined, AppAction>;

// Use of "as any" in here to get over how our reducers are assuming their
// actions will have a data parameter.
const reducers = combineReducers<AppState>({
  assignment: assignmentModule.reducers as any,
  issue: issueModule.reducers as any,
  lbj: lbjModule.reducers as any,
  filters: filtersModule.reducers as any,
  email: emailModule.reducers as any,
  user: userModule.reducers as any,
  analytics: analyticsModule.reducers as any,
  notifications: notificationsModule.reducers as any,
});

// This needs to be a const array for TS to be ok with the `...middleware` with
// the current typing of `compose`.
const middleware = [
  applyMiddleware(thunk),

  (window as any).window.__REDUX_DEVTOOLS_EXTENSION__
    ? (window as any).window.__REDUX_DEVTOOLS_EXTENSION__()
    : (arg: any) => arg,

  (window as any).devToolsExtension
    ? (window as any).devToolsExtension()
    : (arg: any) => arg,
] as const;

/**
 * Type of `dispatch` that can accept redux-thunk values.
 */
export type AppDispatch = ThunkDispatch<AppState, undefined, AppAction>;

/**
 * The `@connect` decorator gives us a `dispatch` that might be undefined and is
 * not typed to allow redux-thunk. This is a hack that fixes that.
 */
export function toAppDispatch(dispatch: Dispatch<AppState> | undefined) {
  return dispatch as AppDispatch;
}

/**
 * Interface over Redux’s {@link Store} to use the `dispatch` type from
 * redux-thunx.
 */
export interface AppStore extends Store<AppState> {
  dispatch: AppDispatch;
}

export function createFluxStore() {
  return createStore<AppState>(
    reducers,
    compose(...middleware) as any
  ) as AppStore;
}

const fluxStore = createFluxStore();

export default fluxStore;
