import cx from 'classnames';
import * as Immutable from 'immutable';
import React from 'react';

import { lbjAppSections } from '../../../constants';
import { actionCreators } from '../../../modules/filters';
import { AppDispatch } from '../../../modules/flux-store';
import { ImmutableCurrentUserElection } from '../../../modules/user/reducers';
import { ExpandApiUserElectionsPii } from '../../../services/user-service';
import { MapFromJs } from '../../../utils/types';

const { ISSUE, ASSIGNMENT, CHECKINS, DASHBOARD, PROFILE, NOTIFICATIONS, USER } =
  lbjAppSections;

const filterAppSections = [
  ISSUE,
  ASSIGNMENT,
  CHECKINS,
  DASHBOARD,
  PROFILE,
  NOTIFICATIONS,
  USER,
];

export type ImmutableUserElection = MapFromJs<
  ExpandApiUserElectionsPii['user_elections'][number]
>;

export default class ElectionSwitcher extends React.Component<{
  dispatch: AppDispatch;
  currentUserElection: ImmutableCurrentUserElection | null;
  userElections: Immutable.List<ImmutableUserElection>;
  onChoseUserElection: (userElection: ImmutableUserElection) => void;
}> {
  onChoseUserElection(
    userElection: ImmutableUserElection,
    event?: React.MouseEvent
  ) {
    const { dispatch } = this.props;
    const { setFilters } = actionCreators;

    if (event) {
      event.preventDefault();
    }

    filterAppSections.forEach((section) => {
      dispatch(setFilters({}, section));
    });

    if (userElection.get('id') !== this.props.currentUserElection?.get('id')) {
      this.props.onChoseUserElection(userElection);
    }

    return false;
  }

  onChoseUserElectionOption(event: React.ChangeEvent<HTMLSelectElement>) {
    const chosenId = parseInt(event.target.value, 10);
    const userElection = this.props.userElections.find(
      (userElection) => userElection.get('id') === chosenId
    )!;

    this.onChoseUserElection(userElection);
  }

  renderSelect() {
    const selectedId = this.props.currentUserElection
      ? this.props.currentUserElection.get('id')
      : null;

    return (
      <select
        onChange={(e) => this.onChoseUserElectionOption(e)}
        value={selectedId}
      >
        {this.props.userElections.map((userElection) => {
          const userElectionId = userElection.get('id');
          return (
            <option key={userElectionId} value={userElectionId}>
              {userElection.getIn(['election', 'name']) as string}
            </option>
          );
        })}
      </select>
    );
  }

  renderHoverMenu() {
    return (
      <ul className="lbj-sub-nav not-small-only lbj-election-switcher">
        {this.props.userElections.map((userElection) => {
          return (
            <li key={userElection.get('id')}>
              <a
                href="/"
                onClick={(e) => this.onChoseUserElection(userElection, e)}
              >
                {userElection.getIn(['election', 'name']) as string}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const defaultTitle = 'LBJ';
    const navTitle = this.props.currentUserElection
      ? (this.props.currentUserElection.getIn(['election', 'name']) as string)
      : defaultTitle;

    const canSwitchElections = this.props.userElections.size > 1;

    return (
      <ul className="lbj-nav-list">
        <li>
          <span
            className={cx('lbj-nav-title', 'has-lbj-dropdown', {
              'emphasize-dropdown': canSwitchElections,
            })}
          >
            {canSwitchElections ? this.renderSelect() : <span>{navTitle}</span>}
          </span>

          {canSwitchElections && this.renderHoverMenu()}
        </li>
      </ul>
    );
  }
}
