import PropTypes from 'prop-types';
import React, { Component } from 'react';

const friendlyMap = {
  closed: 'Closed',
  full: 'Full',
  full_rover: 'Full (Rover)',
  none: 'None',
  partial: 'Partial',
};

const friendlyMapWithLabel = {
  closed: 'Closed',
  full: 'Full Coverage',
  full_rover: 'Full Coverage (Rover)',
  none: 'No Coverage',
  partial: 'Partial Coverage',
};

export default class AssignmentCoverage extends Component {
  static propTypes = {
    coverage: PropTypes.oneOf([
      'closed',
      'full',
      'full_rover',
      'none',
      'partial',
    ]).isRequired,
    includeLabel: PropTypes.bool,
  };

  render() {
    const { coverage } = this.props;

    return (
      <div className={`assignment-coverage assignment-coverage--${coverage}`}>
        {this.props.includeLabel
          ? friendlyMapWithLabel[coverage]
          : friendlyMap[coverage]}
      </div>
    );
  }
}
