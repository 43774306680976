import _analyticsColors from './analytics-colors';
import _assignmentAvailabilityTags from './assignment-availability-tags';
import _assignmentViewTypes from './assignment-view-types';
import _boilerRoomLevels from './boiler-room-levels';
import _callerRelationships from './caller-relationships';
import {
  WAIT_TIME_CHOICES as _WAIT_TIME_CHOICES,
  WAIT_TIME_DISPLAY as _WAIT_TIME_DISPLAY,
  WAIT_TIME_KEYS as _WAIT_TIME_KEYS,
  YES_NO_UNKNOWN_CHOICES,
  YES_NO_UNKNOWN_KEYS,
  YesNoUnknown,
  CheckinType,
} from './checkins';
import { METERS_TO_MILES_RATIO as _METERS_TO_MILES_RATIO } from './conversions';
import {
  GENERAL_ELECTION_TYPE as _GENERAL_ELECTION_TYPE,
  PERMANENT_ELECTION_TYPE as _PERMANENT_ELECTION_TYPE,
  PRIMARY_ELECTION_TYPE as _PRIMARY_ELECTION_TYPE,
} from './election-types';
import _EMAIL_TEMPLATES from './email-templates';
import _issueCategories from './issue-categories';
import _issueClasses from './issue-classes';
import _issueCreatePermissions from './issue-create-permissions';
import {
  _issueEditPermissions,
  _ownIssueEditPermissions,
} from './issue-edit-permissions';
import _issueFormTypes from './issue-form-types';
import _issueNotificationEvents, {
  ISSUE_NOTIF_TYPES as _ISSUE_NOTIF_TYPES,
} from './issue-notification-events';
import _issuePriorities from './issue-priorities';
import _issueQaChoices from './issue-qa-choices';
import _issueScopes from './issue-scopes';
import _issueSources from './issue-sources';
import _issueStatuses from './issue-statuses';
import _issueViewTypes from './issue-view-types';
import _lbjAppSections from './lbj-app-sections';
import _requestStatuses from './request-statuses';
import { STATE_BBOXES, bboxToLatLngBounds } from './state-boundaries';
import _states, {
  US_NATIONAL_STATE_CODE as _US_NATIONAL_STATE_CODE,
  State,
  isState,
  isCountySlug,
} from './states';
import _userNotExcludableTags from './user-not-excludable-tags';
import _userRoles, { ROLE_CODES as _ROLE_CODES } from './user-roles';
import _userViewTypes from './user-view-types';
import { _EDAY_ROLES, _EVOTE_ROLES } from './volunteer-role-tags';
import _voteStatuses from './vote-statuses';

export const analyticsColors = _analyticsColors;
export const METERS_TO_MILES_RATIO = _METERS_TO_MILES_RATIO;
export const US_NATIONAL_STATE_CODE = _US_NATIONAL_STATE_CODE;

export const GENERAL_ELECTION_TYPE = _GENERAL_ELECTION_TYPE;
export const PERMANENT_ELECTION_TYPE = _PERMANENT_ELECTION_TYPE;
export const PRIMARY_ELECTION_TYPE = _PRIMARY_ELECTION_TYPE;
export type ElectionType =
  | typeof GENERAL_ELECTION_TYPE
  | typeof PERMANENT_ELECTION_TYPE
  | typeof PRIMARY_ELECTION_TYPE;

export const EDAY_ROLES = _EDAY_ROLES;
export const EVOTE_ROLES = _EVOTE_ROLES;

export const states = _states;
export { isState, isCountySlug };
export type { State };

export { STATE_BBOXES, bboxToLatLngBounds };

export const requestStatuses = _requestStatuses;
export type RequestStatus = keyof typeof requestStatuses;

export const assignmentAvailabilityTags = _assignmentAvailabilityTags;
export type assignmentAvailabilityTag =
  typeof assignmentAvailabilityTags[number];

export const assignmentViewTypes = _assignmentViewTypes;
export type AssignmentViewType = keyof typeof assignmentViewTypes;

export const issueScopes = _issueScopes;
export type IssueScope = keyof typeof issueScopes;

export const issuePriorities = _issuePriorities;
export type IssuePriority = keyof typeof issuePriorities;

export const voteStatuses = _voteStatuses;
export type VoteStatus = keyof typeof voteStatuses;

export const issueStatuses = _issueStatuses;
export type IssueStatus = keyof typeof issueStatuses;

export const issueCategories = _issueCategories;
export type InquiryIssueCategories = keyof typeof issueCategories['inquiry'];
export type IncidentIssueCategories = keyof typeof issueCategories['incident'];

export const issueViewTypes = _issueViewTypes;
export type IssueViewType = keyof typeof issueViewTypes;

export const callerRelationships = _callerRelationships;
export type CallerRelationship = keyof typeof callerRelationships;

export const issueClasses = _issueClasses;
export type IssueClass = keyof typeof issueClasses;

export const issueSources = _issueSources;
export type IssueSource = keyof typeof issueSources;

export const userNotExcludableTags = _userNotExcludableTags;

export const userRoles = _userRoles;
export type UserRole = keyof typeof userRoles;

export const boilerRoomLevels = _boilerRoomLevels;
export type BoilerRoomLevel = typeof boilerRoomLevels[number];

export const lbjAppSections = _lbjAppSections;
export type LbjAppSection = keyof typeof lbjAppSections;

export const issueCreatePermissions = _issueCreatePermissions;
export const issueEditPermissions = _issueEditPermissions;
export const ownIssueEditPermissions = _ownIssueEditPermissions;

export const userViewTypes = _userViewTypes;
export type UserViewType = keyof typeof userViewTypes;

export const WAIT_TIME_CHOICES = _WAIT_TIME_CHOICES;
export const WAIT_TIME_DISPLAY = _WAIT_TIME_DISPLAY;
export const WAIT_TIME_KEYS = _WAIT_TIME_KEYS;
export type WaitTime = keyof typeof WAIT_TIME_KEYS;

export { YES_NO_UNKNOWN_CHOICES, YES_NO_UNKNOWN_KEYS };
export type { CheckinType, YesNoUnknown };

export const issueNotificationEvents = _issueNotificationEvents;
export const FILTERS_EXISTING = 'existing';
export const ROLE_CODES = _ROLE_CODES;
export const EMAIL_TEMPLATES = _EMAIL_TEMPLATES;
export const ISSUE_NOTIF_TYPES = _ISSUE_NOTIF_TYPES;

export const issueFormTypes = _issueFormTypes;
export const issueQaChoices = _issueQaChoices;

export type IssuePermission =
  | 'observer'
  | 'voter'
  | 'location'
  | 'description'
  | 'details'
  | 'comments'
  | 'status';

export default {
  analyticsColors,
  US_NATIONAL_STATE_CODE,
  states,
  requestStatuses,
  assignmentAvailabilityTags,
  assignmentViewTypes,
  issueScopes,
  issuePriorities,
  voteStatuses,
  issueStatuses,
  issueCategories,
  issueViewTypes,
  issueClasses,
  issueSources,
  userNotExcludableTags,
  userRoles,
  boilerRoomLevels,
  lbjAppSections,
  issueCreatePermissions,
  issueEditPermissions,
  userViewTypes,
  WAIT_TIME_CHOICES,
  WAIT_TIME_DISPLAY,
  WAIT_TIME_KEYS,
  ROLE_CODES,
  issueNotificationEvents,
  EMAIL_TEMPLATES,
  ISSUE_NOTIF_TYPES,
  issueFormTypes,
  issueQaChoices,
};
