import * as Immutable from 'immutable';

import { requestStatuses } from '../../constants';
import {
  ApiAssignmentCrudResponse,
  ApiAssignmentType,
  ApiBoardOfElectionsWithAssignments,
  ApiBoilerRoomWithAssignments,
  ApiLocationWithAssignments,
  ApiPrecinctWithAssignments,
  ApiUserWithAssignments,
} from '../../services/assignment-service';
import {
  makeListRequestRecordObj,
  updateListRequestRecord,
} from '../../utils/lbj/list-request-state-handler';
import { assertUnreachable, MapFromJs, mapOf } from '../../utils/types';
import { AppAction } from '../flux-store';
import {
  DISMISS_TOAST,
  ShowToastAction,
  SHOW_TOAST,
  ToastRecord,
} from '../toast';

import {
  AssignmentCrudData,
  AssignmentParentResource,
  GetAssignmentListAction,
  OpenEditorPanelAction,
  RequestAssignmentCsvAction,
  SaveAssignmentDataAction,
} from './action-creators';

import actionTypes from './action-types';

const { SUCCESS, PENDING, ERROR } = requestStatuses;

function handleOpenAssignmentEditor(
  state: AssignmentState,
  data: OpenEditorPanelAction['data']
): AssignmentState {
  const editFields = data.parentResource.assignment || {
    shift_date: data.shiftDate,
    start_time: data.startTime,
    end_time: data.endTime,
  };

  return state
    .set(
      'assignmentEditor',
      state.assignmentEditor
        .set('isOpen', true)
        .merge({
          ...data,
          parentResource: mapOf(data.parentResource),
        })
        .set(
          'updatedFields',
          Immutable.fromJS(editFields) as Immutable.Map<string, any>
        )
    )
    .set(
      'updatingAssignment',
      state.updatingAssignment.set('requestErred', false)
    );
}

function handleSaveAssignmentData(
  state: AssignmentState,
  data: SaveAssignmentDataAction['data']
): AssignmentState {
  let assignmentEditor = state.assignmentEditor;

  if ('updatedFields' in data) {
    assignmentEditor = assignmentEditor.set(
      'updatedFields',
      Immutable.fromJS(data.updatedFields) as Immutable.Map<string, any>
    );
  }

  if ('assignmentType' in data) {
    assignmentEditor = assignmentEditor.set(
      'assignmentType',
      data.assignmentType
    );
  }

  return state.set('assignmentEditor', assignmentEditor);
}

function handleCloseAssignmentEditor(state: AssignmentState): AssignmentState {
  return state.set('assignmentEditor', initialState.get('assignmentEditor'));
}

/**
 * Creates an updater function from a new element that, when applied to a list,
 * replaces an element in that list if its `id` matches our new element’s.
 *
 * Calls {@link Immutable.fromJS} on the updated entity before assigning it.
 */
function updateListData<T extends { id: any }>(updatedEntity: T) {
  return (listData: Immutable.List<MapFromJs<T>>) =>
    listData.map((el) =>
      el.get('id') === updatedEntity.id
        ? (Immutable.fromJS(updatedEntity) as typeof el)
        : el
    );
}

/**
 * Given the results of an assignment CRUD operation, updates the appropriate
 * saved list to include the modified entity.
 *
 * _E.g._ if a new assignment was added to a precinct, updates our list of
 * precincts to include a precinct object that includes that new assignment.
 */
function handleAssignmentListUpdate(
  state: AssignmentState,
  data: AssignmentCrudData & { status: typeof SUCCESS }
): AssignmentState {
  let assignmentIndex = state.assignmentIndex;

  // We handle all of the cases explicitly so we don’t have to handwave the
  // types.
  //
  // The `in` checks are here because `data` is not set up to discriminate among
  // listViewType values.
  switch (data.listViewType) {
    case 'BOARDS_OF_ELECTIONS':
      if ('board_of_elections' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'BOARDS_OF_ELECTIONS',
          assignmentIndex.BOARDS_OF_ELECTIONS.update(
            'listData',
            updateListData(data.updatedEntity.board_of_elections)
          )
        );
      }
      break;

    case 'BOILER_ROOMS':
      if ('boiler_room' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'BOILER_ROOMS',
          assignmentIndex.BOILER_ROOMS.update(
            'listData',
            updateListData(data.updatedEntity.boiler_room)
          )
        );
      }
      break;

    case 'HOTLINES':
      // Hotlines and boiler rooms share a property and type.
      if ('boiler_room' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'HOTLINES',
          assignmentIndex.HOTLINES.update(
            'listData',
            updateListData(data.updatedEntity.boiler_room)
          )
        );
      }
      break;

    case 'LOCATIONS':
      if ('location' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'LOCATIONS',
          assignmentIndex.LOCATIONS.update(
            'listData',
            updateListData(data.updatedEntity.location)
          )
        );
      }
      break;

    case 'PEOPLE':
      if ('user' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'PEOPLE',
          assignmentIndex.PEOPLE.update(
            'listData',
            // The "as" here removes the "WithApiUserCurrentBoilerRooms" from
            // the type
            updateListData(data.updatedEntity.user as ApiUserWithAssignments)
          )
        );
      }
      break;

    case 'PRECINCTS':
      if ('precinct' in data.updatedEntity) {
        assignmentIndex = assignmentIndex.set(
          'PRECINCTS',
          assignmentIndex.PRECINCTS.update(
            'listData',
            updateListData(data.updatedEntity.precinct)
          )
        );
      }
      break;

    default:
      assertUnreachable(data.listViewType);
  }

  return state.set('assignmentIndex', assignmentIndex);
}

/**
 * Type of the `assignment` value from the updatedEntity in a CRUD datum, as run
 * through Immutable.
 */
type ImmutableCrudAssignment = MapFromJs<
  NonNullable<ApiAssignmentCrudResponse['assignment']>
>;

/**
 * Called when an assignment moves from one location to another. Unclear how
 * this case is different from what’s handled by `handleAssignmentListUpdate`.
 *
 * Updates the location in `listData` to include this assignment in
 * `assignment_set`.
 */
function handleNewLocationAssignmentSet(
  state: AssignmentState,
  assignment: ImmutableCrudAssignment
): AssignmentState {
  const updatedListData = state.assignmentIndex.LOCATIONS.listData.map(
    (location) =>
      location.get('id') === assignment.getIn(['location', 'id'])
        ? updateLocationWithAssignment(location, assignment)
        : location
  );

  return state.update('assignmentIndex', (assignmentIndex) =>
    assignmentIndex.update('LOCATIONS', (locations) =>
      locations.set('listData', updatedListData)
    )
  );
}

/**
 * Adds the given assigment to the location’s `assignment_set` map.
 */
function updateLocationWithAssignment(
  location: MapFromJs<ApiLocationWithAssignments>,
  assignment: ImmutableCrudAssignment
) {
  return location.update(
    'assignment_set',
    // assignment_set is a map of assignment id -> assignment
    (assignmentSet: Immutable.Map<number, ImmutableCrudAssignment>) =>
      assignmentSet.set(assignment.getIn(['id']) as number, assignment)
  );
}

/**
 * Called when an assignment is moved from one precinct to another. Unclear what
 * this updates that `handleAssignmentListUpdate` doesn’t.
 */
function handleNewPrecinctAssignmentSet(
  state: AssignmentState,
  assignment: ImmutableCrudAssignment
): AssignmentState {
  const updatedListData = state.assignmentIndex.PRECINCTS.listData.map(
    (precinct) =>
      precinct.get('id') === assignment.getIn(['precinct', 'id'])
        ? updateExistingPrecinctWithAssignment(precinct, assignment)
        : precinct
  );

  return state.update('assignmentIndex', (assignmentIndex) =>
    assignmentIndex.update('PRECINCTS', (precincts) =>
      precincts.set('listData', updatedListData)
    )
  );
}

/**
 * The type of a single record in a precinct’s `related` array. Has a location
 * and a list of assignments.
 */
type ImmutablePrecinctRelated = Immutable.List<
  MapFromJs<ApiPrecinctWithAssignments['related'][number]>
>;

/**
 * The `related` property of an {@link ApiPrecinctWithAssignments} is a list of
 * objects that include a location and a list of assignments for that location.
 *
 * Given a new assignment, we need to update that `related` list.
 *
 * If the assignment refers to a location that we don’t have any assignments for
 * yet, this appends a new record to the `related` list.
 *
 * If there already is a record for the assignment’s location, this appends the
 * assignment to the location’s list.
 *
 * Note: this code does not handle the case where the assignment is already in
 * the list. It appends it again. That may be a bug.
 */
function updateExistingPrecinctWithAssignment(
  precinct: MapFromJs<ApiPrecinctWithAssignments>,
  assignment: ImmutableCrudAssignment
) {
  return precinct.update(
    'related',
    (related: ImmutablePrecinctRelated): ImmutablePrecinctRelated => {
      const existingRelatedEntryForLocationIdx = related.findIndex(
        (entry) =>
          entry.getIn(['location', 'id']) ===
          assignment.getIn(['location', 'id'])
      );

      if (existingRelatedEntryForLocationIdx >= 0) {
        return related.update(existingRelatedEntryForLocationIdx, (entry) =>
          // ! because we just found this index, so we know it’s valid.
          entry!.update('assignments', (assignments) =>
            assignments.push(assignment)
          )
        );
      } else {
        return related.push(
          mapOf({
            // "as any" since we know this assignment has a location,
            // since it’s for a precinct.
            location: assignment.getIn(['location']),
            assignments: Immutable.List([assignment]),
          })
        );
      }
    }
  );
}

function handleAssignmentCRUD(
  state: AssignmentState,
  data: AssignmentCrudData
): AssignmentState {
  switch (data.status) {
    case PENDING:
      return state.update('updatingAssignment', (r) =>
        r.clear().merge({
          requestIsPending: true,
          requestErred: false,
          error: {},
        })
      );

    case SUCCESS: {
      let updatedState = handleAssignmentListUpdate(state, data).update(
        'updatingAssignment',
        (r) =>
          r.clear().merge({
            requestIsPending: false,
            requestErred: false,
            error: {},
          })
      );

      const listViewType = data.listViewType;

      // We may get back an updated user, e.g. one that has a new list of
      // assignments
      const updatedUser =
        'user' in data.updatedEntity && data.updatedEntity.user;

      updatedState = updatedState.update(
        'assignmentEditor',
        (assignmentEditor) =>
          assignmentEditor.update('parentResource', (parentResource) =>
            parentResource.has('user')
              ? parentResource.set('user', Immutable.fromJS(updatedUser))
              : parentResource
          )
      );

      // This should exist unless we’re deleting.
      if (data.updatedEntity.assignment) {
        const immutableAssignment = Immutable.fromJS(
          data.updatedEntity.assignment
        ) as ImmutableCrudAssignment;

        updatedState = updatedState.update(
          'assignmentEditor',
          (assignmentEditor) =>
            assignmentEditor
              .update('parentResource', (parentResource) =>
                parentResource.set('assignment', immutableAssignment)
              )
              .set('updatedFields', immutableAssignment)
        );

        // update location information if the location has changed and we're in
        // the LOCATIONS or PRECINCT view
        if (
          (listViewType === 'LOCATIONS' || listViewType === 'PRECINCTS') &&
          immutableAssignment.getIn(['location', 'id']) !==
            state.assignmentEditor.parentResource.getIn(['location', 'id'])
        ) {
          updatedState = updatedState.update(
            'assignmentEditor',
            (assignmentEditor) =>
              assignmentEditor.update('parentResource', (parentResource) =>
                parentResource.set(
                  'location',
                  // we know there’s a location key because this is location or
                  // precinct
                  immutableAssignment.getIn(['location'])
                )
              )
          );

          updatedState = handleNewLocationAssignmentSet(
            updatedState,
            immutableAssignment
          );
        }

        // do the same for precinct information
        if (
          (listViewType === 'LOCATIONS' || listViewType === 'PRECINCTS') &&
          immutableAssignment.getIn(['precinct', 'id']) !==
            state.assignmentEditor.parentResource.getIn(['precinct', 'id'])
        ) {
          updatedState = updatedState.update(
            'assignmentEditor',
            (assignmentEditor) =>
              assignmentEditor.update('parentResource', (parentResource) =>
                parentResource.set(
                  'precinct',
                  immutableAssignment.getIn(['precinct'])
                )
              )
          );

          updatedState = handleNewPrecinctAssignmentSet(
            updatedState,
            immutableAssignment
          );
        }

        return updatedState;
      } else {
        // This is the case where there’s no assignment, meaning it has been deleted.
        return updatedState.update('assignmentEditor', (assignmentEditor) =>
          assignmentEditor
            .update('parentResource', (parentResource) =>
              parentResource.delete('assignment')
            )
            .update('updatedFields', (updatedFields) =>
              // When the assignment is deleted, replace all updated fields with
              // just the existing shift date.
              Immutable.Map({ shift_date: updatedFields.get('shift_date') })
            )
        );
      }
    }

    case ERROR:
      return state.update('updatingAssignment', (r) =>
        r.clear().merge({
          requestIsPending: false,
          requestErred: true,
          error: data.error,
        })
      );
  }
}

function requestAssignmentExport(
  state: AssignmentState,
  data: RequestAssignmentCsvAction['data']
): AssignmentState {
  return state.update('assignmentExport', (r) =>
    r.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,
      error: data.status === ERROR ? data.error : {},
    })
  );
}

function handleShowToast(
  state: AssignmentState,
  data: ShowToastAction['data']
): AssignmentState {
  return state.set('toastData', new ToastRecord(data));
}

function handleDismissToast(state: AssignmentState): AssignmentState {
  return state.set('toastData', new ToastRecord());
}

function handleGetAssignmentList(
  state: AssignmentState,
  action: GetAssignmentListAction
): AssignmentState {
  return state.update('assignmentIndex', (assignmentIndex) => {
    switch (action.data.listViewType) {
      case 'BOARDS_OF_ELECTIONS':
        return assignmentIndex.update(
          'BOARDS_OF_ELECTIONS',
          updateListRequestRecord(action.data)
        );

      case 'BOILER_ROOMS':
        return assignmentIndex.update(
          'BOILER_ROOMS',
          updateListRequestRecord(action.data)
        );

      case 'HOTLINES':
        return assignmentIndex.update(
          'HOTLINES',
          updateListRequestRecord(action.data)
        );

      case 'LOCATIONS':
        return assignmentIndex.update(
          'LOCATIONS',
          updateListRequestRecord(action.data)
        );

      case 'PEOPLE':
        return assignmentIndex.update(
          'PEOPLE',
          updateListRequestRecord(action.data)
        );

      case 'PRECINCTS':
        return assignmentIndex.update(
          'PRECINCTS',
          updateListRequestRecord(action.data)
        );

      default:
        assertUnreachable(action.data);
    }
  });
}

export class AssignmentState extends Immutable.Record(
  {
    assignmentIndex: Immutable.Record({
      PRECINCTS: makeListRequestRecordObj<ApiPrecinctWithAssignments>(),
      LOCATIONS: makeListRequestRecordObj<ApiLocationWithAssignments>(),
      PEOPLE: makeListRequestRecordObj<ApiUserWithAssignments>(),
      BOILER_ROOMS: makeListRequestRecordObj<ApiBoilerRoomWithAssignments>(),
      BOARDS_OF_ELECTIONS:
        makeListRequestRecordObj<ApiBoardOfElectionsWithAssignments>(),
      HOTLINES: makeListRequestRecordObj<ApiBoilerRoomWithAssignments>(),
    })(),

    assignmentEditor: Immutable.Record({
      isOpen: false,
      isAssigning: false,
      isEditing: false,

      assignmentType: undefined as ApiAssignmentType | undefined,
      parentResource: Immutable.Map<keyof AssignmentParentResource, any>(),

      updatedFields: Immutable.Map(),

      // The code merges these in, but it’s unclear if they’re read off of here.
      shiftDate: undefined as string | undefined,
      startTime: undefined as string | undefined,
      endTime: undefined as string | undefined,
    })(),

    updatingAssignment: Immutable.Record({
      requestIsPending: false,
      requestErred: false,
      // Purposefully not an Immutable type
      error: {} as unknown,
    })(),

    assignmentExport: Immutable.Record({
      requestIsPending: false,
      requestErred: false,
      // Purposefully not an Immutable type
      error: {} as unknown,
      csvData: '',
    })(),

    toastData: new ToastRecord(),
  },
  'AssignmentState'
) {}

export const initialState = new AssignmentState({});

export default function assignment(
  state: AssignmentState = initialState,
  action: AppAction
): AssignmentState {
  const {
    GET_ASSIGNMENT_LIST,
    CREATE_ASSIGNMENT,
    UPDATE_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    OPEN_EDITOR_PANEL,
    SAVE_ASSIGNMENT_DATA,
    CLOSE_EDITOR_PANEL,
    REQUEST_ASSIGNMENT_CSV,
  } = actionTypes;

  switch (action.type) {
    case GET_ASSIGNMENT_LIST:
      return handleGetAssignmentList(
        handleCloseAssignmentEditor(state),
        action
      );

    case CREATE_ASSIGNMENT:
      return handleAssignmentCRUD(state, action.data);

    case UPDATE_ASSIGNMENT:
      return handleAssignmentCRUD(state, action.data);

    case DELETE_ASSIGNMENT:
      return handleAssignmentCRUD(state, action.data);

    case OPEN_EDITOR_PANEL:
      return handleOpenAssignmentEditor(state, action.data);

    case SAVE_ASSIGNMENT_DATA:
      return handleSaveAssignmentData(state, action.data);

    case CLOSE_EDITOR_PANEL:
      return handleCloseAssignmentEditor(state);

    case REQUEST_ASSIGNMENT_CSV:
      return requestAssignmentExport(state, action.data);

    case SHOW_TOAST:
      return handleShowToast(state, action.data);

    case DISMISS_TOAST:
      return handleDismissToast(state);

    default:
      return state;
  }
}
