import { IssueClass, State } from '../../constants';
import { ApiIssue } from '../../services/issue-service';

const REQUIRED_FIELDS_BY_FORM_TYPE_AND_ISSUE_CLASS = {
  hotline: {
    inquiry: [
      'type',
      'category',
      'sub_category',
      'caller_relationship',
      'state',
    ],
    incident: [
      'type',
      'category',
      'sub_category',
      'caller_relationship',
      'state',
      'vote_status',
      'scope',
      'county',
    ],
  },
  poll_observer: {
    inquiry: ['type', 'category', 'sub_category', 'state', 'county'],
    incident: [
      'type',
      'category',
      'sub_category',
      'state',
      'vote_status',
      'scope',
      'county',
      'location',
    ],
  },
} as const;

export function getRequiredFields(
  formType: 'poll_observer' | 'hotline',
  issueType: IssueClass | '' | null,
  numCategories: number
): Array<keyof ApiIssue> {
  if (!issueType) {
    return ['type'];
  }

  const requiredFields: (keyof ApiIssue)[] = [
    ...REQUIRED_FIELDS_BY_FORM_TYPE_AND_ISSUE_CLASS[formType][issueType],
  ];

  if (numCategories > 1) {
    requiredFields.push('category_2', 'sub_category_2');
  }

  if (numCategories > 2) {
    requiredFields.push('category_3', 'sub_category_3');
  }

  return requiredFields;
}

type ACTIVE_ADDRESS_FIELDS =
  | 'street_number'
  | 'route'
  | 'locality'
  | 'sublocality'
  | 'administrative_area_level_1'
  | 'postal_code';

// to be confirmed:
// long/short name preference for street names
// and do we want to grab zip + 4 if it exists?
const MAP_ADDRESS_COMPONENT_TO_NAME_FORMAT: {
  [field in ACTIVE_ADDRESS_FIELDS]: 'short_name' | 'long_name';
} = {
  /* 
    Notes on the differences between short/long names by field:
    - street number
      - short_name and long_name are the same
    - route
      - short_name will abbreviate pre/post direction and street type
        ex: 'N Main St', 'Logan Blvd'
      - long_name does not abbreviate anything
        ex: 'North Main Street', 'Logan Boulevard'
    - locality
      - short_name and long_name are the same
        ex: short_name: 'Anchorage', long_name: 'Anchorage'
    - sublocality
      - short_name and long_name are the same
        ex: short_name: 'Brooklyn', long_name: 'Brooklyn'
    - administrative_area_level_1, aka state
      - short_name is state code
        ex: short_name: 'CO'
      - long_name is state name
        ex: long_name: 'Colorado'
    - postal code
      - short_name and long_name are the same
  */
  street_number: 'long_name',
  route: 'long_name',
  locality: 'long_name',
  sublocality: 'long_name',
  administrative_area_level_1: 'short_name',
  postal_code: 'short_name',
};

const filterAddressComponent = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  desiredType: ACTIVE_ADDRESS_FIELDS
) => {
  // Helper method for addrComponentsToStructuredAddrFields()
  // Maps a GeocoderAddressComponent to expected voter_*
  // structured address components.
  for (const component of addressComponents) {
    if (component.types.includes(desiredType)) {
      return component[MAP_ADDRESS_COMPONENT_TO_NAME_FORMAT[desiredType]];
    }
  }
  return '';
};

export const ADDRESS_COMPONENT_TYPE = 'google_v1';

export function addrComponentsToStructuredAddrFields(
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  /* 
   The addressComponents here are an array of
   GeocoderAddressComponents.

   GeocoderAddressComponents are shaped like so:
    {
      "long_name": string,
      "short_name": string,
      "types": [...]
    }

    In order to get from GeocoderAddressComponent to expected
    LBJ issue.voter_* structured address components, we need to
    both find the correct type, by searching through the
    component's types AND select the correct long or short form
    of that component.
  */

  const street_address =
    filterAddressComponent(addressComponents, 'street_number') +
    ' ' +
    filterAddressComponent(addressComponents, 'route');
  const locality = filterAddressComponent(addressComponents, 'locality');
  /* Why are we including locality and sublocality?
    Generally speaking, the locality field should represent the city
    an address is located in. However, when testing locally,
    I (Alex) observed that addresses in a place like Brooklyn,
    for example, won't return a locality. The sublocality
    is necessary here to populate an address as expected.
  */
  const city =
    locality !== ''
      ? locality
      : filterAddressComponent(addressComponents, 'sublocality');
  const stateCode = filterAddressComponent(
    addressComponents,
    'administrative_area_level_1'
  );

  const zipCode = filterAddressComponent(addressComponents, 'postal_code');
  return { street_address, city, stateCode, zipCode };
}

export function getAddressCoordinates(
  geometry: google.maps.places.PlaceGeometry | undefined
) {
  const latitude = geometry?.location?.lat();
  const longitude = geometry?.location?.lng();
  if (latitude && longitude) {
    return [longitude, latitude];
  } else return null;
}

// reusable method to filter out out-of-state suggestions
// by way of secondary_text city, state, country string
export function filterSuggestionData(
  suggestions: google.maps.places.AutocompletePrediction[],
  stateForBounds: State
) {
  /* 
      The shape of an autofill suggestion from Google:
    {
        "description": string,
          // description format: full street address, city, state, country
        "matched_substrings": [array indicating which substrings of the description matched the search term],
        "place_id": string,
        "reference": string,
        "structured_formatting": {
            "main_text": string 
              //main text format is the full street address : street number pre-direction street name post-direction street type
            "main_text_matched_substrings": [array indicating which substrings of the description matched the search term]
            "secondary_text": string
              // secondary text format: city, state, country
        },
        "terms": [array of 'prediction term' objects: {offset: number, value: string}],
          // the prediction term objects to the human eye appear to be address components, without an identifier of their type
        "types": [array of address types, ex: 'street_address', 'geocode']
    },

    In order to display _only_ relevant suggestions, specifically only in-state addresses,
    we post-filter the suggestions by state.
    
    The stateForBounds argument is the US state whose lat/lon boundaries are used to bias Google's
    response.
  */

  // filter out out-of-state results
  // by way of secondary_text city, state, country string
  return suggestions.filter((suggestion) =>
    suggestion.structured_formatting.secondary_text.includes(
      `, ${stateForBounds}`
    )
  );
}

// reusable method to format address suggestions for display
export function formatAddressSuggestion(
  suggestion: google.maps.places.AutocompletePrediction
) {
  const {
    structured_formatting: { main_text, secondary_text },
  } = suggestion;
  /*
    Format of structured_formatting main_text and secondary_text
    "structured_formatting": {
        "main_text": string 
          //main text format is the full street address : street number pre-direction street name post-direction street type
        "secondary_text": string
          // secondary text format: city, state, country
    },
  */
  const streetAddress = main_text;
  // no need to display the country, it will always be USA
  const cityState = secondary_text.split(', USA')[0];

  return `${streetAddress} ${cityState}`;
}
