import _ from 'lodash';
import React from 'react';
import { Location, useLocation } from 'react-router-dom';

import IssueNotificationLanding from '../components/containers/notifications/issue-notification-landing';
import LocationNotificationsIndex from '../components/containers/notifications/location-notifications-index';
import { lbjAppSections } from '../constants';
import fluxStore from '../modules/flux-store';
import * as notificationsModule from '../modules/notifications';
import getDefaultNotificationFilters from '../utils/notifications/default-filters';
import mapStateToNotificationsFilters from '../utils/notifications/map-state-to-notifications-filters';
import { searchToQuery } from '../utils/routing-provider';

import { storeAppFilters } from './app';

function handleIssueChanges() {
  const { dispatch } = fluxStore;
  const { getIssueNotificationsAsync } = notificationsModule.actionCreators;

  dispatch(getIssueNotificationsAsync());
}

export const NotificationsRoute: React.FunctionComponent = () => {
  const location = useLocation();

  React.useEffect(() => {
    handleIssueChanges();
  }, [location]);

  return <IssueNotificationLanding />;
};

function handleLocationChanges(location: Location) {
  const { dispatch } = fluxStore;
  const { search } = location;
  const query = searchToQuery(search);

  const { getNotificationsAsync } = notificationsModule.actionCreators;

  const filters = _.assign(
    {},
    getDefaultNotificationFilters(fluxStore.getState()),
    query
  );

  storeAppFilters(fluxStore, filters, lbjAppSections.NOTIFICATIONS).then(
    (state) => {
      const filters = mapStateToNotificationsFilters(state);
      dispatch(getNotificationsAsync(filters));
    }
  );
}

export const LocationNotificationsRoute: React.FunctionComponent = () => {
  const location = useLocation();

  React.useEffect(() => {
    handleLocationChanges(location);
  }, [location]);

  return <LocationNotificationsIndex />;
};
