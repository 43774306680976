import React from 'react';
import { useLocation } from 'react-router-dom';

import { ApiElectionBanner } from '../../../services/user-service';
import { TextButton } from '../../form/TextButton';

const PATHS_FOR_AT_POLLING_LOCATION_BANNERS = [
  '/home',
  '/issues/new',
  '/checkin',
  '/notifications',
];

function isBannerVisibleAtPath(
  banner: ApiElectionBanner,
  pathname: string
): boolean {
  switch (banner.type) {
    case 'at_polling_location':
      return PATHS_FOR_AT_POLLING_LOCATION_BANNERS.includes(pathname);
    case 'global':
      return true;
    default:
      // Don't show types of banners we don't know about
      return false;
  }
}

/**
 * Component for rendering banners from the navbar.
 */
const ElectionBanners: React.FunctionComponent<{
  banners: ApiElectionBanner[];

  /** Override the pathname for storybook */
  pathnameForTest?: string;
}> = ({ banners, pathnameForTest }) => {
  const [expandedBannerIds, setExpandedBannerIds] = React.useState<number[]>(
    []
  );

  const { pathname } = useLocation();

  return (
    <>
      {banners.map((banner) => {
        if (!isBannerVisibleAtPath(banner, pathnameForTest ?? pathname)) {
          return null;
        }

        const isExpanded = expandedBannerIds.includes(banner.id);

        // If the message has the format “WORDS: more words” then split them so
        // we can bold the first part with the colon.
        const [firstBit, ...rest] = banner.text.split(': ');

        return (
          <React.Fragment key={banner.id}>
            <div className="justify-left flex items-center bg-yellow-500 p-2 text-base md:p-4">
              <div>
                {rest.length === 0 ? (
                  firstBit
                ) : (
                  <>
                    <strong>{firstBit}:</strong> {rest.join(': ')}
                  </>
                )}
              </div>

              {banner.detail_text ? (
                <TextButton
                  size="default"
                  className="px-10 text-blue-900"
                  icon={isExpanded ? 'unfold_less' : 'unfold_more'}
                  onPress={() =>
                    setExpandedBannerIds((prev) =>
                      isExpanded
                        ? prev.filter((el) => el !== banner.id)
                        : [...prev, banner.id]
                    )
                  }
                >
                  {isExpanded ? 'Less' : 'More'}
                </TextButton>
              ) : banner.url ? (
                <a
                  href={banner.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs font-bold text-blue-900 underline"
                >
                  More info…
                </a>
              ) : null}
            </div>

            {isExpanded && banner.detail_text && (
              <div className="align-stretch flex flex-col gap-2 bg-gray-200 p-2 md:p-4">
                {banner.detail_text.split(/[\r\n]+/).map((txt, txtIdx) => (
                  <p key={txtIdx} className="text-base leading-relaxed">
                    {txt}
                  </p>
                ))}

                {banner.url && (
                  <a
                    href={banner.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-base font-bold text-blue-900 underline"
                  >
                    More info…
                  </a>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ElectionBanners;
