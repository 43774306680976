import { debounce } from 'lodash';
import React from 'react';

import { ApiUser, ExpandApiUserPii } from '../../../services/user-service';
import { UserListFilters, useUserListWithPii } from '../../hooks/user-data';

import Autocomplete from './autocomplete';

/**
 * Type of the choice value passed to `<Autocomplete>`.
 */
type UserChoice = {
  name: string;
  email: string;
};

/**
 * These are the only parts of the {@link ApiUser} that we really care about, so
 * don’t require any more so we don’t have to be goofy with types coming from
 * different places.
 *
 * `email` is optional because this may be being viewed by someone who’s not
 * allowed to see PII (e.g. a poll observer).
 */
export type SelectableUser = {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email?: string;
};

export type UserAutocompleteProps = {
  title: string;
  name?: string;
  userId: number | null;
  setUserId: (userId: number | null) => void;
  disabled?: boolean;
  errors?: string[] | undefined;
  required?: boolean;
  /**
   * Users that come from other sources than UserSearchState. Allows nulls just
   * so we don’t have to pre-filter values from parent components.
   */
  preloadedUsers: Array<SelectableUser | undefined | null>;
};

/**
 * View for {@link UserAutocompleteView}.
 */
export const UserAutocompleteView: React.FunctionComponent<
  UserAutocompleteProps & {
    users: Array<ApiUser & ExpandApiUserPii>;
    setUserNamePrefix: (prefix: string) => void;
  }
> = ({
  title,
  name = 'user',
  userId,
  setUserId,
  preloadedUsers,
  users,
  setUserNamePrefix,
  disabled = false,
  required = false,
  errors,
}) => {
  const choices = [...preloadedUsers, ...users].reduce(
    (acc, user) =>
      user ? { ...acc, [user.id.toString()]: userToChoice(user) } : acc,
    {} as { [userId: string]: UserChoice }
  );

  return (
    <Autocomplete
      name={name}
      type="text"
      title={title}
      required={required}
      disabled={disabled}
      placeholder={!disabled ? 'Start typing…' : ''}
      choices={choices}
      errors={errors}
      // For filtering we do a substring check. This isn’t 100% a match with how
      // the autocomplete API requests work, since they load prefixes for first
      // or last name, but it’s close enough that it still filters.
      filterOption={(option, value) =>
        !value
          ? true
          : (option.label as unknown as UserChoice).name
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
      }
      optionRenderer={(option) => {
        // Type cast since we’re dealing with <Autocomplete>’s hack of
        // setting "label" to an object rather than a string.
        const label = option.label as unknown as UserChoice;

        return label.email ? (
          <span>
            {label.name}
            <br />
            {label.email}
          </span>
        ) : (
          <span>{label.name}</span>
        );
      }}
      valueRenderer={
        // Type cast since we’re dealing with <Autocomplete>’s hack of
        // setting "label" to an object rather than a string.
        (option) => <span>{(option.label as unknown as UserChoice).name}</span>
      }
      onInputChange={(text) => {
        setUserNamePrefix(text);
        // react-select requires that input change return a string.
        return text;
      }}
      onChange={(ev) =>
        setUserId(ev.target.value ? parseInt(ev.target.value) : null)
      }
      value={userId?.toString() ?? ''}
    />
  );
};

/**
 * Converts an {@link ApiUser} object (w/ optional PII) to the
 * {@link UserChoice} type we use in our `<Autocomplete>`.
 */
const userToChoice = ({
  first_name,
  last_name,
  email = '',
}: SelectableUser): UserChoice => ({
  name: `${first_name} ${last_name}`,
  email,
});

/**
 * Form component that renders an autocomplete box for selecting a user.
 *
 * Typing in the autocomplete box will cause requests to the backend to find
 * users that match the string in their first or last names.
 *
 * Uses {@link useUserListWithPii} to load auto-complete results from the
 * server.
 */
const UserAutocomplete: React.FunctionComponent<
  UserAutocompleteProps & {
    /**
     * Non-name things to filter the user list by (such as boiler room).
     */
    externalFilters?: Omit<UserListFilters, 'name_prefix'>;
    /** Exposed for tests so we don’t have to wait 500ms */
    debounceWait?: number;
  }
> = ({
  debounceWait = 500,
  externalFilters = {},
  preloadedUsers,
  setUserId,
  ...props
}) => {
  // Filters based on user input from our children.
  const [inputFilters, setInputFilters] = React.useState<UserListFilters>({});

  // ESLint can’t understand the dependencies when we pass the value from
  // `debounce` rather than a function literal.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setInputFiltersDebounced = React.useCallback(
    debounce(setInputFilters, debounceWait),
    []
  );

  const setUserNamePrefix = React.useCallback(
    (namePrefix: string) =>
      setInputFiltersDebounced(namePrefix ? { namePrefix } : {}),
    [setInputFiltersDebounced]
  );

  const combinedFilters = {
    ...externalFilters,
    ...inputFilters,
  };

  // We want `beLaggy` because we’re expecting that <Autocomplete> is also doing
  // filtering on our results, and further requests are a refinement of previous
  // ones. So if the hook is returning its cached results for “Ke” while an API
  // request is outstanding for “Kenne” then the user will still get
  // correctly-filtered results in the UI component. Once the “Kenne” API
  // response returns more results might pop in, but that’s fine and expected.
  const users = useUserListWithPii(combinedFilters, {
    beLaggy: true,
    disabled: Object.keys(combinedFilters).length === 0 || !!props.disabled,
  });

  // Store the last selected user so it can be added to preloadedUsers. This is
  // for when the selected user was chosen from the list of auto-completed
  // users. We can’t guarantee that we can find it again by id in users
  // (blurring the input field resets the filter).
  const [lastSelectedUser, setLastSelectedUser] = React.useState<
    (ApiUser & ExpandApiUserPii) | null
  >(null);

  return (
    <UserAutocompleteView
      {...props}
      preloadedUsers={[...preloadedUsers, lastSelectedUser]}
      users={users ?? []}
      setUserId={(id) => {
        setLastSelectedUser(users?.find((u) => u.id === id) ?? null);
        setUserId(id);
      }}
      setUserNamePrefix={setUserNamePrefix}
    />
  );
};

export default UserAutocomplete;
