import moment from 'moment';
import React from 'react';
import useSWR from 'swr';

import { issueClasses, State } from '../../constants';

import { PaginatedResponse } from '../../services/common';
import {
  getIssuesByPhoneNumber,
  IssuesByPhoneNumberApiIssue,
} from '../../services/issue-service';
import { useStateWithDeps } from '../../utils/hooks';

/**
 * Subset of the issue fields we care about, so that we don’t have to fake out
 * more than necessary.
 */
export type IssueByPhoneNumberForDisplay = Pick<
  IssuesByPhoneNumberApiIssue,
  | 'id'
  | 'type'
  | 'category'
  | 'sub_category'
  | 'comments'
  | 'voter_first_name'
  | 'voter_last_name'
  | 'report_time'
>;

export const IssuesListByPhoneNumberView: React.FunctionComponent<{
  search: (() => void) | undefined;
  issuesList:
    | PaginatedResponse<IssueByPhoneNumberForDisplay>
    | null
    | undefined;
  addToIssueRecord: (issueId: number) => void;
  formIsDirty: boolean;
}> = ({ search, issuesList, addToIssueRecord, formIsDirty }) => (
  <>
    <div className="search-div">
      <button type="button" disabled={!search} onClick={search}>
        Search
      </button>

      <p className="microcopy">
        Check to see if this person has called before.
      </p>
    </div>

    {issuesList?.count === 0 && (
      <div className="no-record">
        <span>
          No existing issue found for this caller. Continue to capture this
          caller’s new issue.
        </span>
      </div>
    )}

    {!!issuesList?.count && (
      <fieldset className="issue-detail-v2">
        <legend>Record Locator</legend>
        <div className="row">
          <div className="col-small-12">
            <p className="script">
              I see you have called before. Is this to talk about the same issue
              or a new one?
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-small-12">
            {issuesList.results.map((issue) => (
              <IssuesListByPhoneNumberIssue
                key={issue.id}
                issue={issue}
                addToIssueRecord={addToIssueRecord}
                formIsDirty={formIsDirty}
              />
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-small-12">
            {issuesList.count > 5 && (
              <p>
                <i>
                  There are {issuesList.count} matching records, so the above
                  list has been truncated to the 5 most recent matching records.
                </i>
              </p>
            )}
          </div>
        </div>
        If this is a different issue, just continue filling out this form.
      </fieldset>
    )}
  </>
);

const IssuesListByPhoneNumber: React.FunctionComponent<{
  phoneNumber: string;
  state: State | '';
  ticketElectionId: number | null;
  addToIssueRecord: (phoneNumber: string, issueId: number) => void;
  formIsDirty: boolean;
}> = ({
  phoneNumber,
  state,
  ticketElectionId,
  addToIssueRecord,
  formIsDirty,
}) => {
  // Boolean state to say whether we should have an active search going. Used to
  // bridge between clicking the button and useSWR for fetching.
  //
  // We have useStateWithDeps so that it resets when our parameters change.
  //
  // TODO(fiona): Switch to search-as-you-type?
  const [searchActive, setSearchActive] = useStateWithDeps(false, [
    phoneNumber,
    state,
    ticketElectionId,
  ]);

  const validSearchParams =
    phoneNumber.length >= 5 ? { phoneNumber, state, ticketElectionId } : null;

  const issuesList = useSWR(
    [
      'IssueService#getIssuesByPhoneNumber',
      searchActive ? validSearchParams : null,
    ] as const,
    async ([_, params]) => {
      if (!params) {
        return null;
      }

      return await getIssuesByPhoneNumber({
        voter_phone_number__exact: params.phoneNumber,
        // We allow state and election ID to be empty so that we can query from
        // the National hotline, since the search form appears before a state is
        // chosen.
        ...(params.state ? { state: params.state } : {}),
        ...(params.ticketElectionId !== null
          ? { query_election_id: params.ticketElectionId }
          : {}),
        expand: 'comments',
      });
    }
  ).data;

  return (
    <IssuesListByPhoneNumberView
      addToIssueRecord={addToIssueRecord.bind(null, phoneNumber)}
      formIsDirty={formIsDirty}
      issuesList={issuesList}
      search={validSearchParams ? () => setSearchActive(true) : undefined}
    />
  );
};

const IssuesListByPhoneNumberIssue: React.FunctionComponent<{
  issue: IssueByPhoneNumberForDisplay;
  addToIssueRecord: (id: number) => void;
  formIsDirty: boolean;
}> = ({ issue, addToIssueRecord, formIsDirty }) => {
  const {
    type,
    category,
    sub_category: subcategory,
    report_time: reportTime,
    comments,
  } = issue;

  const typeLetter = issueClasses[type] ? issueClasses[type][0] : '';

  const reportTimeFormat = reportTime
    ? moment(reportTime).format('MMM Do YYYY, h:mma — ')
    : '';
  const relativeReportTimeString = reportTime
    ? moment(reportTime).fromNow()
    : '';
  const reportTimeString = reportTimeFormat + relativeReportTimeString;

  return (
    <div className="issue-phone-record">
      <div className="issue-phone-found-well">
        <p>{reportTimeString}</p>
        <div className="record-well-section">
          <p>
            <strong>NAME</strong>
          </p>
          <p>
            {issue.voter_first_name} {issue.voter_last_name}
          </p>
        </div>

        <div className="record-well-section">
          <p>
            <strong>ISSUE</strong>
          </p>
          <p>
            {typeLetter}:{' '}
            <span className="issue-list-category">{category}</span>
            <br />
            <span className="issue-list-subcategory">{subcategory}</span>
          </p>
        </div>

        <div className="record-well-section">
          <p>
            <strong>COMMENTS</strong>
          </p>
          {comments.map((comment) => (
            <p key={comment.id}>{comment.text}</p>
          ))}
        </div>
      </div>

      <div>
        <button
          type="button"
          className="issue-claim-button"
          onClick={() => addToIssueRecord(issue.id)}
        >
          Add to this Record
        </button>
      </div>

      {formIsDirty && (
        <p className="record-warning">
          Any information entered so far will be lost.
        </p>
      )}
    </div>
  );
};

export default IssuesListByPhoneNumber;
