import _ from 'lodash';
import React from 'react';
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  Location,
} from 'react-router-dom';

import AnalyticsContainer from '../components/containers/analytics/analytics-container';
import { lbjAppSections } from '../constants';
import * as analyticsModule from '../modules/analytics';
import fluxStore from '../modules/flux-store';
import * as lbjModule from '../modules/lbj';
import mapStateToAnalyticsFilters from '../utils/analytics/map-state-to-analytics-filters';
import {
  mapQueryParamsToAnalyticsFilters,
  mapAnalyticsFiltersToQueryParams,
} from '../utils/analytics/query-params';
import { queryToSearch, searchToQuery } from '../utils/routing-provider';

import { redirectUnauthorizedUser, storeAppFilters } from './app';

function handleAnalytics(location: Location, navigate: NavigateFunction) {
  if (
    redirectUnauthorizedUser(fluxStore, navigate, [
      'vpd',
      'deputy_vpd',
      'boiler_room_leader',
      'boiler_room_user',
      'view_only',
    ])
  ) {
    return;
  }

  const { dispatch } = fluxStore;
  const { pathname, search } = location;
  const query = searchToQuery(search);

  const { getIncidentAnalyticsAsync } = analyticsModule.actionCreators;
  const { getCountyListAsync, getElectionListAsync } = lbjModule.actionCreators;
  const filters = mapQueryParamsToAnalyticsFilters(query);
  const urlParams = mapAnalyticsFiltersToQueryParams(filters);

  if (!_.isEqual(urlParams, query)) {
    navigate({ pathname, search: queryToSearch(urlParams) }, { replace: true });
    return;
  }

  storeAppFilters(fluxStore, filters, lbjAppSections.ANALYTICS).then(
    (state) => {
      const requestPayload = {
        size: 25,
        ...mapStateToAnalyticsFilters(state).toJS(),
      };
      dispatch(getIncidentAnalyticsAsync(requestPayload));
      dispatch(getCountyListAsync(filters.state ?? ''));
      dispatch(getElectionListAsync(filters.state ?? ''));
    }
  );
}

export const AnalyticsRoute: React.FunctionComponent = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  React.useEffect(() => {
    handleAnalytics(location, navigateRef.current);
  }, [location]);

  return <AnalyticsContainer />;
};
