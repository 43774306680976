import type { Node as CollectionNode } from '@react-types/shared';
import type { Virtualizer } from '@tanstack/react-virtual';
import React from 'react';
import {
  AriaListBoxOptions,
  AriaListBoxProps,
  useListBoxSection,
  mergeProps,
  useListBox,
  useOption,
} from 'react-aria';
import { ListState, useListState } from 'react-stately';

import {
  ListView,
  ListItemView,
  ListSectionView,
  ListSelectionStyle,
  VirtualizeCollection,
  VirtualizedItemProps,
} from './ListInternals';

/**
 * Renders a box with items inside of it.
 *
 * Uses the react-stately Collection interface for specifying the items: either
 * pass {@link Section} and {@link Item} components as children, or pass an
 * array of objects in the `items` prop and pass a render function as the only
 * child.
 *
 * By default, will just call the `onAction` handler when the items are chosen.
 * To maintain a selection state, set the `selectionMode` prop.
 *
 * @see https://react-spectrum.adobe.com/react-stately/collections.html
 */
export function ListBox<T extends {}>({
  selectionStyle = 'checkmark',
  shouldVirtualize,
  listBoxRef,
  itemHeight,
  ...props
}: AriaListBoxProps<T> & {
  selectionStyle?: ListSelectionStyle;
  shouldVirtualize?: boolean | undefined;
  listBoxRef?: React.RefObject<HTMLUListElement> | undefined;
  itemHeight?: number | undefined;
}): React.ReactElement {
  const state = useListState(props);

  return (
    <ListBoxView
      state={state}
      selectionStyle={selectionStyle}
      shouldVirtualize={shouldVirtualize}
      listBoxRef={listBoxRef}
      itemHeight={itemHeight}
      {...props}
    />
  );
}

/**
 * Renders a list of items with {@link useListBox}.
 *
 * Split out from {@link ListBox} so it can be re-used in components that want a
 * different state than the {@link useListState} that it uses (such as a select
 * box).
 *
 * Because {@link ListState} is kind of a whole thing, components that use this
 * will need to call {@link useSelectState} or {@link useListState} to get one,
 * rather than try to hand-roll one.
 */
export function ListBoxView<T extends {}>({
  state,
  selectionStyle,
  listBoxRef,
  shouldVirtualize = false,
  itemHeight = 34,
  ...props
}: AriaListBoxOptions<T> & {
  state: ListState<T>;
  selectionStyle: ListSelectionStyle;
  listBoxRef?: React.RefObject<HTMLUListElement> | undefined;
  shouldVirtualize?: boolean | undefined;
  itemHeight?: number | undefined;
}): React.ReactElement {
  const internalListBoxRef = React.useRef(null);
  listBoxRef = listBoxRef ?? internalListBoxRef;

  const { listBoxProps } = useListBox(
    {
      ...props,
      isVirtualized: shouldVirtualize,
    },
    state,
    listBoxRef
  );

  const virtualizerRef = React.useRef<Virtualizer<HTMLElement, Element> | null>(
    null
  );

  // For the case of a virtual list, we need to manually handle scrolling when
  // the focus changes. E.g. pressing the down arrow past the bottom of the
  // visible items should scroll the selected item into view.
  React.useEffect(() => {
    if (state.selectionManager.focusedKey !== null) {
      const focusedIndex = state.collection.getItem(
        state.selectionManager.focusedKey
      )?.index;

      if (typeof focusedIndex === 'number') {
        virtualizerRef.current?.scrollToIndex(focusedIndex);
      }
    }
  }, [state.selectionManager.focusedKey, state.collection]);

  const renderItem = (
    item: CollectionNode<T>,
    itemProps?: VirtualizedItemProps,
    measureElement?: (el: HTMLElement | null) => void
  ) => {
    switch (item.type) {
      case 'section':
        return (
          <ListBoxSection
            key={item.key}
            section={item}
            state={state}
            selectionStyle={selectionStyle}
            isVirtualized={shouldVirtualize}
            virtualizedItemProps={itemProps}
            measureElement={measureElement}
            shouldFocusOnHover={props.shouldFocusOnHover}
          />
        );
      case 'item':
        return (
          <ListBoxItem
            key={item.key}
            item={item}
            state={state}
            selectionStyle={selectionStyle}
            virtualizedItemProps={itemProps}
            measureElement={measureElement}
            shouldFocusOnHover={props.shouldFocusOnHover}
          />
        );
    }
  };

  return (
    <ListView ref={listBoxRef} {...listBoxProps}>
      {shouldVirtualize ? (
        <VirtualizeCollection
          scrollingRef={listBoxRef}
          virtualizerRef={virtualizerRef}
          collection={state.collection}
          renderItem={renderItem}
          itemHeight={itemHeight}
        />
      ) : (
        [...state.collection].map((item) => renderItem(item))
      )}
    </ListView>
  );
}

/**
 * Component for a single item in a {@link ListBox}/{@link ListBoxView}.
 * Delegates rendering to {@link ListItemView}.
 */
function ListBoxItem<T extends {}>({
  item,
  state,
  selectionStyle,
  virtualizedItemProps,
  measureElement,
  shouldFocusOnHover,
}: {
  item: CollectionNode<T>;
  state: ListState<T>;
  selectionStyle: ListSelectionStyle;
  virtualizedItemProps?: VirtualizedItemProps | undefined;
  measureElement?: ((el: HTMLElement | null) => void) | undefined;
  shouldFocusOnHover?: boolean | undefined;
}): React.ReactElement {
  const itemRef = React.useRef<HTMLElement | null>(null);
  const { optionProps, isSelected, isDisabled, isFocused, isFocusVisible } =
    useOption({ key: item.key }, state, itemRef);

  // We need to both maintain the `itemRef` for `useOption` and also we need
  // to call `measureElement`.
  const combinedRef = React.useCallback(
    (el: HTMLElement | null) => {
      itemRef.current = el;
      measureElement?.(el);
    },
    [measureElement]
  );

  return (
    <ListItemView
      ref={combinedRef}
      selectionStyle={selectionStyle}
      isSelected={isSelected}
      isDisabled={isDisabled}
      // Use a focus ring if we’re done doing the full `isHovered` and also if a
      // keyboard was used to set focus.
      showFocusRing={!!(isFocused && !shouldFocusOnHover) && isFocusVisible}
      // The `isHovered` rendering is a full background color change, like an
      // active menu item.
      isHovered={!!(isFocused && shouldFocusOnHover)}
      {...mergeProps(optionProps, virtualizedItemProps)}
    >
      {item.rendered}
    </ListItemView>
  );
}

/**
 * Renders a “section” of a {@link ListBox}/{@link ListBoxView}, with the items
 * of that section as a nested list.
 *
 * Delegates rendering to {@link ListSectionView}.
 */
function ListBoxSection<T extends {}>({
  section,
  state,
  selectionStyle,
  isVirtualized,
  virtualizedItemProps,
  measureElement,
  shouldFocusOnHover,
}: {
  section: CollectionNode<T>;
  state: ListState<T>;
  selectionStyle: ListSelectionStyle;
  isVirtualized?: boolean | undefined;
  virtualizedItemProps?: VirtualizedItemProps | undefined;
  measureElement?: ((el: HTMLElement | null) => void) | undefined;
  shouldFocusOnHover: boolean | undefined;
}) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    // jank due to our pedantic handling of `undefined`
    ...(section['aria-label'] !== undefined
      ? { 'aria-label': section['aria-label'] }
      : {}),
  });

  return (
    <ListSectionView
      itemProps={mergeProps(itemProps, virtualizedItemProps ?? {})}
      itemRef={measureElement}
      headingProps={headingProps}
      groupProps={groupProps}
      isFirst={section.key === state.collection.getFirstKey()}
      heading={section.rendered}
    >
      {/* We assume no nesting of sections */}
      {/* If the list is virtualized then we don’t nest the section’s children; they’ll be rendered separately. */}
      {!isVirtualized &&
        [...section.childNodes].map((node) => (
          <ListBoxItem
            key={node.key}
            item={node}
            state={state}
            selectionStyle={selectionStyle}
            shouldFocusOnHover={shouldFocusOnHover}
          />
        ))}
    </ListSectionView>
  );
}
