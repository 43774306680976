import moment from 'moment';
import momentTz from 'moment-timezone';
import React from 'react';
import { LoaderFunctionArgs, useNavigation } from 'react-router-dom';

import CountySelect, {
  countiesToChoices,
} from '../../../components/presentational/form/county-select';
import DatetimeInput from '../../../components/presentational/form/datetime';
import StateSelect from '../../../components/presentational/form/state-select';
import LoadingOverlay from '../../../components/presentational/lbj/loading';
import Sidebar from '../../../components/presentational/lbj/sidebar';
import { State, states, US_NATIONAL_STATE_CODE } from '../../../constants';
import { arrayToPaginationData } from '../../../decorators/paginated-component';
import { AppStore } from '../../../modules/flux-store';
import { loadCurrentUser } from '../../../route-handlers/app';
import {
  callIfDate,
  CountySlug,
  DATE_STRING_FORMAT,
  Iso8601String,
} from '../../../services/common';
import { ApiLineLengthByHour } from '../../../services/dashboard-service';
import * as DashboardService from '../../../services/dashboard-service';
import * as LbjSharedService from '../../../services/lbj-shared-service';
import { ApiElection, ApiCounty } from '../../../services/lbj-shared-service';

import { orderingToSortProps } from '../../../utils/list-helpers';
import {
  MakeQueryFieldsFn,
  parseRequestQueryState,
  UpdateQueryStateFn,
  useRouterQueryState,
} from '../../../utils/query-state';

import { DASHBOARD_USER_ROLES } from '../dashboard-utils';

import DashboardRecentLinesTable from './DashboardRecentLinesTable';

const DEFAULT_RECENT_LINES_COUNT_SIZE = 10;

export type DashboardRecentLinesPageFilters = {
  offset: number;
  size: number;

  date: Date;

  state: State | undefined;
  county: CountySlug | undefined;

  ordering: string;
};

function makeDashboardRecentLinesFiltersQueryFields(
  currentElection: ApiElection
): MakeQueryFieldsFn<DashboardRecentLinesPageFilters> {
  return (q) => ({
    offset: q.number(0),
    size: q.number(DEFAULT_RECENT_LINES_COUNT_SIZE),

    // If the current election is national, allow any state to be chosen.
    // Otherwise silently force the state of the current election.
    state:
      currentElection.state === US_NATIONAL_STATE_CODE
        ? q.state({ ignoreUs: true })
        : q.silent(currentElection.state),
    county: q.county(),

    ordering: q.string('Location'),

    date: q.date(DATE_STRING_FORMAT, new Date()),
  });
}

const DashboardRecentLinesPage: React.FunctionComponent<{
  currentElection: ApiElection;
  results: ApiLineLengthByHour[];
  tableHeaderValues: (keyof ApiLineLengthByHour)[];
  counties: ApiCounty[] | undefined;
}> = (props) => {
  const { state: navigationState } = useNavigation();
  const isLoading = navigationState === 'loading';

  const [filters, updateFilters] =
    useRouterQueryState<DashboardRecentLinesPageFilters>(
      makeDashboardRecentLinesFiltersQueryFields(props.currentElection)
    );

  return (
    <DashboardRecentLinesPageView
      {...props}
      isLoading={isLoading}
      filters={filters}
      updateFilters={updateFilters}
    />
  );
};

export const DashboardRecentLinesPageView: React.FunctionComponent<{
  currentElection: ApiElection;
  counties: ApiCounty[] | undefined;
  results: ApiLineLengthByHour[];
  tableHeaderValues: (keyof ApiLineLengthByHour)[];
  filters: DashboardRecentLinesPageFilters;
  updateFilters: UpdateQueryStateFn<DashboardRecentLinesPageFilters>;
  isLoading: boolean;
}> = ({
  currentElection,
  isLoading,
  results,
  counties,
  filters,
  updateFilters,
  tableHeaderValues,
}) => {
  return (
    <div className="lbj-page-columns">
      <Sidebar title="Filters" collapsible>
        <div className="lbj-column-content">
          <DatetimeInput
            name="date"
            title="Date"
            type="date"
            value={filters.date}
            dateFormat="MMMM Do, YYYY"
            onChange={(ev) =>
              callIfDate(ev.target.value, (d) => {
                updateFilters({ date: d });
              })
            }
          />

          <StateSelect
            disabled={currentElection.state !== US_NATIONAL_STATE_CODE}
            includeNational={false}
            value={filters.state ?? null}
            onChange={(ev) =>
              updateFilters({
                state: ev.target.value ?? undefined,
                // reset dependent filters
                county: undefined,
              })
            }
          />

          <CountySelect
            disabled={
              filters.state === undefined || !counties || counties.length === 0
            }
            counties={countiesToChoices(counties)}
            value={filters.county ?? null}
            onChange={(ev) => {
              updateFilters({
                county: ev.target.value ?? undefined,
              });
            }}
          />
        </div>
      </Sidebar>

      <div className="lbj-main">
        <div className="lbj-main-title lbj-column-label">
          <DashboardRecentLinesActiveFilters
            filters={filters}
            counties={counties}
          />
        </div>

        <div className="lbj-column-content-wrapper">
          {isLoading && <LoadingOverlay />}

          <DashboardRecentLinesTable
            results={results}
            paginationData={arrayToPaginationData(results, filters)}
            setPage={({ offset, size }) => updateFilters({ offset, size })}
            {...orderingToSortProps(filters, updateFilters)}
            tableHeaderValues={tableHeaderValues}
          />
        </div>
      </div>
    </div>
  );
};

const DashboardRecentLinesActiveFilters: React.FunctionComponent<{
  filters: Pick<DashboardRecentLinesPageFilters, 'state' | 'county'>;
  counties: ApiCounty[] | undefined;
}> = ({ filters, counties }) => {
  // There’s no “group by” for this page so we just say “Locations” for
  // consistency with the others.
  const labels: string[] = ['Locations'];

  if (filters.state) {
    labels.push(states[filters.state]);
  }

  if (filters.county !== undefined && counties) {
    const county = counties.find(({ slug }) => slug === filters.county);
    if (county) {
      labels.push(county.name);
    }
  }

  return (
    <h4 className="font-bold">
      Showing:{' '}
      {labels.map((label, idx) => (
        <span key={idx}>{label}</span>
      ))}
    </h4>
  );
};

export async function loadDashboardRecentLinesPage(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<React.ComponentProps<typeof DashboardRecentLinesPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: DASHBOARD_USER_ROLES,
  });

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const filters = parseRequestQueryState<DashboardRecentLinesPageFilters>(
    request,
    makeDashboardRecentLinesFiltersQueryFields(currentElection)
  );

  const lineLengthsResp = DashboardService.getLineLengthByHour({
    offset: filters.offset,
    size: filters.size,
    ordering: filters.ordering,
    ...(filters.state !== undefined ? { state: filters.state } : {}),
    ...(filters.county !== undefined ? { county: filters.county } : {}),

    tz: momentTz.tz.guess(),

    // Loads checkins from midnight to midnight, local time in the browser.
    //
    // The old version of this code loaded from 5am today to 5am tomorrow…
    // this may have been an attempt to compensate for EST. Doing midnight ->
    // midnight for now since it makes the most sense but could update this if
    // there’s a need.
    checkin_time__gte: moment(filters.date)
      .startOf('day')
      .format() as Iso8601String,
    checkin_time__lt: moment(filters.date)
      .endOf('day')
      .format() as Iso8601String,
  });

  const countiesResp = filters.state
    ? LbjSharedService.getCounties(filters.state)
    : undefined;

  const { length_by_hour, hour_headers } = await lineLengthsResp;

  return {
    currentElection,
    results: length_by_hour,
    tableHeaderValues: hour_headers,
    counties: (await countiesResp)?.counties,
  };
}

export default DashboardRecentLinesPage;
