import type { DOMProps } from '@react-types/shared';
import _, { isEqual } from 'lodash';
import React from 'react';
import { useId } from 'react-aria';
import { Item } from 'react-stately';

import {
  ActionButton,
  Radio,
  RadioGroup,
  TagComboBox,
  TextButton,
} from '../../../components/form';
import { Checkbox } from '../../../components/form/Checkbox';

import { NumberField } from '../../../components/form/NumberField';

import { Dialog, Popover } from '../../../components/layout';
import { CountySlug } from '../../../services/common';
import {
  ApiCounty,
  ApiLocationTierConfiguration,
} from '../../../services/lbj-shared-service';
import { AssignmentLocation } from '../assignment-state';
import {
  DEFAULT_LOCATION_FILTERS,
  LocationFilterOptions,
} from '../assignment-utils';

export type LocationMunicipalities = {
  counties: ApiCounty[];
  cities: string[];
};

/**
 * Makes a button and popover for filtering locations.
 */
const LocationFilter: React.FunctionComponent<{
  locationFilters: LocationFilterOptions;
  setLocationFilters: (
    updater: (prev: LocationFilterOptions) => LocationFilterOptions
  ) => void;
  locationTierConfiguration: ApiLocationTierConfiguration[];
  municipalities: LocationMunicipalities;
  maxRanking: number | null;
  isLocked?: boolean | undefined;
  isDisabled?: boolean | undefined;
  hasLastSuggestedAssignments: boolean;
  openForTest?: boolean;
}> = ({
  locationFilters,
  setLocationFilters,
  locationTierConfiguration,
  maxRanking,
  municipalities,
  hasLastSuggestedAssignments,
  isLocked = false,
  isDisabled = false,
  openForTest = false,
}) => {
  const locationsHeaderId = useId();
  const locationTypeHeaderId = useId();

  const filterIsInUse = !isEqual(locationFilters, DEFAULT_LOCATION_FILTERS);

  return (
    <Popover
      {...(openForTest ? { isOpen: true } : {})}
      type="menu"
      placement="bottom"
      trigger={(props, ref) => (
        <ActionButton
          icon={isLocked ? 'lock' : 'filter_list'}
          role="toolbar"
          buttonRef={ref}
          isDisabled={isDisabled}
          isSelected={filterIsInUse}
          {...props}
        >
          Filter
        </ActionButton>
      )}
      content={(props: DOMProps) => (
        <Dialog
          {...props}
          className="flex w-[600px] flex-col gap-2 rounded border border-gray-200 bg-white p-4 text-base leading-none"
        >
          <div className="flex gap-4">
            {maxRanking !== null && (
              <div className="flex flex-1 flex-col gap-3">
                <div className="font-semibold">Ranking</div>
                <TierRankingFilters
                  isLocked={isLocked}
                  locationTierConfiguration={locationTierConfiguration}
                  maxRanking={maxRanking}
                  locationFilters={locationFilters}
                  setLocationFilters={setLocationFilters}
                />
              </div>
            )}

            <div className="flex flex-1 flex-col gap-3">
              <div className="font-semibold" id={locationsHeaderId}>
                Location
              </div>
              <MunicipalityFilters
                aria-labelledby={locationsHeaderId}
                municipalities={municipalities}
                locationFilters={locationFilters}
                setLocationFilters={setLocationFilters}
              />
              <div className="mt-2 font-semibold" id={locationTypeHeaderId}>
                Location Type
              </div>
              <MunicipalityTypeFilters
                aria-labelledby={locationTypeHeaderId}
                locationFilters={locationFilters}
                setLocationFilters={setLocationFilters}
              />
            </div>
          </div>

          {(hasLastSuggestedAssignments ||
            locationFilters.withSuggestedAssignments) && (
            <div className="mt-4 flex flex-1 flex-col gap-3">
              <div className="font-semibold" id={locationsHeaderId}>
                Suggested Assignments
              </div>

              <Checkbox
                isSelected={locationFilters.withSuggestedAssignments}
                onChange={(val) =>
                  setLocationFilters((prev) => ({
                    ...prev,
                    withSuggestedAssignments: val,
                  }))
                }
              >
                Only show locations with suggestions
              </Checkbox>
            </div>
          )}

          <div className="mt-8">
            {isLocked ? (
              <div className="flex items-center gap-2">
                <span className="material-icons text-gray-500">lock</span>
                <div className="flex-1 text-sm italic text-gray-700">
                  Filters are locked while reviewing suggested assignments.
                </div>
              </div>
            ) : (
              <TextButton
                onPress={() =>
                  setLocationFilters((prevLocationFilters) => ({
                    ...prevLocationFilters,
                    ...DEFAULT_LOCATION_FILTERS,
                  }))
                }
              >
                Clear all filters
              </TextButton>
            )}
          </div>
        </Dialog>
      )}
    />
  );
};

export default LocationFilter;

export const TierRankingFilters: React.FunctionComponent<{
  showAllOption?: boolean | undefined;
  isLocked?: boolean | undefined;
  locationTierConfiguration: ApiLocationTierConfiguration[];
  maxRanking: number;
  locationFilters: LocationFilterOptions;
  setLocationFilters: (
    updater: (prev: LocationFilterOptions) => LocationFilterOptions
  ) => void;
}> = ({
  showAllOption = false,
  isLocked = false,
  locationTierConfiguration,
  maxRanking,
  locationFilters,
  setLocationFilters,
}) => {
  /**
   * Toggle handler for tiers.
   *
   * Pass -1 for `tierIdx` to toggle the “show unranked locations” value.
   */
  const toggleTierCheckbox = (tierIdx: number, checked: boolean) => {
    setLocationFilters((prevLocationFilters) => {
      const showUnrankedLocations =
        tierIdx === -1
          ? checked
          : prevLocationFilters.rankings.type === 'tiers'
          ? prevLocationFilters.rankings.showUnrankedLocations
          : false;

      const prevTiers =
        prevLocationFilters.rankings.type === 'tiers'
          ? prevLocationFilters.rankings.tiers
          : [];

      const newTiers =
        tierIdx === -1
          ? prevTiers
          : checked
          ? [...prevTiers, tierIdx]
          : prevTiers.filter((tier) => tier !== tierIdx);

      if (newTiers.length || showUnrankedLocations) {
        return {
          ...prevLocationFilters,
          rankings: {
            type: 'tiers',
            tiers: newTiers,
            showUnrankedLocations,
          },
        };
      } else {
        // Un-toggling the last checkbox sets us to “all”
        return {
          ...prevLocationFilters,
          rankings: {
            type: 'all',
          },
        };
      }
    });
  };

  const unrankedLocationsCheckbox = (
    <Checkbox
      // "-1" is a hack for the show unranked locations option
      onChange={(checked) => toggleTierCheckbox(-1, checked)}
      isSelected={
        locationFilters.rankings.type === 'tiers' &&
        locationFilters.rankings.showUnrankedLocations
      }
      isDisabled={isLocked}
    >
      Unranked locations
    </Checkbox>
  );

  const minRankFieldRef = React.useRef<HTMLInputElement>(null);
  const prevRankingTypeRef = React.useRef(locationFilters.rankings.type);

  // Focus the min rank input if someone checks the “custom” checkbox.
  React.useEffect(() => {
    if (
      locationFilters.rankings.type === 'custom' &&
      prevRankingTypeRef.current !== 'custom'
    ) {
      minRankFieldRef.current?.focus();
    }

    prevRankingTypeRef.current = locationFilters.rankings.type;
  }, [locationFilters.rankings.type]);

  return (
    <>
      {showAllOption && (
        <>
          <Checkbox
            isSelected={locationFilters.rankings.type === 'all'}
            isDisabled={isLocked}
            onChange={() =>
              setLocationFilters((prev) => ({
                ...prev,
                rankings: { type: 'all' },
              }))
            }
          >
            All Rankings
          </Checkbox>

          <hr />
        </>
      )}

      {locationTierConfiguration.length > 0 && (
        <>
          {locationTierConfiguration.map((tierConfig, tierIdx) => {
            return (
              <Checkbox
                key={tierIdx}
                onChange={(checked) => toggleTierCheckbox(tierIdx, checked)}
                isDisabled={isLocked}
                isSelected={
                  locationFilters.rankings.type === 'tiers' &&
                  locationFilters.rankings.tiers.includes(tierIdx)
                }
              >
                Tier {tierIdx + 1}: Ranks {tierConfig.state_rank_gte}
                {tierIdx === locationTierConfiguration.length - 1 &&
                tierConfig.state_rank_lte >= maxRanking
                  ? '+'
                  : ` – ${tierConfig.state_rank_lte}`}
              </Checkbox>
            );
          })}
          {unrankedLocationsCheckbox}
          <hr />
        </>
      )}

      <Checkbox
        onChange={(checked) =>
          setLocationFilters((prevLocationFilters) => ({
            ...prevLocationFilters,
            rankings: checked
              ? { type: 'custom', range: [1, maxRanking] }
              : { type: 'all' },
          }))
        }
        isSelected={locationFilters.rankings.type === 'custom'}
        isDisabled={isLocked}
      >
        <div className="flex items-center gap-x-2">
          Ranks:
          <NumberField
            ref={minRankFieldRef}
            {...(locationFilters.rankings.type === 'custom'
              ? {
                  value: locationFilters.rankings.range[0],
                  isDisabled: isLocked,
                  minValue: 1,
                  maxValue: locationFilters.rankings.range[1],
                  selectOnFocus: true,
                  onChange: (newMin) => {
                    if (!newMin) {
                      return;
                    }
                    setLocationFilters((prevLocationFilters) => {
                      if (prevLocationFilters.rankings.type !== 'custom') {
                        return prevLocationFilters;
                      } else {
                        return {
                          ...prevLocationFilters,
                          rankings: {
                            type: 'custom',
                            range: [
                              newMin,
                              prevLocationFilters.rankings.range[1],
                            ],
                          },
                        };
                      }
                    });
                  },
                }
              : {
                  value: 1,
                  isDisabled: true,
                })}
            aria-label={'min rank'}
          />
          <div>–</div>
          <NumberField
            {...(locationFilters.rankings.type === 'custom'
              ? {
                  isDisabled: isLocked,
                  value: locationFilters.rankings.range[1],
                  minValue: locationFilters.rankings.range[0],
                  maxValue: maxRanking,
                  selectOnFocus: true,
                  onChange: (newMax) => {
                    if (!newMax) {
                      return;
                    }
                    setLocationFilters((prevLocationFilters) => {
                      if (prevLocationFilters.rankings.type !== 'custom') {
                        return prevLocationFilters;
                      } else {
                        return {
                          ...prevLocationFilters,
                          rankings: {
                            type: 'custom',
                            range: [
                              prevLocationFilters.rankings.range[0],
                              newMax,
                            ],
                          },
                        };
                      }
                    });
                  },
                }
              : {
                  value: maxRanking,
                  isDisabled: true,
                })}
            aria-label={'max rank'}
          />
        </div>
      </Checkbox>

      {locationTierConfiguration.length === 0 && unrankedLocationsCheckbox}
    </>
  );
};

/**
 * Filters for cities and counties.
 *
 * @see LocationMunicipalityFilterOptions
 */
export const MunicipalityFilters: React.FunctionComponent<{
  'aria-labelledby': string;
  locationFilters: Pick<LocationFilterOptions, 'municipalities'>;
  setLocationFilters: (
    updater: (prev: LocationFilterOptions) => LocationFilterOptions
  ) => void;
  municipalities: LocationMunicipalities;
}> = ({
  locationFilters,
  setLocationFilters,
  municipalities,
  'aria-labelledby': ariaLabelledBy,
}) => {
  // We can’t pass strings directly to <TagComboBox>, we need to wrap them in
  // objects.
  const cityItems = React.useMemo(
    () => municipalities.cities.map((c) => ({ city: c })),
    [municipalities.cities]
  );

  return (
    <RadioGroup
      aria-labelledby={ariaLabelledBy}
      value={locationFilters.municipalities.type}
      onChange={(value) => {
        if (value === 'all') {
          setLocationFilters((prev) => ({
            ...prev,
            municipalities: { type: 'all' },
          }));
        } else if (value === 'counties') {
          setLocationFilters((prev) => ({
            ...prev,
            municipalities: { type: 'counties', countySlugs: [] },
          }));
        } else if (value === 'cities') {
          setLocationFilters((prev) => ({
            ...prev,
            municipalities: { type: 'cities', cities: [] },
          }));
        }
      }}
    >
      <Radio value="all">All municipalities</Radio>

      <Radio value="counties" isDisabled={municipalities.counties.length === 0}>
        By counties
      </Radio>

      {locationFilters.municipalities.type === 'counties' && (
        <TagComboBox
          items={municipalities.counties}
          selectedKeys={locationFilters.municipalities.countySlugs}
          onSelectionChange={(keys) =>
            typeof keys !== 'string' &&
            setLocationFilters((prev) => ({
              ...prev,
              municipalities: {
                type: 'counties',
                countySlugs: [...keys] as CountySlug[],
              },
            }))
          }
        >
          {(c) => <Item key={c.slug}>{c.name}</Item>}
        </TagComboBox>
      )}

      <Radio value="cities" isDisabled={municipalities.cities.length === 0}>
        By cities
      </Radio>

      {locationFilters.municipalities.type === 'cities' && (
        <TagComboBox
          items={cityItems}
          selectedKeys={locationFilters.municipalities.cities}
          onSelectionChange={(keys) =>
            typeof keys !== 'string' &&
            setLocationFilters((prev) => ({
              ...prev,
              municipalities: {
                type: 'cities',
                cities: [...keys] as string[],
              },
            }))
          }
        >
          {({ city }) => <Item key={city}>{city}</Item>}
        </TagComboBox>
      )}
    </RadioGroup>
  );
};

/**
 * Extracts the cities and counties from a list of locations so that they can be
 * used as choices in filtering.
 *
 * Returns values in alphabetical order.
 *
 * @see MunicipalityFilters
 */
export function municipalitiesFromLocations(
  locations: Iterable<AssignmentLocation>
): LocationMunicipalities {
  const citySet = new Set<string>();
  const countySlugsToCounties = new Map<CountySlug, ApiCounty>();

  for (const location of locations) {
    if (location.city) {
      citySet.add(location.city);
    }

    if (location.county && location.county.slug) {
      countySlugsToCounties.set(location.county.slug, location.county);
    }
  }

  const countySlugs = [...countySlugsToCounties.keys()] as CountySlug[];
  // Sorting alphabetically by slug is close enough to sorting the county names
  // alphabetically.
  countySlugs.sort();

  const cities = [...citySet];
  cities.sort();

  return {
    counties: countySlugs.map((slug) => countySlugsToCounties.get(slug)!),
    cities,
  };
}

/**
 * Filters for location type (early vote vs. election day)
 *
 * @see LocationMunicipalityTypeFilterOptions
 */
export const MunicipalityTypeFilters: React.FunctionComponent<{
  'aria-labelledby': string;
  locationFilters: LocationFilterOptions;
  setLocationFilters: (
    updater: (prev: LocationFilterOptions) => LocationFilterOptions
  ) => void;
}> = ({
  locationFilters,
  setLocationFilters,
  'aria-labelledby': ariaLabelledBy,
}) => (
  <RadioGroup
    aria-labelledby={ariaLabelledBy}
    value={locationFilters.locationType}
    onChange={(value) => {
      if (value === 'all') {
        setLocationFilters((prev) => ({
          ...prev,
          locationType: 'all',
        }));
      } else if (value === 'eday') {
        setLocationFilters((prev) => ({
          ...prev,
          locationType: 'eday',
        }));
      } else if (value === 'ev') {
        setLocationFilters((prev) => ({
          ...prev,
          locationType: 'ev',
        }));
      }
    }}
  >
    <Radio value="all">All locations</Radio>

    <Radio value="eday">Only Election Day</Radio>

    <Radio value="ev">Only Early Vote</Radio>
  </RadioGroup>
);
