import moment from 'moment';
import React from 'react';
import { LoaderFunctionArgs, useNavigation } from 'react-router-dom';

import CountySelect, {
  countiesToChoices,
} from '../../../components/presentational/form/county-select';
import SelectInput from '../../../components/presentational/form/select';
import StateSelect from '../../../components/presentational/form/state-select';
import LoadingOverlay from '../../../components/presentational/lbj/loading';
import Sidebar from '../../../components/presentational/lbj/sidebar';
import { State, states, US_NATIONAL_STATE_CODE } from '../../../constants';
import { LbjInputEvent } from '../../../decorators/input-props';
import { arrayToPaginationData } from '../../../decorators/paginated-component';
import { AppStore } from '../../../modules/flux-store';
import { loadCurrentUser } from '../../../route-handlers/app';

import {
  CountySlug,
  Iso8601String,
  ISO_8601_FORMAT,
} from '../../../services/common';
import * as DashboardService from '../../../services/dashboard-service';
import {
  ApiLineLengthCounts,
  ApiLineLengthCountsGroupBy,
} from '../../../services/dashboard-service';
import * as LbjSharedService from '../../../services/lbj-shared-service';
import { ApiCounty, ApiElection } from '../../../services/lbj-shared-service';
import { orderingToSortProps } from '../../../utils/list-helpers';

import {
  MakeQueryFieldsFn,
  parseRequestQueryState,
  UpdateQueryStateFn,
  useRouterQueryState,
} from '../../../utils/query-state';
import { assertUnreachable } from '../../../utils/types';

import { DASHBOARD_USER_ROLES } from '../dashboard-utils';

import DashboardLineLengthsTable from './DashboardLineLengthsTable';

const DEFAULT_LINE_LENGTHS_COUNT_SIZE = 10;

const VIEW_BY_CHOICES = {
  county: 'County',
  state: 'State',
} as const;

const VIEW_BY_LABELS = {
  county: 'Counties',
  state: 'States',
} as const;

const RECENCY_CHOICES = {
  '1': 'Last Hour',
  '2': 'Last 2 Hours',
  '24': 'All Reports',
} as const;

export type DashboardLineLengthsPageFilters = {
  offset: number;
  size: number;

  state: State | undefined;
  county: CountySlug | undefined;

  recency: '1' | '2' | '24';

  ordering: string;
  group_by: ApiLineLengthCountsGroupBy;

  /**
   * For testing, to override using the current time as the basis for relative
   * checkin times.
   */
  now: Date | undefined;
};

function makeDashboardLineLengthsFiltersQueryFields(
  currentElection: ApiElection
): MakeQueryFieldsFn<DashboardLineLengthsPageFilters> {
  return (q) => ({
    offset: q.number(0),
    size: q.number(DEFAULT_LINE_LENGTHS_COUNT_SIZE),

    // If the current election is national, allow any state to be chosen.
    // Otherwise silently force the state of the current election.
    state:
      currentElection.state === US_NATIONAL_STATE_CODE
        ? q.state({ ignoreUs: true })
        : q.silent(currentElection.state),
    county: q.county(),

    recency: q.oneOf(RECENCY_CHOICES, '24'),

    group_by: q.oneOf(VIEW_BY_CHOICES, 'county'),
    ordering: q.string('Name'),

    now: q.date(ISO_8601_FORMAT),
  });
}

const DashboardLineLengthsPage: React.FunctionComponent<{
  currentElection: ApiElection;
  results: ApiLineLengthCounts[];
  counties: ApiCounty[] | undefined;
}> = (props) => {
  const { state: navigationState } = useNavigation();
  const isLoading = navigationState === 'loading';

  const [filters, updateFilters] =
    useRouterQueryState<DashboardLineLengthsPageFilters>(
      makeDashboardLineLengthsFiltersQueryFields(props.currentElection)
    );

  return (
    <DashboardLineLengthsPageView
      {...props}
      isLoading={isLoading}
      filters={filters}
      updateFilters={updateFilters}
    />
  );
};

export const DashboardLineLengthsPageView: React.FunctionComponent<{
  currentElection: ApiElection;
  counties: ApiCounty[] | undefined;
  results: ApiLineLengthCounts[];
  filters: DashboardLineLengthsPageFilters;
  updateFilters: UpdateQueryStateFn<DashboardLineLengthsPageFilters>;
  isLoading: boolean;
}> = ({
  currentElection,
  counties,
  filters,
  updateFilters,
  isLoading,
  results,
}) => {
  return (
    <div className="lbj-page-columns">
      <Sidebar title="View By" collapsible>
        <div className="lbj-column-content">
          <SelectInput
            name="group_by"
            title="View By"
            value={filters.group_by}
            choices={VIEW_BY_CHOICES}
            onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
              updateFilters({
                group_by: ev.currentTarget.value as ApiLineLengthCountsGroupBy,
              });
            }}
          />
        </div>

        <div className="lbj-column-label lbj-divider">
          <h4>Filters</h4>
        </div>

        <div className="lbj-column-content">
          <SelectInput
            title="Recency Today"
            name="recency"
            value={filters.recency}
            onChange={(ev: LbjInputEvent<keyof typeof RECENCY_CHOICES>) => {
              updateFilters({ recency: ev.target.value });
            }}
            choices={RECENCY_CHOICES}
          />

          <StateSelect
            disabled={currentElection.state !== US_NATIONAL_STATE_CODE}
            includeNational={false}
            value={filters.state ?? null}
            onChange={(ev) =>
              updateFilters({
                state: ev.target.value ?? undefined,
                // reset dependent filters
                county: undefined,
              })
            }
          />

          <CountySelect
            disabled={
              filters.state === undefined || !counties || counties.length === 0
            }
            counties={countiesToChoices(counties)}
            value={filters.county ?? null}
            onChange={(ev) => {
              updateFilters({
                county: ev.target.value ?? undefined,
              });
            }}
          />
        </div>
      </Sidebar>

      <div className="lbj-main">
        <div className="lbj-main-title lbj-column-label">
          <DashboardLineLengthsActiveFilters
            filters={filters}
            counties={counties}
          />
        </div>

        <div className="lbj-column-content-wrapper">
          {isLoading && <LoadingOverlay />}

          <DashboardLineLengthsTable
            results={results}
            paginationData={arrayToPaginationData(results, filters)}
            setPage={({ offset, size }) => updateFilters({ offset, size })}
            {...orderingToSortProps(filters, updateFilters)}
            viewByValue={VIEW_BY_CHOICES[filters.group_by]}
          />
        </div>
      </div>
    </div>
  );
};

const DashboardLineLengthsActiveFilters: React.FunctionComponent<{
  filters: Pick<
    DashboardLineLengthsPageFilters,
    'group_by' | 'state' | 'county'
  >;
  counties: ApiCounty[] | undefined;
}> = ({ filters, counties }) => {
  const labels: string[] = [];

  labels.push(VIEW_BY_LABELS[filters.group_by]);

  if (filters.state) {
    labels.push(states[filters.state]);
  }

  if (filters.county !== undefined && counties) {
    const county = counties.find(({ slug }) => slug === filters.county);
    if (county) {
      labels.push(county.name);
    }
  }

  return (
    <h4 className="font-bold">
      Showing:{' '}
      {labels.map((label, idx) => (
        <span key={idx}>{label}</span>
      ))}
    </h4>
  );
};

export async function loadDashboardLineLengthsPage(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<React.ComponentProps<typeof DashboardLineLengthsPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: DASHBOARD_USER_ROLES,
  });

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const filters = parseRequestQueryState<DashboardLineLengthsPageFilters>(
    request,
    makeDashboardLineLengthsFiltersQueryFields(currentElection)
  );

  const now = filters.now ? moment(filters.now) : moment();

  let startTime: Iso8601String;
  let endTime: Iso8601String;

  switch (filters.recency) {
    case '24':
      // Loads checkins from midnight to midnight, local time in the browser.
      //
      // The old version of this code loaded from 5am today to 5am tomorrow…
      // this may have been an attempt to compensate for EST. Doing midnight ->
      // midnight for now since it makes the most sense but could update this if
      // there’s a need.
      startTime = now.clone().startOf('day').utc().format() as Iso8601String;
      endTime = now.clone().endOf('day').utc().format() as Iso8601String;
      break;

    case '1':
    case '2':
      startTime = now
        .clone()
        .subtract(Number(filters.recency), 'hours')
        .utc()
        .format() as Iso8601String;
      endTime = now.clone().utc().format() as Iso8601String;
      break;

    default:
      assertUnreachable(filters.recency);
  }

  const lineLengthsResp = DashboardService.getLineLengthCount({
    offset: filters.offset,
    size: filters.size,
    group_by: filters.group_by,
    ordering: filters.ordering,
    ...(filters.state !== undefined ? { state: filters.state } : {}),
    ...(filters.county !== undefined ? { county: filters.county } : {}),

    checkin_time__gte: startTime,
    checkin_time__lt: endTime,
  });

  const countiesResp = filters.state
    ? LbjSharedService.getCounties(filters.state)
    : undefined;

  return {
    currentElection,
    results: (await lineLengthsResp).line_lengths,
    counties: (await countiesResp)?.counties,
  };
}

export default DashboardLineLengthsPage;
