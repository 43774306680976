import cx from 'classnames';
import { Immutable } from 'immer';
import React from 'react';

import { IconButton } from '../../../components/form';

import { ApiLocationHours } from '../../../services/assignment-service';
import { formatTime, TimeString } from '../../../services/common';
import {
  ApiLocationTierConfiguration,
  getLanguageIsoCode,
} from '../../../services/lbj-shared-service';

import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentUser,
} from '../assignment-state';
import {
  DistanceMiLookupFunc,
  getVolunteerAttributes,
  shiftRowsFromAssignments,
  volunteerLanguagesToLanguageNames,
} from '../assignment-utils';

import { describeDistanceMi, iconForDistanceMi } from './location-table-utils';

/**
 * Component for a table cell when we’re showing all dates.
 *
 * Double-clicking on the cell opens up the new assignment modal.
 */
const LocationDayCell: React.FunctionComponent<{
  location: Omit<AssignmentLocation, 'hours'>;
  /**
   * {@link ApiLocationHours} instance for this specific location/day pair, or
   * `null` if this location is not enabled (ev/eday) for this date.
   */
  hours: ApiLocationHours | null;
  assignments: (AssignmentRecord & { type: 'poll' })[];
  usersById: Immutable<Map<number, AssignmentUser>>;
  tierConfiguration: ApiLocationTierConfiguration[];
  expanded?: boolean | undefined;
  bulkEditModeType: 'shifts' | null;
  getDistanceMi: DistanceMiLookupFunc;
  doOpenNewAssignmentModal: (
    location: Omit<AssignmentLocation, 'hours'>,
    hours: ApiLocationHours
  ) => void;
  acceptAssignments: (
    assignments: (AssignmentRecord & { type: 'poll' })[]
  ) => void;
  changeShiftCount: (
    location: Omit<AssignmentLocation, 'hours'>,
    hours: ApiLocationHours,
    delta: number
  ) => void;
}> = ({
  location,
  hours,
  assignments,
  usersById,
  tierConfiguration,
  expanded = false,
  bulkEditModeType,
  doOpenNewAssignmentModal,
  getDistanceMi,
  acceptAssignments,
  changeShiftCount,
}) => {
  if (!hours) {
    return (
      // This is the case where the location is not enabled for either EV or
      // Eday. We draw a diagonal line through the box.
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        className="absolute top-0 left-0 h-full w-full p-0"
      >
        <line
          x1="5"
          y1="80"
          x2="95"
          y2="20"
          strokeOpacity={0.75}
          strokeWidth={2}
          stroke="#d0d0d0"
          // Keeps the stroke from distorting based on how it’s squished
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    );
  }

  const { fullRows, amRows, pmRows } = shiftRowsFromAssignments(
    location,
    assignments,
    hours,
    tierConfiguration
  );

  const hasNoShifts = fullRows.length + amRows.length + pmRows.length === 0;

  const unassignedFullRows = fullRows.filter((v) => v === null).length;
  const unassignedAmRows = amRows.filter((v) => v === null).length;
  const unassignedPmRows = pmRows.filter((v) => v === null).length;

  // Don’t allow adding more than 4, since the UI can’t handle it.
  const canAddShifts =
    fullRows.length + Math.max(amRows.length, pmRows.length) < 4;

  // Only allow removing shifts if we can remove a full day or both am and pm.
  const canSubtractShifts =
    unassignedFullRows > 0 || (unassignedAmRows > 0 && unassignedPmRows > 0);

  const bulkEditControls =
    bulkEditModeType === 'shifts' ? (
      <div className="flex flex-col justify-center gap-1">
        <IconButton
          icon="add_circle_outline"
          onPress={() => changeShiftCount(location, hours, 1)}
          isDisabled={!canAddShifts || hours.closed}
          title="Add shift"
          role="secondary"
        />
        <IconButton
          icon="remove_circle_outline"
          onPress={() => changeShiftCount(location, hours, -1)}
          isDisabled={!canSubtractShifts || hours.closed}
          title="Remove shift"
          role="secondary"
        />
      </div>
    ) : null;

  if (hours.closed || hasNoShifts) {
    let icon = '';
    let text = '';

    if (hours.closed) {
      icon = 'event_busy';

      if (assignments.length === 0) {
        text = 'Location is closed';
      } else {
        text = 'Location is closed but has scheduled assignments';
      }
    } else if (hasNoShifts) {
      if (expanded) {
        // Only show this icon if we’re expanded, since it feels very negative
        // without the explanatory text.
        icon = 'block';
      }
      text = 'No shifts set up for this location';
    }

    const ICON_SIZE_CLASSES = 'material-icons text-2xl';

    return (
      <div
        className="flex h-full w-full"
        onDoubleClick={() => {
          if (bulkEditModeType) {
            return;
          }

          doOpenNewAssignmentModal(location, hours);
        }}
      >
        <div className="flex flex-1 items-center justify-center gap-2">
          <div className="flex">
            <div className={cx(ICON_SIZE_CLASSES, 'text-gray-500')} aria-hidden>
              {icon}
            </div>

            {hours.closed && assignments.length > 0 && (
              // Show a warning error if there are assignments made on a location
              // that’s closed on that day.
              <div className={cx(ICON_SIZE_CLASSES, 'text-error')} aria-hidden>
                warning
              </div>
            )}
          </div>

          {expanded && <div className="flex-1 text-base">{text}</div>}
        </div>

        {bulkEditControls}
      </div>
    );
  }

  return (
    <div
      className="flex h-full w-full"
      onDoubleClick={() => {
        if (bulkEditModeType) {
          return;
        }

        doOpenNewAssignmentModal(location, hours);
      }}
    >
      <div className="flex flex-1 items-stretch gap-1">
        {fullRows.map((shift, idx) => (
          <div
            key={idx}
            className="flex w-6 flex-col items-stretch justify-center gap-1 align-top"
          >
            <ShiftBox shift={shift} full />
          </div>
        ))}

        {[...Array(Math.max(amRows.length, pmRows.length))].map((_, idx) => {
          return (
            <div
              key={idx}
              className="flex w-6 flex-col items-stretch justify-center gap-1 align-top"
            >
              <ShiftBox shift={amRows[idx]} />
              <ShiftBox shift={pmRows[idx]} />
            </div>
          );
        })}

        {expanded && (
          <div className="ml-2 flex-1 self-center">
            <ExpandedShiftUsers
              locationId={location.id}
              assignments={assignments}
              usersById={usersById}
              getDistanceMi={getDistanceMi}
              emptyShifts={(() => {
                // We build this off of the rows data because it takes into
                // account assignments that are not strictly the same times as the
                // configured shifts. E.g. if someone is scheduled to go an extra
                // hour in the morning, this will keep us from writing “No
                // Assignments” next to text for the exact morning shift times.
                const emptyShifts: ShiftKey[] = [];

                if (
                  fullRows.length &&
                  fullRows.filter((a) => !!a).length === 0
                ) {
                  emptyShifts.push(`${hours.open_time}-${hours.close_time}`);
                }

                if (amRows.length && amRows.filter((a) => !!a).length === 0) {
                  emptyShifts.push(
                    `${hours.open_time}-${hours.shift_change_time!}`
                  );
                }

                if (pmRows.length && pmRows.filter((a) => !!a).length === 0) {
                  emptyShifts.push(
                    `${hours.shift_change_time!}-${hours.close_time}`
                  );
                }

                return emptyShifts;
              })()}
            />
          </div>
        )}

        {expanded && !!assignments.find((a) => a.source === 'auto') && (
          // <span> rather than <TextButton> so that the react-aria table stuff
          // keeps focus on the cell itself rather than moving it in to the
          // button.
          //
          // TODO(fiona): Sort out how we want focus to work in these tables.
          <span
            className="leading-0 cursor-pointer self-center text-xs font-bold uppercase text-accent focus:outline-dotted focus:outline-1"
            onClick={() =>
              acceptAssignments(assignments.filter((a) => a.source === 'auto'))
            }
          >
            <span className="material-icons relative top-0.5 text-sm">
              check_circle
            </span>{' '}
            Accept{' '}
            {assignments.filter((a) => a.source === 'auto').length === 1
              ? 'Suggestion'
              : 'Suggestions'}
          </span>
        )}
      </div>

      {bulkEditControls}
    </div>
  );
};

/**
 * Key type to use when grouping shifts: startTime-endTime
 */
type ShiftKey = `${TimeString}-${TimeString}`;

/**
 * Shows a list of shifts and the users assigned to them.
 */
const ExpandedShiftUsers: React.FunctionComponent<{
  locationId: number;
  assignments: AssignmentRecord[];
  usersById: Immutable<Map<number, AssignmentUser>>;
  getDistanceMi: DistanceMiLookupFunc;
  /** Keys for shifts with no assignments */
  emptyShifts: ShiftKey[];
}> = ({ locationId, assignments, usersById, getDistanceMi, emptyShifts }) => {
  // The code in this component relies on the startTime and endTime being in ISO
  // time format, which means that we can compare them as strings.

  const usersByShiftTimes = new Map<ShiftKey, (AssignmentUser | null)[]>();

  for (const emptyShift of emptyShifts) {
    usersByShiftTimes.set(emptyShift, []);
  }

  for (const assignment of assignments) {
    const shiftTimeKey: ShiftKey = `${assignment.startTime}-${assignment.endTime}`;

    if (!usersByShiftTimes.has(shiftTimeKey)) {
      usersByShiftTimes.set(shiftTimeKey, []);
    }

    usersByShiftTimes
      .get(shiftTimeKey)!
      .push(usersById.get(assignment.userId) ?? null);
  }

  const shiftTimeKeys = [...usersByShiftTimes.keys()];
  shiftTimeKeys.sort();

  return (
    <div className="text-sm leading-snug">
      {shiftTimeKeys.map((shiftTimeKey) => {
        const users = usersByShiftTimes.get(shiftTimeKey)!;
        // See `ShiftKey`
        const [startTime, endTime] = shiftTimeKey.split('-') as [
          TimeString,
          TimeString
        ];

        return (
          <React.Fragment key={shiftTimeKey}>
            <div
              className={cx('my-1', {
                // If there are only two shifts, render as blocks so that
                // they’re on separate lines. Otherwise just jumble it all in
                // one big paragraph because we don’t have too much vertical
                // space.
                block: shiftTimeKeys.length === 2,
                'mr-2 inline': shiftTimeKeys.length !== 2,
              })}
            >
              <strong className="whitespace-nowrap">
                {formatTime(startTime, { tight: true })}–
                {formatTime(endTime, { tight: true })}:&nbsp;
              </strong>

              {users.length === 0 && (
                <span className="italic">No assignments</span>
              )}

              {users
                // non-breaking space in names
                .map((u, idx) => {
                  const distanceMi = u ? getDistanceMi(locationId, u.id) : null;
                  const showDistanceWarning =
                    distanceMi !== null &&
                    typeof u?.max_distance_miles === 'number' &&
                    distanceMi / u.max_distance_miles >= 0.75;
                  const {
                    isExperienced,
                    isLegalCommunity,
                    hasCarAccess,
                    languageTags,
                  } = u
                    ? getVolunteerAttributes(u)
                    : {
                        isExperienced: false,
                        isLegalCommunity: false,
                        hasCarAccess: false,
                        languageTags: [],
                      };
                  const languageNames =
                    volunteerLanguagesToLanguageNames(languageTags);

                  return (
                    <React.Fragment key={idx}>
                      {idx !== 0 && ', '}
                      {u && (
                        <span>
                          {u.first_name}&nbsp;{u.last_name}
                          <span className="relative top-[2px] ml-1 inline-flex cursor-default items-end align-baseline text-gray-700">
                            <span
                              className="material-icons text-base leading-none"
                              title={describeDistanceMi(
                                distanceMi,
                                u.max_distance_miles
                              )}
                            >
                              {showDistanceWarning && (
                                <span className="text-yellow-900">
                                  taxi_alert
                                </span>
                              )}
                              <span>{iconForDistanceMi(distanceMi)}</span>
                            </span>

                            {isExperienced && (
                              <span
                                className="material-icons cursor-default align-bottom text-base leading-none"
                                title="Experienced Observer"
                              >
                                military_tech
                              </span>
                            )}

                            {isLegalCommunity && (
                              <span
                                className="material-icons cursor-default align-bottom text-base leading-none"
                                title="Legal Community"
                              >
                                gavel
                              </span>
                            )}

                            {hasCarAccess && (
                              <span
                                className="material-icons cursor-default align-bottom text-base leading-none"
                                title="Has Car Access"
                              >
                                garage
                              </span>
                            )}

                            {languageTags.length > 0 && (
                              <>
                                <span
                                  className="material-icons cursor-default align-bottom text-base leading-none"
                                  title={`Speaks ${languageNames.join(', ')}`}
                                >
                                  chat
                                </span>
                                <span
                                  className="align-bottom leading-none"
                                  title={`Speaks ${languageNames.join(', ')}`}
                                >
                                  {languageTags.length > 1
                                    ? `${languageTags.length}`
                                    : `${getLanguageIsoCode(languageTags[0])}`}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      )}
                      {!u && 'Unknown User'}
                    </React.Fragment>
                  );
                })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LocationDayCell;

export const ShiftBox: React.FunctionComponent<{
  shift: Pick<AssignmentRecord, 'source'> | null | undefined;
  full?: boolean | undefined;
}> = ({ shift, full = false }) => {
  const COMMON_CLASSES =
    'rounded-sm flex flex-col items-center justify-center cursor-default';
  const FULL_SHIFT_CLASSES = 'bg-primary';
  const SUGGESTED_SHIFT_CLASSES = 'bg-purple-500';
  const UNASSIGNED_SHIFT_CLASSES = 'border border-gray-500 bg-gray-200';

  return (
    <div
      className={cx(COMMON_CLASSES, {
        // h-13 = 2 * h-6 + gap-1, except there isn’t an h-13 so we do it in rems
        'h-[3.25rem]': full,
        'h-6': !full,
        [FULL_SHIFT_CLASSES]:
          shift?.source === 'server' || shift?.source === 'manual',
        [SUGGESTED_SHIFT_CLASSES]: shift?.source === 'auto',
        [UNASSIGNED_SHIFT_CLASSES]: shift === null,
      })}
    >
      {shift?.source === 'auto' && (
        <div className="material-icons text-base text-white">smart_toy</div>
      )}
      {shift?.source === 'manual' && (
        <div className="text-base text-white">•</div>
      )}
    </div>
  );
};
