import React from 'react';

import { issueCategories } from '../../../constants';
import { LbjInputEvent } from '../../../decorators/input-props';
import {
  IssueErrorData,
  NewIssueInProgress,
} from '../../../modules/issue/reducers';
import { IssueInProgressPayload } from '../../../utils/issue/map-state-to-issue-payload';
import { assertUnreachable } from '../../../utils/types';

import Autocomplete from './autocomplete';

/**
 * This component allows a user to select 1 to 3 categories and subcategories to
 * describe a voter's question or incident. This UI is a workaround to being unable to
 * upgrade libraries for a simpler UI.
 *
 * By design, there will only ever be a maximum of 3 categories per issue.
 * For simplicity, this component does not generate category fields dynamically.
 */
export default class CategorySelect extends React.Component<{
  issueData: NewIssueInProgress | IssueInProgressPayload;
  isDescriptionReadOnly: boolean;
  onChange: (ev: LbjInputEvent<string | null | undefined>) => void;
  errors: IssueErrorData;
  numCategories: number;
  setNumCategories: (numCategories: number) => void;
}> {
  constructor(props: CategorySelect['props']) {
    super(props);

    this.addCategory = this.addCategory.bind(this);
  }

  getIssueCategories(): { [category: string]: string } {
    const { issueData } = this.props;

    if (issueData.type) {
      let categories: string[];

      if (issueData.type === 'incident') {
        categories = Object.keys(issueCategories.incident);
      } else if (issueData.type === 'inquiry') {
        categories = Object.keys(issueCategories.inquiry);
      } else {
        assertUnreachable(issueData.type);
      }

      // Convert the array of categories into a map to use as dropdown options
      return categories.reduce<{ [category: string]: string }>(
        (choices, category) => {
          choices[category] = category;
          return choices;
        },
        {}
      );
    } else {
      return {};
    }
  }

  getIssueSubCategories(category: string | null | undefined): {
    [subcategory: string]: string;
  } {
    const { issueData } = this.props;

    if (issueData.type && category) {
      // We assign to a more general type so that we can index with `string`.
      // Remember that there’s no guarantee that the category the issue was
      // saved with is something the frontend recognizes.
      const categories: { [category: string]: readonly string[] } =
        issueCategories[issueData.type];

      // Saves us in case the issue’s category is no longer configured. See
      // LBJ-720.
      const subCategories = categories[category] ?? [];

      // Convert the array of subcategories into a map to use as dropdown options
      return subCategories.reduce<{ [subcategory: string]: string }>(
        (choices, subCategory) => {
          choices[subCategory] = subCategory;
          return choices;
        },
        {}
      );
    } else {
      return {};
    }
  }

  addCategory() {
    this.props.setNumCategories(this.props.numCategories + 1);
  }

  removeCategory(categoryNumber: number) {
    const { issueData, onChange } = this.props;
    // If the second or third category was removed...
    if (categoryNumber > 1) {
      if (categoryNumber === 2) {
        // Shift the third category's values into the second
        onChange({
          target: { name: 'category_2', value: issueData.category_3 },
        });
        onChange({
          target: { name: 'sub_category_2', value: issueData.sub_category_3 },
        });
      }
      // Blank out the third category's values
      onChange({ target: { name: 'category_3', value: null } });
      onChange({ target: { name: 'sub_category_3', value: null } });
    }
    this.props.setNumCategories(this.props.numCategories - 1);
  }

  render() {
    const { issueData, isDescriptionReadOnly, onChange, errors } = this.props;
    const placeholder = '';

    return (
      <div className="category-select">
        <div className="category-select-group">
          <Autocomplete
            title="Category"
            name="category"
            type="text"
            placeholder={placeholder}
            choices={this.getIssueCategories()}
            value={issueData.category ?? null}
            onChange={onChange}
            errors={errors.category}
            disabled={isDescriptionReadOnly || !issueData.type}
            required
          />
          <Autocomplete
            title="Subcategory"
            name="sub_category"
            type="text"
            placeholder={placeholder}
            choices={this.getIssueSubCategories(issueData.category)}
            value={issueData.sub_category ?? null}
            onChange={onChange}
            errors={errors.sub_category}
            disabled={isDescriptionReadOnly || !issueData.category}
            required
          />
        </div>
        {this.props.numCategories > 1 && (
          <div className="category-select-group">
            <div className="category-heading">
              <span>Second Category</span>
              <a className="remove" onClick={() => this.removeCategory(2)}>
                Remove category
              </a>
            </div>
            <Autocomplete
              title="Category"
              name="category_2"
              type="text"
              placeholder={placeholder}
              choices={this.getIssueCategories()}
              value={issueData.category_2 ?? null}
              onChange={onChange}
              errors={errors.category_2}
              disabled={isDescriptionReadOnly || !issueData.type}
              required
            />
            <Autocomplete
              title="Subcategory"
              name="sub_category_2"
              type="text"
              placeholder={placeholder}
              choices={this.getIssueSubCategories(issueData.category_2)}
              value={issueData.sub_category_2 ?? null}
              onChange={onChange}
              errors={errors.sub_category_2}
              disabled={isDescriptionReadOnly || !issueData.category_2}
              required
            />
          </div>
        )}
        {this.props.numCategories > 2 && (
          <div className="category-select-group">
            <div className="category-heading">
              <span>Third Category</span>
              <a className="remove" onClick={() => this.removeCategory(3)}>
                Remove category
              </a>
            </div>
            <Autocomplete
              title="Category"
              name="category_3"
              type="text"
              placeholder={placeholder}
              choices={this.getIssueCategories()}
              value={
                this.props.numCategories > 2
                  ? issueData.category_3 ?? null
                  : null
              }
              onChange={onChange}
              errors={errors.category_3}
              disabled={isDescriptionReadOnly || !issueData.type}
              required
            />
            <Autocomplete
              title="Subcategory"
              name="sub_category_3"
              type="text"
              placeholder={placeholder}
              choices={this.getIssueSubCategories(issueData.category_3)}
              value={issueData.sub_category_3 ?? null}
              onChange={onChange}
              errors={errors.sub_category_3}
              disabled={isDescriptionReadOnly || !issueData.category_3}
              required
            />
          </div>
        )}
        {this.props.numCategories < 3 && (
          <a onClick={this.addCategory}>+ Add another category</a>
        )}
        {this.props.numCategories === 3 && (
          <div className="microcopy">
            You cannot add more than 3 categories.
          </div>
        )}
      </div>
    );
  }
}
