import React from 'react';
import { LoaderFunctionArgs, useRevalidator } from 'react-router-dom';

import { PageTitle } from '../../components/common';

import EmptyAlert from '../../components/presentational/lbj/empty-alert';
import LoadingOverlay from '../../components/presentational/lbj/loading';
import Toast, { useToast } from '../../components/presentational/lbj/toast';
import { PaginatedPage } from '../../decorators/paginated-component';

import { AppStore } from '../../modules/flux-store';
import { ToastData, ToastRecord } from '../../modules/toast';
import { loadCurrentUser } from '../../route-handlers/app';

import { PaginatedResponse } from '../../services/common';
import * as IssueService from '../../services/issue-service';
import * as LbjService from '../../services/lbj-shared-service';
import { ApiElection } from '../../services/lbj-shared-service';
import { ApiCurrentUser } from '../../services/user-service';

import { PaginationData } from '../../utils/lbj/map-state-to-pagination';
import { orderingToSortProps } from '../../utils/list-helpers';
import {
  useRouterQueryState,
  parseRequestQueryState,
} from '../../utils/query-state';
import {
  LbjPermissions,
  roleMayExport,
  roleMayUseElectionSpecificFilters,
} from '../../utils/user/map-state-to-lbj-permissions';

import ActiveFilters from './active-filters';
import IssueList from './issue-list';
import { UnconnectedIssueListExport } from './issue-list-export';
import {
  filtersToIssueListApiParams,
  IssuesListFilters,
  IssuesListFilterUpdater,
  IssuesListLbjData,
  loadIssuesListLbjData,
  makeIssuesListFilterLabels,
  makeIssuesListFiltersQueryFields,
  makeUpdateFiltersWithDependencies,
  useIssueCsvExport,
  useUpdateListPolling,
} from './issues-list-utils';

import ResultsFilterSidebar from './results-filter-sidebar';

export type ResultsIndexPermissions = Pick<
  LbjPermissions,
  'canUseElectionSpecificFilters' | 'canExport'
>;

function makeResultsIndexFiltersQueryFields(
  currentElection: LbjService.ApiElection
) {
  return makeIssuesListFiltersQueryFields(currentElection, (q) => ({
    // Ignores the view and limits the issues to just results issues.
    view: q.silent('ALL'),
    category: q.silent(['Results']),
    sub_category: q.silent(['Vote Tally']),
  }));
}

/**
 * Wrapper component for {@link ResultsIndexView} that handles network requests
 * and interfacing with the query string for managing state.
 */
export const ResultsIndexWithState: React.FunctionComponent<
  {
    currentUser: ApiCurrentUser;
    currentUserElection: ApiCurrentUser['user_elections'][0];
    issueListResponse: PaginatedResponse<IssueService.IssueListApiIssue>;
  } & IssuesListLbjData
> = ({
  currentUser,
  currentUserElection,
  issueListResponse,
  elections,
  regions,
  counties,
  precincts,
  locations,
  districts,
  boilerRooms,
}) => {
  const [toastData, { showToast, dismissToast }] = useToast();

  const currentElection = currentUserElection.election;

  const [
    filters,
    updateQueryFilters,
    {
      reset: resetQueryFilters,
      paramsFromUpdates: queryFilterParamsFromUpdates,
    },
  ] = useRouterQueryState<IssuesListFilters>(
    makeResultsIndexFiltersQueryFields(currentElection)
  );

  // These are filters and other values that should apply both to loading the
  // list of issues and to polling for new issues.
  const commonListApiParams = filtersToIssueListApiParams(filters);

  // Used to trigger react-router to reload our data if the issues list updates.
  const revalidator = useRevalidator();

  useUpdateListPolling({
    currentUser,
    params: commonListApiParams,
    results: issueListResponse.results,
    refetch: () => revalidator.revalidate(),
    onFirstPage: filters.offset === 0,
  });

  const updateFilters = makeUpdateFiltersWithDependencies(
    elections,
    filters,
    updateQueryFilters
  );

  const [isCsvExporting, startCsvExport] = useIssueCsvExport();

  const permissions: ResultsIndexPermissions = {
    canExport: roleMayExport(currentUser.role),
    canUseElectionSpecificFilters: roleMayUseElectionSpecificFilters(
      currentUser.role
    ),
  };

  return (
    <ResultsIndexView
      permissions={permissions}
      currentElection={currentElection}
      issueListResponse={issueListResponse}
      elections={elections}
      regions={regions}
      counties={counties}
      precincts={precincts}
      locations={locations}
      districts={districts}
      boilerRooms={boilerRooms}
      filters={filters}
      updateFilters={updateFilters}
      clearFilters={resetQueryFilters}
      filtersToSearchParams={(filters) =>
        // We use resetAll since we don’t want to carry over any current filter
        // values for the URLs this function will generate.
        queryFilterParamsFromUpdates(filters, true)
      }
      paginationAction={({ offset, size }) => {
        updateQueryFilters({ offset, size });
      }}
      toastData={toastData}
      showToast={showToast}
      dismissToast={dismissToast}
      isCsvExporting={isCsvExporting}
      startCsvExport={() => startCsvExport(currentUser, commonListApiParams)}
    />
  );
};

/**
 * View component for the results list.
 */
export const ResultsIndexView: React.FunctionComponent<
  IssuesListLbjData & {
    permissions: ResultsIndexPermissions;
    currentElection: LbjService.ApiElection;
    issueListResponse: PaginatedResponse<IssueService.IssueListApiIssue>;
    filters: IssuesListFilters;
    updateFilters: IssuesListFilterUpdater;
    filtersToSearchParams: (
      filters: Partial<IssuesListFilters>
    ) => URLSearchParams;
    clearFilters: () => void;
    paginationAction: (page: PaginatedPage) => void;
    isCsvExporting: boolean;
    startCsvExport: () => Promise<string | null>;
    toastData: ToastRecord;
    showToast: (d: ToastData) => void;
    dismissToast: () => void;
  }
> = ({
  permissions,
  currentElection,
  issueListResponse,
  elections,
  regions,
  counties,
  precincts,
  locations,
  districts,
  boilerRooms,
  filters,
  updateFilters,
  clearFilters,
  filtersToSearchParams,
  paginationAction,
  isCsvExporting,
  startCsvExport,
  toastData,
  showToast,
  dismissToast,
}) => {
  const currentElectionState = currentElection.state;

  const resultsLabels = makeIssuesListFilterLabels(
    currentElectionState,
    currentElection.type,
    {
      elections,
      regions,
      counties,
      precincts,
      locations,
      districts,
      boilerRooms,
      // Results page doesn’t filter on any users, so we don’t need to preload
      // anything to populate the filters.
      preloadedUsers: [],
    }
  );

  const listRequestIsPending = issueListResponse === undefined;

  const paginationData = new PaginationData({
    isFirstPage: issueListResponse.previous === null,
    isLastPage: issueListResponse.next === null,
    listIsEmpty: issueListResponse.results.length === 0,
    offset: filters.offset,
    size: filters.size,
  });

  return (
    <div className="lbj-page-wrapper">
      <div className="lbj-page-columns">
        <ResultsFilterSidebar
          canUseElectionSpecificFilters={
            permissions.canUseElectionSpecificFilters
          }
          currentElectionState={currentElectionState}
          filters={filters}
          updateFilters={updateFilters}
          elections={elections}
          regions={regions}
          counties={counties}
          precincts={precincts}
          locations={locations}
          districts={districts}
          boilerRooms={boilerRooms}
        />

        <div className="issue-main">
          <Toast toastData={toastData} onDismiss={() => dismissToast()} />

          <div className="issue-header">
            <PageTitle>Results</PageTitle>

            <UnconnectedIssueListExport
              canExport={permissions.canExport}
              isExporting={isCsvExporting}
              showToast={showToast}
              startExport={startCsvExport}
            />
          </div>

          <hr />

          <ActiveFilters
            filters={filters}
            labels={resultsLabels}
            onClearFilter={(key) => updateFilters({ [key]: undefined })}
            onClearAll={() => clearFilters()}
          />

          <div className="lbj-main">
            <IssueList
              issues={issueListResponse.results}
              filterParams={filtersToSearchParams(filters)}
              listRequestIsPending={listRequestIsPending}
              paginationData={paginationData}
              paginationAction={paginationAction}
              emptyAlert={
                <EmptyAlert
                  header="Looks like there are no results tickets right now."
                  description="Tickets will appear here after they’ve been added."
                />
              }
              {...orderingToSortProps(filters, updateFilters)}
            />

            {listRequestIsPending && <LoadingOverlay />}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Loads the data necessary for {@link ResultsIndexWithState}.
 *
 * This includes the issues list and also the data to populate the filter
 * choices, such as elections, counties, precincts, &c.
 */
export async function loadResultsIndexPage(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<React.ComponentProps<typeof ResultsIndexWithState>> {
  const { currentUser, currentUserElection } = await loadCurrentUser(
    fluxStore,
    {
      allowedRoles: [
        'vpd',
        'deputy_vpd',
        'boiler_room_leader',
        'boiler_room_user',
        'hotline_manager',
        'hotline_worker',
        'poll_observer',
        'view_only',
      ],
    }
  );

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const filters = parseRequestQueryState<IssuesListFilters>(
    request,
    makeResultsIndexFiltersQueryFields(currentElection)
  );

  const issueListResp = IssueService.getIssues(
    {
      id: currentUser.get('id'),
      role: currentUser.get('role'),
    },
    {
      offset: filters.offset,
      size: filters.size,
      ...filtersToIssueListApiParams(filters),
    }
  );

  return {
    currentUser: currentUser.toJS() as ApiCurrentUser,
    currentUserElection:
      currentUserElection.toJS() as ApiCurrentUser['user_elections'][0],
    issueListResponse: await issueListResp,
    ...(await loadIssuesListLbjData(filters)),
  };
}
