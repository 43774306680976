import { AppState } from '../../modules/flux-store';
import * as LocalStoreService from '../../services/local-store-service';
import * as RavenService from '../../services/sentry-service';

import mapStateToNotificationFilters from './map-state-to-notifications-filters';

/**
 * Compare version strings and make sure that the the
 *
 * @return     {boolean}
 */
export function getNewVersionIsAvailable(
  latestFEVersion: string | null = '0.0.0',
  oldVersion = '0.0.0'
) {
  try {
    if (!latestFEVersion || !oldVersion) return false;

    const [latestMajor, latestMinor, latestBugfix] = latestFEVersion
      .split('.')
      .map((str) => Number(str)) as unknown as [string, string, string];
    const [major, minor, bugfix] = oldVersion
      .split('.')
      .map((str) => Number(str)) as unknown as [string, string, string];

    if (latestMajor > major) return true;
    if (latestMajor >= major && latestMinor > minor) return true;
    if (latestMajor >= major && latestMinor >= minor && latestBugfix > bugfix)
      return true;

    return false;
  } catch (e: any) {
    RavenService.captureException(e, {
      type: 'app',
      method: 'getNewVersionIsAvailable',
    });

    return false;
  }
}

export default function mapStateToAppNotifications(state: AppState) {
  const { notifications } = state;
  const shouldStopUpdatePolling =
    notifications.issue.unseenCountRequestErred ||
    notifications.location.unseenCountRequestErred;
  const currentFEVersion = LocalStoreService.getCurrentFEVersion();
  const newVersionIsAvailable = getNewVersionIsAvailable(
    currentFEVersion,
    process.env['FE_VERSION']
  );

  return {
    shouldStopUpdatePolling,
    newVersionIsAvailable,
    pollingInterval: notifications.pollingInterval,
    unseenNotificationCount: notifications.issue.unseenCount,
    locationChangeCount: notifications.location.unseenCount,
    notificationFilters: mapStateToNotificationFilters(state),
  };
}
