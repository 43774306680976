import React from 'react';
import {
  LoaderFunctionArgs,
  NavLink,
  Outlet,
  redirect,
  useSearchParams,
} from 'react-router-dom';

import { PageHeader } from '../../components/common';

/**
 * Layout component for Dashboard pages.
 *
 * Renders a header with links to each of the 3 sub-pages.
 */
const DashboardLayout: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  // Since state and county are all shared among the 3 pages, we include those
  // values when linking among them, if they’re present in the query string.

  const sharedSearchParams = new URLSearchParams();

  if (searchParams.has('state')) {
    sharedSearchParams.set('state', searchParams.get('state')!);
  }

  if (searchParams.has('county')) {
    sharedSearchParams.set('county', searchParams.get('county')!);
  }

  const sharedSearch = sharedSearchParams.toString();

  return (
    <div className="lbj-page-wrapper">
      <PageHeader title="Dashboard">
        <ul className="lbj-view-filters">
          <li>
            <NavLink
              to={{ pathname: 'summary', search: sharedSearch }}
              className={({ isActive }) => (isActive ? 'is-active' : undefined)}
            >
              Issues
            </NavLink>
          </li>

          <li>
            <NavLink
              to={{ pathname: 'line_length_counts', search: sharedSearch }}
              className={({ isActive }) => (isActive ? 'is-active' : undefined)}
            >
              Line Lengths Today
            </NavLink>
          </li>

          <li>
            <NavLink
              to={{ pathname: 'recent_line_lengths', search: sharedSearch }}
              className={({ isActive }) => (isActive ? 'is-active' : undefined)}
            >
              Line Lengths by Hour
            </NavLink>
          </li>
        </ul>
      </PageHeader>

      <Outlet />
    </div>
  );
};

/**
 * The old implementation of the dashboard used a `view` query parameter to
 * distinguish among its 3 views.
 *
 * Since we’re now doing those as separate paths, we have this loader to
 * generate redirects in case anyone has the old URLs bookmarked or saved
 * somewhere.
 */
export function dashboardViewRedirector({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);

  if (searchParams.has('view')) {
    const view = searchParams.get('view');

    // Preserving the other query parameters for the redirect.
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('view');
    const searchSuffix =
      [...newSearchParams].length > 0 ? `?${newSearchParams.toString()}` : '';

    switch (view) {
      case 'summary':
        throw redirect(`summary${searchSuffix}`);
      case 'line_length_counts':
        throw redirect(`line_length_counts${searchSuffix}`);
      case 'recent_line_lengths':
        throw redirect(`recent_line_lengths${searchSuffix}`);
    }
  }

  return null;
}

export default DashboardLayout;
