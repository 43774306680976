import _ from 'lodash';
import React from 'react';

import EmptyAlert from '../../../components/presentational/lbj/empty-alert';
import {
  PaginatedPage,
  PaginationWrapper,
} from '../../../decorators/paginated-component';
import { ApiTicketSummaryResult } from '../../../services/dashboard-service';
import { PaginationData } from '../../../utils/lbj/map-state-to-pagination';

export default class DashboardIssuesTable extends React.Component<{
  paginationData: PaginationData;
  setPage: (page: PaginatedPage) => void;

  results: ApiTicketSummaryResult[];

  /**
   * This is the label to use instead of “Name” so we can say _e.g._ “County”
   * or “State”.
   */
  viewByValue: string;

  sortSlug: string;
  sortDescending: boolean;
  toggleSort: (slug: string) => void;

  tableHeadingLabels: (keyof ApiTicketSummaryResult)[];
  tableHeadingOrderingValues: (keyof ApiTicketSummaryResult)[];
}> {
  renderHeadings() {
    const {
      viewByValue,
      tableHeadingLabels,
      tableHeadingOrderingValues,
      sortSlug,
      sortDescending,
      toggleSort,
    } = this.props;

    const cols = ['Name', ...tableHeadingOrderingValues, 'Total'];
    const labels = ['Name', ...tableHeadingLabels, 'Total'];
    const headings = [];

    for (let i = 0; i < cols.length; i++) {
      const slug = cols[i];
      const label = labels[i];

      if (slug) {
        let sortAttributes;

        const name = slug === 'Name' ? viewByValue : label;

        if (slug === sortSlug) {
          sortAttributes = {
            'data-sort': sortDescending ? 'DESC' : 'ASC',
            onClick: () => toggleSort(slug),
          };
        } else {
          sortAttributes = {
            'data-sort': '',
            onClick: () => toggleSort(slug),
          };
        }

        headings.push(
          <th key={`heading-${slug}`} data-slug={slug} {...sortAttributes}>
            {name}
          </th>
        );
      }
    }

    return <tr>{headings}</tr>;
  }

  renderPlace(place: ApiTicketSummaryResult, key: string) {
    const { tableHeadingOrderingValues } = this.props;

    return (
      <tr key={key}>
        <td>
          <a>{place.Name}</a>
        </td>

        {_.map(tableHeadingOrderingValues, (name) => {
          const val = place[name] || '0';
          return (
            <td key={`${name}-value`}>
              <a>{val}</a>
            </td>
          );
        })}

        <td>
          <a>{place.Total}</a>
        </td>
      </tr>
    );
  }

  render() {
    const { results, paginationData, setPage } = this.props;

    return (
      <PaginationWrapper paginationData={paginationData} setPage={setPage}>
        <div className="lbj-table-wrapper">
          <table className="lbj-table lbj-issue-list-table">
            <thead>{this.renderHeadings()}</thead>
            <tbody>{results.map((p) => this.renderPlace(p, p.id))}</tbody>
          </table>

          {results.length === 0 && (
            <EmptyAlert
              header="Looks like there are no issues to process right now."
              description="Click New issue to submit an issue. Issues will appear here after they’ve been added."
            />
          )}
        </div>
      </PaginationWrapper>
    );
  }
}
