import { DateValue, createCalendar } from '@internationalized/date';
import React from 'react';
import { RangeCalendarProps, useLocale, useRangeCalendar } from 'react-aria';
import { RangeCalendarState, useRangeCalendarState } from 'react-stately';

import { CalendarView } from './Calendar';

/**
 * Version of {@link Calendar} for a range of dates. Value type is an object
 * with `start` and `end` props.
 *
 * @see https://react-spectrum.adobe.com/react-aria/useRangeCalendar.html
 */
export const RangeCalendar: React.FunctionComponent<
  Omit<RangeCalendarProps<DateValue>, 'isDateUnavailable'> & {
    /** Dates that will be rendered in bold. */
    emphasizedDates?: DateValue[] | undefined;
    /** Number of months to show side-by-side. */
    monthCount?: number;

    /**
     * Overridden version of the default `isDateUnavailable` callback. This also
     * passes the `anchorDate`, which is set when the user has selected one
     * point of the range but not the other.
     *
     * You can use this to, for example, make dates farther away than a certain
     * limit unavailable during picking, to limit the size of the range.
     */
    isDateUnavailable?: (
      date: DateValue,
      anchorDate: DateValue | null
    ) => boolean;
  }
> = ({ emphasizedDates, monthCount = 1, isDateUnavailable, ...props }) => {
  const { locale } = useLocale();
  const state: RangeCalendarState = useRangeCalendarState({
    ...props,
    isDateUnavailable: (date) =>
      isDateUnavailable?.(date, state?.anchorDate ?? null) ?? false,
    visibleDuration: { months: monthCount },
    locale,
    createCalendar,
  });

  const ref = React.useRef<HTMLDivElement | null>(null);

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  return (
    <CalendarView
      ref={ref}
      state={state}
      calendarProps={calendarProps}
      prevButtonProps={prevButtonProps}
      nextButtonProps={nextButtonProps}
      title={title}
      emphasizedDates={emphasizedDates}
      monthCount={monthCount}
    />
  );
};
