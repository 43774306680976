import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';
import { filterFilters } from '../filters/common';

function filterUserFilters<OutK extends keyof FiltersBySection['USER']>(
  state: AppState,
  keys: OutK[]
) {
  return filterFilters(state.filters.USER, keys);
}

/**
 * Returns the relevant user filters.
 */
export default function mapStateToUserFilters(state: AppState) {
  return filterUserFilters(state, [
    'state',
    'county',
    'region',
    'assigned',
    'assignment_state',
    'search',
    'role',
    'tags',
    'size',
    'offset',
    'view',
    'invitation_status',
    'has_received_availability_survey',
    'has_received_training_invite',
    'has_checked_in',
    'needs_assignment_email',
  ]);
}
