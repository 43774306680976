import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import { AppState, toAppDispatch } from '../../../modules/flux-store';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import {
  ApiLocationWithAssignments,
  WithApiAssignmentExtras,
} from '../../../services/assignment-service';
import { MapFromJs } from '../../../utils/types';
import AssignmentCoverage from '../../presentational/assignment/assignment-coverage';
import AssignmentTimelineV2 from '../../presentational/assignment/assignment-timeline-v2';

const LocationItemV2: React.FunctionComponent<{
  dispatch?: Dispatch<any>;
  isShowingDetails: boolean;
  location: MapFromJs<ApiLocationWithAssignments & WithApiAssignmentExtras>;
  now: moment.Moment;
}> = (props) => {
  const { location, isShowingDetails, now } = props;
  const dispatch = toAppDispatch(props.dispatch);

  const showLocationDetails = () => {
    const { showDetails } = assignmentActionCreators;
    const { getNearbyLocationsAsync } = lbjActionCreators;
    const assignments = location.get('assignment_set');
    const isEditing = assignments.isEmpty(); // if there are no assignments, show the "add assignment" form

    dispatch(getNearbyLocationsAsync(location.get('id')));
    dispatch(
      showDetails(
        {
          location,
        },
        isEditing
      )
    );
  };

  /**
   * AssignmentTimelineV2 works in plain JS since it’s easier to type, so we
   * convert to plain JS and memoize it, since that can be expensive.
   */
  const assignments = React.useMemo(
    () =>
      location.get('assignment_set').toJS() as {
        [key: string]: ApiLocationWithAssignments['assignment_set'][string];
      },
    [location]
  );

  return (
    <tr
      onClick={showLocationDetails}
      className={isShowingDetails ? 'selected' : ''}
    >
      <td className="location">
        <strong>{location.get('name')}</strong>
      </td>
      <td className="address">
        {location.get('address') + ', ' + location.get('city')}
      </td>
      <td className="county">{location.get('county').get('name')}</td>
      <td className="coverage">
        <AssignmentCoverage
          coverage={location.getIn(['hours', 0, 'coverage'])}
        />
      </td>
      {/* Span two columns to accommodate the check-in status display */}
      <td colSpan={2}>
        {Object.entries(assignments).map(([key, assignment]) => (
          <AssignmentTimelineV2
            key={key}
            assignmentData={assignment}
            showCheckinStatus
            now={now}
          />
        ))}
      </td>
    </tr>
  );
};

export default connect(
  (
    { assignment }: AppState,
    { location }: Pick<React.ComponentProps<typeof LocationItemV2>, 'location'>
  ): Omit<React.ComponentProps<typeof LocationItemV2>, 'location' | 'now'> => {
    const assignmentEditor = assignment.assignmentEditor;

    const isShowingDetails =
      assignmentEditor.getIn(['parentResource', 'location', 'id']) ===
      location.get('id');

    return {
      isShowingDetails,
    };
  }
)(LocationItemV2);
