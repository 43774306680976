import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { assignmentViewTypes } from '../../../constants';
import { PaginationWrapper } from '../../../decorators/paginated-component';
import { AppState } from '../../../modules/flux-store';
import {
  ApiLocationWithAssignments,
  ApiPrecinctWithAssignments,
} from '../../../services/assignment-service';
import mapStateToPagination, {
  PaginationData,
} from '../../../utils/lbj/map-state-to-pagination';
import { queryToSearch, searchToQuery } from '../../../utils/routing-provider';
import { MapFromJs } from '../../../utils/types';
import LocationListV2 from '../../presentational/assignment/assignment-location-list-v2';
import PrecinctList from '../../presentational/assignment/assignment-precinct-list';
import LoadingOverlay from '../../presentational/lbj/loading';

/**
 * Component to wrap the list of locations or precincts for assignment.
 *
 * Takes one or the other based on the value of `currentView`.
 */
const PollingListContainerV2: React.FunctionComponent<
  {
    listRequestIsPending: boolean;
    listRequestErred: boolean;
    paginationData: PaginationData;
    now?: moment.Moment;
  } & (
    | {
        currentView: typeof assignmentViewTypes.PRECINCTS;
        listData: Immutable.List<MapFromJs<ApiPrecinctWithAssignments>>;
      }
    | {
        currentView: typeof assignmentViewTypes.LOCATIONS;
        listData: Immutable.List<MapFromJs<ApiLocationWithAssignments>>;
      }
  )
> = (props) => {
  const { listRequestIsPending, now = moment() } = props;
  const { PRECINCTS, LOCATIONS } = assignmentViewTypes;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <PaginationWrapper
      paginationData={props.paginationData}
      setPage={(page) => {
        const { pathname, search } = location;
        const query = searchToQuery(search);

        window.scrollTo(0, 0);

        navigate({
          pathname,
          search: queryToSearch(_.assign({}, query, page)),
        });
      }}
    >
      <div className="lbj-column-content assignment-list-container-v2">
        {listRequestIsPending && <LoadingOverlay />}

        {props.currentView === PRECINCTS && (
          <PrecinctList
            listRequestIsPending={props.listRequestIsPending}
            precincts={props.listData}
            listRequestErred={props.listRequestErred}
          />
        )}

        {props.currentView === LOCATIONS && (
          <LocationListV2
            listRequestIsPending={props.listRequestIsPending}
            locations={props.listData}
            listRequestErred={props.listRequestErred}
            now={now}
          />
        )}
      </div>
    </PaginationWrapper>
  );
};

export default connect(
  (
    state: AppState,
    {
      currentView,
    }: Pick<React.ComponentProps<typeof PollingListContainerV2>, 'currentView'>
  ): React.ComponentProps<typeof PollingListContainerV2> => {
    const { assignment } = state;

    const paginationData = mapStateToPagination(assignment, [
      'assignmentIndex',
      currentView,
    ]);

    if (currentView === assignmentViewTypes.LOCATIONS) {
      const locationListRequest = assignment.assignmentIndex.LOCATIONS;

      return {
        currentView: assignmentViewTypes.LOCATIONS,
        listData: locationListRequest.listData,
        listRequestIsPending: locationListRequest.requestIsPending,
        listRequestErred: locationListRequest.requestErred,
        paginationData,
      };
    } else {
      const precinctListRequest = assignment.assignmentIndex.PRECINCTS;

      return {
        currentView: assignmentViewTypes.PRECINCTS,
        listData: precinctListRequest.listData,
        listRequestIsPending: precinctListRequest.requestIsPending,
        listRequestErred: precinctListRequest.requestErred,
        paginationData,
      };
    }
  }
)(PollingListContainerV2);
