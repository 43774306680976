import * as Immutable from 'immutable';
import * as _ from 'lodash';

import { requestStatuses, State } from '../../constants';
import {
  ApiBoardOfElections,
  ApiBoilerRoom,
  ApiCounty,
  ApiElection,
  ApiLocation,
  ApiNearbyLocation,
  ApiPrecinct,
  ApiRegion,
  ApiUserTag,
} from '../../services/lbj-shared-service';
import {
  DEFAULT_LIST_REQUEST_RECORD_FIELDS,
  makeListRequestRecord,
  makeListRequestRecordObj,
  updateListRequestMap,
  updateListRequestRecord,
} from '../../utils/lbj/list-request-state-handler';
import { MapFromJs } from '../../utils/types';
import { AppAction } from '../flux-store';

import { GetCongressionalDistrictListAction } from './action-creators';

import actionTypes from './action-types';

const { SUCCESS, PENDING, ERROR } = requestStatuses;

const {
  GET_CONGRESSIONAL_DISTRICT_LIST,
  GET_COUNTY_LIST,
  GET_ELECTION_LIST,
  GET_LOCATION_LIST,
  GET_PRECINCT_LIST,
  GET_REGION_LIST,
  GET_BOILER_ROOM_LIST,
  GET_BOARDS_OF_ELECTIONS_LIST,
  GET_USER_TAG_LIST,
  GET_NEARBY_LOCATIONS,
} = actionTypes;

function handleCongressionalDistrictsRequest(
  state: LbjState,
  data: GetCongressionalDistrictListAction['data']
): LbjState {
  switch (data.status) {
    case PENDING: {
      const loadingValues = {
        requestIsPending: true,
        requestErred: false,
        // These fields use a non-Immutable error, but no one reads it
        // anyway so _shrug_.
        error: {} as any,
      };

      return state
        .update('us_house', (rec) => rec.clear().merge(loadingValues))
        .update('state_house', (rec) => rec.clear().merge(loadingValues))
        .update('state_senate', (rec) => rec.clear().merge(loadingValues));
    }

    case SUCCESS: {
      const response = data.listResponse.districtData;
      const offset = data.listResponse.offset;
      const size = data.listResponse.size;

      const usHouse = response.us_house || [];
      const stateHouse = response.state_house || [];
      const stateSenate = response.state_senate || [];

      return state
        .update('us_house', (rec) =>
          rec.clear().merge({
            requestIsPending: false,
            requestErred: false,
            error: {} as any,
            listData: Immutable.fromJS(usHouse) as Immutable.List<
              MapFromJs<{}>
            >,
            offset,
            size,
          })
        )
        .update('state_house', (rec) =>
          rec.clear().merge({
            requestIsPending: false,
            requestErred: false,
            error: {} as any,
            listData: Immutable.fromJS(stateHouse) as Immutable.List<
              MapFromJs<{}>
            >,
            offset,
            size,
          })
        )
        .update('state_senate', (rec) =>
          rec.clear().merge({
            requestIsPending: false,
            requestErred: false,
            error: {} as any,
            listData: Immutable.fromJS(stateSenate) as Immutable.List<
              MapFromJs<{}>
            >,
            offset,
            size,
          })
        );
    }

    case ERROR: {
      const errorValues = {
        requestIsPending: false,
        requestErred: true,
        // These fields use a non-Immutable error, but no one reads it
        // anyway so _shrug_.
        error: data.error as any,
      };

      return state
        .update('us_house', (rec) => rec.clear().merge(errorValues))
        .update('state_house', (rec) => rec.clear().merge(errorValues))
        .update('state_senate', (rec) => rec.clear().merge(errorValues));
    }

    default:
      return state;
  }
}

// ApiCounty is a bit of a guess here
export const CountyListRecordFactory = makeListRequestRecord<ApiCounty>();
export const LocationListRecordFactory = makeListRequestRecord<ApiLocation>();
export const PrecinctListRecordFactory = makeListRequestRecord<ApiPrecinct>();

export type CountyListRecord = ReturnType<typeof CountyListRecordFactory>;
export type LocationListRecord = ReturnType<typeof LocationListRecordFactory>;
export type PrecinctListRecord = ReturnType<typeof PrecinctListRecordFactory>;

export const TagListRequestFactory = makeListRequestRecord<ApiUserTag>();

export class LbjState extends Immutable.Record({
  boards_of_elections: makeListRequestRecordObj<ApiBoardOfElections>(),
  boiler_rooms: makeListRequestRecordObj<ApiBoilerRoom>(),

  us_house: makeListRequestRecordObj<{}>(),
  state_house: makeListRequestRecordObj<{}>(),
  state_senate: makeListRequestRecordObj<{}>(),

  counties: Immutable.Map<State, CountyListRecord>(),
  elections: makeListRequestRecordObj<ApiElection>(),

  /**
   * This Map contains both the fields of a list request record as well as
   * {@link LocationListRecord} instances keyed by county.
   */
  locations: Immutable.Map<string, any>({
    ...DEFAULT_LIST_REQUEST_RECORD_FIELDS,
    listData: Immutable.List(),
  }),

  nearby_locations: Immutable.Record({
    ...DEFAULT_LIST_REQUEST_RECORD_FIELDS,
    listData: Immutable.List<MapFromJs<ApiNearbyLocation>>(),
    locationId: null as number | null,
  })(),

  /**
   * This Map contains both the fields of a list request record as well as
   * {@link PrecinctListRecord} instances keyed by county.
   */
  precincts: Immutable.Map<string, any>({
    ...DEFAULT_LIST_REQUEST_RECORD_FIELDS,
    listData: Immutable.List(),
  }),

  regions: makeListRequestRecordObj<ApiRegion>(),
  tags: new TagListRequestFactory(),
}) {}

export const initialState = new LbjState();

export default function lbj(
  state: LbjState = initialState,
  action: AppAction
): LbjState {
  switch (action.type) {
    case GET_CONGRESSIONAL_DISTRICT_LIST:
      return handleCongressionalDistrictsRequest(state, action.data);

    case GET_COUNTY_LIST: {
      return state.update('counties', (counties) =>
        // Slightly different shape here because we need to handle the case
        // where this state isn’t in the map yet, so we need to create a fresh
        // CountyListRecord.
        counties.set(
          action.data.state,
          updateListRequestRecord(action.data)(
            counties.get(action.data.state) ?? new CountyListRecordFactory()
          )
        )
      );
    }

    case GET_ELECTION_LIST:
      return state.update('elections', updateListRequestRecord(action.data));

    case GET_LOCATION_LIST:
      return state.update('locations', (locations) => {
        if (
          _.isEqual(action.data.keypath, ['locations']) ||
          !action.data.county
        ) {
          // This is the case where we’re updating a generic location list, or a
          // county was not provided in the request.
          return updateListRequestMap(action.data)(locations);
        } else {
          return locations.update(
            action.data.county,
            (rec: LocationListRecord | undefined) =>
              updateListRequestRecord(action.data)(
                rec ?? LocationListRecordFactory()
              )
          );
        }
      });

    case GET_PRECINCT_LIST: {
      return state.update('precincts', (precincts) => {
        if (
          _.isEqual(action.data.keypath, ['precincts']) ||
          !action.data.county
        ) {
          // This is the case where we’re updating a generic precinct list, or a
          // county was not provided in the request.
          return updateListRequestMap(action.data)(precincts);
        } else {
          return precincts.update(
            action.data.county,
            (rec: PrecinctListRecord | undefined) =>
              updateListRequestRecord(action.data)(
                rec ?? PrecinctListRecordFactory()
              )
          );
        }
      });
    }

    case GET_REGION_LIST:
      return state.update('regions', updateListRequestRecord(action.data));

    case GET_BOILER_ROOM_LIST:
      return state.update('boiler_rooms', updateListRequestRecord(action.data));

    case GET_BOARDS_OF_ELECTIONS_LIST:
      return state.update(
        'boards_of_elections',
        updateListRequestRecord(action.data)
      );

    case GET_USER_TAG_LIST:
      return state.update('tags', updateListRequestRecord(action.data));

    case GET_NEARBY_LOCATIONS:
      return state.update(
        'nearby_locations',
        updateListRequestRecord(action.data)
      );

    default:
      return state;
  }
}
