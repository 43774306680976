import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class AssignmentEditorButton extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <button
        type="button"
        className="js_assignment-crud c-button-secondary c-button-wide"
        {...rest}
      >
        {this.props.children}
      </button>
    );
  }
}
