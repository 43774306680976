import type { PressEvent } from '@react-types/shared';
import React from 'react';
import { useButton, AriaButtonProps } from 'react-aria';

import { assertUnreachable } from '../../utils/types';

/**
 * A button that’s an icon.
 */
export const IconButton: React.FunctionComponent<
  {
    /** material icon */
    icon: string;
    buttonRef?: React.RefObject<HTMLButtonElement> | undefined;
    onPress?: (e: PressEvent) => void;
    isDisabled?: boolean;
    isSelected?: boolean;
    title?: string | undefined;
    className?: string | undefined;
    role?: 'toolbar' | 'secondary' | undefined;
    size?: 'default' | 'small' | undefined;
  } & AriaButtonProps<'button'>
> = ({
  icon,
  isSelected,
  title,
  className = '',
  size = 'default',
  role = 'toolbar',
  buttonRef,
  ...props
}) => {
  // We need a ref for `useButton`, so either re-use the one given us, or make a
  // new one.
  const localRef = React.useRef<HTMLButtonElement | null>(null);
  buttonRef = buttonRef ?? localRef;

  const { buttonProps } = useButton(props, buttonRef);

  let sizeClasses: string;
  switch (size) {
    case 'default':
      sizeClasses = 'text-xl';
      break;

    case 'small':
      sizeClasses = 'text-base leading-[14px]';
      break;

    default:
      assertUnreachable(size);
  }

  const textColorClass = isSelected
    ? 'text-black'
    : props.isDisabled
    ? 'text-gray-500 opacity-50'
    : role === 'toolbar'
    ? 'text-gray-500'
    : role === 'secondary'
    ? 'text-accent'
    : assertUnreachable(role);

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      className={`material-icons cursor-default bg-transparent align-baseline leading-none ${textColorClass} ${sizeClasses} ${className}`}
      title={title}
    >
      {icon}
    </button>
  );
};
