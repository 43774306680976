import * as Immutable from 'immutable';
import moment from 'moment';

import { State, UserRole } from '../../constants';

import { CurrentUser } from '../../modules/user/action-creators';
import { CountySlug, DateString, Iso8601String } from '../../services/common';
import { ApiIssue } from '../../services/issue-service';
import { MapFromJs } from '../types';

function safeToString(object: Object | null | undefined) {
  if (object) {
    return object.toString();
  }
  return object;
}

export type InitialFields = Pick<
  ApiIssue,
  'incident_time' | 'report_time' | 'requires_followup'
> & {
  caller_relationship: ApiIssue['caller_relationship'] | null;
  state: ApiIssue['state'] | null;
  user?: ApiIssue['user'];
  user_role?: UserRole;
  location?: string | null | undefined;
  zipcode?: string | null | undefined;
  county?: CountySlug | undefined;
  precinct?: string | null | undefined;
};

/**
 * Returns the defaults to populate an issue creation form.
 *
 * Uses the current user so that if the user is currently assigned to a polling
 * place, we add that polling place’s information to the form.
 */
export function getInitialFields(
  currentUserData: MapFromJs<CurrentUser>,
  currentTime = moment()
): InitialFields {
  const currentUserState: State = currentUserData.get('assignment_state');
  const role: UserRole = currentUserData.get('role');
  const related: Immutable.List<MapFromJs<CurrentUser['related'][number]>> =
    currentUserData.get('related') || Immutable.List([]);

  const initialFields: InitialFields = {
    incident_time: currentTime.format() as Iso8601String,
    report_time: currentTime.format() as Iso8601String,
    caller_relationship: null,
    requires_followup: false,
    state: currentUserState !== 'US' ? currentUserState : null,
    user_role: role,
    ...(role === 'poll_observer' ? { user: currentUserData.get('id') } : {}),
  };

  // We want to autopopulate with the nearest assignment in the present or future
  // since that is the most likely target
  // So filter out older assignments and sort by date
  const remainingAssignments = related
    .filter((item) => {
      const date = item.getIn(['assignment', 'shift_date']) as DateString;
      return moment(date).isSameOrAfter(currentTime, 'day');
    })
    .sort((l, r) => {
      const lDate = l.getIn(['assignment', 'shift_date']) as DateString;
      const rDate = r.getIn(['assignment', 'shift_date']) as DateString;
      return moment(lDate).unix() - moment(rDate).unix();
    });

  const nextAssignment = remainingAssignments.first();

  if (nextAssignment) {
    // Check for conflicting assignments on the same shift day
    const assignmentLocation = nextAssignment.getIn(['location', 'id']);
    const assignmentPrecinct = nextAssignment.getIn(['precinct', 'id']);
    const assignmentDay = nextAssignment.getIn([
      'assignment',
      'shift_date',
    ]) as DateString;
    // Note that, unlike Array.shift, Immutable.Map.shift returns the tail of the list
    const overlapping = remainingAssignments.shift().filter((item) => {
      const date = item.getIn(['assignment', 'shift_date']) as DateString;
      return moment(date).isSame(assignmentDay, 'day');
    });

    const conflictingItem = overlapping.find((item) => {
      const itemLocation = item.getIn(['location', 'id']);
      const itemPrecinct = item.getIn(['precinct', 'id']);
      return (
        itemLocation !== assignmentLocation ||
        itemPrecinct !== assignmentPrecinct
      );
    });

    if (conflictingItem) {
      // Other assignments for the day have conflicting locations, so just give up.
      // We should probably check actual assignment times and then pick
      // the best one there, but the expectation of having multiple assignments
      // to different physical locations on one day is unlikely
      return initialFields;
    }
  }

  if (nextAssignment && role === 'poll_observer') {
    return {
      ...initialFields,

      location: safeToString(
        nextAssignment.getIn(['location', 'id']) as number | undefined
      ),
      zipcode: nextAssignment.getIn(['location', 'zipcode']) as
        | string
        | null
        | undefined,
      state:
        (nextAssignment.getIn(['location', 'county', 'state']) as
          | State
          | undefined) ||
        (nextAssignment.getIn(['precinct', 'county', 'state']) as
          | State
          | undefined) ||
        (nextAssignment.getIn(['board_of_elections', 'county', 'state']) as
          | State
          | undefined) ||
        currentUserState,
      county:
        (nextAssignment.getIn(['location', 'county', 'slug']) as
          | CountySlug
          | undefined) ||
        (nextAssignment.getIn(['precinct', 'county', 'slug']) as
          | CountySlug
          | undefined),
      precinct: safeToString(
        nextAssignment.getIn(['precinct', 'id']) as number | undefined
      ),
    };
  } else {
    return initialFields;
  }
}
