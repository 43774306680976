/* Polyfills */

// Immutable.js uses isInteger
import 'core-js/stable/number/is-integer';

import { enableMapSet, enablePatches } from 'immer';

/**
 * Function for code that should run in _all_ of our environments: app, mocha
 * tests, and storybook.
 */
export function commonSetup() {
  // For immer, have it handle JS Maps and Sets and patches
  enableMapSet();
  enablePatches();
}
