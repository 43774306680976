import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EMAIL_TEMPLATES } from '../../../constants';
import RIT from '../../../utils/render-if-truthy';
import UserReplytoContainer from '../../containers/form/user-replyto-container';
import Checkbox from '../form/checkbox';
import Input from '../form/input';
import Select from '../form/select';
import TextArea from '../form/textarea';
import Modal from '../lbj/modal';

export default class SendEmailModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    onSetReplyTo: PropTypes.func.isRequired,
    userCount: PropTypes.number.isRequired,
    isEmailingUsers: PropTypes.bool.isRequired,
    erredEmailingUsers: PropTypes.bool.isRequired,
    descriptionText: PropTypes.node.isRequired,
    headerText: PropTypes.node.isRequired,
    errorText: PropTypes.node.isRequired,
    replyToFilters: PropTypes.object.isRequired,
    emailTemplates: PropTypes.object,
    defaultReplyTo: PropTypes.string,
    template: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      replyTo: props.defaultReplyTo ? props.defaultReplyTo : '',
      trainingLink: '',
      sendAssignment: false,
      template: props.template,
    };
  }

  sendShouldBeDisabled() {
    const { isEmailingUsers, emailTemplates } = this.props;
    const { template } = this.state;

    if (isEmailingUsers) {
      return true;
    } else if (!!emailTemplates && !template) {
      return true;
    } else if (template === 'training_invite') {
      // TODO: This should probably check for a reasonable URL.
      return !this.state.trainingLink;
    }
    return false;
  }

  handleFileSelect(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ attachment: file });
  }

  handleReplyToChange(replyTo) {
    this.setState({ replyTo });
    this.props.onSetReplyTo(replyTo !== '');
  }

  handleSubmit(e) {
    e.preventDefault();
    const { replyTo, subject, trainingLink, sendAssignment, attachment } =
      this.state;
    const { comment } = e.target;
    let { template } = this.state;

    if (sendAssignment) {
      template = EMAIL_TEMPLATES.assignment;
    }

    const emailBody = {
      reply_to: replyTo,
      comment: comment.value,
      bcc_sender: false,
      subject,
      attachment,
      template,
    };

    if (template === 'training_invite') {
      emailBody.training_link = trainingLink;
    }

    this.props.onSendEmail(emailBody);
  }

  render() {
    const {
      onClose,
      userCount,
      isEmailingUsers,
      erredEmailingUsers,
      descriptionText,
      headerText,
      errorText,
      replyToFilters,
      defaultReplyTo,
      emailTemplates,
    } = this.props;
    const { template, sendAssignment } = this.state;
    const buttonText = isEmailingUsers
      ? 'Sending...'
      : `Send ${userCount} email${userCount > 1 ? 's' : ''}`;
    // const bccText = `BCC me on ${userCount} email${userCount > 1 ? 's' : ''} (feature disabled)`;
    const messageText = `Message${sendAssignment ? ' (Optional) ' : ''}`;
    const shouldShowTrainingLink = template === 'training_invite';
    const shouldShowSubject = template === 'broadcast' && !sendAssignment;

    const replyToPlaceholder = defaultReplyTo || 'Select a reply-to address';

    return (
      <Modal
        headerText={headerText}
        classes="lbj-purple-modal"
        onClose={onClose}
        dismissable
      >
        <div className="email-modal-description">{descriptionText}</div>

        {RIT(erredEmailingUsers, () => (
          <div className="c-alert-error">{errorText}</div>
        ))}

        <div className="email-modal-form">
          <form name="invite_users" onSubmit={this.handleSubmit.bind(this)}>
            {RIT(emailTemplates, () => {
              return (
                <div className="row">
                  <div className="col-small-12">
                    <Select
                      title="Email template"
                      name="email_template"
                      choices={emailTemplates}
                      onChange={({ target }) =>
                        this.setState({ template: target.value })
                      }
                    />
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-small-12">
                <UserReplytoContainer
                  onChange={this.handleReplyToChange.bind(this)}
                  searchFilters={replyToFilters}
                  placeholder={replyToPlaceholder}
                />
              </div>
            </div>

            {RIT(shouldShowSubject, () => {
              return (
                <div className="row">
                  <div className="col-small-12">
                    <Input
                      name="subject"
                      title="Subject"
                      onChange={({ target }) =>
                        this.setState({ subject: target.value })
                      }
                      placeholder="Subject"
                    />
                  </div>
                </div>
              );
            })}

            {RIT(shouldShowTrainingLink, () => {
              return (
                <div className="row">
                  <div className="col-small-12">
                    <Input
                      name="training_link"
                      title="Training Site URL"
                      onChange={({ target }) =>
                        this.setState({ trainingLink: target.value })
                      }
                      placeholder="https://www.example.com/voter-protection-training/"
                    />
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-small-12">
                <TextArea name="comment" title={messageText} buttonless />
              </div>
            </div>

            <div className="row">
              <div className="col-small-6">
                <div className="lbj-modal-actions">
                  <button
                    disabled={this.sendShouldBeDisabled()}
                    className="c-button-large lbj-button-purple"
                    type="submit"
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
              <div className="col-small-6">
                <div className="file-upload email-action">
                  Add an attachment
                  <input
                    ref={(input) => (this.uploadInput = input)}
                    className="file-upload-input"
                    type="file"
                    accept="application/pdf"
                    onChange={this.handleFileSelect.bind(this)}
                  />
                </div>
                <div className="lbj-modal-checkbox">
                  <Checkbox
                    name="send_assignment"
                    title="Include assignment information"
                    showLabel
                    checked={this.state.sendAssignment}
                    onChange={({ target }) =>
                      this.setState({ sendAssignment: target.checked })
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}
