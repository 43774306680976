import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class RadioInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    choices: PropTypes.object,
    errors: PropTypes.array,
    className: PropTypes.string,
  };

  static defaultProps = {
    errors: [],
  };

  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label className="group-label" htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return (
      <label className="group-label" htmlFor={name}>
        {title}
      </label>
    );
  }

  renderInput() {
    const { name, onChange, choices, value, disabled } = this.props;

    return (
      <div className="lbj-radio-wrapper">
        {Object.keys(choices).map((choice) => (
          <div key={choice}>
            <label className="radio-choice-label">
              <input
                name={name}
                value={choice}
                checked={choice === value}
                disabled={disabled}
                type="radio"
                onChange={onChange}
              />
              <span>{choices[choice]}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }

  renderError(error, key) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  render() {
    const { name, errors } = this.props;
    const passedClassName = this.props.className;
    const className = cx(
      'lbj-input',
      'lbj-input-radio',
      'lbj-input-' + name,
      passedClassName
    );

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
        {RIT(errors.length, () => {
          return errors.map((error, key) => this.renderError(error, key));
        })}
      </div>
    );
  }
}
