import keyMirror from 'key-mirror';

export default keyMirror({
  GET_ISSUE: null,
  CREATE_ISSUE: null,
  ESCALATE_ISSUE: null,
  UPDATE_ISSUE: null,
  GET_ISSUE_COMMENTS: null,
  CREATE_ISSUE_COMMENT: null,
  GET_ISSUE_HISTORY: null,
  RESET_ISSUE_IN_PROGRESS: null,
  GET_ISSUE_DOCUMENTS: null,
  UPLOAD_ISSUE_DOCUMENT: null,
  GET_ISSUE_PHOTOS: null,
  UPLOAD_ISSUE_PHOTO: null,
  GET_ISSUE_ELECTION: null,
  SET_ISSUE_ELECTION: null,
});
