import { DateValue } from '@internationalized/date';
import React from 'react';
import { AriaDatePickerProps, useDatePicker } from 'react-aria';
import { useDatePickerState } from 'react-stately';

import { PopoverView, Dialog } from '../layout';

import { Calendar } from './Calendar';
import { DateField } from './DateField';
import { IconButton } from './IconButton';

export function DatePicker({
  isValid,
  emphasizedDates,
  ...props
}: AriaDatePickerProps<DateValue> & {
  isValid?: boolean;
  emphasizedDates?: DateValue[] | undefined;
}) {
  let state = useDatePickerState(props);
  let triggerRef = React.useRef(null);
  let {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps: { onPress, ...buttonProps },
    dialogProps,
    calendarProps,
  } = useDatePicker(props, state, triggerRef);

  return (
    <div>
      <label {...labelProps} className="mb-2 text-base">
        {props.label}
      </label>
      <div
        {...groupProps}
        ref={triggerRef}
        className={`${'flex h-[40px] items-center divide-x-2 divide-solid rounded border-2 border-solid'} ${
          state.validationState === 'invalid' || isValid === false
            ? 'divide-error border-error'
            : 'divide-gray-300 border-gray-300'
        } `}
      >
        <IconButton
          {...buttonProps}
          // exactOptionalPropertyTypes shenanigans
          {...(onPress ? { onPress } : {})}
          icon="calendar_month"
          className="h-full rounded-r-none rounded-l px-2 hover:text-opacity-70"
        />
        <DateField {...fieldProps} />
      </div>
      {state.isOpen && (
        <PopoverView
          state={state}
          triggerRef={triggerRef}
          placement="bottom start"
          arrowColor="none"
        >
          <Dialog
            {...dialogProps}
            className="rounded-lg border-2 border-gray-300 bg-white p-2"
          >
            <Calendar {...calendarProps} emphasizedDates={emphasizedDates} />
          </Dialog>
        </PopoverView>
      )}
    </div>
  );
}
