import moment from 'moment';
import React from 'react';

import { ActionButton } from '../../components/form';
import Toast from '../../components/presentational/lbj/toast';
import { ToastRecord } from '../../modules/toast';

import { ApiUserTag } from '../../services/lbj-shared-service';
import {
  ApiUser,
  ExpandApiUserEmailsReceived,
  ExpandApiUserPii,
} from '../../services/user-service';

import UserForm, { UserUpdates } from './UserForm';

/**
 * Page content for editing a user’s profile. Mostly delegates to
 * {@link UserForm}.
 *
 * This is seen by both a user looking at their own profile, and by boiler room
 * leaders, dvpds, and vpds.
 *
 * Boiler room leaders are limited in their editing to the same things that
 * users can update about themselves, but dvpds/vpds can also modify the user’s
 * role.
 */
const UserDetailForm: React.FunctionComponent<{
  id?: string | undefined;

  /**
   * User we’re showing. May or may not have its `emails_received` expanded,
   * depending upon whether this is a user looking at their own profile or an
   * admin viewing the user details page.
   */
  user:
    | (ApiUser & ExpandApiUserPii & ExpandApiUserEmailsReceived)
    | (ApiUser & ExpandApiUserPii);
  tagData: ApiUserTag[];

  /** True if the user is modifying themself. Affects labels. */
  isEditingSelf: boolean;
  hasAdminPermissions: boolean;

  onSubmit: (updates: UserUpdates) => void;
  onRestoreUser: () => void;

  /**
   * Reports back up that the form is dirty, so that the parent can enable a
   * submit button.
   */
  setFormIsDirty: (formIsDirty: boolean) => void;

  toastData: ToastRecord;
  dismissToast: () => void;
}> = ({
  id,
  user,
  tagData,
  isEditingSelf,
  hasAdminPermissions,
  onSubmit,
  onRestoreUser,
  setFormIsDirty,
  toastData,
  dismissToast,
}) => {
  return (
    <div className="reset-2023 flex flex-col items-stretch gap-8 p-4 lg:p-8">
      <Toast toastData={toastData} onDismiss={dismissToast} />

      {!user.enabled && (
        <div className="flex items-center border-2 border-yellow-700 bg-yellow-300 p-4 text-left">
          <span className="flex-1">
            This user is disabled for this election.
          </span>

          {hasAdminPermissions && (
            <ActionButton role="secondary" onPress={onRestoreUser}>
              Restore User
            </ActionButton>
          )}
        </div>
      )}

      <UserForm
        id={id}
        wide
        user={user}
        tagData={tagData}
        isEditingSelf={isEditingSelf}
        onSubmit={onSubmit}
        formSubscription={{ dirty: true }}
        onFormChange={({ dirty }) => {
          setFormIsDirty(dirty);
        }}
        showAddressFields
        showExperienceFields
        // HACK(fiona): This selects boiler_room_leader users, who are the only
        // ones who can see other users’ profiles but aren’t vpds/dvpds.
        showRoleFields={hasAdminPermissions || !isEditingSelf}
        mayEditRoleFields={hasAdminPermissions}
        showInPersonVolunteeringFields
        showProgramFields={hasAdminPermissions}
      />

      {'emails_received' in user && user.emails_received.length > 0 && (
        <div>
          {/* TODO(fiona): clean this up; many emails missing subject lines? */}
          <h3 className="text-bold font-base">Emails sent:</h3>

          <ul className="user-email-list">
            {user.emails_received.map((email, idx) => {
              const date = moment(email.sent_at).format('MMMM DD, YYYY');

              return (
                <li key={idx}>
                  <strong>{email.subject || <i>Unknown subject</i>}</strong>{' '}
                  sent on {date}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDetailForm;
