import { CheckinType, WaitTime, YesNoUnknown } from '../constants';
import { DeepMerge } from '../utils/types';

import ApiClient from './api-client';
import { DateString, Iso8601String, OffsetResponse } from './common';
import { ApiLocation } from './lbj-shared-service';

export type CheckinParameters = {
  location: number;
  waitTime: WaitTime;
  // TODO(fiona): remove assignment and make date required when old checkin form
  // is deleted.
  assignment: number | null;
  date?: DateString;
  user: number;
  type: CheckinType;
  isCountingFinished: YesNoUnknown | null;
  isResultsAnnounced: YesNoUnknown | null;
};

export type ApiCheckin = {
  id: number;
  type: CheckinType;
  time: Iso8601String;
  user: number;
  location: number;
  assignment: number | null;
  wait_time: WaitTime;
  is_counting_finished: YesNoUnknown | null;
  is_results_announced: YesNoUnknown | null;
};

export type ApiNewCheckin = Omit<ApiCheckin, 'id' | 'time'> & {
  date?: DateString;
};

export type ExpandApiCheckinLocation = {
  location: ApiLocation;
};

/**
 * A checkin with location expanded.
 *
 * We need to specify this with `DeepMerge` because without the expansion,
 * `location` is a number rather than an `ApiLocation`.
 */
export type ApiCheckinWithLocation = DeepMerge<
  [ApiCheckin, ExpandApiCheckinLocation]
>;

export function createCheckin({
  location,
  waitTime,
  assignment,
  date,
  user,
  type,
  isCountingFinished,
  isResultsAnnounced,
}: CheckinParameters) {
  const params: ApiNewCheckin = {
    location,
    wait_time: waitTime,
    assignment,
    user,
    type,
    is_counting_finished: isCountingFinished,
    is_results_announced: isResultsAnnounced,
  };

  if (date) {
    params['date'] = date;
  }

  return ApiClient<{ checkin: ApiCheckin }>('checkin/', 'POST', {}, {}, params);
}

export function listCheckins(userId: number, offset = 0, size = 10) {
  const params = {
    expand: 'location',
    user: userId,
    offset,
    size,
  };

  return ApiClient<OffsetResponse<'checkins', ApiCheckinWithLocation>>(
    'checkins/',
    'GET',
    params
  );
}

export default { createCheckin, listCheckins };
