import keyMirror from 'key-mirror';

export default keyMirror({
  GET_LOCATION_CHANGES: null,
  GET_NOTIFICATIONS_EXIST: null,
  GET_UNACKED_LOCATION_CHANGE_COUNT: null,
  GET_UNSEEN_NOTIF_COUNT: null,
  GET_ISSUE_NOTIFICATIONS: null,
  STORE_POLLING_INTERVAL: null,
  BACK_OFF_POLLING_INTERVAL: null,
});
