import * as Immutable from 'immutable';
import React from 'react';

import { ApiLocationWithAssignments } from '../../../services/assignment-service';
import { MapFromJs } from '../../../utils/types';
import LocationItemV2 from '../../containers/assignment/assignment-location-item-v2';
import EmptyAlert from '../lbj/empty-alert';

const LocationListV2: React.FunctionComponent<{
  locations: Immutable.List<MapFromJs<ApiLocationWithAssignments>>;
  listRequestIsPending: boolean;
  listRequestErred: boolean;
  now: moment.Moment;
}> = ({ locations, listRequestIsPending, listRequestErred, now }) => {
  const isEmpty = locations.isEmpty();

  return (
    <div>
      {isEmpty && !listRequestIsPending && !listRequestErred ? (
        <EmptyAlert
          header="There are no polling locations"
          description="Make sure you've selected a state on the left or change existing filters"
        />
      ) : (
        <table className="assignment-list-table">
          <thead>
            <tr>
              <th className="location">Location Name</th>
              <th className="address">Address</th>
              <th className="county">County</th>
              <th className="coverage">Coverage</th>
              <th className="volunteers">Volunteers</th>
              <th className="checkin">Check-in Status</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location, key) => (
              <LocationItemV2 location={location} key={key} now={now} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LocationListV2;
