import cx from 'classnames';
import React from 'react';
import {
  HiddenSelect,
  useSelect,
  AriaSelectOptions,
  useButton,
} from 'react-aria';
import { useSelectState, SelectStateOptions } from 'react-stately';

import { PopoverView } from '../layout';

import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import { ListBoxView } from './ListBox';

/**
 * Replacement for `<select>` that is built with react-aria and is styled to
 * match our components.
 *
 * @see https://react-spectrum.adobe.com/react-aria/useSelect.html
 */
export function Select<T extends Object>({
  placeholder = 'Select an option…',
  className,
  ...props
}: SelectStateOptions<T> &
  AriaSelectOptions<T> & {
    placeholder?: string | undefined;
    isReadOnly?: boolean | undefined;
    className?: string | undefined;
  }): React.ReactElement {
  const state = useSelectState(props);

  const triggerRef = React.useRef(null);
  const { labelProps, triggerProps, valueProps, menuProps, errorMessageProps } =
    useSelect(
      {
        ...props,
        // `useSelect` doesn’t have an `isReadOnly` by default, so we fake it
        // with `isDisabled`.
        isDisabled: !!(props.isDisabled || props.isReadOnly),
      },
      state,
      triggerRef
    );

  const { buttonProps } = useButton(triggerProps, triggerRef);

  return (
    // We need 'relative' to keep the <HiddenSelect> from positioning itself
    // absolutely in relation to the <body>, causing a scrollbar.
    <div className={cx('relative flex flex-col gap-2', className)}>
      {typeof props.label === 'string' ? (
        <FormLabel isRequired={props.isRequired} {...labelProps}>
          {props.label}
        </FormLabel>
      ) : (
        props.label
      )}

      <HiddenSelect
        state={state}
        triggerRef={triggerRef}
        label={props.label}
        {...(props.name ? { name: props.name } : {})}
        {...(props.isDisabled ? { isDisabled: props.isDisabled } : {})}
      />

      <button
        {...buttonProps}
        ref={triggerRef}
        className={cx(
          'relative flex min-h-[40px] items-center rounded border-2 border-solid',
          'whitespace-nowrap py-0 px-2 text-left font-normal text-gray-700',
          {
            'bg-white': !props.isDisabled,
            'cursor-not-allowed bg-gray-200': props.isDisabled,
            'border-gray-300': props.validationState !== 'invalid',
            'border-red-700': props.validationState === 'invalid',
          }
        )}
      >
        <span
          {...valueProps}
          className="flex-1 overflow-hidden overflow-ellipsis"
        >
          {state.selectedItem ? (
            state.selectedItem.rendered
          ) : (
            <span className="italic">{placeholder}</span>
          )}
        </span>
        {!props.isReadOnly && (
          <span aria-hidden className="">
            ▼
          </span>
        )}
      </button>

      {props.errorMessage && (
        <FormError {...errorMessageProps}>{props.errorMessage}</FormError>
      )}

      {state.isOpen && (
        <PopoverView
          state={state}
          triggerRef={triggerRef}
          placement="bottom start"
        >
          <ListBoxView
            {...menuProps}
            state={state}
            selectionStyle="checkmark"
            shouldFocusOnHover
          />
        </PopoverView>
      )}
    </div>
  );
}
