import React, { Component } from 'react';

import { PageHeader } from '../../common';

import UserFilterSidebar from './user-filter-sidebar';
import UserList from './user-list';
import UserViewFilters from './user-view-filters';

export default class UserIndex extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="lbj-page-wrapper">
        <PageHeader title="All Users">
          <UserViewFilters />
        </PageHeader>

        <div className="lbj-page-columns">
          <UserFilterSidebar />

          <div className="lbj-main">
            <UserList />
          </div>
        </div>
      </div>
    );
  }
}
