import cx from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ToastData, ToastRecord } from '../../../modules/toast';

export default class Toast extends Component<{
  toastData: ToastRecord;
  onDismiss: () => void;
}> {
  renderMessage() {
    const { onDismiss } = this.props;
    const { message, url } = this.props.toastData;

    if (url) {
      return (
        <Link to={url} onClick={onDismiss}>
          {message}
        </Link>
      );
    } else {
      return message;
    }
  }

  render() {
    const { onDismiss } = this.props;
    const { type, message } = this.props.toastData;

    const className = cx('lbj-toast', `lbj-toast-${type}`);

    if (message) {
      return (
        <div className={className}>
          <p>
            {this.renderMessage()}
            <button type="button" onClick={onDismiss}>
              X
            </button>
          </p>
        </div>
      );
    } else {
      return <div className="lbj-toast lbj-toast-empty" />;
    }
  }
}

/**
 * Convenience hook for minding toast state outside of Redux.
 *
 * TODO(fiona): Have toasts disappear after X amount of time
 *
 * TODO(fiona): Maybe do this with contexts so that we don’t necessarily need to
 * drill these down.
 */
export function useToast(): [
  ToastRecord,
  { showToast: (d: ToastData) => void; dismissToast: () => void }
] {
  const [toast, setToast] = React.useState(new ToastRecord());

  const showToast = React.useCallback(
    (d: ToastData) => setToast(new ToastRecord(d)),
    []
  );

  const dismissToast = React.useCallback(() => setToast(new ToastRecord()), []);

  return [toast, { showToast, dismissToast }];
}
