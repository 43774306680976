import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import { TextButton } from '../../form';
import CalendarFilter from '../assignment/assignment-calendar-filter';

export default class UserDetailSidebar extends Component {
  static propTypes = {
    filters: PropTypes.object,
    view: PropTypes.string.isRequired,
    onCancelChanges: PropTypes.func,
    onDateFilterChange: PropTypes.func,
    disableSave: PropTypes.bool,
    disableCancel: PropTypes.bool,

    isEditingSelf: PropTypes.bool,
    userHasAdminPermissions: PropTypes.bool,
    userIsDisabled: PropTypes.bool,
    showDeleteModal: PropTypes.func,
  };

  render() {
    const {
      view,
      filters,
      onCancelChanges,
      disableSave,
      onDateFilterChange,
      disableCancel,
      isEditingSelf,
      userHasAdminPermissions,
      userIsDisabled,
      showDeleteModal,
    } = this.props;

    return (
      <div className="lbj-sidebar p-4">
        {RIT(view !== 'assignments', () => {
          return (
            <>
              <div>
                <button
                  type="submit"
                  className="c-button-large
                  lbj-button-submit lbj-button-blue"
                  disabled={disableSave}
                  form="profile-form"
                >
                  Save Changes
                </button>
                {RIT(!disableCancel, () => {
                  return (
                    <button
                      type="button"
                      to="/issues"
                      className="c-button-outline c-button-large lbj-button-cancel"
                      onClick={onCancelChanges}
                    >
                      Cancel
                    </button>
                  );
                })}
              </div>

              <div className="mt-10 text-center">
                {userHasAdminPermissions && !isEditingSelf && !userIsDisabled && (
                  <TextButton onPress={showDeleteModal} size="small">
                    Disable User
                  </TextButton>
                )}
              </div>
            </>
          );
        })}

        {RIT(view === 'assignments', () => {
          return (
            <div>
              <div className="lbj-column-label">
                <h4>Filters</h4>
              </div>
              <div className="lbj-column-content-wrapper">
                <div className="lbj-column-content">
                  <CalendarFilter
                    filters={filters}
                    onFilterChange={onDateFilterChange}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
