import Immutable from 'immutable';

import {
  AssignmentViewType,
  assignmentViewTypes,
  lbjAppSections,
} from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';
import { filterFilters } from '../filters/common';

const { PEOPLE, LOCATIONS, PRECINCTS, BOILER_ROOMS, BOARDS_OF_ELECTIONS } =
  assignmentViewTypes;
const { ASSIGNMENT } = lbjAppSections;

function filterAssignmentFilters<
  OutK extends keyof FiltersBySection['ASSIGNMENT']
>(state: AppState, keys: OutK[]) {
  return filterFilters(state.filters.ASSIGNMENT, keys);
}

export function mapStateToPrecinctFilters(state: AppState) {
  return filterAssignmentFilters(state, [
    'view',
    'dates',
    'county',
    'tier',
    'state',
    'location_exists',
    'name',
    'offset',
    'size',
    'precinct',
    'location',
  ]);
}

export function mapStateToLocationFilters(state: AppState) {
  return filterAssignmentFilters(state, [
    'view',
    'dates',
    'county',
    'tier',
    'state',
    'coverage',
    'name',
    'offset',
    'size',
    'precinct',
    'location',
    'has_checked_in_assignments',
  ]);
}

export function mapStateToObserverFilters(state: AppState) {
  return filterAssignmentFilters(state, [
    'view',
    'assignment_state',
    'dates',
    'name_prefix',
    'offset',
    'size',
    'tags',
    'coverage',
    'has_checked_in_assignments',
    'assigned',
  ]);
}

export function mapStateToBoilerFilters(state: AppState) {
  return filterAssignmentFilters(state, [
    'view',
    'name',
    'dates',
    'state',
    'offset',
    'size',
  ]);
}

function mapStateToBoardOfElectionsFilters(state: AppState) {
  return filterAssignmentFilters(state, [
    'view',
    'dates',
    'state',
    'county',
    'offset',
    'size',
  ]);
}

/**
 * Filters the map of current “Assignment” filters down to those that are
 * relevant for the given Assignment “view.”
 *
 * We return a very generic Map because otherwise these different page-specific
 * maps overlap.
 */
export function mapStateToAssignmentFiltersForView(
  state: AppState,
  view: AssignmentViewType | undefined
): Immutable.Map<string, unknown> {
  switch (view) {
    case PEOPLE:
      return mapStateToObserverFilters(state);

    case PRECINCTS:
      return mapStateToPrecinctFilters(state);

    case LOCATIONS:
      return mapStateToLocationFilters(state);

    case BOARDS_OF_ELECTIONS:
      return mapStateToBoardOfElectionsFilters(state);

    case BOILER_ROOMS:
    default:
      return mapStateToBoilerFilters(state);
  }
}

/**
 * Filters the map of current “Assignment” filters down to those that are
 * relevant for the current Assignment “view.”
 */
export default function mapStateToAssignmentFilters(state: AppState) {
  const filters = state.filters.get(ASSIGNMENT);

  return mapStateToAssignmentFiltersForView(
    state,
    filters.get('view') as AssignmentViewType | undefined
  );
}
