import * as _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  isState,
  isCountySlug,
  State,
  US_NATIONAL_STATE_CODE,
} from '../constants';
import { CountySlug } from '../services/common';

import { Undefinable } from './types';

/**
 * Error class for when there’s an error parsing things.
 *
 * Right now this is not checked explicitly in the code or exposed out to
 * callers. Parse errors are predominantly ignored and treated as if the query
 * param were missing.
 */
export class QueryFieldParseError extends Error {
  constructor(msg?: string) {
    super(msg);
  }
}

/**
 * Interface for a class that can convert a string value from a query param to
 * an object and back again.
 *
 * These will only be called when the param exists (they’re not called for
 * `undefined`) and are assumed to be able to convert any legal value to a
 * string representation. (I.e. `serialize` is not expected to throw or return
 * `undefined`.)
 */
export interface QueryFieldSerializer<T> {
  /**
   * Convert a value of the serialized type to a query parameter string.
   */
  serialize(val: T): string;

  /**
   * Convert a query parameter string to a value of the given type. May throw an
   * exception if the string is of an invalid format, which is typically treated
   * as the parameter being missing.
   */
  deserialize(param: string): T;
}

/**
 * Simple serializer for strings, since they just round-trip as-is.
 */
export class StringSerializer implements QueryFieldSerializer<string> {
  serialize = (val: string) => val;
  deserialize = (param: string) => param;
}

/**
 * Serializer for strings that allows for a filter predicate to test if they’re
 * valid.
 *
 * Uses TypeScript’s `is` type predicates so that this can generate
 * particularly-typed values.
 */
export class CheckedStringSerializer<T extends string>
  implements QueryFieldSerializer<T>
{
  checker: (param: string) => param is T;

  constructor(checker: (param: string) => param is T) {
    this.checker = checker;
  }

  serialize = (val: T) => val;

  deserialize(param: string) {
    if (!this.checker(param)) {
      throw new QueryFieldParseError(
        `Param failed ${this.checker.name}: ${param}`
      );
    }

    return param;
  }
}

/**
 * Serializer based on a map where the keys are what’s serialized in the query
 * string and the values are what’s returned by the parser.
 */
export class MappedStringSerializer<T> implements QueryFieldSerializer<T> {
  values: { [key: string]: T };

  constructor(values: { [key: string]: T }) {
    this.values = values;
  }

  serialize(val: T) {
    const entry = Object.entries(this.values).find(([_k, v]) => v === val);

    if (entry) {
      return entry[0];
    } else {
      throw new QueryFieldParseError(`Could not serialize value ${val}`);
    }
  }

  deserialize(param: string) {
    if (!(param in this.values)) {
      throw new QueryFieldParseError(`Key ${param} not found in our map`);
    }

    return this.values[param]!;
  }
}

/**
 * Simple serializer for numbers, which uses `parseInt` constructor to parse
 * from strings.
 *
 * Throws an exception for strings that don’t parse, rather than returning a
 * `NaN`.
 */
export class NumberSerializer implements QueryFieldSerializer<number> {
  serialize = (val: number) => String(val);

  deserialize(param: string) {
    // We want parseInt rather than Number so that we don’t return 0 for empty
    // strings and such.
    const val = parseInt(param);

    if (Number.isNaN(val)) {
      throw new QueryFieldParseError(`Could not parse ${param} to a number`);
    }

    return val;
  }
}

/**
 * Simple serializer for booleans.
 *
 * Throws an exception for strings that don’t parse.
 */
export class BooleanSerializer implements QueryFieldSerializer<boolean> {
  serialize = (val: boolean) => String(val);

  deserialize(param: string) {
    switch (param) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        throw new QueryFieldParseError(`Could not parse ${param} to a boolean`);
    }
  }
}

/**
 * Serializer that parses a {@link Date} object with a given format string,
 * using `moment`.
 */
export class DateSerializer implements QueryFieldSerializer<Date> {
  format: string;

  constructor(format: string) {
    this.format = format;
  }

  serialize = (val: Date) => moment(val).format(this.format);

  deserialize(param: string) {
    const m = moment(param, this.format, true);

    if (!m.isValid()) {
      throw new QueryFieldParseError(
        `Param ${param} is not of format ${this.format}`
      );
    }

    return m.toDate();
  }
}

/**
 * Options for {@link ListSerializer}’s constructor.
 */
export type ListSerializerOptions = {
  /** Separator string or character for the list elements. Typically defaults to
   * “,” */
  separator?: string;
};

/**
 * Handles serializing/deserializing a list of elements. Elements are
 * serialized/deserialized by a provided serializer, and are joined/split with a
 * customizable separator string.
 *
 * When deserializing, elements that do not parse via the sub-serializer are
 * ignored and filtered out of the returned array.
 */
export class ListSerializer<T> implements QueryFieldSerializer<T[]> {
  serializer: QueryFieldSerializer<T>;
  separator: string;

  constructor(
    parser: QueryFieldSerializer<T>,
    options: ListSerializerOptions = {}
  ) {
    this.serializer = parser;
    this.separator = options.separator ?? ',';
  }

  serialize(val: T[]): string {
    return val.map((v) => this.serializer.serialize(v)).join(this.separator);
  }

  deserialize(param: string): T[] {
    const out: T[] = [];

    param.split(this.separator).forEach((p) => {
      try {
        out.push(this.serializer.deserialize(p));
      } catch (e) {
        // ignore parse errors
      }
    });

    return out;
  }
}

/**
 * Return type for {@link QueryField}’s `paramToValue` to support signaling that
 * a required param was missing or needs to be updated to a new value.
 */
export type QueryFieldValueResult<T> =
  | { type: 'success'; value: T }
  | { type: 'retry'; newParam: string }
  | { type: 'fail' };

/**
 * Interface for an object that can take a query parameter and convert it to a
 * value, or a value and convert it to a query parameter.
 *
 * Implementations of this type build on top of {@link QueryFieldSerializer}
 * instances and add handling for missing values, defaults, and required fields.
 */
export interface QueryField<T> {
  /**
   * Converts the given value into either a string to include in the query
   * parameter, or `undefined` if the field should be left out of the query
   * parameter. (This latter case can come up if the value matches an implicit
   * default, in which case it’s left out of the query string).
   */
  valueToParam(val: T): string | undefined;

  /**
   * Converts either a parameter value or the lack of a parameter value into a
   * response. Called with `undefined` if the key this field is associated with
   * does not appear in the query parameters.
   *
   * Can return a “success” with a value, a “retry” with an updated param to use
   * (likely causing an HTTPish redirect), or a “fail” if the value (or lack of
   * value) is unrecoverable and the page should 404 or otherwis error.
   */
  paramToValue(param: string | undefined): QueryFieldValueResult<T>;
}

/**
 * {@link QueryField} implementation that allows for the value to be undefined.
 * If the param is missing it serializes to `undefined`, and if the value is
 * `undefined` it does not appear as a query parameter.
 *
 * Hides the `undefined` handling from its {@link QueryFieldSerializer} so that
 * that implementation can focus solely on the values being present.
 */
export class OptionalQueryField<T> implements QueryField<T | undefined> {
  // We allow this class’s serializer to be public so it can be a stand-in to
  // pass a parser into e.g. `listOf`: `my_list: q.listOf(q.number())`
  //
  // We don’t let other subclasses have a public serializer becaues it would be
  // confusing to say `q.listOf(q.number(3))` since that “3” is meaningless and
  // would be ignored.
  public serializer: QueryFieldSerializer<T>;

  constructor(serializer: QueryFieldSerializer<T>) {
    this.serializer = serializer;
  }

  valueToParam(val: T | undefined): string | undefined {
    if (val === undefined) {
      return undefined;
    } else {
      return this.serializer.serialize(val);
    }
  }

  paramToValue(
    param: string | undefined
  ): QueryFieldValueResult<T | undefined> {
    if (param !== undefined) {
      try {
        return { type: 'success', value: this.serializer.deserialize(param) };
      } catch (e) {
        // ignore parse errors
      }
    }

    return { type: 'success', value: undefined };
  }

  /**
   * Makes this a required field, but without a default. If the parameter is not
   * provided (or the provided version fails deserialization) the overall parse
   * will fail.
   */
  required(): QueryField<T> {
    return new NotMissingQueryField(this.serializer);
  }
}

/**
 * Built from {@link OptionalQueryField}’s `required` method. If the param is
 * not present in the query string, fails the parse in an unrecoverable way.
 */
export class NotMissingQueryField<T> implements QueryField<T> {
  private serializer: QueryFieldSerializer<T>;

  constructor(serializer: QueryFieldSerializer<T>) {
    this.serializer = serializer;
  }

  valueToParam(val: T): string | undefined {
    return this.serializer.serialize(val);
  }

  paramToValue(param: string | undefined): QueryFieldValueResult<T> {
    if (param !== undefined) {
      try {
        return { type: 'success', value: this.serializer.deserialize(param) };
      } catch (e) {
        // ignore parse errors
      }
    }

    return { type: 'fail' };
  }
}

/**
 * A {@link QueryField} implementation with a value to use when it’s not
 * provided.
 *
 * If the parameter is not provided, this returns a default value.
 * Symmetrically, if this is going to serialize a value that matches the
 * serialization of its default, it leaves it off the query string.
 *
 * Can be converted into {@link ExplicitlyDefaultedQueryField} or
 * {@link ForcedQueryField}.
 */
export class DefaultedQueryField<T> implements QueryField<T> {
  private serializer: QueryFieldSerializer<T>;
  private defaultValue: T;

  constructor(serializer: QueryFieldSerializer<T>, defaultValue: T) {
    this.serializer = serializer;
    this.defaultValue = defaultValue;
  }

  valueToParam(val: T): string | undefined {
    const defaultParam = this.serializer.serialize(this.defaultValue);
    const param = this.serializer.serialize(val);

    // If we’re serializing to the same value as the default, return undefined
    // so that the param is removed from the query.
    if (param === defaultParam) {
      return undefined;
    }

    return param;
  }

  paramToValue(param: string | undefined): QueryFieldValueResult<T> {
    if (param !== undefined) {
      try {
        return { type: 'success', value: this.serializer.deserialize(param) };
      } catch (e) {
        // ignore parse errors
      }
    }

    // If we get this far then the parameter either isn’t provided or didn’t
    // parse.
    return { type: 'success', value: this.defaultValue };
  }

  /**
   * Returns a {@link QueryField} that will redirect to ensure that the default
   * value is added to the query string.
   */
  required() {
    return new ExplicitlyDefaultedQueryField(
      this.serializer,
      this.defaultValue
    );
  }

  /**
   * Returns a {@link QueryField} that will redirect to ensure that the query
   * parameter always matches the value that this was initialized with.
   */
  forced() {
    return new ForcedQueryField(this.serializer, this.defaultValue);
  }
}

/**
 * Unlike {@link DefaultedQueryField}, this implementation requires that the
 * value appears in the query string, it just has a pre-defined default to use
 * in that case.
 *
 * Trying to parse out the state when the field is missing will cause this class
 * to return a “retry” {@link QueryFieldValueResult} that can be used to
 * redirect the user to a URL where the field is provided.
 */
export class ExplicitlyDefaultedQueryField<T> implements QueryField<T> {
  private serializer: QueryFieldSerializer<T>;
  private defaultValue: T;

  constructor(serializer: QueryFieldSerializer<T>, defaultValue: T) {
    this.serializer = serializer;
    this.defaultValue = defaultValue;
  }

  valueToParam(val: T): string | undefined {
    return this.serializer.serialize(val);
  }

  paramToValue(param: string | undefined): QueryFieldValueResult<T> {
    if (param !== undefined) {
      try {
        return { type: 'success', value: this.serializer.deserialize(param) };
      } catch (e) {
        // ignore parse errors
      }
    }

    // If we get this far then the parameter either isn’t provided or didn’t
    // parse.
    //
    // In the case that it didn’t parse (rather than just not existing), do we
    // want to allow a mode where it fails rather than becoming the default?
    // (E.g. `?view=nonsense` query parameter 404ing instead of redirecting to
    // `?view=issues`)
    return {
      type: 'retry',
      newParam: this.serializer.serialize(this.defaultValue),
    };
  }
}

/**
 * This handles cases where we need the query parameter to match a specific
 * known value. (And it’s relevant to have that value in the query parameter
 * rather than being implied.)
 */
export class ForcedQueryField<T> implements QueryField<T> {
  private serializer: QueryFieldSerializer<T>;
  private forcedValue: T;

  constructor(serializer: QueryFieldSerializer<T>, defaultValue: T) {
    this.serializer = serializer;
    this.forcedValue = defaultValue;
  }

  valueToParam(val: T): string | undefined {
    return this.serializer.serialize(val);
  }

  paramToValue(param: string | undefined): QueryFieldValueResult<T> {
    // We compare at the serialized string level to avoid any issues of object
    // equality.
    const enforcedParam = this.serializer.serialize(this.forcedValue);

    if (param === enforcedParam) {
      return { type: 'success', value: this.forcedValue };
    } else {
      return {
        type: 'retry',
        newParam: enforcedParam,
      };
    }
  }
}

/**
 * Adaptor to allow for constant values that don’t get serialized/deserialized
 * in the query string to still appear in the parsed query state object.
 */
export class SilentQueryField<T> implements QueryField<T> {
  private value: T;

  constructor(value: T) {
    this.value = value;
  }

  valueToParam(_val: T): string | undefined {
    return undefined;
  }

  paramToValue(_param: string | undefined): QueryFieldValueResult<T> {
    return { type: 'success', value: this.value };
  }
}

/**
 * Type of an object of {@link QueryField} instances that defines the entire
 * serialization / deserialization of a query state.
 */
export type QueryFields<T extends Object> = {
  [key in keyof T]: QueryField<T[key]>;
};

/**
 * Type of the builder function that’s passed into our various hooks and
 * functions that defines how to serialize to/from our query state.
 */
export type MakeQueryFieldsFn<T extends Object> = (
  q: QueryFieldBuilders
) => QueryFields<T>;

/**
 * Helper function to return a function that makes either
 * {@link DefaultedQueryField} or {@link OptionalQueryField} instances for the
 * given serializer, depending upon whether it’s called with a default value.
 */
function makeQueryFieldFn<T>(serializer: QueryFieldSerializer<T>) {
  function make(): OptionalQueryField<T>;
  function make(defaultVal: T): DefaultedQueryField<T>;
  function make(defaultVal?: T) {
    if (arguments.length === 0) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultVal!);
    }
  }

  return make;
}

/**
 * Version of {@link makeQueryFieldFn} for when the returned function should
 * take an argument, but the type of the field does not vary based on that
 * argument. Good for e.g. “format” parameters.
 */
function makeQueryFieldFnWithArg<T, A>(
  makeSerializer: (arg: A) => QueryFieldSerializer<T>
) {
  function make(arg: A): OptionalQueryField<T>;
  function make(arg: A, defaultVal: T): DefaultedQueryField<T>;
  function make(arg: A, defaultVal?: T) {
    const serializer = makeSerializer(arg);

    if (arguments.length === 1) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultVal!);
    }
  }

  return make;
}

/**
 * Version of {@link makeQueryFieldFn} that allows for an optional “options”
 * object as the first parameter.
 *
 * Due to the need to distinguish between the options object and the value,
 * since both are optional, this can only be used when the value type is a
 * scalar. If the value type is an object, use {@link makeQueryFieldFnWithArg}
 * instead. It will require than an options argument is always passed, which
 * removes the ambiguity.
 */
function makeQueryFieldFnWithOptions<
  T extends string | number | boolean,
  A extends Object
>(makeSerializer: (opts?: A) => QueryFieldSerializer<T>) {
  function make(opts?: A): OptionalQueryField<T>;
  function make(defaultVal: T): DefaultedQueryField<T>;
  function make(opts: A, defaultVal: T): DefaultedQueryField<T>;
  function make(opts?: A | T, defaultVal?: T) {
    if (
      typeof opts === 'string' ||
      typeof opts === 'number' ||
      typeof opts === 'boolean'
    ) {
      // If the first argument is one of those scalar types then the user has
      // not provided an options object.
      defaultVal = opts;
      opts = undefined;
    }

    const serializer = makeSerializer(opts as A | undefined);

    if (defaultVal === undefined) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultVal!);
    }
  }

  return make;
}

/**
 * Object with helper methods for creating {@link QueryField} instances.
 *
 * We wrap these methods in an object instance for discoverability, since you
 * can just tab-complete its member fields, rather than hunting for separate
 * functions for defining {@link QueryField}s.
 *
 * For ergonomic reasons, we’re using overloaded function types, which
 * unfortunately makes this implementation a bit messy. The idea is that it’s
 * much more pleasant to be able to write `q.number()` and `q.number(4)` and
 * have those return {@link OptionalQueryField} and {@link DefaultedQueryField}
 * instances, respectively, than it is to do, say, `q.number().withDefault(4)`
 * for the latter.
 *
 * This is a choice made for aesthetics and cuteness, and is hopefully worth the
 * complexity of types and helper functions.
 */
export class QueryFieldBuilders {
  /** Field that only appears in the parsed query state object output and not
   * the query string. */
  silent = <T>(value: T) => new SilentQueryField(value);

  /** Arbitrary string field. */
  string = makeQueryFieldFn(new StringSerializer());
  /** Arbitrary numeric field. */
  number = makeQueryFieldFn(new NumberSerializer());
  /** true/false boolean field. */
  boolean = makeQueryFieldFn(new BooleanSerializer());

  /** Date with the specified moment date format. */
  date = makeQueryFieldFnWithArg(
    (format: string) => new DateSerializer(format)
  );

  // We had to write these types custom, rather than using
  // `makeQueryFieldFnWithArg` because the field type varies based on the type
  // of the values argument, and we couldn’t capture that when going through
  // another function.
  oneOf<T extends string>(
    values: readonly T[] | { [key in T]: unknown }
  ): OptionalQueryField<T>;
  oneOf<T extends string>(
    values: readonly T[] | { [key in T]: unknown },
    defaultValue: T
  ): DefaultedQueryField<T>;
  /**
   * String from a particular set of values, specified by either elements of an
   * array or keys of an object.
   */
  oneOf<T extends string>(
    values: readonly T[] | { [key in T]: unknown },
    defaultValue?: T
  ) {
    const serializer = new CheckedStringSerializer((v): v is T =>
      Array.isArray(values) ? values.includes(v as any) : v in values
    );

    if (arguments.length === 1) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultValue!);
    }
  }

  /**
   * Parses via a map where the keys of the map are the serialized values (that
   * are in the query string) and the values in the map are the deserialized
   * values (that are returned in the state object).
   */
  byMap<T>(values: { [key: string]: T }, defaultValue?: T) {
    const serializer = new MappedStringSerializer(values);

    if (arguments.length === 1) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultValue!);
    }
  }

  listOf<T>(
    kind: OptionalQueryField<T>,
    opts?: ListSerializerOptions
  ): OptionalQueryField<T[]>;
  listOf<T>(
    kind: OptionalQueryField<T>,
    defaultValue: T[]
  ): DefaultedQueryField<T[]>;
  listOf<T>(
    kind: OptionalQueryField<T>,
    opts: ListSerializerOptions,
    defaultValue: T[]
  ): DefaultedQueryField<T[]>;
  /**
   * List of objects of a given type, specified by using _e.g._ `q.number()`.
   *
   * Takes an optional second parameter for options such as the list separator.
   *
   * Note: will return `undefined` if the parameter is missing, unless `[]` is
   * provided as the default value.
   */
  listOf<T>(
    /**
     * Handler for the values within the list.
     *
     * We pass an {@link OptionalQueryField} here instead of a
     * {@link QueryFieldSerializer} simply because `listOf` is called within a
     * builder function that has easy access to creating
     * {@link OptionalQueryField}s via the `q` argument. _E.g._ `q.number()`
     */
    kind: OptionalQueryField<T>,
    opts: ListSerializerOptions | T[] = {},
    defaultValue?: T[]
  ) {
    if (Array.isArray(opts)) {
      defaultValue = opts;
      opts = {};
    }

    const serializer = new ListSerializer(kind.serializer, opts);

    if (defaultValue === undefined) {
      return new OptionalQueryField(serializer);
    } else {
      return new DefaultedQueryField(serializer, defaultValue);
    }
  }

  /** For state codes, with the option of excluding 'US' as a value. */
  state = makeQueryFieldFnWithOptions((opts: { ignoreUs?: boolean } = {}) =>
    opts.ignoreUs
      ? new CheckedStringSerializer(
          (v): v is State => isState(v) && v !== US_NATIONAL_STATE_CODE
        )
      : new CheckedStringSerializer(isState)
  );

  /** For {@link CountySlug}s. */
  county = makeQueryFieldFn(new CheckedStringSerializer(isCountySlug));

  /**
   * Field that is deserialized by calling the provided function, and serialized
   * for the query parameter via `String()`.
   *
   * If you need more complex serialization, use `viaSerializer` and a custom
   * {@link QueryFieldSerializer} implementation.
   */
  viaFromString = <T>(fromString: (param: string) => T) =>
    makeQueryFieldFn({
      serialize: (val: T) => String(val),
      deserialize: (param: string) => fromString(param),
    });

  /**
   * Field built from a custom {@link QueryFieldSerializer} implementation.
   */
  viaSerializer = <T>(p: QueryFieldSerializer<T>) => makeQueryFieldFn(p);
}

/**
 * Type for a function that updates query states of the given type.
 *
 * Allows for partial updates by only setting some of the parameters. Also
 * allows for any of the fields to be marked as `undefined`, even those that are
 * always de-serialized to defined values (_i.e._ are configured with defaults)
 * as a way to “reset” them.
 */
export type UpdateQueryStateFn<T extends Object> = (
  updates: Partial<Undefinable<T>>
) => void;

/**
 * Type for a function that generates a new {@link URLSearchParams} based on the
 * current {@link URLSearchParams} and updates.
 *
 * Allows for partial updates by only setting some of the parameters. Also
 * allows for any of the fields to be marked as `undefined`, even those that are
 * always de-serialized to defined values (_i.e._ are configured with defaults)
 * as a way to “reset” them.
 *
 * If “resetAll” is passed, then all params managed by the underlying
 * {@link QueryFields} are reset. (This is a shortcut rather than passing
 * undefined to all of them.)
 */
export type UpdateQueryStateParamsFn<T extends Object> = (
  updates: Partial<Undefinable<T>>,
  resetAll?: boolean
) => URLSearchParams;

/**
 * Removes all query parameters that our state manages, resetting everything
 * back to their defaults.
 */
export type ResetQueryStateFn = () => void;

export type QueryStateParseResult<T extends Object> =
  | {
      result: 'success';
      state: T;
    }
  | {
      result: 'notFound';
      missingFields: string[];
      state?: undefined;
    }
  | {
      result: 'redirect';
      updatedQuery: URLSearchParams;
      state?: undefined;
    };

/**
 * Parses out the {@link QueryStateParseResult} from the given
 * {@link URLSearchParams}.
 *
 * Also returns a function that generates a new {@link URLSearchParams} from
 * partial updates.
 *
 * This function is designed to be called in React Router `loader` functions.
 */
export function parseQueryState<T extends Object>(
  query: URLSearchParams,
  builder: MakeQueryFieldsFn<T>
): [QueryStateParseResult<T>, UpdateQueryStateParamsFn<T>] {
  const queryFields = builder(new QueryFieldBuilders());

  return [
    queryToQueryState(query, queryFields),
    makeUpdatedQueryStateParams.bind(null, query, queryFields),
  ];
}

/**
 * Hook that turns a {@link URLSearchParams} object and its setter into a little
 * state storage for scalarish types.
 *
 * Fields are defined by passing a `builder` function, which is called with an
 * instance of {@link QueryFieldBuilders}. This function must return a
 * {@link QueryFields} object, which is typically built by making an object
 * literal whose values are calls to {@link QueryFieldBuilders} members.
 *
 * Also returns an updater function as the second value of the tuple. This takes
 * a partial of values to update in the query. Passing `undefined` as a key’s
 * value, or a value that matches the default will remove that value from the
 * query string.
 *
 * Returns a 3rd argument to reset the entire query string.
 *
 * The updater function’s identity will not change over the life of this hook.
 * It also does not call `setQuery` if it would be a no-op (the updated query
 * object isEqual to the current one).
 */
export function useQueryState<T extends Object>(
  query: URLSearchParams,
  setQuery: (params: URLSearchParams) => void,
  /**
   * Builder function for defining the query state. This is passed as a
   * function, rather than a direct instance of {@link QueryFields}, purely for
   * convenience for getting a {@link QueryFieldBuilders} object with a nice,
   * short name.
   *
   * Currently, this hook throws exceptions for queries that don’t parse
   * successfully.
   *
   * TODO(fiona): Re-evaluate that behavior as necessary.
   */
  builder: MakeQueryFieldsFn<T>
): [
  T,
  UpdateQueryStateFn<T>,
  {
    reset: ResetQueryStateFn;
    paramsFromUpdates: UpdateQueryStateParamsFn<T>;
  }
] {
  const queryFields = builder(new QueryFieldBuilders());

  // Keeps our updateQueryState function from relying on these as deps and
  // having to change its value if they change.
  const queryRef = React.useRef(query);
  const setQueryRef = React.useRef(setQuery);
  const queryFieldsRef = React.useRef(queryFields);

  React.useLayoutEffect(() => {
    queryRef.current = query;
    setQueryRef.current = setQuery;
    queryFieldsRef.current = queryFields;
  });

  // In updateQueryState we don’t really care about whether or not things are
  // required. You’re always allowed to update a value to `undefined` to clear
  // it from the query parameters, which will cause the parser deserialize it to
  // a default on the other side.
  const updateQueryState = React.useCallback<UpdateQueryStateFn<T>>(
    (updates) => {
      const newQuery = makeUpdatedQueryStateParams(
        queryRef.current,
        queryFieldsRef.current,
        updates
      );

      // We want to no-op if there’s no actual change to the query string to
      // prevent accidentally looping and creating history entries.
      if (newQuery !== queryRef.current) {
        setQueryRef.current(newQuery);
      }
    },
    []
  );

  const reset = React.useCallback(() => {
    const next = new URLSearchParams(queryRef.current);

    /**
     * Used to check if we did anything, so that we can no-op if there’s
     * nothing to reset.
     */
    let removed = false;

    for (const key of Object.keys(queryFieldsRef.current)) {
      if (next.has(key)) {
        next.delete(key);
        removed = true;
      }
    }

    if (removed) {
      setQueryRef.current(next);
    }
  }, []);

  const paramsFromUpdates = React.useMemo(
    () => makeUpdatedQueryStateParams.bind(null, query, queryFields),
    [query, queryFields]
  );

  const queryState = queryToQueryState(query, queryFields);

  if (queryState.result !== 'success') {
    // TODO(fiona): Evaluate if this is the right call.
    throw new Error('useQueryState requires that the query succeed');
  }

  return [queryState.state, updateQueryState, { reset, paramsFromUpdates }];
}

/**
 * Internal function to parse a {@link URLSearchParams} into a query state
 * object based on the definitions in {@link QueryFields}.
 */
function queryToQueryState<T extends Object>(
  query: URLSearchParams,
  queryFields: QueryFields<T>
): QueryStateParseResult<T> {
  // TypeScript doesn’t give us a great way to build one object from another in
  // a way that preserves the distinct types for each key, so we use this more
  // general type and trust that the `QueryStateDefinition` type for builder’s
  // return value holds.
  const out: { [key: string]: any } = {};

  const missingFields = [];

  let queryNeedsUpdate = false;
  const updatedQuery = new URLSearchParams(query);

  for (const key in queryFields) {
    const param = query.get(key) ?? undefined;
    const queryField = queryFields[key];

    const valueResult = queryField.paramToValue(param);

    switch (valueResult.type) {
      case 'success':
        out[key] = valueResult.value;
        break;

      case 'retry':
        queryNeedsUpdate = true;
        updatedQuery.set(key, valueResult.newParam);
        break;

      case 'fail':
        missingFields.push(key);
        break;
    }
  }

  if (missingFields.length) {
    return { result: 'notFound', missingFields };
  } else if (queryNeedsUpdate) {
    return { result: 'redirect', updatedQuery };
  } else {
    return { result: 'success', state: out as T };
  }
}

/**
 * Applies partial updates of a query state to a {@link URLSearchParams} and
 * returns the new {@link URLSearchParams}. Parameterized over
 * {@link QueryFields} so we know how to serialize the updates.
 *
 * @param resetAll If true, removes all parameters managed by the
 * {@link QueryFields} from the query string before applying updates. This is
 * the same as passing `undefined` for all values in the `updates` param.
 *
 * @returns The new {@link URLSearchParams}, unless the updates are a no-op and
 * in that case returns the same object as before.
 *
 * @see UpdateQueryStateParamsFn
 */
function makeUpdatedQueryStateParams<T extends Object>(
  query: URLSearchParams,
  queryFields: QueryFields<T>,
  updates: Partial<Undefinable<T>>,
  resetAll = false
): URLSearchParams {
  const newQuery = new URLSearchParams(query);

  if (resetAll) {
    for (const key in queryFields) {
      newQuery.delete(key);
    }
  }

  for (const key in updates) {
    const val = updates[key];
    const queryField = queryFields[key];

    if (!queryField) {
      if (process.env['NODE_ENV'] === 'development') {
        console.warn(`Update given for unspecified query field: ${key}`);
      }

      continue;
    }

    const param =
      val === undefined ? undefined : queryField.valueToParam(val as any);

    if (param === undefined) {
      newQuery.delete(key);
    } else {
      newQuery.set(key, param);
    }
  }

  // We want to no-op if there’s no actual change to the query string to
  // prevent accidentally looping and creating history entries.
  if (!_.isEqual(Object.fromEntries(query), Object.fromEntries(newQuery))) {
    return newQuery;
  } else {
    return query;
  }
}

/**
 * `useQueryState` using the React Router default hooks for getting and setting
 * the query.
 *
 * @see useQueryState
 */
export function useRouterQueryState<T extends Object>(
  builder: MakeQueryFieldsFn<T>
): [
  T,
  UpdateQueryStateFn<T>,
  { reset: ResetQueryStateFn; paramsFromUpdates: UpdateQueryStateParamsFn<T> }
] {
  // While we’d like to use the updater version of `setQuery`, it does not
  // support no-oping by returning an identical value the way that `useState`’s
  // updater does. Because being able to no-op is very important, we just use
  // the direct version and compare against the most recently-rendered query
  // ourselves.
  const [searchParams, setSearchParams] = useSearchParams();

  return useQueryState(
    searchParams,
    // We call React Router on the next tick over to avoid a bug where
    // `setQuery` can’t be called from a child `useEffect` on mount because it
    // runs before a `useEffect` inside RR’s `useNavigate` hook.
    //
    // See: https://github.com/remix-run/react-router/issues/8809
    (q) => Promise.resolve().then(() => setSearchParams(q)),
    builder
  );
}

/**
 * Function to use in React Router loaders that parses out the query state from
 * the simulated Request object. If the state needs to be redirected or fails,
 * this throws the Response objects that React Router will automatically handle.
 *
 * Does not return an updater function. Wrapped pages that need to update query
 * parameters are expected to still call useQueryState.
 */
export function parseRequestQueryState<T extends Object>(
  request: Request,
  builder: MakeQueryFieldsFn<T>
): T {
  const url = new URL(request.url);

  const result = queryToQueryState(
    url.searchParams,
    builder(new QueryFieldBuilders())
  );

  if (result.result === 'redirect') {
    // TODO(fiona): Should this changed so that it does a replace rather than a
    // push state?
    throw new Response('', {
      status: 301,
      headers: {
        location: `${url.pathname}?${result.updatedQuery}`,
      },
    });
  } else if (result.result === 'notFound') {
    throw new Response(
      `Missing required fields: ${result.missingFields.join(', ')}`,
      {
        status: 404,
      }
    );
  } else {
    return result.state;
  }
}
