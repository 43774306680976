import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RIT from '../../../utils/render-if-truthy';

export default class UserDetails extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    user: Immutable.Map({}),
  };

  render() {
    const { user, showHeader } = this.props;
    const userName = `${user.get('first_name')} ${user.get('last_name')}`;

    return (
      <div>
        {RIT(showHeader, () => {
          return (
            <div className="lbj-column-label lbj-column-divider">
              <Link to={`users/${user.get('id')}`}>
                <h4 className="assignment-details-title-text">{userName}</h4>
              </Link>
            </div>
          );
        })}

        <div className="lbj-column-content">
          <ul className="lbj-detail-list">
            <li>
              <strong>Phone: </strong>
              {user.get('phone_number') || 'N/A'}
            </li>
            <li>
              <strong>Email: </strong>
              <span className="assignment-indented-detail">
                {user.get('email') || 'N/A'}
              </span>
            </li>
            <li>
              <strong>Address: </strong>
              {user.get('address') || 'Not provided'}
            </li>
            <li>
              <strong>Zipcode: </strong>
              {user.get('zipcode') || 'Not provided'}
            </li>
            <li>
              <strong>State: </strong>
              {user.get('state') || 'N/A'}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
