import { assignmentViewTypes } from '../../constants';

import mapUserToAssignments from './map-user-to-assignments';

const { LOCATIONS, PEOPLE } = assignmentViewTypes;

export function getAssignmentsFromResource(view, resource) {
  if (view === LOCATIONS) {
    return resource.get('assignment_set');
  } else if (view === PEOPLE) {
    return mapUserToAssignments(resource);
  }
  return null;
}

export function getAssignmentsFromParentResource(view, parentResource) {
  if (view === LOCATIONS) {
    return parentResource.getIn(['location', 'assignment_set']);
  } else if (view === PEOPLE && parentResource.has('user')) {
    return mapUserToAssignments(parentResource.get('user'));
  }
  return null;
}

export function getNumAssignmentsFromParentResource(view, parentResource) {
  const assignments = getAssignmentsFromParentResource(view, parentResource);
  return assignments ? assignments.size : 0;
}
