import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';

import mapStateToStartEndTime from '../../../utils/assignment/map-state-to-start-end-time';

import RIT from '../../../utils/render-if-truthy';

import AssignmentGroupHeader from './assignment-group-header';
import AssignmentRelatedObject from './assignment-related-object';

@connect((state, ownProps) => {
  // TODO: replace shiftDate val to not be dependent on filters
  const { assignment, filters } = state;
  let { precinct } = ownProps;
  const assignmentEditor = assignment.get('assignmentEditor');
  const isShowingDetails =
    assignmentEditor.getIn(['parentResource', 'precinct', 'id']) ===
    precinct.get('id');
  const shiftDate = filters.getIn([lbjAppSections.ASSIGNMENT, 'dates']);
  const { startTime, endTime } = mapStateToStartEndTime(state, shiftDate);

  const related = precinct.get('related');
  if (!related.isEmpty()) {
    const newRelated = related.map((relatedObj) => {
      const tempAssignments = relatedObj
        .get('assignments')
        .map((assignment) => {
          const tempAssignment = assignment.set('precinct', precinct);
          return tempAssignment.set('location', relatedObj.get('location'));
        });
      return Immutable.fromJS({
        location: relatedObj.get('location'),
        assignments: tempAssignments,
      });
    });
    precinct = precinct.set('related', newRelated);
  }

  return {
    isShowingDetails,
    isAssigning: isShowingDetails && assignmentEditor.get('isAssigning'),
    highlightedLocation: assignmentEditor.getIn(['parentResource', 'location']),
    pendingAssignment: assignmentEditor.getIn(['parentResource', 'assignment']),
    precinct: precinct,
    shiftDate,
    startTime,
    endTime,
  };
})
export default class PrecinctItem extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    precinct: PropTypes.object.isRequired,
    isShowingDetails: PropTypes.bool.isRequired,
    isAssigning: PropTypes.bool.isRequired,
    highlightedLocation: PropTypes.object,
    pendingAssignment: PropTypes.object,
    shiftDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  };

  onShowResourceDetails(precinct, location) {
    const { dispatch } = this.props;
    const { showParentResourceDetails } = assignmentActionCreators;

    dispatch(
      showParentResourceDetails({
        precinct,
        location: location ? location.get('location') : null,
      })
    );
  }

  onOpenNewAssignmentEditor(precinct, location) {
    const { dispatch, shiftDate, startTime, endTime } = this.props;
    const { openNewAssignmentEditor } = assignmentActionCreators;

    dispatch(
      openNewAssignmentEditor(
        {
          precinct,
          location: location ? location.get('location') : null,
        },
        'poll',
        shiftDate,
        startTime,
        endTime
      )
    );
  }

  onOpenExistingAssignmentEditor(precinct, location, assignment) {
    const { dispatch } = this.props;
    const { openExistingAssignmentEditor } = assignmentActionCreators;

    dispatch(
      openExistingAssignmentEditor(
        {
          precinct,
          location: location ? location.get('location') : null,
          assignment,
        },
        'poll'
      )
    );
  }

  renderLocationLabel(location) {
    let cityCounty;

    if (location.get('city')) {
      cityCounty = `${location.get('city')}, ${location.getIn([
        'county',
        'name',
      ])}`;
    } else {
      cityCounty = `${location.getIn(['county', 'name'])}`;
    }

    return `${location.get('name')} - ${cityCounty}`;
  }

  renderRelatedObjectWithoutLocation(assignments, key) {
    const {
      precinct,
      isShowingDetails,
      isAssigning,
      pendingAssignment,
      highlightedLocation,
    } = this.props;

    return (
      <AssignmentRelatedObject
        key={key}
        parentEntityClass="precinct"
        assignments={assignments}
        isAssigning={isShowingDetails && isAssigning && !highlightedLocation}
        isShowingDetails={isShowingDetails && !highlightedLocation}
        pendingAssignment={pendingAssignment}
        label="No Location"
        onShowResourceDetails={() => this.onShowResourceDetails(precinct)}
        onOpenNewAssignmentEditor={() =>
          this.onOpenNewAssignmentEditor(precinct)
        }
        onOpenExistingAssignmentEditor={(assignment) =>
          this.onOpenExistingAssignmentEditor(precinct, null, assignment)
        }
      />
    );
  }

  renderRelatedObject(location, key) {
    const {
      precinct,
      isShowingDetails,
      isAssigning,
      highlightedLocation,
      pendingAssignment,
    } = this.props;
    const assignments = location.get('assignments');
    const locationLabel = location.hasIn(['location', 'name'])
      ? this.renderLocationLabel(location.get('location'))
      : 'No Location';
    const locationId = location.getIn(['location', 'id']);
    let isAssigningToLocation, isShowingDetailsForLocation;

    if (highlightedLocation) {
      isAssigningToLocation =
        isShowingDetails &&
        isAssigning &&
        highlightedLocation.get('id') === locationId;
      isShowingDetailsForLocation =
        isShowingDetails && highlightedLocation.get('id') === locationId;
    } else {
      isAssigningToLocation = isShowingDetails && isAssigning && !locationId;
      isShowingDetailsForLocation = isShowingDetails && !locationId;
    }

    return (
      <AssignmentRelatedObject
        parentEntityClass="precinct"
        key={key}
        assignments={assignments}
        isShowingDetails={isShowingDetailsForLocation}
        isAssigning={isAssigningToLocation}
        pendingAssignment={pendingAssignment}
        label={locationLabel}
        onShowResourceDetails={() =>
          this.onShowResourceDetails(precinct, location)
        }
        onOpenNewAssignmentEditor={() =>
          this.onOpenNewAssignmentEditor(precinct, location)
        }
        onOpenExistingAssignmentEditor={(assignment) =>
          this.onOpenExistingAssignmentEditor(precinct, location, assignment)
        }
      />
    );
  }

  render() {
    const { precinct } = this.props;
    const related = precinct.get('related');
    const relatedIsEmpty = related.isEmpty();

    return (
      <div className="assignment-entity-group">
        <AssignmentGroupHeader
          onClick={() => this.onShowResourceDetails(precinct)}
          label={
            <span>
              {precinct.get('name')}
              {RIT(
                precinct.get('tier', () => {
                  return (
                    <span className="assignment-entity-ranking">
                      T{precinct.get('tier')}
                    </span>
                  );
                })
              )}
            </span>
          }
        />

        {RIT(relatedIsEmpty, () =>
          this.renderRelatedObjectWithoutLocation(Immutable.List([]))
        )}
        {RIT(!relatedIsEmpty, () => {
          return related.map((relatedObject, key) => {
            if (relatedObject.get('location')) {
              return this.renderRelatedObject(relatedObject, key);
            }

            return this.renderRelatedObjectWithoutLocation(
              relatedObject.get('assignments'),
              key
            );
          });
        })}
      </div>
    );
  }
}
