import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

import EmptyAlert from '../lbj/empty-alert';

import PrecinctItem from './assignment-precinct-item';

export default class PrecinctList extends Component {
  static propTypes = {
    precincts: PropTypes.object.isRequired,
    listRequestIsPending: PropTypes.bool.isRequired,
    listRequestErred: PropTypes.bool.isRequired,
  };

  renderEmptyState() {
    return (
      <EmptyAlert
        header="There are no precincts"
        description="Make sure you've selected a state on the left or change existing filters"
      />
    );
  }

  renderPrecinct(precinct, key) {
    return <PrecinctItem precinct={precinct} key={key} />;
  }

  render() {
    const { precincts, listRequestIsPending, listRequestErred } = this.props;
    const isEmpty = precincts.isEmpty();

    return (
      <div className="assignment-list-view-content">
        {RIT(
          isEmpty && !listRequestIsPending && !listRequestErred,
          this.renderEmptyState.bind(this)
        )}
        {RIT(!isEmpty, () => {
          return precincts.map((precinct, key) =>
            this.renderPrecinct(precinct, key)
          );
        })}
      </div>
    );
  }
}
