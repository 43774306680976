import _ from 'lodash';
import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import AssignmentIndexV2 from '../components/containers/assignment/assignment-index-v2';

import { lbjAppSections, State } from '../constants';
import {
  closeEditorPanel,
  getAssignmentListForViewTypeAsync,
  showToast,
} from '../modules/assignment/action-creators';
import fluxStore, { AppState } from '../modules/flux-store';
import {
  getCountyListAsync,
  getUserTagListAsync,
} from '../modules/lbj/action-creators';
import mapStateToAssignmentFilters from '../utils/assignment/map-state-to-assignment-filters';
import {
  mapAssignmentFiltersToQueryParams,
  mapQueryParamsToAssignmentFilters,
} from '../utils/assignment/query-params';
import { queryToSearch, searchToQuery } from '../utils/routing-provider';

import { storeAppFilters, redirectUnauthorizedUser } from './app';

const { ASSIGNMENT } = lbjAppSections;

function handleIndexRequestTimeout(state: AppState) {
  const { dispatch } = fluxStore;
  const view = state.filters.getIn([ASSIGNMENT, 'view']);
  const timedOut = state.assignment.getIn([
    'assignmentIndex',
    view,
    'error',
    'timeout',
  ]);

  if (timedOut) {
    dispatch(
      showToast({
        type: 'error',
        message:
          'A request timed out. Please check your network connection or email support if this keeps happening.',
      })
    );
  }
}

/**
 * Calculates state of app filters and requests the the assignment
 * data for the given view type.
 */
function handleAssignmentIndex(location: Location, navigate: NavigateFunction) {
  if (
    redirectUnauthorizedUser(fluxStore, navigate, [
      'vpd',
      'deputy_vpd',
      'boiler_room_leader',
      'boiler_room_user',
      'hotline_manager',
      'view_only',
    ])
  ) {
    return;
  }

  const { dispatch } = fluxStore;
  const { pathname, search } = location;
  const query = searchToQuery(search);

  const filterParams = mapQueryParamsToAssignmentFilters(query);
  const urlParams = mapAssignmentFiltersToQueryParams(filterParams);

  if (!_.isEqual(urlParams, query)) {
    navigate({ pathname, search: queryToSearch(urlParams) }, { replace: true });
    return;
  }

  dispatch(closeEditorPanel());

  storeAppFilters(fluxStore, filterParams, ASSIGNMENT).then((appState) => {
    const filters = mapStateToAssignmentFilters(appState);
    const stateParam = (filters.get('state') ||
      filters.get('assignment_state')) as State;

    dispatch(
      getAssignmentListForViewTypeAsync(filterParams.view, filters.toJS())
    ).then(handleIndexRequestTimeout);
    dispatch(getUserTagListAsync());
    dispatch(getCountyListAsync(stateParam));
  });
}

export const AssignmentsRoute: React.FunctionComponent = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  React.useEffect(() => {
    handleAssignmentIndex(location, navigateRef.current);
  }, [location]);

  return <AssignmentIndexV2 />;
};
