import * as Immutable from 'immutable';

import { Iso8601String } from '../../services/common';
import * as IssueService from '../../services/issue-service';

export type UpdateCursor = {
  minIssueId: number;
  maxIssueId: number;
  maxLastModified: Iso8601String | null;
};

export type ImmutableUpdateCursor = Immutable.Map<
  keyof UpdateCursor,
  number | string | null
>;

// need custom comparator, because the implicit null handling messes things up
function _compareLastModified(a: any, b: any) {
  if (a === b) {
    return 0;
  }

  if (!a && !!b) {
    return -1;
  }

  if (!b && !!a) {
    return 1;
  }

  return a < b ? -1 : 1;
}

/**
 * Returns an {@link UpdateCursor} for the given array of issues, which is
 * necessary for passing to {@link IssueService.getIssuesLastUpdated}.
 */
export function mapIssueListToUpdateCursor(
  issues: IssueService.IssueListApiIssue[] | undefined
): UpdateCursor | null {
  if (!issues || !issues.length) {
    return null;
  }

  // convert just the collection to Immutable so we can use the helper
  // functions.
  const issuesList = Immutable.List(issues);

  // We can assert that these will all return values because we check for issues
  // length above.
  const minIssueId = issuesList.minBy((x) => x.id)!.id;
  const maxIssueId = issuesList.maxBy((x) => x.id)!.id;
  const maxLastModified = issuesList.maxBy(
    (x) => x.last_modified,
    _compareLastModified
  )!.last_modified;

  return { minIssueId, maxIssueId, maxLastModified };
}
