import React from 'react';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { loginWithPasswordless } from '../../services/login-service';
import { Awaitable } from '../../utils/types';

import AuthUiModal from './AuthUiModal';

type AuthError = 'nonce' | 'google';

export const AuthCodeLandingView: React.FunctionComponent<{
  error?: AuthError | undefined;
  continueLoginFn?: (() => Awaitable<void>) | undefined;
  navigate: NavigateFunction;
}> = ({ error, continueLoginFn, navigate }) => {
  if (error) {
    return (
      <AuthUiModal>
        <div className="c-alert-error">
          <p>There was a problem logging you in.</p>

          {error === 'nonce' && (
            <>
              <p>
                The login link you clicked has expired or has already been used.
              </p>

              <p>
                If you keep seeing this message, use your phone’s browser to get
                to your inbox rather than your email app, or reach out to{' '}
                <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>.
              </p>
            </>
          )}
        </div>

        <div style={{ textAlign: 'center' }}>
          <button
            onClick={() => navigate('/login')}
            className="c-button-large c-button-secondary"
          >
            Go to Log In
          </button>
        </div>
      </AuthUiModal>
    );
  } else if (continueLoginFn) {
    return (
      <AuthUiModal>
        <div className="flex flex-col gap-4">
          <div className="text-lg font-bold">Welcome to LBJ!</div>

          <div>
            Click <strong>“Continue to LBJ”</strong> to finish logging in.
          </div>

          <div className="mt-10 text-center">
            <button
              onClick={() => continueLoginFn()}
              className="c-button-large c-button-secondary"
            >
              Continue to LBJ &raquo;
            </button>
          </div>
        </div>
      </AuthUiModal>
    );
  } else {
    return <AuthUiModal>Logging in to LBJ…</AuthUiModal>;
  }
};

/**
 * Parameter that the 1-time login code is passed in via the email.
 */
const LOGIN_CODE_PARAM = 'login_code';

/**
 * Handler for the `/auth` route, which handles the link from login emails.
 *
 * This is responsible for exchanging this code for a session token that can be
 * saved in our cookies for future request authentication.
 */
const AuthCodeLandingPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  const [params] = useSearchParams();

  const loginCode = params.get(LOGIN_CODE_PARAM);

  const [loggingIn, setLoggingIn] = React.useState(false);

  const [authError, setAuthError] = React.useState<AuthError | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!loginCode) {
      // If we got called without a query parameter, just redirect to "/" and
      // let it handle whether or not to show the login page or not based on
      // whether the user is currently logged in.
      navigateRef.current('/', { replace: true });
    }
  }, [loginCode]);

  return (
    <AuthCodeLandingView
      navigate={navigate}
      error={authError}
      continueLoginFn={
        loginCode && !loggingIn
          ? async () => {
              try {
                setLoggingIn(true);
                setAuthError(undefined);
                await loginWithPasswordless(loginCode);

                // If success, then an auth cookie has been set, so the app will make
                // authenticated requests. Redirect to "/" in order to see the app.
                //
                // We “replace” here because, among other things, trying to repeat this
                // action with the same token will cause an error, so we don’t want the
                // user to back-button into it.
                navigateRef.current('/', { replace: true });
              } catch {
                setAuthError('nonce');
                setLoggingIn(false);
              }
            }
          : undefined
      }
    />
  );
};

export default AuthCodeLandingPage;
