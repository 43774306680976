import { DateValue, parseDate, parseTime } from '@internationalized/date';
import type { TimeValue } from '@react-types/datepicker';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect, Dispatch } from 'react-redux';

import {
  Checkbox,
  FormError,
  Radio,
  RadioGroup,
  TextField,
} from '../../components/form';
import { ActionButton } from '../../components/form/ActionButton';
import { TimeField } from '../../components/form/DateField';
import { DatePicker } from '../../components/form/DatePicker';
import { TierBuilder } from '../../components/form/TierBuilder';
import { AppStore, toAppDispatch } from '../../modules/flux-store';
import { actionCreators as userActionCreators } from '../../modules/user';
import { loadCurrentUser } from '../../route-handlers/app';
import { DateString, TimeString } from '../../services/common';
import { updateElection } from '../../services/election-service';
import {
  ApiElection,
  ApiElectionUpdate,
  ApiLocationTierConfiguration,
  isPollObserverRegistrationRequirement,
} from '../../services/lbj-shared-service';
import { Awaitable } from '../../utils/types';

export const ElectionChangeForm: React.FunctionComponent<{
  election: ApiElection;
  /**
   * Funciton to call after we’ve updated the election and we want the version
   * attached to `currentUser` to reflect those changes.
   */
  refreshReduxElection: () => Awaitable<void>;
}> = ({ election, refreshReduxElection }) => {
  const initialValues = {
    eday_shift_start_time: election.eday_shift_start_time,
    eday_shift_change_time: election.eday_shift_change_time,
    eday_shift_end_time: election.eday_shift_end_time,
    early_vote_start: election.early_vote_start,
    early_vote_end: election.early_vote_end,
    ev_shift_start_time: election.ev_shift_start_time,
    ev_shift_change_time: election.ev_shift_change_time,
    ev_shift_end_time: election.ev_shift_end_time,
    contact_email: election.contact_email,
    location_tier_configuration: election.location_tier_configuration,
    assignment_preferences: election.assignment_preferences,
  };

  type FormErrors = {
    earlyVoteDateErrors: string[];
    earlyVoteShiftErrors: string[];
    eDayShiftErrors: string[];
    emailErrors: string[];
  };

  const formErrorStrings = {
    earlyVoteStartOrEndMissing:
      'If early vote start or end is defined, the other must be too',
    earlyVoteStartLaterThanEnd:
      'Early vote end date should be later than start date',
    earlyVoteShiftStartOrEndMissing:
      'If early vote shift start or end is defined, the other must be too',
    earlyVoteShiftChangeWithoutStartOrEnd:
      'If early vote shift change time is defined, start & end times must be too',
    earlyVoteShiftStartLaterThanChange:
      'Early vote shift change time should be later than start time',
    earlyVoteShiftStartLaterThanEnd:
      'Early vote shift end time should be later than start time',
    earlyVoteShiftChangeLaterThanEnd:
      'Early vote shift end time should be later than change time',
    eDayShiftStartOrEndMissing:
      'If election day shift start or end is defined, the other must be too',
    eDayShiftChangeWithoutStartOrEnd:
      'If election day shift change time is defined, start & end times must be too',
    eDayShiftStartLaterThanChange:
      'Election day shift change time should be later than start time',
    eDayShiftStartLaterThanEnd:
      'Election day shift end time should be later than start time',
    eDayShiftChangeLaterThanEnd:
      'Election day shift end time should be later than change time',
    invalidEmailFormat: 'Invalid email format',
  };

  const createEmptyFormErrors = (): FormErrors => {
    return {
      earlyVoteDateErrors: [],
      earlyVoteShiftErrors: [],
      eDayShiftErrors: [],
      emailErrors: [],
    };
  };

  const [formValues, setFormValues] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState(createEmptyFormErrors());
  const [formIsEdited, setFormIsEdited] = React.useState(false);

  const renderErrorBox = (errors: string[]) => {
    return (
      errors.length > 0 && (
        <div className="mt-2">
          <FormError>
            <ul>
              {errors.map((e) => {
                return (
                  <li key={e} className="text-sm">
                    {e}
                  </li>
                );
              })}
            </ul>
          </FormError>
        </div>
      )
    );
  };

  const contactEmailInputRef = React.useRef<HTMLInputElement>(null); // To access native html input validation

  const validate = (formValues: ApiElectionUpdate) => {
    const {
      eday_shift_start_time: eDayShiftStartTime,
      eday_shift_change_time: eDayShiftChangeTime,
      eday_shift_end_time: eDayShiftEndTime,
      early_vote_start: earlyVoteStartDate,
      early_vote_end: earlyVoteEndDate,
      ev_shift_start_time: earlyVoteShiftStartTime,
      ev_shift_change_time: earlyVoteShiftChangeTime,
      ev_shift_end_time: earlyVoteShiftEndTime,
    } = formValues;

    let errors: FormErrors = createEmptyFormErrors();

    if (
      (earlyVoteStartDate && !earlyVoteEndDate) ||
      (!earlyVoteStartDate && earlyVoteEndDate)
    ) {
      errors.earlyVoteDateErrors.push(
        formErrorStrings.earlyVoteStartOrEndMissing
      );
    }

    if (
      earlyVoteStartDate &&
      earlyVoteEndDate &&
      earlyVoteStartDate > earlyVoteEndDate
    ) {
      errors.earlyVoteDateErrors.push(
        formErrorStrings.earlyVoteStartLaterThanEnd
      );
    }

    if (
      (earlyVoteShiftStartTime && !earlyVoteShiftEndTime) ||
      (!earlyVoteShiftStartTime && earlyVoteShiftEndTime)
    ) {
      errors.earlyVoteShiftErrors.push(
        formErrorStrings.earlyVoteShiftStartOrEndMissing
      );
    }

    if (
      (earlyVoteShiftChangeTime && !earlyVoteShiftStartTime) ||
      (earlyVoteShiftChangeTime && !earlyVoteShiftEndTime)
    ) {
      errors.earlyVoteShiftErrors.push(
        formErrorStrings.earlyVoteShiftChangeWithoutStartOrEnd
      );
    }

    if (
      earlyVoteShiftStartTime &&
      earlyVoteShiftChangeTime &&
      earlyVoteShiftStartTime > earlyVoteShiftChangeTime
    ) {
      errors.earlyVoteShiftErrors.push(
        formErrorStrings.earlyVoteShiftStartLaterThanChange
      );
    }

    if (
      earlyVoteShiftStartTime &&
      earlyVoteShiftEndTime &&
      earlyVoteShiftStartTime > earlyVoteShiftEndTime
    ) {
      errors.earlyVoteShiftErrors.push(
        formErrorStrings.earlyVoteShiftStartLaterThanEnd
      );
    }

    if (
      earlyVoteShiftChangeTime &&
      earlyVoteShiftEndTime &&
      earlyVoteShiftChangeTime > earlyVoteShiftEndTime
    ) {
      errors.earlyVoteShiftErrors.push(
        formErrorStrings.earlyVoteShiftChangeLaterThanEnd
      );
    }

    if (
      (eDayShiftStartTime && !eDayShiftEndTime) ||
      (!eDayShiftStartTime && eDayShiftEndTime)
    ) {
      errors.eDayShiftErrors.push(formErrorStrings.eDayShiftStartOrEndMissing);
    }

    if (
      (eDayShiftChangeTime && !eDayShiftStartTime) ||
      (eDayShiftChangeTime && !eDayShiftEndTime)
    ) {
      errors.eDayShiftErrors.push(
        formErrorStrings.eDayShiftChangeWithoutStartOrEnd
      );
    }

    if (
      eDayShiftStartTime &&
      eDayShiftChangeTime &&
      eDayShiftStartTime > eDayShiftChangeTime
    ) {
      errors.eDayShiftErrors.push(
        formErrorStrings.eDayShiftStartLaterThanChange
      );
    }

    if (
      eDayShiftStartTime &&
      eDayShiftEndTime &&
      eDayShiftStartTime > eDayShiftEndTime
    ) {
      errors.eDayShiftErrors.push(formErrorStrings.eDayShiftStartLaterThanEnd);
    }

    if (
      eDayShiftChangeTime &&
      eDayShiftEndTime &&
      eDayShiftChangeTime > eDayShiftEndTime
    ) {
      errors.eDayShiftErrors.push(formErrorStrings.eDayShiftChangeLaterThanEnd);
    }

    if (contactEmailInputRef.current?.validity.typeMismatch) {
      errors.emailErrors.push(formErrorStrings.invalidEmailFormat);
    }

    setFormErrors(errors);
  };

  // TODO (Liz): handle 400/500 errors
  const handleSubmit = async (formValues: ApiElectionUpdate) => {
    setLoading(true);

    try {
      await updateElection(formValues, election.id);
      await refreshReduxElection();
    } catch {
      // TO (Liz): what is error behavior?
      // setFormErrors(['Something went wrong. Please try again.']);
    } finally {
      setLoading(false);
      setFormIsEdited(false);
      setFormErrors(createEmptyFormErrors());
    }
  };

  const [loading, setLoading] = React.useState(false);

  function earliestTimeString(times: Array<TimeString | null>) {
    return _.compact(times).sort()[0] || '23:59';
  }

  function latestTimeString(times: Array<TimeString | null>) {
    return _.compact(times).sort().reverse()[0] || '00:00';
  }

  React.useEffect(() => {
    validate(formValues);
    // The linter wants validate() to be inside the dependency array, which is unnecessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <div className="reset-2023 flex w-full flex-col overflow-y-auto pt-4 pb-8 text-gray-700">
      <div className="flex flex-col gap-8 md:w-9/12 md:self-center">
        <h1 className="font-bold">
          <div className="text-2xl">Election Settings</div>
        </h1>
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold">Volunteer Onboarding</h2>
          <div>
            <Checkbox
              onChange={(checked) => {
                setFormIsEdited(true);
                setFormValues((prev) => ({
                  ...prev,
                  assignment_preferences: {
                    ...prev.assignment_preferences,
                    force_poll_observer_survey: checked,
                  },
                }));
              }}
              isSelected={
                !!formValues.assignment_preferences.force_poll_observer_survey
              }
            >
              Require poll observers to report distance and availability
              preferences on login
            </Checkbox>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold">
            Poll Observer Voter Registration Requirement
          </h2>
          <div>
            <RadioGroup
              defaultValue={
                initialValues.assignment_preferences
                  .poll_observer_registration_requirement || 'none'
              }
              onChange={(value) => {
                setFormIsEdited(true);
                if (isPollObserverRegistrationRequirement(value)) {
                  setFormValues((prev) => ({
                    ...prev,
                    assignment_preferences: {
                      ...prev.assignment_preferences,
                      poll_observer_registration_requirement: value,
                    },
                  }));
                }
              }}
            >
              <Radio value="none">Unenforced</Radio>
              <Radio value="county">Registered County</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold">Election Day schedule</h2>
          <div>
            <TextField
              label="Election date"
              value={moment(election.election_date, 'YYYY-MM-DD').format(
                'MMMM D, YYYY'
              )}
              inputClassName="h-[40px] px-3 w-60"
              isDisabled
            />
          </div>
          <div>
            <div className="flex gap-x-4">
              <div className="w-60">
                <TimeField
                  label="Election Day shift start time"
                  {...(formValues.eday_shift_start_time
                    ? { value: parseTime(formValues.eday_shift_start_time) }
                    : {})}
                  maxValue={parseTime(
                    earliestTimeString([
                      formValues.eday_shift_change_time,
                      formValues.eday_shift_end_time,
                    ])
                  )}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      eday_shift_start_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !(
                      formErrors.eDayShiftErrors.includes(
                        formErrorStrings.eDayShiftChangeWithoutStartOrEnd
                      ) ||
                      formErrors.eDayShiftErrors.includes(
                        formErrorStrings.eDayShiftStartOrEndMissing
                      )
                    )
                  }
                />
              </div>
              <div className="w-60">
                <TimeField
                  label="Election Day shift change time"
                  {...(formValues.eday_shift_change_time
                    ? { value: parseTime(formValues.eday_shift_change_time) }
                    : {})}
                  minValue={parseTime(
                    formValues.eday_shift_start_time ?? '00:00'
                  )}
                  maxValue={parseTime(
                    formValues.eday_shift_end_time ?? '23:59'
                  )}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      eday_shift_change_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !formErrors.eDayShiftErrors.includes(
                      formErrorStrings.eDayShiftChangeWithoutStartOrEnd
                    )
                  }
                />
              </div>
              <div className="w-60">
                <TimeField
                  label="Election Day shift end time"
                  {...(formValues.eday_shift_end_time
                    ? { value: parseTime(formValues.eday_shift_end_time) }
                    : {})}
                  minValue={parseTime(
                    latestTimeString([
                      formValues.eday_shift_change_time,
                      formValues.eday_shift_start_time,
                    ])
                  )}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      eday_shift_end_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !(
                      formErrors.eDayShiftErrors.includes(
                        formErrorStrings.eDayShiftChangeWithoutStartOrEnd
                      ) ||
                      formErrors.eDayShiftErrors.includes(
                        formErrorStrings.eDayShiftStartOrEndMissing
                      )
                    )
                  }
                />
              </div>
            </div>
            <p className="mt-2 text-base leading-relaxed">
              It may take a few minutes for changes to shift schedules to appear
              on the assignments page.
            </p>
            {renderErrorBox(formErrors.eDayShiftErrors)}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold">Early Vote schedule</h2>
          <div>
            <div className="flex gap-x-4">
              <div className="w-60">
                <DatePicker
                  label="Early Vote start date"
                  {...(formValues.early_vote_start
                    ? { value: parseDate(formValues.early_vote_start) }
                    : {})}
                  maxValue={parseDate(
                    formValues.early_vote_end
                      ? formValues.early_vote_end
                      : '2100-01-01' // can't be null
                  )}
                  onChange={(date: DateValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      early_vote_start: date
                        ? (date.toString() as DateString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !formErrors.earlyVoteDateErrors.includes(
                      formErrorStrings.earlyVoteStartOrEndMissing
                    )
                  }
                />
              </div>
              <div className="w-60">
                <DatePicker
                  label="Early Vote end date"
                  {...(formValues.early_vote_end
                    ? { value: parseDate(formValues.early_vote_end) }
                    : {})}
                  minValue={parseDate(
                    formValues.early_vote_start
                      ? formValues.early_vote_start
                      : '1900-01-01' // can't be null
                  )}
                  onChange={(date: DateValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      early_vote_end: date
                        ? (date.toString() as DateString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !formErrors.earlyVoteDateErrors.includes(
                      formErrorStrings.earlyVoteStartOrEndMissing
                    )
                  }
                />
              </div>
            </div>
            {renderErrorBox(formErrors.earlyVoteDateErrors)}
          </div>
          <div>
            <div className="flex gap-x-4">
              <div className="w-60">
                <TimeField
                  label="Early Vote shift start time"
                  {...(formValues.ev_shift_start_time
                    ? { value: parseTime(formValues.ev_shift_start_time) }
                    : {})}
                  maxValue={parseTime(
                    earliestTimeString([
                      formValues.ev_shift_change_time,
                      formValues.ev_shift_end_time,
                    ])
                  )}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      ev_shift_start_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !(
                      formErrors.earlyVoteShiftErrors.includes(
                        formErrorStrings.earlyVoteShiftChangeWithoutStartOrEnd
                      ) ||
                      formErrors.earlyVoteShiftErrors.includes(
                        formErrorStrings.earlyVoteShiftStartOrEndMissing
                      )
                    )
                  }
                />
              </div>
              <div className="w-60">
                <TimeField
                  label="Early Vote shift change time"
                  {...(formValues.ev_shift_change_time
                    ? { value: parseTime(formValues.ev_shift_change_time) }
                    : {})}
                  minValue={parseTime(
                    formValues.ev_shift_start_time ?? '00:00'
                  )}
                  maxValue={parseTime(formValues.ev_shift_end_time ?? '23:59')}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      ev_shift_change_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !formErrors.earlyVoteShiftErrors.includes(
                      formErrorStrings.earlyVoteShiftChangeWithoutStartOrEnd
                    )
                  }
                />
              </div>
              <div className="w-60">
                <TimeField
                  label="Early Vote shift end time"
                  {...(formValues.ev_shift_end_time
                    ? { value: parseTime(formValues.ev_shift_end_time) }
                    : {})}
                  minValue={parseTime(
                    latestTimeString([
                      formValues.ev_shift_change_time,
                      formValues.ev_shift_start_time,
                    ])
                  )}
                  onChange={(time: TimeValue | null) => {
                    setFormIsEdited(true);
                    setFormValues((prev) => ({
                      ...prev,
                      ev_shift_end_time: time
                        ? (time.toString() as TimeString)
                        : null,
                    }));
                  }}
                  isValid={
                    // Only for conditions that aren't handled by react-aria's built-in validations
                    !(
                      formErrors.earlyVoteShiftErrors.includes(
                        formErrorStrings.earlyVoteShiftChangeWithoutStartOrEnd
                      ) ||
                      formErrors.earlyVoteShiftErrors.includes(
                        formErrorStrings.earlyVoteShiftStartOrEndMissing
                      )
                    )
                  }
                />
              </div>
            </div>
            {renderErrorBox(formErrors.earlyVoteShiftErrors)}
          </div>
        </div>
        <div className="flex w-4/5 flex-col gap-y-4">
          <h2 className="text-xl font-bold">Location tiers</h2>
          <div className="flex flex-col gap-2">
            <p className="text-base leading-relaxed">
              Tiers are used on the Polls (beta) assignment page to organize and
              group polling locations based on their ranking. You can upload a
              CSV of polling location rankings on that page.
            </p>
            <p className="text-base leading-relaxed">
              Note: These tiers are not the same as precinct tiers, which can
              only be updated via support request.
            </p>
          </div>
          <TierBuilder
            election={election}
            edayHasShiftChangeTime={formValues.eday_shift_change_time !== null}
            onTierBuilderChange={(tiers: ApiLocationTierConfiguration[]) => {
              setFormIsEdited(true);
              setFormValues((prev) => ({
                ...prev,
                location_tier_configuration: tiers,
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold">Contact email</h2>
          <div>
            <TextField
              label="Contact email"
              type="email"
              value={formValues.contact_email as string}
              inputClassName="h-[40px] px-3 w-[496px]"
              inputRef={contactEmailInputRef}
              onChange={(email: string) => {
                setFormIsEdited(true);
                setFormValues((prev) => ({
                  ...prev,
                  contact_email: email,
                }));
              }}
            />
            {renderErrorBox(formErrors.emailErrors)}
          </div>
        </div>
        <div>
          <ActionButton
            onPress={() => {
              handleSubmit(formValues);
            }}
            isDisabled={
              !formIsEdited ||
              !Object.values(formErrors).every((el) => el.length === 0) || // at least one error
              loading
            }
          >
            {loading ? 'loading…' : 'save changes'}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

const UnconnectedElectionSettingsContainer: React.FunctionComponent<{
  dispatch?: Dispatch<any> | undefined;
  election: ApiElection;
}> = (props) => {
  const { election } = props;
  const dispatch = toAppDispatch(props.dispatch);

  return (
    <ElectionChangeForm
      election={election}
      refreshReduxElection={async () => {
        await dispatch(userActionCreators.getCurrentUserAsync());
      }}
    />
  );
};

export async function loadElectionSettingsPage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof UnconnectedElectionSettingsContainer>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: ['vpd', 'deputy_vpd'],
  });

  const election = currentUserElection.get('election').toJS() as ApiElection;

  return { election };
}

export default connect()(UnconnectedElectionSettingsContainer);
