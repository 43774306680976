import { lbjAppSections } from '../../constants';

export default function (state) {
  const { user, filters } = state;
  const userResponse = user.getIn(['users', 'index']);
  const userCount = user.getIn(['users', 'count']);
  const userListViewType = filters.getIn([lbjAppSections.USER, 'view']);

  return {
    totalUserCount: userCount.get('total'),
    userListData: userResponse.get('listData'),
    isFetchingUserList: userResponse.get('requestIsPending'),
    userResponseErred: userResponse.get('requestErred'),
    updatingUserRequestStatus: user.get('updatingUser'),
    allUserIds: user.getIn(['allUserIds', userListViewType, 'userIds']),
  };
}
