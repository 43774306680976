import React from 'react';

import Modal from '../../components/presentational/lbj/modal';

/**
 * Common modal component for our login/auth UI that shows the LBJ logo in the
 * title bar.
 */
const AuthUiModal: React.FunctionComponent = ({ children }) => (
  <Modal
    dismissable={false}
    headerText={
      <img
        src="../../../../img/logo.svg"
        className="lbj-modal-title-logo"
        alt="LBJ"
      />
    }
  >
    <div className="lbj-login-form-v2">{children}</div>
  </Modal>
);

export default AuthUiModal;
