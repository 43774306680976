import { DateValue, createCalendar } from '@internationalized/date';
import type { TimeValue } from '@react-types/datepicker';
import cx from 'classnames';

import React from 'react';
import {
  AriaDateFieldProps,
  AriaTimeFieldProps,
  useDateField,
  useDateSegment,
  useLocale,
  useTimeField,
} from 'react-aria';
import {
  DateFieldState,
  DateSegment,
  useDateFieldState,
  useTimeFieldState,
} from 'react-stately';

import { FormLabel } from './FormLabel';

export function DateField(props: AriaDateFieldProps<DateValue>) {
  let { locale } = useLocale();
  let state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = React.useRef(null);
  let { labelProps, fieldProps } = useDateField(props, state, ref);

  return (
    <div className="flex h-full items-center px-3 leading-none">
      <span {...labelProps}>{props.label}</span>
      <div {...fieldProps} ref={ref} className="flex">
        {state.segments.map((segment, i) => (
          <DateTimeSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  );
}

export function TimeField(
  props: AriaTimeFieldProps<TimeValue> & { isValid?: boolean }
) {
  const { locale } = useLocale();
  const { label } = props;
  const state = useTimeFieldState({
    ...props,
    locale,
  });

  const ref = React.useRef(null);
  const { labelProps, fieldProps } = useTimeField(props, state, ref);

  return (
    <div className="flex flex-col items-stretch gap-2">
      {typeof label === 'string' ? (
        <FormLabel type="label" {...labelProps} isRequired={props.isRequired}>
          {label}
        </FormLabel>
      ) : (
        label
      )}

      <div
        {...fieldProps}
        ref={ref}
        className={cx(
          'flex min-w-[90px] justify-end rounded border-2 p-2 text-base text-gray-700',
          {
            'bg-gray-300': props.isDisabled,
            'bg-white': !props.isDisabled,
            'border-red-700':
              props.validationState === 'invalid' || props.isValid === false,
            'border-gray-300':
              props.validationState !== 'invalid' && props.isValid !== false,
          }
        )}
      >
        {state.segments.map((segment, i) => (
          <DateTimeSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  );
}

const DateTimeSegment: React.FunctionComponent<{
  segment: DateSegment;
  state: DateFieldState;
}> = ({ segment, state }) => {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div {...segmentProps} ref={ref}>
      {segment.text}
    </div>
  );
};
