import type { DOMProps } from '@react-types/shared';
import cx from 'classnames';
import React from 'react';
import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  useOverlayTrigger,
  OverlayTriggerProps as AriaOverlayTriggerProps,
  usePopover,
  AriaButtonProps,
  PlacementAxis,
} from 'react-aria';
import {
  OverlayTriggerProps,
  OverlayTriggerState,
  useOverlayTriggerState,
} from 'react-stately';

import { UNDERLAY_CLASSES } from './layout-utils';

/**
 * Makes a react-aria popover.
 *
 * This should likely wrap a `<Dialog>` component.
 *
 * @see https://react-spectrum.adobe.com/react-aria/usePopover.html
 */
export const PopoverView: React.FunctionComponent<
  Omit<AriaPopoverProps, 'popoverRef'> & {
    state: OverlayTriggerState;
    arrowColor?: string;
    /** Classes to apply to the absolutely-positioned popover container. Useful
     * for adding min-width or max-height. */
    popoverClassName?: string | undefined;
    popoverRef?: React.RefObject<HTMLDivElement>;
  }
> = ({
  children,
  state,
  popoverRef,
  popoverClassName,
  offset = 8,
  crossOffset = 0,
  arrowSize = 12,
  arrowBoundaryOffset = 6,
  arrowColor = '#5a5a66',
  ...props
}) => {
  const internalPopoverRef = React.useRef(null);
  popoverRef = popoverRef ?? internalPopoverRef;

  const { popoverProps, underlayProps, arrowProps, placement } = usePopover(
    {
      ...props,
      offset,
      crossOffset,
      popoverRef,
      arrowSize,
      arrowBoundaryOffset,
    },
    state
  );

  return (
    // Overlay puts this in a React portal and isolates the focus to it.
    <Overlay>
      {/* Underlay to trap scroll wheel events and clicks */}
      <div {...underlayProps} className={UNDERLAY_CLASSES} />

      {/* We position the arrow separately from the popover container
          so that the popover container can have overflow:hidden. This
          allows it to clip to its rounded corners and also pass height
          restrictions down to child flex elements. */}
      <div style={popoverProps.style}>
        {/* The arrow. */}
        <svg
          {...arrowProps}
          className="absolute"
          viewBox="0 0 12 12"
          fill={arrowColor}
          style={{
            width: 12,
            height: 12,
            ...arrowProps.style,
            ...getPopoverArrowStyle(placement),
          }}
        >
          <path d="M0 0 L6 6 L12 0" />
        </svg>
      </div>

      {/* The positioned popover element */}
      <div
        {...popoverProps}
        ref={popoverRef}
        // add reset-2023 since we’re in a portal and can’t inherit from the
        // page
        className={cx(
          'reset-2023 flex flex-col overflow-hidden rounded-lg shadow',
          popoverClassName
        )}
      >
        {/* Recommendation for a11y is to have these hidden dismiss prompts before and after content. */}
        <DismissButton onDismiss={state.close} />

        {children}

        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

/**
 * Based on a placement parameter, returns style attributes to position an arrow
 * SVG on the correct side of a popover.
 */
function getPopoverArrowStyle(
  placement: PlacementAxis
): React.CSSProperties | undefined {
  switch (placement) {
    case 'top':
      return {
        top: '100%',
        transform: 'translate(-50%)',
      };

    case 'bottom':
      return {
        bottom: '100%',
        transform: 'translate(-50%)rotate(180deg)',
      };

    case 'left':
      return {
        left: '100%',
        transform: 'translateY(-50%)rotate(-90deg)',
      };

    case 'right':
      return {
        right: '100%',
        transform: 'translateY(-50%)rotate(90deg)',
      };
  }
}

/**
 * Renders a trigger element and a popover that’s displayed if it’s clicked.
 *
 * The content of the popover can be specified either as children or, if the
 * {@link OverlayTriggerState} object is needed (_e.g._ to get access to the
 * close method), the `renderContent` prop.
 */
export const Popover: React.FunctionComponent<
  {
    /**
     * Render a button to trigger the popover.
     */
    trigger: (
      props: AriaButtonProps,
      /**
       * Ref to apply to the trigger element (for positioning). Uses `any`
       * because ref typing is kind of a mess.
       */
      ref: React.RefObject<any>
    ) => React.ReactNode;
    /**
     * Render the contents of the {@link Popover}. If not provided, wraps its
     * `children` in a `<div>` instead.
     *
     * @param props The DOM attributes that should be applied to the content of
     * the popover.  (_E.g._ the element with `role="dialog"`.)
     * @param state The {@link OverlayTriggerState}, for accessing the `close`
     * function, if desired.
     */
    content?: (props: DOMProps, state: OverlayTriggerState) => React.ReactNode;
    triggerRef?: AriaPopoverProps['triggerRef'] | undefined;
  } & OverlayTriggerProps &
    AriaOverlayTriggerProps &
    Omit<AriaPopoverProps, 'triggerRef' | 'popoverRef'>
> = ({ children, trigger, content, triggerRef, ...props }) => {
  const localTriggerRef = React.useRef(null);
  triggerRef = triggerRef ?? localTriggerRef;
  const popoverState = useOverlayTriggerState(props);

  const { triggerProps, overlayProps } = useOverlayTrigger(
    props,
    popoverState,
    triggerRef
  );

  return (
    <>
      {trigger(triggerProps, triggerRef)}

      {popoverState.isOpen && (
        <PopoverView state={popoverState} triggerRef={triggerRef} {...props}>
          {content ? (
            content(
              // Smol dance because of TypeScript’s exact optional property
              // types
              overlayProps.id ? { id: overlayProps.id } : {},
              popoverState
            )
          ) : (
            <div {...overlayProps}>{children}</div>
          )}
        </PopoverView>
      )}
    </>
  );
};
