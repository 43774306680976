import { Immutable, Draft } from 'immer';
import { chunk } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Item } from 'react-stately';

import {
  ActionButton,
  FileActionButton,
  ListBox,
  TextButton,
} from '../../components/form';
import { usePapaParse } from '../../components/hooks/papa-parse';
import { ModalDialog } from '../../components/layout';

import { AppStore } from '../../modules/flux-store';
import { loadCurrentUser } from '../../route-handlers/app';
import { bulkCreateAssignments } from '../../services/assignment-service';
import { DateString, DATE_STRING_FORMAT } from '../../services/common';
import { ApiElection } from '../../services/lbj-shared-service';

import {
  assignmentReducer,
  DEFAULT_ASSIGNMENT_STATE,
} from './assignment-state';
import { useLoadInitialAssignmentState } from './assignment-utils';
import { BulkAssignment, bulkToApiAssignment } from './bulk-assignment-utils';

const BulkUploadAssignmentsPage: React.FunctionComponent<{
  currentElection: ApiElection;
}> = ({ currentElection }) => {
  const [csvState, setCsvFile] = usePapaParse({ header: true });

  const [userIdsByCsvEmail, setUserIdsByCsvEmail] = React.useState<
    Immutable<Map<string, number>>
  >(new Map());

  // We re-use the assignment state because we have machinery to load users and
  // locations into it.
  const [assignmentState, assignmentDispatch] = React.useReducer(
    assignmentReducer,
    DEFAULT_ASSIGNMENT_STATE
  );

  /**
   * Map of the CSV’s contents by the volunteer email addresses. Note that these
   * are not guaranteed to map to LBJ users out-of-the-box because LBJ often has
   * a different sense of a user’s email address than they do.
   */
  const [bulkAssignmentsByEmail, setBulkAssignmentsByEmail] = React.useState<
    Immutable<Map<string, BulkAssignment[]>>
  >(new Map());

  /**
   * Count of all of the email addresses mentioned in the file that we don’t
   * have LBJ users for.
   */
  const unmatchedEmailCount = React.useMemo(
    () =>
      [...bulkAssignmentsByEmail.keys()].filter(
        (email) => !userIdsByCsvEmail.has(email)
      ).length,
    [userIdsByCsvEmail, bulkAssignmentsByEmail]
  );

  const unknownLocationIds = React.useMemo(
    () =>
      new Set(
        [...bulkAssignmentsByEmail.values()]
          .flatMap((assignments) => assignments.map((a) => a.locationId))
          .filter((locId) => !assignmentState.locationsById.has(locId))
      ),
    [assignmentState.locationsById, bulkAssignmentsByEmail]
  );

  const [loadingStatus] = useLoadInitialAssignmentState(assignmentDispatch);

  React.useEffect(
    () => {
      if (loadingStatus.status === 'done') {
        const newUserIdsByCsvEmail: Draft<typeof userIdsByCsvEmail> = new Map();

        for (const user of assignmentState.usersById.values()) {
          newUserIdsByCsvEmail.set(user.email.toLowerCase(), user.id);
        }

        setUserIdsByCsvEmail(newUserIdsByCsvEmail);
      }
    },
    // Don’t rely on assignmentState.usersById
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingStatus.status]
  );

  React.useEffect(() => {
    if (csvState.status === 'success') {
      const results = csvState.results;

      const [
        emailKey,
        locationIdKey,
        shiftTypeKey,
        shiftDateKey,
        shiftTimeKey,
        placeKey,
        ...otherKeys
      ] = results.meta.fields!;

      const newBulkAssignmentsByEmail: Draft<typeof bulkAssignmentsByEmail> =
        new Map();

      for (const row of csvState.results.data) {
        const assignment: BulkAssignment = {
          email: row[emailKey!]!.toLowerCase(),
          locationId: parseInt(row[locationIdKey!]!),
          type: row[shiftTypeKey!] as BulkAssignment['type'],
          date: moment(row[shiftDateKey!]).format(
            DATE_STRING_FORMAT
          ) as DateString,
          place: row[placeKey!] as BulkAssignment['place'],
          time: row[shiftTimeKey!] as BulkAssignment['time'],
          otherFields: Object.fromEntries(otherKeys.map((k) => [k, row[k]!])),
        };

        const assignments = newBulkAssignmentsByEmail.get(assignment.email);
        if (assignments) {
          // OK to mutate while we’re building the data structure
          assignments.push(assignment);
        } else {
          newBulkAssignmentsByEmail.set(assignment.email, [assignment]);
        }
      }

      setBulkAssignmentsByEmail(newBulkAssignmentsByEmail);
    }
  }, [csvState.status, csvState.results]);

  const [editMatchEmail, setEditMatchEmail] = React.useState<string | null>(
    null
  );

  const [editMatchSelectedUserId, setEditMatchSelectedUserId] = React.useState<
    number | null
  >(null);

  // Resets the selected user ID if you re-open the dialog
  React.useLayoutEffect(() => {
    setEditMatchSelectedUserId(null);
  }, [editMatchEmail]);

  const [isSaving, setSaving] = React.useState(false);

  const [successCount, setSuccessCount] = React.useState<number | null>(null);

  const saveAssignments = React.useCallback(async () => {
    setSaving(true);

    try {
      const newAssignments = [...bulkAssignmentsByEmail.values()].flatMap(
        (rows) =>
          rows.map((row) =>
            bulkToApiAssignment(userIdsByCsvEmail, currentElection, row)
          )
      );

      let assignmentCount = 0;

      const newAssignmentChunks = chunk(newAssignments, 500);

      for (const newAssignmentChunk of newAssignmentChunks) {
        const assignments = await bulkCreateAssignments(newAssignmentChunk);
        assignmentCount += assignments.length;
      }

      setSuccessCount(assignmentCount);
      setCsvFile(null);
    } catch (e) {
      window.alert('There was an error saving assignments. Check the console.');
      console.error('ERROR', e);
    } finally {
      setSaving(false);
    }
  }, [bulkAssignmentsByEmail, userIdsByCsvEmail, currentElection, setCsvFile]);

  const literalClassNames = 'text-sm font-bold';

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex justify-between p-4">
        <div></div>
        <div className="flex gap-2">
          <ActionButton
            role="secondary"
            onPress={() => {
              setEditMatchEmail(null);
              setCsvFile(null);
            }}
            isDisabled={csvState.status !== 'success' || isSaving}
          >
            Reset CSV
          </ActionButton>

          <ActionButton
            role="primary"
            isDisabled={
              isSaving ||
              bulkAssignmentsByEmail.size === 0 ||
              unmatchedEmailCount > 0 ||
              unknownLocationIds.size > 0
            }
            onPress={saveAssignments}
          >
            {isSaving ? 'Saving Assignments…' : 'Save Assignments'}
          </ActionButton>
        </div>
      </div>

      {successCount !== null && (
        <div className="bg-green-300 p-2 text-center font-bold">
          Successfully created {successCount} assignments!
        </div>
      )}

      {csvState.status !== 'success' && (
        <div className="mb-[10%] flex flex-1 flex-col items-center justify-center">
          <div className="flex w-[500px] flex-col gap-4 border-2 border-primary p-4">
            <p>Choose a CSV file of assignments.</p>

            <p>
              Include a header row. The first 6 columns should be, in order:
            </p>

            <ol className="list-decimal pl-8">
              <li>e-mail address</li>
              <li>LBJ location ID</li>
              <li>
                shift type (<i>e.g.</i>{' '}
                <code className={literalClassNames}>poll</code>)
              </li>
              <li>shift date in MM/DD/YYYY format</li>
              <li>
                shift time (<code className={literalClassNames}>morning</code>,{' '}
                <code className={literalClassNames}>afternoon</code>, or{' '}
                <code className={literalClassNames}>all day</code>, or a custom
                time range in 24h time: <i>e.g.</i>{' '}
                <code className={literalClassNames}>0800-1930</code>)
              </li>
              <li>
                place (<code className={literalClassNames}>inside</code> or{' '}
                <code className={literalClassNames}>outside</code>)
              </li>
            </ol>

            <FileActionButton
              accept=".csv"
              onFiles={([file]) => {
                setSuccessCount(null);
                setCsvFile(file);
              }}
              isDisabled={
                csvState.status === 'parsing' || loadingStatus.status !== 'done'
              }
            >
              {loadingStatus.status === 'loading'
                ? `Loading ${
                    loadingStatus.stage === 'locations'
                      ? 'location'
                      : 'volunteer'
                  } info… (${loadingStatus.count})`
                : 'Upload CSV'}
            </FileActionButton>

            {csvState.status === 'failure' && (
              <div className="border-2 border-error p-2">
                There was an error parsing the file.
                <br />
                {csvState.error.toString()}
              </div>
            )}
          </div>
        </div>
      )}

      {csvState.status === 'success' && (
        <>
          {unknownLocationIds.size > 0 && (
            <div className="my-4 px-8 text-error">
              The following {unknownLocationIds.size} location IDs were not
              found for this election: {[...unknownLocationIds].join(', ')}
              <br />
              You will need to correct them and re-upload your CSV.
            </div>
          )}

          {unmatchedEmailCount > 0 && (
            <div className="my-4 px-8 text-error">
              {unmatchedEmailCount} email addresses in the CSV do not match
              volunteers for this election. Match email addresses to volunteers
              using the “match volunteer” buttons below.
            </div>
          )}

          <div className="flex flex-1 basis-0 flex-col gap-4 overflow-auto py-2 px-8">
            {[...bulkAssignmentsByEmail.entries()].map(
              ([email, assignments]) => {
                const matchedUserId = userIdsByCsvEmail.get(email);
                const matchedUser =
                  matchedUserId === undefined
                    ? null
                    : assignmentState.usersById.get(matchedUserId)!;

                return (
                  <div
                    key={email}
                    className="flex flex-col gap-1 border-b border-b-gray-300 py-2"
                  >
                    {matchedUser ? (
                      <div>
                        <strong>{email}</strong> – {matchedUser.first_name}{' '}
                        {matchedUser.last_name}
                        {matchedUser.email.toLowerCase() !== email && (
                          <>
                            {' '}
                            <TextButton
                              onPress={() =>
                                setUserIdsByCsvEmail((prev) => {
                                  const next = new Map(prev);
                                  next.delete(email);
                                  return next;
                                })
                              }
                            >
                              unmatch volunteer
                            </TextButton>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <strong className="text-error">{email}</strong> —{' '}
                        <TextButton onPress={() => setEditMatchEmail(email)}>
                          match volunteer
                        </TextButton>
                      </div>
                    )}

                    {assignments.map((a, idx) => {
                      const location =
                        assignmentState.locationsById.get(a.locationId) ?? null;

                      return (
                        <div key={idx} className="flex gap-2 align-top">
                          <div className="font-bold">
                            {moment(a.date).format('DD MMM')}
                          </div>
                          <div>({a.time})</div>
                          <div>
                            {location ? (
                              `${location.name}, ${location.city}`
                            ) : (
                              <i className="text-error">
                                unknown location ID: {a.locationId}
                              </i>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
          </div>
        </>
      )}

      {editMatchEmail &&
        (() => {
          const sampleAssignment =
            bulkAssignmentsByEmail.get(editMatchEmail)![0]!;

          return (
            <ModalDialog
              title={`Match ${editMatchEmail}`}
              doClose={() => setEditMatchEmail(null)}
              aboveCenter
              showClose
            >
              <div className="flex h-[60vh] w-[700px] flex-col">
                <div className="flex-1 basis-0 overflow-auto">
                  <table className="table-fixed">
                    <tbody>
                      {Object.entries(sampleAssignment.otherFields).map(
                        ([key, value]) => (
                          <tr key={key}>
                            <th>{key}</th>
                            <td>{value}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="flex-1 basis-0 overflow-y-scroll">
                  <ListBox
                    items={assignmentState.usersById.values()}
                    selectionMode="single"
                    selectionBehavior="replace"
                    selectedKeys={
                      editMatchSelectedUserId ? [editMatchSelectedUserId] : []
                    }
                    onSelectionChange={(keys) => {
                      if (typeof keys !== 'string') {
                        if (keys.size === 0) {
                          setEditMatchSelectedUserId(null);
                        } else {
                          setEditMatchSelectedUserId(
                            keys.values().next().value
                          );
                        }
                      }
                    }}
                  >
                    {(user) => (
                      <Item>
                        {user.first_name} {user.last_name} ({user.email})
                      </Item>
                    )}
                  </ListBox>
                </div>

                <div className="flex justify-end gap-2">
                  <ActionButton
                    role="secondary"
                    onPress={() => setEditMatchEmail(null)}
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton
                    role="primary"
                    isDisabled={editMatchSelectedUserId === null}
                    onPress={() => {
                      setUserIdsByCsvEmail((prev) => {
                        const next = new Map(prev);
                        next.set(editMatchEmail, editMatchSelectedUserId!);
                        return next;
                      });

                      setEditMatchEmail(null);
                    }}
                  >
                    Match Volunteer
                  </ActionButton>
                </div>
              </div>
            </ModalDialog>
          );
        })()}
    </div>
  );
};

/**
 * react-router loader for {@link BulkUploadAssignmentsPage}.
 */
export async function loadBulkUploadAssignmentsPage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof BulkUploadAssignmentsPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: ['vpd', 'deputy_vpd'],
  });

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  return { currentElection };
}

export default BulkUploadAssignmentsPage;
