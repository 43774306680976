import * as Immutable from 'immutable';

/**
 * Given an {@link Immutable.Map} of filters, returns a map that’s just the
 * filters specified by the `keys` param that also have truthy values.
 *
 * Note that because of the way that `filters` state is currently typed to
 * include every category’s filter keys for each category, callers should wrap
 * this function in a helper that performs stricter type checking; passing
 * `state.filters.get(CATEGORY)` as the first param will cause an
 * overly-permissive `InK` type.
 *
 * Return value has a value type of `undefined` because not every element of
 * `OutK` is guaranteed to be present in the map. (But any of those that pass a
 * `has` check will be truthy.)
 */
export function filterFilters<InK extends string, OutK extends InK>(
  filters: Immutable.Map<InK, string | null | undefined>,
  keys: OutK[]
): Immutable.Map<OutK, string | undefined> {
  return Immutable.Map<OutK, string | undefined>(
    keys
      .map((key) => [key, filters.get(key)])
      .filter((entry): entry is [OutK, string] => !!entry[1])
  );
}

/**
 * Returns a union type of every key in T that will cannot have a null,
 * undefined, or empty string value associated with it.
 *
 * This preserves keys that point to numbers and sets of string literals that
 * don’t include ''.
 *
 * We want this so that we can have {@link pruneFilters} guarantee that some of
 * the fields in its return value will exist. I.e. if they can’t be pruned, they
 * shouldn’t become optional.
 *
 * This works by mapping T to become an object of {key -> key | never}, then
 * using `[keyof T]` to extract those non-never values (which are the definite
 * keys).
 */
type DefiniteKeysOf<T> = {
  [K in keyof T]: null extends T[K]
    ? never
    : undefined extends T[K]
    ? never
    : // If it’s just a string then it could be '' which means it could be
    // pruned.
    string extends T[K]
    ? never
    : // in case '' is part of a literal type union
    '' extends T[K]
    ? never
    : K;
}[keyof T];

/**
 * Type for an object after it passes through {@link pruneFilters}.
 *
 * Any key that will definitely survive pruning (because it can’t be null,
 * undefined, or '') is still in the object.
 *
 * Any key that could be pruned is made optional, but if it is in the object its
 * value will definitely not be null or undefined.
 */
export type Pruned<
  T extends { [key: string]: string | number | null | undefined }
> = {
  [k in DefiniteKeysOf<T>]: T[k];
} & {
  [k in Exclude<keyof T, DefiniteKeysOf<T>>]?: NonNullable<T[k]>;
};

/**
 * Given an object of filters, removes any that are an empty string, null, or
 * undefined. (Leaves 0s, though.)
 */
export function pruneFilters<
  T extends { [key: string]: string | number | null | undefined }
>(filters: T): Pruned<T> {
  // We have to use `any` here because of how we’re building this return value.
  const out: any = {};

  for (const key in filters) {
    if (filters[key] || filters[key] === 0) {
      out[key] = filters[key];
    }
  }

  return out;
}
