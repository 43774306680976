/**
 * Helper to convert an `ordering` column into its slug, whether or not its
 * ascending / descending, and a toggle function.
 *
 * We’re playing a little cute with the types here; T should encompass all of
 * the columns, but the `ordering` field can be either "T" or "-T".
 */
export function orderingToSortProps<T extends string>(
  filters: { ordering: T | `-${T}` },
  updateFilters: (updates: { ordering: T | `-${T}` }) => void
): {
  sortSlug: T;
  sortDescending: boolean;
  toggleSort: (slug: T) => void;
} {
  let sortSlug = filters.ordering;
  let sortDescending = false;

  if (sortSlug.startsWith('-')) {
    sortSlug = sortSlug.substring(1) as T;
    sortDescending = true;
  }

  const toggleSort = (newSortSlug: T) => {
    if (newSortSlug === sortSlug) {
      if (sortDescending) {
        updateFilters({ ordering: newSortSlug });
      } else {
        updateFilters({ ordering: `-${newSortSlug}` });
      }
    } else {
      updateFilters({ ordering: newSortSlug });
    }
  };

  return { sortSlug: sortSlug as T, sortDescending, toggleSort };
}
