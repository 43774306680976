import cx from 'classnames';
import { FormApi, FormState, FormSubscription } from 'final-form';
import { sortBy } from 'lodash';
import React from 'react';
import { Form, Field, FieldRenderProps, FormSpy } from 'react-final-form';
import { Item } from 'react-stately';

import { CountySelect } from '../../components/common/CountySelect';
import { StateSelect } from '../../components/common/StateSelect';

import {
  Checkbox,
  Radio,
  RadioGroup,
  Select,
  TagComboBox,
  TextField,
} from '../../components/form';
import type { State, UserRole } from '../../constants';
import { CountySlug } from '../../services/common';

import { ApiUserTag } from '../../services/lbj-shared-service';
import { ApiUser, ExpandApiUserPii } from '../../services/user-service';

import {
  requiredValidation,
  chainValidations,
  adaptCheckboxRenderProps,
  adaptFieldMetaErrors,
  adaptTextFieldRenderProps,
} from '../../utils/form';
import { useContinuity } from '../../utils/hooks';
import { isValidEmail } from '../../utils/user/emails';
import { formatPhoneNumber } from '../../utils/user/phone-numbers';

import { parseTagArray, serializeTags, ParsedUserTags } from './user-tags';

/**
 * Fields that this form can update. This will potentially contain more data
 * than is actually editable on the form, but it’s safe to parrot that back to
 * the backend. The `UserChangelog` model in Django looks to see if the values
 * of the data actually changed.
 *
 * The exception to this is `role`, since including a value for `role` in the
 * PUT, even to the same as it is currently, is an error if the user doesn’t
 * have permissions. So it’s only included if `mayEditRowFields` is `true` in
 * the component props.
 */
export type UserUpdates = Pick<
  ApiUser & ExpandApiUserPii,
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone_number'
  | 'confirmed_email'
  | 'address'
  | 'city'
  | 'state'
  | 'zipcode'
  | 'county'
  | 'tags'
> &
  Partial<Pick<ApiUser, 'role'>>;

/**
 * Pulled out into a const so we can consistently map `ApiUser` properties to
 * human-friendly labels.
 *
 * Note that this doesn’t have a breakdown for tag categories because they’re
 * not distinguished that way on the server.
 */
export const USER_FIELD_LABELS: { [key in keyof UserUpdates]: string } = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  phone_number: 'Cell Phone',
  confirmed_email: 'Email address is confirmed',

  address: 'Street Address',
  city: 'City',
  state: 'State',
  zipcode: 'ZIP Code',
  county: 'County',

  role: 'Account Type',
  tags: 'Tags',
};

/**
 * Internal storage of form values.
 *
 * Includes our parsed out version of tags and requires everything, including role.
 */
export type UserFormValues = Omit<Required<UserUpdates>, 'tags'> & {
  tags: ParsedUserTags;
};

const ROLE_CHOICES: { [role in UserRole]: string } = {
  none: 'No Role',
  view_only: 'View Only',
  poll_observer: 'Poll Observer',
  hotline_worker: 'Hotline Worker',
  hotline_manager: 'Hotline Manager',
  boiler_room_user: 'Boiler Room User',
  boiler_room_leader: 'Boiler Room Leader',
  deputy_vpd: 'Deputy Voter Protection Director',
  vpd: 'Voter Protection Director',
};

/**
 * Common form to edit user details.
 *
 * Used by the profile page and survey page.
 */
const UserForm: React.FunctionComponent<{
  id?: string | undefined;

  /**
   * If true, uses 2 columns on wide screens.
   */
  wide?: boolean | undefined;

  /**
   * Initial user data. We only care about the data we’re updating, so callers
   * don’t have to provide everything.
   *
   * NOTE: You need to maintain this as the same object, otherwise the form will
   * reset.
   */
  user: Required<UserUpdates>;
  tagData: ApiUserTag[];

  /** True if the user is modifying themself. Affects labels. */
  isEditingSelf?: boolean | undefined;

  showRoleFields?: boolean;
  mayEditRoleFields?: boolean;

  showAddressFields?: boolean;
  requireAddressFields?: boolean;

  showExperienceFields?: boolean;
  requireExperienceFields?: boolean;

  showInPersonVolunteeringFields?: boolean;
  requireInPersonVolunteeringFields?: boolean;

  showProgramFields?: boolean;

  onSubmit: (updates: UserUpdates) => void;

  onFormChange?: (state: FormState<UserFormValues, UserFormValues>) => void;
  /** If provided, limits `onFormChange` to get called only when the specified fields change. */
  formSubscription?: FormSubscription;
}> = ({
  id,
  user,
  tagData,
  wide,
  isEditingSelf = false,
  showRoleFields = false,
  mayEditRoleFields = false,
  showAddressFields = false,
  requireAddressFields = false,
  showExperienceFields = false,
  requireExperienceFields = false,
  showInPersonVolunteeringFields = false,
  requireInPersonVolunteeringFields = false,
  showProgramFields = false,
  onSubmit,
  onFormChange,
  formSubscription,
}) => {
  tagData = useContinuity(tagData);

  /** Filtered / sorted tag information for language-related tags. */
  const languageTagData = sortBy(
    tagData.filter((tag) => tag.type === 'language'),
    'display_name'
  );

  /**
   * Filtered / sorted tag information for “availability” tags, which are
   * volunteer roles. (E.g. “EDay Poll Observer”)
   */
  const availabilityTagData = sortBy(
    tagData.filter((tag) => tag.type === 'availability'),
    'display_name'
  );

  /** Filtered / sorted tag information for distance-related tags. */
  const distanceTagData = sortBy(
    tagData.filter((tag) => tag.type === 'travel_distance'),
    (t) => {
      // Sorts by the numeric miles, smallest to largest
      const displayName: string = t.display_name;
      const milesMatch = displayName.match(/[\d]+/);

      // Dummy distances for anywhere and interstate to get the sort right.
      if (displayName.includes('anywhere')) {
        return 1000;
      } else if (displayName.includes('interstate')) {
        return 900;
      } else if (milesMatch) {
        return parseInt(milesMatch[0]);
      } else {
        return 1100;
      }
    }
  );

  /**
   * The most recent {@link FormApi} for our form, extracted from its render
   * callback.
   */
  const formApiRef = React.useRef<FormApi<any> | null>(null);

  const initialValues = React.useMemo(
    () => ({
      first_name: user['first_name'],
      last_name: user['last_name'],
      email: user['email'],
      phone_number: user['phone_number'],
      confirmed_email: user['confirmed_email'],
      address: user['address'],
      city: user['city'],
      state: user['state'],
      zipcode: user['zipcode'],
      county: user['county'],
      role: user['role'],
      tags: parseTagArray(user['tags'], tagData),
    }),
    // We don’t expect the _content_ of tagData to change, but we may see it go
    // from an empty array to a filled one, in which case we want to reset the
    // form. (This will happen in the seconds of the page loading.)
    [user, tagData]
  );

  // We manually call restart when initialValues changes to clear out the
  // `touched` bits on all of the form fields. Otherwise, all the required
  // fields show errors when the form is reset.
  React.useEffect(() => {
    formApiRef.current?.restart(initialValues);
  }, [initialValues]);

  return (
    <Form<UserFormValues, UserFormValues>
      onSubmit={(values) => {
        const updates: UserUpdates = {
          ...values,
          // We explicitly convert empty string county to null for the backend.
          county: values.county || null,
          tags: serializeTags(values['tags']),
        };

        if (!mayEditRoleFields || isEditingSelf) {
          // Backend will raise an exception if we try to set role but aren’t
          // allowed, or are setting it on ourselves.
          delete updates.role;
        }

        return onSubmit(updates);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, form: formApi }) => (
        <form
          id={id}
          onSubmit={handleSubmit}
          className={cx('flex flex-col items-stretch gap-10', {
            '2xl:grid 2xl:grid-cols-2 2xl:gap-6 2xl:self-stretch': wide,
          })}
        >
          <SaveFormApi formApi={formApi} formApiRef={formApiRef} />

          {onFormChange && (
            <FormSpy<UserFormValues, UserFormValues>
              onChange={(changes) =>
                // Have to do this in the next event tick because `onChange` is
                // called during the render, when one should not be setting
                // state.
                window.setImmediate(() => onFormChange(changes))
              }
              {...(formSubscription ? { subscription: formSubscription } : {})}
            />
          )}

          <UserFormSection legend="Contact Info">
            <Field<UserFormValues['first_name']>
              name="first_name"
              validate={requiredValidation('First name')}
            >
              {(fieldProps) => (
                <TextField
                  label={USER_FIELD_LABELS['first_name']}
                  isRequired
                  maxLength={1000}
                  {...adaptTextFieldRenderProps(fieldProps)}
                />
              )}
            </Field>

            <Field<UserFormValues['last_name']>
              name="last_name"
              validate={requiredValidation('Last name')}
            >
              {(fieldProps) => (
                <TextField
                  label={USER_FIELD_LABELS['last_name']}
                  isRequired
                  maxLength={1000}
                  {...adaptTextFieldRenderProps(fieldProps)}
                />
              )}
            </Field>

            <Field<UserFormValues['email']>
              name="email"
              validate={chainValidations(
                requiredValidation('Email address'),
                (val: string) => {
                  if (!isValidEmail(val)) {
                    return 'Please provide a valid email address.';
                  }
                }
              )}
            >
              {(fieldProps) => (
                <TextField
                  label={USER_FIELD_LABELS['email']}
                  isRequired
                  type="email"
                  {...adaptTextFieldRenderProps(fieldProps)}
                />
              )}
            </Field>

            <Field<UserFormValues['phone_number']>
              name="phone_number"
              validate={chainValidations(
                requiredValidation('Cell phone number'),
                (val: string | null) => {
                  if (!formatPhoneNumber(val)) {
                    return 'Please provide a 10-digit phone number.';
                  }
                }
              )}
            >
              {(fieldProps) => (
                <TextField
                  label={USER_FIELD_LABELS['phone_number']}
                  isRequired
                  maxLength={1000}
                  type="tel"
                  {...adaptTextFieldRenderProps(fieldProps)}
                  // formatPhoneNumber returns null if the value of its arg doesn’t
                  // have exactly 10 digits, so in that case we display the
                  // un-formatted form value.
                  //
                  // The behavior is that this looks like a string of numbers until
                  // you hit 10, and then it pops to a nicely-formatted field.
                  value={
                    formatPhoneNumber(fieldProps.input.value) ??
                    fieldProps.input.value ??
                    ''
                  }
                />
              )}
            </Field>

            <div style={{ gridColumn: '1 / -1' }}>
              <Field<UserFormValues['confirmed_email']>
                name="confirmed_email"
                type="checkbox"
                validate={(value: boolean) => {
                  if (!value && isEditingSelf) {
                    return 'Please confirm your email address.';
                  }
                }}
              >
                {(fieldProps) => (
                  <Checkbox
                    isRequired={isEditingSelf}
                    {...adaptCheckboxRenderProps(fieldProps)}
                  >
                    {isEditingSelf
                      ? 'I will have access to this email address on election day'
                      : USER_FIELD_LABELS['confirmed_email']}
                  </Checkbox>
                )}
              </Field>
            </div>
          </UserFormSection>

          {showAddressFields && (
            <UserFormSection legend="Registration Address">
              {/* Need to use gridColumn explicitly because there’s some jank global CSS that affects `col-*` classes. */}
              <div style={{ gridColumn: '1 / -1' }}>
                <Field<UserFormValues['address']>
                  name="address"
                  validate={
                    requireAddressFields
                      ? requiredValidation('Street address')
                      : () => {}
                  }
                >
                  {(fieldProps) => (
                    <TextField
                      label={USER_FIELD_LABELS['address']}
                      isRequired={requireAddressFields}
                      maxLength={1000}
                      {...adaptTextFieldRenderProps(fieldProps)}
                    />
                  )}
                </Field>
              </div>

              <Field<UserFormValues['city']>
                name="city"
                validate={
                  requireAddressFields ? requiredValidation('City') : () => {}
                }
              >
                {(fieldProps) => (
                  <TextField
                    label={USER_FIELD_LABELS['city']}
                    isRequired={requireAddressFields}
                    maxLength={1000}
                    {...adaptTextFieldRenderProps(fieldProps)}
                  />
                )}
              </Field>

              <Field<UserFormValues['state']>
                name="state"
                validate={
                  requireAddressFields ? requiredValidation('State') : () => {}
                }
              >
                {({ input, meta }) => (
                  <StateSelect
                    label={USER_FIELD_LABELS['state']}
                    isRequired={requireAddressFields}
                    selectedKey={input.value as State | null}
                    onFocus={input.onFocus as any}
                    onBlur={input.onBlur as any}
                    onSelectionChange={input.onChange}
                    {...adaptFieldMetaErrors(meta)}
                  />
                )}
              </Field>

              <Field<UserFormValues['zipcode']>
                name="zipcode"
                validate={
                  requireAddressFields
                    ? requiredValidation('ZIP code')
                    : () => {}
                }
              >
                {(fieldProps) => (
                  <TextField
                    label={USER_FIELD_LABELS['zipcode']}
                    isRequired={requireAddressFields}
                    maxLength={5}
                    {...adaptTextFieldRenderProps(fieldProps)}
                  />
                )}
              </Field>

              <Field<UserFormValues['county']>
                name="county"
                validate={
                  requireAddressFields ? requiredValidation('County') : () => {}
                }
              >
                {({ input, meta }) => (
                  <CountySelect
                    label={USER_FIELD_LABELS['county']}
                    isRequired={requireAddressFields}
                    state={values['state'] as State | null}
                    selectedKey={input.value as CountySlug | null}
                    onSelectionChange={input.onChange}
                    onBlur={input.onBlur as any}
                    onFocus={input.onFocus as any}
                    {...adaptFieldMetaErrors(meta)}
                  />
                )}
              </Field>
            </UserFormSection>
          )}

          {showRoleFields && (
            <UserFormSection legend="Election Role">
              <Field<UserFormValues['role']> name="role">
                {({ input, meta }) => (
                  <Select
                    label={USER_FIELD_LABELS['role']}
                    isReadOnly={!mayEditRoleFields || isEditingSelf}
                    items={Object.entries(ROLE_CHOICES)}
                    // We don’t allow setting to VPD in the UI.
                    disabledKeys={['vpd']}
                    selectedKey={input.value}
                    onSelectionChange={input.onChange}
                    onFocus={input.onFocus as any}
                    onBlur={input.onBlur as any}
                    {...adaptFieldMetaErrors(meta)}
                  >
                    {([key, value]) => <Item key={key}>{value}</Item>}
                  </Select>
                )}
              </Field>

              <div style={{ gridColumn: '1 / -1' }}>
                <Field<
                  UserFormValues['tags']['volunteerTags']
                > name="tags.volunteerTags">
                  {({ input, meta }) => (
                    <TagComboBox
                      label="Volunteer Tags"
                      items={availabilityTagData}
                      selectedKeys={input.value}
                      onSelectionChange={(tags) => input.onChange([...tags])}
                      isReadOnly={!mayEditRoleFields}
                      {...adaptFieldMetaErrors(meta)}
                    >
                      {({ name, display_name }) => (
                        <Item key={name}>{display_name}</Item>
                      )}
                    </TagComboBox>
                  )}
                </Field>
              </div>
            </UserFormSection>
          )}

          {showExperienceFields && (
            <UserFormSection legend="Experience" wideGap>
              <Field<
                UserFormValues['tags']['experienced']
              > name="tags.experienced">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label={
                      isEditingSelf
                        ? 'Have you been a poll observer before?'
                        : 'Has poll observer experience?'
                    }
                    isRequired={requireExperienceFields}
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              <Field<
                UserFormValues['tags']['legal_community']
              > name="tags.legal_community">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label={
                      isEditingSelf
                        ? 'Are you a lawyer?'
                        : 'Is a member of the legal community?'
                    }
                    isRequired={requireExperienceFields}
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              {/* Need to use gridColumn explicitly because there’s some jank global CSS that affects `col-*` classes. */}
              <div style={{ gridColumn: '1 / -1' }}>
                <Field<
                  UserFormValues['tags']['languageTags']
                > name="tags.languageTags">
                  {({ input, meta }) => (
                    <TagComboBox
                      label={
                        isEditingSelf
                          ? 'What languages do you speak? (besides English)'
                          : 'Languages spoken (besides English)'
                      }
                      selectedKeys={input.value}
                      onSelectionChange={(languageTags) => {
                        if (typeof languageTags !== 'string') {
                          input.onChange([...languageTags]);
                        }
                      }}
                      items={languageTagData}
                      {...adaptFieldMetaErrors(meta)}
                    >
                      {(tagDatum) => (
                        <Item key={tagDatum.name}>
                          {/* We remove the “Speaks” at the beginning of the tag name to make the list nicer. */}
                          {tagDatum.display_name.replace(/Speaks /, '')}
                        </Item>
                      )}
                    </TagComboBox>
                  )}
                </Field>
              </div>
            </UserFormSection>
          )}

          {showInPersonVolunteeringFields && (
            <UserFormSection legend="Availability" wideGap>
              <Field<UserFormValues['tags']['distanceTag']>
                name="tags.distanceTag"
                validate={
                  requireInPersonVolunteeringFields
                    ? requiredValidation('Travel distance')
                    : () => {}
                }
              >
                {({ input, meta }) => (
                  <Select
                    label={
                      isEditingSelf
                        ? 'How far will you go to a shift?'
                        : 'Travel limits'
                    }
                    isRequired={requireInPersonVolunteeringFields}
                    items={[
                      ...(!requireInPersonVolunteeringFields
                        ? [
                            // If it’s not required, we add an option to remove
                            // the tag.
                            {
                              name: '',
                              display_name: '-',
                              category: 'travel_distance',
                            },
                          ]
                        : []),
                      ...distanceTagData,
                    ]}
                    selectedKey={input.value}
                    onSelectionChange={input.onChange}
                    onBlur={input.onBlur as any}
                    onFocus={input.onFocus as any}
                    {...adaptFieldMetaErrors(meta)}
                  >
                    {(tagDatum) => (
                      <Item key={tagDatum.name}>{tagDatum.display_name}</Item>
                    )}
                  </Select>
                )}
              </Field>

              <Field<
                UserFormValues['tags']['car_access']
              > name="tags.car_access">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label={
                      isEditingSelf
                        ? 'Will you have access to a car?'
                        : 'Has access to a car?'
                    }
                    isRequired={requireInPersonVolunteeringFields}
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              {
                // This is calculated, rather than passed in, so that it will
                // dynamically show if the `election_day_poll_observer_volunteer`
                // tag is added in the form.
                values.tags.volunteerTags.includes(
                  'election_day_poll_observer_volunteer'
                ) && (
                  <Field<UserFormValues['tags']['edayAvailabilityTag']>
                    name="tags.edayAvailabilityTag"
                    validate={
                      requireInPersonVolunteeringFields
                        ? requiredValidation('Election day availability')
                        : () => {}
                    }
                  >
                    {({ input, meta }) => (
                      <RadioGroup
                        label={
                          isEditingSelf
                            ? 'When are you available on election day?'
                            : 'Election day availability'
                        }
                        isRequired={requireInPersonVolunteeringFields}
                        value={input.value ?? ''}
                        onChange={input.onChange}
                        {...adaptFieldMetaErrors(meta)}
                      >
                        <Radio value="eday_available_am">Morning</Radio>
                        <Radio value="eday_available_pm">Afternoon</Radio>
                        <Radio value="eday_available_all_day">All Day</Radio>
                      </RadioGroup>
                    )}
                  </Field>
                )
              }
            </UserFormSection>
          )}

          {showProgramFields && (
            <UserFormSection legend="For Program Use" wideGap>
              <Field<UserFormValues['tags']['confirmed']> name="tags.confirmed">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label="Election day confirmed"
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              <Field<UserFormValues['tags']['maybe']> name="tags.maybe">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label="Responded with maybe"
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              <Field<UserFormValues['tags']['rover']> name="tags.rover">
                {(fieldProps) => (
                  <YesNoRadioGroup label="Rover" fieldProps={fieldProps} />
                )}
              </Field>

              <Field<
                UserFormValues['tags']['county_lead']
              > name="tags.county_lead">
                {(fieldProps) => (
                  <YesNoRadioGroup
                    label="County lead"
                    fieldProps={fieldProps}
                  />
                )}
              </Field>

              <Field<
                UserFormValues['tags']['trainingTag']
              > name="tags.trainingTag">
                {({ input, meta }) => (
                  <Select
                    label="Training"
                    selectedKey={input.value ?? ''}
                    onSelectionChange={input.onChange}
                    onBlur={input.onBlur as any}
                    onFocus={input.onFocus as any}
                    {...adaptFieldMetaErrors(meta)}
                  >
                    <Item key="">-</Item>
                    <Item key="training_scheduled">
                      {tagData.find((d) => d.name === 'training_scheduled')
                        ?.display_name ?? 'training_scheduled'}
                    </Item>
                    <Item key="training_complete">
                      {tagData.find((d) => d.name === 'training_complete')
                        ?.display_name ?? 'training_complete'}
                    </Item>
                  </Select>
                )}
              </Field>
            </UserFormSection>
          )}
        </form>
      )}
    </Form>
  );
};

export default UserForm;

/**
 * Labeled `<fieldset>` for our user form.
 */
const UserFormSection: React.FunctionComponent<{
  legend: React.ReactNode;
  /**
   * If true, uses a larger gap, which looks better when the input controls are
   * more text than boxes.
   */
  wideGap?: boolean | undefined;
}> = ({ legend, wideGap, children }) => (
  <fieldset className="rounded border-0 border-gray-200 p-4 md:border-2 md:p-6">
    <legend className="mx-[-0.25rem] px-1 text-lg font-bold text-gray-700">
      {legend}
    </legend>

    <div
      className={cx('grid grid-cols-1 gap-4 lg:grid-cols-2', {
        'gap-4': !wideGap,
        'gap-8': wideGap,
      })}
    >
      {children}
    </div>
  </fieldset>
);

/**
 * Common component for a yes/no set of radio buttons that operate on a boolean
 * form value.
 */
const YesNoRadioGroup: React.FunctionComponent<{
  label: string;
  fieldProps: FieldRenderProps<boolean>;
  isRequired?: boolean;
}> = ({ label, isRequired, fieldProps: { input } }) => {
  return (
    <RadioGroup
      name={input.name}
      label={label}
      isRequired={!!isRequired}
      value={input.value.toString()}
      onChange={(val) => input.onChange(val === 'true')}
    >
      <Radio value="true">Yes</Radio>
      <Radio value="false">No</Radio>
    </RadioGroup>
  );
};

/**
 * Pedantic little component to update our formApiRef from inside the
 * {@link Form} render callback, but using `useLayoutEffect` so that the render
 * function itself is side effect–free.
 */
const SaveFormApi: React.FunctionComponent<{
  formApi: FormApi<any>;
  formApiRef: React.MutableRefObject<FormApi<any> | null>;
}> = ({ formApi, formApiRef }) => {
  React.useLayoutEffect(() => {
    formApiRef.current = formApi;

    return () => {
      formApiRef.current = null;
    };
  });

  return null;
};
