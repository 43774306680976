import React from 'react';

import { CountySlug } from '../../../services/common';
import { ApiCounty } from '../../../services/lbj-shared-service';

import Autocomplete from './autocomplete';

export default class CountySelect extends React.Component<
  {
    name: string;
    title: string;
    counties: { [slug: CountySlug]: string };
  } & Omit<React.ComponentProps<typeof Autocomplete<CountySlug>>, 'choices'>
> {
  static defaultProps = {
    name: 'county',
    title: 'County',
  };

  render() {
    const { name, counties, ...rest } = this.props;

    return <Autocomplete name={name} choices={counties} {...rest} />;
  }
}

/**
 * Creates a choices map out of a list of counties that’s suitable to pass to
 * `<Select>`.
 */
export function countiesToChoices(counties: ApiCounty[] | null | undefined): {
  [slug: CountySlug]: string;
} {
  if (!counties) {
    return {};
  }

  return counties.reduce<{ [slug: CountySlug]: string }>(
    (acc, county) => ({
      ...acc,
      [county.slug]: county.name,
    }),
    {}
  );
}
