import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import {
  LoaderFunctionArgs,
  Location,
  Navigate,
  redirect,
  useLocation,
  useRouteError,
} from 'react-router-dom';
import { Dispatch } from 'redux';

import ClearCache from '../components/containers/lbj/clear-cache';
import EulaModal from '../components/containers/user/eula-modal';
import Nav from '../components/presentational/lbj/nav';

import { AppState, AppStore, toAppDispatch } from '../modules/flux-store';
import {
  getUnseenNotificationCounts,
  getIssueNotificationsAsync,
  getUnackedLocationChangeCount,
  markIssuesAsSeen,
} from '../modules/notifications/action-creators';
import { CurrentUser } from '../modules/user/action-creators';
import { ImmutableCurrentUserElection } from '../modules/user/reducers';
import {
  findHome,
  loadCurrentUser,
  UserNotAvailableError,
  UserNotReadyError,
} from '../route-handlers/app';
import {
  ApiElection,
  ApiElectionAssignmentPreferences,
  getUserTags,
} from '../services/lbj-shared-service';

import * as LoginService from '../services/login-service';
import { ApiCurrentUser } from '../services/user-service';

import mapStateToAppNotifications from '../utils/notifications/map-state-to-app-notifications';
import mapStateToNotificationsFilters from '../utils/notifications/map-state-to-notifications-filters';
import RIT from '../utils/render-if-truthy';
import { queryToSearch } from '../utils/routing-provider';
import { assertUnreachable, MapFromJs } from '../utils/types';
import mapStateToLbjPermissions from '../utils/user/map-state-to-lbj-permissions';

import AccountModal from './auth/AccountModal';
import { parseTagArray } from './profile/user-tags';

/**
 * Component to render the nav bar and layout of the app when the user has
 * logged in.
 */
class LoggedInApp extends React.Component<{
  dispatch?: Dispatch<AppState>;
  /**
   * A loaded current user who has elections and has signed the EULA.
   */
  currentUser: MapFromJs<CurrentUser>;
  currentUserElection: ImmutableCurrentUserElection;

  unseenNotificationCount: number;
  locationChangeCount: number;
  notificationFilters: ReturnType<typeof mapStateToNotificationsFilters>;
  shouldStopUpdatePolling: boolean;
  newVersionIsAvailable: boolean;
  userShouldSeeLocationNotifications: boolean;
  pollingInterval: number;
  withCaptiveNav?: boolean;
}> {
  componentDidMount() {
    const { pollingInterval } = this.props;

    this.startPolling(pollingInterval);
  }

  componentWillReceiveProps(nextProps: LoggedInApp['props']) {
    if (nextProps.pollingInterval !== this.props.pollingInterval) {
      this.stopPolling();
      this.startPolling(nextProps.pollingInterval);
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  /**
   * Value from `window.setInterval` for our notification polling.
   */
  pollingInterval: number | undefined;

  checkForUpdates() {
    const { shouldStopUpdatePolling } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);

    if (shouldStopUpdatePolling) {
      window.clearInterval(this.pollingInterval);
    } else {
      dispatch(getUnseenNotificationCounts());
    }
  }

  handleNotificationPopoverOpen() {
    const dispatch = toAppDispatch(this.props.dispatch);

    dispatch(getUnackedLocationChangeCount());
    dispatch(getIssueNotificationsAsync());
  }

  handleNotificationPopoverClose() {
    const { notificationFilters, userShouldSeeLocationNotifications } =
      this.props;
    const dispatch = toAppDispatch(this.props.dispatch);
    const now = new Date();
    const filterPayload = Object.assign(
      {
        created_date__lte: now.toISOString().slice(0, -1),
      },
      notificationFilters.toJS()
    );
    const queryParams: Parameters<typeof markIssuesAsSeen>[1] = {};

    if (userShouldSeeLocationNotifications) {
      queryParams.bulk_update_location_changes = true;
    }

    dispatch(markIssuesAsSeen(filterPayload, queryParams)).then(() => {
      dispatch(getUnseenNotificationCounts());
    });
  }

  startPolling(pollingInterval: number) {
    this.pollingInterval = window.setInterval(
      this.checkForUpdates.bind(this),
      pollingInterval
    );

    // TODO(fiona): checkForUpdates won’t do anything if previous updates have
    // failed. Do we want to force a retry here in the way that `requireLogin`
    // did in the past?
    //
    // Address this when converting `<LoggedInApp>` to a functional component.
    this.checkForUpdates();
  }

  stopPolling() {
    window.clearInterval(this.pollingInterval);
  }

  renderNewVersionAlert() {
    return (
      <a
        className="lbj-new-version-alert"
        onClick={() => window.location.reload()}
      >
        <div className="lbj-new-version-alert-content">
          <div className="lbj-new-version-alert-icon"> </div>A new version of
          LBJ is available! Click here to update.
        </div>
      </a>
    );
  }

  render() {
    const {
      dispatch,
      currentUserElection,
      currentUser,
      children,
      unseenNotificationCount,
      locationChangeCount,
      withCaptiveNav,
      newVersionIsAvailable,
      userShouldSeeLocationNotifications,
    } = this.props;
    const appClassName = cx('lbj-app', {
      'is-showing-alert': newVersionIsAvailable,
    });

    return (
      <div className={appClassName}>
        <ClearCache
          electionId={currentUserElection.getIn(['election', 'id']) as number}
          userElectionId={currentUserElection.get('id')}
        />

        <Nav
          dispatch={toAppDispatch(dispatch)}
          currentUserElection={currentUserElection}
          currentUser={currentUser}
          onLogoutClick={LoginService.logout}
          onNotificationPopoverClose={this.handleNotificationPopoverClose.bind(
            this
          )}
          onNotificationPopoverOpen={this.handleNotificationPopoverOpen.bind(
            this
          )}
          unseenNotificationCount={unseenNotificationCount}
          locationChangeCount={locationChangeCount}
          // TODO(fiona): Bring back checkin alerts to remind users to check in!
          checkinAlert={false}
          withCaptiveNav={withCaptiveNav}
          userShouldSeeLocationNotifications={
            userShouldSeeLocationNotifications
          }
        />

        {children}

        {RIT(newVersionIsAvailable, this.renderNewVersionAlert)}
      </div>
    );
  }
}

type LoggedInAppLoadedProps = Pick<
  LoggedInApp['props'],
  'currentUser' | 'currentUserElection' | 'withCaptiveNav'
>;

const ConnectedLoggedInApp = connect(
  (state: AppState, _ownProps: LoggedInAppLoadedProps) => {
    const { userShouldSeeLocationNotifications } =
      mapStateToLbjPermissions(state);

    return {
      ...mapStateToAppNotifications(state),
      userShouldSeeLocationNotifications,
    };
  }
)(LoggedInApp);

export default ConnectedLoggedInApp;

/**
 * Loader for {@link LoggedInApp}, which requires a “ready” user (logged in,
 * accepted EULA, _&c._).
 */
export async function loadForLoggedInApp(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<LoggedInAppLoadedProps> {
  const { currentUser, currentUserElection } = await loadCurrentUser(fluxStore);
  const tagData = (await getUserTags()).tags;

  const { pathname } = new URL(request.url);
  const isOnSurvey = pathname === '/survey';

  // All unconfirmed email users get the survey
  let needsSurvey = !currentUser.get('confirmed_email');

  const currentElection: MapFromJs<ApiElection> =
    currentUserElection.get('election');

  const currentElectionAssignmentPreferences: ApiElectionAssignmentPreferences =
    currentElection.get('assignment_preferences').toJS();

  if (
    currentElectionAssignmentPreferences.force_poll_observer_survey &&
    currentUser.get('role') === 'poll_observer'
  ) {
    // OK! The user is a poll observer and the VPD has set things to require
    // poll observers to answer some questions. Let’s see if they already have
    // everything.

    const REQUIRED_STRING_FIELDS: Array<keyof ApiCurrentUser> = [
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'address',
      'city',
      'state',
      'zipcode',
      'county',
    ];

    const parsedTags = parseTagArray(
      (currentUser.get('tags') as Immutable.List<string>).toArray(),
      tagData
    );

    const hasMissingStringField = !!REQUIRED_STRING_FIELDS.find(
      (field) => !currentUser.get(field)
    );

    const hasMissingTags =
      parsedTags.distanceTag === null ||
      (parsedTags.volunteerTags.includes(
        'election_day_poll_observer_volunteer'
      ) &&
        parsedTags.edayAvailabilityTag === null);

    if (hasMissingStringField || hasMissingTags) {
      needsSurvey = true;
    }
  }

  if (needsSurvey && !isOnSurvey) {
    throw redirect('/survey');
  }

  return {
    currentUser,
    currentUserElection,
    withCaptiveNav: isOnSurvey,
  };
}

/**
 * Error boundary designed to handle user loading–related errors thrown by
 * components and loaders. Also handles displaying the EULA and redirecting the
 * user to the /survey endpoint.
 *
 * Designed to wrap {@link LoggedInApp} and all other routes that require a
 * fully ready-to-go user account.
 *
 * We can get here if:
 *
 *  * There are no stored auth credentials and the user needs to log in.
 *  * There was an error making the API request to get the current user.
 *  * The user has loaded but has not accepted the EULA.
 *
 * @see loadCurrentUser
 */
export const UserAuthBoundary: React.FunctionComponent = () => {
  const location = useLocation();
  const error = useRouteError();

  if (error instanceof UserNotAvailableError) {
    return handleUserNotAvailableError(error, location);
  } else if (error instanceof UserNotReadyError) {
    return handleUserNotReadyError(error);
  } else {
    throw error;
  }
};

/**
 * Handles the two cases where we couldn’t get a current user at all: they don’t
 * have an auth token (in which case we redirect to the login screen) or there
 * was an HTTP error loading the current user.
 */
function handleUserNotAvailableError(
  error: UserNotAvailableError,
  location: Location
) {
  switch (error.reason) {
    case 'NOT_LOGGED_IN':
      // If they’re not logged in at all, we need to send them to the login form
      // to get themselves an email link.
      return (
        <Navigate
          to={{
            pathname: '/login',
            search: queryToSearch({
              // Pass our current URL in case we have ways in the future to get
              // back here.
              redirect: location.search
                ? `${location.pathname}?${location.search}`
                : location.pathname,
            }),
          }}
        />
      );

    case 'USER_LOADING_ERROR':
      // There’s an auth cookie, but for some reason we couldn’t get the user
      // (network or they’re no longer an active LBJ user).
      return (
        <AccountModal status="accountError" logOut={LoginService.logout} />
      );

    default:
      assertUnreachable(error.reason);
  }
}

/**
 * Handles the cases where the user has logged in but their account is in a
 * state where they shouldn’t have access to LBJ. (E.g. haven’t signed the
 * EULA.)
 */
function handleUserNotReadyError(error: UserNotReadyError) {
  switch (error.reason) {
    case 'NO_ELECTIONS':
      // Note: we handle this case mostly out of being pedantic in the frontend,
      // since loading the current user will fail on the backend with an error if
      // there are no active elections.
      return <AccountModal status="noElections" logOut={LoginService.logout} />;

    case 'MISSING_EULA':
      return <EulaModal />;

    case 'NOT_AUTHORIZED':
      return <Navigate to={findHome(error.currentUser)} />;

    default:
      assertUnreachable(error.reason);
  }
}
