import React from 'react';

import AuthUiModal from './AuthUiModal';

/**
 * Small modal that shows statuses related to when we have an auth token in the
 * browser for the user but haven’t loaded their `currentUser` account
 * information yet (or got an error while trying).
 */
const AccountModal: React.FunctionComponent<{
  status: 'loading' | 'accountError' | 'noElections';
  logOut: () => void;
}> = ({ status, logOut }) => {
  return (
    <AuthUiModal>
      {status === 'loading' && <div>Loading your LBJ account…</div>}

      {/* Because the backend currently errors when there are no elections,
          we don’t really differentiate here. */}
      {(status === 'accountError' || status === 'noElections') && (
        <div className="c-alert-error">
          <p>
            There was a problem logging in to LBJ with that account. It may not
            be not enabled for any current elections.
          </p>

          <p>
            If this seems incorrect,{' '}
            <a
              href="#"
              className="lbj-alert-link"
              onClick={(ev) => {
                ev.preventDefault();
                logOut();
              }}
            >
              try clicking here
            </a>{' '}
            to clear your cache and please log in again.
          </p>

          <p>
            If you still have trouble, contact your state’s Voter Protection
            Director or email{' '}
            <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>.
          </p>
        </div>
      )}
    </AuthUiModal>
  );
};

export default AccountModal;
