import keyMirror from 'key-mirror';

export default keyMirror({
  GET_ASSIGNMENT_LIST: null,
  CREATE_ASSIGNMENT: null,
  UPDATE_ASSIGNMENT: null,
  DELETE_ASSIGNMENT: null,
  OPEN_EDITOR_PANEL: null,
  SAVE_ASSIGNMENT_DATA: null,
  CLOSE_EDITOR_PANEL: null,
  REQUEST_ASSIGNMENT_CSV: null,
});
