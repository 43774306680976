import * as Immutable from 'immutable';

import {
  ApiAssignment,
  ApiBoardOfElectionsAssignmentWithRelatedFields,
  ApiBoilerRoomAssignmentWithRelatedFields,
  ApiPollAssignmentWithRelatedFields,
  ApiUserWithAssignments,
  WithApiAssignmentCheckinSet,
  WithApiAssignmentExtras,
} from '../../services/assignment-service';
import { MapFromJs } from '../types';

/**
 * The type you get when you collapse the {@link ApiAssignment} from a user’s
 * `related` array with all of the expanded objects that are siblings of that
 * assignment.
 *
 * This overwrites properties that, unexpanded, are the IDs of e.g. the boiler
 * room or location, with the full objects (or undefined if they’re not
 * present).
 *
 * The exception is `user`, which is always the user ID.
 */
export type UserMappedAssignment = Omit<
  ApiAssignment & WithApiAssignmentExtras & WithApiAssignmentCheckinSet,
  'precinct' | 'location' | 'boiler_room' | 'board_of_elections'
> & {
  precinct: ApiPollAssignmentWithRelatedFields['precinct'] | undefined;
  location: ApiPollAssignmentWithRelatedFields['location'] | undefined;
  boiler_room:
    | ApiBoilerRoomAssignmentWithRelatedFields['boiler_room']
    | undefined;
  board_of_elections:
    | ApiBoardOfElectionsAssignmentWithRelatedFields['board_of_elections']
    | undefined;
};

export default function mapUserToAssignments(
  user: MapFromJs<ApiUserWithAssignments>
): Immutable.List<MapFromJs<UserMappedAssignment>> {
  const related = user.get('related') as Immutable.List<
    MapFromJs<ApiUserWithAssignments['related'][number]>
  >;

  return related
    .filter((related) => related.has('assignment'))
    .map((related: Immutable.Map<any, any>) => {
      return related
        .get('assignment')
        .set('precinct', related.get('precinct'))
        .set('location', related.get('location'))
        .set('boiler_room', related.get('boiler_room'))
        .set('board_of_elections', related.get('board_of_elections'))
        .set('user', user.get('id'));
    });
}
