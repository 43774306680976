export default {
  poll_observer: 'Poll Observer',
  hotline_worker: 'Hotline Worker',
  hotline_manager: 'Hotline Manager',
  boiler_room_user: 'Boiler Room User',
  boiler_room_leader: 'Boiler Room Leader',
  deputy_vpd: 'Deputy VPD',
  vpd: 'Voter Protection Director',
  view_only: 'View Only',
  // Used in the backend, though typically we don’t see any users with "none" as
  // a role logged in or anything.
  none: 'None',
};

export const ROLE_CODES = {
  none: '-',
  view_only: 'VO',
  poll_observer: 'PO',
  hotline_worker: 'HW',
  hotline_manager: 'HM',
  boiler_room_user: 'BR',
  boiler_room_leader: 'BRL',
  deputy_vpd: 'DVPD',
  vpd: 'VPD',
};
