import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections, US_NATIONAL_STATE_CODE } from '../../../constants';
import inputProps from '../../../decorators/input-props';
import StateSelect from '../../presentational/form/state-select';

const {
  ISSUE,
  ASSIGNMENT,
  CHECKINS,
  DASHBOARD,
  ANALYTICS,
  PROFILE,
  USER,
  NOTIFICATIONS,
} = lbjAppSections;

@connect((state, props) => {
  const { appSection, name } = props;
  const { user } = state;
  const filterAttr = name || 'state';
  const shouldBeDisabled =
    user.getIn(['currentUser', 'userData', 'assignment_state']) !==
    US_NATIONAL_STATE_CODE;

  return {
    value:
      typeof props.value !== 'undefined'
        ? props.value
        : state.filters.getIn([appSection, filterAttr]),
    shouldBeDisabled,
  };
})
@inputProps() /* eslint-disable react/prop-types */
export default class StateAutocomplete extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    includeNational: PropTypes.bool.isRequired,
    value: PropTypes.string,
    appSection: PropTypes.oneOf([
      ISSUE,
      ASSIGNMENT,
      CHECKINS,
      DASHBOARD,
      ANALYTICS,
      PROFILE,
      USER,
      NOTIFICATIONS,
    ]),
    shouldBeDisabled: PropTypes.bool,
  };

  static defaultProps = {
    name: 'state',
    title: 'State',
  };

  handleChange(event) {
    const { onChange } = this.props;

    onChange(event);
  }

  render() {
    const { dispatch, appSection, onChange, shouldBeDisabled, ...rest } =
      this.props;
    const disabled = Object.prototype.hasOwnProperty.call(
      this.props,
      'disabled'
    )
      ? this.props.disabled
      : shouldBeDisabled;

    return (
      <StateSelect
        onChange={this.handleChange.bind(this)}
        disabled={disabled}
        {...rest}
      />
    );
  }
}
