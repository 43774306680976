import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class PrecinctCongressionalItem extends Component {
  static propTypes = {
    precinct: PropTypes.object.isRequired,
  };

  getDistrictText(districtList) {
    return districtList.map((d) => `#${d}`).join(', ') || 'N/A';
  }

  render() {
    const { precinct } = this.props;

    return (
      <li className="with-icon icon-info">
        <span>
          State Senate:{' '}
          {this.getDistrictText(precinct.get('state_senate_districts'))}
        </span>
        <br />
        <span>
          State House:{' '}
          {this.getDistrictText(precinct.get('state_house_districts'))}
        </span>
        <br />
        <span>
          Congressional:{' '}
          {this.getDistrictText(precinct.get('us_house_districts'))}
        </span>
      </li>
    );
  }
}
