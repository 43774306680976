import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ViewTypeControl extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;

    return <ul className="lbj-view-filters">{children}</ul>;
  }
}
