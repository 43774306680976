import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { dismissToast } from '../../../modules/user/action-creators';
import AssignmentToastContainer from '../../containers/assignment/assignment-toast-container';
import Modal from '../../presentational/lbj/modal';

@connect((state) => {
  const { user } = state;
  const electionName = user
    .get('currentUser')
    .get('currentUserElection')
    .get('election')
    .get('name');

  return { electionName: electionName };
})
export default class ExportModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isPending: PropTypes.bool,
    isVisible: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    electionName: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.isVisible !== this.props.isVisible) {
      this.reset();
    }

    const success = !props.isPending && this.props.isPending && !props.hasError;

    if (success) {
      this.reset();
    }
  }

  onClose() {
    const { onRequestClose } = this.props;
    this.reset();
    onRequestClose();
  }

  reset() {
    this.props.dispatch(dismissToast());
    this.setState({ election: null });
  }

  render() {
    const { isPending, isVisible, onAccept, type, electionName } = this.props;

    if (!isVisible) {
      return null;
    }

    const spinner = isPending ? (
      <div className="lbj-loading-overlay">
        <div className="lbj-loading-icon" />
      </div>
    ) : null;

    return (
      <Modal
        classes="ExportModal UserModal"
        dismissable
        headerText={`Export ${type}`}
        onClose={this.onClose}
      >
        <div className="exportContent userModalContent">
          <AssignmentToastContainer />
          <div>
            <div>
              By clicking “I agree,” and as a precondition of exporting
              information from LBJ (“Data”) related to the {electionName} (the
              “Election”), you agree to the following:
            </div>
            <div className="export-details">
              <ol>
                <li>
                  You may only use and export any Data as part of and in
                  furtherance of voter protection efforts as it pertains to
                  elections in your state (“Permitted Purpose”).
                </li>
                <li>
                  You will not use any of the Data for any commercial,
                  promotional, or non-electoral activities or for any purpose
                  other than the Permitted Purpose.{' '}
                </li>
                <li>
                  You will treat Data as confidential information and not share
                  any Data with third parties other than your volunteers, staff,
                  consultants, or vendors working as part of your state’s voter
                  protection program. You will also ensure that anyone with whom
                  you share Data complies with the terms of this Agreement.
                </li>
                <li>
                  Any used or exported Data must be deleted upon the termination
                  of your involvement with your state’s voter protection program
                  or by December 31, 2022, whichever is earlier.
                </li>
                <li>
                  Any information compiled or added to exported Data must be
                  inputted into LBJ prior to the termination of your involvement
                  with your state’s voter protection program or by December 31,
                  2022, whichever is earlier.
                </li>
                <li>
                  The Democratic National Committee reserves the right to revoke
                  your access and privileges should you violate any of the terms
                  of this Agreement or use the Data outside of the Permitted
                  Purpose.{' '}
                </li>
                <li>
                  This agreement is in addition to (and not in lieu of) the End
                  User License Agreement agreed to by you upon your first login
                  to LBJ.
                </li>
              </ol>
            </div>
          </div>
          <div className="buttonRow">
            <button
              type="submit"
              className="c-button-large c-button-secondary"
              onClick={onAccept}
            >
              I agree
            </button>
          </div>
          {spinner}
        </div>
      </Modal>
    );
  }
}
