import { Immutable } from 'immer';
import moment from 'moment';
import React from 'react';
import {
  Cell,
  Column,
  Row,
  TableHeaderProps,
  TableBodyProps,
} from 'react-stately';

import { ApiLocationHours } from '../../../services/assignment-service';
import { DateString, isDateString } from '../../../services/common';
import {
  ApiElectionDay,
  ApiLocationTierConfiguration,
} from '../../../services/lbj-shared-service';
import { ApiVolunteerAvailability } from '../../../services/volunteer-availability-service';
import { assertUnreachable } from '../../../utils/types';
import { AssignmentTableRow } from '../AssignmentTable';
import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentUser,
} from '../assignment-state';

import { DistanceMiLookupFunc, LocationTableRow } from '../assignment-utils';

import LocationDayCell from './LocationDayCell';
import LocationRowHeader from './LocationRowHeader';
import {
  AssignmentUserCounts,
  DateColumnHeader,
  DAY_COLUMN_MIN_WIDTH_PX,
  LocationsColumnHeader,
  PeopleColumnHeader,
} from './LocationTableHeader';
import PersonDayCell from './PersonDayCell';
import PersonRowHeader from './PersonRowHeader';

/**
 * Type that identifies a selected cell in our location table.
 */
export type SelectedLocationDay = {
  /** Can be null if we’re in single-day view but didn’t select a location. */
  locationId: number | null;
  date: DateString;
};

export type AssignmentTableColumn =
  | {
      type: 'location';
      key: 'location';
      locationCount: number;
    }
  | {
      type: 'people';
      key: 'people';
      peopleCount: number;
    }
  | {
      type: 'day';
      key: string;
      day: ApiElectionDay;
      userCounts: AssignmentUserCounts | null;
      suggestionCount: number;
    };

export type PeopleTableRow = AssignmentTableRow<{
  user: AssignmentUser;
  assignments: AssignmentRecord[];
  availability: Immutable<ApiVolunteerAvailability[]>;
}>;

/**
 * Creates a renderer to be the child of our `<TableHeader>` in the assignment
 * table.
 *
 * For consistency, we use this for locations and people, since the days part is
 * the same.
 */
export function makeAssignmentTableColumnRenderer({
  rowHeaderColumnWidthPx,
}: {
  rowHeaderColumnWidthPx: number;
}): TableHeaderProps<AssignmentTableColumn>['children'] {
  // eslint-disable-next-line react/display-name
  return (col: AssignmentTableColumn) => {
    switch (col.type) {
      case 'location':
        return (
          <Column
            textValue="Polling Location"
            isRowHeader
            width={rowHeaderColumnWidthPx}
          >
            <div className="flex h-full w-96">
              <LocationsColumnHeader locationCount={col.locationCount} />
            </div>
          </Column>
        );

      case 'people':
        return (
          <Column textValue="People" isRowHeader width={rowHeaderColumnWidthPx}>
            <div className="flex h-full w-96">
              <PeopleColumnHeader peopleCount={col.peopleCount} />
            </div>
          </Column>
        );

      case 'day':
        return (
          <Column
            textValue={moment(col.day.date).format('LL')}
            minWidth={DAY_COLUMN_MIN_WIDTH_PX}
          >
            <DateColumnHeader
              day={col.day}
              userCounts={col.userCounts}
              suggestionCount={col.suggestionCount}
            />
          </Column>
        );

      default:
        assertUnreachable(col);
    }
  };
}

/**
 * Creates a function to turn our `LocationTableRow`s values into rendered table
 * `<Cell>`s.
 *
 * We want the data to primarily come from the rows themselves, so that
 * rendering a cell is idempotent, but we make an exception for looking up user
 * info.
 */
export function makeLocationCellRenderer({
  visibleElectionDays,
  usersById,
  tierConfiguration,
  doOpenNewAssignmentModal,
  getDistanceMi,
  acceptAssignments,
  changeShiftCount,
}: {
  visibleElectionDays: ApiElectionDay[];
  usersById: Immutable<Map<number, AssignmentUser>>;
  tierConfiguration: ApiLocationTierConfiguration[];
  doOpenNewAssignmentModal: (
    location: Omit<AssignmentLocation, 'hours'>,
    hours: ApiLocationHours
  ) => void;
  getDistanceMi: DistanceMiLookupFunc;
  acceptAssignments: (
    assignments: (AssignmentRecord & { type: 'poll' })[]
  ) => void;
  changeShiftCount: (
    location: Omit<AssignmentLocation, 'hours'>,
    hours: ApiLocationHours,
    delta: number
  ) => void;
}): TableBodyProps<LocationTableRow>['children'] {
  // eslint-disable-next-line react/display-name
  return (row) => {
    switch (row.type) {
      case 'header':
        // We don’t have to put any specifics in header since <AssignmentTable>
        // will handle rendering it automatically.
        return (
          <Row>
            {[
              <Cell key={`${row.key}-title`}>{row.title}</Cell>,
              // react-stately requires that each row have the same number of
              // columns, so we add blank cells to fill this out.
              ...visibleElectionDays.map((day) => (
                <Cell key={`${row.key}-${day.date}`}>{''}</Cell>
              )),
            ]}
          </Row>
        );

      case 'item': {
        const { location, assignments, hours, bulkEditModeType, isExpanded } =
          row.value;

        return (
          <Row>
            {[
              <Cell
                key={makeLocationRowHeaderKey(location.id)}
                textValue={location.name}
              >
                <LocationRowHeader location={location} />
              </Cell>,

              ...visibleElectionDays.map((day) => (
                <Cell key={makeLocationDayKey(location.id, day.date)}>
                  <LocationDayCell
                    location={location}
                    hours={hours.find((h) => h.date === day.date) ?? null}
                    assignments={assignments.filter((a) => a.date === day.date)}
                    tierConfiguration={tierConfiguration}
                    usersById={usersById}
                    expanded={isExpanded}
                    getDistanceMi={getDistanceMi}
                    doOpenNewAssignmentModal={doOpenNewAssignmentModal}
                    acceptAssignments={acceptAssignments}
                    bulkEditModeType={bulkEditModeType}
                    changeShiftCount={changeShiftCount}
                  />
                </Cell>
              )),
            ]}
          </Row>
        );
      }

      default:
        assertUnreachable(row);
    }
  };
}

/**
 * Creates a function to turn our `PeopleTableRow` values into rendered table
 * `<Cell>`s.
 */
export function makePeopleCellRenderer({
  visibleElectionDays,
  locationsById,
  electionDate,
  setSelectedLocationDay,
  doOpenNewAssignmentModal,
  getDistanceMi,
}: {
  visibleElectionDays: ApiElectionDay[];
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  electionDate: DateString | null;
  setSelectedLocationDay: (locationDay: SelectedLocationDay) => void;
  doOpenNewAssignmentModal: (
    location: AssignmentLocation,
    hours: ApiLocationHours,
    defaultUserId: number
  ) => void;
  getDistanceMi: DistanceMiLookupFunc;
}): TableBodyProps<PeopleTableRow>['children'] {
  // eslint-disable-next-line react/display-name
  return (row) => {
    switch (row.type) {
      case 'header':
        // We don’t have to put any specifics in header since <AssignmentTable>
        // will handle rendering it automatically.
        return (
          <Row>
            {[
              <Cell key={`${row.key}-title`}>{row.title}</Cell>,
              // react-stately requires that each row have the same number of
              // columns, so we add blank cells to fill this out.
              ...visibleElectionDays.map((day) => (
                <Cell key={`${row.key}-${day.date}`}>{''}</Cell>
              )),
            ]}
          </Row>
        );

      case 'item': {
        const { user, assignments, availability } = row.value;

        return (
          <Row>
            {[
              <Cell
                key={makeLocationRowHeaderKey(user.id)}
                textValue={`${user.first_name} ${user.last_name}`}
              >
                <PersonRowHeader user={user} />
              </Cell>,

              ...visibleElectionDays.map((day) => (
                <Cell key={makePersonDayKey(user.id, day.date)}>
                  <PersonDayCell
                    date={day.date}
                    user={user}
                    isEDay={day.date === electionDate}
                    assignments={assignments.filter((a) => a.date === day.date)}
                    availability={availability.filter(
                      (a) => a.date === day.date
                    )}
                    locationsById={locationsById}
                    expanded={visibleElectionDays.length === 1}
                    selectLocationDay={(locationId, date) =>
                      setSelectedLocationDay({ locationId, date })
                    }
                    doOpenNewAssignmentModal={doOpenNewAssignmentModal}
                    getDistanceMi={getDistanceMi}
                  />
                </Cell>
              )),
            ]}
          </Row>
        );
      }

      default:
        assertUnreachable(row);
    }
  };
}

/**
 * Returns the key used for the row header cell in the location table.
 */
export function makeLocationRowHeaderKey(locationId: number): React.Key {
  return `location:${locationId}`;
}

/**
 * Returns the key of a day cell in the location table.
 */
export function makeLocationDayKey(
  locationId: number,
  date: DateString
): React.Key {
  return `day:${locationId}:${date}`;
}

/**
 * Returns the key of a day cell in the people table.
 */
export function makePersonDayKey(userId: number, date: DateString): React.Key {
  return `day:${userId}:${date}`;
}

/**
 * Return type for {@link parseLocationDayKey} to differentiate between clicking
 * in a cell or a cell header.
 */
export type CellIdentifier =
  | {
      type: 'location-day';
      locationId: number;
      date: DateString;
    }
  | {
      type: 'day-column-header';
      date: DateString;
    };

/**
 * Given a key to a cell, parses out the location ID and date, if the key refers
 * to a date cell.
 *
 * Used to go from a clicked-on cell to the actual location and date.
 */
export function parseLocationDayKey(key: React.Key): CellIdentifier | null {
  if (typeof key === 'string') {
    if (key.startsWith('day:')) {
      const [, locationIdStr, dateStr] = key.split(':');
      return {
        type: 'location-day',
        locationId: Number(locationIdStr),
        date: dateStr as DateString,
      };
    } else if (isDateString(key)) {
      return {
        type: 'day-column-header',
        date: key,
      };
    }
  }
  return null;
}

export function describeDistanceMi(
  distanceMi: number | null,
  maxDistanceMiles: number | undefined | null
) {
  let text = `${distanceMi === null ? '?' : Math.round(distanceMi)}mi`;

  if (typeof maxDistanceMiles === 'number') {
    // \xa0 is non-breaking space
    text = `${text} (max.\xa0${maxDistanceMiles}mi)`;
  }

  return text;
}

/**
 * Returns a material-icons icon name to represent the distance a volunteer is
 * from the polling location.
 */
export function iconForDistanceMi(distanceMi: number | null) {
  if (distanceMi === null) {
    return 'not_listed_location';
  }

  distanceMi = Math.round(distanceMi);

  if (distanceMi <= 1) {
    return 'directions_walk';
  } else if (distanceMi <= 10) {
    return 'directions_bike';
  } else if (distanceMi <= 25) {
    return 'directions_bus';
  } else if (distanceMi <= 50) {
    return 'directions_car';
  } else if (distanceMi <= 100) {
    return 'airport_shuttle';
  } else if (distanceMi <= 250) {
    return 'local_shipping';
  } else {
    return 'flight';
  }
}

/**
 * Creates a URL that links to Google Maps directions from a user to a polling
 * location.
 */
export function makeGoogleDirectionsUrl(
  user: AssignmentUser,
  location: Pick<AssignmentLocation, 'address' | 'city' | 'county' | 'zipcode'>
) {
  const locationAddr = `${location.address || ''}, ${location.city}, ${
    location.county.state
  } ${location.zipcode}`;

  const userAddr = `${user.address || ''}, ${user.city}, ${user.state}`;

  return `https://google.com/maps/dir/?api=1&origin=${encodeURIComponent(
    userAddr
  )}&destination=${encodeURIComponent(locationAddr)}`;
}
