import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import EmptyAlert from '../../components/presentational/lbj/empty-alert';
import {
  issuePriorities,
  ROLE_CODES,
  issueStatuses,
  issueClasses,
  IssueClass,
} from '../../constants';
import {
  PaginationWrapper,
  SetPageFn,
} from '../../decorators/paginated-component';
import { IssueListApiIssue } from '../../services/issue-service';
import { PaginationData } from '../../utils/lbj/map-state-to-pagination';

const TABLE_COLUMNS = [
  {
    slug: 'id',
    name: 'ID',
    sortable: true,
  },
  {
    slug: 'status',
    name: 'Status',
    sortable: false,
  },
  {
    slug: 'owner',
    name: 'Assignee',
    sortable: true,
  },
  {
    slug: 'priority',
    name: 'Priority',
    sortable: true,
  },
  {
    slug: 'state',
    name: 'State',
    sortable: true,
  },
  {
    slug: 'county',
    name: 'County',
    sortable: true,
  },
  {
    slug: 'description',
    name: 'Description',
    sortable: true,
  },
  {
    slug: 'created_by',
    name: 'Reporter',
    sortable: true,
  },
  {
    slug: 'boiler_room',
    name: 'Boiler Room',
    sortable: true,
  },
  {
    slug: 'report_time',
    name: 'Reported time',
    sortable: true,
  },
];

export default class IssueList extends React.Component<{
  issues: IssueListApiIssue[];
  filterParams: URLSearchParams;
  listRequestIsPending: boolean;
  paginationData: PaginationData;
  paginationAction: SetPageFn;
  sortSlug: string;
  sortDescending: boolean;
  toggleSort: (slug: string) => void;
  /** Element to render when the issues list is empty. */
  emptyAlert?: JSX.Element;
}> {
  getAssigneeText(issue: IssueListApiIssue) {
    const assignee = issue.owner;
    const boilerRoom = issue.boiler_room;

    let name = 'None';
    let roleCode = '';
    let brLevelCode = 'State BR';

    if (assignee) {
      name = `${assignee.last_name}, ${assignee.first_name}`;
      roleCode = ROLE_CODES[assignee.role];
    }

    if (boilerRoom.level === 'national') {
      brLevelCode = 'National BR';
    }

    if (boilerRoom.level === 'regional') {
      brLevelCode = 'Regional BR';
    }

    const label = `${brLevelCode} ${roleCode}`.trim();

    return (
      <span>
        {name}
        <br />(<i>{label}</i>)
      </span>
    );
  }

  renderHeadings() {
    const { sortSlug, sortDescending, toggleSort } = this.props;

    const headings = TABLE_COLUMNS.map(({ slug, name, sortable }) => {
      let sortAttributes;

      if (sortable) {
        const toggleColumnSort = () => toggleSort(slug);

        if (sortSlug === slug) {
          sortAttributes = {
            'data-sort': sortDescending ? 'DESC' : 'ASC',
            onClick: toggleColumnSort,
          };
        } else {
          sortAttributes = {
            'data-sort': '',
            onClick: toggleColumnSort,
          };
        }
      }

      return (
        <th key={`heading-${slug}`} data-slug={slug} {...sortAttributes}>
          {name}
        </th>
      );
    });

    return <tr>{headings}</tr>;
  }

  renderIssueCategory(
    type: IssueClass,
    category: string,
    subcategory: string,
    first = false
  ) {
    const typeLetter = issueClasses[type] ? issueClasses[type][0] : '';
    return (
      <div>
        {' '}
        {!first && <hr />}
        {typeLetter}: <span className="issue-list-category">{category}</span>
        <br />
        <span className="issue-list-subcategory">{subcategory}</span>
      </div>
    );
  }

  renderIssue(issue: IssueListApiIssue, index: number) {
    const { issues, filterParams } = this.props;

    const reportTimeString = issue.report_time
      ? moment(issue.report_time).format('MMM Do, h:mma')
      : '';
    const priorityString =
      issue.priority === '' ? '' : issuePriorities[issue.priority];
    const rowClassName = issue.status === 'resolved' ? 'is-resolved' : '';
    const reporter = issue.created_by;

    const issueUrlParams = new URLSearchParams({
      issues: issues.map((iss) => iss.id).join(','),
      index: index.toString(),
      filters: filterParams.toString(),
    });

    const issueUrl = `/issues/${issue.id}?${issueUrlParams.toString()}`;

    return (
      <tr key={index} className={rowClassName}>
        <td>
          <Link to={issueUrl}>{issue.id}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{issueStatuses[issue.status]}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{this.getAssigneeText(issue)}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{priorityString}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{issue.state}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{issue.county.name}</Link>
        </td>
        <td>
          <Link to={issueUrl}>
            {this.renderIssueCategory(
              issue.type,
              issue.category,
              issue.sub_category,
              true
            )}
            {issue.category_2 &&
              this.renderIssueCategory(
                issue.type,
                issue.category_2,
                issue.sub_category_2
              )}
            {issue.category_3 &&
              this.renderIssueCategory(
                issue.type,
                issue.category_3,
                issue.sub_category_3
              )}
          </Link>
        </td>
        <td>
          <Link to={issueUrl}>
            {reporter.last_name}, {reporter.first_name}
            <br />({reporter.assignment_state} {ROLE_CODES[reporter.role]})
          </Link>
        </td>
        <td>
          <Link to={issueUrl}>{issue.boiler_room.name}</Link>
        </td>
        <td>
          <Link to={issueUrl}>{reportTimeString}</Link>
        </td>
      </tr>
    );
  }

  render() {
    const {
      issues,
      listRequestIsPending,
      emptyAlert,
      paginationData,
      paginationAction,
    } = this.props;

    const isEmpty = issues.length === 0;

    return (
      <PaginationWrapper
        paginationData={paginationData}
        setPage={paginationAction}
      >
        <div className="lbj-table-wrapper">
          <table className="lbj-table lbj-issue-list-table">
            <thead>{this.renderHeadings()}</thead>
            <tbody>
              {issues.map((issue, index) => this.renderIssue(issue, index))}
            </tbody>
          </table>

          {isEmpty &&
            !listRequestIsPending &&
            (emptyAlert || (
              <EmptyAlert
                header="Looks like there are no issues to process right now."
                description="Click New issue to submit an issue. Issues will appear here after they’ve been added."
              />
            ))}
        </div>
      </PaginationWrapper>
    );
  }
}
