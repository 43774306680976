import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import Modal from '../lbj/modal';

export default class EmailConfirmationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    replyTo: PropTypes.string.isRequired,
    confirmationMsg: PropTypes.node.isRequired,
    headerText: PropTypes.node.isRequired,
  };

  render() {
    const { onClose, replyTo, confirmationMsg, headerText } = this.props;

    return (
      <Modal
        classes="lbj-green-modal"
        headerText={headerText}
        onClose={onClose}
        dismissable
      >
        <div className="email-confirmation-modal">
          <div className="email-confirmation-text">
            {confirmationMsg}
            {RIT(!!replyTo.trim(), () => {
              return (
                <p>
                  Users’ replies will be delivered to <strong>{replyTo}</strong>
                  .
                </p>
              );
            })}
          </div>
          <button
            type="button"
            className="c-button-large lbj-button-green"
            onClick={onClose}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}
