import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import RIT from '../../../utils/render-if-truthy';
import { queryToSearch } from '../../../utils/routing-provider';

@connect((state) => {
  return {
    filters: mapStateToAssignmentFilters(state),
  };
})
export default class LocationDetails extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  formatLocationHours() {
    const { location } = this.props;
    const date = location.getIn(['hours', 0, 'date']);
    const openTime = location.getIn(['hours', 0, 'open_time']);
    const closeTime = location.getIn(['hours', 0, 'close_time']);

    if (openTime && closeTime) {
      return `${moment(`${date}T${openTime}`).format('h:mm a')} - ${moment(
        `${date}T${closeTime}`
      ).format('h:mm a')} on ${date}`;
    }

    return null;
  }

  render() {
    const { filters, location, children } = this.props;
    const locationHours = this.formatLocationHours();

    return (
      <div className="lbj-column-content">
        <ul className="lbj-detail-list">
          <li className="with-icon icon-rank">
            <Link
              className="lbj-more-detail-link"
              to={{
                pathname: '/assignments',
                search: queryToSearch(
                  Object.assign(filters.toJS(), {
                    view: 'precincts',
                    location: location.get('id'),
                  })
                ),
              }}
            >
              View precincts at this location
            </Link>
          </li>

          <li className="with-icon icon-location">
            {location.get('address')}, {location.get('city')}{' '}
            {location.getIn(['county', 'state'])} {location.get('zipcode')}
          </li>

          {RIT(!!locationHours, () => {
            return <li className="with-icon icon-clock">{locationHours}</li>;
          })}

          {RIT(!!location.get('text_hours'), () => {
            return (
              <li className="with-icon icon-calendar">
                {location.get('text_hours')}
              </li>
            );
          })}
        </ul>
        {children}
      </div>
    );
  }
}
