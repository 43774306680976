import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { assignmentViewTypes } from '../../../constants';
import { actionCreators } from '../../../modules/assignment';
import { getAssignmentsFromResource } from '../../../utils/assignment/map-resource-to-assignments';
import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import mapStateToListData from '../../../utils/assignment/map-state-to-list-data';
import mapStateToRequestedResource from '../../../utils/assignment/map-state-to-requested-resource';
import mapStateToStartEndTime from '../../../utils/assignment/map-state-to-start-end-time';
import AssignmentCoverage from '../../presentational/assignment/assignment-coverage';
import AssignmentDetailsItem from '../../presentational/assignment/assignment-details-item';

const { LOCATIONS, PEOPLE } = assignmentViewTypes;

@connect((state) => {
  const { assignment } = state;
  const assignmentEditor = assignment.get('assignmentEditor');
  const filters = mapStateToAssignmentFilters(state);
  const assignmentView = filters.get('view');
  const { requestedResourceId } = mapStateToRequestedResource(state);
  const { listData } = mapStateToListData(state);
  const resource = listData.findEntry(
    (entity) => entity.get('id') === requestedResourceId
  )[1];

  const assigningShiftDate = filters.get('dates');
  const { startTime, endTime } = mapStateToStartEndTime(
    state,
    assigningShiftDate
  );

  // the assignment that the user is currently editing
  const activeAssignmentId = assignmentEditor.getIn([
    'parentResource',
    'assignment',
    'id',
  ]);

  return {
    filters,
    assignmentView,
    requestedResourceId,
    resource,
    activeAssignmentId,
    assigningShiftDate,
    startTime,
    endTime,
  };
})

/**
 * This component displays a set of assignment timelines (either for a person
 * or a polling location) and includes buttons for adding, editing, and
 * deleting assignments. Used in the assignment details side pane.
 */
export default class AssignmentDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    requestedResourceId: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    assignmentView: PropTypes.oneOf([LOCATIONS, PEOPLE]).isRequired,
    resource: PropTypes.object.isRequired, // the location or person data object
    activeAssignmentId: PropTypes.number,
    readOnly: PropTypes.bool, // indicates whether to show editing buttons; editing isn't allowed for some roles
    assigningShiftDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      disableAddButton: true,
      selectedAssignmentId: null,
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentDidMount() {
    this.updateButtons();
  }

  componentDidUpdate(prevProps) {
    const {
      assignmentView,
      requestedResourceId,
      resource,
      activeAssignmentId,
    } = this.props;
    const prevNumAssignments = getAssignmentsFromResource(
      assignmentView,
      prevProps.resource
    ).size;
    const numAssignments = getAssignmentsFromResource(
      assignmentView,
      resource
    ).size;

    // If this component is showing assignments for a different location/person,
    // or if an assignment as added or deleted, update the disabled/selected
    // states of the buttons
    if (
      requestedResourceId !== prevProps.requestedResourceId ||
      numAssignments !== prevNumAssignments
    ) {
      this.updateButtons(activeAssignmentId);
    }
  }

  onEditClick(assignment) {
    const { dispatch } = this.props;
    const { showEditAssignment } = actionCreators;

    const parentResource = this.createParentResource();
    parentResource.assignment = assignment;
    dispatch(showEditAssignment(parentResource, assignment.get('type')));

    this.setState({
      disableAddButton: false,
      selectedAssignmentId: assignment.get('id'),
    });
  }

  // TODO: Consolidate this logic with what's in AssignmentSubmissionControls
  // so they can be kept consistent; eliminate need to pass filters
  onDeleteClick(assignment) {
    const { dispatch, filters, assignmentView, requestedResourceId } =
      this.props;
    const { deleteAssignmentAsync } = actionCreators;

    const resource = {
      resourceId: requestedResourceId,
      listViewType: assignmentView,
    };
    dispatch(
      deleteAssignmentAsync(assignment.toJS(), resource, filters.toJS())
    );

    this.setState({
      disableAddButton: true,
      selectedAssignmentId: null,
    });
  }

  onAddClick() {
    const { dispatch, assigningShiftDate, startTime, endTime } = this.props;
    const { showNewAssignment } = actionCreators;

    const parentResource = this.createParentResource();
    dispatch(
      showNewAssignment(
        parentResource,
        'poll',
        assigningShiftDate,
        startTime,
        endTime
      )
    );

    this.setState({
      disableAddButton: true,
      selectedAssignmentId: null,
    });
  }

  updateButtons(activeAssignmentId) {
    const { assignmentView, resource } = this.props;
    const assignments = getAssignmentsFromResource(assignmentView, resource);
    const hasAssignments = assignments && assignments.size > 0;
    this.setState({
      disableAddButton: !hasAssignments,
      selectedAssignmentId: activeAssignmentId,
    });
  }

  createParentResource() {
    const { assignmentView, resource } = this.props;
    const obj = {};
    if (assignmentView === LOCATIONS) {
      obj.location = resource;
    } else if (assignmentView === PEOPLE) {
      obj.user = resource;
    }
    return obj;
  }

  renderCoverage(resource) {
    return (
      <AssignmentCoverage
        includeLabel
        coverage={resource.getIn(['hours', 0, 'coverage'])}
      />
    );
  }

  render() {
    const { assignmentView, resource, readOnly } = this.props;
    const { disableAddButton, selectedAssignmentId } = this.state;
    const assignments = getAssignmentsFromResource(assignmentView, resource);

    return (
      <div className="assignment-details-v2">
        {assignmentView === LOCATIONS && this.renderCoverage(resource)}
        {assignments.entrySeq().map(([key, assignment]) => (
          <AssignmentDetailsItem
            key={key}
            assignment={assignment}
            onEditClick={() => this.onEditClick(assignment)}
            onDeleteClick={() => this.onDeleteClick(assignment)}
            selected={assignment.get('id') === selectedAssignmentId}
            readOnly={readOnly}
            now={moment()}
          />
        ))}
        {!readOnly && (
          <button
            className="assignment-details__add link-button--with-icon"
            onClick={this.onAddClick}
            disabled={disableAddButton}
          >
            <span className="material-icons">add_circle</span>Add Assignment
          </button>
        )}
      </div>
    );
  }
}
