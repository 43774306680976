import _ from 'lodash';

export function trackPackageVersion() {
  (global as any).gtag('set', { appVersion: process.env['FE_VERSION'] });
}

type GoogleAnalyticsEvent = {
  hitType: 'event';
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  value?: number;
};

export type RawAnalyticsEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};

export default function trackGoogleAnalyticsEvent(
  eventName: string,
  rawEventArgs: RawAnalyticsEvent
) {
  try {
    const gaArgs: GoogleAnalyticsEvent = {
      hitType: 'event',
      eventCategory: rawEventArgs.category,
      eventAction: rawEventArgs.action,
    };
    if (rawEventArgs.label) {
      gaArgs.eventLabel = rawEventArgs.label;
    }
    if (rawEventArgs.value) {
      gaArgs.value = rawEventArgs.value;
    }
    // ? because we’re not guaranteed to have this defined in Storybook or tests
    (global as any).gtag?.('event', eventName, gaArgs);
  } catch (e) {
    if (process.env['NODE_ENV'] === 'development') {
      console.error(e);
    }
  }
}
