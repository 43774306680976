import keyMirror from 'key-mirror';

export default keyMirror({
  PRECINCTS: null,
  LOCATIONS: null,
  PEOPLE: null,
  BOILER_ROOMS: null,
  HOTLINES: null,
  BOARDS_OF_ELECTIONS: null,
});
