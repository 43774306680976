import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { assignmentViewTypes, lbjAppSections } from '../../../constants';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';

import { getNumAssignmentsFromParentResource } from '../../../utils/assignment/map-resource-to-assignments';
import mapStateToRequestedResource from '../../../utils/assignment/map-state-to-requested-resource';
import mapStateToStartEndTime from '../../../utils/assignment/map-state-to-start-end-time';
import RIT from '../../../utils/render-if-truthy';
import BoardOfElectionsDetails from '../../presentational/assignment/assignment-boe-details';
import BoilerDetails from '../../presentational/assignment/assignment-boiler-details';
import LocationDateHours from '../../presentational/assignment/assignment-date-and-hours';
import FindUserFormV2 from '../../presentational/assignment/assignment-find-user-form-v2';
import LocationDetailsV2 from '../../presentational/assignment/assignment-location-details-v2';
import PrecinctDetails from '../../presentational/assignment/assignment-precinct-details';
import AssignmentSidebarContent from '../../presentational/assignment/assignment-sidebar-content';
import UserDetailsV2 from '../../presentational/assignment/assignment-user-details-v2';
import Sidebar from '../../presentational/lbj/sidebar';
import GoogleMap from '../maps/google-map-container';

import AssignmentDetails from './assignment-details';
import AssignmentDetailsFormV2 from './assignment-details-form-v2';
import FindBoardOfElectionsForm from './assignment-find-boe-form';
import FindBoilerForm from './assignment-find-boiler-form';
import FindPollingFormV2 from './assignment-find-polling-form-v2';
import FindPrecinctForm from './assignment-find-precinct-form';

import SubmissionControlsV2 from './assignment-submission-controls-v2';

const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
} = assignmentViewTypes;

@connect((state) => {
  const { assignment, user } = state;
  const assignmentEditor = assignment.get('assignmentEditor');
  const assignmentType = assignmentEditor.get('assignmentType');
  const updatedFields = assignmentEditor.get('updatedFields');
  const isReadOnly =
    user.getIn(['currentUser', 'userData', 'role']) === 'boiler_room_user';
  const filters = state.filters.get(lbjAppSections.ASSIGNMENT);
  const { requestedResourceId } = mapStateToRequestedResource(state);
  const shiftDate = filters.get('dates');
  const { startTime, endTime } = mapStateToStartEndTime(state, shiftDate);
  const electionState = user.getIn([
    'currentUser',
    'currentUserElection',
    'election',
    'state',
  ]);

  return {
    assignmentType,
    updatedFields,
    isReadOnly,
    filters,
    requestedResourceId,
    startTime,
    endTime,
    parentResource: assignmentEditor.get('parentResource'),
    shouldShowEditor: assignmentEditor.get('isEditing'),
    electionState,
  };
})
export default class EditorPanelV2 extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    requestedResourceId: PropTypes.number,
    assignmentView: PropTypes.string,
    assignmentType: PropTypes.string,
    parentResource: PropTypes.object,
    updatedFields: PropTypes.object,
    shouldShowEditor: PropTypes.bool,
    panelIsOpen: PropTypes.bool,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    electionState: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    // Close editor panel if parentResource is gone.
    const { parentResource, dispatch } = this.props;
    if (
      nextProps.parentResource.size === 0 &&
      parentResource.size !== 0 &&
      nextProps.panelIsOpen
    ) {
      dispatch(assignmentActionCreators.closeEditorPanel());
    }
  }

  shouldComponentUpdate(nextProps) {
    // Block component update if parentResource is gone and panel is not
    // yet closed.
    const { parentResource } = this.props;
    if (
      nextProps.parentResource.size === 0 &&
      parentResource.size !== 0 &&
      nextProps.panelIsOpen
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      requestedResourceId,
      assignmentView,
      parentResource,
      startTime,
      endTime,
    } = this.props;
    const prevNumAssignments = getNumAssignmentsFromParentResource(
      assignmentView,
      prevProps.parentResource
    );
    const numAssignments = getNumAssignmentsFromParentResource(
      assignmentView,
      parentResource
    );
    // If the user selected a different location/person in the listing or
    // deleted an assignment in the details pane, set the default shift times
    // on "updatedFields" so they'll be sent in the API call if the user saves
    // a new assignment without changing the times.
    if (
      requestedResourceId !== prevProps.requestedResourceId ||
      numAssignments < prevNumAssignments
    ) {
      this.onEditAssignmentData({
        start_time: startTime,
        end_time: endTime,
      });
    }
  }

  onCloseEditor(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { closeEditorPanel } = assignmentActionCreators;

    dispatch(closeEditorPanel());
  }

  onEditAssignmentData(assignmentData) {
    const { dispatch, updatedFields } = this.props;
    const { saveUpdatedAssignmentFields } = assignmentActionCreators;
    const updatedAssignmentData = _.assign(
      updatedFields.toJS(),
      assignmentData
    );

    dispatch(saveUpdatedAssignmentFields(updatedAssignmentData));
  }

  renderSidebarTitle() {
    const { assignmentView, parentResource, panelIsOpen } = this.props;
    let displayName;

    if (panelIsOpen) {
      switch (assignmentView) {
        case PRECINCTS:
          displayName = parentResource.getIn(['precinct', 'name']);
          break;

        case LOCATIONS:
          displayName = 'Location Details';
          break;

        case PEOPLE:
          displayName = 'Volunteer Details';
          break;

        case BOILER_ROOMS:
        case HOTLINES:
          displayName = parentResource.getIn(['boiler_room', 'name']);
          break;

        case BOARDS_OF_ELECTIONS:
          displayName = parentResource.getIn(['board_of_elections', 'name']);
          break;
      }
    }

    return (
      <span className="assignment-panel-title">
        <span
          className="lbj-fake-link assignment-close-editor"
          onClick={this.onCloseEditor.bind(this)}
        />
        <span className="assignment-details-title-text">{displayName}</span>
      </span>
    );
  }

  renderResourceDetails() {
    const { assignmentView, parentResource, isReadOnly, shouldShowEditor } =
      this.props;
    const location = parentResource.get('location');

    switch (assignmentView) {
      case PEOPLE: {
        const user = parentResource.get('user');
        return (
          <div>
            <AssignmentSidebarContent title="Volunteer Info" icon="person">
              <UserDetailsV2 user={user} />
            </AssignmentSidebarContent>

            <AssignmentSidebarContent title="Assignments" icon="event_note">
              <AssignmentDetails readOnly={isReadOnly} />
            </AssignmentSidebarContent>
          </div>
        );
      }

      case BOILER_ROOMS:
      case HOTLINES:
        return <BoilerDetails boilerRoom={parentResource.get('boiler_room')} />;

      case BOARDS_OF_ELECTIONS:
        return (
          <BoardOfElectionsDetails
            boardOfElections={parentResource.get('board_of_elections')}
          />
        );

      case PRECINCTS: {
        const precinctIsGeoCoded = location
          ? location.hasIn(['coordinates', 0])
          : false;
        return (
          <PrecinctDetails
            precinct={parentResource.get('precinct')}
            location={location}
          >
            {RIT(precinctIsGeoCoded && !shouldShowEditor, () => {
              return <GoogleMap center={location.get('coordinates')} />;
            })}
          </PrecinctDetails>
        );
      }

      case LOCATIONS:
      default: {
        const { filters } = this.props;
        const date = filters.get('dates');

        return (
          <div>
            <AssignmentSidebarContent title="Location" icon="place">
              <LocationDetailsV2 location={location} />
            </AssignmentSidebarContent>

            <AssignmentSidebarContent title="Date &amp; Hours" icon="today">
              <LocationDateHours location={location} date={date} />
            </AssignmentSidebarContent>

            <AssignmentSidebarContent title="Volunteers" icon="person">
              <AssignmentDetails readOnly={isReadOnly} />
            </AssignmentSidebarContent>
          </div>
        );
      }
    }
  }

  renderPlaceForm() {
    const { assignmentView, assignmentType, updatedFields, parentResource } =
      this.props;
    const pollingFormState =
      parentResource.getIn(['user', 'assignment_state']) ||
      parentResource.getIn(['assignment', 'user', 'assignment_state']);
    const user = parentResource.get('user');

    switch (assignmentType) {
      case 'poll':
        return (
          <FindPollingFormV2
            chooseLocationFirst={assignmentView === PEOPLE}
            precinct={updatedFields.get('precinct')}
            location={updatedFields.get('location')}
            assignmentState={pollingFormState}
            shiftDate={updatedFields.get('shift_date')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
            user={user}
          />
        );

      case 'hotline_center':
      case 'hotline_manager':
        return (
          <FindBoilerForm
            hotlineCenters
            assignmentState={parentResource.getIn(['user', 'assignment_state'])}
            boilerRoom={updatedFields.get('boiler_room')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );

      case 'boiler_room':
        return (
          <FindBoilerForm
            assignmentState={parentResource.getIn(['user', 'assignment_state'])}
            boilerRoom={updatedFields.get('boiler_room')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );

      case 'board_of_elections':
        return (
          <FindBoardOfElectionsForm
            boardOfElections={updatedFields.get('board_of_elections')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );
    }
  }

  renderUserForm() {
    const { assignmentView, electionState, parentResource, updatedFields } =
      this.props;
    const user = updatedFields.get('user');
    const formProps = {
      user,
      onEditAssignmentData: this.onEditAssignmentData.bind(this),
    };

    formProps.assignmentState = electionState;
    switch (assignmentView) {
      case PRECINCTS:
        formProps.includePlaceStatus = true;
        formProps.geoFilterByLocation = parentResource.getIn([
          'location',
          'id',
        ]);
        formProps.county = parentResource.getIn(['precinct', 'county']);
        break;

      case LOCATIONS:
        formProps.includePlaceStatus = true;
        formProps.geoFilterByLocation = parentResource.getIn([
          'location',
          'id',
        ]);
        formProps.county = parentResource.getIn(['location', 'county']);
        break;
    }
    return <FindUserFormV2 {...formProps} />;
  }

  renderAssignmentEditor() {
    const {
      parentResource,
      assignmentView,
      updatedFields,
      startTime,
      endTime,
    } = this.props;
    const requirePrecinctPicker = assignmentView === LOCATIONS;
    const defaultPrecinct = updatedFields.hasIn(['precinct', 'id'])
      ? updatedFields.get('precinct')
      : null;
    const title = parentResource.has('assignment')
      ? 'Edit Assignment'
      : 'Add Assignment';

    return (
      <AssignmentSidebarContent title={title} icon="schedule">
        <AssignmentDetailsFormV2
          requirePrecinctPicker={requirePrecinctPicker}
          defaultPrecinct={defaultPrecinct}
          onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          startTime={startTime}
          endTime={endTime}
        >
          {RIT(requirePrecinctPicker, () => {
            return (
              <FindPrecinctForm
                location={parentResource.get('location')}
                precinct={defaultPrecinct}
                onEditAssignmentData={this.onEditAssignmentData.bind(this)}
              />
            );
          })}
        </AssignmentDetailsFormV2>

        {RIT(parentResource.get('user'), this.renderPlaceForm.bind(this))}
        {RIT(parentResource.get('location'), this.renderUserForm.bind(this))}
        <SubmissionControlsV2 />
      </AssignmentSidebarContent>
    );
  }

  render() {
    const {
      shouldShowEditor,
      panelIsOpen,
      isReadOnly,
      assignmentView,
      parentResource,
    } = this.props;
    const isEditingAssignment = parentResource.has('assignment');
    const numAssignments = getNumAssignmentsFromParentResource(
      assignmentView,
      parentResource
    );
    // Show the Add/Edit Assignment form if there are no assignments, if the
    // user clicked on "Add Assignment", or if the user clicked on "Edit
    // Assignment, but NOT if the user has a role that doesn't permit editing.
    const showAssignmentForm =
      (numAssignments === 0 || shouldShowEditor || isEditingAssignment) &&
      !isReadOnly;

    return (
      <Sidebar
        title={this.renderSidebarTitle()}
        collapsible={false}
        showMemorial={false}
        className={`assignment-sidebar-v2 ${panelIsOpen ? '' : 'is-offscreen'}`}
      >
        {RIT(panelIsOpen, this.renderResourceDetails.bind(this))}
        {RIT(showAssignmentForm, this.renderAssignmentEditor.bind(this))}
      </Sidebar>
    );
  }
}
