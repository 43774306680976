import React from 'react';

import { DateMonth, DateString, SHORT_MONTHS } from '../../services/common';

export const DateBadge: React.FunctionComponent<{
  shiftDate: DateString;
}> = (props) => {
  const { shiftDate } = props;
  const shiftMonth = shiftDate.split('-')[1] as DateMonth;
  const shiftDay = shiftDate.split('-')[2]!;

  return (
    <div className={'min-w-fit'} aria-hidden="true">
      <div
        className={
          'flex h-20 w-20 flex-col rounded-lg bg-primary pt-3 pb-2 text-center leading-none text-white'
        }
      >
        <div className={'font-semibold uppercase'}>
          {SHORT_MONTHS[shiftMonth]}
        </div>
        <div className={'text-2xl font-bold'}>{shiftDay}</div>
      </div>
    </div>
  );
};
