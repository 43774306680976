import _ from 'lodash';

import { BoilerRoomLevel, ElectionType, State } from '../constants';
import { DeepMerge } from '../utils/types';

import ApiClient from './api-client';
import {
  CountySlug,
  DateString,
  OffsetResponse,
  PaginatedResponse,
  TimeString,
} from './common';

// TODO: I think i might re-name this to PlaceService and
//       include the routes to search polling locations/precincts

const defaultCongressionalDistrictsParams = {
  size: 10000,
};

export const defaultLocationsParams = {
  size: 1000,
};

const defaultPrecinctsParams = {
  size: 10000,
};

const defaultRegionsParams = {
  size: 1000,
};

const defaultBoilerRoomParams = {
  size: 1000,
};

const defaultBoardsOfElectionsParams = {
  size: 1000,
};

export type ApiCongressionalDistrict = {
  id: number;
  name: string;
};

export type ApiElectionDay = {
  is_early_vote: boolean;
  date: DateString;
};

export type ApiLocationTierConfiguration = {
  state_rank_gte: number;
  state_rank_lte: number;
  eday_am_shift_count?: number;
  eday_pm_shift_count?: number;
};

const REGISTRATION_REQUIREMENTS = ['none', 'county'] as const;

export type PollObserverRegistrationRequirement =
  typeof REGISTRATION_REQUIREMENTS[number];

export function isPollObserverRegistrationRequirement(
  value: any
): value is PollObserverRegistrationRequirement {
  return REGISTRATION_REQUIREMENTS.includes(value);
}

export type ApiElectionAssignmentPreferences = {
  force_poll_observer_survey?: boolean;
  poll_observer_registration_requirement?: PollObserverRegistrationRequirement;
};

/**
 * Type for an election! They matter!
 */
export type ApiElection = {
  id: number;
  name: string;
  state: State;
  type: ElectionType;

  is_test: boolean;
  contact_email: string | null;
  active: boolean;

  early_vote_start: DateString | null;
  early_vote_end: DateString | null;
  ev_shift_start_time: TimeString | null;
  ev_shift_change_time: TimeString | null;
  ev_shift_end_time: TimeString | null;

  election_date: DateString | null;
  eday_shift_start_time: TimeString | null;
  eday_shift_change_time: TimeString | null;
  eday_shift_end_time: TimeString | null;

  days: ApiElectionDay[];

  location_tier_configuration: ApiLocationTierConfiguration[];
  assignment_preferences: ApiElectionAssignmentPreferences;
};

/**
 * Type for election update form values
 */
export type ApiElectionUpdate = Pick<
  ApiElection,
  | 'eday_shift_start_time'
  | 'eday_shift_change_time'
  | 'eday_shift_end_time'
  | 'early_vote_start'
  | 'early_vote_end'
  | 'ev_shift_start_time'
  | 'ev_shift_change_time'
  | 'ev_shift_end_time'
  | 'contact_email'
  | 'location_tier_configuration'
  | 'assignment_preferences'
>;

/**
 * Common definition of a county. Appears as a property in a lot of other types.
 */
export type ApiCounty = {
  name: string;
  state: string | null;
  slug: CountySlug | '';
};

/**
 * Extension on {@link ApiCounty} that we see sometimes.
 */
export type WithApiCountyRegions = {
  regions: number[];
};

/**
 * API for a location, typically of a polling place.
 */
export type ApiLocation = {
  id: number;

  /**
   * WARNING: these IDs exceed safe values for JS integers
   */
  polling_place_id: number;
  name: string;

  /** True if open during early vote. */
  early_vote: boolean;
  /** True if open on election day. */
  election_day: boolean;

  text_hours: string | null;

  address: string;
  address_line_2: string | null;
  zipcode: string | null;
  city: string;

  /** Lng/Lat of the location, or empty array if not geocoded. */
  coordinates: [number, number] | [];

  /**
   * Program-set priority within the election. Lower number is more important.
   */
  state_rank: null | number;

  county: ApiCounty;

  /** Relevant when making requests relative to a specific user. */
  distance: null;
};

/**
 * Distance is only filled in when using {@link getNearbyLocations}.
 */
export type WithApiLocationDistance = {
  distance: number;
};

/**
 * Objects returned by the `/precincts/` API.
 */
export type ApiPrecinct = {
  id: number;
  van_precinct_id: number;
  name: string;
  tier: string | null;
  address: null;
  zipcode: null;
  city: null;
  county: ApiCounty;
  distance: null;
};

export type ApiRegion = {
  id: number;
  name: string;
  state: State;
};

/**
 * Type for a boiler room.
 */
export type ApiBoilerRoom = {
  id: number;
  /** Hotlines are also returned from the boiler room API. */
  level: BoilerRoomLevel | 'hotline';
  state: State;

  name: string;
  address: null;
  city: null;
  zipcode: null;
  region: null;
};

/**
 * Type for a Board of Elections’ information.
 */
export type ApiBoardOfElections = {
  id: number;
  name: string;
  county: ApiCounty & WithApiCountyRegions;
  address: string;
  zipcode: string;
  city: string;
  text_hours: string;
  coordinates: null;
};

export type ApiBoardOfElectionsNew = {
  name: string;
  county: string;
  address: string;
  zipcode: string;
  city: string;
  text_hours: string;
};

export function isApiBoardOfElectionsNew(obj: Object) {
  return 'name' in obj &&
    obj['name'] &&
    'county' in obj &&
    obj['county'] &&
    'address' in obj &&
    obj['address'] &&
    'zipcode' in obj &&
    obj['zipcode'] &&
    'city' in obj &&
    obj['city'] &&
    'text_hours' in obj &&
    obj['text_hours']
    ? true
    : false;
}

export const LANGUAGE_TAG_TO_ISO_CODE = {
  // ISO codes taken from Library of Congress definitions
  // see: https://www.loc.gov/standards/iso639-2/php/code_list.php
  // see: https://www.loc.gov/standards/iso639-5/id.php
  speaks_alaskan_athabascan: 'ATH',
  speaks_aleut: 'ALE',
  speaks_apache: 'APA',
  speaks_arabic: 'AR',
  speaks_cantonese: 'YUE',
  speaks_chinese: 'ZHO',
  speaks_choctaw: 'CHO',
  speaks_creole: 'CPE',
  speaks_filipino_tagalog: 'FIL',
  speaks_french: 'FR',
  speaks_german: 'DE',
  speaks_hindi: 'HIN',
  speaks_hmong: 'HMN',
  speaks_inupiat: 'IPK',
  speaks_korean: 'KOR',
  speaks_mandarin: 'CMN',
  speaks_navajo: 'NAV',
  // TODO: which sub-family of Puebloan languages?
  speaks_pueblo: 'PUEBLO',
  speaks_somali: 'SOM',
  speaks_limited_spanish: 'ES - Limited',
  speaks_spanish: 'ES',
  // TODO: Tegalu -> Telugu
  speaks_tegalu: 'TEL',
  speaks_urdu: 'URD',
  speaks_ute: 'UTE',
  speaks_vietnamese: 'VIE',
  "speaks_yup\\'ik": 'YPK',
};

export type LanguageTag = keyof typeof LANGUAGE_TAG_TO_ISO_CODE;

export function isActiveLanguageTag(
  tagName: string | undefined
): tagName is LanguageTag {
  return (
    !!tagName &&
    tagName.startsWith('speaks_') &&
    Object.keys(LANGUAGE_TAG_TO_ISO_CODE).includes(tagName)
  );
}

export function getLanguageIsoCode(tagName: string | undefined) {
  const tagNameIsLanguageTag = isActiveLanguageTag(tagName);
  if (!tagName || !tagNameIsLanguageTag) {
    return '???';
  }
  return LANGUAGE_TAG_TO_ISO_CODE[tagName];
}
/**
 * Tag info that can be used to categorize volunteers.
 */
export type ApiUserTag = {
  name: string | LanguageTag;
  display_name: string;
  type:
    | 'travel_distance'
    | 'language'
    | 'capability'
    | 'other'
    | 'availability';
};

// This is kind of a guess based on how it’s used since the API is returning an
// empty object.
export type ApiCongressionalDistrictResponseMap = {
  us_house?: ApiCongressionalDistrict[];
  state_house?: ApiCongressionalDistrict[];
  state_senate?: ApiCongressionalDistrict[];
};

export type ApiStateContactInfo = {
  code: State;
  name: string;
  contact: string | null;
};

export type ApiStateContactInfoMap = {
  [state in State]?: ApiStateContactInfo;
};

/**
 * Returns congressional districts? We think?
 */
export function getCongressionalDistricts(
  query: {
    state?: State;
    precinct?: string | number | undefined;
    county?: CountySlug | undefined;
  } = {}
) {
  const params = _.assign({}, defaultCongressionalDistrictsParams, query);

  return ApiClient<{
    districts: ApiCongressionalDistrictResponseMap;
    size: number;
    offset: number;
  }>('congressional_districts/', 'GET', params, {
    useCache: true,
  });
}

/**
 * Returns the counties in a given state, possibly filtered to region.
 */
export function getCounties(state: State, region: string | null = null) {
  const params: JQuery.PlainObject = _.assign({ size: 1000 }, { state });

  if (region !== null) {
    params['region'] = region;
  }

  return ApiClient<
    OffsetResponse<'counties', ApiCounty & WithApiCountyRegions>
  >('counties/', 'GET', params, { useCache: true });
}

/**
 * Returns the elections running in a given state.
 */
export function getElections(state: State | '' = '') {
  const params = _.assign({ size: 200 }, { state });
  return ApiClient<PaginatedResponse<ApiElection>>(
    'elections/',
    'GET',
    params,
    { useCache: true }
  );
}

/**
 * Returns the {@link ApiLocation}s with the given filter parameters.
 */
export function getLocations(
  query: {
    county?: string | undefined;
    query_election_id?: string | number;
    precinct?: string | number;
    size?: number;
  } = {}
) {
  const params = _.assign({}, defaultLocationsParams, query);

  if (query.county && !query.size) {
    // In 2022 Cook County IL has 2400+, LA County has 1200+. We allow loading
    // all iff the user is filtering on county.
    params.size = 3000;
  }

  return ApiClient<OffsetResponse<'locations', ApiLocation>>(
    'locations/',
    'GET',
    params,
    { useCache: false }
  );
}

/**
 * Returns the {@link ApiPrecinct}s that match the given query parameters.
 */
export function getPrecincts(
  query:
    | {
        county?: string;
        query_election_id?: string | number;
        location?: string | number;
        size?: number;
        offset?: number;
      }
    | { state?: string } = {}
) {
  const params = _.assign({}, defaultPrecinctsParams, query);
  return ApiClient<OffsetResponse<'precincts', ApiPrecinct>>(
    'precincts/',
    'GET',
    params,
    { useCache: false }
  );
}

/**
 * Returns the {@link ApiRegion}s that match the given query parameters.
 */
export function getRegions(
  query: {
    query_election_id?: number | string | undefined;
    state?: State | '';
    county?: CountySlug;
  } = {}
) {
  const params = _.assign({}, defaultRegionsParams, query);
  return ApiClient<OffsetResponse<'regions', ApiRegion>>(
    'regions/',
    'GET',
    params,
    {
      useCache: false,
    }
  );
}

/**
 * Returns the {@link ApiBoilerRoom}s that match the given query.
 */
export function getBoilerRooms(query = {}) {
  const params = _.assign({}, defaultBoilerRoomParams, query);
  return ApiClient<OffsetResponse<'boiler_rooms', ApiBoilerRoom>>(
    'boiler_rooms/',
    'GET',
    params,
    { useCache: true }
  );
}

/**
 * Returns the {@link ApiBoardOfElections}s that match the query.
 */
export function getBoardsOfElections(query = {}) {
  const params = _.assign({}, defaultBoardsOfElectionsParams, query);
  return ApiClient<OffsetResponse<'boards_of_elections', ApiBoardOfElections>>(
    'boards_of_elections/',
    'GET',
    params,
    { useCache: true }
  );
}

/**
 * Gets the tags allowed for this election to categorize users.
 */
export function getUserTags() {
  const params = { size: 1000 };
  return ApiClient<OffsetResponse<'tags', ApiUserTag>>(
    'user_tags/',
    'GET',
    params,
    { useCache: true }
  );
}

export type ApiNearbyLocation = DeepMerge<
  [ApiLocation, WithApiLocationDistance]
>;

/**
 * Returns locations that are near another location. The {@link ApiLocation}s in
 * the response will have a `distance` value filled in.
 */
export function getNearbyLocations(locationId: number) {
  return ApiClient<
    OffsetResponse<'nearby_locations', ApiNearbyLocation> & { location: number }
  >(`location/${locationId}/nearby/`, 'GET', {}, { useCache: true });
}

// Currently unused because onSetConutyFromZip is never called in `<IssueLocation
// />`.
export function getCountyForZip(zip: string) {
  return ApiClient(`zip_to_county/${zip}/`, 'GET', {}, { useCache: true });
}

/**
 * Returns a map of contact info for all current elections.
 */
export function getStateContactInfo() {
  return ApiClient<ApiStateContactInfoMap>(
    `state_contacts/`,
    'GET',
    {},
    {
      useCache: true,
    }
  );
}

/**
 * Returns the {@link ApiLocation} with the given locationId.
 */
export function getLocation(locationId: number) {
  return ApiClient<{ location: ApiLocation }>(
    `location/${locationId}`,
    'GET',
    {},
    { useCache: true }
  );
}

export default {
  getCongressionalDistricts,
  getCounties,
  getElections,
  getLocations,
  getPrecincts,
  getRegions,
  getBoilerRooms,
  getBoardsOfElections,
  getUserTags,
  getNearbyLocations,
  getCountyForZip,
  getLocation,
  getLanguageIsoCode,
};
