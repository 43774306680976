import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import RIT from '../../../utils/render-if-truthy';
import BoardOfElectionsDetails from '../../presentational/assignment/assignment-boe-details';
import BoardsOfElectionSearchForm from '../../presentational/assignment/assignment-boe-search-form';
import EditorSearchResults from '../../presentational/assignment/assignment-editor-search-results';

@connect((state) => {
  const filters = state.filters.getIn([lbjAppSections.ASSIGNMENT]);
  const boardOfElectionResults = state.lbj.get('boards_of_elections');

  return {
    filters,
    boardOfElectionsList: boardOfElectionResults.get('listData'),
    isLoadingList: boardOfElectionResults.get('requestIsPending'),
  };
})
export default class FindBoardOfElectionsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onEditAssignmentData: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    boardOfElectionsList: PropTypes.object.isRequired,
    isLoadingList: PropTypes.bool.isRequired,
    boardOfElections: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.lastSearch = {};
  }

  componentWillMount() {
    this.onSearchForBoardsOfElections();
  }

  componentDidUpdate(prevProps) {
    if (this.props.boardOfElections !== prevProps.boardOfElections) {
      this.onSearchForBoardsOfElections();
    }
  }

  onSearchForBoardsOfElections(filters = {}) {
    const { dispatch } = this.props;
    const { getBoardOfElectionsListAsync } = lbjActionCreators;

    dispatch(getBoardOfElectionsListAsync(filters));
    this.lastSearch = filters;
  }

  onGetMoreSearchResults({ offset }) {
    const { dispatch } = this.props;
    const { getBoardOfElectionsListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastSearch, { offset, size: 25 });
    dispatch(getBoardOfElectionsListAsync(filterPayload, true));
  }

  renderSearchForm() {
    return (
      <BoardsOfElectionSearchForm
        onSearchForBoardsOfElections={this.onSearchForBoardsOfElections.bind(
          this
        )}
      />
    );
  }

  renderBoardOfElectionsResult(boardOfElections, key) {
    const { onEditAssignmentData } = this.props;

    return (
      <li
        onClick={() =>
          onEditAssignmentData({ board_of_elections: boardOfElections })
        }
        key={key}
      >
        {boardOfElections.get('name')}
      </li>
    );
  }

  renderSearchResults() {
    const { boardOfElectionsList, isLoadingList } = this.props;

    return (
      <div className="lbj-column-content">
        <EditorSearchResults
          listData={boardOfElectionsList}
          isLoadingList={isLoadingList}
          onGetMoreSearchResults={this.onGetMoreSearchResults.bind(this)}
          listElementRenderer={this.renderBoardOfElectionsResult.bind(this)}
        />
      </div>
    );
  }

  renderBoardOfElectionsDetails() {
    const { boardOfElections } = this.props;

    return (
      <BoardOfElectionsDetails boardOfElections={boardOfElections} showHeader />
    );
  }

  renderBackButton(assignemntDataToClear) {
    return (
      <div className="lbj-column-content">
        <button
          type="button"
          className="c-button-wide c-button-secondary"
          onClick={this.props.onEditAssignmentData.bind(
            this,
            assignemntDataToClear
          )}
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    const { boardOfElections } = this.props;

    return (
      <div>
        {RIT(!boardOfElections, this.renderSearchForm.bind(this))}
        {RIT(!boardOfElections, this.renderSearchResults.bind(this))}
        {RIT(boardOfElections, this.renderBoardOfElectionsDetails.bind(this))}

        {RIT(
          !boardOfElections,
          this.renderBackButton.bind(this, { start_time: null, end_time: null })
        )}
        {RIT(
          boardOfElections,
          this.renderBackButton.bind(this, { board_of_elections: null })
        )}
      </div>
    );
  }
}
