import * as Immutable from 'immutable';
import React from 'react';
import { connect, Dispatch } from 'react-redux';

import { AppState, AppStore, toAppDispatch } from '../../../modules/flux-store';
import { actionCreators as userActionCreators } from '../../../modules/user';
import { loadCurrentUser } from '../../../route-handlers/app';
import { ApiUserTag } from '../../../services/lbj-shared-service';
import * as LbjSharedService from '../../../services/lbj-shared-service';
import * as LoginService from '../../../services/login-service';
import { ApiCurrentUser } from '../../../services/user-service';
import RIT from '../../../utils/render-if-truthy';
import { withRouting, WithRoutingProps } from '../../../utils/routing-provider';
import { MapFromJs } from '../../../utils/types';
import Modal from '../../presentational/lbj/modal';
import SurveyUserDetails from '../../presentational/survey/survey-user-details';

class SurveyContainer extends React.Component<
  {
    dispatch?: Dispatch<any> | undefined;
    tagData: Immutable.List<MapFromJs<ApiUserTag>>;
    currentUser: MapFromJs<ApiCurrentUser>;
    requirePollObserverInfo: boolean;
    isUpdatingUser: boolean;
    updatingUserErred: boolean;
    errorMsg?: string | undefined;
  } & WithRoutingProps,
  {
    errorMsg: string;
    showLogoutModal: boolean;
  }
> {
  constructor(props: SurveyContainer['props']) {
    super(props);

    this.state = {
      errorMsg: '',
      showLogoutModal: false,
    };
  }

  onConfirmChangeEmailRequest() {
    const { navigate } = this.props;

    navigate('/profile?view=assignments');
  }

  onUserDetailsSubmit(updatedFields: Immutable.Map<string, any>) {
    const { currentUser, navigate } = this.props;
    const dispatch = toAppDispatch(this.props.dispatch);

    const { updateUserAsync } = userActionCreators;
    const oldEmail = currentUser.get('email');
    const newEmail = updatedFields.get('email');

    dispatch(
      updateUserAsync(
        currentUser.get('id'),
        updatedFields.toJS(),
        currentUser.get('id')
      )
    ).then((state) => {
      const requestErred = state.user.getIn(['updatingUser', 'requestErred']);
      if (!requestErred) {
        if (!!newEmail && oldEmail !== newEmail) {
          this.showLogoutModal();
        } else {
          navigate('/');
        }
      }
    });
  }

  showLogoutModal() {
    this.setState({
      showLogoutModal: true,
    });
  }

  renderLogoutModal() {
    return (
      <Modal classes="UserModal" dismissable={false} headerText="Log Out">
        <div className="userModalContent">
          <form className="userModalForm" onSubmit={LoginService.logout}>
            <div>
              Since you updated your email address, you will need to log out and
              then log back in using the new email address.
            </div>
            <div className="buttonRow">
              <button
                type="submit"
                className="c-button-large c-button-secondary"
              >
                Log Out
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  render() {
    const {
      currentUser,
      tagData,
      isUpdatingUser,
      errorMsg,
      updatingUserErred,
      requirePollObserverInfo,
    } = this.props;

    return (
      <div className="lbj-page-wrapper overflow-auto">
        {RIT(this.state.showLogoutModal, this.renderLogoutModal.bind(this))}

        <SurveyUserDetails
          tagData={tagData}
          currentUser={currentUser}
          isUpdatingUser={isUpdatingUser}
          errorMsg={errorMsg}
          updatingUserErred={updatingUserErred}
          requirePollObserverInfo={requirePollObserverInfo}
          onSubmit={this.onUserDetailsSubmit.bind(this)}
        />
      </div>
    );
  }
}

/**
 * Props that the loader function is responsible for getting.
 */
type SurveyContainerLoadedProps = Pick<
  SurveyContainer['props'],
  'currentUser' | 'tagData' | 'requirePollObserverInfo'
>;

export default connect(
  (state: AppState, _ownProps: SurveyContainerLoadedProps) => {
    const { user } = state;

    return {
      isUpdatingUser: user.updatingUser.requestIsPending,
      updatingUserErred: user.updatingUser.requestErred,
      errorMsg:
        (user.updatingUser.error.get('detail') as string) ||
        // error.info holds ValidationError messages from lbj-backend
        (user.updatingUser.error.get('info') as string) ||
        undefined,
    };
  }
)(withRouting(SurveyContainer));

/**
 * Route loader for {@link SurveyContainer}. Ensures we have a current user and global
 * tag options.
 */
export async function loadForSurvey(
  fluxStore: AppStore
): Promise<SurveyContainerLoadedProps> {
  const { currentUser, currentUserElection } = await loadCurrentUser(fluxStore);
  const { tags } = await LbjSharedService.getUserTags();

  return {
    currentUser,
    tagData: Immutable.fromJS(tags) as Immutable.List<MapFromJs<ApiUserTag>>,
    requirePollObserverInfo: !!(
      currentUserElection
        .get('election')
        .get(
          'assignment_preferences'
        ) as MapFromJs<LbjSharedService.ApiElectionAssignmentPreferences>
    ).get('force_poll_observer_survey'),
  };
}
