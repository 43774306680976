import { Query } from 'history';
import keyMirror from 'key-mirror';
import _ from 'lodash';
import moment from 'moment';

import {
  FILTERS_EXISTING,
  PERMANENT_ELECTION_TYPE,
  State,
  US_NATIONAL_STATE_CODE,
} from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import fluxStore from '../../modules/flux-store';
import { DateString } from '../../services/common';
import { ApiElection } from '../../services/lbj-shared-service';
import { MapFromJs } from '../types';

import mapStateToAnalyticsFilters from './map-state-to-analytics-filters';

export const ANALYTICS_VIEW_PARAMS = keyMirror({
  issues_by_category: null,
  issues_by_region: null,
  issue_tables: null,
  observers: null,
});

export type AnalyticsView = keyof typeof ANALYTICS_VIEW_PARAMS;

export const USER_DEFAULT_FILTERS = keyMirror({
  category: null,
  state: null,
  query_election_id: null,
  date_lte: null,
  date_gte: null,
});

const STATIC_DEFAULTS = {
  ordering: 'Name',
  group_by: 'category',
  category: '',
} as const;

const SYNCED_FILTERS = ['state'] as const;

function _getUserDefaults() {
  const { getState } = fluxStore;
  const state = getState();
  const { user } = state;
  const assignmentState = user.currentUser.userData?.get('assignment_state') as
    | State
    | null
    | undefined;

  const election = user.currentUser.currentUserElection?.get(
    'election'
  ) as MapFromJs<ApiElection> | null;

  const today = moment().format('YYYY-MM-DD') as DateString;
  const defaults: Pick<
    FiltersBySection['ANALYTICS'],
    'state' | 'date_lte' | 'query_election_id'
  > = {
    state: assignmentState,
    date_lte: today,
  };

  if (assignmentState === US_NATIONAL_STATE_CODE) {
    delete defaults.state;
  } else {
    if (election && election.get('type') !== PERMANENT_ELECTION_TYPE) {
      defaults.query_election_id = election.get('id').toString();
    }
  }

  return defaults;
}

/**
 * Given a browser query string, returns the Analytics filters that it
 * describes.
 *
 * If the special query param “filters” is set to “existing,” uses the current
 * state to re-constitute `SYNCED_FILTERS`.
 */
export function mapQueryParamsToAnalyticsFilters(
  query: Query
): FiltersBySection['ANALYTICS'] {
  if (query['filters'] === FILTERS_EXISTING) {
    const fromState: Partial<FiltersBySection['ANALYTICS']> =
      mapStateToAnalyticsFilters(fluxStore.getState()).toJS();

    const syncedFilters = _.pick(fromState, SYNCED_FILTERS);
    const fromQuery = _.omit(query, ['filters']);

    return {
      ...syncedFilters,
      ...fromQuery,
    };
  }

  if (query['state'] === US_NATIONAL_STATE_CODE) {
    delete query['state'];
  }

  const userDefaults = _getUserDefaults();

  const filters = {
    ...STATIC_DEFAULTS,
    ...{ view: ANALYTICS_VIEW_PARAMS.issues_by_category },
    ...userDefaults,

    // TODO(fiona): validate this!!
    ...query,
  };

  return filters;
}

/**
 * Given the current state of Analytics filters, removes any that are the same
 * as the default values for those filters, since it would be redundant to show
 * those in the query string.
 */
export function mapAnalyticsFiltersToQueryParams(
  filters: FiltersBySection['ANALYTICS']
): FiltersBySection['ANALYTICS'] {
  const omit: Array<keyof FiltersBySection['ANALYTICS']> = [];

  // "as" since Object.keys is string[] by design
  (Object.keys(STATIC_DEFAULTS) as Array<keyof typeof STATIC_DEFAULTS>).forEach(
    (k) => {
      if (filters[k] === STATIC_DEFAULTS[k]) {
        omit.push(k);
      }
    }
  );

  return _.omit(filters, omit);
}
