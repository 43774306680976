import * as Immutable from 'immutable';
import * as _ from 'lodash';

import { requestStatuses } from '../../constants';
import * as LocalStoreService from '../../services/local-store-service';
import { ApiUser } from '../../services/user-service';
import {
  makeListRequestRecordObj,
  updateListRequestRecord,
} from '../../utils/lbj/list-request-state-handler';
import { MapFromJs } from '../../utils/types';
import { AppAction } from '../flux-store';
import {
  DISMISS_TOAST,
  ShowToastAction,
  SHOW_TOAST,
  ToastRecord,
} from '../toast';

import {
  BulkTagUsersAction,
  CloneUsersAction,
  CurrentUser,
  DetailedUser,
  DisableUsersAction,
  GetAllUserIdsAction,
  GetCurrentUserAction,
  GetUserCountAction,
  GetUserDetailsAction,
  ModifyUserError,
  UpdateUserAction,
} from './action-creators';

import actionTypes from './action-types';

const {
  GET_USER_COUNT,
  GET_USER_LIST,
  GET_ALL_USER_IDS,
  UPDATE_USER,
  GET_CURRENT_USER,
  GET_USER_DETAILS,
  CLEAR_USER_DETAILS,
  BULK_TAG_USERS,
  CLONE_USERS,
  DISABLE_USERS,
} = actionTypes;
const { PENDING, SUCCESS, ERROR } = requestStatuses;

function handleGetUserCount(
  state: UserState,
  data: GetUserCountAction['data']
) {
  switch (data.status) {
    case SUCCESS:
      return state.setIn(
        ['users', 'count', 'total'],
        // This is purposely a map from "count" -> count
        Immutable.Map({ count: data.count })
      );
    default:
      return state.setIn(['users', 'count', 'total'], undefined);
  }
}

/**
 * Returns the user election from the list of the user’s user elections that was
 * most recently visited.
 *
 * If there wasn’t one visited, or if that one is no longer active, returns the
 * first active user election for the user.
 */
function pickCurrentUserElection(
  currentUserElectionId: number | null,
  userElections: CurrentUser['user_elections']
) {
  const activeUserElections = userElections.filter(
    (ue) =>
      ue.active &&
      (ue.election.active || ue.role === 'vpd' || ue.role === 'deputy_vpd')
  );

  const defaultChoice = activeUserElections[0] ?? null;

  // Nothing set so default to the first one
  if (typeof currentUserElectionId !== 'number') {
    return defaultChoice;
  }

  // Try to find the user election that was stored in local storage, if it’s
  // still active. Otherwise just return the default.
  return (
    activeUserElections.find((ue) => ue.id === currentUserElectionId) ??
    defaultChoice
  );
}

/**
 * Handles a user getting updated.
 *
 * Besides updating the `updatingUser` API status fields, if the data is for a
 * success it will update the user in `currentUser`, `userDetails`, and the
 * `users.index` list if it’s there.
 */
function handleUpdateUser(state: UserState, data: UpdateUserAction['data']) {
  switch (data.status) {
    case PENDING:
      return state.update('updatingUser', (updatingUser) =>
        updatingUser.clear().merge({
          requestIsPending: true,
          requestErred: false,
          error: Immutable.Map({}),
        })
      );

    case SUCCESS: {
      const updatedUser = Immutable.fromJS(data.user) as MapFromJs<ApiUser>;

      /**
       * Updater for a user field. If the current value of the field matches our
       * updated user (by id), return that updated user.
       */
      const updateIfSameUser = <
        T extends MapFromJs<ApiUser> | MapFromJs<CurrentUser> | null
      >(
        u: T
      ) => (u?.get('id') === updatedUser.get('id') ? updatedUser : u);

      return state
        .update('currentUser', (currentUser) =>
          currentUser.update('userData', updateIfSameUser)
        )
        .update('userDetails', (currentUser) =>
          currentUser.update('userData', updateIfSameUser)
        )
        .update('users', (users) =>
          // TODO(fiona): should we update autocomplete as well?
          users.update('index', (index) =>
            index.update('listData', (listData) =>
              listData.map(updateIfSameUser)
            )
          )
        )
        .update('updatingUser', (updatingUser) =>
          updatingUser.clear().merge({
            requestIsPending: false,
            requestErred: false,
            error: Immutable.Map({}),
          })
        );
    }

    case ERROR:
      return state.update('updatingUser', (updatingUser) =>
        updatingUser.clear().merge({
          requestIsPending: false,
          requestErred: true,
          error: Immutable.fromJS(data.error) as MapFromJs<ModifyUserError>,
        })
      );
  }
}

function handleGetCurrentUser(
  state: UserState,
  data: GetCurrentUserAction['data']
): UserState {
  switch (data.status) {
    case PENDING:
      // `merge` without `clear` because we don’t want to overwrite `userData`
      // if it’s already set.
      return state.update('currentUser', (currentUser) =>
        currentUser.merge({
          requestIsPending: true,
          requestErred: false,
        })
      );

    case SUCCESS: {
      const currentUserElection = pickCurrentUserElection(
        data.currentUserElectionId,
        data.currentUser.user_elections
      );

      const userData = (
        Immutable.fromJS(data.currentUser) as MapFromJs<CurrentUser>
      )
        // Override their assignment state with the value from the current
        // election, if available
        .update(
          'assignment_state',
          (curState) => currentUserElection?.election.state ?? curState
        )
        .update('role', (curRole) => currentUserElection?.role ?? curRole);

      const currentUserElectionId = currentUserElection?.id;
      const currentElectionId = currentUserElection?.election.id;

      // We update the local store service here so that by the time the Redux
      // updates are visible to rendering components, these values have updated
      // as well.
      LocalStoreService.updateUserElectionIds(
        currentUserElectionId ?? null,
        currentElectionId ?? null
      );

      return state.update('currentUser', (currentUser) =>
        currentUser.clear().merge({
          requestIsPending: false,
          requestErred: false,
          userData,
          currentUserElection: Immutable.fromJS(
            currentUserElection
          ) as MapFromJs<CurrentUser['user_elections'][number]>,
        })
      );
    }

    case ERROR:
      // merge without clearing so we preserve `userData`
      //
      // TODO(fiona): Do we want to preserve userData??
      return state.update('currentUser', (currentUser) =>
        currentUser.merge({
          requestIsPending: false,
          requestErred: true,
          currentUserElection: null,
        })
      );
  }
}

function handleGetUserDetails(
  state: UserState,
  data: GetUserDetailsAction['data']
): UserState {
  switch (data.status) {
    case PENDING:
      // Merge without clear so we don’t disrupt userData?
      return state.update('userDetails', (userDetails) =>
        userDetails.merge({
          requestIsPending: true,
          requestErred: false,
        })
      );

    case SUCCESS:
      return state.update('userDetails', (userDetails) =>
        userDetails.clear().merge({
          requestIsPending: false,
          requestErred: false,
          userData: Immutable.fromJS(data.userData) as MapFromJs<DetailedUser>,
        })
      );

    case ERROR:
      // Merge without clearing so we don’t disrupt userData?
      return state.update('userDetails', (userDetails) =>
        userDetails.merge({
          requestIsPending: false,
          requestErred: true,
        })
      );
  }
}

function handleGetAllUserIds(
  state: UserState,
  data: GetAllUserIdsAction['data']
): UserState {
  return state.update('allUserIds', (allUserIds) =>
    allUserIds.update(data.userViewType, (userIds) => {
      switch (data.status) {
        case PENDING:
          return userIds.clear().merge({
            requestIsPending: true,
            requestErred: false,
          });

        case SUCCESS:
          return userIds.clear().merge({
            requestIsPending: false,
            requestErred: false,
            userIds: Immutable.List(data.userIds) as Immutable.List<number>,
          });

        case ERROR:
          return userIds.clear().merge({
            requestIsPending: false,
            requestErred: true,
          });
      }
    })
  );
}

function handleShowToast(state: UserState, data: ShowToastAction['data']) {
  return state.set('toastData', new ToastRecord(data));
}

function handleDismissToast(state: UserState) {
  return state.set('toastData', new ToastRecord());
}

function handleBulkTag(state: UserState, data: BulkTagUsersAction['data']) {
  switch (data.status) {
    case PENDING:
      return state.set('bulkTagError', false).set('isBulkTagging', true);

    case SUCCESS:
      return state.set('bulkTagError', false).set('isBulkTagging', false);

    case ERROR:
      return state.set('bulkTagError', true).set('isBulkTagging', false);
  }
}

function handleCloneUsers(state: UserState, data: CloneUsersAction['data']) {
  switch (data.status) {
    case PENDING:
      return state.set('cloneUsersError', false).set('isCloningUsers', true);

    case SUCCESS:
      return state.set('cloneUsersError', false).set('isCloningUsers', false);

    case ERROR:
      return state.set('cloneUsersError', true).set('isCloningUsers', false);
  }
}

function handleDisableUsers(
  state: UserState,
  data: DisableUsersAction['data']
) {
  switch (data.status) {
    case PENDING:
      return state
        .set('disableUsersError', false)
        .set('isDisablingUsers', true);

    case SUCCESS:
      return state
        .set('disableUsersError', false)
        .set('isDisablingUsers', false);

    case ERROR:
      return state
        .set('disableUsersError', true)
        .set('isDisablingUsers', false);
  }
}

export type ImmutableCurrentUserElection = MapFromJs<
  CurrentUser['user_elections'][number]
>;

export class UserState extends Immutable.Record({
  toastData: new ToastRecord(),

  users: Immutable.Record({
    index: makeListRequestRecordObj<ApiUser>(),
    autocomplete: makeListRequestRecordObj<ApiUser>(),

    count: Immutable.Record({
      requestIsPending: false,
      requestErred: false,
      total: null,
    })(),
  })(),

  userDetails: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    userData: null as MapFromJs<DetailedUser> | null,
  })(),

  allUserIds: Immutable.Record({
    ASSIGNMENT_NOTIFY: Immutable.Record({
      requestIsPending: false,
      requestErred: false,
      userIds: Immutable.List(),
    })(),
  })(),

  updatingUser: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    error: Immutable.Map({}),
  })(),

  currentUser: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    userData: null as MapFromJs<CurrentUser> | null,
    currentUserElection: null as ImmutableCurrentUserElection | null,
  })(),

  userSchedule: Immutable.Map(),

  isBulkTagging: false,
  bulkTagError: false,

  isCloningUsers: false,
  cloneUsersError: false,

  isDisablingUsers: false,
  disableUsersError: false,
}) {}

export const initialState = new UserState();

export default function users(
  state: UserState = initialState,
  action: AppAction
): UserState {
  switch (action.type) {
    case GET_USER_COUNT:
      return handleGetUserCount(state, action.data);

    case GET_USER_LIST:
      return state.update('users', (users) => {
        const usersKey = action.data.keypath[1];

        if (usersKey !== 'index' && usersKey !== 'autocomplete') {
          return users;
        }

        if (action.data.status === 'SUCCESS' && action.data.mergeResults) {
          // Save this in a const because referencing `action` in the callbacks
          // loses the type narrowing from this if statement.
          const { data } = action;

          return users.update(usersKey, (rec) =>
            updateListRequestRecord({
              ...data,
              listResponse: {
                ...data.listResponse,
                // Merge of the previous list with our new data.
                listData: [
                  ...(rec.listData.toJS() as ApiUser[]),
                  ...data.listResponse.listData,
                ],
              },
            })(rec)
          );
        } else {
          return users.update(usersKey, updateListRequestRecord(action.data));
        }
      });

    case UPDATE_USER:
      return handleUpdateUser(state, action.data);

    case GET_CURRENT_USER:
      return handleGetCurrentUser(state, action.data);

    case GET_ALL_USER_IDS:
      return handleGetAllUserIds(state, action.data);

    case GET_USER_DETAILS:
      return handleGetUserDetails(state, action.data);

    case CLEAR_USER_DETAILS:
      return state.update('userDetails', (userDetails) =>
        userDetails.clear().merge({
          requestIsPending: false,
          requestErred: false,
          userData: null,
        })
      );

    case SHOW_TOAST:
      return handleShowToast(state, action.data);

    case DISMISS_TOAST:
      return handleDismissToast(state);

    case BULK_TAG_USERS:
      return handleBulkTag(state, action.data);

    case CLONE_USERS:
      return handleCloneUsers(state, action.data);

    case DISABLE_USERS:
      return handleDisableUsers(state, action.data);

    default:
      return state;
  }
}
