import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ISSUE_NOTIF_TYPES, issueNotificationEvents } from '../../../constants';
import RIT from '../../../utils/render-if-truthy';

export default class NotificationItem extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    onAcknowledgeNotification: PropTypes.func.isRequired,
    isPopoverView: PropTypes.bool,
  };

  getUpdaterName() {
    const { notification } = this.props;

    if (notification.get('updater')) {
      return `${notification.getIn([
        'updater',
        'first_name',
      ])} ${notification.getIn(['updater', 'last_name'])}`;
    }
  }

  getCommentText() {
    const { notification } = this.props;

    if (notification.getIn(['comment', 'text'])) {
      const text = notification.getIn(['comment', 'text']);

      if (text.length > 100) {
        return `${text.slice(0, 100)}...`;
      }

      return text;
    }
  }

  getStory() {
    const { notification } = this.props;
    const updater = this.getUpdaterName();
    const event = notification.get('event');
    const commentText = this.getCommentText();

    if (!updater) return;

    switch (event) {
      case ISSUE_NOTIF_TYPES.details_changed:
        return `${updater} updated the details of this ticket`;

      case ISSUE_NOTIF_TYPES.assigned:
        return `${updater} updated the assignment`;

      case ISSUE_NOTIF_TYPES.resolved:
        return `Resolved by ${updater}`;

      case ISSUE_NOTIF_TYPES.reopened:
        return `Reopened by ${updater}`;

      case ISSUE_NOTIF_TYPES.comment:
        return commentText
          ? `${updater} said "${commentText}"`
          : `${updater} left a comment`;

      case ISSUE_NOTIF_TYPES.photo:
        return `${updater} added a photo`;

      case ISSUE_NOTIF_TYPES.escalation:
        return `${updater} escalated issue to ${notification.getIn([
          'boiler_room',
          'name',
        ])}`;

      case ISSUE_NOTIF_TYPES.deescalation:
        return `${updater} de-escalated issue to ${notification.getIn([
          'boiler_room',
          'name',
        ])}`;
    }
  }

  renderTimeStamp() {
    const { notification } = this.props;

    return (
      <span className="issue-notification-timestamp">
        {moment(notification.get('created_at')).fromNow(true)}
      </span>
    );
  }

  renderPopoverRows() {
    const { notification } = this.props;

    return (
      <div className="row">
        <div className="col-small-9">
          <strong>
            #{notification.get('ticket')} -{' '}
            {issueNotificationEvents[notification.get('event')]}
          </strong>
          <p className="notification-popover-story">{this.getStory()}</p>
        </div>

        <div className="col-small-3">{this.renderTimeStamp()}</div>
      </div>
    );
  }

  renderIndexRows() {
    const { notification } = this.props;

    return (
      <div className="row">
        <div className="col-medium-2">
          <strong>#{notification.get('ticket')}</strong>
        </div>

        <div className="col-medium-3">
          <strong>{issueNotificationEvents[notification.get('event')]}</strong>
        </div>

        <div className="col-medium-5">{this.getStory()}</div>

        <div className="col-medium-2">{this.renderTimeStamp()}</div>
      </div>
    );
  }

  render() {
    const { notification, isPopoverView, onAcknowledgeNotification } =
      this.props;
    const wrapperClass = cx('notification-item', {
      'is-shrunken': isPopoverView,
      'is-acked': notification.get('acked'),
    });

    return (
      <div
        className={wrapperClass}
        onClick={() => {
          onAcknowledgeNotification(notification);
        }}
      >
        {RIT(isPopoverView, this.renderPopoverRows.bind(this))}
        {RIT(!isPopoverView, this.renderIndexRows.bind(this))}
      </div>
    );
  }
}
