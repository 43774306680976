import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * A wrapper for each section of the details side pane, which has a heading
 * (text and icon) on the left and the content on the right.
 *
 * For simplicity (as opposed to the flexibility of passing in arbitrary HTML),
 * the "icon" prop is a Material Icon type.
 * See https://material.io/resources/icons/
 */
export default class AssignmentSidebarContent extends Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    icon: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { title, icon, children } = this.props;

    return (
      <div className="lbj-column-content lbj-sidebar-content">
        <div className="row">
          <div className="col-small-4 details-heading">
            <h4 className="font-bold">{title}</h4>
            <div>
              <span className="circle-icon">
                <span className="material-icons">{icon}</span>
              </span>
            </div>
          </div>
          <div className="col-small-8">{children}</div>
        </div>
      </div>
    );
  }
}
