import * as Immutable from 'immutable';

import { State } from '../../constants';

import { AppState } from '../../modules/flux-store';
import { ExistingIssueInProgress } from '../../modules/issue/reducers';
import { ApiIssue } from '../../services/issue-service';
import { ApiElection } from '../../services/lbj-shared-service';
import { DeepMerge, MapFromJs } from '../types';

type NoNullUndefined<T> = {
  [k in keyof T]: Exclude<T[k], null | undefined>;
};

type NumbersToStrings<T> = {
  [k in keyof T]: T[k] extends number ? string : T[k];
};

/**
 * {@link mapStateToIssuePayload} starts with `issueInProgress` and then
 * overwrites user, owner, county, location, and precinct with their IDs, then
 * makes all null and undefineds '' and makes all numbers into strings.
 *
 * TODO(fiona): Remove this odd intermediate representation.
 */
export type IssueInProgressPayload = NumbersToStrings<
  NoNullUndefined<
    DeepMerge<
      [
        ExistingIssueInProgress,
        Pick<
          ApiIssue,
          'user' | 'owner' | 'county' | 'location' | 'precinct' | 'created_by'
        >
      ]
    >
  >
>;

export default function mapStateToIssuePayload(
  state: AppState
): IssueInProgressPayload {
  const { issue } = state;
  const issueInProgress = issue.issueInProgress;
  const issueComments = issue.issueComments?.results ?? Immutable.List();
  const issuePhotos = (issue.issuePhotos?.results ?? Immutable.List()).sort(
    (a, b) => b.get('id') - a.get('id')
  );
  const issueDocuments = (
    issue.issueDocuments?.results ?? Immutable.List()
  ).sort((a, b) => b.get('id') - a.get('id'));

  const currentElection = state.user.currentUser.getIn([
    'currentUserElection',
    'election',
  ]) as MapFromJs<ApiElection>;

  let currentState: State | null | undefined = issueInProgress.get('state');
  if (!currentState) {
    currentState =
      currentElection.get('state') !== 'US'
        ? currentElection.get('state')
        : null;
  }

  // Flattens the expanded user, owner, county, location, and slug values to
  // their IDs.
  return issueInProgress
    .set('user', issueInProgress.getIn(['user', 'id']))
    .set('owner', issueInProgress.getIn(['owner', 'id']))
    .set('state', currentState)
    .set('county', issueInProgress.getIn(['county', 'slug']))
    .set('location', issueInProgress.getIn(['location', 'id']))
    .set('precinct', issueInProgress.getIn(['precinct', 'id']))
    .set('comments', issueComments)
    .set('photos', issuePhotos)
    .set('documents', issueDocuments)
    .map((val) => {
      if (val === null || typeof val === 'undefined') {
        return '';
      }

      if (typeof val === 'number') {
        return val.toString();
      }

      return val;
    })
    .toJS() as IssueInProgressPayload;
}
