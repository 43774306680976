import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { ApiLocationWithAssignments } from '../../../services/assignment-service';
import { formatTime } from '../../../services/common';
import { UserMappedAssignment } from '../../../utils/assignment/map-user-to-assignments';

import { formatPhoneNumber } from '../../../utils/user/phone-numbers';

/**
 * Type for the assignment data that’s passed to <AssignmentTimelineV2>.
 *
 * This can either be from a location’s expanded assignments, or a user’s
 * expanded assignments.
 */
export type AssignmentData =
  // This is the case where the assignment is coming from the location’s list of
  // assignments. We expect the user to be expanded.
  | ApiLocationWithAssignments['assignment_set'][string]

  // This is the case where the assignment is coming from the user’s expanded
  // assignments, in which case the objects will be expanded by {@link
  // mapUserToAssignments} or are undefined.
  | UserMappedAssignment;

/**
 * Important to narrow the type between the two choices for
 * {@link AssignmentData}.
 */
function isUserMappedAssignment(
  assignmentData: AssignmentData
): assignmentData is UserMappedAssignment {
  return typeof assignmentData.user === 'number';
}

/**
 * A time bar that shows when a volunteer has an assignment or when a polling
 * location has a volunteer.
 *
 * If this is rendered on the page of locations, the timeline is titled by the
 * user’s name.
 *
 * If this is rendered on the page of users, the timeline is titled by the place
 * that the assignment is for (a location, precinct, boiler room/hotline, or
 * board of elections).
 */
export default class AssignmentTimelineV2 extends React.Component<{
  assignmentData: AssignmentData;
  timelineStartHour: number;
  showCheckinStatus: boolean;
  now: moment.Moment;
  /** Element to show to the right of the timeline. */
  timelineActionsEl?: React.ReactElement | undefined;
}> {
  static defaultProps = {
    timelineStartHour: 5,
    showCheckinStatus: false,
  };

  state = {
    hovering: false,
  };

  getAssignmentLabel() {
    const { assignmentData } = this.props;

    // This handles the case where we’re rendering on the location list, so the
    // user property is hydrated.
    if (!isUserMappedAssignment(assignmentData)) {
      const user = assignmentData.user;
      const name = `${user.first_name} ${user.last_name}`;
      const phoneNumber = formatPhoneNumber(user.phone_number);

      return (
        <span>
          {name} {phoneNumber && <span className="phone">{phoneNumber}</span>}
        </span>
      );
    }

    // All of these other cases are from when we’re looking at a list of users
    // and want to render the assignments that each one has. The user object was
    // not hydrated but all the other objects are.

    const precinctName = assignmentData.precinct?.name;
    const locationName = assignmentData.location?.name;
    const countyName = assignmentData.location?.county.name;

    if (locationName && precinctName) {
      return (
        <span>
          {locationName} <span className="precinct">({precinctName})</span>
        </span>
      );
    } else if (locationName && countyName) {
      return (
        <>
          <span>{locationName}</span>{' '}
          <span className="county">{countyName}</span>
        </>
      );
    } else if (locationName) {
      return locationName;
    } else if (precinctName) {
      return precinctName;
    }

    if (assignmentData.boiler_room) {
      return assignmentData.boiler_room.name;
    }

    if (assignmentData.board_of_elections) {
      return assignmentData.board_of_elections.name;
    }

    // We don’t really expect to get here, but don’t have the types to prove it.
    return null;
  }

  renderAssignmentBar() {
    const { assignmentData, timelineStartHour } = this.props;
    const shiftStartHours = moment(
      assignmentData.start_time,
      'HH:mm:ss'
    ).hours();
    const shiftEndHours = moment(assignmentData.end_time, 'HH:mm:ss').hours();
    const widthClass = `timeline-width-${shiftEndHours - shiftStartHours}`;
    const offsetClass = `timeline-offset-${
      shiftStartHours - timelineStartHour
    }`;
    const className = cx(
      'assignment-timeline-object',
      widthClass,
      offsetClass,
      {
        'is-rover': assignmentData.rover,
      }
    );
    const startTime = formatTime(assignmentData.start_time);
    const endTime = formatTime(assignmentData.end_time);

    return (
      <div className={className}>
        {startTime} - {endTime}
      </div>
    );
  }

  /**
   * UX design note:
   * Check-in status should not be together with assignments because they're
   * different concepts. But, we're keeping check-in status on the same page for
   * now because it's a critical feature. We may separate them in a future UX
   * refinement.
   */
  renderCheckinStatus(withinLink: boolean) {
    const { assignmentData } = this.props;

    const mostRecentCheckIn = assignmentData.checkin_set[0];

    if (mostRecentCheckIn?.type === 'checkin') {
      return (
        <div className="checked-in checkin-action">&#10004; Checked in</div>
      );
    } else if (mostRecentCheckIn?.type === 'checkout') {
      return <div className="checked-out checkin-action">Checked out</div>;
    }

    // Only show “Add check in” if we are, in fact, linking to the check in
    // page.
    return withinLink && this.state.hovering ? (
      <div className="checkin-action">Add check in</div>
    ) : null;
  }

  renderVotingCompleteStatus() {
    const { assignmentData } = this.props;

    const mostRecentVotingComplete = assignmentData.checkin_set.find(
      (checkIn) => checkIn.wait_time === 'complete'
    );

    if (!mostRecentVotingComplete) {
      return null;
    }

    const titleBits: string[] = [];

    switch (mostRecentVotingComplete.is_counting_finished) {
      case 'no':
        titleBits.push('Counting has not finished.');
        break;

      case 'yes':
        titleBits.push('Counting has finished.');
        break;
    }

    switch (mostRecentVotingComplete.is_results_announced) {
      case 'no':
        titleBits.push('Results have not been announced.');
        break;

      case 'yes':
        titleBits.push('Results have been announced.');
        break;
    }

    return (
      <div
        className="status-info"
        title={titleBits.length ? titleBits.join(' ') : undefined}
      >
        Voting has completed
      </div>
    );
  }

  render() {
    const { assignmentData, showCheckinStatus, now, timelineActionsEl } =
      this.props;
    const isOutside = assignmentData.place_status === 'outside';

    const userId = isUserMappedAssignment(assignmentData)
      ? assignmentData.user
      : assignmentData.user.id;

    return (
      <div
        className="assignment-timeline-v2"
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <div className="assignment-info">
          <div className="assignment-label">
            {isOutside && <span className="is-outside">(Outside)</span>}
            {this.getAssignmentLabel()}
          </div>
          <div className="assignment-content">
            <div className="assignment-timeline-wrapper">
              <div className="assignment-data">
                {this.renderAssignmentBar()}
              </div>
            </div>
            {timelineActionsEl}
          </div>
        </div>
        {showCheckinStatus && (
          <div className="checkin-status">
            {now.isSame(moment(assignmentData.shift_date), 'day') ? (
              // Only show the link if we’re day of the assignment, since you
              // can only check in on the specific day.
              <Link
                to={`/checkin?user_id=${userId}&default_assignment_id=${assignmentData.id}`}
              >
                {this.renderCheckinStatus(true)}
              </Link>
            ) : (
              this.renderCheckinStatus(false)
            )}
            {this.renderVotingCompleteStatus()}
          </div>
        )}
      </div>
    );
  }
}
