import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { dismissToast } from '../../../modules/user/action-creators';
import Modal from '../../presentational/lbj/modal';

import UserToastContainer from './user-toast-container';

@connect((state) => ({
  isPending: state.user.get('isDisablingUsers'),
  hasError: state.user.get('disableUsersError'),
}))
export default class DisableUsersModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    isPending: PropTypes.bool,
    isVisible: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    handleDisableUsers: PropTypes.func.isRequired,
    userCount: PropTypes.number.isRequired,
    currentUserInQueue: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(props) {
    const { dispatch } = this.props;

    if (props.isVisible !== this.props.isVisible) {
      dispatch(dismissToast());
    }

    const success = !props.isPending && this.props.isPending && !props.hasError;

    if (success) {
      dispatch(dismissToast());
      this.onClose(true);
    }
  }

  onClose(success = false) {
    const { dispatch, onRequestClose } = this.props;
    dispatch(dismissToast());
    onRequestClose(success);
  }

  render() {
    const {
      handleDisableUsers,
      isPending,
      isVisible,
      userCount,
      currentUserInQueue,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    let descriptionText;

    if (currentUserInQueue) {
      descriptionText =
        'You cannot disable yourself. Close this modal and uncheck yourself.';
    } else {
      descriptionText = `Really disable ${userCount} user${
        userCount > 1 ? 's' : ''
      }?\
                         This will permanently delete all of their assignments and remove\
                         them from LBJ for this election.`;
    }
    const spinner = isPending ? (
      <div className="lbj-loading-overlay">
        <div className="lbj-loading-icon" />
      </div>
    ) : null;

    return (
      <Modal
        classes="UserActionModal UserModal"
        dismissable
        headerText="Disable users"
        onClose={this.onClose}
      >
        <div className="userActionContent userModalContent">
          <UserToastContainer />
          <div className="user-action-description">{descriptionText}</div>
          <div className="buttonRow">
            <button
              type="submit"
              className="c-button-large c-button-secondary"
              onClick={handleDisableUsers}
              disabled={currentUserInQueue}
            >
              Disable Users
            </button>
          </div>
          {spinner}
        </div>
      </Modal>
    );
  }
}
