import { lbjAppSections, assignmentViewTypes } from '../../constants';

const { ASSIGNMENT } = lbjAppSections;
const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  HOTLINES,
  BOARDS_OF_ELECTIONS,
} = assignmentViewTypes;

export default function (state) {
  const filters = state.filters.get(ASSIGNMENT);
  const parentResource = state.assignment.getIn([
    'assignmentEditor',
    'parentResource',
  ]);
  let requestedResourceId;

  switch (filters.get('view')) {
    case PRECINCTS:
      requestedResourceId = parentResource.getIn(['precinct', 'id']);
      break;

    case LOCATIONS:
      requestedResourceId = parentResource.getIn(['location', 'id']);
      break;

    case PEOPLE:
      requestedResourceId = parentResource.getIn(['user', 'id']);
      break;

    case BOILER_ROOMS:
    case HOTLINES:
      requestedResourceId = parentResource.getIn(['boiler_room', 'id']);
      break;

    case BOARDS_OF_ELECTIONS:
      requestedResourceId = parentResource.getIn(['board_of_elections', 'id']);
      break;
  }

  return { requestedResourceId };
}
