import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const inputPropTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export type LbjInputEvent<OutV> = {
  target: {
    name: string;
    value: OutV;
  };
  error?: string | null;
};

/**
 * Type for input props that get reused a lot across different components.
 */
export type InputProps<InV = string, OutV = InV> = {
  name: string;
  title?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: InV;
  value?: InV;
  required?: boolean;
  disabled?: boolean;
  onChange?: (ev: LbjInputEvent<OutV>) => void;
  errors?: string[] | undefined;
};

/**
 * Common default values for the above props.
 */
export const inputDefaultProps: Pick<
  InputProps,
  'type' | 'placeholder' | 'onChange' | 'errors'
> = {
  type: 'text',
  placeholder: '-',
  onChange: () => {},
  errors: [],
};

/**
 * Extends a component with common propTypes and defaultProps for form inputs
 */
export default function inputPropsDecorator() {
  return function (InputComponent: React.ComponentClass) {
    InputComponent.propTypes = _.assign(
      {},
      inputPropTypes,
      InputComponent.propTypes
    );
    InputComponent.defaultProps = _.assign(
      {},
      inputDefaultProps,
      InputComponent.defaultProps
    );
    return InputComponent;
  };
}
