import { produce, Immutable, castImmutable, Draft } from 'immer';

import { ApiLocationBulkUpdate } from '../../../services/assignment-service';

import * as Sets from '../../../utils/sets';
import { assertUnreachable } from '../../../utils/types';

import { AssignmentLocation } from '../assignment-state';

/**
 * Object w/ keys for {@link LocationMatchingColumn}. Used to implement
 * {@link isLocationMatchingColumn}.
 */
const LOCATION_MATCHING_COLUMNS = {
  id: null,
  countyName: null,
  address: null,
  name: null,
  city: null,
  zip: null,
};

/**
 * Object w/ keys for {@link LocationMetadataColumn}. Used to implement
 * {@link isLocationMetadataColumn}.
 */
const LOCATION_METADATA_COLUMNS = {
  rank: null,
};

/**
 * Names for the “columns” in LBJ data that can be used to link rows from a CSV.
 */
export type LocationMatchingColumn = keyof typeof LOCATION_MATCHING_COLUMNS;

/**
 * Names for the “columns” of output metadata that we’ll be importing.
 */
export type LocationMetadataColumn = keyof typeof LOCATION_METADATA_COLUMNS;

/**
 * @returns True if `str` is a {@link LocationMatchingColumn}.
 */
export function isLocationMatchingColumn(
  str: string
): str is LocationMatchingColumn {
  return str in LOCATION_MATCHING_COLUMNS;
}

/**
 * @returns True if `str` is a {@link LocationMetadataColumn}.
 */
export function isLocationMetadataColumn(
  str: string
): str is LocationMetadataColumn {
  return str in LOCATION_METADATA_COLUMNS;
}

/**
 * These are columns where we expect that the data may not match exactly. If a
 * row matches a location’s non-fuzzy columns but not its fuzzy ones, it gets
 * put in as “recommended.”
 *
 * TODO(fiona): Allow for fuzziness to still lead to concrete matches. There is
 * complexity here when there is reasonable fuzzy-match overlap. E.g. “Fire
 * House 38” and “Fire House 43.” We would want them to both fuzzy-match “Fire
 * House 25,” unless there’s a row that _exactly_ matches “Fire House 25.”
 */
const FUZZY_MATCHING_COLUMNS: LocationMatchingColumn[] = ['name', 'address'];

/**
 * Generic data structure to hold the linkage data for an LBJ location.
 *
 * We do all value lookups by string for consistency, so we don’t mis-parse CSV
 * data.
 */
export type LocationMatchingValues = {
  [linkageColumn in LocationMatchingColumn]: string | null;
};

export const LOCATION_COLUMN_LABELS: {
  [col in LocationMatchingColumn | LocationMetadataColumn]: string;
} = {
  id: 'LBJ ID',
  countyName: 'County Name',
  address: 'Address',
  city: 'City',
  name: 'Location Name',
  zip: 'ZIP Code',
  rank: 'Ranking',
};

/**
 * Maps of Sets of location IDs, collected by the values we’ll be doing matching
 * against.
 */
export type LocationIdsByMatchingColumns = {
  [linkageColumn in LocationMatchingColumn]: Map<string, Set<number>>;
};

export type LocationMatchingResults = {
  /** The matches if we ignore all “fuzzy” columns */
  recommendedRowIdToLocationIds: Map<number, Set<number>>;

  /**
   * Matches where all linked columns match exactly. Note that the same location
   * may appear multiple times in this map, so it’s not necessarily valid for
   * SET_MATCHES.
   */
  matchedRowIdxToLocationIds: Map<number, Set<number>>;
  /** Matches where all linked columns match exactly */
  matchedLocationIdToRowIdxs: Map<number, Set<number>>;
};

/**
 * Actions that work with {@link locationLinkageReducer}.
 */
export type LocationLinkageAction =
  | {
      /** Moves all “matched” rows to “accepted” */
      type: 'ACCEPT_MATCHED_ROWS';
    }
  | {
      /** Moves all “accepted” rows back to “matched” */
      type: 'RESET_ACCEPTED_ROWS';
    }
  | {
      /**
       * Accepts the auto-made matches. Ignores any rows or locations that were
       * already accepted.
       *
       * Ignores any rows that share locations with other matched rows.
       */
      type: 'IMPORT_LINKAGE_MATCHES';
      matches: Map<number, Set<number>>;
      /** If true, removes existing “accepted” and “matched” state. */
      reset?: boolean | undefined;
    }
  | {
      /**
       * Sets the row -> locations mapping for the “matched” stage.
       * Overwrites any existing match for the specified row.
       */
      type: 'SET_ROW_MATCH';
      rowIdx: number;
      locationIds: Set<number>;
    };

/**
 * Keeps track of what row -> locations have been matched (either automatically
 * or manually) and which have been “accepted.” We allow a single row to be
 * matched to muliple locations, with data flowing to each of them.
 *
 * Maintains the invariants that a location only appears once among the values.
 *
 * Matching a location from one row to another moves it. Trying to match a
 * location that’s already accepted is an error.
 *
 * Rows also only appear as matched or accepted. Matching a row that’s already
 * accepted un-matches it.
 */
export type LocationLinkageState = Immutable<{
  matchedRowIdxToLocationIds: Map<number, Set<number>>;
  acceptedRowIdxToLocationIds: Map<number, Set<number>>;
}>;

export const DEFAULT_LOCATION_LINKAGE_STATE: LocationLinkageState = {
  matchedRowIdxToLocationIds: castImmutable(new Map()),
  acceptedRowIdxToLocationIds: castImmutable(new Map()),
};

/**
 * Dispatch function to go with {@link LocationLinkageState} and
 * {@link locationLinkageReducer}.
 */
export type LocationLinkageDispatch = (action: LocationLinkageAction) => void;

export function locationLinkageReducer(
  state: LocationLinkageState,
  action: LocationLinkageAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'IMPORT_LINKAGE_MATCHES':
        if (action.reset) {
          draft.acceptedRowIdxToLocationIds.clear();
          draft.matchedRowIdxToLocationIds.clear();
        }

        handleImportLinkageMatchesAction(draft, action.matches);
        break;

      case 'SET_ROW_MATCH':
        handleSetRowMatchAction(draft, action.rowIdx, action.locationIds);
        break;

      case 'ACCEPT_MATCHED_ROWS':
        // Move all of our matched rows into accepted.
        for (const [rowIdx, locIds] of draft.matchedRowIdxToLocationIds) {
          draft.acceptedRowIdxToLocationIds.set(rowIdx, locIds);
        }

        draft.matchedRowIdxToLocationIds.clear();
        break;

      case 'RESET_ACCEPTED_ROWS':
        // Move all of our accepted rows back to just matched.
        for (const [rowIdx, locIds] of draft.acceptedRowIdxToLocationIds) {
          draft.matchedRowIdxToLocationIds.set(rowIdx, locIds);
        }

        draft.acceptedRowIdxToLocationIds.clear();
        break;

      default:
        assertUnreachable(action);
    }
  });
}

/**
 * Handles importing matches that come from the automatic linker.
 *
 * In this process, we ignore rows and locations that have already been
 * accepted, and also ignore non-deterministic matches (where more than one row
 * maps to the same location).
 */
function handleImportLinkageMatchesAction(
  draft: Draft<LocationLinkageState>,
  matches: Map<number, Set<number>>
) {
  const newMatches = new Map([...matches.entries()]);

  // Remove all rows that have been accepted.
  for (const acceptedRowIdx of draft.acceptedRowIdxToLocationIds.keys()) {
    newMatches.delete(acceptedRowIdx);
  }

  // Remove all locations that have been accepted.
  const acceptedLocationIds = makeLocationIdSet(
    draft.acceptedRowIdxToLocationIds
  );

  for (const [rowIdx, locIds] of newMatches.entries()) {
    for (const locId of locIds) {
      if (acceptedLocationIds.has(locId)) {
        locIds.delete(locId);
      }
    }

    if (locIds.size === 0) {
      newMatches.delete(rowIdx);
    }
  }

  // Determine which locations appear multiple times and remove those
  // rows.
  const overmatchedLocationIds = findOvermatchedLocationIds(newMatches);

  for (const [rowId, locIds] of newMatches.entries()) {
    for (const locId of locIds) {
      if (overmatchedLocationIds.has(locId)) {
        newMatches.delete(rowId);
      }
    }
  }

  // Necessary to know the existing row matches for locations so that we can
  // move them to new rows.
  const matchedLocationIdToRowIdx = makeLocationIdToRowIdxMap(
    draft.matchedRowIdxToLocationIds
  );

  for (const [rowIdx, locIds] of newMatches.entries()) {
    const newMatchedLocationIdsForRow = new Set<number>();

    for (const locId of locIds) {
      // If we already have this location matched, remove the old match
      // so we can add the new one.
      if (matchedLocationIdToRowIdx.has(locId)) {
        const oldRowIdx = matchedLocationIdToRowIdx.get(locId)!;
        const oldLocIds = draft.matchedRowIdxToLocationIds.get(oldRowIdx)!;

        oldLocIds.delete(locId);

        if (oldLocIds.size === 0) {
          // Maintains the invariant that rows aren’t in the state maps
          // if they’re not matched.
          draft.matchedRowIdxToLocationIds.delete(oldRowIdx);
        }
      }

      newMatchedLocationIdsForRow.add(locId);
    }

    if (newMatchedLocationIdsForRow.size) {
      draft.matchedRowIdxToLocationIds.set(rowIdx, newMatchedLocationIdsForRow);
    } else {
      draft.matchedRowIdxToLocationIds.delete(rowIdx);
    }
  }
}

/**
 * Handles setting the matches for a specific row.
 *
 * If the row has been “accepted,” this un-accepts it.
 *
 * If the new matches specify a location that is accepted somewhere else, this
 * errors. If it has been just matched somewhere else, this removes it from that
 * row.
 */
function handleSetRowMatchAction(
  draft: Draft<LocationLinkageState>,
  rowIdx: number,
  locationIds: Set<number>
) {
  // If we’re setting an accepted row, un-accept it.
  if (draft.acceptedRowIdxToLocationIds.has(rowIdx)) {
    draft.acceptedRowIdxToLocationIds.delete(rowIdx);
  }

  const acceptedLocationIds = makeLocationIdSet(
    draft.acceptedRowIdxToLocationIds
  );
  const locationIdToRowIdx = makeLocationIdToRowIdxMap(
    draft.matchedRowIdxToLocationIds
  );

  for (const locId of locationIds) {
    // Can’t match a location that’s already accepted.
    if (acceptedLocationIds.has(locId)) {
      throw new Error(`Trying to set accepted location id ${locId} as matched`);
    }

    // Matching a location to a different row deletes it from the old row.
    if (locationIdToRowIdx.has(locId)) {
      const previousRowIdx = locationIdToRowIdx.get(locId)!;
      const previousLocIds =
        draft.matchedRowIdxToLocationIds.get(previousRowIdx)!;

      previousLocIds.delete(locId);

      if (previousLocIds.size === 0) {
        draft.matchedRowIdxToLocationIds.delete(previousRowIdx);
      }
    }
  }

  if (locationIds.size > 0) {
    draft.matchedRowIdxToLocationIds.set(rowIdx, locationIds);
  } else {
    draft.matchedRowIdxToLocationIds.delete(rowIdx);
  }
}

/**
 * Returns a Set of all of the values in the given map.
 */
export function makeLocationIdSet(
  rowIdxToLocationIds: Immutable<Map<number, Set<number>>>
) {
  return Sets.union(...[...rowIdxToLocationIds.values()]);
}

/**
 * Returns a set of location IDs that appear more than once in the values of the
 * map.
 */
function findOvermatchedLocationIds(
  rowIdxToLocationIds: Map<number, Set<number>>
) {
  const newLocationIds = new Set<number>();
  const overmatchedLocationIds = new Set<number>();

  for (const locIds of rowIdxToLocationIds.values()) {
    for (const locId of locIds) {
      if (newLocationIds.has(locId)) {
        overmatchedLocationIds.add(locId);
      }

      newLocationIds.add(locId);
    }
  }

  return overmatchedLocationIds;
}

/**
 * We need a map from location ID to row index so that we can move a location
 * from its old row if it’s matched in the new data.
 *
 * This returns a 1-to-1 Map because we maintain that any location is only
 * matched to a single row (there may be duplicate values across keys, though,
 * since the same row could match to multiple locations).
 */
function makeLocationIdToRowIdxMap(
  matchedRowIdxToLocationIds: Map<number, Set<number>>
) {
  const matchedLocationIdToRowIdx = new Map<number, number>();

  for (const [rowIdx, locIds] of matchedRowIdxToLocationIds) {
    for (const locId of locIds) {
      if (matchedLocationIdToRowIdx.has(locId)) {
        // Shouldn’t come up, but let’s guard to catch bugs.
        throw new Error(
          `STATE INVARIANT ERROR: ${locId} in state for both ${rowIdx} and ${matchedLocationIdToRowIdx.get(
            locId
          )}`
        );
      }

      matchedLocationIdToRowIdx.set(locId, rowIdx);
    }
  }

  return matchedLocationIdToRowIdx;
}

export async function* matchLocations(
  locationsById: Immutable<Map<number, AssignmentLocation>>,
  csvResults: Papa.ParseResult<{
    [key: string]: string;
  }>,
  csvColumnToMatchingColumn: { [col: string]: LocationMatchingColumn },
  options: {
    /**
     * Number of ms before we yield to the main thread, so that linkage doesn’t
     * block UI.
     */
    pauseIntervalMs: number;
  }
): AsyncGenerator<undefined, LocationMatchingResults | null> {
  if (Object.entries(csvColumnToMatchingColumn).length === 0) {
    return null;
  }

  // Make sure that we don’t run until the next full event loop tick, so that we
  // don’t hold up rendering or anything else in the first go through the loop.
  await new Promise((resolve) => window.setTimeout(resolve, 0));

  let lastPauseMs = Date.now();

  const locationMatchingCache = makeLocationMatchingCache(
    locationsById.values()
  );

  const matchingColumnToCsvColumn: {
    [col in LocationMatchingColumn]?: string;
  } = {};

  for (const [csvCol, matchingCol] of Object.entries(
    csvColumnToMatchingColumn
  )) {
    matchingColumnToCsvColumn[matchingCol] = csvCol;
  }

  const results: LocationMatchingResults = {
    recommendedRowIdToLocationIds: new Map(),

    matchedRowIdxToLocationIds: new Map(),
    matchedLocationIdToRowIdxs: new Map(),
  };

  for (let rowIdx = 0; rowIdx < csvResults.data.length; ++rowIdx) {
    const row = csvResults.data[rowIdx]!;

    /**
     * Collection of Sets of location IDs that match the “precise,” non-fuzzy
     * matching values (ZIP, county, city).
     */
    const candidateLocationIdSets: Set<number>[] = [];
    /** Collection of Sets that match all values */
    const matchedLocationIdSets: Set<number>[] = [];

    for (const [csvCol, linkageCol] of Object.entries(
      csvColumnToMatchingColumn
    )) {
      const value = (row[csvCol] ?? '').toLowerCase();
      const locIds = locationMatchingCache[linkageCol].get(value) ?? new Set();

      matchedLocationIdSets.push(locIds);

      if (!FUZZY_MATCHING_COLUMNS.includes(linkageCol)) {
        candidateLocationIdSets.push(locIds);
      }
    }

    const candidateLocationIds = Sets.intersect(...candidateLocationIdSets);
    const matchingLocationIds = Sets.intersect(...matchedLocationIdSets);

    if (matchingLocationIds.size > 0) {
      results.matchedRowIdxToLocationIds.set(rowIdx, matchingLocationIds);

      for (const locId of matchingLocationIds) {
        if (!results.matchedLocationIdToRowIdxs.has(locId)) {
          results.matchedLocationIdToRowIdxs.set(locId, new Set());
        }

        results.matchedLocationIdToRowIdxs.get(locId)!.add(rowIdx);
      }
    }

    if (candidateLocationIds.size > 0) {
      results.recommendedRowIdToLocationIds.set(rowIdx, candidateLocationIds);
    }

    const nowMs = Date.now();

    if (nowMs - lastPauseMs > options.pauseIntervalMs) {
      await new Promise((resolve) => window.setTimeout(resolve, 0));
      // Yielding allows this process to be stopped if we’re no longer
      // interested in the result. If the component has moved on, it won’t
      // call next() and iteration will stop.
      yield undefined;
      lastPauseMs = Date.now();
    }
  }

  return results;
}

/**
 * Pre-computes lookup tables to speed up the linkage process when we’re
 * linking by things we can look up exactly.
 *
 * Makes the process not O(n^2).
 */
export function makeLocationMatchingCache(
  locations: IterableIterator<AssignmentLocation>
): LocationIdsByMatchingColumns {
  const out: LocationIdsByMatchingColumns = {
    id: new Map(),
    countyName: new Map(),
    address: new Map(),
    city: new Map(),
    name: new Map(),
    zip: new Map(),
  };

  for (const {
    id,
    county: { name: countyName },
    city,
    address,
    name,
    zipcode,
  } of locations) {
    const values: LocationMatchingValues = {
      id: id.toString(),
      countyName,
      city,
      address,
      name,
      zip: zipcode,
    };

    for (let [key, value] of Object.entries(values)) {
      const linkageColumn = key as LocationMatchingColumn;

      if (!value) {
        continue;
      }

      value = value.toLowerCase();

      if (!out[linkageColumn].has(value)) {
        out[linkageColumn].set(value, new Set());
      }

      out[linkageColumn].get(value)!.add(id);
    }
  }

  return out;
}

/**
 * Returns the {@link ApiLocationBulkUpdate} values derived from the
 * currently-accepted matches.
 */
export function calculateBulkLocationUpdates(
  locationLinkageState: Pick<
    LocationLinkageState,
    'acceptedRowIdxToLocationIds'
  >,
  csvResults: Papa.ParseResult<{ [key: string]: string }>,
  csvColumnToLinkageColumn: {
    [col: string]: LocationMatchingColumn | LocationMetadataColumn;
  }
): ApiLocationBulkUpdate[] {
  return [
    ...locationLinkageState.acceptedRowIdxToLocationIds.entries(),
  ].flatMap<ApiLocationBulkUpdate>(([rowIdx, locationIds]) => {
    const row = csvResults.data[rowIdx]!;

    /**
     * Shared update values for every location this row matched
     * to.
     */
    const update: Omit<ApiLocationBulkUpdate, 'id'> = {};

    for (const csvColumn in csvColumnToLinkageColumn) {
      switch (csvColumnToLinkageColumn[csvColumn]) {
        case 'rank': {
          const num = parseInt(row[csvColumn] ?? '');
          update.state_rank = isNaN(num) ? null : num;
        }
      }
    }

    return [...locationIds].map((locId) => ({
      id: locId,
      ...update,
    }));
  });
}
