import cx from 'classnames';
import React from 'react';
import { AriaTagProps, useFocusRing, useTag } from 'react-aria';
import { ListState } from 'react-stately';

import { IconButton } from './IconButton';

/**
 * A single rendered tag.
 *
 * @see TagGroup
 * @see TagComboBox
 */
export function Tag<T>({
  size = 'default',
  state,
  ...props
}: AriaTagProps<T> & {
  state: ListState<T>;
  size?: 'small' | 'default' | undefined;
}) {
  const tagRef = React.useRef(null);

  const { focusProps, isFocusVisible } = useFocusRing({ within: true });
  const {
    rowProps,
    gridCellProps,
    removeButtonProps,
    allowsRemoving,
    isSelected,
  } = useTag(props, state, tagRef);

  return (
    <div
      ref={tagRef}
      {...rowProps}
      {...focusProps}
      data-focus-visible={isFocusVisible}
      className={cx(
        'flex cursor-default items-center rounded-full leading-none',
        {
          'py-2 px-4 text-base': size === 'default',
          'py-1 px-3 text-sm': size === 'small',
          'bg-gray-200 text-gray-700': !isSelected,
          'bg-primary text-white': isSelected,
        }
      )}
    >
      <div {...gridCellProps} className="flex items-center gap-2">
        {props.item.rendered}

        {allowsRemoving && (
          <IconButton icon="close" size="small" {...removeButtonProps} />
        )}
      </div>
    </div>
  );
}
