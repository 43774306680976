import moment from 'moment';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import { DateBadge } from '../../components/common';

import { ActionButton } from '../../components/form/ActionButton';
import { useStateContactInfo } from '../../components/hooks/lbj-data';
import { State } from '../../constants';

import {
  DateMonth,
  DateString,
  DATE_STRING_FORMAT,
  formatTime,
  FULL_MONTHS,
  TimeString,
} from '../../services/common';
import { ApiUser } from '../../services/user-service';
import { UserMappedAssignment } from '../../utils/assignment/map-user-to-assignments';
import { MapFromJs } from '../../utils/types';

const ASSIGNMENT_TYPES: { [key: string]: string } = {
  poll: 'Poll',
  board_of_elections: 'Board of Elections',
  boiler_room: 'Boiler Room',
  hotline_center: 'Hotline Center',
};
const linkClasses = 'text-hyperlink underline';

function formatTimeRange(startTime: TimeString, endTime: TimeString) {
  const start = formatTime(startTime);
  const end = formatTime(endTime);
  let timeRange;

  // Removes repeated am or pm, e.g. 1 pm - 5 pm => 1-5 pm
  if (start.split(' ')[1] == end.split(' ')[1]) {
    timeRange = `${start.split(' ')[0]}–${end.split(' ')[0]} ${
      start.split(' ')[1]
    }`;
  } else {
    timeRange = `${start}–${end}`;
  }

  // Removes spaces because formatTime() uses a library function that adds a space
  // between the time and am/pm.
  return timeRange.replaceAll(' ', '');
}

function groupAndSortAssignmentsByDate(
  assignments: Array<UserMappedAssignment>
) {
  type DateGroups = { [key: DateString]: Array<UserMappedAssignment> };
  const dateGroups: DateGroups = {};

  assignments.forEach((assignment) => {
    const dateString = assignment.shift_date;

    if (dateGroups[dateString]) {
      const dateGroup = dateGroups[dateString]!;
      dateGroup.push(assignment);
    } else {
      dateGroups[dateString] = [assignment];
    }
  });

  const dates = Object.keys(dateGroups).sort() as DateString[];

  return dates.map((date) => {
    return { date: date, assignments: dateGroups[date]! };
  });
}

const HelpEmail: React.FunctionComponent<{
  state: State | null | undefined;
}> = (props) => {
  const { state } = props;
  const contactInfoMap = useStateContactInfo() || {};

  let contactInfo;
  if (state && contactInfoMap[state]?.contact) {
    contactInfo = contactInfoMap[state]?.contact;
  } else {
    contactInfo = 'lbj-help@dnc.org';
  }

  return (
    <a
      className={`${linkClasses} whitespace-nowrap`}
      href={`mailto:${contactInfo}`}
    >
      {contactInfo}
    </a>
  );
};

const MessageBox: React.FunctionComponent<{
  hasAssignments: boolean;
  assignmentState: State;
}> = (props) => {
  const { hasAssignments, assignmentState } = props;

  return (
    <div
      className={
        'my-6 flex justify-between space-x-2 rounded-lg border-2 border-solid border-gray-200 p-4'
      }
    >
      <div className="material-icons text-hyperlink" aria-hidden="true">
        info
      </div>
      {hasAssignments ? (
        <div>
          <p className="text-base leading-relaxed">
            For assistance with your account or assignments, contact your State
            Voter Protection Coordinator at{' '}
            <HelpEmail state={assignmentState} />.
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <p className="text-base leading-relaxed">
            You do not have any assignments, or your assignments may not be
            loaded yet.
          </p>
          <p className="text-base leading-relaxed">
            For technical support, contact <HelpEmail state={assignmentState} />
            .
          </p>
        </div>
      )}
    </div>
  );
};

const AddressWithMapLink: React.FunctionComponent<{
  assignment: UserMappedAssignment;
}> = (props) => {
  const { assignment } = props;
  const type = assignment.type;
  const baseUrl = 'https://www.google.com/maps/dir/?api=1&destination=';

  let locationName, address, city, state, county, zipcode, coordinates;
  if (type === 'boiler_room' || type === 'hotline_center') {
    locationName = assignment.boiler_room?.name;
    address = assignment.boiler_room?.address;
    city = assignment.boiler_room?.city;
    state = assignment.boiler_room?.state;
    zipcode = assignment.boiler_room?.zipcode;
  } else if (type === 'board_of_elections') {
    locationName = assignment.board_of_elections?.name;
    address = assignment.board_of_elections?.address;
    city = assignment.board_of_elections?.city;
    zipcode = assignment.board_of_elections?.zipcode;
    coordinates = assignment.board_of_elections?.coordinates;
  } else if (type === 'poll') {
    locationName = assignment.location?.name;
    address = assignment.location?.address;
    city = assignment.location?.city;
    county = assignment.location?.county;
    zipcode = assignment.location?.zipcode;
    coordinates = assignment.location?.coordinates;
  }

  const googleMapLink =
    address && city && zipcode
      ? baseUrl + encodeURIComponent([address, city, zipcode].join(' '))
      : coordinates && coordinates.length == 2
      ? baseUrl + encodeURIComponent(coordinates.slice().reverse().join(',')) // coordinates property is [lng,lat]
      : address && city && state
      ? baseUrl + encodeURIComponent([address, city, state].join(' '))
      : null;

  const addressString = [address, city, state].filter((el) => el).join(', ');

  return (
    <div className="flex flex-col gap-1 text-base">
      <div className="font-bold">{locationName}</div>
      <div>
        {googleMapLink ? (
          <a
            className={linkClasses}
            href={googleMapLink}
            target="_blank"
            rel="noreferrer"
          >
            {addressString}
          </a>
        ) : (
          <span>{addressString}</span>
        )}
        {county?.name && (
          <div className={'md:inline'}>
            <span className={'hidden md:inline'}>{' — '}</span>
            <span>{`${county.name} County`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const AssignmentListItem: React.FunctionComponent<{
  assignment: UserMappedAssignment;
}> = ({ assignment }) => {
  const today = moment().format(DATE_STRING_FORMAT) as DateString;
  const navigate = useNavigate();
  const shiftMonth =
    FULL_MONTHS[assignment.shift_date.split('-')[1] as DateMonth];
  const shiftDay = parseInt(assignment.shift_date.split('-')[2]!); // trims leading zero

  return (
    <div className={'flex'}>
      <div className={'ml-4 flex w-full flex-col justify-between space-y-2'}>
        <h2 className={'flex flex-col gap-2 md:mb-0'}>
          <div className={'text-xl font-bold leading-none'}>
            {`${shiftMonth} ${shiftDay}: ${formatTimeRange(
              assignment.start_time,
              assignment.end_time
            )}`}
          </div>
          <div className={'text-xs font-bold'}>{`${
            ASSIGNMENT_TYPES[assignment.type]
          } Shift`}</div>
        </h2>
        <AddressWithMapLink assignment={assignment} />
        {assignment.shift_date === today && (
          <div
            className={
              'flex flex-col space-y-2 space-x-0 md:flex-row md:justify-between md:space-x-2 md:space-y-0'
            }
          >
            <ActionButton
              role="secondary"
              onPress={() => {
                navigate('/checkin');
              }}
              elementProps={{ role: 'link' }}
              full
              icon="check_circle"
              iconPosition="end"
            >
              Check in
            </ActionButton>
            <ActionButton
              role="secondary"
              onPress={() => {
                navigate({ pathname: '/issues/new', search: 'mobile=1' });
              }}
              elementProps={{ role: 'link' }}
              full
              icon="add_circle"
              iconPosition="end"
            >
              Log issue
            </ActionButton>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Groups all assignments for a given date.
 */
const DateBlock: React.FunctionComponent<{
  date: DateString;
  assignments: Array<UserMappedAssignment>;
}> = (props) => {
  const { date, assignments } = props;
  return (
    <div className={'flex border-t-2 border-t-gray-300 py-8'}>
      <DateBadge shiftDate={date} />

      <ol
        className={
          'flex w-full list-none flex-col justify-between space-y-8 text-base'
        }
      >
        {assignments.map((a) => (
          <li key={a.id}>
            <AssignmentListItem assignment={a} />
          </li>
        ))}
      </ol>
    </div>
  );
};

const VolunteerLandingView: React.FunctionComponent<{
  assignments: Array<UserMappedAssignment>;
  user: MapFromJs<ApiUser>;
}> = (props) => {
  const { assignments, user } = props;
  const name = user.get('first_name');
  const assignmentsByDate = groupAndSortAssignmentsByDate(assignments);
  const assignmentState = user.get('assignment_state');

  return (
    <main className="mx-auto w-11/12 max-w-2xl text-gray-700 md:w-9/12">
      <h1 className="my-6">
        <div className="text-lg font-bold">Welcome, {name}!</div>
        <div className="text-2xl font-bold">Your Assignments</div>
      </h1>

      <MessageBox
        hasAssignments={assignments.length > 0}
        assignmentState={assignmentState}
      />

      <ol className={'list-none'}>
        {assignmentsByDate.map((dateGroup) => {
          return (
            <li key={dateGroup.date}>
              <DateBlock
                date={dateGroup.date}
                assignments={dateGroup.assignments}
              />
            </li>
          );
        })}
      </ol>
    </main>
  );
};

export default VolunteerLandingView;
