import type { Node as CollectionNode } from '@react-types/shared';
import React from 'react';
import {
  AriaButtonProps,
  AriaMenuOptions,
  AriaMenuProps,
  AriaMenuTriggerProps,
  mergeProps,
  useMenu,
  useMenuItem,
  useMenuSection,
  useMenuTrigger,
} from 'react-aria';
import {
  MenuTriggerProps,
  MenuTriggerState,
  TreeProps,
  TreeState,
  useMenuTriggerState,
  useTreeState,
} from 'react-stately';

import { PopoverView } from '../layout';

import {
  ListView,
  ListItemView,
  ListSectionView,
  ListSelectionStyle,
  VirtualizeCollection,
  VirtualizedItemProps,
} from './ListInternals';

export type MenuProps<T> = AriaMenuProps<T> &
  Omit<MenuTriggerProps, 'trigger'> &
  Omit<AriaMenuTriggerProps, 'trigger'> & {
    /**
     * External ref that will be used for the trigger button. If not provided
     * this component uses one of its own.
     */
    triggerRef?: React.RefObject<Element> | undefined;
    /**
     * Render an element that will trigger the menu to open. This must pass
     * `ref` along so that it gets associated with the triggering element so the
     * menu can be positioned.
     */
    trigger: (
      /**
       * Props to apply to the button.
       */
      props: AriaButtonProps,
      /**
       * Ref to apply to the trigger element (for positioning). Uses `any`
       * because ref typing is kind of a mess.
       */
      ref: React.MutableRefObject<any>
    ) => React.ReactElement;

    selectionStyle: ListSelectionStyle;
    shouldVirtualize?: boolean | undefined;
    closeOnSelect?: boolean | undefined;
    itemHeight?: number | undefined;
  };

/**
 * Function that receives the `<ListView>` component, in case a caller wants
 * to wrap it in other markup (or get access to the `TreeState`).
 */
export type MenuContentRenderer<T extends {}> = (
  listViewEl: JSX.Element,
  menuTriggerState: MenuTriggerState,
  listState: TreeState<T>
) => JSX.Element | null;

/**
 * Component for a menu, with a trigger element to open it.
 *
 * Menus are built using the Collections interface for React ARIA. Note that all
 * menu items are in the same collection so that you can, say, down arrow from
 * one item to the next.
 *
 * (This does mean that if you have a menu with several distinct groups of items
 * it in you will need to manage their mutual selection behavior yourself.)
 *
 * Use the `trigger` prop to handle rendering the button (based on the
 * provided props, and setting the ref so that the menu can be positioned).
 * Provide the menu items either as `<Item>` children, or as values with the
 * `items` prop and a render function as the children.
 *
 * @see https://react-spectrum.adobe.com/react-stately/collections.html
 */
export function Menu<T extends {}>({
  triggerRef,
  trigger,
  selectionStyle,
  contentRenderer,
  emptyFallbackEl,
  shouldVirtualize,
  itemHeight,
  ...props
}: MenuProps<T> & {
  contentRenderer?: MenuContentRenderer<T> | undefined;
  emptyFallbackEl?: React.ReactNode | undefined;
}) {
  const triggerState = useMenuTriggerState(props);

  const localTriggerRef = React.useRef(null);
  triggerRef = triggerRef ?? localTriggerRef;

  const { menuTriggerProps, menuProps } = useMenuTrigger<T>(
    props,
    triggerState,
    triggerRef
  );

  return (
    <>
      {trigger(
        { ...menuTriggerProps, isDisabled: !!props.isDisabled },
        triggerRef
      )}

      {triggerState.isOpen && (
        <PopoverView
          state={triggerState}
          triggerRef={triggerRef}
          placement="bottom start"
        >
          <MenuContent
            {...props}
            {...menuProps}
            selectionStyle={selectionStyle}
            shouldVirtualize={shouldVirtualize}
            itemHeight={itemHeight}
            emptyFallbackEl={emptyFallbackEl}
            triggerState={triggerState}
            contentRenderer={contentRenderer}
          />
        </PopoverView>
      )}
    </>
  );
}

/**
 * Popover contents of the menu rendered by {@link Menu}.
 *
 * Statefully manages the menu contents with `useTreeState`.
 */
export function MenuContent<T extends {}>({
  selectionStyle,
  triggerState,
  contentRenderer,
  emptyFallbackEl,
  shouldVirtualize = false,
  itemHeight,
  ...props
}: AriaMenuOptions<T> &
  TreeProps<T> & {
    selectionStyle: ListSelectionStyle;
    triggerState: MenuTriggerState;
    emptyFallbackEl?: React.ReactNode | undefined;
    contentRenderer?: MenuContentRenderer<T> | undefined;
    shouldVirtualize?: boolean | undefined;
    itemHeight?: number | undefined;
  }) {
  const state = useTreeState(props);

  const menuRef = React.useRef(null);
  const { menuProps } = useMenu(
    {
      ...props,
      isVirtualized: shouldVirtualize,
    },
    state,
    menuRef
  );

  const renderItem = (
    item: CollectionNode<T>,
    props?: VirtualizedItemProps,
    measureElement?: (el: HTMLElement | null) => void
  ) =>
    item.type === 'section' ? (
      <MenuSection
        key={item.key}
        section={item}
        state={state}
        selectionStyle={selectionStyle}
        isVirtualized={shouldVirtualize}
        virtualizedItemProps={props}
        measureElement={measureElement}
      />
    ) : (
      <MenuItem
        key={item.key}
        item={item}
        state={state}
        selectionStyle={selectionStyle}
        virtualizedItemProps={props}
        measureElement={measureElement}
      />
    );

  const menuEl = (
    <ListView
      ref={menuRef}
      // “inherit” for max-height will give us the max-height that react-aria
      // adds to the popover content (so we don’t go past the bottom of the
      // window).
      className="max-h-[inherit] min-w-[16rem]"
      {...menuProps}
    >
      {state.collection.size === 0 && emptyFallbackEl}

      {shouldVirtualize ? (
        <VirtualizeCollection
          scrollingRef={menuRef}
          collection={state.collection}
          renderItem={renderItem}
          itemHeight={itemHeight}
        />
      ) : (
        [...state.collection].map((item) => renderItem(item))
      )}
    </ListView>
  );

  return contentRenderer
    ? contentRenderer(menuEl, triggerState, state)
    : menuEl;
}

/**
 * Component for a single item in a {@link Menu}.
 *
 * Delegates to {@list ListItemView}.
 */
function MenuItem<T extends {}>({
  item,
  state,
  selectionStyle,
  virtualizedItemProps,
  measureElement,
}: {
  item: CollectionNode<T>;
  state: TreeState<T>;
  selectionStyle: ListSelectionStyle;
  virtualizedItemProps?: VirtualizedItemProps | undefined;
  measureElement?: ((el: HTMLElement | null) => void) | undefined;
}): React.ReactElement {
  const itemRef = React.useRef<HTMLElement | null>(null);
  const { menuItemProps, isFocused, isSelected, isDisabled } = useMenuItem(
    { key: item.key },
    state,
    itemRef
  );

  // We need to both maintain the `itemRef` for `useMenuItem` and also we need
  // to call `measureElement`.
  const combinedRef = React.useCallback(
    (el: HTMLElement | null) => {
      itemRef.current = el;
      measureElement?.(el);
    },
    [measureElement]
  );

  return (
    <ListItemView
      ref={combinedRef}
      isDisabled={isDisabled}
      isSelected={isSelected}
      // We don’t show a separate focus ring, since the menu treats focus/hover
      // as the same.
      isHovered={isFocused}
      selectionStyle={selectionStyle}
      {...mergeProps(menuItemProps, virtualizedItemProps ?? {})}
    >
      {item.rendered}
    </ListItemView>
  );
}

/**
 * Section for a menu rendered with {@link Menu}.
 *
 * Delegates actual rendering to {@link ListSectionView}.
 */
function MenuSection<T extends {}>({
  section,
  state,
  selectionStyle,
  isVirtualized,
  virtualizedItemProps,
  measureElement,
}: {
  section: CollectionNode<T>;
  state: TreeState<T>;
  selectionStyle: ListSelectionStyle;
  isVirtualized?: boolean | undefined;
  virtualizedItemProps?: VirtualizedItemProps | undefined;
  measureElement?: ((el: HTMLElement | null) => void) | undefined;
}): React.ReactElement {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    // jank due to our pedantic handling of `undefined`
    ...(section['aria-label']
      ? {
          'aria-label': section['aria-label'],
        }
      : {}),
  });

  return (
    <ListSectionView
      itemProps={mergeProps(itemProps, virtualizedItemProps ?? {})}
      itemRef={measureElement}
      headingProps={headingProps}
      groupProps={groupProps}
      heading={section.rendered}
      hideSeparator={isVirtualized}
      isFirst={section.key === state.collection.getFirstKey()}
    >
      {/* If the list is virtualized then we don’t nest the section’s children; they’ll be rendered separately. */}
      {!isVirtualized &&
        [...section.childNodes].map((node) => (
          <MenuItem
            key={node.key}
            item={node}
            state={state}
            selectionStyle={selectionStyle}
          />
        ))}
    </ListSectionView>
  );
}
