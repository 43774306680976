import cx from 'classnames';
import React from 'react';

import LoadingOverlay from '../../components/presentational/lbj/loading';
import { PhotoUploadData } from '../../modules/issue/reducers';
import { ApiIssuePhoto } from '../../services/issue-service';

import PhotoLightbox from './photo-lightbox';

const KB = 1000;
const MB = 1000 * KB;
const MAX_DIMENSION = 1280;

export type PhotoUploadBlob = Blob & { lastModifiedDate?: Date; name?: string };

export default class IssuePhotos extends React.Component<
  {
    uploadIsPending: boolean;
    canAddPhoto: boolean;
  } & (
    | {
        isNewIssue: true;
        photos: PhotoUploadData[] | undefined | null;
        onPhotoAdd: (photo: PhotoUploadData) => void;
        onPhotoDelete: (photo: PhotoUploadData) => void;
      }
    | {
        isNewIssue: false;
        photos: ApiIssuePhoto[] | undefined | null;

        onPhotoAdd: (photo: PhotoUploadBlob) => void;
        onPhotoDelete: (photo: ApiIssuePhoto) => void;
      }
  ),
  { photo: ApiIssuePhoto | null; isProcessingPhoto: boolean }
> {
  constructor(props: IssuePhotos['props']) {
    super(props);
    this.state = { photo: null, isProcessingPhoto: false };
  }

  getResizedImage(image: HTMLImageElement) {
    const { height, width } = image;

    let toHeight, toWidth;
    if (height < MAX_DIMENSION && width < MAX_DIMENSION) {
      toHeight = height;
      toWidth = width;
    } else if (height > width) {
      // portrait
      toHeight = MAX_DIMENSION;
      toWidth = (width * MAX_DIMENSION) / height;
    } else {
      // landscape
      toWidth = MAX_DIMENSION;
      toHeight = (height * MAX_DIMENSION) / width;
    }

    // resize image
    const canvas = document.createElement('canvas');
    canvas.width = toWidth;
    canvas.height = toHeight;
    const ctx = canvas.getContext('2d');
    ctx!.drawImage(image, 0, 0, toWidth, toHeight);
    return canvas.toDataURL('image/jpeg');
  }

  uploadInputRef = React.createRef<HTMLInputElement>();

  /**
   * @param image Is assumed to be a data URL, so it should be a MIME type, a
   * comma, then base64-encoded data.
   */
  buildBlob(image: string): PhotoUploadBlob {
    const binStr = atob(image.split(',')[1]!);
    const arr = new Uint8Array(binStr.length);
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: 'image/jpeg' });
  }

  handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    this.setState({ isProcessingPhoto: true });
    const reader = new FileReader();
    reader.onload = () => {
      const src = reader.result;
      if (typeof src === 'string') {
        const img = new Image();
        img.onload = this.handleImage.bind(this, file);
        img.src = src;
        img.onerror = () => {
          this.setState({ isProcessingPhoto: false });
        };
      }
    };
    reader.readAsDataURL(file);
  }

  handleImage(original: File, e: Event) {
    const { isNewIssue, onPhotoAdd } = this.props;

    const image = this.getResizedImage(e.target as HTMLImageElement);
    const file = this.buildBlob(image);
    file.lastModifiedDate = new Date();
    file.name = original.name;

    if (!isNewIssue) {
      // file upload endpoint expects the file
      onPhotoAdd(file);
    } else {
      // issue creation upload expects raw base64 data
      onPhotoAdd({
        filename: file.name,
        filesize: file.size,
        header: image.split(',')[0]!,
        data: image.split(',')[1]!,
      });
    }

    this.setState({ isProcessingPhoto: false });

    if (this.uploadInputRef.current) {
      this.uploadInputRef.current.value = '';
    }
  }

  handleOpen(photo: ApiIssuePhoto) {
    this.setState({ photo });
  }

  handleClose() {
    this.setState({ photo: null });
  }

  renderFilesize(photo: PhotoUploadData) {
    let size, unit;

    if (photo.filesize / MB > 1) {
      unit = 'MB';
      size = photo.filesize / MB;
    } else {
      unit = 'KB';
      size = photo.filesize / KB;
    }

    if (size > 10) {
      size = Math.floor(size);
    } else {
      size = (Math.round(size * 10) / 10).toFixed(1);
    }

    return `(${size} ${unit})`;
  }

  renderNewIssuePhoto(
    onPhotoDelete: (photo: PhotoUploadData) => void,
    photo: PhotoUploadData,
    idx: number
  ) {
    const tooLarge = photo.filesize > 5 * MB;
    const errorMessage = tooLarge ? (
      <span className="errorMessage">Must be {'<'} 5 MB</span>
    ) : null;
    const src = [photo.header, photo.data].join(',');
    return (
      <div
        key={`photo-${idx}`}
        className={cx('photo-preview', { error: tooLarge })}
      >
        <img className="preview-image" src={src} />
        <span className="filename">{photo.filename}</span>
        <span className="filesize">{this.renderFilesize(photo)}</span>
        {errorMessage}
        <span className="links">
          <a
            className="remove"
            onClick={() => {
              onPhotoDelete(photo);
              this.handleClose();
            }}
          >
            <img
              className="removeIcon"
              src={require('../../../img/close_black.svg')}
            />
          </a>
        </span>
      </div>
    );
  }

  renderPhoto(photo: ApiIssuePhoto) {
    const style = {
      backgroundImage: `url(${photo.photo})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };

    return (
      <a
        className="gallery-image-link"
        onClick={this.handleOpen.bind(this, photo)}
        key={photo.id}
      >
        <div className={cx('gallery-image')} style={style} />
      </a>
    );
  }

  renderPhotos() {
    const { isNewIssue, photos, uploadIsPending } = this.props;
    const { isProcessingPhoto } = this.state;

    const spinner = !photos || isProcessingPhoto ? <LoadingOverlay /> : null;

    const placeholderPhoto = uploadIsPending ? (
      <div className="gallery-image-link pending">
        <div className={cx('gallery-image')} />
      </div>
    ) : null;

    if (!photos) {
      return <div className="issue-image-gallery empty">{spinner}</div>;
    }

    if (!photos.length) {
      return <div className="empty">{spinner}</div>;
    }

    let error = null;
    if (isNewIssue) {
      const cumulativeFileSize = photos.reduce((a, b) => a + b.filesize, 0);
      if (cumulativeFileSize > 9.5 * MB) {
        error = (
          <div className="photos-error">
            You have exceeded the available request size. Please consider
            uploading smaller photos or adding photos one by one.
          </div>
        );
      }
    }

    return (
      <div className={cx({ 'issue-image-gallery': !isNewIssue })}>
        {placeholderPhoto}
        {isNewIssue &&
          photos.map(
            this.renderNewIssuePhoto.bind(this, this.props.onPhotoDelete)
          )}
        {!isNewIssue && photos.map(this.renderPhoto.bind(this))}
        {error}
        {spinner}
      </div>
    );
  }

  renderInput() {
    const label = this.props.isNewIssue ? 'Add a photo' : 'Upload photo';
    return (
      <div className="file-upload">
        + {label}
        <input
          ref={this.uploadInputRef}
          className="file-upload-input"
          type="file"
          accept="image/*;capture=camera, image/jpg, image/jpeg, image/png"
          onChange={this.handleFileSelect.bind(this)}
        />
      </div>
    );
  }

  renderLightbox() {
    return (
      this.state.photo && (
        <PhotoLightbox
          isVisible
          photo={this.state.photo}
          onRequestClose={this.handleClose.bind(this)}
          onRequestDelete={(photo) => {
            // Need this check for the types, but the lightbox is only used for
            // non-new issues.
            if (!this.props.isNewIssue) {
              this.props.onPhotoDelete(photo);
            }
            this.handleClose();
          }}
        />
      )
    );
  }

  render() {
    const { canAddPhoto } = this.props;
    return (
      <div className="issue-photos">
        {this.renderPhotos()}
        {canAddPhoto && this.renderInput()}
        {this.renderLightbox()}
      </div>
    );
  }
}
