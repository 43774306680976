import * as I from 'immutable';
import moment from 'moment';

import { lbjAppSections } from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';
import { filterFilters } from '../filters/common';

const { ISSUE } = lbjAppSections;

function filterIssueFilters<OutK extends keyof FiltersBySection['ISSUE']>(
  state: Pick<AppState, 'filters'>,
  keys: OutK[]
) {
  return filterFilters(state.filters.get(ISSUE), keys);
}

/**
 * Filters the map of current “Issue” filters down to what we know about, and
 * adds the derived `incident_time__gte` and `incident_time__lt` values.
 *
 * Also corrects nested filters (disallows specifying county if state isn’t set,
 * for example.)
 */
export default function mapStateToIssueFilters(
  state: Pick<AppState, 'filters'>
) {
  const outFilters = filterIssueFilters(state, [
    'from_permanent',
    'requires_followup',
    'state',
    'region',
    'county',
    'status',
    'precinct',
    'location',
    'priority',
    'qa_reviewed',
    'category',
    'sub_category',
    'category_2',
    'sub_category_2',
    'category_3',
    'sub_category_3',
    'source',
    'type__in',
    'scope',
    'voter_name',
    'voter_phone_number',
    'owner',
    'owner__isnull',
    'boiler_room',
    'created_by',
    'ordering',
    'offset',
    'size',
    'user',
    'search_term',
    'wait_time',
    'watchers',
    'vote_status',
    'boiler_room__level',
    'us_house',
    'state_house',
    'state_senate',
    'start_date',
    'end_date',
    'query_election_id',

    // Derived filter values
    'incident_time__gte',
    'incident_time__lt',
  ]);

  const startDate = outFilters.get('start_date');
  const endDate = outFilters.get('end_date');
  const timeStart = startDate ? moment(startDate).startOf('day').format() : '';
  const timeEnd = endDate
    ? moment(endDate).startOf('day').add(1, 'days').format()
    : '';

  const params: I.Map<string, any> = outFilters
    .filter((_, attr) => {
      if (attr === 'county' && !outFilters.get('state')) {
        return false;
      }

      if (attr === 'precinct' && !outFilters.get('county')) {
        return false;
      }

      if (attr === 'sub_category' && !outFilters.get('category')) {
        return false;
      }

      return true;
    })
    .toMap();

  return (
    params
      .set('incident_time__gte', timeStart)
      .set('incident_time__lt', timeEnd)
      // The index page for issues doesn’t show issues that are only Vote Tally
      // results.
      .set('exclude_category', 'Results|Vote Tally')
  );
}
