import _ from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import fluxStore from '../modules/flux-store';
import {
  getIssueAsync,
  getIssueCommentsAsync,
  getIssueDocumentsAsync,
  getIssueHistoryAsync,
  getIssuePhotosAsync,
  resetIssueInProgressData,
  getIssueElectionAsync,
  setIssueElection,
} from '../modules/issue/action-creators';
import { ExistingIssueInProgress } from '../modules/issue/reducers';
import { getCurrentUserAsync } from '../modules/user/action-creators';
import IssueDetail from '../pages/issue/issue-detail';
import NewIssueContainer from '../pages/issue/issue-new-container';
import { getInitialFields } from '../utils/issue/initial-fields';

export const IssueDetailRoute: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  // We know this will be defined because of the path pattern.
  const issueId = Number(useParams()['issueId']!);

  React.useEffect(() => {
    const { getState, dispatch } = fluxStore;

    // Using ! because we won’t be rendered unless there’s a current user.
    const userRole = getState().user.currentUser.userData!.get('role');

    dispatch(resetIssueInProgressData());
    dispatch(getIssueAsync(issueId)).then(() => {
      const appState = getState();
      const { issue } = appState;
      const issueInProgress =
        issue.issueInProgress.toJS() as ExistingIssueInProgress;

      const issueElectionId = issueInProgress.issue_election_id;
      const requestErred = issue.issueDetail.responseErred;

      if (requestErred) {
        navigateRef.current('/404');
        return;
      }

      dispatch(setIssueElection(issueElectionId));
    });
    dispatch(getIssueCommentsAsync(issueId));
    dispatch(getIssueDocumentsAsync(issueId));
    dispatch(getIssueHistoryAsync(userRole, issueId));
    dispatch(getIssuePhotosAsync(issueId));
    dispatch(getCurrentUserAsync());
  }, [issueId]);

  return <IssueDetail />;
};

export const NewIssueRoute: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  React.useEffect(() => {
    const { getState, dispatch } = fluxStore;
    const initialFields = getInitialFields(
      getState().user.currentUser.userData!
    );
    const userRole = initialFields.user_role;
    if (userRole === 'view_only') {
      navigateRef.current('/404');
      return;
    }
    const userState = initialFields.state;
    const electionState = userState || '';

    dispatch(resetIssueInProgressData());
    dispatch(getCurrentUserAsync());
    dispatch(getIssueElectionAsync(electionState));
  }, []);

  return <NewIssueContainer />;
};
