import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { LbjInputEvent } from '../../../decorators/input-props';
import { AppState } from '../../../modules/flux-store';
import { ApiUserTag } from '../../../services/lbj-shared-service';
import { MapFromJs } from '../../../utils/types';

import { extractExcludedTagName, isExcluded } from '../../../utils/user/tags';
import Autocomplete from '../../presentational/form/autocomplete';

/**
 * Picker for tags.
 *
 * Designed so that it can show a subset of tags, but will call its `onChange`
 * with all of the tags.
 */
export const UnconnectedTagPicker: React.FunctionComponent<{
  tagData: Immutable.List<MapFromJs<ApiUserTag>>;
  /**
   * All of the currently-selected tags, including those that won’t be visible
   * in this component due to `onlyTypes`/`excludes`.
   */
  allTags?: string[] | undefined;
  /**
   * Called when tags update. Includes every selected tag, and also all tags
   * from `allTags` that are hidden by `onlyTypes`/`excludes`.
   */
  onChange: (allTags: Immutable.Set<string>) => void;
  /**
   * If provided, filters `tagData` down to just the given types of tags.
   */
  onlyTypes?: string[];
  /**
   * The currently-selected tags, joined into a comma-separated string.
   *
   * TODO(fiona): This should be derived from `allTags`.
   */
  defaultValue?: string | null | undefined;
  /**
   * Tags to not show as selected or in the options.
   */
  excludes?: string[] | undefined;
  title?: string | undefined;
  name?: string | undefined;
  multi?: boolean | undefined;
  disabled?: boolean | undefined;
}> = ({
  tagData,
  onChange,
  onlyTypes = null,
  allTags = [],
  excludes = [],
  multi = true,
  title = 'Tags',
  name = 'tags',
  defaultValue,
  disabled,
}) => {
  const allTagsSet = Immutable.Set(allTags);

  // Filter the election’s tags to those which match the types specified
  // and transform the listData into a map of {name: display_name}
  const availableTagToTagNameMap = Immutable.Map<string, string>(
    tagData
      .filter(
        (tag) => _.isNull(onlyTypes) || _.includes(onlyTypes, tag.get('type'))
      )
      .filterNot((tag) => _.includes(excludes, tag.get('name')))
      .map((tag) => [tag.get('name'), tag.get('display_name')])
  );

  /**
   * Tags that are hidden because they’re not in `onlyTypes` or are excluded by
   * `excludes`.
   */
  const invisibleTags = allTagsSet.filter((tagName) => {
    // check to make sure that the tag is in the available set of tags
    // for this component. if the tag is excluded, we need to check
    // just the tag name
    return (
      !availableTagToTagNameMap.has(tagName) ||
      (isExcluded(tagName) &&
        !availableTagToTagNameMap.has(extractExcludedTagName(tagName)))
    );
  });

  const onTagsChange = ({ target }: LbjInputEvent<string | null>) => {
    const { value } = target;
    let selectedTags;

    if (!!value && value.length > 0) {
      selectedTags = Immutable.Set(value.split(','));
    } else {
      selectedTags = Immutable.Set([]);
    }

    return onChange(invisibleTags.union(selectedTags));
  };

  return (
    <Autocomplete
      name={name}
      title={title}
      onChange={onTagsChange}
      choices={availableTagToTagNameMap.toJSON()}
      alphaSort
      defaultValue={defaultValue ?? null}
      disabled={disabled ?? false}
      multi={multi}
      errors={[]}
    />
  );
};

export default connect((state: AppState) => {
  const tags = state.lbj.get('tags');
  const tagData = tags.get('listData');

  return {
    tagData,
  };
})(UnconnectedTagPicker);
