import { useVirtualizer } from '@tanstack/react-virtual';
import { Immutable } from 'immer';
import Papa from 'papaparse';
import React from 'react';
import { Item, Section } from 'react-stately';

import { ActionButton, Menu, TextButton } from '../../../components/form';
import { ApiLocationBulkUpdate } from '../../../services/assignment-service';
import * as AssignmentService from '../../../services/assignment-service';
import { useAsyncGenerator, useContinuity } from '../../../utils/hooks';
import * as Sets from '../../../utils/sets';
import { numberToStringWithCommas } from '../../../utils/strings';

import { AssignmentLocation } from '../assignment-state';

import {
  DEFAULT_LOCATION_LINKAGE_STATE,
  LOCATION_COLUMN_LABELS,
  LocationMatchingColumn,
  LocationLinkageDispatch,
  LocationMatchingResults,
  LocationLinkageState,
  matchLocations,
  locationLinkageReducer,
  makeLocationIdSet,
  LocationMetadataColumn,
  isLocationMatchingColumn,
  isLocationMetadataColumn,
  calculateBulkLocationUpdates,
} from './linkage-state';

/**
 * Content of {@link LocationMetadataDialog} when a CSV has been loaded and
 * we’re showing how it matches to locations.
 */
const LocationMetadataResults: React.FunctionComponent<{
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  csvResults: Papa.ParseResult<{ [key: string]: string }>;
  csvFileName: string | null;
  resetCsvFile: () => void;
  doClose: () => void;
  applyBulkUpdates: (updates: ApiLocationBulkUpdate[]) => void;
}> = ({
  locationsById,
  csvResults,
  resetCsvFile,
  csvFileName,
  doClose,
  applyBulkUpdates,
}) => {
  const [locationLinkageState, locationLinkageDispatch] = React.useReducer(
    locationLinkageReducer,
    DEFAULT_LOCATION_LINKAGE_STATE
  );

  const [isSaving, setIsSaving] = React.useState(false);
  const [savingError, setSavingError] = React.useState<string | null>(null);

  const [csvColumnToLinkageColumn, setCsvColumnToLinkageColumn] =
    React.useState<{
      [col: string]: LocationMatchingColumn | LocationMetadataColumn;
    }>({});

  const [tableSortColumn, setTableSortColumn] = React.useState(LBJ_ROW_ID_KEY);
  const [tableSortAscending, setTableSortAscending] = React.useState(true);

  const [linkageResultsGen, setLinkageResultsGen] =
    React.useState<null | ReturnType<typeof matchLocations>>(null);

  // Gets the results out of the generator. This does not yield useful
  // intermediate values. We have it set up as a generator just so that it’s
  // interruptable.
  const linkageResults = useAsyncGenerator(linkageResultsGen)?.value;

  /**
   * Filtered version of csvColumnToLinkageColumn that only includes the
   * “matching” values. We pull this out and keep it consistent w/ useContinuity
   * so that we only re-run {@link matchLocations} if the linkage values change
   * (rather than if the user later chooses what column the rankings are in).
   */
  const csvColumnToMatchingColumn = useContinuity(
    Object.fromEntries(
      Object.entries(csvColumnToLinkageColumn).filter(
        (el): el is [string, LocationMatchingColumn] =>
          isLocationMatchingColumn(el[1])
      )
    )
  );

  // Run linkage every time the data that feeds in to linkage changes.
  React.useEffect(() => {
    setLinkageResultsGen(
      matchLocations(locationsById, csvResults, csvColumnToMatchingColumn, {
        pauseIntervalMs: 50,
      })
    );
  }, [locationsById, csvResults, csvColumnToMatchingColumn]);

  // Once the generator has run to completion and we have linkage results,
  // update the “matched” map to include rows that map 1-to-1 to a location.
  React.useEffect(() => {
    if (linkageResults) {
      locationLinkageDispatch({
        type: 'IMPORT_LINKAGE_MATCHES',
        matches: linkageResults.matchedRowIdxToLocationIds,
      });
    }
  }, [linkageResults]);

  // True if the user has chosen output columns, meaning there’s something to
  // import.
  const hasOutputColumns = !!Object.entries(csvColumnToLinkageColumn).find(
    ([_, col]) => isLocationMetadataColumn(col)
  );

  return (
    <>
      <div className="mb-4 flex gap-8">
        <div className="min-w-0 flex-1 overflow-hidden overflow-ellipsis">
          <strong>Selected file:</strong> {csvFileName ?? 'unknown.csv'}
        </div>

        <div className="whitespace-nowrap">
          {numberToStringWithCommas(csvResults.data.length)} rows loaded
          <br />
          (for {numberToStringWithCommas(locationsById.size)} LBJ locations)
        </div>
      </div>

      {csvResults.errors.length > 0 && (
        <span>
          There were {numberToStringWithCommas(csvResults.errors.length)} errors
          parsing this file.
        </span>
      )}

      <LocationMetadataTable
        csvResults={csvResults}
        locationsById={locationsById}
        linkageResults={linkageResults ?? null}
        showAcceptedRows={false}
        isBusy={
          !!(linkageResultsGen && linkageResults === undefined) || isSaving
        }
        csvColumnToLinkageColumn={csvColumnToLinkageColumn}
        setLinkageColumn={(csvColumn, linkageColumn) =>
          setCsvColumnToLinkageColumn((prev) => {
            const next = { ...prev };

            if (linkageColumn) {
              next[csvColumn] = linkageColumn;
            } else {
              delete next[csvColumn];
            }

            return next;
          })
        }
        tableSortColumn={tableSortColumn}
        setTableSortColumn={setTableSortColumn}
        tableSortAscending={tableSortAscending}
        setTableSortAscending={setTableSortAscending}
        locationLinkageState={locationLinkageState}
        locationLinkageDispatch={locationLinkageDispatch}
      />

      <div className="mt-8 flex items-center gap-2">
        <ActionButton
          role="secondary"
          onPress={() => {
            locationLinkageDispatch({ type: 'ACCEPT_MATCHED_ROWS' });
          }}
          isDisabled={
            locationLinkageState.matchedRowIdxToLocationIds.size === 0
          }
        >
          Accept{' '}
          {numberToStringWithCommas(
            locationLinkageState.matchedRowIdxToLocationIds.size
          )}{' '}
          Matched
        </ActionButton>

        {locationLinkageState.acceptedRowIdxToLocationIds.size > 0 && (
          <div>
            {numberToStringWithCommas(
              locationLinkageState.acceptedRowIdxToLocationIds.size
            )}{' '}
            rows accepted [
            <TextButton
              onPress={() => {
                locationLinkageDispatch({
                  type: 'IMPORT_LINKAGE_MATCHES',
                  matches:
                    linkageResults?.matchedRowIdxToLocationIds ?? new Map(),
                  reset: true,
                });
              }}
            >
              reset
            </TextButton>
            ]
          </div>
        )}

        <div className="flex-1" />

        <ActionButton role="secondary" onPress={resetCsvFile}>
          Back
        </ActionButton>

        <ActionButton
          role="primary"
          onPress={async () => {
            setIsSaving(true);
            setSavingError(null);

            try {
              const updates = calculateBulkLocationUpdates(
                locationLinkageState,
                csvResults,
                csvColumnToLinkageColumn
              );

              await AssignmentService.bulkUpdateLocations(updates);

              applyBulkUpdates(updates);
              doClose();
            } catch (e) {
              setSavingError(
                'Unable to save this data. Please try again later, or reload the page.'
              );
            } finally {
              setIsSaving(false);
            }
          }}
          isDisabled={
            // Disabled if either we haven’t chosen any output columns, or if no
            // rows have been accepted as matches.
            !hasOutputColumns ||
            locationLinkageState.acceptedRowIdxToLocationIds.size === 0 ||
            isSaving
          }
        >
          Save
        </ActionButton>
      </div>

      {/* TODO(fiona): do this better */}
      {savingError && <div className="text-error">{savingError}</div>}
    </>
  );
};

export default LocationMetadataResults;

const CSV_HEADER_HEIGHT_PX = 30.5;
const CSV_ROW_HEIGHT_PX = 31.5;

// We store the original row index so that we have it if we later filter the
// list.
const LBJ_ROW_ID_KEY = '_lbj_row_idx';

/**
 * Actual table to show the CSV rows and what they’re linked to.
 */
const LocationMetadataTable: React.FunctionComponent<{
  csvResults: Papa.ParseResult<{
    [key: string]: string;
  }>;
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  linkageResults: LocationMatchingResults | null;
  showAcceptedRows: boolean;
  isBusy: boolean;
  csvColumnToLinkageColumn: {
    [col: string]: LocationMatchingColumn | LocationMetadataColumn;
  };
  setLinkageColumn: (
    csvColumn: string,
    linkageColumn: LocationMatchingColumn | LocationMetadataColumn | null
  ) => void;
  tableSortColumn: string;
  setTableSortColumn: (col: string) => void;
  tableSortAscending: boolean;
  setTableSortAscending: (val: boolean) => void;
  locationLinkageState: LocationLinkageState;
  locationLinkageDispatch: LocationLinkageDispatch;
}> = ({
  csvResults,
  locationsById,
  linkageResults,
  showAcceptedRows,
  isBusy,

  csvColumnToLinkageColumn,
  setLinkageColumn,

  tableSortColumn,
  setTableSortColumn,
  tableSortAscending,
  setTableSortAscending,

  locationLinkageState,
  locationLinkageDispatch,
}) => {
  const matchedLocationIds = React.useMemo(
    () => makeLocationIdSet(locationLinkageState.matchedRowIdxToLocationIds),
    [locationLinkageState.matchedRowIdxToLocationIds]
  );

  const acceptedLocationIds = React.useMemo(
    () => makeLocationIdSet(locationLinkageState.acceptedRowIdxToLocationIds),
    [locationLinkageState.acceptedRowIdxToLocationIds]
  );

  // TODO(fiona): Pre-calculate appropriate column widths for the table so that
  // they don’t jump around as the virtual rows pop in and out.
  const columnNames = [LBJ_ROW_ID_KEY, ...csvResults.meta.fields!];

  /**
   * The rows to render, with `LBJ_ROW_ID_KEY` added, filtered to only
   * unaccepted, and sorted based on the selected column and direction.
   */
  const rows = React.useMemo(() => {
    const rows = csvResults.data
      // add the original row ID to the records because once we start
      // filtering the data they won’t line up anymore (and original row ID is
      // essentially our only key for the CSV data).
      .map<{ [LBJ_ROW_ID_KEY]: number } & { [key: string]: string | number }>(
        (row, idx) => ({ ...row, [LBJ_ROW_ID_KEY]: idx })
      )
      .filter((row) =>
        showAcceptedRows
          ? true
          : !locationLinkageState.acceptedRowIdxToLocationIds.has(
              row[LBJ_ROW_ID_KEY]
            )
      );

    // Sort by our particular column. If both values for the column parse as
    // numbers, sort it numerically (so that 3 comes before 200).
    rows.sort((a, b) => {
      let valA = a[tableSortColumn] ?? '';
      let valB = b[tableSortColumn] ?? '';

      if (
        `${valA}` === Number(valA).toString() &&
        `${valB}` === Number(valB).toString()
      ) {
        valA = Number(valA);
        valB = Number(valB);
      }

      if (valA < valB) {
        return tableSortAscending ? -1 : 1;
      } else if (valB > valA) {
        return tableSortAscending ? 1 : -1;
      } else {
        return 0;
      }
    });

    return rows;
  }, [
    csvResults.data,
    showAcceptedRows,
    locationLinkageState.acceptedRowIdxToLocationIds,
    tableSortColumn,
    tableSortAscending,
  ]);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const tableVirtualizer = useVirtualizer({
    count: rows.length,
    overscan: 60,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => CSV_ROW_HEIGHT_PX,
    getItemKey: (idx) => rows[idx]![LBJ_ROW_ID_KEY],
    paddingStart: CSV_HEADER_HEIGHT_PX,
  });

  const virtualRows = tableVirtualizer.getVirtualItems();

  // We need rows at the top and bottom of the table to take up the height of
  // all of the unrendered rows.
  //
  // We subtract the HEADER_ROW_HEIGHT because we’ll be rendering it, so it will
  // take up its own space.
  const scrollPaddingTop = (virtualRows[0]?.start ?? 0) - CSV_HEADER_HEIGHT_PX;
  const scrollPaddingBottom =
    tableVirtualizer.getTotalSize() -
    (virtualRows[virtualRows.length - 1]?.end ?? 0);

  return (
    <div
      // min-h-0 so that this element will not exceed the dialog’s size (which
      // has its own screen size–based constraints coming from ModalDialog’s
      // overlay).
      className="relative min-h-0 overflow-x-auto overflow-y-scroll overscroll-contain"
      ref={scrollContainerRef}
    >
      {isBusy && (
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-white opacity-40" />
      )}
      <table
        className="overflow-clip text-base"
        style={{ height: tableVirtualizer.getTotalSize() }}
      >
        <thead>
          <tr>
            <th className="sticky top-0 left-0 z-20 whitespace-nowrap border-r border-b border-gray-300 bg-white p-2">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </th>

            {columnNames.map((col) => (
              <th
                key={col}
                className="sticky top-0 z-10 whitespace-nowrap border-r border-b border-gray-300 bg-white p-2"
              >
                <div className="flex justify-between gap-4">
                  <span
                    className={`cursor-pointer ${
                      col === tableSortColumn ? 'underline' : 'hover:underline'
                    }`}
                    onClick={() => {
                      if (col === tableSortColumn) {
                        setTableSortAscending(!tableSortAscending);
                      } else {
                        setTableSortColumn(col);
                        setTableSortAscending(true);
                      }
                    }}
                  >
                    {col === LBJ_ROW_ID_KEY ? '#' : col}
                  </span>

                  {col !== LBJ_ROW_ID_KEY && (
                    <LinkageColumnButton
                      csvColumnToLinkageColumn={csvColumnToLinkageColumn}
                      linkageColumn={csvColumnToLinkageColumn[col] ?? null}
                      setLinkageColumn={(linkageCol) =>
                        setLinkageColumn(col, linkageCol)
                      }
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr className="border-0" style={{ height: scrollPaddingTop }} />

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index]!;
            const rowIdx = row[LBJ_ROW_ID_KEY];

            return (
              <tr
                key={virtualRow.key}
                className={virtualRow.index % 2 === 0 ? 'bg-white' : 'bg-zebra'}
              >
                <td className="z-1 sticky left-0 border-r border-gray-300 bg-inherit p-2 text-right">
                  {linkageResults && (
                    <LinkageStatusCell
                      linkageResults={linkageResults}
                      locationsById={locationsById}
                      selectedLocationIdsForRow={
                        locationLinkageState.matchedRowIdxToLocationIds.get(
                          rowIdx
                        ) ??
                        locationLinkageState.acceptedRowIdxToLocationIds.get(
                          rowIdx
                        ) ??
                        new Set()
                      }
                      setSelectedLocationIdsForRow={(locIds) =>
                        locationLinkageDispatch({
                          type: 'SET_ROW_MATCH',
                          rowIdx,
                          locationIds: locIds,
                        })
                      }
                      matchedLocationIds={matchedLocationIds}
                      acceptedLocationIds={acceptedLocationIds}
                      rowIndex={rowIdx}
                    />
                  )}
                </td>

                {columnNames.map((col) => (
                  <td
                    key={col}
                    className="whitespace-nowrap border-r border-gray-300 p-2"
                  >
                    {rows[virtualRow.index]?.[col]}
                  </td>
                ))}
              </tr>
            );
          })}

          <tr className="border-0" style={{ height: scrollPaddingBottom }} />
        </tbody>
      </table>
    </div>
  );
};

/**
 * Button that goes in the column header that allows the column to be linked to
 * one the types of data we can link on.
 */
const LinkageColumnButton: React.FunctionComponent<{
  csvColumnToLinkageColumn: {
    [col: string]: LocationMatchingColumn | LocationMetadataColumn;
  };
  linkageColumn: LocationMatchingColumn | LocationMetadataColumn | null;
  setLinkageColumn: (
    col: LocationMatchingColumn | LocationMetadataColumn | null
  ) => void;
}> = ({ csvColumnToLinkageColumn, linkageColumn, setLinkageColumn }) => (
  <Menu<{
    title: string;
    columns: { id: LocationMatchingColumn | LocationMetadataColumn }[];
  }>
    trigger={(props, ref) => (
      <TextButton {...props} buttonRef={ref}>
        {linkageColumn === null
          ? 'link'
          : `→ ${LOCATION_COLUMN_LABELS[linkageColumn]}`}
      </TextButton>
    )}
    selectionStyle="checkmark"
    selectionMode="single"
    selectedKeys={linkageColumn ? [linkageColumn] : []}
    // Show the other already-selected linkage columns as disabled
    disabledKeys={Object.values(csvColumnToLinkageColumn).filter(
      (col) => col !== linkageColumn
    )}
    onSelectionChange={(keys) => {
      if (typeof keys !== 'string') {
        if (keys.size === 0) {
          setLinkageColumn(null);
        } else {
          const linkageColumn = [...keys][0]! as
            | LocationMatchingColumn
            | LocationMetadataColumn;
          setLinkageColumn(linkageColumn);
        }
      }
    }}
    items={[
      {
        title: 'Matching',
        columns: [
          { id: 'id' },
          { id: 'name' },
          { id: 'countyName' },
          { id: 'address' },
          { id: 'city' },
          { id: 'zip' },
        ],
      },
      {
        title: 'Import',
        columns: [{ id: 'rank' }],
      },
    ]}
  >
    {({ title, columns }) => (
      <Section key={title} title={title} items={columns}>
        {({ id }) => <Item>{LOCATION_COLUMN_LABELS[id]}</Item>}
      </Section>
    )}
  </Menu>
);

/**
 * Button for a specific row that allows the user to change what locations it’s
 * linked to.
 */
const LinkageStatusCell: React.FunctionComponent<{
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  linkageResults: LocationMatchingResults;
  selectedLocationIdsForRow: Immutable<Set<number>>;
  setSelectedLocationIdsForRow: (locIds: Set<number>) => void;
  matchedLocationIds: Set<number>;
  acceptedLocationIds: Set<number>;
  rowIndex: number;
}> = ({
  locationsById,
  linkageResults,
  rowIndex,
  acceptedLocationIds,
  matchedLocationIds,
  selectedLocationIdsForRow,
  setSelectedLocationIdsForRow,
}) => {
  type LocationItem = {
    // react-stately wants id or key props on objects
    id: number;
    location: AssignmentLocation;

    // We pass these in because the underlying hooks cache rendered components,
    // so every datum that goes into rendering has to be on the item or else it
    // won’t re-render when the item changes.
    accepted: boolean;
    matched: boolean;
  };

  const items = [
    // The provided locations to link to are everything that came up during the
    // matching process.
    ...new Set([
      ...(linkageResults.matchedRowIdxToLocationIds.get(rowIndex) ?? []),
      // TODO(fiona): Sort these by closeness to fuzzy columns
      ...(linkageResults.recommendedRowIdToLocationIds.get(rowIndex) ?? []),
    ]),
  ].map<LocationItem>((locId) => ({
    id: locId,
    location: locationsById.get(locId)!,
    accepted:
      !selectedLocationIdsForRow.has(locId) && acceptedLocationIds.has(locId),
    matched:
      !selectedLocationIdsForRow.has(locId) && matchedLocationIds.has(locId),
  }));

  // Sorts the items so that accepted locations are at the bottom, and
  // already-matched locations are above them.
  items.sort((itemA, itemB) => {
    if (!itemA.accepted && itemB.accepted) {
      return -1;
    } else if (itemA.accepted && !itemB.accepted) {
      return 1;
    } else if (!itemA.matched && itemB.matched) {
      return -1;
    } else if (itemA.matched && !itemB.matched) {
      return 1;
    } else if (itemA.location.name < itemB.location.name) {
      return -1;
    } else if (itemA.location.name > itemB.location.name) {
      return 1;
    } else {
      return itemA.id - itemB.id;
    }
  });

  return (
    // TODO(fiona): Add combo box to allow searching for _any_ location in the
    // election, not just selecting from among the matched ones.
    <Menu<LocationItem>
      selectionStyle="control"
      selectionMode="multiple"
      trigger={(props, ref) => (
        <TextButton {...props} buttonRef={ref} isDisabled={items.length === 0}>
          {selectedLocationIdsForRow.size === 0
            ? '\u00a0\u00a0+\u00a0\u00a0'
            : selectedLocationIdsForRow.size}
        </TextButton>
      )}
      items={items}
      closeOnSelect={false}
      selectedKeys={selectedLocationIdsForRow}
      disabledKeys={Sets.difference(
        acceptedLocationIds,
        selectedLocationIdsForRow
      )}
      onSelectionChange={(keys) =>
        keys !== 'all' && setSelectedLocationIdsForRow(keys as Set<number>)
      }
    >
      {({ location, accepted, matched }) => (
        <Item
          textValue={`${location.name}, ${location.address} ${location.zipcode} ${location.county.name}`}
        >
          <strong>{location.name}</strong>
          {accepted && <em> Already accepted</em>}
          {matched && <em> Already matched</em>}
          <br />
          {location.address}
          <br />
          {location.city} {location.zipcode} {location.county.name}
        </Item>
      )}
    </Menu>
  );
};
