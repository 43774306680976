import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import mapStateToRequestedResource from '../../../utils/assignment/map-state-to-requested-resource';
import RIT from '../../../utils/render-if-truthy';
import EditorButton from '../../presentational/assignment/assignment-editor-button';

@connect((state) => {
  const { assignment } = state;
  const filters = state.filters.get(lbjAppSections.ASSIGNMENT);
  const assignmentEditor = assignment.get('assignmentEditor');
  const parentResource = assignmentEditor.get('parentResource');
  const errorInfo = assignment.getIn(['updatingAssignment', 'error', 'info']);
  const { requestedResourceId } = mapStateToRequestedResource(state);

  return {
    parentResource,
    assignmentType: assignmentEditor.get('assignmentType'),
    updatedFields: assignmentEditor.get('updatedFields'),
    isExistingAssignment: parentResource.has('assignment'),
    requestIsPending: assignment.getIn([
      'updatingAssignment',
      'requestIsPending',
    ]),
    requestErred: assignment.getIn(['updatingAssignment', 'requestErred']),
    requestedResourceId,
    errorInfo,
    filters,
  };
})
export default class AssignmentSubmissionControlsV2 extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    parentResource: PropTypes.object.isRequired,
    isExistingAssignment: PropTypes.bool.isRequired,
    requestIsPending: PropTypes.bool.isRequired,
    requestErred: PropTypes.bool.isRequired,
    requestedResourceId: PropTypes.number,
    errorInfo: PropTypes.string,
    filters: PropTypes.object.isRequired,
    assignmentType: PropTypes.oneOf([
      'poll',
      'boiler_room',
      'hotline_center',
      'hotline_manager',
      'board_of_elections',
    ]),
    updatedFields: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  onAssignmentUpdate(e) {
    e.preventDefault();
    this.handleAssignmentCRUD(assignmentActionCreators.updateAssignmentAsync);
  }

  onAssignmentCreate(e) {
    e.preventDefault();
    this.handleAssignmentCRUD(assignmentActionCreators.createAssignmentAsync);
  }

  onAssignmentDelete(e) {
    e.preventDefault();

    if (!this.props.requestIsPending) {
      this.handleAssignmentCRUD(assignmentActionCreators.deleteAssignmentAsync);
    }
  }

  getAssignmentData() {
    const { isExistingAssignment, parentResource, updatedFields } = this.props;

    if (isExistingAssignment) {
      return updatedFields;
    }

    // updatedFields comes from the assignment reducer and is immutable via
    // fromJS.
    return parentResource.merge(/* 4.1 SAFE */ updatedFields);
  }

  getResourceId(assignment, resourceType) {
    return assignment.hasIn([resourceType, 'id'])
      ? assignment.getIn([resourceType, 'id'])
      : assignment.get(resourceType);
  }

  getAssignmentPayload() {
    const { assignmentType, filters } = this.props;
    const assignment = this.getAssignmentData();

    return assignment
      .set('type', assignmentType)
      .set('shift_date', filters.get('dates'))
      .set('precinct', this.getResourceId(assignment, 'precinct'))
      .set('location', this.getResourceId(assignment, 'location'))
      .set('boiler_room', this.getResourceId(assignment, 'boiler_room'))
      .set(
        'board_of_elections',
        this.getResourceId(assignment, 'board_of_elections')
      )
      .set('user', this.getResourceId(assignment, 'user'))
      .toJS();
  }

  handleAssignmentCRUD(action) {
    const { dispatch, filters, requestedResourceId } = this.props;
    const requestedResource = {
      resourceId: requestedResourceId,
      listViewType: filters.get('view'),
    };

    return dispatch(
      action(this.getAssignmentPayload(), requestedResource, filters.toJS())
    );
  }

  actionShouldBeDisabled() {
    const { requestIsPending } = this.props;
    const assignmentNotComplete = !this.assignmentIsComplete();

    return requestIsPending || assignmentNotComplete;
  }

  assignmentIsComplete() {
    const { assignmentType } = this.props;
    const assignmentData = this.getAssignmentData();
    let hasCompleteResources;

    if (assignmentType === 'poll') {
      hasCompleteResources =
        !!assignmentData.get('user') &&
        (!!assignmentData.get('precinct') || !!assignmentData.get('location'));
    } else if (assignmentType === 'board_of_elections') {
      hasCompleteResources =
        !!assignmentData.get('user') &&
        !!assignmentData.get('board_of_elections');
    } else {
      hasCompleteResources =
        !!assignmentData.get('user') && !!assignmentData.get('boiler_room');
    }

    return (
      hasCompleteResources &&
      !!assignmentData.get('start_time') &&
      !!assignmentData.get('end_time')
    );
  }

  renderEditAssignmentControls() {
    const { requestIsPending } = this.props;
    const buttonText = requestIsPending ? 'Saving...' : 'Save Changes';

    return (
      <div className="assignment-submission-buttons">
        <div>
          <EditorButton
            disabled={this.actionShouldBeDisabled()}
            onClick={this.onAssignmentUpdate.bind(this)}
          >
            <span>{buttonText}</span>
          </EditorButton>
        </div>
        <div>
          <button
            className="assignment-submission-buttons__delete link-button"
            onClick={this.onAssignmentDelete.bind(this)}
          >
            Delete Assignment
          </button>
        </div>
      </div>
    );
  }

  renderNewAssignmentControls() {
    const { requestIsPending } = this.props;
    const buttonText = requestIsPending ? 'Creating...' : 'Create Assignment';

    return (
      <EditorButton
        disabled={this.actionShouldBeDisabled()}
        onClick={this.onAssignmentCreate.bind(this)}
      >
        <span>{buttonText}</span>
      </EditorButton>
    );
  }

  render() {
    const { requestErred, isExistingAssignment, errorInfo } = this.props;
    const errorText = errorInfo
      ? errorInfo
      : 'There was an error. Please try again!';

    return (
      <div>
        <div className="assignment-submission-controls-v2">
          {RIT(requestErred, () => (
            <div className="c-alert-error">{errorText}</div>
          ))}
          {RIT(
            !isExistingAssignment,
            this.renderNewAssignmentControls.bind(this)
          )}
          {RIT(
            isExistingAssignment,
            this.renderEditAssignmentControls.bind(this)
          )}
        </div>
      </div>
    );
  }
}
