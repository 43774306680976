import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import { commonSetup } from './common-setup';
import { trackPackageVersion } from './services/analytics-service';

// We import Tailwind first and the legacy styles afterwards for safety as we
// start rolling Tailwind out, since we want the legacy styles to take
// precedence over the Preflight CSS reset.
import '../css/styles.css';
import '../scss/main.scss';
import '../css/reset-2023.css';

commonSetup();

// Send the user's package.json version to GA.
trackPackageVersion();

// This file is purposefully small, with few direct dependencies, because if any
// hot module changes bubble up to this module, it will be re-evaluated, and,
// this call to ReactDOM.render will essentially reset the entire app.
ReactDOM.render(<App />, document.querySelector('[data-react]'));
