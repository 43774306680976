import * as Immutable from 'immutable';

import { requestStatuses } from '../../constants';
import { AppAction } from '../flux-store';

import {
  AddUsersToQueueAction,
  RemoveUsersFromQueueAction,
  SendEmailAction,
} from './action-creators';
import actionTypes from './action-types';

const {
  SEND_EMAIL,
  RESET_EMAIL_RECEIPT,
  ADD_USERS_TO_QUEUE,
  REMOVE_USERS_FROM_QUEUE,
  ADD_ALL_USERS_TO_QUEUE,
  REMOVE_ALL_USERS_FROM_QUEUE,
} = actionTypes;

const { PENDING, SUCCESS, ERROR } = requestStatuses;

export class EmailState extends Immutable.Record({
  queue: Immutable.List<number>(),
  emailRequest: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
  })(),
  emailReceipt: null as Immutable.Map<string, any> | null,
}) {}

export const initialState = new EmailState();

function handleAddUsersToQueue(
  state: EmailState,
  data: AddUsersToQueueAction['data']
): EmailState {
  return state.update('queue', (queue) =>
    queue.concat(data.userIds).toSet().toList()
  );
}

function handleRemoveUsersFromQueue(
  state: EmailState,
  data: RemoveUsersFromQueueAction['data']
) {
  return state.update('queue', (queue) =>
    // `includes` here is mostly called on 1-element arrays, so no need to be
    // fancier for greater efficiency.
    queue.filterNot((userId) => data.userIds.includes(userId!)).toList()
  );
}

function handleSendEmail(
  state: EmailState,
  data: SendEmailAction['data']
): EmailState {
  return state
    .update('emailRequest', (emailRequest) =>
      emailRequest.clear().merge({
        requestIsPending: data.status === PENDING,
        requestErred: data.status === ERROR,
      })
    )
    .set(
      'emailReceipt',
      data.status === SUCCESS
        ? (Immutable.fromJS(data.emailReceipt) as Immutable.Map<string, any>)
        : state.get('emailReceipt')
    );
}

function handleResetEmailReceipt(state: EmailState): EmailState {
  return state.set('emailReceipt', null);
}

export default function email(
  state: EmailState = initialState,
  action: AppAction
): EmailState {
  switch (action.type) {
    case SEND_EMAIL:
      return handleSendEmail(state, action.data);

    case ADD_USERS_TO_QUEUE:
      return handleAddUsersToQueue(state, action.data);

    case REMOVE_USERS_FROM_QUEUE:
      return handleRemoveUsersFromQueue(state, action.data);

    case RESET_EMAIL_RECEIPT:
      return handleResetEmailReceipt(state);

    case ADD_ALL_USERS_TO_QUEUE:
      return state.set('queue', Immutable.List(action.data.userIds));

    case REMOVE_ALL_USERS_FROM_QUEUE:
      return state.set('queue', Immutable.List());

    default:
      return state;
  }
}
