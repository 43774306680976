import * as Immutable from 'immutable';
import React from 'react';

import Autocomplete from '../../components/presentational/form/autocomplete';
import CountySelect, {
  countiesToChoices,
} from '../../components/presentational/form/county-select';
import Input from '../../components/presentational/form/input';
import StateSelect from '../../components/presentational/form/state-select';
import { State, UserRole } from '../../constants';
import { LbjInputEvent } from '../../decorators/input-props';
import { NewIssueInProgress } from '../../modules/issue/reducers';
import { ApiPollAssignmentWithRelatedFields } from '../../services/assignment-service';
import { CountySlug } from '../../services/common';
import {
  ApiCounty,
  ApiLocation,
  ApiPrecinct,
} from '../../services/lbj-shared-service';
import { ApiCurrentUser } from '../../services/user-service';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';
import RIT from '../../utils/render-if-truthy';

type IssueLocationFields =
  | 'type'
  | 'state'
  | 'zipcode'
  | 'county'
  | 'precinct'
  | 'location';

export type SelectableLocation = Pick<
  ApiLocation,
  'id' | 'name' | 'early_vote' | 'election_day'
>;

export type SelectablePrecinct = Pick<ApiPrecinct, 'id' | 'name'>;

export default class IssueLocation extends React.Component<{
  issueData:
    | Pick<IssueInProgressPayload, IssueLocationFields>
    | Pick<NewIssueInProgress, IssueLocationFields>;
  errors: { [key: string]: string[] };
  onInputChange: (ev: LbjInputEvent<string | null>) => void;
  onStateChange: (ev: LbjInputEvent<State | null>) => void;
  onCountyChange: (ev: LbjInputEvent<CountySlug | null>) => void;
  onPrecinctChange: (ev: LbjInputEvent<string | null | undefined>) => void;
  onLocationChange: (ev: LbjInputEvent<string | null | undefined>) => void;
  currentUserRole: UserRole;
  currentUserRelated: ApiCurrentUser['related'] | undefined;
  locationList: SelectableLocation[];
  precinctList: SelectablePrecinct[];
  countyList: ApiCounty[];
  existingLocation: SelectableLocation | null;
  existingPrecinct: SelectablePrecinct | null;
  existingCounty: ApiCounty | null;
  locationIsReadOnly: boolean;
  isStateReadOnly: boolean;
  requiredFields: Immutable.List<string>;
  formType: 'poll_observer' | 'hotline';
}> {
  static defaultProps = {
    precinctList: [],
  };

  getLocationChoices() {
    const { locationList, existingLocation } = this.props;

    const locationChoices = locationList.reduce<{ [id: string]: string }>(
      (choices, loc) => {
        let name = loc.name;

        if (loc.early_vote) {
          name += ' (EV)';
        }

        if (loc.election_day) {
          name += ' (Eday)';
        }

        return { ...choices, [loc.id]: name };
      },
      {}
    );

    if (existingLocation) {
      locationChoices[existingLocation.id] = existingLocation.name;
    }

    return locationChoices;
  }

  getPrecinctChoices() {
    const { precinctList, existingPrecinct } = this.props;

    const precinctChoices = precinctList.reduce<{ [id: string]: string }>(
      (choices, precinct) => ({ ...choices, [precinct.id]: precinct.name }),
      {}
    );

    if (existingPrecinct) {
      precinctChoices[existingPrecinct.id] = existingPrecinct.name;
    }

    return precinctChoices;
  }

  /**
   * Returns the ids of either locations or precincts the user is assigned to.
   */
  getAssigned(type: 'location' | 'precinct'): string[] {
    const { currentUserRelated } = this.props;

    return (
      (currentUserRelated ?? [])
        .filter(
          (
            relatedObject
          ): relatedObject is ApiPollAssignmentWithRelatedFields =>
            // "as any" safe  because of the "type in" check
            type in relatedObject && !!(relatedObject as any)[type]?.id
        )
        // ! because the filter also checks for [type]’s existence
        .map((relatedObject) => relatedObject[type]!.id.toString())
    );
  }

  /**
   * Returns the slugs of counties the user is assigned to, either from location
   * or precinct.
   */
  getAssignedCounties() {
    const { currentUserRelated } = this.props;

    return (currentUserRelated ?? [])
      .map((relatedObject: any): CountySlug | undefined | null => {
        return (
          relatedObject.location?.county?.slug ||
          relatedObject.precinct?.county?.slug
        );
      })
      .filter((slug): slug is CountySlug => !!slug);
  }

  render() {
    const {
      issueData,
      errors,
      onInputChange,
      locationList,
      precinctList,
      countyList,
      existingCounty,
      locationIsReadOnly,
      isStateReadOnly,
      onStateChange,
      onCountyChange,
      onPrecinctChange,
      onLocationChange,
      requiredFields,
      formType,
      currentUserRole,
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-small-6">
            <StateSelect
              value={issueData.state ?? null}
              onChange={onStateChange}
              type="select"
              disabled={locationIsReadOnly || isStateReadOnly}
              required={requiredFields.includes('state')}
              includeNational={false}
              errors={errors['state']}
            />
          </div>
          <div className="col-small-6">
            <CountySelect
              type="select"
              value={issueData.county ?? null}
              counties={countiesToChoices([
                ...countyList,
                ...(existingCounty ? [existingCounty] : []),
              ])}
              preferredChoices={this.getAssignedCounties()}
              onChange={onCountyChange}
              errors={errors['county']}
              required={requiredFields.includes('county')}
              disabled={locationIsReadOnly || countyList.length === 0}
            />
          </div>
        </div>
        {RIT(
          issueData.type === 'incident' || formType === 'poll_observer',
          () => (
            <div>
              <div className="row">
                <div className="col-small-6">
                  <Input
                    name="zipcode"
                    title="Zip code"
                    placeholder="Zip code"
                    value={issueData.zipcode || ''}
                    onChange={onInputChange}
                    errors={errors['zip']}
                    disabled={locationIsReadOnly}
                  />
                </div>
                <div className="col-small-6">
                  <Autocomplete
                    name="precinct"
                    title="Precinct"
                    choices={this.getPrecinctChoices()}
                    preferredChoices={this.getAssigned('precinct')}
                    alphaSort
                    disabled={locationIsReadOnly || precinctList.length === 0}
                    value={issueData.precinct ?? ''}
                    onChange={onPrecinctChange}
                    errors={errors['precinct']}
                    type="select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-small-12">
                  <Autocomplete
                    name="location"
                    title="Location name"
                    disabled={locationIsReadOnly || locationList.length === 0}
                    value={issueData.location ?? ''}
                    choices={this.getLocationChoices()}
                    preferredChoices={this.getAssigned('location')}
                    alphaSort
                    onChange={onLocationChange}
                    errors={errors['location']}
                    type="select"
                    required={
                      requiredFields.includes('location') ||
                      // Backend enforces that poll observers submit a location,
                      // regardless of the issue type. We do this here because
                      // the reducer that sets requiredFields doesn’t have
                      // access to the user’s role.
                      currentUserRole === 'poll_observer'
                    }
                  />
                </div>
              </div>
              <div className="row">
                {/* TODO(fiona): Delete this? Need to make sure it doesn’t break layout. */}
                <div className="location-details col-small-12"></div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
