import { connect } from 'react-redux';

import { AppDispatch, AppState } from '../../../modules/flux-store';
import { dismissToast } from '../../../modules/toast';
import Toast from '../../presentational/lbj/toast';

export default connect(
  ({ user }: AppState) => ({
    toastData: user.toastData,
  }),
  (dispatch: AppDispatch) => ({
    onDismiss: () => {
      dispatch(dismissToast());
    },
  })
)(Toast);
