import moment from 'moment';

import localStorage from './local-storage';
import { captureException } from './sentry-service';

const API_VERSION_KEY = 'API_VERSION_KEY';
const FE_VERSION_KEY = 'FE_VERSION_KEY';
const USER_ELECTION_ID_KEY = 'USER_ELECTION_ID_KEY';

/**
 * Type of data that we keep in local storage to keep track of current versions
 * (so we can reload or cache purge when they change) and to pass global
 * election data around.
 */
type LocalStorageAppInfo = {
  [API_VERSION_KEY]?: string;
  [FE_VERSION_KEY]?: string;
  [USER_ELECTION_ID_KEY]?: {
    electionId: number | null;
    userElectionId: number | null;
  };
};

/**
 * Item for API responses that are cached in local storage.
 *
 * @see set
 * @see get
 */
type LocalStorageCacheItem<T> = {
  response: T;
  /** Expiration time in moment.format() format. */
  expiration: string;
};

function logError(e: any) {
  if (process.env['NODE_ENV'] === 'development') {
    console.error(e);
  }

  captureException(e);
}

/**
 * Attempts to grab content from local storage. If it has
 * expired, removes it and returns null.
 *
 * @param key - API request URL
 * @return ladybird response
 */
export function get<T>(key: string, now = moment()): T | null {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return null;
  }

  try {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    const cachedContent: LocalStorageCacheItem<T> = JSON.parse(item);

    if (!cachedContent) {
      return null;
    }

    const { response, expiration } = cachedContent;
    const expiredDate = expiration ? moment(expiration) : now;

    if (!response || now.isAfter(expiredDate)) {
      localStorage.removeItem(key);
      return null;
    }

    return response;
  } catch (e) {
    logError(e);

    try {
      // If this item errored, remove it if possible to prevent future errors.
      localStorage.removeItem(key);
    } catch (e2) {
      logError(e2);
    }

    return null;
  }
}

/**
 * Store the ladybird response and set an
 * expiration date in seconds.
 *
 * @param key     API request URL
 * @param value   ladybird response
 * @param ttl     TTL in seconds
 */
export function set<T>(key: string, value: T, ttl = 360, now = moment()) {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return;
  }

  try {
    const expiration = now.add(ttl, 'seconds').format();
    const cachedContent = JSON.stringify({
      response: value,
      expiration,
    });

    localStorage.setItem(key, cachedContent);
  } catch (e) {
    logError(e);
  }
}

/**
 * Clear local storage cache, saving the user election IDs.
 *
 * Use this when we need to invalidate the entire cache.
 */
export function purgeCache() {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return;
  }
  const saveKeys = [USER_ELECTION_ID_KEY];

  const saved = saveKeys.map((key) => ({
    key,
    value: localStorage.getItem(key),
  }));

  try {
    localStorage.clear();

    saved.forEach(
      (item) => item.value && localStorage.setItem(item.key, item.value)
    );
  } catch (e) {
    logError(e);
  }
}

/**
 * Clear everything in local storage, including the user election information.
 *
 * Use this on logout / login for a clean slate.
 */
export function purgeAll() {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return;
  }

  try {
    localStorage.clear();
  } catch (e) {
    logError(e);
  }
}

function _getJSONKey<K extends keyof LocalStorageAppInfo>(
  key: K
): LocalStorageAppInfo[K] | null {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return;
  }

  try {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  } catch (e) {
    logError(e);

    try {
      // If this item errored, remove it if possible to prevent future errors.
      localStorage.removeItem(key);
    } catch (e2) {
      logError(e2);
    }

    return null;
  }
}

function _setJSONKey<K extends keyof LocalStorageAppInfo>(
  key: K,
  value: LocalStorageAppInfo[K]
) {
  // localStorage can be null in Incognito windows and such.
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    logError(e);
  }
}

/**
 * Get the API version of responses cached in
 * localStorage.
 *
 * @return     {String}  The current api version.
 */
export function getCurrentAPIVersion(): string | null {
  return _getJSONKey(API_VERSION_KEY) ?? null;
}

/**
 * Update the API version of responses
 * cached in localStorage.
 *
 * @param      {<type>}  version  The version
 */
export function updateAPIVersion(version: string) {
  _setJSONKey(API_VERSION_KEY, version);
}

/**
 * Gets the current fe version.
 *
 * @return     {String}  The current fe version.
 */
export function getCurrentFEVersion(): string | null {
  return _getJSONKey(FE_VERSION_KEY) ?? null;
}

/**
 * Sets the current fe version.
 *
 * @param      {String}  version  The version
 */
export function updateFEVersion(version: string) {
  _setJSONKey(FE_VERSION_KEY, version);
}

/**
 * Gets the current user election id.
 *
 * @return    {number | null}  The user election id.
 */
export function getCurrentUserElectionId(): number | null {
  const result = _getJSONKey(USER_ELECTION_ID_KEY);

  return result?.userElectionId ?? null;
}

/**
 * Gets the current election id.
 *
 * @return    {number | null}  The user election id.
 */
export function getCurrentElectionId(): number | null {
  const result = _getJSONKey(USER_ELECTION_ID_KEY);

  return result?.electionId ?? null;
}

/**
 * Sets the current user election id.
 *
 * @param      {number | null}  userElectionId  The user_election id.
 * @param      {number | null}  electionId  The user_election id.
 */
export function updateUserElectionIds(
  userElectionId: number | null,
  electionId: number | null
) {
  _setJSONKey(USER_ELECTION_ID_KEY, { userElectionId, electionId });
}

export default {
  get,
  set,
  purgeCache,
  purgeAll,
  getCurrentAPIVersion,
  updateAPIVersion,
  getCurrentFEVersion,
  updateFEVersion,
  getCurrentUserElectionId,
  getCurrentElectionId,
  updateUserElectionIds,
};
