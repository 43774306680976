import React, { ReactElement } from 'react';

import { useIsMobile } from '../../utils/hooks';

const MOBILE_WIDTH = 650;

export const RenderOnDesktop: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props): ReactElement => {
  const isMobile = useIsMobile(MOBILE_WIDTH);

  return <>{!isMobile && <>{props.children}</>}</>;
};

export const RenderOnMobile: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props): ReactElement => {
  const isMobile = useIsMobile(MOBILE_WIDTH);

  return <>{isMobile && <>{props.children}</>}</>;
};
