import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import EmptyAlert from '../../presentational/lbj/empty-alert';
import LoadingOverlay from '../../presentational/lbj/loading';

export default class NotificationListWrapper extends Component {
  static propTypes = {
    notificationListIsEmpty: PropTypes.bool.isRequired,
    isLoadingNotifications: PropTypes.bool.isRequired,
    children: PropTypes.node,
  };

  renderEmpty() {
    return (
      <EmptyAlert
        header="Nothing to see here."
        description={
          'Looks like you’re up to date. The notification icon in the top ' +
          'right will turn red when there are issues that need your attention.'
        }
      />
    );
  }

  render() {
    const { notificationListIsEmpty, isLoadingNotifications, children } =
      this.props;

    return (
      <div className="lbj-column-content no-gutter">
        {RIT(notificationListIsEmpty, this.renderEmpty.bind(this))}
        {RIT(!notificationListIsEmpty, () => children)}
        {RIT(isLoadingNotifications, () => (
          <LoadingOverlay />
        ))}
      </div>
    );
  }
}
