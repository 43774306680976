import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import paginatedComponent from '../../../decorators/paginated-component';
import { actionCreators } from '../../../modules/notifications';
import mapStateToPagination from '../../../utils/lbj/map-state-to-pagination';
import {
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import IssueNotification from '../../presentational/notifications/issue-notification-item';

@connect((state) => {
  const { notifications } = state;

  return {
    issueChangeList: notifications.getIn(['issue', 'changeList', 'listData']),
    paginationData: mapStateToPagination(notifications, [
      'issue',
      'changeList',
    ]),
  };
})
@withRouting
@paginatedComponent((props, page) => {
  const { dispatch } = props;
  const { getIssueNotificationsAsync } = actionCreators;

  return dispatch(getIssueNotificationsAsync(page));
})
export default class NotificationList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    issueChangeList: PropTypes.object.isRequired,
    paginationData: PropTypes.object.isRequired,
    ...WithRoutingPropTypes,
  };

  onAcknowledgeNotification(notification) {
    const { dispatch, navigate } = this.props;
    const { ackIssueNotifications } = actionCreators;

    dispatch(ackIssueNotifications([notification.get('id')]));
    navigate(`/issues/${notification.get('ticket')}`);
  }

  render() {
    const { issueChangeList } = this.props;

    return (
      <div>
        {issueChangeList.map((notification, key) => {
          return (
            <IssueNotification
              key={key}
              notification={notification}
              onAcknowledgeNotification={this.onAcknowledgeNotification.bind(
                this
              )}
            />
          );
        })}
      </div>
    );
  }
}
