import React from 'react';

import { clearLoginData } from '../services/login-service';

/**
 * Route handler for "/fix" that logs out the user and clears local storage
 * before doing a hard redirect to "/".
 *
 * We direct folks to this as an escape hatch in case something is wrong with
 * their login situation.
 *
 * TODO(fiona): Look into clearing Google login information as well that’s tied
 * to this app.
 */
export const FixAuthRoute: React.FunctionComponent = () => {
  React.useLayoutEffect(() => {
    clearLoginData();
    window.location.replace('/');
  }, []);

  // Since we’re going to navigate away immediately, just return null rather
  // than even a loading spinner.
  return null;
};
