import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class PollingPlaceTag extends Component {
  static propTypes = {
    onRemoveTag: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  onRemoveTag(e) {
    e.preventDefault();
    this.props.onRemoveTag();
  }

  render() {
    const { children } = this.props;

    return (
      <div className="assignment-polling-place-tag">
        <a
          href="#"
          onClick={this.onRemoveTag.bind(this)}
          className="assignment-remove-tag a-float-left"
        />
        <div className="assignment-polling-place-label">{children}</div>
      </div>
    );
  }
}
