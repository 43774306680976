import * as Sentry from '@sentry/react';
import _ from 'lodash';

export function captureException(err: string | object, tags = {}) {
  Sentry.captureException(err, { tags });
}

export function setUserContext(context: { id?: string }) {
  Sentry.setUser(context);
}
