import * as I from 'immutable';

/**
 * Return a formatted error block to be passed into showToast.
 */
// TODO(fiona): Stricter types for I.Map.
export default function renderError(data: I.Map<string, any>, verbing: string) {
  // `errorData` is consistently _not_ run through `fromJS` in the action
  // creators. It’s just plain JS object (or string).
  return renderErrorJs(data.get('errorData'), verbing);
}

/**
 * Return a formatted error block to be passed into showToast.
 */
export function renderErrorJs(
  errorData: string | { error_message: string } | { timeout: number } | {},
  verbing: string
) {
  let message: string;

  if (errorData === '500 Internal Server Error') {
    message = `There was a problem ${verbing} this issue. Please contact support for help.`;
  } else if (typeof errorData === 'object' && 'timeout' in errorData) {
    // If it was a timeout, use a different message than the generic "there was a problem" one
    message =
      'LBJ took too long to respond. You should try saving this form again.';
  } else if (typeof errorData === 'object' && 'error_message' in errorData) {
    message = String(errorData.error_message);
  } else {
    message = `There was a problem ${verbing} this issue. Check the fields below for more info.`;
  }

  return {
    type: 'error' as const,
    message: message,
  };
}
