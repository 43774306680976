import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { dismissToast } from '../../../modules/user/action-creators';
import mapStateToElections from '../../../utils/user/map-state-to-elections';
import Autocomplete from '../../presentational/form/autocomplete';
import Modal from '../../presentational/lbj/modal';

import UserToastContainer from './user-toast-container';

@connect((state) => ({
  electionsList: mapStateToElections(state),
  isPending: state.user.get('isCloningUsers'),
  hasError: state.user.get('cloneUsersError'),
}))
export default class CloneUsersModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    electionsList: PropTypes.object,
    hasError: PropTypes.bool,
    isPending: PropTypes.bool,
    isVisible: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    handleCloneUsers: PropTypes.func.isRequired,
    userCount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      election: null,
    };

    this.onSelectElection = this.onSelectElection.bind(this);
    this.onCloneUsers = this.onCloneUsers.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.isVisible !== this.props.isVisible) {
      this.reset();
    }

    const success = !props.isPending && this.props.isPending && !props.hasError;

    if (success) {
      this.reset();
    }
  }

  onCloneUsers() {
    const { election } = this.state;
    if (!election) {
      return;
    }

    this.props.handleCloneUsers(election);
  }

  onSelectElection(event) {
    this.setState({ election: event.target.value });
  }

  onClose() {
    const { onRequestClose } = this.props;
    this.reset();
    onRequestClose();
  }

  reset() {
    this.props.dispatch(dismissToast());
    this.setState({ election: null });
  }

  render() {
    const { electionsList, isPending, isVisible, userCount } = this.props;
    const { election } = this.state;

    if (!isVisible) {
      return null;
    }

    const descriptionText = `Add ${userCount} user${
      userCount > 1 ? 's' : ''
    } to another election`;
    const disabled = election === null;
    const spinner = isPending ? (
      <div className="lbj-loading-overlay">
        <div className="lbj-loading-icon" />
      </div>
    ) : null;

    return (
      <Modal
        classes="UserActionModal UserModal"
        dismissable
        headerText="Add users to another election"
        onClose={this.onClose}
      >
        <div className="userActionContent userModalContent">
          <UserToastContainer />
          <div className="user-action-description">{descriptionText}</div>
          <div className="userActionField">
            <Autocomplete
              name="election"
              title="Election"
              value={election}
              required
              onChange={this.onSelectElection}
              choices={electionsList}
            />
          </div>
          <div className="buttonRow">
            <button
              type="submit"
              className="c-button-large c-button-secondary"
              onClick={this.onCloneUsers}
              disabled={disabled}
            >
              Add Users
            </button>
          </div>
          {spinner}
        </div>
      </Modal>
    );
  }
}
