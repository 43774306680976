import * as Immutable from 'immutable';

import { requestStatuses } from '../../constants';
import {
  ApiExpandedIssueComment,
  ApiIssue,
  ApiIssueDocument,
  ApiIssueHistory,
  ApiIssuePhoto,
  ApiNewIssue,
  DetailedApiIssue,
} from '../../services/issue-service';
import { MapFromJs, Nullable, Undefinable } from '../../utils/types';
import { AppAction } from '../flux-store';
import {
  DISMISS_TOAST,
  ShowToastAction,
  SHOW_TOAST,
  ToastRecord,
} from '../toast';

import {
  CreateIssueCommentAction,
  GetIssueAction,
  GetIssueCommentsAction,
  GetIssueDocumentsAction,
  GetIssueElectionAction,
  GetIssueHistoryAction,
  GetIssuePhotosAction,
  IssueCrudData,
  SetIssueElectionAction,
  UploadIssueDocumentAction,
  UploadIssuePhotoAction,
} from './action-creators';
import actionTypes from './action-types';

const { SUCCESS, PENDING, ERROR } = requestStatuses;

function handleGetIssue(
  state: IssueState,
  data: GetIssueAction['data']
): IssueState {
  switch (data.status) {
    case PENDING:
      return state.update('issueDetail', (issueDetail) =>
        issueDetail.merge({
          isFetching: true,
          responseErred: false,
        })
      );

    case SUCCESS:
      return state
        .update('issueDetail', (issueDetail) =>
          issueDetail.merge({
            isFetching: false,
            responseErred: false,
          })
        )
        .update('issueInProgress', (issueInProgress) =>
          // merge in case handleGetIssueHistory returns first and sets
          // `['issueInProgress', 'history']`, and we don’t want to overwrite
          // that.
          issueInProgress.merge(Immutable.fromJS(data.issueResponse) as any)
        );

    case ERROR:
      return state.update('issueDetail', (issueDetail) =>
        issueDetail.merge({
          isFetching: false,
          responseErred: true,
        })
      );

    default:
      return state;
  }
}

function handleGetIssueComments(
  state: IssueState,
  data: GetIssueCommentsAction['data']
) {
  switch (data.status) {
    case PENDING:
      return state.update('issueComments', (issueComments) =>
        (issueComments ?? new IssueCommentsRecord()).merge({
          isFetching: true,
          responseErred: false,
        })
      );

    case SUCCESS:
      return state.set(
        'issueComments',
        new IssueCommentsRecord({
          isFetching: false,
          responseErred: false,

          results: Immutable.fromJS(
            data.issueCommentsResponse.results
          ) as Immutable.List<MapFromJs<ApiExpandedIssueComment>>,
        })
      );

    case ERROR:
      return state.update('issueComments', (issueComments) =>
        (issueComments ?? new IssueCommentsRecord()).merge({
          isFetching: false,
          responseErred: true,
        })
      );

    default:
      return state;
  }
}

function handleCreateIssueComment(
  state: IssueState,
  data: CreateIssueCommentAction['data']
) {
  return state.update('creatingComment', (creatingComment) =>
    creatingComment.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,
    })
  );
}

function handleIssueCRUD(state: IssueState, data: IssueCrudData) {
  return state.update('updatingIssue', (updatingIssue) =>
    updatingIssue.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,

      // This is _not_ an Immutable value, on purpose.
      errorData:
        data.status === ERROR
          ? typeof data.errorData === 'string'
            ? { error: data.errorData }
            : data.errorData
          : {},
    })
  );
}

function handleGetIssueHistory(
  state: IssueState,
  data: GetIssueHistoryAction['data']
) {
  switch (data.status) {
    case PENDING:
      return state.update('issueHistoryRequest', (issueHistoryRequest) =>
        issueHistoryRequest.clear().merge({
          requestIsPending: true,
          requestErred: false,
        })
      );

    case SUCCESS:
      return state
        .update('issueHistoryRequest', (issueHistoryRequest) =>
          issueHistoryRequest.clear().merge({
            requestIsPending: false,
            requestErred: false,
          })
        )
        .update('issueInProgress', (issueInProgress) =>
          issueInProgress.set('history', Immutable.fromJS(data.issueHistory))
        );

    case ERROR:
      return state.update('issueHistoryRequest', (issueHistoryRequest) =>
        issueHistoryRequest.clear().merge({
          requestIsPending: false,
          requestErred: true,
        })
      );
  }
}

function handleResetIssueInProgress(state: IssueState) {
  return state
    .set(
      'issueInProgress',
      Immutable.Map<keyof ExistingIssueInProgress, any>({})
    )
    .update('updatingIssue', (updatingIssue) =>
      updatingIssue.set('errorData', {})
    )
    .delete('issueComments')
    .delete('issuePhotos')
    .delete('issueDocuments');
}

function handleShowToast(state: IssueState, data: ShowToastAction['data']) {
  return state.set('toastData', new ToastRecord(data));
}

function handleDismissToast(state: IssueState) {
  return state.set('toastData', new ToastRecord());
}

function handleGetIssuePhotos(
  state: IssueState,
  data: GetIssuePhotosAction['data']
): IssueState {
  switch (data.status) {
    case PENDING:
      return state.update('issuePhotos', (issuePhotos) =>
        (issuePhotos ?? new IssuePhotosRecord()).merge({
          isFetching: true,
          responseErred: false,
        })
      );

    case SUCCESS:
      return state.set(
        'issuePhotos',
        new IssuePhotosRecord({
          isFetching: false,
          responseErred: false,

          results: Immutable.fromJS(
            data.issuePhotosResponse.results
          ) as Immutable.List<MapFromJs<ApiIssuePhoto>>,
        })
      );

    case ERROR:
      return state.update('issuePhotos', (issuePhotos) =>
        (issuePhotos ?? new IssuePhotosRecord()).merge({
          isFetching: false,
          responseErred: true,
        })
      );

    default:
      return state;
  }
}

function handleUploadIssuePhoto(
  state: IssueState,
  data: UploadIssuePhotoAction['data']
): IssueState {
  return state.update('uploadingPhoto', (uploadingPhoto) =>
    uploadingPhoto.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,
      errorData: data.status === ERROR ? data.errorData : Immutable.Map(),
    })
  );
}

function handleGetIssueDocuments(
  state: IssueState,
  data: GetIssueDocumentsAction['data']
): IssueState {
  switch (data.status) {
    case PENDING:
      return state.update('issueDocuments', (issueDocuments) =>
        (issueDocuments ?? new IssueDocumentsRecord()).merge({
          isFetching: true,
          responseErred: false,
        })
      );

    case SUCCESS:
      return state.set(
        'issueDocuments',
        new IssueDocumentsRecord({
          isFetching: false,
          responseErred: false,

          results: Immutable.fromJS(
            data.issueDocumentsResponse.results
          ) as Immutable.List<MapFromJs<ApiIssueDocument>>,
        })
      );

    case ERROR:
      return state.update('issueDocuments', (issueDocuments) =>
        (issueDocuments ?? new IssueDocumentsRecord()).merge({
          isFetching: false,
          responseErred: true,
        })
      );

    default:
      return state;
  }
}

function handleGetIssueElection(
  state: IssueState,
  data: GetIssueElectionAction['data']
): IssueState {
  return state.update('issueElectionRequest', (issueElectionRequest) =>
    issueElectionRequest.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,
      electionId:
        data.status === SUCCESS
          ? data.electionId
          : // Preserving the `merge` behavior from the pre-TS version of this
            // code, for better or worse.
            issueElectionRequest.electionId,
      errorData: data.status === ERROR ? data.errorData : {},
    })
  );
}

function handleSetIssueElection(
  state: IssueState,
  data: SetIssueElectionAction['data']
): IssueState {
  return state.update('issueElectionRequest', (issueElectionRequest) =>
    issueElectionRequest.set('electionId', data.electionId)
  );
}

function handleUploadIssueDocument(
  state: IssueState,
  data: UploadIssueDocumentAction['data']
): IssueState {
  return state.update('uploadDocument', (uploadDocument) =>
    uploadDocument.clear().merge({
      requestIsPending: data.status === PENDING,
      requestErred: data.status === ERROR,
      errorData: data.status === ERROR ? data.errorData : {},
    })
  );
}

// These 3 are separately-defined records because we need to instantiate them
// outside of the initial state.

export class IssueCommentsRecord extends Immutable.Record({
  isFetching: false,
  responseErred: false,
  results: Immutable.List<MapFromJs<ApiExpandedIssueComment>>(),
}) {}

export class IssuePhotosRecord extends Immutable.Record({
  isFetching: false,
  responseErred: false,
  results: Immutable.List<MapFromJs<ApiIssuePhoto>>(),
}) {}

export class IssueDocumentsRecord extends Immutable.Record({
  isFetching: false,
  responseErred: false,
  results: Immutable.List<MapFromJs<ApiIssueDocument>>(),
}) {}

/**
 * When we’re displaying an existing issue, we load in the detailed, fully
 * expanded version with all of these trimmings.
 */
export type ExistingIssueInProgress = DetailedApiIssue & {
  caller_voter_impacted?: 'yes' | 'no';
  history: ApiIssueHistory[];
  comments: Array<ApiExpandedIssueComment>;
  photos: ApiIssuePhoto[];
  documents: ApiIssueDocument[];
};

export type PhotoUploadData = {
  filename: string;
  filesize: number;
  header: string;
  data: string;
};

export type DocumentUploadData = {
  filename: string;
  filesize: number;
  header: string;
  data: string;
};

/**
 * When editing a new issue, we might not have all the data and the values won’t
 * be expanded.
 */
export type NewIssueInProgress = Nullable<Undefinable<ApiNewIssue>>;

export type IssueErrorData = {
  error?: string;
  error_message?: string;
} & {
  [field in keyof ApiIssue]?: string[];
};

export class IssueState extends Immutable.Record({
  toastData: new ToastRecord(),

  issueDetail: Immutable.Record({
    isFetching: false,
    responseErred: false,
  })(),

  updatingIssue: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    // Specifically not an Immutable type
    errorData: {} as IssueErrorData,
  })(),

  // TODO(fiona): Re-name to uploadingDocument for consistency.
  uploadDocument: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    // Specifically not an Immutable type
    errorData: {},
  })(),

  uploadingPhoto: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    // Specifically not an Immutable type
    errorData: {},
  })(),

  issueHistoryRequest: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
  })(),

  issueElectionRequest: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
    electionId: null as number | null,
    // Specifically not an Immutable type
    errorData: {},
  })(),

  /**
   * Note that the arrays declared in IssueInProgress will be Immutable.Lists in
   * this value.
   *
   * (We don’t have `NewIssueInProgress` here because new issues aren’t stored
   * in the Redux store.)
   */
  issueInProgress: Immutable.Map<keyof ExistingIssueInProgress, any>({}),
  creatingComment: Immutable.Record({
    requestIsPending: false,
    requestErred: false,
  })(),

  issueComments: undefined as IssueCommentsRecord | undefined,
  issuePhotos: undefined as IssuePhotosRecord | undefined,
  issueDocuments: undefined as IssueDocumentsRecord | undefined,
}) {}

export const initialState = new IssueState();

export default function issue(
  state: IssueState = initialState,
  action: AppAction
): IssueState {
  const {
    GET_ISSUE,
    GET_ISSUE_COMMENTS,
    CREATE_ISSUE_COMMENT,
    CREATE_ISSUE,
    ESCALATE_ISSUE,
    UPDATE_ISSUE,
    GET_ISSUE_HISTORY,
    RESET_ISSUE_IN_PROGRESS,
    GET_ISSUE_PHOTOS,
    UPLOAD_ISSUE_PHOTO,
    GET_ISSUE_DOCUMENTS,
    UPLOAD_ISSUE_DOCUMENT,
    GET_ISSUE_ELECTION,
    SET_ISSUE_ELECTION,
  } = actionTypes;

  switch (action.type) {
    case GET_ISSUE:
      return handleGetIssue(state, action.data);

    case GET_ISSUE_COMMENTS:
      return handleGetIssueComments(state, action.data);

    case CREATE_ISSUE_COMMENT:
      return handleCreateIssueComment(state, action.data);

    case CREATE_ISSUE:
      return handleIssueCRUD(state, action.data);

    case ESCALATE_ISSUE:
      return handleIssueCRUD(state, action.data);

    case UPDATE_ISSUE:
      return handleIssueCRUD(state, action.data);

    case GET_ISSUE_HISTORY:
      return handleGetIssueHistory(state, action.data);

    case RESET_ISSUE_IN_PROGRESS:
      return handleResetIssueInProgress(state);

    case SHOW_TOAST:
      return handleShowToast(state, action.data);

    case DISMISS_TOAST:
      return handleDismissToast(state);

    case GET_ISSUE_PHOTOS:
      return handleGetIssuePhotos(state, action.data);

    case UPLOAD_ISSUE_PHOTO:
      return handleUploadIssuePhoto(state, action.data);

    case GET_ISSUE_DOCUMENTS:
      return handleGetIssueDocuments(state, action.data);

    case UPLOAD_ISSUE_DOCUMENT:
      return handleUploadIssueDocument(state, action.data);

    case GET_ISSUE_ELECTION:
      return handleGetIssueElection(state, action.data);

    case SET_ISSUE_ELECTION:
      return handleSetIssueElection(state, action.data);

    default:
      return state;
  }
}
