import type { DOMProps } from '@react-types/shared';
import _ from 'lodash';
import React from 'react';

import { Item } from 'react-stately';

import {
  ActionButton,
  TagComboBox,
  TextButton,
} from '../../../components/form';
import { Checkbox } from '../../../components/form/Checkbox';

import { Dialog, Popover } from '../../../components/layout';
import { ApiUserTag, LanguageTag } from '../../../services/lbj-shared-service';
import { PersonFilterOptions } from '../assignment-utils';

/**
 * Makes a button and popover for filtering volunteers.
 */
const PersonFilter: React.FunctionComponent<{
  userLanguageTagInfos: ApiUserTag[];
  personFilters: PersonFilterOptions;
  setPersonFilters: (
    updater: (prev: PersonFilterOptions) => PersonFilterOptions
  ) => void;
  isLocked?: boolean | undefined;
  isDisabled?: boolean | undefined;
  openForTest?: boolean;
}> = ({
  userLanguageTagInfos,
  personFilters,
  setPersonFilters,
  isLocked = false,
  isDisabled = false,
  openForTest = false,
}) => {
  return (
    <Popover
      {...(openForTest ? { isOpen: true } : {})}
      type="menu"
      placement="bottom start"
      trigger={(props, ref) => (
        <ActionButton
          icon={isLocked ? 'lock' : 'filter_list'}
          role="toolbar"
          buttonRef={ref}
          isDisabled={isDisabled}
          {...props}
        >
          Filter
        </ActionButton>
      )}
      content={(props: DOMProps) => (
        <Dialog
          {...props}
          className="flex w-[300px] flex-col gap-2 rounded border border-gray-200 bg-white p-4 text-base leading-none"
        >
          <div className="flex flex-col gap-3">
            <div className="font-semibold">Assignment Status</div>
            <Checkbox
              isSelected={personFilters.edayAssignmentStatus === 'assigned'}
              isDisabled={isLocked}
              onChange={(checked) => {
                if (checked) {
                  setPersonFilters((prev) => ({
                    ...prev,
                    edayAssignmentStatus: 'assigned',
                  }));
                } else {
                  setPersonFilters((prev) => ({
                    ...prev,
                    edayAssignmentStatus: 'any',
                  }));
                }
              }}
            >
              Assigned on Election Day
            </Checkbox>
            <Checkbox
              isSelected={personFilters.edayAssignmentStatus === 'unassigned'}
              isDisabled={isLocked}
              onChange={(checked) => {
                if (checked) {
                  setPersonFilters((prev) => ({
                    ...prev,
                    edayAssignmentStatus: 'unassigned',
                  }));
                } else {
                  setPersonFilters((prev) => ({
                    ...prev,
                    edayAssignmentStatus: 'any',
                  }));
                }
              }}
            >
              Unassigned on Election Day
            </Checkbox>

            <hr />
            <div className="font-semibold">Volunteer Info</div>
            <Checkbox
              isSelected={personFilters.filterForExperience === 'yes'}
              onChange={(checked) => {
                if (checked) {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForExperience: 'yes',
                  }));
                } else {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForExperience: 'any',
                  }));
                }
              }}
            >
              Experienced
              <span className="material-icons text-base leading-none">
                military_tech
              </span>
            </Checkbox>
            <Checkbox
              isSelected={personFilters.filterForLegalCommunity === 'yes'}
              onChange={(checked) => {
                if (checked) {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForLegalCommunity: 'yes',
                  }));
                } else {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForLegalCommunity: 'any',
                  }));
                }
              }}
            >
              Legal Community Member
              <span className="material-icons text-base leading-none">
                gavel
              </span>
            </Checkbox>
            <Checkbox
              isSelected={personFilters.filterForCarAccess === 'yes'}
              onChange={(checked) => {
                if (checked) {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForCarAccess: 'yes',
                  }));
                } else {
                  setPersonFilters((prev) => ({
                    ...prev,
                    filterForCarAccess: 'any',
                  }));
                }
              }}
            >
              Has Car Access
              <span className="material-icons text-base leading-none">
                garage
              </span>
            </Checkbox>
            <hr />
            <TagComboBox
              label="Language(s) (besides English)"
              items={userLanguageTagInfos}
              selectedKeys={
                personFilters.filterForLanguages.type === 'all'
                  ? []
                  : personFilters.filterForLanguages.languages
              }
              onSelectionChange={(keys) =>
                typeof keys !== 'string' &&
                setPersonFilters((prev) => ({
                  ...prev,
                  filterForLanguages: {
                    type: 'languages',
                    languages: [...keys] as LanguageTag[],
                  },
                }))
              }
            >
              {(tagDatum) => (
                <Item key={tagDatum.name}>
                  {/* We remove the “Speaks” at the beginning of the tag name to make the list nicer. */}
                  {tagDatum.display_name.replace(/Speaks /, '')}
                </Item>
              )}
            </TagComboBox>
          </div>

          <div className="mt-4">
            {isLocked ? (
              <div className="flex items-center gap-2">
                <span className="material-icons text-gray-500">lock</span>
                <div className="flex-1 text-sm italic text-gray-700">
                  Filters are locked while reviewing suggested assignments.
                </div>
              </div>
            ) : (
              <TextButton
                onPress={() =>
                  setPersonFilters(() => ({
                    edayAssignmentStatus: 'any',
                    filterForExperience: 'any',
                    filterForLegalCommunity: 'any',
                    filterForCarAccess: 'any',
                    filterForLanguages: { type: 'all' },
                  }))
                }
              >
                Clear all filters
              </TextButton>
            )}
          </div>
        </Dialog>
      )}
    />
  );
};

export default PersonFilter;
