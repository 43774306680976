import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { assignmentViewTypes, lbjAppSections } from '../../../constants';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import mapStateToRequestedResource from '../../../utils/assignment/map-state-to-requested-resource';
import mapStateToStartEndTime from '../../../utils/assignment/map-state-to-start-end-time';
import RIT from '../../../utils/render-if-truthy';
import BoardOfElectionsDetails from '../../presentational/assignment/assignment-boe-details';
import BoilerDetails from '../../presentational/assignment/assignment-boiler-details';
import AssignmentDetailSummary from '../../presentational/assignment/assignment-detail-summary';
import FindUserForm from '../../presentational/assignment/assignment-find-user-form';
import LocationDetails from '../../presentational/assignment/assignment-location-details';
import PrecinctDetails from '../../presentational/assignment/assignment-precinct-details';
import UserDetails from '../../presentational/assignment/assignment-user-details';
import Sidebar from '../../presentational/lbj/sidebar';
import GoogleMap from '../maps/google-map-container';

import AssignmentDetailsForm from './assignment-details-form';
import FindBoardOfElectionsForm from './assignment-find-boe-form';
import FindBoilerForm from './assignment-find-boiler-form';
import FindPollingForm from './assignment-find-polling-form';
import FindPrecinctForm from './assignment-find-precinct-form';
import SubmissionControls from './assignment-submission-controls';

const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
} = assignmentViewTypes;

@connect((state) => {
  const { assignment, user } = state;
  const assignmentEditor = assignment.get('assignmentEditor');
  const assignmentType = assignmentEditor.get('assignmentType');
  const updatedFields = assignmentEditor.get('updatedFields');
  const isReadOnly =
    user.getIn(['currentUser', 'userData', 'role']) === 'boiler_room_user';
  const filters = state.filters.get(lbjAppSections.ASSIGNMENT);
  const { requestedResourceId } = mapStateToRequestedResource(state);
  const assigningShiftDate = updatedFields.get('shift_date');
  const { startTime, endTime } = mapStateToStartEndTime(
    state,
    assigningShiftDate
  );
  const electionState = user.getIn([
    'currentUser',
    'currentUserElection',
    'election',
    'state',
  ]);

  return {
    assignmentType,
    updatedFields,
    isReadOnly,
    filters,
    requestedResourceId,
    startTime,
    endTime,
    parentResource: assignmentEditor.get('parentResource'),
    shouldShowEditor: assignmentEditor.get('isAssigning'),
    electionState,
  };
})
export default class EditorPanel extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    requestedResourceId: PropTypes.number,
    assignmentView: PropTypes.string,
    assignmentType: PropTypes.string,
    parentResource: PropTypes.object,
    updatedFields: PropTypes.object,
    shouldShowEditor: PropTypes.bool,
    panelIsOpen: PropTypes.bool,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    electionState: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    // Close editor panel if parentResource is gone.
    const { parentResource, dispatch } = this.props;
    if (
      nextProps.parentResource.size === 0 &&
      parentResource.size !== 0 &&
      nextProps.panelIsOpen
    ) {
      dispatch(assignmentActionCreators.closeEditorPanel());
    }
  }

  shouldComponentUpdate(nextProps) {
    // Block component update if parentResource is gone and panel is not
    // yet closed.
    const { parentResource } = this.props;
    if (
      nextProps.parentResource.size === 0 &&
      parentResource.size !== 0 &&
      nextProps.panelIsOpen
    ) {
      return false;
    }
    return true;
  }

  onCloseEditor(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { closeEditorPanel } = assignmentActionCreators;

    dispatch(closeEditorPanel());
  }

  onEditAssignmentData(assignmentData) {
    const { dispatch, updatedFields } = this.props;
    const { saveUpdatedAssignmentFields } = assignmentActionCreators;
    const updatedAssignmentData = _.assign(
      updatedFields.toJS(),
      assignmentData
    );

    dispatch(saveUpdatedAssignmentFields(updatedAssignmentData));
  }

  renderSidebarTitle() {
    const { assignmentView, parentResource, panelIsOpen } = this.props;
    let displayName;

    if (panelIsOpen) {
      switch (assignmentView) {
        case PRECINCTS:
          displayName = parentResource.getIn(['precinct', 'name']);
          break;

        case LOCATIONS:
          displayName = parentResource.getIn(['location', 'name']);
          break;

        case PEOPLE: {
          const volunteer = parentResource.get('user');
          displayName = (
            <Link to={`/users/${volunteer.get('id')}`}>
              {`${volunteer.get('first_name')} ${volunteer.get('last_name')}`}
            </Link>
          );
          break;
        }

        case BOILER_ROOMS:
        case HOTLINES:
          displayName = parentResource.getIn(['boiler_room', 'name']);
          break;

        case BOARDS_OF_ELECTIONS:
          displayName = parentResource.getIn(['board_of_elections', 'name']);
          break;
      }
    }

    return (
      <span className="assignment-panel-title">
        <span
          className="lbj-fake-link assignment-close-editor"
          onClick={this.onCloseEditor.bind(this)}
        />
        <span className="assignment-details-title-text">{displayName}</span>
      </span>
    );
  }

  renderResourceDetails() {
    const { assignmentView, parentResource, shouldShowEditor } = this.props;

    switch (assignmentView) {
      case PEOPLE: {
        const user = parentResource.get('user');
        return <UserDetails user={user} />;
      }

      case BOILER_ROOMS:
      case HOTLINES:
        return <BoilerDetails boilerRoom={parentResource.get('boiler_room')} />;

      case BOARDS_OF_ELECTIONS:
        return (
          <BoardOfElectionsDetails
            boardOfElections={parentResource.get('board_of_elections')}
          />
        );

      case PRECINCTS: {
        const location = parentResource.get('location');
        const precinctIsGeoCoded = location
          ? location.hasIn(['coordinates', 0])
          : false;
        return (
          <PrecinctDetails
            precinct={parentResource.get('precinct')}
            location={location}
          >
            {RIT(precinctIsGeoCoded && !shouldShowEditor, () => {
              return <GoogleMap center={location.get('coordinates')} />;
            })}
          </PrecinctDetails>
        );
      }

      case LOCATIONS:
      default: {
        const locationIsGeoCoded = parentResource.hasIn([
          'location',
          'coordinates',
          0,
        ]);

        return (
          <LocationDetails location={parentResource.get('location')}>
            {RIT(locationIsGeoCoded && !shouldShowEditor, () => {
              return (
                <GoogleMap
                  center={parentResource.getIn(['location', 'coordinates'])}
                />
              );
            })}
          </LocationDetails>
        );
      }
    }
  }

  renderPlaceForm() {
    const { assignmentView, assignmentType, updatedFields, parentResource } =
      this.props;
    const pollingFormState =
      parentResource.getIn(['user', 'assignment_state']) ||
      parentResource.getIn(['assignment', 'user', 'assignment_state']);

    switch (assignmentType) {
      case 'poll':
        return (
          <FindPollingForm
            chooseLocationFirst={assignmentView === LOCATIONS}
            precinct={updatedFields.get('precinct')}
            location={updatedFields.get('location')}
            assignmentState={pollingFormState}
            shiftDate={updatedFields.get('shift_date')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );

      case 'hotline_center':
      case 'hotline_manager':
        return (
          <FindBoilerForm
            hotlineCenters
            assignmentState={parentResource.getIn(['user', 'assignment_state'])}
            boilerRoom={updatedFields.get('boiler_room')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );

      case 'boiler_room':
        return (
          <FindBoilerForm
            assignmentState={parentResource.getIn(['user', 'assignment_state'])}
            boilerRoom={updatedFields.get('boiler_room')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );

      case 'board_of_elections':
        return (
          <FindBoardOfElectionsForm
            boardOfElections={updatedFields.get('board_of_elections')}
            onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          />
        );
    }
  }

  renderUserForm() {
    const { assignmentView, electionState, parentResource, updatedFields } =
      this.props;
    const user = updatedFields.get('user');
    const formProps = {
      user,
      onEditAssignmentData: this.onEditAssignmentData.bind(this),
    };

    formProps.assignmentState = electionState;
    switch (assignmentView) {
      case PRECINCTS:
        formProps.includePlaceStatus = true;
        formProps.geoFilterByLocation = parentResource.getIn([
          'location',
          'id',
        ]);
        formProps.county = parentResource.getIn(['precinct', 'county']);
        break;

      case LOCATIONS:
        formProps.includePlaceStatus = true;
        formProps.geoFilterByLocation = parentResource.getIn([
          'location',
          'id',
        ]);
        formProps.county = parentResource.getIn(['location', 'county']);
        break;
    }
    return <FindUserForm {...formProps} />;
  }

  renderAssignmentEditor() {
    const {
      parentResource,
      assignmentView,
      updatedFields,
      startTime,
      endTime,
    } = this.props;
    const requirePrecinctPicker = assignmentView === LOCATIONS;
    const defaultPrecinct = updatedFields.hasIn(['precinct', 'id'])
      ? updatedFields.get('precinct')
      : null;
    const showChangeLocationForm =
      parentResource.has('assignment') &&
      (assignmentView === LOCATIONS || assignmentView === PRECINCTS);

    return (
      <div>
        <AssignmentDetailsForm
          requirePrecinctPicker={requirePrecinctPicker}
          defaultPrecinct={defaultPrecinct}
          onEditAssignmentData={this.onEditAssignmentData.bind(this)}
          startTime={startTime}
          endTime={endTime}
        >
          {RIT(requirePrecinctPicker, () => {
            return (
              <FindPrecinctForm
                location={parentResource.get('location')}
                precinct={defaultPrecinct}
                onEditAssignmentData={this.onEditAssignmentData.bind(this)}
              />
            );
          })}
        </AssignmentDetailsForm>

        {RIT(
          parentResource.has('user') || showChangeLocationForm,
          this.renderPlaceForm.bind(this)
        )}
        {RIT(!parentResource.has('user'), this.renderUserForm.bind(this))}
        <SubmissionControls />
      </div>
    );
  }

  renderReadOnlyAssignment() {
    const { assignmentType, parentResource } = this.props;
    let specifiedPrecinct;
    const assignment = parentResource.get('assignment');

    if (assignmentType === 'poll') {
      specifiedPrecinct = assignment.get('precinct')
        ? assignment.getIn(['precinct', 'name']) ||
          parentResource.getIn(['precinct', 'name'])
        : 'All precincts';
    }

    return (
      <div>
        <AssignmentDetailSummary
          shiftDate={assignment.get('shift_date')}
          startTime={assignment.get('start_time')}
          endTime={assignment.get('end_time')}
          placeStatus={assignment.get('place_status')}
          notes={assignment.get('notes')}
          assignmentType={assignmentType}
          specifiedPrecinct={specifiedPrecinct}
        />
      </div>
    );
  }

  render() {
    const { shouldShowEditor, panelIsOpen, isReadOnly, parentResource } =
      this.props;
    const classNameProps = panelIsOpen
      ? { className: 'assignment-sidebar' }
      : { className: 'is-offscreen' };
    const renderReadOnlyView =
      shouldShowEditor && isReadOnly && parentResource.has('assignment');

    return (
      <Sidebar
        title={this.renderSidebarTitle()}
        collapsible={false}
        showMemorial={false}
        {...classNameProps}
      >
        {RIT(panelIsOpen, this.renderResourceDetails.bind(this))}
        {RIT(
          shouldShowEditor && !isReadOnly,
          this.renderAssignmentEditor.bind(this)
        )}
        {RIT(renderReadOnlyView, this.renderReadOnlyAssignment.bind(this))}
      </Sidebar>
    );
  }
}
