import type { GridNode, GridCollection } from '@react-types/grid';
import type { Node as CollectionNode } from '@react-types/shared';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import cx from 'classnames';

import React from 'react';

import {
  mergeProps,
  useFocusRing,
  useTable,
  useTableCell,
  useTableRow,
  useTableRowGroup,
  useTableHeaderRow,
  useTableColumnHeader,
  AriaTableProps,
  useFilter,
} from 'react-aria';
import {
  TableStateProps,
  TableState,
  useTableState,
  Item,
} from 'react-stately';

import { FilteredMenu, IconButton } from '../../components/form';

import { assertUnreachable } from '../../utils/types';

/**
 * Wrapper data type for rows to render. "header" rows should preceed the "item"
 * rows that belong under that header.
 */
export type AssignmentTableRow<I> =
  | {
      type: 'item';
      key: React.Key;
      value: I;
    }
  | {
      type: 'header';
      key: React.Key;
      title: string;
    };

/**
 * Data type for a table grouping. Has a title and also contains the row that it
 * starts on so that we can jump to it.
 */
export type AssignmentTableGroupHeader = {
  title: string;
  key: React.Key;
  rowIndex: number;
};

const GROUP_HEADER_ROW_HEIGHT_PX = 32;

/**
 * react-aria `useTable` implementation.
 *
 * Supports sticky group headers. Virtualizes the rendering of the table with
 * `@tanstack/react-virtual`.
 *
 * This is somewhat more general that the location table where it’s used right
 * now, but still kept in the `pages/assign` directory because its design is
 * built for auto-assignments, and is not guaranteed to be generally-useful in
 * LBJ.
 */
export function AssignmentTable<I>({
  headerRowHeight,
  overscan = 1,
  estimateSize,
  selectedCellKey,
  minWidth,
  flushRight,
  tableRef: propsTableRef,
  ...props
}: TableStateProps<AssignmentTableRow<I>> &
  AriaTableProps<AssignmentTableRow<I>> & {
    /**
     * Height of the sticky header at the very top of the table.
     */
    headerRowHeight: number;
    /**
     * Minimum width for the table (will trigger horizontal scroll if bigger
     * than available space).
     *
     * Necessary here because `<td>` elements can’t have individual min-widths.
     */
    minWidth?: number | undefined;

    flushRight?: boolean | undefined;

    /**
     * Number of rows to render outside of the visible area. Used to make fast
     * scrolling not go blank as much.
     */
    overscan?: number | undefined;
    /**
     * Function to return the size of a particular item row. Not used for the
     * group header rows, since they are rendered internally by this component.
     */
    estimateSize: (index: number) => number;
    /**
     * If a cell is selected, its key. We do this outside of react-aria because
     * it tracks selection on a row-by-row basis for tables.
     */
    selectedCellKey?: React.Key | null | undefined;

    tableRef?: React.MutableRefObject<HTMLTableElement | null> | undefined;
  }) {
  const state = useTableState(props);

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const backupTableRef = React.useRef<HTMLTableElement | null>(null);
  const tableRef = propsTableRef ?? backupTableRef;

  const { collection } = state;

  const groupHeaders = React.useMemo(
    () =>
      [...collection]
        // map before filter so that idx is correct
        .map<AssignmentTableGroupHeader | null>((row, idx) =>
          row.value?.type === 'header'
            ? {
                title: row.value.title,
                rowIndex: idx,
                key: row.key,
              }
            : null
        )
        .filter((g): g is AssignmentTableGroupHeader => !!g),
    [collection]
  );

  /**
   * Set of indices that are our sticky group headers so that we can quickly
   * tell by index the row’s height, &c.
   */
  const groupHeaderRowIndices = React.useMemo(() => {
    const indexSet = new Set<number>();

    groupHeaders.forEach(({ rowIndex }) => {
      indexSet.add(rowIndex);
    });

    return indexSet;
  }, [groupHeaders]);

  const items = [...collection];

  const tableVirtualizer = useVirtualizer({
    count: items.length,
    overscan,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: (index) =>
      groupHeaderRowIndices.has(index)
        ? GROUP_HEADER_ROW_HEIGHT_PX
        : estimateSize(index),
    getItemKey: (index) => items[index]!.key,
    // Because the group header rows are sticky to the top of the table, we
    // always want to make sure we’re rendering the group header for whatever
    // the first rendered row is.
    //
    // This code looks for the first group header that comes before the start of
    // the range, and makes sure to include it in the list of indices to render.
    rangeExtractor: (range) => {
      const indices = defaultRangeExtractor(range);

      if (groupHeaderRowIndices.size > 0) {
        const groupHeaderRowIndexArr = [...groupHeaderRowIndices];
        groupHeaderRowIndexArr.reverse();

        const previousGroupHeaderRowIndex = groupHeaderRowIndexArr.find(
          (idx) => idx <= range.startIndex
        )!;

        if (!indices.includes(previousGroupHeaderRowIndex)) {
          indices.unshift(previousGroupHeaderRowIndex);
        }
      }

      return indices;
    },
    // Reserves space at the top for the sticky header to appear.
    paddingStart: headerRowHeight,
  });

  const { gridProps } = useTable(
    { ...props, isVirtualized: true, focusMode: 'cell' },
    state,
    tableRef
  );

  /**
   * Last row index that had focus.
   *
   * We use this to distinguish between focus changes from jumping to a location
   * vs. ones from arrowing around the table.
   */
  const lastFocusedRowIndexRef = React.useRef(-1);

  // This useEffect is here to make sure that the focused cell is appropriately
  // scrolled to.
  //
  // We accommodate focus changes from arrowing around as well as those from
  // jumping (e.g. via the sticky header jump buttons).
  React.useEffect(
    () => {
      let rowKey = state.selectionManager.focusedKey;

      if (rowKey === null) {
        // Nothing focused, return.
        lastFocusedRowIndexRef.current = -1;
        return;
      }

      let rowItem = collection.getItem(rowKey)!;
      let cellItem: GridNode<AssignmentTableRow<I>> | null = null;

      if (rowItem.type === 'cell') {
        // The current focus is on a cell, so use `parentKey` to find the
        // associated row so we can scroll to it, but keep the `cellItem` around
        // for horizontal scrolling.
        cellItem = rowItem;

        rowKey = cellItem.parentKey!;
        rowItem = collection.getItem(rowKey)!;
      }

      // Mostly a type safety check. Rows are “items” since they’re the record
      // that’s passed in the main array for the collection.
      if (rowItem?.type !== 'item' || typeof rowItem?.index !== 'number') {
        lastFocusedRowIndexRef.current = -1;
        return;
      }

      // First we want to scroll the row vertically into view.
      const rowIndex = rowItem.index;

      let topPadding = headerRowHeight;

      if (rowItem.value?.type === 'item' && groupHeaders.length > 0) {
        // If we’re scrolling to an item that will be beneath a sticky group
        // header, we need to account for that header’s height. Otherwise the
        // top of the row will be cut off by the sticky header.
        topPadding += GROUP_HEADER_ROW_HEIGHT_PX;
      }

      // This is the “automatic” offset. If the row is already fully visible,
      // this will return the scroll container’s current offset and an
      // alignment of “auto.” If the row is below the bottom of the scroll,
      // this will return an align of “end” and the offset will be enough to
      // scroll the element to the very bottom of the screen.
      //
      // Note that an align of “auto” might still cause the item to be
      // obscured by the sticky headers, hence the checks below with
      // startScrollOffset.
      const [scrollOffset, align] = tableVirtualizer.getOffsetForIndex(
        rowIndex,
        'auto'
      );

      // This is the offset that would put the element at the very top of the
      // screen (technically probably covered by the sticky headers).
      const [startScrollOffset] = tableVirtualizer.getOffsetForIndex(
        rowIndex,
        'start'
      );

      /**
       * True if the row is above the top of the list, either completely
       * scrolled off or underneath the sticky headers.
       */
      const rowScrolledOffTop =
        startScrollOffset - tableVirtualizer.scrollOffset < topPadding;

      if (
        rowScrolledOffTop ||
        // Handles the case where we are jumping to a header that’s not
        // currently visible in the list, in which case we always scroll it to
        // the top of the page. The check against lastFocusedRowIndexRef is so
        // that we don’t trigger this case if you’re arrowing focus down
        // row-by-row, in which case we want the group header row to just
        // appear at the bottom like any other row.
        (rowItem.value?.type === 'header' &&
          align !== 'auto' &&
          lastFocusedRowIndexRef.current !== rowIndex - 1)
      ) {
        // Scrolls the row to the top, taking into account the top padding due
        // to sticky headers.
        tableVirtualizer.scrollToOffset(startScrollOffset - topPadding);
      } else if (align === 'end') {
        // Scroll so the element is at the bottom.
        //
        // If there’s a horizontal scroll bar, take its height into account.
        const horizontalScrollBarHeight = scrollContainerRef.current
          ? scrollContainerRef.current.offsetHeight -
            scrollContainerRef.current.clientHeight
          : 0;

        tableVirtualizer.scrollToOffset(
          scrollOffset + horizontalScrollBarHeight
        );
      }

      lastFocusedRowIndexRef.current = rowIndex;

      const scrollEl = scrollContainerRef.current;
      const tableEl = tableRef.current;

      // Now we scroll horizontally to get the cell into view.
      if (cellItem && scrollEl && tableEl) {
        // Set immediate so that we run after the scroll container does any size
        // changes related to selecting a cell, such as the introduction of the
        // side panel.
        //
        // TODO(fiona): Find a more principled way of doing this.
        window.setImmediate(() => {
          const numStickyColumns = collection.rowHeaderColumnKeys.size;

          // We assume for now that all the sticky columns are at the start of
          // the row.
          if (cellItem!.index! < numStickyColumns) {
            return;
          }

          const stickyColumnsWidth = [...collection.rowHeaderColumnKeys].reduce(
            (acc: number, key) => {
              const col = collection.columns.find((c) => c.key === key);

              if (col && col.props.width) {
                // + 1 for the border
                return acc + col.props.width + 1;
              } else {
                return acc;
              }
            },
            0
          );

          // We assume that each cell other than the sticky ones takes up a
          // consistent amount of space.
          const cellWidth =
            (tableEl.clientWidth - stickyColumnsWidth) /
            (collection.columnCount - numStickyColumns);

          /**
           * The amount of the non-sticky table content that can be seen.
           *
           * offsetLeft is included to account for the left padding on the scroll container.
           */
          const tableVisibleWidth =
            scrollEl.clientWidth - stickyColumnsWidth - tableEl.offsetLeft;

          /**
           * What left-most offset within the table’s non-sticy columns is currently
           * visible.
           *
           * (We don‘t have to worry about the sticky widths because we assume
           * that if we’re scrolled to 0 that the left-most column is fully
           * visible.)
           */
          const tableVisibleLeft = scrollEl.scrollLeft;

          /**
           * What right-most offset within the table’s non-sticky columns is
           * currently visible.
           */
          const tableVisibleRight = tableVisibleLeft + tableVisibleWidth;
          const cellLeft = cellWidth * (cellItem!.index! - numStickyColumns);
          const cellRight = cellLeft + cellWidth;

          if (cellLeft < tableVisibleLeft) {
            scrollEl.scrollBy({ left: cellLeft - tableVisibleLeft });
          } else if (cellRight > tableVisibleRight) {
            scrollEl.scrollBy({ left: cellRight - tableVisibleRight });
          }
        });
      }
    },
    // We re-scroll if the focusedKey changes (from clicking or moving it with
    // the keyboard) but also if the size of the collection changes, likely due
    // to filtering or picking a specific day.
    //
    // TODO(fiona): Need to re-scroll if changing sort options?
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectionManager.focusedKey, collection.size]
  );

  const virtualRows = tableVirtualizer.getVirtualItems();
  const rowComponents: JSX.Element[] = [];

  React.useEffect(() => {
    // Safety for programmers. Accidentally ran into this and was trying to
    // figure out why the page was using gigabytes of memory.
    if (virtualRows.length > 200) {
      console.warn(
        `WARNING: Rendering ${virtualRows.length} rows. Make sure you haven’t broken the height constraint on the scroll container`,
        scrollContainerRef.current
      );
    }
  }, [virtualRows.length]);

  // This amount of space is already being rendered by the header, so we don’t
  // need to account for it.
  let lastElBottomPx = headerRowHeight;

  // `useVirtualizer` provides the expected top and bottom pixel positions for
  // every row that it renders. Because we’re rendering into a `<table>`, we
  // need to account for any empty space by filling it with blank rows,
  // otherwise the browser will re-distribute that empty space into the rows
  // that we _are_ rendering.
  //
  // That way the <table> element is the full height that it would be if every
  // row were rendered (so that the scroll position is correct) but we’re only
  // actually rendering a fraction of its children.
  //
  // There’s typically always blank space at the start and end, but there can be
  // spaces between rows because we always render the current sticky group
  // header but might not be rendering the locations between it and the first
  // location in the table.
  for (const virtualRow of virtualRows) {
    if (virtualRow.start > lastElBottomPx) {
      rowComponents.push(
        <tr
          key={`spacer-${virtualRow.index}`}
          className="border-0"
          style={{ height: virtualRow.start - lastElBottomPx }}
        />
      );
    }

    const item = items[virtualRow.index]!;
    const row = item.value!;

    switch (row.type) {
      case 'header':
        rowComponents.push(
          <TableGroupHeaderRow
            key={item.key}
            collection={collection}
            item={item}
            state={state}
            isVirtualized
            headerRowHeight={headerRowHeight}
            measureElement={tableVirtualizer.measureElement}
            groupHeaders={groupHeaders}
          />
        );
        break;

      case 'item':
        rowComponents.push(
          <TableRow
            key={item.key}
            item={item}
            state={state}
            isVirtualized
            measureElement={tableVirtualizer.measureElement}
          >
            {[...collection.getChildren!(item.key)].map((cell) => (
              <TableCell
                key={cell.key}
                cell={cell}
                state={state}
                isVirtualized
                isSelected={cell.key === selectedCellKey}
              />
            ))}
          </TableRow>
        );
        break;

      default:
        assertUnreachable(row);
    }

    lastElBottomPx = virtualRow.end;
  }

  // Have to add a spacer at the end, too, to account for the un-rendered rows
  // below what we’re rendering.
  if (lastElBottomPx < tableVirtualizer.getTotalSize()) {
    rowComponents.push(
      <tr
        key="spacer-end"
        className="border-0"
        style={{ height: tableVirtualizer.getTotalSize() - lastElBottomPx }}
      />
    );
  }

  return (
    <div
      ref={scrollContainerRef}
      // overscroll-contain keeps the browser from doing forward/back if you
      // scroll with horizontal gestures.
      //
      // TODO(fiona): The left padding exposes non-sticky rows when scrolling
      // all the way to the right. Fix it up.
      className={cx(
        'relative flex-1 basis-0 overflow-x-auto overflow-y-scroll overscroll-contain pl-4 pb-4',
        {
          'pr-4': !flushRight,
        }
      )}
    >
      <table
        {...gridProps}
        ref={tableRef}
        // border-separate to keep the sticky <th>s from losing their borders
        // when scrolling.
        //
        // All the cells are responsible for bottom and right borders, we just
        // do the left.
        //
        // TODO(fiona): Hide the right border when we’re flush-right
        className={cx(
          'relative m-0 w-full table-fixed border-separate overflow-clip border-l border-gray-300',
          {
            'min-w-full': minWidth === undefined,
          }
        )}
        style={{ minWidth }}
      >
        {/* Keep header above everything else in the table */}
        <TableRowGroup type="thead" className="relative z-10">
          {collection.headerRows.map((headerRow) => (
            <TableHeaderRow
              key={headerRow.key}
              item={headerRow}
              state={state}
              height={headerRowHeight}
              isVirtualized
            >
              {[...collection.getChildren!(headerRow.key)].map((column) => (
                <TableColumnHeader
                  key={column.key}
                  column={column}
                  state={state}
                  height={headerRowHeight}
                  isVirtualized
                />
              ))}
            </TableHeaderRow>
          ))}
        </TableRowGroup>

        <TableRowGroup type="tbody" className="relative z-0">
          {rowComponents}
        </TableRowGroup>
      </table>
    </div>
  );
}

/**
 * For rendering `<thead>` or `<tbody>`.
 */
const TableRowGroup: React.FunctionComponent<{
  type: keyof React.ReactHTML;
  className?: string | undefined;
}> = ({ type: Element, className, children }) => {
  const { rowGroupProps } = useTableRowGroup();

  return (
    <Element {...rowGroupProps} className={className}>
      {children}
    </Element>
  );
};

/**
 * For rendering a `<tr>` for the header cells at the very top of the table, or
 * a `<tr>` in the middle for a group header.
 *
 * Needs an explicit height because its contents are positioned "sticky".
 */
const TableHeaderRow: React.FunctionComponent<{
  item: CollectionNode<unknown>;
  state: TableState<unknown>;
  children: React.ReactNode | React.ReactNode[];
  isVirtualized?: boolean | undefined;
  height: number;
  className?: string | undefined;
  /**
   * If this is being rendered as part of the virtualized section of the table,
   * pass in measureElement so we can send its height calculation back into the
   * cache.
   */
  measureElement?: ((el: Element | null) => void) | undefined;
}> = ({
  item,
  isVirtualized = false,
  state,
  children,
  height,
  className,
  measureElement,
}) => {
  const rowRef = React.useRef(null);
  const { rowProps } = useTableHeaderRow(
    { node: item, isVirtualized },
    state,
    rowRef
  );

  const mergedRef = React.useCallback(
    (el) => {
      rowRef.current = el;
      measureElement?.(el);
    },
    [measureElement]
  );

  return (
    <tr
      ref={mergedRef}
      data-index={item.index}
      style={{ height }}
      className={className}
      {...rowProps}
    >
      {children}
    </tr>
  );
};

/**
 * Renders one of the sticky header cells in the top row of the table.
 *
 * Sets an explicit width from the original `<Column>`’s props so that it can be
 * used with `table-fixed` tables.
 */
const TableColumnHeader: React.FunctionComponent<{
  column: GridNode<unknown>;
  isVirtualized?: boolean;
  state: TableState<unknown>;
  height?: number;
}> = ({ column, height, isVirtualized = false, state }) => {
  const cellRef = React.useRef(null);

  const { columnHeaderProps } = useTableColumnHeader(
    { node: column, isVirtualized },
    state,
    cellRef
  );

  // TODO(fiona): Render nice focus ring
  const { focusProps } = useFocusRing();

  const columnIsRowHeader = state.collection.rowHeaderColumnKeys.has(
    column.key
  );

  return (
    <th
      ref={cellRef}
      colSpan={column.colspan}
      className={`sticky top-0 border-0 border-y border-r border-gray-300 bg-white py-1 px-2 ${
        // need z-10 to get above the other headers in this row, which are
        // sticky as well, so that they will horizontally scroll underneath it.
        columnIsRowHeader ? 'left-0 z-10' : ''
      }`}
      style={{
        width: column.props?.width,
        height,
      }}
      {...mergeProps(columnHeaderProps, focusProps)}
    >
      {column.rendered}
    </th>
  );
};

/**
 * Component to render a sticky “group” header for e.g. grouping by county or
 * tier or city.
 *
 * Includes a “jump” button that lets you easily go to another group.
 *
 * Delegates to {@link TableHeaderRow} for `<tr>` rendering, and also handles
 * rendering the `<td>`s for the row, which are a little special.
 */
const TableGroupHeaderRow: React.FunctionComponent<{
  item: CollectionNode<unknown>;
  collection: GridCollection<unknown>;
  state: TableState<unknown>;
  isVirtualized?: boolean | undefined;
  headerRowHeight: number;
  measureElement?: ((el: Element | null) => void) | undefined;

  /**
   * List of all {@link AssignmentTableGroupHeader}s so we can render the jump
   * menu.
   */
  groupHeaders: AssignmentTableGroupHeader[];
}> = ({
  item,
  collection,
  state,
  isVirtualized = false,
  headerRowHeight,
  measureElement,
  groupHeaders,
}) => {
  /**
   * The `firstChildNode` will have the group header’s title. The rest are just
   * filler necessary because `react-stately` enforces that all rows have the
   * full number of `<Item>`s, one for each declared column.
   */
  const [firstChildNode, ...restChildNodes] = [
    ...collection.getChildren!(item.key),
  ];

  const cellRef = React.useRef(null);
  const { gridCellProps } = useTableCell(
    { node: firstChildNode!, isVirtualized },
    state,
    cellRef
  );

  const jumpButtonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <TableHeaderRow
      item={item}
      state={state}
      isVirtualized={isVirtualized}
      height={GROUP_HEADER_ROW_HEIGHT_PX}
      measureElement={measureElement}
      // Ensures we’re above any relatively-positioned elements within table
      // cells
      className="relative z-10"
    >
      <th
        ref={cellRef}
        // We want to sticky to just below the existing sticky header row.
        style={{ top: headerRowHeight }}
        // z-10 to stay above the other cells in this row, which are only sticky
        // vertically, not horizontally.
        className="sticky left-0 z-10 border-b border-gray-300 bg-gray-100 px-2 text-base leading-snug"
        {...gridCellProps}
        onClick={() => jumpButtonRef.current?.click()}
      >
        <div className="flex items-center gap-2">
          <TableGroupHeaderJumpButton
            triggerRef={jumpButtonRef}
            gridCellKey={firstChildNode!.key}
            gridCellOnKeyDown={gridCellProps.onKeyDown}
            state={state}
            groupHeaders={groupHeaders}
          />

          <div
            className="cursor-default"
            onClick={() => jumpButtonRef.current?.click()}
          >
            {firstChildNode!.rendered}
          </div>
        </div>
      </th>

      {restChildNodes.map((childItem) => (
        <TableGroupHeaderFillerCell
          key={childItem.key}
          cell={childItem}
          isVirtualized={isVirtualized}
          state={state}
          headerRowHeight={headerRowHeight}
          jumpButtonRef={jumpButtonRef}
        />
      ))}
    </TableHeaderRow>
  );
};

/**
 * Renders a “jump” button to send focus to any of the group headers in the
 * table (scrolling to them automatically).
 */
const TableGroupHeaderJumpButton: React.FunctionComponent<{
  state: TableState<unknown>;
  triggerRef: React.RefObject<HTMLElement>;
  groupHeaders: AssignmentTableGroupHeader[];
  gridCellKey: React.Key;
  gridCellOnKeyDown: React.KeyboardEventHandler<HTMLElement> | undefined;
}> = ({ state, triggerRef, groupHeaders, gridCellKey, gridCellOnKeyDown }) => {
  // TODO(fiona): Switch to a better searching algorithm
  const { contains } = useFilter({ sensitivity: 'base' });

  return (
    <FilteredMenu
      selectionStyle="menu"
      triggerRef={triggerRef}
      trigger={(props, ref) => (
        <IconButton
          {...props}
          // The default `<Menu>` behavior when pressing up or down arrow when
          // the trigger button is focused is to open the menu. That becomes
          // really annoying when trying to use the arrow keys to just arrow
          // around the table, so we intercept those events and pass them to the
          // grid cell’s keyboard handler.
          //
          // Users can still use space or return to open the menu.
          onKeyDown={(ev) => {
            if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
              // If the table isn’t focused, which can happen if the
              // trigger element has focus because the menu was opened and
              // then closed via clicking outside of it, pretend that our
              // current cell is focused so that up and down handling will
              // work correctly.
              if (!state.selectionManager.isFocused) {
                state.selectionManager.setFocusedKey(gridCellKey);
              }

              return gridCellOnKeyDown?.(ev);
            } else {
              return props.onKeyDown?.(ev);
            }
          }}
          icon="menu_open"
          buttonRef={ref}
          title="Jump To…"
          className="block"
        />
      )}
      noMatchMessage="No matches found"
      items={groupHeaders}
      filterItem={(value, group) => contains(group.title, value)}
      filterFieldProps={{
        'aria-label': 'Filter navigation options',
        placeholder: 'Jump to…',
      }}
      onAction={(rowKey) => {
        const children = state.collection.getChildren!(rowKey);
        // We want to focus on the first cell in the target row, which is the
        // one with its jump button.
        const firstCellKey = [...children][0]?.key!;

        // We do this in a setImmediate so that the menu closing and
        // setting its focus to its button happens before we try to set
        // focus into the table.
        window.setImmediate(() =>
          state.selectionManager.setFocusedKey(firstCellKey)
        );
      }}
      // We disable the table’s keyboard navigation when the menu is open to
      // prevent its “focus on rows you type” behavior from activating while the
      // user is typing in the menu’s filter box or the menu itself.
      onOpenChange={(isOpen) => {
        if (isOpen) {
          // We need to use setImmediate, otherwise clicking on the button when it
          // doesn’t have focus causes it to immediately open then close, making
          // it look like it didn’t work.
          window.setImmediate(() => state.setKeyboardNavigationDisabled(true));
        } else {
          // But we can’t use it in the close case.
          state.setKeyboardNavigationDisabled(false);
        }
      }}
    >
      {({ title }) => <Item>{title}</Item>}
    </FilteredMenu>
  );
};

/**
 * This “filler” cell pads out the rest of the group header row. It serves two
 * purposes:
 *
 * - Visually continuing the row. The jump cell itself cannot span the entire
 *   row because then its contents will scroll horizontally rather than
 *   sticking.
 * - Catching focus events from arrowing up from the row below and moving that
 *   focus over to the jump button.
 */
const TableGroupHeaderFillerCell: React.FunctionComponent<{
  cell: GridNode<unknown>;
  isVirtualized?: boolean | undefined;
  state: TableState<unknown>;
  headerRowHeight: number;
  jumpButtonRef: React.RefObject<HTMLElement>;
}> = ({
  cell,
  isVirtualized = false,
  state,
  headerRowHeight,
  jumpButtonRef,
}) => {
  const cellRef = React.useRef(null);
  const { gridCellProps } = useTableCell(
    { node: cell, isVirtualized },
    state,
    cellRef
  );

  return (
    <td
      ref={cellRef}
      // border-r only on the last cell to give the illusion that this is one
      // merged cell across the entire row.
      className="sticky border-b border-gray-300 bg-gray-100 last:border-r"
      style={{ top: headerRowHeight }}
      {...gridCellProps}
      // We want all interactions with this cell to be like interacting with the
      // jump button.
      //
      // We only send the _browser_ focus over to the jump button, preserving
      // the table’s internal tracking of the focused cell. This means that if
      // you’re focused on cells in one of the day columns and press up, focus
      // will move to the jump button, but if you press up again, focus will go
      // to the cell in the same column you started in, rather than the
      // left-most column of the table.
      onFocus={() => jumpButtonRef.current?.focus()}
      onClick={() => jumpButtonRef.current?.click()}
    />
  );
};

/**
 * Straightforward `<tr>`. Renders zebra stripes and sends measurements back to
 * `react-virtual`.
 */
const TableRow: React.FunctionComponent<{
  item: CollectionNode<unknown>;
  isVirtualized?: boolean | undefined;
  state: TableState<unknown>;
  measureElement?: ((el: Element | null) => void) | undefined;
}> = ({ item, isVirtualized = false, children, state, measureElement }) => {
  const rowRef = React.useRef(null);
  const { rowProps } = useTableRow(
    {
      node: item,
      isVirtualized,
    },
    state,
    rowRef
  );

  // TODO(fiona): Render nice focus ring
  const { focusProps } = useFocusRing();

  const mergedRef = React.useCallback(
    (el) => {
      rowRef.current = el;
      measureElement?.(el);
    },
    [measureElement]
  );

  return (
    <tr
      ref={mergedRef}
      data-index={item.index}
      className={`${(item.index ?? 0) % 2 === 1 ? 'bg-white' : 'bg-gray-200'}`}
      {...mergeProps(rowProps, focusProps)}
    >
      {children}
    </tr>
  );
};

/**
 * Renders a basic `<td>` with its cell contents.
 *
 * If the cell is a row header, renders the cell as sticky.
 */
const TableCell: React.FunctionComponent<{
  cell: GridNode<unknown>;
  isVirtualized?: boolean | undefined;
  state: TableState<unknown>;
  isSelected: boolean;
}> = ({ cell, isVirtualized = false, state, isSelected }) => {
  const cellRef = React.useRef(null);
  const { gridCellProps } = useTableCell(
    { node: cell, isVirtualized },
    state,
    cellRef
  );

  // TODO(fiona): Render nice focus ring
  const { focusProps, isFocusVisible } = useFocusRing();

  const stickyClasses = 'sticky left-0 bg-inherit';
  const focusedClasses =
    'outline-1 outline-dotted outline-hyperlink -outline-offset-1 rounded';
  const selectedClasses =
    'outline-2 outline-accent outline -outline-offset-2 rounded';

  return (
    <td
      {...mergeProps(gridCellProps, focusProps)}
      ref={cellRef}
      className={`relative border-r border-b border-gray-300 p-2 ${
        // This is the easiest way to tell if this cell is a row header.
        gridCellProps.role === 'rowheader' ? stickyClasses : ''
      } ${isSelected ? selectedClasses : ''} ${
        isFocusVisible && !isSelected ? focusedClasses : ''
      }`}
    >
      {cell.rendered}
    </td>
  );
};
