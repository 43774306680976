import * as Immutable from 'immutable';
import _ from 'lodash';

import {
  AssignmentViewType,
  assignmentViewTypes,
  requestStatuses,
} from '../../constants';
import * as AssignmentService from '../../services/assignment-service';
import { ApiPrecinct } from '../../services/lbj-shared-service';
import * as RavenService from '../../services/sentry-service';
import UrlClient from '../../services/url-client';
import {
  ListRequestData,
  ListRequestError,
} from '../../utils/lbj/list-request-state-handler';
import { MapFromJs } from '../../utils/types';
import { AppState, AppThunk } from '../flux-store';
import { showToast, dismissToast } from '../toast';

import actionTypes from './action-types';

const {
  GET_ASSIGNMENT_LIST,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  OPEN_EDITOR_PANEL,
  CLOSE_EDITOR_PANEL,
  SAVE_ASSIGNMENT_DATA,
  REQUEST_ASSIGNMENT_CSV,
} = actionTypes;

const { PENDING, SUCCESS, ERROR } = requestStatuses;

const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
} = assignmentViewTypes;

export type Action =
  | RequestAssignmentCsvAction
  | GetAssignmentListAction
  | CreateAssignmentAction
  | UpdateAssignmentAction
  | DeleteAssignmentAction
  | OpenEditorPanelAction
  | SaveAssignmentDataAction
  | CloseEditorPanelAction;

export type RequestAssignmentCsvAction = {
  type: typeof REQUEST_ASSIGNMENT_CSV;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        /**
         * URL to download from. Not read by the reducer, but used by the UI.
         */
        url: string;
      }
    | {
        status: typeof ERROR;
        error: unknown;
      };
};

function requestAssignmentCSV(): RequestAssignmentCsvAction {
  return {
    type: REQUEST_ASSIGNMENT_CSV,
    data: {
      status: PENDING,
    },
  };
}

function receiveAssignmentCSV(url: string): RequestAssignmentCsvAction {
  return {
    type: REQUEST_ASSIGNMENT_CSV,
    data: {
      status: SUCCESS,
      url,
    },
  };
}

function errorReceivingAssignmentCSV(
  error: unknown
): RequestAssignmentCsvAction {
  return {
    type: REQUEST_ASSIGNMENT_CSV,
    data: {
      status: ERROR,
      error,
    },
  };
}

export function getAssignmentExportAsync(
  filters = {}
): AppThunk<Promise<RequestAssignmentCsvAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentCSV());
    return AssignmentService.getCSVExport(filters).then(
      (urls) => {
        return UrlClient(urls.head_url, 'HEAD', { retries: 60 }) // give it five minutes
          .then(() => {
            return dispatch(receiveAssignmentCSV(urls.get_url));
          });
      },
      (error) => {
        RavenService.captureException(error, {
          type: 'assignment',
          method: 'getAssignmentExportAsync',
        });
        return dispatch(errorReceivingAssignmentCSV(error));
      }
    );
  };
}

export type GetAssignmentListAction = {
  type: typeof GET_ASSIGNMENT_LIST;
  /**
   * Data varies based on `listViewType` enforcing the distinctions is too much
   * trouble at this point.
   */
  data:
    | (ListRequestData<AssignmentService.ApiPrecinctWithAssignments> & {
        listViewType: typeof assignmentViewTypes.PRECINCTS;
      })
    | (ListRequestData<AssignmentService.ApiLocationWithAssignments> & {
        listViewType: typeof assignmentViewTypes.LOCATIONS;
      })
    | (ListRequestData<AssignmentService.ApiUserWithAssignments> & {
        listViewType: typeof assignmentViewTypes.PEOPLE;
      })
    | (ListRequestData<AssignmentService.ApiBoilerRoomWithAssignments> & {
        listViewType:
          | typeof assignmentViewTypes.BOILER_ROOMS
          | typeof assignmentViewTypes.HOTLINES;
      })
    | (ListRequestData<AssignmentService.ApiBoardOfElectionsWithAssignments> & {
        listViewType: typeof assignmentViewTypes.BOARDS_OF_ELECTIONS;
      });
};

function requestAssignmentList(
  listViewType: AssignmentViewType
): GetAssignmentListAction {
  return {
    type: GET_ASSIGNMENT_LIST,
    data: {
      status: PENDING,
      listViewType,
    },
  };
}

function receiveAssignmentList(
  // We need to include the 'SUCCESS' to discriminate the ListRequestData type.
  data: GetAssignmentListAction['data'] & { status: 'SUCCESS' }
): GetAssignmentListAction {
  return {
    type: GET_ASSIGNMENT_LIST,
    data,
  };
}

function errorReceivingAssignmentList(
  listViewType: AssignmentViewType,
  error: ListRequestError
): GetAssignmentListAction {
  return {
    type: GET_ASSIGNMENT_LIST,
    data: {
      status: ERROR,
      listViewType,
      error,
    },
  };
}

export function getAssignmentListByPrecinctAsync(
  filters = {}
): AppThunk<Promise<GetAssignmentListAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentList(PRECINCTS));

    return AssignmentService.getPrecinctsWithAssignments(filters).then(
      ({ offset, size, precincts }) => {
        return dispatch(
          receiveAssignmentList({
            status: 'SUCCESS',
            listViewType: PRECINCTS,
            listResponse: {
              offset,
              size,
              listData: precincts,
            },
          })
        );
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'assignment',
          method: 'getAssignmentListByPrecinctAsync',
        });
        return dispatch(errorReceivingAssignmentList(PRECINCTS, errorResponse));
      }
    );
  };
}

export function getAssignmentListByLocationAsync(
  filters = {}
): AppThunk<Promise<GetAssignmentListAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentList(LOCATIONS));
    const filtersWithoutBoe = _.assign(filters, { boe__isnull: true });

    return AssignmentService.getLocationsWithAssignments(
      filtersWithoutBoe
    ).then(
      ({ offset, size, locations }) => {
        return dispatch(
          receiveAssignmentList({
            status: 'SUCCESS',
            listViewType: LOCATIONS,
            listResponse: {
              offset,
              size,
              listData: locations,
            },
          })
        );
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'assignment',
          method: 'getAssignmentListByLocationAsync',
        });
        return dispatch(errorReceivingAssignmentList(LOCATIONS, errorResponse));
      }
    );
  };
}

export function getAssignmentListByPersonAsync(
  filters = {}
): AppThunk<Promise<GetAssignmentListAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentList(PEOPLE));

    return AssignmentService.getUsersWithAssignments(filters).then(
      ({ offset, size, users }) => {
        return dispatch(
          receiveAssignmentList({
            status: 'SUCCESS',
            listViewType: PEOPLE,
            listResponse: {
              offset,
              size,
              listData: users,
            },
          })
        );
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'assignment',
          method: 'getAssignmentListByPersonAsync',
        });
        return dispatch(errorReceivingAssignmentList(PEOPLE, errorResponse));
      }
    );
  };
}

function _getAssignmentListForBoilerOrHotline(
  listViewType: typeof BOILER_ROOMS | typeof HOTLINES,
  filterPayload: {}
): AppThunk<Promise<GetAssignmentListAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentList(listViewType));

    return AssignmentService.getBoilerRoomsWithAssignments(filterPayload).then(
      ({ offset, size, boiler_rooms }) => {
        return dispatch(
          receiveAssignmentList({
            status: 'SUCCESS',
            listViewType: listViewType,
            listResponse: {
              offset,
              size,
              listData: boiler_rooms,
            },
          })
        );
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'assignment',
          method: '_getAssignmentListForBoilerOrHotline',
        });
        return dispatch(
          errorReceivingAssignmentList(listViewType, errorResponse)
        );
      }
    );
  };
}

export function getAssignmentListForBoilerRoomsAsync(filters = {}) {
  const filterPayload = _.assign({}, filters, {
    level: ['state', 'regional', 'national'],
  });
  return _getAssignmentListForBoilerOrHotline(BOILER_ROOMS, filterPayload);
}

function getAssignmentListForHotlinesAsync(filters = {}) {
  const filterPayload = _.assign({}, filters, { level: ['hotline'] });
  return _getAssignmentListForBoilerOrHotline(HOTLINES, filterPayload);
}

export function getAssignmentListForBoardsOfElectionsAsync(
  filters = {}
): AppThunk<Promise<GetAssignmentListAction>> {
  return (dispatch) => {
    dispatch(requestAssignmentList(BOARDS_OF_ELECTIONS));

    return AssignmentService.getBoardsOfElectionsWithAssignments(filters).then(
      ({ offset, size, boards_of_elections }) => {
        return dispatch(
          receiveAssignmentList({
            status: 'SUCCESS',
            listViewType: BOARDS_OF_ELECTIONS,
            listResponse: {
              offset,
              size,
              listData: boards_of_elections,
            },
          })
        );
      },
      (errorResponse) => {
        RavenService.captureException(errorResponse, {
          type: 'assignment',
          method: 'getAssignmentListForBoardsOfElectionsAsync',
        });
        return dispatch(
          errorReceivingAssignmentList(BOARDS_OF_ELECTIONS, errorResponse)
        );
      }
    );
  };
}

export function getAssignmentListForViewTypeAsync(
  listViewType: AssignmentViewType,
  filters = {}
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const handleResolution = () => resolve(getState());

      switch (listViewType) {
        case PRECINCTS:
          return dispatch(getAssignmentListByPrecinctAsync(filters)).then(
            handleResolution
          );

        case LOCATIONS:
          return dispatch(getAssignmentListByLocationAsync(filters)).then(
            handleResolution
          );

        case PEOPLE:
          return dispatch(getAssignmentListByPersonAsync(filters)).then(
            handleResolution
          );

        case BOILER_ROOMS:
          return dispatch(getAssignmentListForBoilerRoomsAsync(filters)).then(
            handleResolution
          );

        case BOARDS_OF_ELECTIONS:
          return dispatch(
            getAssignmentListForBoardsOfElectionsAsync(filters)
          ).then(handleResolution);

        case HOTLINES:
          return dispatch(getAssignmentListForHotlinesAsync(filters)).then(
            handleResolution
          );
      }
    });
  };
}

/**
 * Common data for create, update, and delete operations, since they go through
 * the same reducer.
 */
export type AssignmentCrudData =
  | {
      status: typeof PENDING;
    }
  | {
      status: typeof SUCCESS;
      updatedEntity: AssignmentService.ApiAssignmentCrudResponse;
      listViewType: AssignmentViewType;
    }
  | {
      status: typeof ERROR;
      error: unknown;
    };

export type CreateAssignmentAction = {
  type: typeof CREATE_ASSIGNMENT;
  data: AssignmentCrudData;
};

function requestCreateAssignment(): CreateAssignmentAction {
  return {
    type: CREATE_ASSIGNMENT,
    data: {
      status: PENDING,
    },
  };
}

function successfullyCreateAssignment(
  updatedEntity: AssignmentService.ApiAssignmentCrudResponse,
  listViewType: AssignmentViewType
): CreateAssignmentAction {
  return {
    type: CREATE_ASSIGNMENT,
    data: {
      status: SUCCESS,
      updatedEntity,
      listViewType,
    },
  };
}

function errorCreatingAssignment(error: unknown): CreateAssignmentAction {
  return {
    type: CREATE_ASSIGNMENT,
    data: {
      status: ERROR,
      error,
    },
  };
}

export function createAssignmentAsync(
  assignmentData: AssignmentService.NewApiAssignment,
  ctx: AssignmentService.AssignmentContext,
  query = {}
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestCreateAssignment());

    return AssignmentService.createAssignment(assignmentData, ctx, query).then(
      (updatedEntity) =>
        dispatch(successfullyCreateAssignment(updatedEntity, ctx.listViewType)),
      (error) => {
        RavenService.captureException(error, {
          type: 'assignment',
          method: 'createAssignmentAsync',
        });
        return dispatch(errorCreatingAssignment(error));
      }
    );
  };
}

export type UpdateAssignmentAction = {
  type: typeof UPDATE_ASSIGNMENT;
  data: AssignmentCrudData;
};

function requestUpdateAssignment(): UpdateAssignmentAction {
  return {
    type: UPDATE_ASSIGNMENT,
    data: {
      status: PENDING,
    },
  };
}

function successfullyUpdateAssignment(
  updatedEntity: AssignmentService.ApiAssignmentCrudResponse,
  listViewType: AssignmentViewType
): UpdateAssignmentAction {
  return {
    type: UPDATE_ASSIGNMENT,
    data: {
      status: SUCCESS,
      updatedEntity,
      listViewType,
    },
  };
}

function errorUpdatingAssignment(error: unknown): UpdateAssignmentAction {
  return {
    type: UPDATE_ASSIGNMENT,
    data: {
      status: ERROR,
      error,
    },
  };
}

export function updateAssignmentAsync(
  assignmentData: AssignmentService.ApiAssignment,
  ctx: AssignmentService.AssignmentContext,
  query = {}
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestUpdateAssignment());

    return AssignmentService.updateAssignment(assignmentData, ctx, query).then(
      (updatedEntity) =>
        dispatch(successfullyUpdateAssignment(updatedEntity, ctx.listViewType)),
      (error) => {
        RavenService.captureException(error, {
          type: 'assignment',
          method: 'updateAssignmentAsync',
        });
        return dispatch(errorUpdatingAssignment(error));
      }
    );
  };
}

export type DeleteAssignmentAction = {
  type: typeof DELETE_ASSIGNMENT;
  data: AssignmentCrudData;
};

function requestToDeleteAssignment(): DeleteAssignmentAction {
  return {
    type: DELETE_ASSIGNMENT,
    data: {
      status: PENDING,
    },
  };
}

function successfullyDeleteAssignment(
  updatedEntity: AssignmentService.ApiAssignmentCrudResponse,
  listViewType: AssignmentViewType
): DeleteAssignmentAction {
  return {
    type: DELETE_ASSIGNMENT,
    data: {
      status: SUCCESS,
      updatedEntity,
      listViewType,
    },
  };
}

function errorDeletingAssignment(error: unknown): DeleteAssignmentAction {
  return {
    type: DELETE_ASSIGNMENT,
    data: {
      status: ERROR,
      error,
    },
  };
}

export function deleteAssignmentAsync(
  assignmentData: AssignmentService.ApiAssignment,
  ctx: AssignmentService.AssignmentContext,
  query = {}
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestToDeleteAssignment());

    return AssignmentService.deleteAssignment(assignmentData, ctx, query).then(
      (updatedEntity) =>
        dispatch(successfullyDeleteAssignment(updatedEntity, ctx.listViewType)),
      (error) => {
        RavenService.captureException(error, {
          type: 'assignment',
          method: 'deleteAssignmentAsync',
        });
        return dispatch(errorDeletingAssignment(error));
      }
    );
  };
}

/**
 * This will be Immutable objects since they come back out of the store.
 */
export type AssignmentParentResource = {
  assignment?: Immutable.Map<keyof AssignmentService.ApiAssignment, unknown>;
  location?: MapFromJs<
    AssignmentService.ApiLocationWithAssignments &
      AssignmentService.WithApiAssignmentExtras
  >;
  precinct?: Immutable.Map<keyof ApiPrecinct, unknown>;
  user?: MapFromJs<AssignmentService.ApiUserWithAssignments>;
  // TODO(fiona): fill this in as more stuff is discovered
};

export type OpenEditorPanelAction = {
  type: typeof OPEN_EDITOR_PANEL;
  data: {
    parentResource: AssignmentParentResource;

    isAssigning?: boolean;
    isEditing?: boolean;
    assignmentType?: AssignmentService.ApiAssignmentType;
    shiftDate?: string;
    startTime?: string;
    endTime?: string;
  };
};

export function showParentResourceDetails(
  parentResource: AssignmentParentResource
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      parentResource,
      isAssigning: false,
    },
  };
}

// Similar to showParentResourceDetails, but used for the V2 Assignments UI
// (V2 has different UI show/hide behavior and therefore different flags to
// avoid interfering with V1 behavior.)
export function showDetails(
  parentResource: AssignmentParentResource,
  isEditing: boolean,
  assignmentType: AssignmentService.ApiAssignmentType = 'poll'
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      assignmentType,
      isEditing: isEditing,
      parentResource: parentResource,
    },
  };
}

export function openNewAssignmentEditor(
  parentResource: AssignmentParentResource,
  assignmentType: AssignmentService.ApiAssignmentType,
  shiftDate: string,
  startTime: string,
  endTime: string
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      isAssigning: true,
      parentResource: parentResource,
      assignmentType,
      shiftDate,
      startTime,
      endTime,
    },
  };
}

// Similar to openNewAssignmentEditor, but used for V2 Assignments UI
// (V2 has different UI show/hide behavior and therefore different flags to
// avoid interfering with V1 behavior.)
export function showNewAssignment(
  parentResource: AssignmentParentResource,
  assignmentType: AssignmentService.ApiAssignmentType,
  shiftDate: string,
  startTime: string,
  endTime: string
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      isEditing: true,
      parentResource: parentResource,
      assignmentType,
      shiftDate,
      startTime,
      endTime,
    },
  };
}

export function openExistingAssignmentEditor(
  parentResource: AssignmentParentResource,
  assignmentType: AssignmentService.ApiAssignmentType
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      isAssigning: true,
      parentResource: parentResource,
      assignmentType,
    },
  };
}

// Similar to openExistingAssignmentEditor, but used for V2 Assignments UI
// (V2 has different UI show/hide behavior and therefore different flags to
// avoid interfering with V1 behavior.)
export function showEditAssignment(
  parentResource: AssignmentParentResource,
  assignmentType: AssignmentService.ApiAssignmentType
): OpenEditorPanelAction {
  return {
    type: OPEN_EDITOR_PANEL,
    data: {
      isEditing: true,
      parentResource: parentResource,
      assignmentType,
    },
  };
}

export type SaveAssignmentDataAction = {
  type: typeof SAVE_ASSIGNMENT_DATA;
  data:
    | {
        assignmentType: AssignmentService.ApiAssignmentType;
      }
    | {
        updatedFields: { [key: string]: unknown };
      };
};

export function saveAssignmentType(
  assignmentType: AssignmentService.ApiAssignmentType
): SaveAssignmentDataAction {
  return {
    type: SAVE_ASSIGNMENT_DATA,
    data: { assignmentType },
  };
}

export function saveUpdatedAssignmentFields(updatedFields: {
  [key: string]: unknown;
}): SaveAssignmentDataAction {
  return {
    type: SAVE_ASSIGNMENT_DATA,
    data: { updatedFields },
  };
}

export type CloseEditorPanelAction = {
  type: typeof CLOSE_EDITOR_PANEL;
};

export function closeEditorPanel(): CloseEditorPanelAction {
  return {
    type: CLOSE_EDITOR_PANEL,
  };
}

export { showToast, dismissToast };
