import cx from 'classnames';
import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import mapStateToAnalyticsFilters from '../../../utils/analytics/map-state-to-analytics-filters';
import {
  USER_DEFAULT_FILTERS,
  ANALYTICS_VIEW_PARAMS,
  mapAnalyticsFiltersToQueryParams,
} from '../../../utils/analytics/query-params';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import { PageHeader } from '../../common';

import AnalyticsIncidents from '../../presentational/analytics/analytics-incidents';
import AnalyticsIssueTables from '../../presentational/analytics/analytics-issue-tables';

import AnalyticsFilterSidebar from './analytics-filter-sidebar';

@connect((state) => {
  const { analytics } = state;
  const incidentsListData = analytics.getIn(['incidentAnalytics', 'listData']);
  const incidentsData = incidentsListData
    ? incidentsListData.map((data) => {
        return { name: data.get('Name'), value: data.get('Total') };
      })
    : Immutable.fromJS([]);

  return {
    filters: mapStateToAnalyticsFilters(state),
    incidentsData,
    incidentsListData,
  };
})
@withRouting
export default class AnalyticsContainer extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    incidentsData: PropTypes.object.isRequired,
    incidentsListData: PropTypes.object.isRequired,
    ...WithRoutingPropTypes,
  };

  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onMultiFilterChange = this.onMultiFilterChange.bind(this);
  }

  onFilterChange({ target }) {
    const { name, value } = target;
    const { location, navigate } = this.props;
    const { search, pathname } = location;
    const query = searchToQuery(search);
    const newQueryParams = _.omit(query, ['size', 'offset']);

    if (value) {
      newQueryParams[name] = value;
    } else if (name in USER_DEFAULT_FILTERS) {
      // explicitly set a null param, so that the filter is not populated
      // with a default value
      newQueryParams[name] = null;
    } else {
      delete newQueryParams[name];
    }

    navigate({ pathname, search: queryToSearch(newQueryParams) });
  }

  onMultiFilterChange(changes) {
    const { location, navigate } = this.props;
    const { search, pathname } = location;
    const query = searchToQuery(search);

    const newQueryParams = _.omit(query, ['size', 'offset']);
    changes.map((change) => {
      const { name, value } = change;
      if (value) {
        newQueryParams[name] = value;
      } else if (name in USER_DEFAULT_FILTERS) {
        // explicitly set a null param, so that the filter is not populated
        // with a default value
        newQueryParams[name] = null;
      } else {
        delete newQueryParams[name];
      }
    });

    navigate({ pathname, search: queryToSearch(newQueryParams) });
  }

  renderViewFilters() {
    const { location, filters } = this.props;
    const { pathname } = location;
    const baseParams = _.omit(
      mapAnalyticsFiltersToQueryParams(filters.toJS()),
      ['ordering', 'group_by']
    );

    return (
      <ul className="lbj-view-filters">
        <li>
          <Link
            to={{
              pathname,
              search: queryToSearch(
                _.assign({}, baseParams, {
                  view: ANALYTICS_VIEW_PARAMS.issues_by_category,
                })
              ),
            }}
            className={cx({
              'is-active':
                filters.get('view') ===
                ANALYTICS_VIEW_PARAMS.issues_by_category,
            })}
          >
            Issues by Category
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname,
              search: queryToSearch(
                _.assign({}, baseParams, {
                  view: ANALYTICS_VIEW_PARAMS.issues_by_region,
                })
              ),
            }}
            className={cx({
              'is-active':
                filters.get('view') === ANALYTICS_VIEW_PARAMS.issues_by_region,
            })}
          >
            Issues by Region
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname,
              search: queryToSearch(
                _.assign({}, baseParams, {
                  view: ANALYTICS_VIEW_PARAMS.issue_tables,
                })
              ),
            }}
            className={cx({
              'is-active':
                filters.get('view') === ANALYTICS_VIEW_PARAMS.issue_tables,
            })}
          >
            Issue Totals by Region
          </Link>
        </li>
      </ul>
    );
  }

  renderMainAnalyticsView() {
    const { filters, incidentsData, incidentsListData } = this.props;
    switch (filters.get('view')) {
      case ANALYTICS_VIEW_PARAMS.issues_by_region:
        return (
          <AnalyticsIncidents
            groupBy={filters.get('group_by')}
            incidentsData={incidentsData}
            onFilterChange={() => {}}
          />
        );

      case ANALYTICS_VIEW_PARAMS.issue_tables:
        return (
          <AnalyticsIssueTables
            column={filters.get('column')}
            issueData={incidentsListData}
          />
        );

      case ANALYTICS_VIEW_PARAMS.issues_by_category:
      default:
        return (
          <AnalyticsIncidents
            groupBy={filters.get('group_by')}
            incidentsData={incidentsData}
            onFilterChange={this.onMultiFilterChange}
          />
        );
    }
  }

  render() {
    return (
      <div className="lbj-page-wrapper">
        <PageHeader title="Analytics">{this.renderViewFilters()}</PageHeader>
        <div className="lbj-page-columns">
          <AnalyticsFilterSidebar
            onFilterChange={this.onFilterChange}
            onMultiFilterChange={this.onMultiFilterChange}
          />
          <div className="lbj-main">{this.renderMainAnalyticsView()}</div>
        </div>
      </div>
    );
  }
}
