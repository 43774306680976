import React from 'react';
import { Overlay, useModalOverlay } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

import { UNDERLAY_CLASSES } from './layout-utils';

/**
 * Component to set up a modal focus scope.
 */
export const ModalOverlay: React.FunctionComponent<{
  state: OverlayTriggerState;
  aboveCenter?: boolean | undefined;
}> = ({ children, state, aboveCenter, ...props }) => {
  const ref = React.useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  return (
    <Overlay>
      <div
        {...underlayProps}
        className={`${UNDERLAY_CLASSES} bg-neutral-300 opacity-70`}
      />

      <div
        ref={ref}
        {...modalProps}
        // this set of classes puts the overlay’s content centered in the middle
        // of the screen, with a padding of size 12 on all sides, except for 24
        // on the top.
        //
        // Adds reset-2023 since all dialog content should be new style.
        // (Necessary here because this is rendered in a React portal and
        // therefore doesn’t inherit from any reset-2023 applied to the page’s
        // main content area.)
        className="reset-2023 fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-around px-12 pt-24 pb-12"
      >
        {aboveCenter && <div className="flex-1" />}
        {children}
        {aboveCenter && <div className="flex-grow-[3]" />}
      </div>
    </Overlay>
  );
};
