import keyMirror from 'key-mirror';

export default keyMirror({
  GET_USER_COUNT: null,
  GET_USER_LIST: null,
  GET_ALL_USER_IDS: null,
  UPDATE_USER: null,
  GET_CURRENT_USER: null,
  CHANGE_USER_VIEW: null,
  GET_USER_DETAILS: null,
  CLEAR_USER_DETAILS: null,
  BULK_TAG_USERS: null,
  CLONE_USERS: null,
  DISABLE_USERS: null,
});
