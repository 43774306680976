import keyMirror from 'key-mirror';

export default keyMirror({
  ISSUE: null,
  ISSUE_DETAIL: null,
  ASSIGNMENT: null,
  CHECKINS: null,
  DASHBOARD: null,
  ANALYTICS: null,
  PROFILE: null,
  NOTIFICATIONS: null,
  USER: null,
});
