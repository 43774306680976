import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DebouncedInput from '../form/debounced-input';
import HiddenInput from '../form/hidden-input';

export default class BoilerSearchForm extends Component {
  static propTypes = {
    onSearchForBoilerRooms: PropTypes.func.isRequired,
    hotlineCenters: PropTypes.bool,
  };

  onChange({ target }) {
    const { value } = target;

    return this.props.onSearchForBoilerRooms({ name: value });
  }

  render() {
    const { hotlineCenters } = this.props;
    const title = hotlineCenters ? 'Hotline Centers' : 'Boiler Rooms';
    const ctaText = hotlineCenters
      ? 'Select a hotline center'
      : 'Select a boiler room';

    return (
      <div>
        <div className="lbj-column-label">
          <h4>{title}</h4>
        </div>

        <div className="lbj-column-content">
          <HiddenInput ctaText={ctaText}>
            <DebouncedInput
              title={title}
              name="name"
              onChange={this.onChange.bind(this)}
            />
          </HiddenInput>
        </div>
      </div>
    );
  }
}
