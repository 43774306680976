import _ from 'lodash';
import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import UserIndex from '../components/containers/user/user-index';

import { userViewTypes, lbjAppSections } from '../constants';
import { removeAllUsersFromQueue } from '../modules/email/action-creators';
import fluxStore from '../modules/flux-store';
import { getUserTagListAsync } from '../modules/lbj/action-creators';
import {
  clearUserDetails,
  getCurrentUserAsync,
  getUserDetailsAsync,
  getUserListAsync,
  getUserCountAsync,
  getAllUserIdsAsync,
} from '../modules/user/action-creators';
import EditUserContainer from '../pages/profile/edit-user-container';
import ProfileContainer from '../pages/profile/profile-container';
import { queryToSearch, searchToQuery } from '../utils/routing-provider';

import mapStateToUserFilters from '../utils/user/map-state-to-user-filters';
import {
  mapQueryParamsToUserFilters,
  mapQueryParamsToUserDetailFilters,
  mapUserFiltersToQueryParams,
  USER_DETAIL_VIEW_PARAMS,
} from '../utils/user/query-params';

import { redirectUnauthorizedUser, storeAppFilters } from './app';

const { USER, PROFILE } = lbjAppSections;
const { ASSIGNMENT_NOTIFY } = userViewTypes;

function handleUserIndex(location: Location, navigate: NavigateFunction) {
  if (
    redirectUnauthorizedUser(fluxStore, navigate, [
      'vpd',
      'deputy_vpd',
      'boiler_room_leader',
    ])
  ) {
    return;
  }

  const { dispatch } = fluxStore;
  const { pathname, search } = location;
  const query = searchToQuery(search);

  const filterParams = mapQueryParamsToUserFilters(query);
  const urlParams = mapUserFiltersToQueryParams(filterParams);

  if (!_.isEqual(urlParams, query)) {
    navigate({ pathname, search: queryToSearch(urlParams) }, { replace: true });
    return;
  }

  storeAppFilters(fluxStore, filterParams, USER).then((state) => {
    const filterPayload = mapStateToUserFilters(state).toJS();

    dispatch(getUserListAsync(filterPayload));
    dispatch(getUserTagListAsync());
    dispatch(getUserCountAsync(filterPayload));

    // fetch select-all lists for all views
    dispatch(
      getAllUserIdsAsync(mapStateToUserFilters(state).toJS(), ASSIGNMENT_NOTIFY)
    );
  });
}

export const UsersRoute: React.FunctionComponent = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  React.useEffect(() => {
    handleUserIndex(location, navigateRef.current);
  }, [location]);

  React.useEffect(() => {
    return () => {
      const { dispatch } = fluxStore;
      dispatch(removeAllUsersFromQueue());
    };
  }, []);

  return <UserIndex />;
};

function handleProfile(location: Location) {
  const { dispatch } = fluxStore;
  const { search } = location;
  const query = searchToQuery(search);

  const params = mapQueryParamsToUserDetailFilters(query);

  dispatch(getUserTagListAsync());
  storeAppFilters(fluxStore, params, PROFILE).then((state) => {
    if (params.view === USER_DETAIL_VIEW_PARAMS.assignments) {
      dispatch(
        getCurrentUserAsync({
          dates: state.filters.getIn([PROFILE, 'dates']) as string,
        })
      );
    }
  });
}

export const ProfileRoute: React.FunctionComponent = () => {
  const location = useLocation();

  React.useEffect(() => {
    handleProfile(location);
  }, [location]);

  return <ProfileContainer />;
};

function handleUserDetail(
  userId: string,
  location: Location,
  navigate: NavigateFunction
) {
  if (
    redirectUnauthorizedUser(fluxStore, navigate, [
      'vpd',
      'deputy_vpd',
      'boiler_room_leader',
    ])
  ) {
    return;
  }

  const { dispatch } = fluxStore;
  const { search } = location;
  const query = searchToQuery(search);

  const params = mapQueryParamsToUserDetailFilters(query);

  dispatch(getUserTagListAsync());
  storeAppFilters(fluxStore, params, USER).then((state) => {
    dispatch(
      getUserDetailsAsync(Number(userId), {
        expand: 'related,pii,emails_received',
        dates: state.filters.getIn([USER, 'dates']) as string,
      })
    ).then((state) => {
      const requestErred = state.user.getIn(['userDetails', 'requestErred']);
      if (requestErred) {
        navigate('/404');
        return;
      }
    });
  });
}

export const UserDetailRoute: React.FunctionComponent = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);
  React.useEffect(() => {
    navigateRef.current = navigate;
  });

  const userId = useParams()['userId']!;

  React.useEffect(() => {
    handleUserDetail(userId, location, navigateRef.current);
  }, [userId, location]);

  React.useEffect(() => {
    return () => {
      const { dispatch } = fluxStore;
      dispatch(clearUserDetails());
    };
  }, []);

  return <EditUserContainer />;
};
