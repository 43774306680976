import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Input from '../../presentational/form/input';

export default class DebouncedInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    wait: PropTypes.number.isRequired,
  };

  static defaultProps = {
    wait: 500,
  };

  debounceChange = _.debounce(this.props.onChange, this.props.wait, {
    trailing: true,
  });

  render() {
    const { onChange, wait, ...rest } = this.props;

    return (
      <Input
        {...rest}
        type="text"
        onChange={this.debounceChange.bind(this)}
        throttle
      />
    );
  }
}
