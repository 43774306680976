import { useObjectRef } from '@react-aria/utils';
import type { Validation } from '@react-types/shared';
import cx from 'classnames';
import React from 'react';
import {
  useComboBox,
  useFilter,
  useButton,
  AriaComboBoxProps,
  AriaButtonProps,
} from 'react-aria';
import { useComboBoxState, ComboBoxStateOptions } from 'react-stately';

import { PopoverView } from '../layout';

import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import { ListBoxView } from './ListBox';

/**
 * A select box that also allows autocompletish typing.
 *
 * @see https://react-spectrum.adobe.com/react-aria/useComboBox.html
 */
export function ComboBox<T extends Object>(
  props: ComboBoxStateOptions<T> & AriaComboBoxProps<T>
): React.ReactElement {
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({
    ...props,
    defaultFilter: contains,
  });

  const buttonRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const {
    buttonProps,
    inputProps,
    listBoxProps,
    labelProps,
    errorMessageProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  return (
    <div className="flex flex-col gap-2">
      {typeof props.label === 'string' ? (
        <FormLabel type="label" isRequired={props.isRequired} {...labelProps}>
          {props.label}
        </FormLabel>
      ) : (
        props.label
      )}

      <div className="flex h-[40px] items-stretch">
        <input
          {...inputProps}
          ref={inputRef}
          className={cx(
            'block px-2 py-0 text-base text-gray-700 shadow-none placeholder:text-base placeholder:not-italic',
            'rounded-r-none rounded-l border-2 border-r-0',
            //  overrides some common styles
            'disabled:border-solid',
            {
              'bg-white': !props.isDisabled,
              'cursor-not-allowed bg-gray-200': props.isDisabled,
              'border-gray-300': props.validationState !== 'invalid',
              'border-red-700': props.validationState === 'invalid',
            }
          )}
        />

        <ComboBoxMenuButton
          ref={buttonRef}
          {...buttonProps}
          validationState={props.validationState ?? 'valid'}
        />
      </div>

      {props.errorMessage && (
        <FormError {...errorMessageProps}>{props.errorMessage}</FormError>
      )}

      {state.isOpen && (
        <PopoverView
          state={state}
          popoverRef={popoverRef}
          triggerRef={inputRef}
          placement="bottom start"
          isNonModal
          popoverClassName="min-w-[300px]"
        >
          <ListBoxView
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            selectionStyle="checkmark"
            shouldFocusOnHover
          />
        </PopoverView>
      )}
    </div>
  );
}

/**
 * Button for opening up the menu on a combo box.
 *
 * Styled so that it has a border all around, but is only rounded on the right,
 * since the left is assumed to be flush with the input box.
 *
 * Factored to be shared between `<ComboBox>` and `<TagComboBox>`.
 */
export const ComboBoxMenuButton = React.forwardRef<
  HTMLButtonElement,
  AriaButtonProps<'button'> & Validation
>(function ComboBoxMenuButton(props, ref) {
  const buttonRef = useObjectRef<HTMLButtonElement | null>(ref);
  const { buttonProps } = useButton(props, buttonRef);

  return (
    <button
      ref={buttonRef}
      {...buttonProps}
      className={cx('rounded-r rounded-l-none border-2 border-solid px-2', {
        'bg-white text-gray-700': !props.isDisabled,
        'cursor-not-allowed bg-gray-200 text-gray-500': props.isDisabled,
        'border-gray-300': props.validationState !== 'invalid',
        'border-red-700': props.validationState === 'invalid',
      })}
    >
      <span aria-hidden>▼</span>
    </button>
  );
});
