import React, { Component } from 'react';

import UserToastContainer from '../../components/containers/user/user-toast-container';
import AssignmentForm from '../../components/presentational/user/user-assignment-form';
import { DetailedUser } from '../../modules/user/action-creators';
import { ApiUserElection } from '../../services/user-service';
import { MapFromJs } from '../../utils/types';

// TODO(fiona): Replace this whole page with the updated assignments view at
// `/home`
export default class UserDetailAssignmentContainer extends Component<{
  user: MapFromJs<DetailedUser>;
  currentUserId: number;
  currentElection: Immutable.Map<keyof ApiUserElection, any> | null;
  filters: Immutable.Map<string, string | null | undefined>;
}> {
  render() {
    const { user, filters, currentUserId, currentElection } = this.props;

    return (
      <div className="lbj-main">
        <UserToastContainer />

        <div className="lbj-column-content-wrapper">
          <div className="lbj-column-content">
            <div className="user-detail-form-section">
              <AssignmentForm
                isCurrentUser={currentUserId === user.get('id')}
                currentElection={currentElection}
                user={user}
                assignmentDate={filters.get('dates')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
