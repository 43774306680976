import cx from 'classnames';
import React from 'react';
import { Link, Location } from 'react-router-dom';

import { IssueViewType, issueViewTypes } from '../../constants';
import { ApiCurrentUser } from '../../services/user-service';

import { IssuesListFilters } from './issues-list-utils';

const {
  ALL,
  CREATED_BY_ME,
  ASSIGNED_TO_ME,
  BOILER_ROOM,
  ME_WATCHING,
  RESOLVED,
} = issueViewTypes;

/**
 * Component for the “All Issues” / “Reported by Me” / &c. links at the top of
 * the issues list.
 */
export default class ViewTypeControl extends React.Component<{
  listViewType: IssueViewType;
  currentUser: Pick<ApiCurrentUser, 'id' | 'role' | 'current_boiler_rooms'>;
  currentIssueCount: number | undefined;
  location: Location;
  filtersToSearchParams: (
    filters: Partial<IssuesListFilters>
  ) => URLSearchParams;
}> {
  getClassNameForAnchor(type: string) {
    const { listViewType } = this.props;
    return cx({ 'is-active': type === listViewType });
  }

  renderLink(
    type: IssueViewType,
    label: string,
    filters: Partial<IssuesListFilters> = {},
    isLast = false
  ) {
    const { location, currentIssueCount, listViewType, filtersToSearchParams } =
      this.props;

    const { pathname } = location;
    const searchParams = filtersToSearchParams(filters);

    return (
      <li>
        <Link
          className={this.getClassNameForAnchor(type)}
          to={{ pathname, search: searchParams.toString() }}
        >
          {label}
          {type === listViewType && <span> ({currentIssueCount})</span>}
        </Link>

        {!isLast && <span>|</span>}
      </li>
    );
  }

  renderMyBoilerLink() {
    const { currentUser } = this.props;
    const userRole = currentUser.role;
    const isHotlineWorker =
      userRole === 'hotline_worker' || userRole === 'hotline_manager';
    const linkText = isHotlineWorker ? 'My hotline center' : 'My boiler room';

    const boilerFilters = {
      boiler_room: currentUser.current_boiler_rooms.map((room) => room.id),
      view: BOILER_ROOM,
    };

    return this.renderLink(BOILER_ROOM, linkText, boilerFilters);
  }

  render() {
    const { currentUser } = this.props;
    const isPollObserver = currentUser.role === 'poll_observer';

    const reportFilters = {
      created_by: currentUser.id,
      view: CREATED_BY_ME,
    };

    const assignFilters = {
      owner: currentUser.id,
      view: ASSIGNED_TO_ME,
    };

    const watchingFilters = {
      watchers: currentUser.id,
      view: ME_WATCHING,
    };

    const resolvedFilters = {
      status: 'resolved' as const,
      view: RESOLVED,
    };

    return (
      <ul className="lbj-view-filters issue-view-filters">
        {this.renderLink(ALL, 'All issues')}

        {this.renderLink(CREATED_BY_ME, 'Reported by me', reportFilters)}

        {!isPollObserver &&
          this.renderLink(ASSIGNED_TO_ME, 'Assigned to me', assignFilters)}

        {this.renderLink(ME_WATCHING, 'Subscribed', watchingFilters)}

        {currentUser.current_boiler_rooms.length > 0 &&
          !isPollObserver &&
          this.renderMyBoilerLink.bind(this)}

        {this.renderLink(RESOLVED, 'Resolved issues', resolvedFilters, true)}
      </ul>
    );
  }
}
