import Papa from 'papaparse';
import React from 'react';

export type CsvParsingState =
  | {
      status: 'blank';

      fileName?: undefined;
      error?: undefined;
      results?: undefined;
    }
  | {
      status: 'parsing';

      fileName: string;
      error?: undefined;
      results?: undefined;
    }
  | {
      status: 'success';

      fileName: string;
      error?: undefined;

      results: Papa.ParseResult<{
        [key: string]: string;
      }>;
    }
  | {
      status: 'failure';

      fileName: string;
      error: Error;

      results?: undefined;
    };

/**
 * Hook to wrap PapaParse. Set a CSV file and it will process it.
 */
export function usePapaParse(
  config: Papa.ParseConfig<{}> = {}
): [CsvParsingState, (csvFile: File | null) => void] {
  const [state, setState] = React.useState<CsvParsingState>({
    status: 'blank',
  });

  const setCsvFile = React.useCallback(
    (csvFile: File | null) => {
      if (!csvFile) {
        setState({
          status: 'blank',
        });

        return;
      }

      setState({
        status: 'parsing',
        fileName: csvFile.name,
      });

      Papa.parse<{ [key: string]: string }, File>(csvFile, {
        ...config,
        complete(results) {
          setState({
            status: 'success',
            fileName: csvFile.name,
            results,
          });
        },
        error(error) {
          console.error(error);

          setState({
            status: 'failure',
            fileName: csvFile.name,
            error,
          });
        },
      });
    },
    [config]
  );

  return [state, setCsvFile];
}

/**
 * Given an array of objects and a file name, causes the browser to “download”
 * the CSV locally.
 */
export function downloadLocalCsv<T extends Object>(
  fileName: string,
  values: T[]
) {
  const csvStr = Papa.unparse(values);

  const csvBlob = new Blob([csvStr], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvBlob);

  const a = document.createElement('a');
  a.href = csvUrl;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(csvUrl);
}
