import cx from 'classnames';
import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections, METERS_TO_MILES_RATIO } from '../../../constants';
import { actionCreators } from '../../../modules/user';

import RIT from '../../../utils/render-if-truthy';

import EditorSearchResults from './assignment-editor-search-results';
import UserDetails from './assignment-user-details';
import UserSearchForm from './assignment-user-search-form';

@connect((state) => {
  const { assignment } = state;
  const { ASSIGNMENT } = lbjAppSections;
  const filters = state.filters.get(ASSIGNMENT);
  const userResults = state.user.getIn(['users', 'autocomplete']);
  const assignmentType = assignment.getIn([
    'assignmentEditor',
    'assignmentType',
  ]);
  const updatedFields = assignment.getIn(['assignmentEditor', 'updatedFields']);
  const currentElection = state.user.getIn([
    'currentUser',
    'currentUserElection',
  ]);
  const voteDays = currentElection
    ? currentElection.getIn(['election', 'days'])
    : Immutable.List([]);

  return {
    filters,
    updatedFields,
    userList: userResults.get('listData'),
    isLoadingList: userResults.get('requestIsPending'),
    assignmentType,
    voteDays,
  };
})
export default class FindUserForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    county: PropTypes.object,
    geoFilterByLocation: PropTypes.number,
    geoFilterByPrecinct: PropTypes.number,
    includePlaceStatus: PropTypes.bool,
    filters: PropTypes.object,
    updatedFields: PropTypes.object.isRequired,
    user: PropTypes.object,
    userList: PropTypes.object,
    isLoadingList: PropTypes.bool,
    assignmentType: PropTypes.string,
    onEditAssignmentData: PropTypes.func,
    assignmentState: PropTypes.string.isRequired,
    voteDays: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showOutOfRange: false,
    };
    this.lastSearch = {};

    this.onSearchForUsers = this.onSearchForUsers.bind(this);
    this.onShowOutOfRange = this.onShowOutOfRange.bind(this);
  }

  onSearchForUsers(filters) {
    const { dispatch } = this.props;
    const { getUserAutocompleteListAsync } = actionCreators;
    const filterPayload = _.assign(this.getDefaultSearchFilters(), filters);

    dispatch(getUserAutocompleteListAsync(filterPayload));
    this.lastSearch = filterPayload;
  }

  onShowOutOfRange(showOutOfRange) {
    this.setState({ showOutOfRange });
  }

  onGetMoreSearchResults({ offset }) {
    const { dispatch } = this.props;
    const { getUserAutocompleteListAsync } = actionCreators;
    const filterPayload = _.assign(this.lastSearch, { offset, size: 25 });

    dispatch(getUserAutocompleteListAsync(filterPayload, true));
  }

  getDefaultSearchFilters() {
    const {
      geoFilterByLocation,
      geoFilterByPrecinct,
      assignmentState,
      updatedFields,
    } = this.props;

    return _.pickBy(
      {
        size: 25,
        expand: 'related,pii',
        assignment_state: assignmentState,
        dates: updatedFields.get('shift_date'),
        sort_from_location: geoFilterByLocation,
        sort_from_precinct: geoFilterByPrecinct,
      },
      (val) => !!val
    );
  }

  renderSearchForm() {
    const { county, assignmentType, updatedFields, assignmentState, voteDays } =
      this.props;

    return (
      <UserSearchForm
        defaultSearchFilters={this.getDefaultSearchFilters()}
        onSearchForUsers={this.onSearchForUsers}
        onShowOutOfRange={this.onShowOutOfRange}
        shiftDate={updatedFields.get('shift_date')}
        county={county}
        voteDays={voteDays}
        stateFilter={assignmentState}
        assignmentType={assignmentType}
      />
    );
  }

  renderUserResult(user, key) {
    const { assignmentState, onEditAssignmentData } = this.props;
    const userName = `${user.get('last_name')}, ${user.get('first_name')}`;
    const distanceVal = user.get('distance')
      ? Math.floor(user.get('distance') / METERS_TO_MILES_RATIO)
      : null;
    const distance = distanceVal ? `${distanceVal}mi` : null;
    const maxDistanceVal = user.get('max_distance_miles');
    const maxDistance = maxDistanceVal ? `${maxDistanceVal}mi` : null;
    const hasConcurrentAssignment =
      user.has('related') && !user.get('related').isEmpty();
    const outOfState =
      user.get('state') && user.get('state') !== assignmentState;
    const outOfRange =
      distanceVal && maxDistanceVal && distanceVal > maxDistanceVal;
    const containerClassName = cx(`assignment-results-max-distance`, {
      'assignment-out-of-range': outOfRange,
    });
    return (
      <li onClick={onEditAssignmentData.bind(this, { user })} key={key}>
        <span className="assignment-results-username">{userName}</span>
        {RIT(distance || hasConcurrentAssignment || outOfState, () => {
          return (
            <span className="assignment-results-disclaimer">
              {RIT(outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    Lives out of state
                  </span>
                );
              })}

              {RIT(distance && !outOfState, () => {
                return (
                  <span className="assignment-results-distance">
                    {distance}
                  </span>
                );
              })}

              {RIT(maxDistance, () => {
                return (
                  <span className={containerClassName}>
                    (max {maxDistance})
                  </span>
                );
              })}

              {RIT(hasConcurrentAssignment, () => {
                return (
                  <span className="assignment-results-alert">
                    (assigned during this time)
                  </span>
                );
              })}
            </span>
          );
        })}
      </li>
    );
  }

  renderSearchResults() {
    const { isLoadingList } = this.props;
    const { showOutOfRange } = this.state;
    let { userList } = this.props;
    if (!showOutOfRange) {
      userList = userList.filter((user) => {
        return (
          !user.get('distance') ||
          !user.get('max_distance_miles') ||
          Math.floor(user.get('distance') / METERS_TO_MILES_RATIO) <
            user.get('max_distance_miles')
        );
      });
    }

    return (
      <div className="lbj-column-content">
        <EditorSearchResults
          listData={userList}
          isLoadingList={isLoadingList}
          onGetMoreSearchResults={this.onGetMoreSearchResults.bind(this)}
          listElementRenderer={this.renderUserResult.bind(this)}
        />
      </div>
    );
  }

  renderUserDetails() {
    return <UserDetails user={this.props.user} showHeader />;
  }

  renderBackButton(assignemntDataToClear) {
    return (
      <div className="lbj-column-content">
        <button
          type="button"
          className="c-button-wide c-button-secondary js_back-button"
          onClick={this.props.onEditAssignmentData.bind(
            this,
            assignemntDataToClear
          )}
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        {RIT(!user, this.renderSearchForm.bind(this))}
        {RIT(!user, this.renderSearchResults.bind(this))}
        {RIT(user, this.renderUserDetails.bind(this))}

        {RIT(user, this.renderBackButton.bind(this, { user: null }))}
      </div>
    );
  }
}
