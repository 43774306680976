import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import RIT from '../../../utils/render-if-truthy';
import BoilerDetails from '../../presentational/assignment/assignment-boiler-details';
import BoilerSearchForm from '../../presentational/assignment/assignment-boiler-search-form';
import EditorSearchResults from '../../presentational/assignment/assignment-editor-search-results';

@connect((state) => {
  const filters = state.filters.getIn([lbjAppSections.ASSIGNMENT]);
  const boilerRoomResults = state.lbj.get('boiler_rooms');

  return {
    filters,
    boilerRoomList: boilerRoomResults.get('listData'),
    isLoadingList: boilerRoomResults.get('requestIsPending'),
  };
})
export default class FindBoilerForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onEditAssignmentData: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    boilerRoomList: PropTypes.object.isRequired,
    isLoadingList: PropTypes.bool.isRequired,
    hotlineCenters: PropTypes.bool,
    boilerRoom: PropTypes.object,
    assignmentState: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.lastSearch = {};
  }

  componentWillMount() {
    this.onSearchForBoilerRooms();
  }

  componentDidUpdate(prevProps) {
    if (this.props.hotlineCenters !== prevProps.hotlineCenters) {
      this.onSearchForBoilerRooms();
    }
  }

  onSearchForBoilerRooms(filters = {}) {
    const { dispatch } = this.props;
    const { getBoilerRoomListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.getDefaultSearchFilters(), filters);

    dispatch(getBoilerRoomListAsync(filterPayload));
    this.lastSearch = filterPayload;
  }

  onGetMoreSearchResults({ offset }) {
    const { dispatch } = this.props;
    const { getBoilerRoomListAsync } = lbjActionCreators;
    const filterPayload = _.assign(this.lastSearch, { offset, size: 25 });

    dispatch(getBoilerRoomListAsync(filterPayload, true));
  }

  getDefaultSearchFilters() {
    const { hotlineCenters, assignmentState } = this.props;
    const level = hotlineCenters
      ? ['hotline']
      : ['regional', 'state', 'national'];

    return _.pickBy({
      size: 25,
      state: assignmentState,
      level,
    });
  }

  renderSearchForm() {
    return (
      <BoilerSearchForm
        hotlineCenters={this.props.hotlineCenters}
        onSearchForBoilerRooms={this.onSearchForBoilerRooms.bind(this)}
      />
    );
  }

  renderBoilerResult(boilerRoom, key) {
    const { onEditAssignmentData } = this.props;

    return (
      <li
        onClick={() => {
          onEditAssignmentData({ boiler_room: boilerRoom });
        }}
        key={key}
      >
        {boilerRoom.get('name')}
      </li>
    );
  }

  renderSearchResults() {
    const { boilerRoomList, isLoadingList } = this.props;

    return (
      <div className="lbj-column-content">
        <EditorSearchResults
          listData={boilerRoomList}
          isLoadingList={isLoadingList}
          onGetMoreSearchResults={this.onGetMoreSearchResults.bind(this)}
          listElementRenderer={this.renderBoilerResult.bind(this)}
        />
      </div>
    );
  }

  renderBoilerRoomDetails() {
    const { boilerRoom } = this.props;

    return <BoilerDetails boilerRoom={boilerRoom} showHeader />;
  }

  renderBackButton(assignemntDataToClear) {
    return (
      <div className="lbj-column-content">
        <button
          type="button"
          className="c-button-wide c-button-secondary"
          onClick={this.props.onEditAssignmentData.bind(
            this,
            assignemntDataToClear
          )}
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    const { boilerRoom } = this.props;

    return (
      <div>
        {RIT(!boilerRoom, this.renderSearchForm.bind(this))}
        {RIT(!boilerRoom, this.renderSearchResults.bind(this))}
        {RIT(boilerRoom, this.renderBoilerRoomDetails.bind(this))}

        {RIT(
          !boilerRoom,
          this.renderBackButton.bind(this, { start_time: null, end_time: null })
        )}
        {RIT(
          boilerRoom,
          this.renderBackButton.bind(this, { boiler_room: null })
        )}
      </div>
    );
  }
}
