/* eslint-disable react/no-string-refs */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    throttle: PropTypes.bool,
  };

  static defaultProps = {
    type: 'text',
    placeholder: '-',
    onChange: () => {},
    errors: [],
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  // Input text doesn't update with the default value when the props
  // change, so hard refreshes leave search fields blank and hard to
  // edit filters without explicitly removing query params.
  componentDidUpdate(prevProps) {
    const { defaultValue, name } = this.props;
    const receivedValue = defaultValue && !prevProps.defaultValue;
    const changedInputName = name !== prevProps.name;

    if ((receivedValue || changedInputName) && this.inputRef.current) {
      this.inputRef.current.value = defaultValue || '';
    }

    // If "clear all filters" is clicked, remove the text from the box
    if (!defaultValue && prevProps.defaultValue && this.inputRef.current) {
      this.inputRef.current.value = '';
    }
  }

  renderLabel() {
    const { name, title, required } = this.props;

    if (!title) {
      return;
    }

    if (required) {
      return (
        <label htmlFor={name}>
          {title}&nbsp;<span className="req-indicator">*</span>
        </label>
      );
    }

    return <label htmlFor={name}>{title}</label>;
  }

  renderInput() {
    const { name, errors, throttle, onChange, ...rest } = this.props;

    if (throttle) {
      return (
        <input
          ref={this.inputRef}
          id={name}
          name={name}
          onChange={(e) => {
            e.persist();
            return onChange(e);
          }}
          {...rest}
        />
      );
    }

    return <input id={name} name={name} onChange={onChange} {...rest} />;
  }

  renderError(error, key) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  render() {
    const { name, type, errors } = this.props;
    const className = cx('lbj-input', `lbj-input-${type}`, `lbj-input-${name}`);

    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
        {RIT(errors.length, () => {
          return errors.map((error, key) => this.renderError(error, key));
        })}
      </div>
    );
  }
}
