import { ApiUserTag } from '../../services/lbj-shared-service';

const EDAY_AVAILABILITY_TAGS = [
  'eday_available_am',
  'eday_available_pm',
  'eday_available_all_day',
];

const TRAINING_TAGS = ['training_scheduled', 'training_complete'];

/**
 * These are all stored as one big list in the user record. We parse it out into
 * a structured set of fields to make it easier to update and so we can attach
 * errors to particular sets of tags.
 *
 * This is parsed out manually in {@link parseTagArray} but combined
 * back together via algorithm in {@link serializeTags}. Because of
 * that, this must follow the pattern that properties with `boolean` values have
 * a key that’s a tag name, and that any `string` or `string[]` values are also
 * tag names.
 */
export type ParsedUserTags = {
  experienced: boolean;
  legal_community: boolean;
  car_access: boolean;
  rover: boolean;
  maybe: boolean;
  county_lead: boolean;
  confirmed: boolean;

  distanceTag: string | null;
  edayAvailabilityTag: string | null;
  trainingTag: string | null;

  languageTags: string[];
  volunteerTags: string[];

  /** Tags that our form doesn’t affect, so we just pass through. */
  otherTags: string[];
};

/**
 * Splits an array of tags up into {@link ParsedUserTags} so we can interact with
 * them individually or by category.
 *
 * @see serializeTags
 */
export function parseTagArray(
  tags: string[],
  tagData: ApiUserTag[]
): ParsedUserTags {
  const tagValues: ParsedUserTags = {
    experienced: false,
    legal_community: false,
    car_access: false,
    rover: false,
    maybe: false,
    county_lead: false,
    confirmed: false,

    distanceTag: null,
    edayAvailabilityTag: null,
    trainingTag: null,

    languageTags: [],
    volunteerTags: [],
    otherTags: [],
  };

  for (const tag of tags) {
    const tagDatum = tagData.find((d) => d.name === tag);

    if (tag === 'experienced') {
      tagValues.experienced = true;
    } else if (tag === 'legal_community') {
      tagValues.legal_community = true;
    } else if (tag === 'car_access') {
      tagValues.car_access = true;
    } else if (tag === 'rover') {
      tagValues.rover = true;
    } else if (tag === 'maybe') {
      tagValues.maybe = true;
    } else if (tag === 'county_lead') {
      tagValues.county_lead = true;
    } else if (tag === 'confirmed') {
      tagValues.confirmed = true;
    } else if (EDAY_AVAILABILITY_TAGS.includes(tag)) {
      tagValues.edayAvailabilityTag = tag;
    } else if (TRAINING_TAGS.includes(tag)) {
      tagValues.trainingTag = tag;
    } else if (tagDatum?.type === 'availability') {
      tagValues.volunteerTags.push(tag);
    } else if (tagDatum?.type === 'language') {
      tagValues.languageTags.push(tag);
    } else if (tagDatum?.type === 'travel_distance') {
      tagValues.distanceTag = tag;
    } else {
      tagValues.otherTags.push(tag);
    }
  }

  return tagValues;
}

/**
 * Takes a {@link ParsedUserTags} and combines it back together to an array of
 * tags that can be sent to the backend.
 *
 * @see parseTagArray
 */
export function serializeTags(tagValues: ParsedUserTags): string[] {
  const tags: string[] = [];

  // Cute little algorithm that operates on the shape of `TagFormValues`. Arrays
  // of tags are copied over. Values that are themselves tags are added. Values
  // that are booleans add their key, but only if the boolean is true.
  for (const [key, value] of Object.entries(tagValues)) {
    if (Array.isArray(value)) {
      tags.push(...value);
    } else if (typeof value === 'string' && value) {
      // && value check removes the tag by setting to empty string (since
      // setting to `null` is not always possible with form components)
      tags.push(value);
    } else if (typeof value === 'boolean' && value) {
      tags.push(key);
    }
  }

  return tags;
}
