import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { State } from '../../../constants';

import { AppState } from '../../../modules/flux-store';
import { ImmutableCurrentUserElection } from '../../../modules/user/reducers';
import {
  ApiCheckin,
  CheckinParameters,
  createCheckin,
} from '../../../services/checkin-service';
import { DateString } from '../../../services/common';
import { ApiElection, ApiLocation } from '../../../services/lbj-shared-service';
import { ApiCurrentUser, ApiUser } from '../../../services/user-service';
import { useChanges, useStateWithDeps } from '../../../utils/hooks';
import {
  UpdateQueryStateFn,
  useRouterQueryState,
} from '../../../utils/query-state';
import { MapFromJs } from '../../../utils/types';
import {
  DetailedUserWithAssignments,
  useDetailedUserWithAssignments,
  useLatestCheckinFromDay,
} from '../../hooks/user-data';
import CheckinForm, {
  CheckinFormState,
  CHECK_IN_OTHERS_ROLES,
  UNSET_VALUE,
} from '../../presentational/checkin/checkin-form';
import { useLocationAutocomplete } from '../../presentational/checkin/location-autocomplete';
import LoadingOverlay from '../../presentational/lbj/loading';
import { useToast } from '../../presentational/lbj/toast';

/**
 * We keep the selected user and a default assignment id in the query parameters
 * so they can be passed in from other parts of the UI.
 */
export type CheckinQueryState = {
  user_id: number | undefined;
  default_assignment_id: number | undefined;
};

/**
 * Component that wraps {@link CheckinForm} and includes all of the hooks
 * necessary to power its state.
 */
export const CheckinFormWithState: React.FunctionComponent<{
  queryState: CheckinQueryState;
  updateQueryState: UpdateQueryStateFn<CheckinQueryState>;
  currentUser: Pick<ApiUser, 'id' | 'role'>;
  currentElection: Pick<ApiElection, 'id' | 'state'>;
  now?: moment.Moment;
}> = ({
  queryState,
  updateQueryState,
  currentUser,
  currentElection,
  now = moment(),
}) => {
  const canCheckInOtherUsers = CHECK_IN_OTHERS_ROLES.includes(currentUser.role);

  const [toastData, { showToast, dismissToast }] = useToast();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // Used when the user is a manager or VPD and is able to check in for other
  // users.
  const selectedUserId = canCheckInOtherUsers
    ? queryState.user_id ?? null
    : currentUser.id;

  const setSelectedUserId = React.useCallback(
    (selectedUserId: number | null) => {
      updateQueryState({ user_id: selectedUserId ?? undefined });
    },
    [updateQueryState]
  );

  /**
   * The API-loaded instance of selected user ID. We include assignments so that
   * we can get locations out of them and also associate checkins with them.
   *
   * Note: we load this for the current user as well, which is maybe redundant
   * given what’s loaded for an ApiCurrentUser but it’s a small overhead in
   * order to be consistent across all users.
   */
  const selectedUser = useDetailedUserWithAssignments(selectedUserId, {
    // Ensures that we’re only working with assignments for the current day.
    dates: [now.format('YYYY-MM-DD') as DateString],
  });

  const [todaysCheckin, checkinActions] = useLatestCheckinFromDay(
    selectedUserId,
    now
  );

  /**
   * Preferred locations are derived from the user’s assignment(s) for the day
   * and the most recent checkin from today.
   *
   * `undefined` if the user or checkin are still loading, `null` if there’s no
   * user.
   */
  const preferredLocations = React.useMemo(() => {
    if (selectedUser === undefined || todaysCheckin === undefined) {
      return undefined;
    }

    if (selectedUser === null) {
      return null;
    }

    const locs = getAssignmentLocations(selectedUser);

    // We make sure that the location from the most recent checkin is in the list
    // of locations since we want to select it by default.
    if (
      todaysCheckin &&
      !locs.find((loc) => loc.id === todaysCheckin.location.id)
    ) {
      locs.unshift(todaysCheckin.location);
    }

    return locs;
  }, [selectedUser, todaysCheckin]);

  const [locationAutocompleteState, locationAutocompleteDispatch] =
    useLocationAutocomplete(
      currentElection,
      preferredLocations,
      // When the selected user ID changes, reset the location picker
      // completely.
      [selectedUserId]
    );

  // When the component loads, we set the selected user to the current user,
  // unless a selected user was already set in the query parameters. We do this
  // with an effect rather than having the current user as the default, since
  // once the component loads we use the query parameter being missing to mean
  // that no user is currently selected.
  React.useEffect(
    () => {
      if (queryState.user_id === null && canCheckInOtherUsers) {
        updateQueryState({ user_id: currentUser.id });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // If a `default_assignment_id` query parameter is passed, we wait until the
  // selected user loads and then set the location to that assignment’s
  // location.
  //
  // If the assignment is not found in the selectedUser this does nothing.
  //
  // In either case the query parameter is cleared out since it’s only a
  // default.
  //
  // Necessary for when the assignment list page links to checkins.
  React.useEffect(() => {
    if (queryState.default_assignment_id && selectedUser) {
      const assignmentRel = selectedUser.related.find(
        (rel) => rel.assignment.id === queryState.default_assignment_id
      );

      if (assignmentRel && 'location' in assignmentRel) {
        locationAutocompleteDispatch({
          type: 'SET_LOCATION',
          location: assignmentRel.location,
        });
      }

      updateQueryState({ default_assignment_id: undefined });
    }
  }, [
    queryState.default_assignment_id,
    selectedUser,
    updateQueryState,
    locationAutocompleteDispatch,
  ]);

  const locationId = locationAutocompleteState.location?.id;

  // We want to default the location to the location of the most recent
  // check-in.
  useChanges(
    (prevTodaysCheckin) => {
      if (!prevTodaysCheckin && todaysCheckin) {
        locationAutocompleteDispatch({
          type: 'SET_LOCATION',
          location: todaysCheckin.location,
        });
      }
    },
    [todaysCheckin]
  );

  const [formState, setFormState] = useStateWithDeps<CheckinFormState>(
    defaultFormStateForPreviousCheckin(todaysCheckin),
    // Form state resets when the user or location changes, or when the checkin
    // first loads. (We don’t change on subsequent checkin loads because we
    // don’t want to change the form out from under the user if a checkin comes
    // in async.)
    [selectedUserId, locationId, todaysCheckin !== undefined]
  );

  const checkinParams = formStateToCheckinParams({
    formState,
    locationId,
    selectedUserId,
  });

  const submitCheckIn =
    checkinParams && !isSubmitting
      ? async (isCheckOut: boolean) => {
          setIsSubmitting(true);

          try {
            const { checkin } = await createCheckin({
              type: isCheckOut ? 'checkout' : 'checkin',
              // This is the date from the user’s perspective, so we can look up
              // the assignment to associate this checkin with.
              date: now.format('YYYY-MM-DD') as DateString,
              ...checkinParams,
            });

            // Load the new checkin (with location)
            checkinActions.refresh();

            setFormState(defaultFormStateForPreviousCheckin(checkin));

            showToast({
              type: 'success',
              message: isCheckOut
                ? 'You successfully checked out!'
                : 'You successfully checked in!',
            });
          } catch {
            showToast({
              type: 'error',
              message: isCheckOut
                ? 'Oh no! We couldn’t check you out. Please try again in a bit.'
                : 'Oh no! We couldn’t check you in. Please try again in a bit.',
            });
          } finally {
            setIsSubmitting(false);
          }
        }
      : null;

  return (
    <CheckinForm
      currentUserRole={currentUser.role}
      lastCheckin={todaysCheckin}
      isSubmitting={isSubmitting}
      selectedUserId={selectedUserId}
      setSelectedUserId={setSelectedUserId}
      selectedUser={selectedUser}
      submitCheckIn={submitCheckIn}
      locationAutocompleteState={locationAutocompleteState}
      locationAutocompleteDispatch={locationAutocompleteDispatch}
      toastData={toastData}
      onDismissToast={dismissToast}
      formState={formState}
      updateFormState={
        // We don’t allow setting form state while these are loading or unset
        // because when they finish the form state will be reset.
        selectedUser &&
        locationAutocompleteState.location &&
        todaysCheckin !== undefined
          ? (updates) =>
              setFormState((prevState) => {
                // Maintain the invariant that if waitTime isn’t complete then
                // the two questions _must_ be null.
                const waitTime = updates.waitTime ?? prevState.waitTime;

                if (waitTime === 'complete') {
                  return {
                    ...prevState,
                    ...updates,
                    waitTime,
                  };
                } else {
                  return {
                    waitTime,
                    isCountingFinished: null,
                    isResultsAnnounced: null,
                  };
                }
              })
          : null
      }
      now={now}
    />
  );
};

/**
 * Based on our form state and other parameters, either returns a
 * {@link CheckinParameters} object (or most of one, anyway) if the data passes
 * validation, or `null` if it doesn’t.
 */
function formStateToCheckinParams({
  selectedUserId,
  locationId,
  formState,
}: {
  selectedUserId: number | null;
  locationId: number | undefined;
  formState: CheckinFormState;
}): Omit<CheckinParameters, 'type'> | null {
  if (selectedUserId === null || locationId === undefined) {
    return null;
  }

  const { waitTime, isCountingFinished, isResultsAnnounced } = formState;

  if (waitTime === UNSET_VALUE) {
    return null;
  }

  if (
    waitTime === 'complete' &&
    (isCountingFinished === null || isResultsAnnounced === null)
  ) {
    return null;
  }

  return {
    // This will get filled in by the backend based on the user’s assignments
    // today.
    assignment: null,
    isCountingFinished,
    isResultsAnnounced,
    location: locationId,
    user: selectedUserId,
    waitTime,
  };
}

/**
 * Returns the form state we should default to given the most recent check-in.
 *
 * Really this just sets the “waitTime” to `complete` if it already is
 * `complete`, on the assumption that polls will not generally re-open.
 *
 * Factored out for consistency since this is used both when loading the page
 * initially and after submitting a checkin.
 */
function defaultFormStateForPreviousCheckin(
  checkin: Pick<ApiCheckin, 'wait_time'> | undefined | null
): CheckinFormState {
  return {
    waitTime: checkin?.wait_time === 'complete' ? 'complete' : UNSET_VALUE,
    isCountingFinished: null,
    isResultsAnnounced: null,
  };
}

/**
 * Returns any locations in the `related` values for a user.
 *
 * We use this to provide “preferred” options for checking in.
 *
 * We assume that we will only see assignments for the current day because of
 * the `dates` filter that’s used in the parent container’s call to
 * useDetailedUserWithAssignments.
 */
function getAssignmentLocations({
  related,
}: Pick<DetailedUserWithAssignments, 'related'>): ApiLocation[] {
  return related
    .map((rel) => ('location' in rel ? rel.location : null))
    .filter((l): l is ApiLocation => !!l);
}

/**
 * Wrapper to ensure that there is a current user and current election loaded so
 * that we don’t have to keep checking for `null` in
 * {@link CheckinFormWithState}.
 */
const CheckInRoute: React.FunctionComponent<{
  currentUserData: MapFromJs<ApiCurrentUser> | null;
  currentUserElection: ImmutableCurrentUserElection | null;
}> = ({ currentUserData, currentUserElection }) => {
  const [queryState, updateQueryState] = useRouterQueryState<CheckinQueryState>(
    (q) => ({
      default_assignment_id: q.number(),
      user_id: q.number(),
    })
  );

  if (currentUserData && currentUserElection) {
    return (
      <CheckinFormWithState
        queryState={queryState}
        updateQueryState={updateQueryState}
        currentUser={{
          id: currentUserData.get('id'),
          role: currentUserData.get('role'),
        }}
        currentElection={{
          id: currentUserElection.getIn(['election', 'id']) as number,
          state: currentUserElection.getIn(['election', 'state']) as State,
        }}
      />
    );
  } else {
    return <LoadingOverlay />;
  }
};

export default connect(
  ({ user }: AppState): React.ComponentProps<typeof CheckInRoute> => ({
    currentUserData: user.currentUser.userData,
    currentUserElection: user.currentUser.currentUserElection,
  })
)(CheckInRoute);
