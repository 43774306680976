import React from 'react';
import {
  AriaRadioGroupProps,
  useFocusRing,
  useRadio,
  useRadioGroup,
  VisuallyHidden,
} from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';

export type TextRadioGroupProps<K extends string> = {
  options: { [key in K]?: string };
  value: K | null;
  onChange: (newK: K) => void;
} & Omit<AriaRadioGroupProps, 'value' | 'defaultValue' | 'onChange'>;

/**
 * Component for a “radio group” that is a horizontal list of text buttons.
 *
 * Options are presented in the order they’re defined in the `options` prop.
 */
export const TextRadioGroup = <K extends string>({
  options,
  value,
  onChange,
  ...props
}: TextRadioGroupProps<K>): React.ReactElement<any, any> | null => {
  // controlled version of radio group state
  const state = useRadioGroupState({
    value: value || '',
    onChange: onChange as (value: string) => void,
  });

  const { radioGroupProps, labelProps } = useRadioGroup(
    { orientation: 'horizontal', ...props },
    state
  );

  return (
    <div {...radioGroupProps} className="whitespace-nowrap text-base">
      {props.label && (
        <span {...labelProps} className="font-bold">
          {props.label}
        </span>
      )}{' '}
      {Object.entries(options).map(([value, label], idx) => {
        const button = (
          <TextRadioGroupOption value={value} state={state} key={value}>
            {label}
          </TextRadioGroupOption>
        );

        if (idx === 0) {
          return button;
        } else {
          return (
            <React.Fragment key={value}>
              {' | '}
              {button}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

/**
 * Radio-ish text link for within a link group.
 *
 * This keeps the radio input for a11y but hides the input itself and just
 * displays its label, formatted as a link.
 *
 * @see TextRadioGroup
 * @see https://react-spectrum.adobe.com/react-aria/useRadioGroup.html#styling
 */
const TextRadioGroupOption: React.FunctionComponent<{
  value: string;
  state: RadioGroupState;
}> = ({ value, children, state }) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const { inputProps, isSelected, isDisabled } = useRadio(
    // pass children to disable the warning about needing a label
    { value, children },
    state,
    ref
  );

  // Necessary to show the outline on the label when the hidden input element is
  // what has focus.
  const { isFocusVisible, focusProps } = useFocusRing();

  const textColorClass = isSelected
    ? 'text-black'
    : isDisabled
    ? 'text-gray'
    : 'text-accent';

  return (
    <label
      className={`m-0 inline text-base font-bold ${textColorClass} ${
        isFocusVisible
          ? 'rounded-sm outline outline-2 outline-offset-2 outline-primary'
          : ''
      }`}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      {children}
    </label>
  );
};
