import _ from 'lodash';
import React from 'react';

const foldThreshold = 8;
const foldTo = 4;

const SHOW_MORE_LESS_CLASSES =
  'text-legacy-xs leading-5 font-bold uppercase text-hyperlink';

/**
 * Displays a list of “choice” links. Clicking them picks them as the value for
 * the component, and clicking them again toggles them off.
 *
 * This can operate in two modes. Single, the default, makes the options
 * mutually-exclusive. This allows us to put stricter types on the onChange
 * callback, since we know the exact set of values that can be returned.
 *
 * In `multi` mode, the value of this component is a comma-separated list of
 * values, and is therefore just typed as a string.
 *
 * TODO(fiona): Change multi-mode’s value to be an array maybe.
 */
export default class ChoiceFilter<T extends string> extends React.Component<
  {
    name: string;
    title?: string;
    /** Map of values to their labels. */
    choices: { [value in T]?: string };

    isLoading?: boolean;
    isDisabled?: boolean;
  } & (
    | {
        value: T | null | undefined | '';
        onChange: (ev: {
          target: {
            name: string;
            value: T | '';
          };
        }) => void;
        multi?: false;
      }
    | {
        /** Comma-separated string of choice keys. */
        value: string | null | undefined;
        onChange: (ev: {
          target: {
            name: string;
            value: string;
          };
        }) => void;
        multi: true;
      }
  ),
  {
    showingMore: boolean;
  }
> {
  constructor(props: ChoiceFilter<T>['props']) {
    super(props);

    this.state = {
      showingMore: false,
    };
  }

  onToggleFilter(choiceName: T, linkDisabled: boolean, e: React.MouseEvent) {
    e.preventDefault();

    if (linkDisabled) {
      return;
    }

    this.setState({ showingMore: false });

    if (!this.props.multi) {
      this.onToggleSingleFilter(choiceName);
    } else {
      this.onToggleMultiFilter(choiceName);
    }
  }

  onToggleSingleFilter(choiceName: T) {
    // This constrains this.props to the version for single-select, where “value”
    // is of type T.
    if (this.props.multi) {
      return;
    }

    const { name, value } = this.props;

    // Deselect the filter if the active one is clicked on
    const newValue = value === choiceName ? '' : choiceName;

    this.props.onChange({
      target: {
        name,
        value: newValue,
      },
    });
  }

  onToggleMultiFilter(choiceName: T) {
    // This constrains this.props to the version for multi-select, where “value”
    // can be a comma-separated list.
    if (!this.props.multi) {
      return;
    }

    const { name, value } = this.props;
    const values = value ? value.split(',') : [];
    if (values.includes(choiceName)) {
      // Deselect the filter if the active one is clicked on
      _.pull(values, choiceName);
    } else {
      values.push(choiceName);
    }
    const newValues = values.join(',');
    this.props.onChange({
      target: {
        name,
        value: newValues,
      },
    });
  }

  getSubheading() {
    const { isLoading, isDisabled, multi } = this.props;
    if (isLoading) {
      return 'Loading...';
    } else if (isDisabled) {
      return 'None Available';
    } else if (multi) {
      return '(select multiple)';
    }

    return '';
  }

  render() {
    const { title, value, choices } = this.props;
    const selected = value ? value.split(',') : [];
    const linkDisabled = _.size(choices) === 1;

    let visibleChoices: Partial<typeof choices>;
    if (
      Object.keys(choices).length < foldThreshold ||
      this.state.showingMore ||
      selected.length
    ) {
      visibleChoices = choices;
    } else {
      const visibleChoiceKeys = _.take(_.keys(choices), foldTo);
      visibleChoices = _.pick(choices, visibleChoiceKeys);
    }
    const numInvisibleChoices = _.size(choices) - _.size(visibleChoices);

    return (
      <div className="mb-3">
        <h5 className="text-legacy-sm font-bold uppercase leading-5">
          {title}
        </h5>
        <h6 className="text-legacy-xs uppercase leading-5">
          {this.getSubheading()}
        </h6>
        <ul>
          {_.map(visibleChoices, (choice: string, choiceName: T) => {
            const currentSelected = selected.includes(choiceName);
            return (
              <li
                key={choiceName}
                className={`${
                  currentSelected ? 'font-bold text-hyperlink' : ''
                } text-legacy-sm leading-6 hover:underline `}
              >
                <a
                  href="#"
                  onClick={this.onToggleFilter.bind(
                    this,
                    choiceName,
                    linkDisabled
                  )}
                >
                  {choice}
                  {currentSelected ? (
                    <span className="text-legacy-xs font-normal text-gray">
                      {' '}
                      (selected)
                    </span>
                  ) : (
                    ''
                  )}
                </a>
              </li>
            );
          })}
          {numInvisibleChoices > 0 ? (
            <li
              className={SHOW_MORE_LESS_CLASSES}
              onClick={() => this.setState({ showingMore: true })}
            >
              See {numInvisibleChoices} More &gt;
            </li>
          ) : (
            ''
          )}
          {numInvisibleChoices === 0 && this.state.showingMore === true ? (
            <li
              className={SHOW_MORE_LESS_CLASSES}
              onClick={() => this.setState({ showingMore: false })}
            >
              See fewer
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    );
  }
}
