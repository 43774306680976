import * as Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actionCreators as notificationsActionCreators } from '../../../modules/notifications';
import RIT from '../../../utils/render-if-truthy';

@connect(() => {
  return {};
})
export default class NotificationItem extends Component {
  static propTypes = {
    state: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    changes: PropTypes.object.isRequired,
  };

  getLocationChangeBody(changes) {
    // Get the oldest `old` and the newest `new` for each attribute changed
    const changeRollup = {};
    changes.forEach((change) => {
      change.get('change_data').forEach((value, key) => {
        changeRollup[key] = { old: value.get('old') };
      });
    });
    changes.reverse().forEach((change) => {
      change.get('change_data').forEach((value, key) => {
        changeRollup[key].new = value.get('new');
      });
    });

    const changeStrings = _.map(changeRollup, (diff, field) => {
      return `${field.replace('_', ' ')} changed from “${diff.old}” to “${
        diff.new
      }”`;
    });

    if (changeStrings.length === 1) {
      return _.upperFirst(changeStrings[0] + '.');
    } else if (changeStrings.length === 2) {
      return _.upperFirst(`${changeStrings[0]} and ${changeStrings[1]}.`);
    }
    const start = changeStrings.slice(0, changeStrings.length - 1).join(', ');
    return _.upperFirst(
      `${start}, and ${changeStrings[changeStrings.length - 1]}.`
    );
  }

  getDisplayData(state, changes) {
    switch (changes.first().get('event')) {
      case 'precinct_location_added':
        return this.dataForPrecinctLocationAdded(state, changes);
      case 'assignment_location_removed':
        return this.dataForAssignmentLocationRemoved(state, changes);
      case 'location_changed':
        return this.dataForLocationChanged(state, changes);
    }
  }

  dataForPrecinctLocationAdded(state, changes) {
    const locationIds = Immutable.Set(
      changes.map((change) => change.getIn(['location', 'id']))
    );
    return {
      title: `New location${changes.size === 1 ? '' : 's'} added`,
      body:
        `Precinct ${changes.first().getIn(['precinct', 'name'])} ` +
        `has ${changes.size} new location${changes.size === 1 ? '' : 's'}.`,
      timestamp: moment(changes.first().get('created_at')).fromNow(),
      clickThroughUrl: `/assignments?view=locations&state=${state}&location=${locationIds
        .toJS()
        .join(',')}`,
      changeIds: changes.map((change) => change.get('id')),
      acked: changes.first().get('acked'),
    };
  }

  dataForLocationChanged(state, changes) {
    // Location is changed, separate link for each assignment/day?
    const locationIds = Immutable.Set(
      changes.map((change) => change.getIn(['location', 'id']))
    );
    return {
      title: `${changes.first().getIn(['location', 'name'])} updated`,
      body: this.getLocationChangeBody(changes),
      timestamp: moment(changes.first().get('created_at')).fromNow(),
      ctaText: 'Send Assignments',
      clickThroughUrl: `/assignments/?view=locations&state=${state}&location=${locationIds
        .toJS()
        .join(',')}`,
      changeIds: changes.map((change) => change.get('id')),
      acked: changes.first().get('acked'),
    };
  }

  dataForAssignmentLocationRemoved(state, changes) {
    const precinctIds = Immutable.Set(
      changes.map((change) => change.getIn(['precinct', 'id']))
    );
    return {
      title: 'Assignments need locations',
      body:
        `${changes.size} assignment${changes.size === 1 ? '' : 's'} ` +
        `in precinct ${changes.first().getIn(['precinct', 'name'])} ` +
        `need${changes.size === 1 ? 's' : ''} a location.`,
      timestamp: moment(changes.first().get('created_at')).fromNow(),
      ctaText: 'Update Assignments',
      clickThroughUrl: `/assignments/?view=precincts&state=${state}&precinct=${precinctIds
        .toJS()
        .join(',')}`,
      changeIds: changes.map((change) => change.get('id')),
      acked: changes.first().get('acked'),
    };
  }

  updateNotifications(state, changeIds) {
    const { dispatch } = this.props;
    const { updateLocationChanges } = notificationsActionCreators;
    dispatch(updateLocationChanges(state, changeIds));
  }

  renderActionButton(changeIds) {
    return (
      <div className="button-container">
        <button
          type="button"
          className="c-button-large c-button-secondary"
          onClick={this.updateNotifications.bind(this, 'acked', changeIds)}
        >
          {
            /* This needs to support CTA text and redirecting you to somewhere
              useful based on the CTA url.*/
            'OK'
          }
        </button>
      </div>
    );
  }

  renderAckedBadge(changeIds) {
    return (
      <div className="completed-banner">
        <div
          className="checkmark"
          onClick={this.updateNotifications.bind(this, 'unacked', changeIds)}
        ></div>
      </div>
    );
  }

  render() {
    const { title, body, timestamp, clickThroughUrl, changeIds, acked } =
      this.getDisplayData(this.props.state, this.props.changes);

    return (
      <div className="notification-container row">
        <Link to={clickThroughUrl} className="col-small-8">
          <div className="notification-content">
            <p className="title">
              {title}
              <span className="timestamp">{timestamp}</span>
            </p>
            <p>{body}</p>
          </div>
        </Link>
        <div className="col-small-4">
          {RIT(!acked, () => this.renderActionButton(changeIds))}
          {RIT(!!acked, () => this.renderAckedBadge(changeIds))}
        </div>
      </div>
    );
  }
}
