import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import _ from 'lodash';
import * as React from 'react';

import usePlacesAutocomplete, {
  getDetails,
  RequestOptions,
} from 'use-places-autocomplete';

import { usePollingLocation } from '../../components/hooks/lbj-data';
import Input from '../../components/presentational/form/input';
import StateSelect from '../../components/presentational/form/state-select';
import { bboxToLatLngBounds, US_NATIONAL_STATE_CODE } from '../../constants';

import { NewIssueInProgress } from '../../modules/issue/reducers';
import { loadGoogleMapsPlaces } from '../../utils/google-maps';

import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';

import {
  addrComponentsToStructuredAddrFields,
  ADDRESS_COMPONENT_TYPE,
  filterSuggestionData,
  formatAddressSuggestion,
  getAddressCoordinates,
} from './issue-utils';

const IssueStructuredVoterAddress: React.FunctionComponent<{
  issueData: NewIssueInProgress | IssueInProgressPayload;
  onInputChange: (event: {
    target: {
      name: string;
      value: string | boolean | null | undefined | number;
    };
  }) => void;
  onSetFields: (changes: any) => void;
  formType: 'poll_observer' | 'hotline';
  canUpdateVoterAddress: boolean;
}> = ({
  issueData,
  onInputChange,
  onSetFields,
  formType,
  canUpdateVoterAddress,
}) => {
  const [googleMapsPlaces, setGoogleMapsPlaces] = React.useState<
    (google.maps.CoreLibrary & google.maps.PlacesLibrary) | null
  >(null);

  React.useEffect(() => {
    loadGoogleMapsPlaces().then(setGoogleMapsPlaces);
  }, []);

  /* An AutocompleteSessionToken bundles requests
    for address suggestions and address details into one session
    included in:
    requestOptionsWithStateBounds,
    requestOptionsWithLatLon, and
    getDetails request

    Note: only creates a session token when googleMapsIsReady
  */
  const placesSessionToken = React.useMemo(
    () => googleMapsPlaces && new googleMapsPlaces.AutocompleteSessionToken(),
    [googleMapsPlaces]
  );

  const stateForBounds =
    issueData.voter_state || issueData.state || US_NATIONAL_STATE_CODE;

  // establish default request options
  // if the session token changes,
  // defaultRequestOptions will update
  const defaultRequestOptions = React.useMemo(
    () =>
      placesSessionToken
        ? {
            componentRestrictions: { country: 'us' },
            types: ['address'],
            language: 'en',
            sessionToken: placesSessionToken,
          }
        : {
            componentRestrictions: { country: 'us' },
            types: ['address'],
            language: 'en',
          },
    [placesSessionToken]
  );

  // if the stateForBounds changes,
  // or if the defaultRequestOptions change (ex: new sessionToken)
  // requestOptionsWithStateBounds will update
  const requestOptionsWithStateBounds = React.useMemo<RequestOptions>(
    () => ({
      ...defaultRequestOptions,
      bounds: bboxToLatLngBounds(stateForBounds),
    }),
    [defaultRequestOptions, stateForBounds]
  );

  // if the Issue form is for a hotline volunteer
  // sets the locationIdToFind to null
  const locationIdToFind =
    formType === 'poll_observer' ? issueData.location : null;
  const pollingLocation = usePollingLocation(locationIdToFind);
  const pollObserverLocation = pollingLocation?.location;

  // if the pollObserverLocation changes (ex: undefined -> ApiLocation),
  // if the defaultRequestOptions change (ex: new sessionToken),
  // or if the requestOptionsWithStateBounds change (ex: updated state)
  // pollRequestOptions will update
  const pollRequestOptions = React.useMemo<RequestOptions>(() => {
    const pollObserverLat = pollObserverLocation?.coordinates[0];
    const pollObserverLon = pollObserverLocation?.coordinates[1];

    if (pollObserverLat && pollObserverLon && googleMapsPlaces) {
      return {
        ...defaultRequestOptions,

        location: new googleMapsPlaces.LatLng(pollObserverLat, pollObserverLon),
        // radius is defined in meters
        // 16094 meters is approx. 10 miles
        radius: 16094,
      };
    } else {
      return requestOptionsWithStateBounds;
    }
  }, [
    googleMapsPlaces,
    pollObserverLocation,
    defaultRequestOptions,
    requestOptionsWithStateBounds,
  ]);

  const addressRequestOptions =
    formType === 'hotline' ? requestOptionsWithStateBounds : pollRequestOptions;

  const {
    setValue: setAddressAutocompleteValue,
    ready,
    suggestions: { status, data },
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    googleMaps: googleMapsPlaces,
    requestOptions: addressRequestOptions,
    debounce: 300,
    initOnMount: false,
  });

  React.useEffect(() => {
    if (googleMapsPlaces) {
      init();
    }
  }, [googleMapsPlaces, init]);

  // the event type is a modified
  // copy of the onInputChange event type
  const handleAutocompleteInput = (event: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    const inputText = event.target.value;
    onInputChange(event);
    // trigger Google address autocomplete
    // with enough text to perform search
    if (inputText && inputText.length > 3) {
      setAddressAutocompleteValue(inputText);
    }
    if (!inputText || inputText.length < 1) {
      clearSuggestions();
    }
  };

  const placeDetailsRequestOptions = (
    selectedPlace: google.maps.places.AutocompletePrediction
  ) => {
    if (placesSessionToken) {
      return {
        placeId: selectedPlace.place_id,
        fields: ['address_components', 'geometry', 'name'],
        sessionToken: placesSessionToken,
      };
    }
    return {
      placeId: selectedPlace.place_id,
      fields: ['address_components', 'geometry', 'name'],
    };
  };

  const handleAutocompleteSelection = (displayAddressValue: string) => {
    const selectedPlace = filteredSuggestions.find((suggestion) => {
      const formattedSuggestedAddress = formatAddressSuggestion(suggestion);
      return formattedSuggestedAddress === displayAddressValue;
    });
    if (selectedPlace) {
      const detailsRequestOptions = placeDetailsRequestOptions(selectedPlace);
      getDetails(detailsRequestOptions).then(
        (placeDetailsResponse: string | google.maps.places.PlaceResult) => {
          // getDetails either returns a string or PlaceResult
          // it is unclear what the string would be
          if (typeof placeDetailsResponse !== 'string') {
            const { address_components } = placeDetailsResponse;
            const addressComponents = address_components ?? [];
            const { street_address, city, stateCode, zipCode } =
              addrComponentsToStructuredAddrFields(addressComponents);
            const coordinates = getAddressCoordinates(
              placeDetailsResponse.geometry
            );
            onSetFields({
              voter_street_address: street_address,
              voter_city: city,
              voter_state: stateCode,
              voter_zipcode: zipCode,
              voter_address_components: addressComponents,
              voter_address_coordinates: coordinates,
              voter_address_components_type: ADDRESS_COMPONENT_TYPE,
            });
            clearSuggestions();
          }
        }
      );
    }
  };

  // filteredSuggestions are in-state results based on the stateForBounds
  const filteredSuggestions = filterSuggestionData(data, stateForBounds);

  const renderAutofillSuggestions = () => {
    return filteredSuggestions.map((suggestion) => {
      const formattedAddressCityState = formatAddressSuggestion(suggestion);
      return (
        <ComboboxOption
          className="cursor-pointer px-2.5 py-1.5 hover:bg-hover aria-selected:bg-hover"
          key={suggestion.place_id}
          aria-label={formattedAddressCityState}
          value={formattedAddressCityState}
        />
      );
    });
  };

  const renderStructuredAddressForm = () => {
    return (
      <>
        <Combobox
          className="lbj-input lbj-input-text lbj-input-voter_street_address"
          onSelect={handleAutocompleteSelection}
          aria-label="Address Input"
          openOnFocus
        >
          <label id="address_line_1">Address Line 1</label>
          <ComboboxInput
            id="voter_street_address_input"
            name="voter_street_address"
            aria-labelledby="address_line_1"
            value={issueData.voter_street_address || ''}
            onChange={handleAutocompleteInput}
            disabled={!ready || !canUpdateVoterAddress}
            placeholder="Begin typing an address"
            className="w-full rounded px-3.5 py-2.5"
            autoComplete="off"
            data-1p-ignore
          />
          <ComboboxPopover className="bg-white shadow">
            <ComboboxList>
              {status === 'OK' && renderAutofillSuggestions()}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        <Input
          title="Address Line 2"
          name="voter_street_address_2"
          placeholder="Apt, Suite, etc"
          value={issueData.voter_street_address_2 || ''}
          onChange={onInputChange}
          onFocus={clearSuggestions}
          type="text"
          autoComplete="off"
          data-1p-ignore
          disabled={!canUpdateVoterAddress}
        />
        <Input
          title="City"
          name="voter_city"
          placeholder="City"
          value={issueData.voter_city || ''}
          onChange={onInputChange}
          onFocus={clearSuggestions}
          type="text"
          autoComplete="off"
          data-1p-ignore
          disabled={!canUpdateVoterAddress}
        />
        <StateSelect
          includeNational={false}
          includeTerritories={false}
          name="voter_state"
          title="State"
          onChange={onInputChange}
          defaultValue={issueData.voter_state || issueData.state || null}
          disabled={!canUpdateVoterAddress}
        />
        <Input
          title="Zip Code"
          name="voter_zipcode"
          placeholder="Zip Code"
          value={issueData.voter_zipcode || ''}
          onChange={onInputChange}
          onFocus={clearSuggestions}
          type="text"
          autoComplete="off"
          data-1p-ignore
          disabled={!canUpdateVoterAddress}
        />
      </>
    );
  };

  // National Hotline Workers are not associated with a state.
  // Selecting a state first enables the hotline form to set
  // accurate latlngbounds before searching
  return (
    <>
      {formType === 'hotline' &&
      issueData.state === null &&
      !issueData.voter_state ? (
        <StateSelect
          includeNational={false}
          includeTerritories={false}
          name="voter_state"
          title="State"
          onChange={onInputChange}
        />
      ) : (
        renderStructuredAddressForm()
      )}
    </>
  );
};

export default IssueStructuredVoterAddress;
