import keyMirror from 'key-mirror';

export default keyMirror({
  SEND_EMAIL: null,
  RESET_EMAIL_RECEIPT: null,
  ADD_USERS_TO_QUEUE: null,
  REMOVE_USERS_FROM_QUEUE: null,
  ADD_ALL_USERS_TO_QUEUE: null,
  REMOVE_ALL_USERS_FROM_QUEUE: null,
});
