import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';

import RIT from '../../../utils/render-if-truthy';
import Checkbox from '../../presentational/form/checkbox';
import OptionalField from '../../presentational/form/optional-field';

import Select from '../../presentational/form/select';
import TextArea from '../../presentational/form/textarea';

import ShiftPickerContainer from './assignment-shift-picker-container';

@connect((state) => {
  const { assignment } = state;
  const assignmentEditor = assignment.get('assignmentEditor');
  const assignmentType = assignmentEditor.get('assignmentType');
  const requiresHotlineManagerCheckbox =
    assignmentType === 'hotline_center' || assignmentType === 'hotline_manager';
  const requiresAssignmentTypePicker = assignmentEditor.hasIn([
    'parentResource',
    'user',
  ]);
  const requiresPlaceStatusInput = assignmentType === 'poll';
  const updatedFields = assignmentEditor.get('updatedFields');

  return {
    updatedFields,
    assignmentType,
    shiftDate: updatedFields.get('shift_date'),
    parentResource: assignmentEditor.get('parentResource'),
    requiresPlaceStatusInput,
    requiresHotlineManagerCheckbox,
    requiresAssignmentTypePicker,
  };
})
export default class AssignmentDetailsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    updatedFields: PropTypes.object.isRequired,
    shiftDate: PropTypes.string.isRequired,
    parentResource: PropTypes.object.isRequired,
    assignmentType: PropTypes.string,
    requiresPlaceStatusInput: PropTypes.bool.isRequired,
    requiresHotlineManagerCheckbox: PropTypes.bool.isRequired,
    requiresAssignmentTypePicker: PropTypes.bool.isRequired,
    requirePrecinctPicker: PropTypes.bool.isRequired,
    defaultPrecinct: PropTypes.object,
    onEditAssignmentData: PropTypes.func.isRequired,
    children: PropTypes.node,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      shift: {
        start_time: props.startTime,
        end_time: props.endTime,
      },
    };

    if (props.requirePrecinctPicker) {
      this.state.assignToAllPrecincts = !props.defaultPrecinct;
    }
  }

  componentDidMount() {
    // need to make sure that we update the store with the default start
    // and end times as soon as we've finished loading everything
    this.onShiftChange(this.state.shift);
  }

  onSaveAssignmentType(assignmentType) {
    const { dispatch } = this.props;
    const { saveAssignmentType } = assignmentActionCreators;

    dispatch(saveAssignmentType(assignmentType));
  }

  onPickPlaceStatus({ target }) {
    const { onEditAssignmentData } = this.props;
    const placeStatus = target.checked ? 'outside' : 'inside';

    onEditAssignmentData({ place_status: placeStatus });
  }

  onShiftChange(shiftInfo) {
    const { shift } = this.state;
    const updatedShift = _.assign({}, shift, shiftInfo);
    this.setState({ shift: updatedShift });
    this.props.onEditAssignmentData(updatedShift);
  }

  renderPlaceStatusInput() {
    const { updatedFields } = this.props;

    return (
      <div className="row">
        <div className="col-small-12">
          <Checkbox
            name="place_status"
            title="Assign to outside"
            showLabel
            checked={updatedFields.get('place_status') === 'outside'}
            onChange={this.onPickPlaceStatus.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderHotlineManagerCheckbox() {
    const { assignmentType } = this.props;

    return (
      <div className="row">
        <div className="col-small-12">
          <Checkbox
            name="assignment_type"
            title="Assign as hotline manager"
            showLabel
            checked={assignmentType === 'hotline_manager'}
            onChange={({ target }) => {
              const selectedType = target.checked
                ? 'hotline_manager'
                : 'hotline_center';
              this.onSaveAssignmentType(selectedType);
            }}
          />
        </div>
      </div>
    );
  }

  renderAssignmentTypePicker() {
    const { assignmentType } = this.props;
    const defaultValue =
      assignmentType === 'hotline_manager' ? 'hotline_center' : assignmentType;

    return (
      <div className="row">
        <div className="col-small-12">
          <Select
            title="Assignment Type"
            name="assignment_type"
            value={defaultValue}
            choices={{
              poll: 'Polling Place',
              boiler_room: 'Boiler Room',
              hotline_center: 'Hotline Center',
              board_of_elections: 'Board of Elections',
            }}
            onChange={({ target }) => this.onSaveAssignmentType(target.value)}
          />
        </div>
      </div>
    );
  }

  renderAssignToAllPrecinctsCheckbox() {
    const { updatedFields, onEditAssignmentData } = this.props;
    const { assignToAllPrecincts } = this.state;

    return (
      <div className="row">
        <div className="col-small-12">
          <Checkbox
            name="precinct"
            title="Assign to all precincts"
            showLabel
            checked={assignToAllPrecincts && !updatedFields.get('precinct')}
            onChange={({ target }) => {
              if (target.checked) {
                onEditAssignmentData({ precinct: null });
                this.setState({ assignToAllPrecincts: true });
              } else {
                this.setState({ assignToAllPrecincts: false });
              }
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      requiresAssignmentTypePicker,
      requiresHotlineManagerCheckbox,
      requiresPlaceStatusInput,
      requirePrecinctPicker,
      onEditAssignmentData,
      shiftDate,
      updatedFields,
      children,
    } = this.props;
    const { shift, assignToAllPrecincts } = this.state;

    return (
      <div>
        <div className="lbj-column-label">
          <h4>Assignment Details</h4>
        </div>

        <div className="lbj-column-content">
          {RIT(
            requiresAssignmentTypePicker,
            this.renderAssignmentTypePicker.bind(this)
          )}

          <ShiftPickerContainer
            assignmentDate={shiftDate}
            onShiftChange={this.onShiftChange.bind(this)}
            startTime={shift.start_time}
            endTime={shift.end_time}
          />

          {RIT(
            requiresHotlineManagerCheckbox,
            this.renderHotlineManagerCheckbox.bind(this)
          )}
          {RIT(
            requiresPlaceStatusInput,
            this.renderPlaceStatusInput.bind(this)
          )}
          {RIT(
            requirePrecinctPicker,
            this.renderAssignToAllPrecinctsCheckbox.bind(this)
          )}
          {RIT(requirePrecinctPicker && !assignToAllPrecincts, () => children)}

          <div className="row assignment-notes-row">
            <div className="col-small-12">
              <OptionalField label="Add a note">
                <TextArea
                  name="notes"
                  title="Add a note"
                  placeholder=""
                  defaultValue={updatedFields.get('notes')}
                  onChange={_.debounce(
                    (e) => {
                      onEditAssignmentData({ notes: e.target.value });
                    },
                    200,
                    { trailing: true }
                  )}
                />
              </OptionalField>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
