import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userViewTypes, EMAIL_TEMPLATES } from '../../../constants';
import paginatedComponent from '../../../decorators/paginated-component';
import { actionCreators as assignmentActionCreators } from '../../../modules/assignment';
import { actionCreators as emailActionCreators } from '../../../modules/email';
import { actionCreators as userActionCreators } from '../../../modules/user';
import {
  bulkTagUsers,
  cloneUsers,
  disableUsers,
} from '../../../modules/user/action-creators';
import mapStateToPagination from '../../../utils/lbj/map-state-to-pagination';
import RIT from '../../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import mapStateToLbjPermissions from '../../../utils/user/map-state-to-lbj-permissions';
import mapStateToUserEmail from '../../../utils/user/map-state-to-user-email';
import mapStateToUserFilters from '../../../utils/user/map-state-to-user-filters';
import mapStateToUserList from '../../../utils/user/map-state-to-user-list';

import ExportModal from '../../presentational/assignment/assignment-export-modal';
import EmailConfirmationModal from '../../presentational/email/email-confirmation-modal';
import EmailNotificationToolbar from '../../presentational/email/email-notification-toolbar';
import SendEmailModal from '../../presentational/email/send-email-modal';
import EmptyAlert from '../../presentational/lbj/empty-alert';

import LoadingOverlay from '../../presentational/lbj/loading';

import UserTable from '../../presentational/user/user-table';

import BulkTaggerModal from './bulk-tagger-modal';
import CloneUsersModal from './clone-users-modal';
import DisableUsersModal from './disable-users-modal';

const { ASSIGNMENT_NOTIFY } = userViewTypes;

@connect((state) => {
  const { assignment, user } = state;
  const paginationData = mapStateToPagination(user, ['users', 'index']);
  const filters = mapStateToUserFilters(state);
  const currentUser = user.get('currentUser');
  const currentElection = currentUser.get('currentUserElection');
  const defaultReplyTo = currentElection
    ? currentElection.getIn(['election', 'contact_email'])
    : null;
  const { canEmailUsers, canExport } = mapStateToLbjPermissions(state);
  const isExporting =
    assignment.getIn(['assignmentExport', 'requestIsPending']) ||
    user.getIn(['updatingUser', 'requestIsPending']);

  return _.assign(
    {
      currentUser,
      filters,
      paginationData,
      defaultReplyTo,
      canEmailUsers,
      canExport,
      isExporting,
    },
    mapStateToUserEmail(state),
    mapStateToUserList(state)
  );
})
@withRouting
@paginatedComponent((props, page) => {
  const { navigate, location } = props;
  const { pathname, search } = location;
  const query = searchToQuery(search);

  navigate({
    pathname,
    search: queryToSearch(_.assign({}, query, page)),
  });
})
export default class UserList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    totalUserCount: PropTypes.object,
    userListData: PropTypes.object.isRequired,
    isFetchingUserList: PropTypes.bool.isRequired,
    userResponseErred: PropTypes.bool.isRequired,
    emailQueue: PropTypes.object.isRequired,
    isEmailingUsers: PropTypes.bool.isRequired,
    erredEmailingUsers: PropTypes.bool.isRequired,
    emailReceipt: PropTypes.object,
    paginationData: PropTypes.object.isRequired,
    updatingUserRequestStatus: PropTypes.object.isRequired,
    allUserIds: PropTypes.object.isRequired,
    toolbarText: PropTypes.string.isRequired,
    defaultReplyTo: PropTypes.string,
    canEmailUsers: PropTypes.bool.isRequired,
    canExport: PropTypes.bool.isRequired,
    isExporting: PropTypes.bool,
    ...WithRoutingPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      replyToSet: props.defaultReplyTo ? true : false,
      showEmailModal: false,
      showBulkTaggerModal: false,
      showCloneUsersModal: false,
      showConfirmationModal: false,
      showDisableUsersModal: false,
      showExportUsersModal: false,
      submittedEmailForm: false,
      emailError: false,
      allSelected: false,
    };

    this.handleCloneUsers = this.handleCloneUsers.bind(this);
    this.handleCloseCloneUsersModal =
      this.handleCloseCloneUsersModal.bind(this);
    this.handleCloseDisableUsersModal =
      this.handleCloseDisableUsersModal.bind(this);
    this.handleCloseExportUsersModal =
      this.handleCloseExportUsersModal.bind(this);
    this.handleDisableUsers = this.handleDisableUsers.bind(this);
    this.handleExportUsers = this.handleExportUsers.bind(this);
    this.onBulkTaggerClick = this.onBulkTaggerClick.bind(this);
    this.onCloneUsersClick = this.onCloneUsersClick.bind(this);
    this.onDeselectAllUsers = this.onDeselectAllUsers.bind(this);
    this.onDisableUsersClick = this.onDisableUsersClick.bind(this);
    this.onExportUsersClick = this.onExportUsersClick.bind(this);
    this.onSelectAllUsers = this.onSelectAllUsers.bind(this);
    this.renderCloneUsersModal = this.renderCloneUsersModal.bind(this);
    this.renderDisableUsersModal = this.renderDisableUsersModal.bind(this);
    this.renderExportUsersModal = this.renderExportUsersModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { submittedEmailForm, allSelected } = this.state;
    const { emailReceipt, emailQueue } = nextProps;

    if (submittedEmailForm && emailReceipt) {
      this.setState({
        submittedEmailForm: false,
        showEmailModal: false,
        showConfirmationModal: true,
      });
    }

    if (emailQueue.count() === 0 && allSelected) {
      this.setState({ allSelected: false });
    }
  }

  onShowUserDetails(userData) {
    const { navigate } = this.props;

    navigate(`/users/${userData.get('id')}/?view=details`);
  }

  onSaveUserDetails(user, updatedUserDetails) {
    const { dispatch, currentUser } = this.props;
    const { updateUserAsync } = userActionCreators;

    dispatch(
      updateUserAsync(
        user.get('id'),
        updatedUserDetails,
        currentUser.getIn(['userData', 'id'])
      )
    );
  }

  onRemoveUserFromEmailQueue(userId) {
    const { dispatch } = this.props;
    const { removeUserFromQueue } = emailActionCreators;

    this.setState({ allSelected: false });
    dispatch(removeUserFromQueue(userId));
  }

  onAddUserToEmailQueue(userId) {
    const { dispatch, allUserIds, emailQueue } = this.props;
    const { addUserToQueue } = emailActionCreators;

    if (emailQueue.count() + 1 === allUserIds.count()) {
      this.setState({ allSelected: true });
    }
    dispatch(addUserToQueue(userId));
  }

  onBulkTaggerClick(e) {
    e.preventDefault();
    this.setState({ showBulkTaggerModal: true });
  }

  onCloneUsersClick(e) {
    e.preventDefault();
    this.setState({ showCloneUsersModal: true });
  }

  onDisableUsersClick(e) {
    e.preventDefault();
    this.setState({ showDisableUsersModal: true });
  }

  onExportUsersClick(e) {
    e.preventDefault();
    this.setState({ showExportUsersModal: true });
  }

  onSelectAllUsers() {
    const { dispatch, allUserIds } = this.props;
    const { addAllUsersToQueue } = emailActionCreators;

    this.setState({ allSelected: true });
    dispatch(addAllUsersToQueue(allUserIds.toJS()));
  }

  onDeselectAllUsers() {
    const { dispatch } = this.props;
    const { removeAllUsersFromQueue } = emailActionCreators;

    this.setState({ allSelected: false });
    dispatch(removeAllUsersFromQueue());
  }

  onSendEmail(emailPayload) {
    const { dispatch, emailQueue } = this.props;
    const requestPayload = _.assign(emailPayload, {
      user_ids: JSON.stringify(emailQueue.toJS()),
    });
    const { template } = requestPayload;
    const { emailUsersAsync } = emailActionCreators;
    if (!this.state.replyToSet) {
      this.setState({
        emailErrorText: 'Reply-to is a required field.',
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
        submittedEmailForm: true,
      });
      dispatch(emailUsersAsync(template, requestPayload));
    }
  }

  onSetReplyTo(replyToSet) {
    let emailError = true;
    let emailErrorText = 'Reply-to is a required field.';
    if (replyToSet) {
      emailError = false;
      emailErrorText = '';
    }
    this.setState({ replyToSet, emailError, emailErrorText });
  }

  download({ data }) {
    const state = this.props.filters.get('assignment_state')
      ? `${this.props.filters.get('assignment_state')}-`
      : '';
    const url = data.url;
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = `${state}users.csv`;
      link.href = url;
      link.click();
      document.body.removeChild(link);
    } else {
      location.replace(url);
    }
  }

  handleCloseBulkTaggerModal() {
    this.setState({
      showBulkTaggerModal: false,
    });
  }

  handleCloseCloneUsersModal() {
    this.setState({
      showCloneUsersModal: false,
    });
  }

  handleCloseDisableUsersModal(success) {
    const { dispatch } = this.props;
    const { getUserListAsync } = userActionCreators;
    const { removeAllUsersFromQueue } = emailActionCreators;

    if (success) {
      dispatch(removeAllUsersFromQueue());
      dispatch(getUserListAsync());
    }

    this.setState({
      showDisableUsersModal: false,
    });
  }

  handleCloseEmailModal() {
    this.setState({
      showEmailModal: false,
      submittedEmailForm: false,
    });
  }

  handleCloseConfirmationModal() {
    const { dispatch, emailQueue } = this.props;
    const { resetEmailReceipt, removeAllUsersFromQueue } = emailActionCreators;

    this.setState({ showConfirmationModal: false });
    dispatch(removeAllUsersFromQueue(emailQueue.toJS()));
    dispatch(resetEmailReceipt());
  }

  handleCloseExportUsersModal() {
    this.setState({
      showExportUsersModal: false,
    });
  }

  handleCloneUsers(election) {
    const { dispatch, emailQueue } = this.props;
    dispatch(cloneUsers(emailQueue.toJS(), election));
  }

  handleDisableUsers() {
    const { dispatch, emailQueue } = this.props;
    dispatch(disableUsers(emailQueue.toJS()));
  }

  handleExportUsers() {
    const { currentUser, dispatch, filters, isExporting } = this.props;
    const { updateUserAsync } = userActionCreators;
    const { getAssignmentExportAsync, showToast } = assignmentActionCreators;
    const csvFilters = _.assign({}, filters.toJS(), {
      include_assignments: false,
    });

    if (isExporting) {
      return;
    }

    const callback = (assignmentCsvAction) => {
      if (assignmentCsvAction) {
        this.download(assignmentCsvAction);
        this.handleCloseExportUsersModal();
      } else {
        // TODO(fiona): This actually won’t trigger in practice, since
        // getAssignmentExportAsync always resolves to a value.
        const toastData = {
          type: 'error',
          message: `No rows found! Try changing your filters?`,
        };
        dispatch(showToast(toastData));
      }
    };

    dispatch(
      updateUserAsync(
        currentUser.getIn(['userData', 'id']),
        { signed_eula: true },
        currentUser.getIn(['userData', 'id'])
      )
    ).then(() => {
      dispatch(getAssignmentExportAsync(csvFilters)).then(callback);
    });
  }

  handleTagUsers(tags) {
    const { dispatch, emailQueue } = this.props;
    dispatch(bulkTagUsers(emailQueue.toJS(), tags));
  }

  showCloneUsers() {
    const { currentUser } = this.props;
    const userElections = currentUser.getIn(['userData', 'user_elections']);

    return (
      userElections
        .filter((election) => {
          return (
            election.get('role') === 'vpd' ||
            election.get('role') === 'deputy_vpd'
          );
        })
        .count() > 1
    );
  }

  renderBulkTaggerModal() {
    const { emailQueue } = this.props;
    return (
      <BulkTaggerModal
        isVisible={this.state.showBulkTaggerModal}
        onRequestClose={this.handleCloseBulkTaggerModal.bind(this)}
        handleTagUsers={this.handleTagUsers.bind(this)}
        userCount={emailQueue.count()}
      />
    );
  }

  renderCloneUsersModal() {
    const { emailQueue } = this.props;
    return (
      <CloneUsersModal
        isVisible={this.state.showCloneUsersModal}
        onRequestClose={this.handleCloseCloneUsersModal}
        handleCloneUsers={this.handleCloneUsers}
        userCount={emailQueue.count()}
      />
    );
  }

  renderDisableUsersModal() {
    const { currentUser, emailQueue } = this.props;
    const currentUserId = currentUser.getIn(['userData', 'id']);

    return (
      <DisableUsersModal
        isVisible={this.state.showDisableUsersModal}
        onRequestClose={this.handleCloseDisableUsersModal}
        handleDisableUsers={this.handleDisableUsers}
        userCount={emailQueue.count()}
        currentUserInQueue={emailQueue.includes(currentUserId)}
      />
    );
  }

  renderExportUsersModal() {
    const { isExporting } = this.props;

    return (
      <ExportModal
        isPending={isExporting}
        isVisible={this.state.showExportUsersModal}
        onAccept={this.handleExportUsers}
        onRequestClose={this.handleCloseExportUsersModal}
        type={'users'}
      />
    );
  }

  renderSendEmailModal() {
    const {
      defaultReplyTo,
      emailQueue,
      isEmailingUsers,
      erredEmailingUsers,
      filters,
    } = this.props;
    const dropdownProps = {};
    let descriptionText, headerText, errorText, template;

    switch (filters.get('view')) {
      case ASSIGNMENT_NOTIFY:
        headerText = 'Email Users';
        descriptionText = (
          <span>
            Each user will receive a customized email. If you choose to send
            assignments, they will also receive their{' '}
            <strong>assignment date</strong>, <strong>time</strong>,{' '}
            <strong>precinct</strong>, and <strong>location name</strong>
          </span>
        );
        errorText =
          this.state.emailErrorText || 'There was an error sending emails.';
        template = EMAIL_TEMPLATES.broadcast;
        break;
    }

    return (
      <SendEmailModal
        userCount={emailQueue.count()}
        onClose={this.handleCloseEmailModal.bind(this)}
        onSendEmail={this.onSendEmail.bind(this)}
        onSetReplyTo={this.onSetReplyTo.bind(this)}
        isEmailingUsers={isEmailingUsers}
        erredEmailingUsers={erredEmailingUsers || this.state.emailError}
        descriptionText={descriptionText}
        headerText={headerText}
        errorText={errorText}
        replyToFilters={{
          role: 'vpd,deputy_vpd',
          size: 1000,
        }}
        template={template}
        defaultReplyTo={defaultReplyTo}
        {...dropdownProps}
      />
    );
  }

  renderEmailConfirmationModal() {
    const { emailQueue, emailReceipt } = this.props;
    const mailSize = emailQueue.count();
    let confirmationMsg, headerText;

    confirmationMsg = (
      <p>
        You successfully emailed{' '}
        <strong>
          {mailSize} {mailSize > 1 ? `users` : `user`}
        </strong>
        .
      </p>
    );
    headerText = 'Emails sent';

    return (
      <EmailConfirmationModal
        replyTo={emailReceipt.get('reply_to')}
        confirmationMsg={confirmationMsg}
        onClose={this.handleCloseConfirmationModal.bind(this)}
        headerText={headerText}
      />
    );
  }

  renderEmptyState() {
    let headerText, descriptionText;

    headerText = 'There are no users to show.';
    descriptionText = 'Update the filters on the left to check for more.';

    return <EmptyAlert header={headerText} description={descriptionText} />;
  }

  render() {
    const {
      toolbarText,
      totalUserCount,
      userListData,
      emailQueue,
      isFetchingUserList,
      allUserIds,
      canEmailUsers,
      canExport,
    } = this.props;
    const {
      showBulkTaggerModal,
      showCloneUsersModal,
      showConfirmationModal,
      showDisableUsersModal,
      showEmailModal,
      showExportUsersModal,
      allSelected,
    } = this.state;
    const listIsEmpty = !isFetchingUserList && userListData.isEmpty();
    const userCount = totalUserCount ? totalUserCount.get('count') : 0;
    return (
      <div>
        {RIT(isFetchingUserList, () => (
          <LoadingOverlay />
        ))}
        {RIT(allUserIds, () => (
          <EmailNotificationToolbar
            queueCount={emailQueue.count()}
            onSelectAll={this.onSelectAllUsers}
            onDeselectAll={this.onDeselectAllUsers}
            onBulkTaggerClick={this.onBulkTaggerClick}
            onCloneUsersClick={this.onCloneUsersClick}
            onDisableUsersClick={this.onDisableUsersClick}
            onExportUsersClick={this.onExportUsersClick}
            onEmailClick={() => this.setState({ showEmailModal: true })}
            CTAText={toolbarText}
            mailableUserCount={allUserIds.count()}
            showCloneUsers={this.showCloneUsers()}
            showEmailUsers={canEmailUsers}
            showExportUsers={canExport}
            disabled={allUserIds.count() === 0}
            allSelected={allSelected}
          />
        ))}
        {RIT(listIsEmpty, () => {
          this.renderEmptyState();
        })}
        {RIT(!listIsEmpty, () => {
          return (
            <div className="lbj-table-wrapper">
              <UserTable
                onRemoveUserFromEmailQueue={this.onRemoveUserFromEmailQueue.bind(
                  this
                )}
                onAddUserToEmailQueue={this.onAddUserToEmailQueue.bind(this)}
                onShowUserDetails={this.onShowUserDetails.bind(this)}
                userCount={userCount}
                userList={userListData}
                emailQueue={emailQueue}
                showCheckboxes
              />
            </div>
          );
        })}
        {RIT(showBulkTaggerModal, this.renderBulkTaggerModal.bind(this))}
        {RIT(showCloneUsersModal, this.renderCloneUsersModal)}
        {RIT(showDisableUsersModal, this.renderDisableUsersModal)}
        {RIT(showEmailModal, this.renderSendEmailModal.bind(this))}
        {RIT(
          showConfirmationModal,
          this.renderEmailConfirmationModal.bind(this)
        )}
        {RIT(showExportUsersModal, this.renderExportUsersModal)}
      </div>
    );
  }
}
