import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class AssignmentDetailSummary extends Component {
  static propTypes = {
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    shiftDate: PropTypes.string.isRequired,
    placeStatus: PropTypes.string,
    notes: PropTypes.string,
    assignmentType: PropTypes.string.isRequired,
    specifiedPrecinct: PropTypes.string,
  };

  getAssignmentTypeLabel() {
    switch (this.props.assignmentType) {
      case 'poll':
        return 'Polling Place Observer';

      case 'boiler_room':
        return 'Boiler Room Worker';

      case 'board_of_elections':
        return 'BOE Observer';

      case 'hotline_center':
        return 'Hotline Center Worker';

      case 'hotline_manager':
        return 'Hotline Center Manager';
    }
  }

  render() {
    const {
      startTime,
      endTime,
      shiftDate,
      placeStatus,
      specifiedPrecinct,
      notes,
    } = this.props;
    const startTimeDisplay = moment(`${shiftDate}T${startTime}`).format('LT');
    const endTimeDisplay = moment(`${shiftDate}T${endTime}`).format('LT');

    return (
      <div>
        <div className="lbj-column-label lbj-column-divider">
          <h4 className="assignment-details-title-text">Assignment Details</h4>
        </div>

        <div className="lbj-column-content">
          <ul className="lbj-detail-list">
            <li>
              <strong>Shift Date: </strong>
              {moment(shiftDate).format('MMM, Do')}
            </li>
            <li>
              <strong>Start Time: </strong>
              {startTimeDisplay}
            </li>
            <li>
              <strong>End Time: </strong>
              {endTimeDisplay}
            </li>
            <li>
              <strong>Type: </strong>
              {this.getAssignmentTypeLabel()}
            </li>

            {RIT(placeStatus, () => {
              return (
                <li>
                  <strong>Where: </strong>Poll observer will be {placeStatus}
                </li>
              );
            })}

            {RIT(specifiedPrecinct, () => {
              return (
                <li>
                  <strong>Precinct: </strong>
                  {specifiedPrecinct}
                </li>
              );
            })}

            {RIT(notes, () => {
              return (
                <li>
                  <strong>Notes: </strong>
                  {notes}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
