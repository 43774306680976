import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { states, EDAY_ROLES, EVOTE_ROLES } from '../../../constants';
import RIT from '../../../utils/render-if-truthy';
import Checkbox from '../form/checkbox';

export default class UserTable extends Component {
  static propTypes = {
    emailQueue: PropTypes.object.isRequired,
    userCount: PropTypes.number.isRequired,
    userList: PropTypes.object.isRequired,
    onShowUserDetails: PropTypes.func.isRequired,
    onRemoveUserFromEmailQueue: PropTypes.func.isRequired,
    onAddUserToEmailQueue: PropTypes.func.isRequired,
    showCheckboxes: PropTypes.bool.isRequired,
  };

  onCheckboxChange(user, e) {
    const { onRemoveUserFromEmailQueue, onAddUserToEmailQueue } = this.props;
    const userId = user.get('id');

    if (e.target.checked) {
      onAddUserToEmailQueue(userId);
    } else {
      onRemoveUserFromEmailQueue(userId);
    }

    return false;
  }

  getSentTimeStamp(user, template) {
    const receivedEmails = user.get('emails_received');

    if (receivedEmails) {
      const lastInvited = receivedEmails
        .filter((email) => email.get('template') === template)
        .getIn([0, 'sent_at']);

      return lastInvited ? moment(lastInvited).format('MMMM DD, YYYY') : '';
    }

    return '';
  }

  getLastInvitedTimestamp(user) {
    return this.getSentTimeStamp(user, 'lbj.invitation');
  }

  getLastNotifiedTimestamp(user) {
    return this.getSentTimeStamp(user, 'lbj.assignment');
  }

  getUserRoleFromTags(user) {
    const predicate = (role) => _.includes(user.get('tags').toJS(), role);
    const edayRoles = _.filter(Object.keys(EDAY_ROLES), predicate)
      .map((role) => EDAY_ROLES[role])
      .join(', ');
    const evoteRoles = _.filter(Object.keys(EVOTE_ROLES), predicate)
      .map((role) => EVOTE_ROLES[role])
      .join(', ');
    const edayRoleString = edayRoles ? `ED: ${edayRoles}` : '';
    const evoteRoleString = evoteRoles ? `EV: ${evoteRoles}` : '';

    if (edayRoleString && evoteRoleString) {
      return `${edayRoleString}, ${evoteRoleString}`;
    }

    return `${edayRoleString} ${evoteRoleString}`.trim();
  }

  renderCheckBox(user) {
    const { emailQueue } = this.props;

    return (
      <Checkbox
        disabled={!user.get('email')}
        name="notification_queue"
        checked={emailQueue.includes(user.get('id'))}
        onChange={this.onCheckboxChange.bind(this, user)}
      />
    );
  }

  renderUserRow(user, key) {
    const { showCheckboxes, onShowUserDetails } = this.props;
    const onClick = () => onShowUserDetails(user);
    const userEmail = user.get('email') || (
      <span className="user-missing-data">Add an email</span>
    );
    const userRole = this.getUserRoleFromTags(user);
    const userPhone = user.get('phone_number') || (
      <span className="user-missing-data">Add phone number</span>
    );
    const userNameClassName = cx('user-name-table-data', {
      'has-accepted-invite': user.get('invitation_status') === 'accepted',
    });
    const lastSeen = moment(user.get('last_logged_in'));

    return (
      <tr key={key}>
        <td>
          {RIT(showCheckboxes, this.renderCheckBox.bind(this, user))}

          <span className={userNameClassName} onClick={onClick}>
            {`${user.get('last_name')}, ${user.get('first_name')}`}
          </span>
        </td>
        <td onClick={onClick}>{userEmail}</td>
        <td onClick={onClick}>{userPhone}</td>
        <td onClick={onClick}>{states[user.get('state')]}</td>
        <td onClick={onClick}>{user.get('county_name')}</td>
        <td onClick={onClick}>{userRole}</td>
        <td onClick={onClick}>
          {lastSeen.isValid() ? lastSeen.fromNow() : 'Never'}
        </td>
      </tr>
    );
  }

  render() {
    const { userList, userCount } = this.props;

    return (
      <table className="lbj-table user-table">
        <thead>
          <tr>
            <th>Users ({userCount})</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Registered State</th>
            <th>Registered County</th>
            <th>Permission</th>
            <th>Last Seen</th>
          </tr>
        </thead>

        <tbody>
          {userList.map((user, key) => this.renderUserRow(user, key))}
        </tbody>
      </table>
    );
  }
}
