import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToNotificationsFilters from '../../../utils/notifications/map-state-to-notifications-filters';
import RIT from '../../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import { PageHeader } from '../../common';
import EmptyAlert from '../../presentational/lbj/empty-alert';
import LoadingOverlay from '../../presentational/lbj/loading';

import NotificationItem from './notification-item';
import NotificationsFilterSidebar from './notifications-filter-sidebar';

// TODO: this needs to support pagination!

@connect((state) => {
  const { notifications } = state;
  const locationChangeList = notifications.getIn(['location', 'changeList']);

  return {
    filters: mapStateToNotificationsFilters(state),
    locationChanges: locationChangeList.get('listData'),
    isFetchingLocationChanges: locationChangeList.get('requestIsPending'),
    locationChangeRequestErred: locationChangeList.get('requestErred'),
  };
})
@withRouting
export default class NotificationsIndex extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    locationChanges: PropTypes.object.isRequired,
    isFetchingLocationChanges: PropTypes.bool.isRequired,
    locationChangeRequestErred: PropTypes.bool.isRequired,
    ...WithRoutingPropTypes,
  };

  onFilterChange(event) {
    const { name, value } = event.target;
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = searchToQuery(search);
    const toBeOmmitted =
      name === 'state' ? ['size', 'offset', 'county'] : ['size', 'offset'];
    const newQueryParams = _.omit(query, toBeOmmitted);

    if (value) {
      newQueryParams[name] = value;
    } else {
      delete newQueryParams[name];
    }

    navigate({ pathname, search: queryToSearch(newQueryParams) });
  }

  groupChanges(locationChanges) {
    // Merge together changes of the same event type, precinct, and ack status
    // into a single group. If the change is for a location, merge together
    // by location instead of precinct
    return locationChanges.groupBy((change) => {
      const isAcked = _.isNull(change.get('acked')).toString();
      if (change.get('event') === 'location_changed') {
        return [
          change.get('event'),
          change.getIn(['location', 'id']).toString(),
          isAcked,
        ].join('.');
      }
      return [
        change.get('event'),
        change.getIn(['precinct', 'id']).toString(),
        isAcked,
      ].join('.');
    });
  }

  renderSpacer(key) {
    return (
      <div className="notification-spacer" key={'spacer-' + key}>
        &nbsp;
      </div>
    );
  }

  renderEmpty() {
    return (
      <EmptyAlert
        header="Nothing to see here."
        description={
          'Looks like you’re up to date. The notification icon in the top ' +
          'right will turn red when there are issues that need your attention.'
        }
      />
    );
  }

  render() {
    const { isFetchingLocationChanges, locationChanges, filters } = this.props;

    const state = filters.get('state');

    const groupedChanges = this.groupChanges(locationChanges);

    return (
      <div className="lbj-page-wrapper">
        <PageHeader title="Notifications" />

        <div className="lbj-page-columns">
          <NotificationsFilterSidebar
            onFilterChange={this.onFilterChange.bind(this)}
          />

          <div className="lbj-main">
            <div className="lbj-column-content-wrapper">
              <div className="lbj-column-content notification-background">
                {RIT(isFetchingLocationChanges, () => (
                  <LoadingOverlay />
                ))}
                {groupedChanges.toIndexedSeq().map((changes, idx) => {
                  return (
                    <div key={'container-' + idx}>
                      {RIT(idx !== 0, () => this.renderSpacer(idx))}
                      <NotificationItem
                        key={'notification-' + idx}
                        changes={changes}
                        state={state}
                      />
                    </div>
                  );
                })}
              </div>
              {RIT(groupedChanges.size === 0, () => this.renderEmpty())}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
