import { useObjectRef } from '@react-aria/utils';
import React from 'react';
import {
  AriaNumberFieldProps,
  mergeProps,
  useLocale,
  useNumberField,
} from 'react-aria';
import { useNumberFieldState } from 'react-stately';

/**
 * Input field that’s specific to numbers.
 */
const ForwardedNumberField = React.forwardRef(function NumberField(
  {
    inputClassName,
    labelClassName,
    selectOnFocus = false,
    ...props
  }: AriaNumberFieldProps & {
    selectOnFocus?: boolean;
    inputClassName?: string;
    labelClassName?: string;
  },
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const inputRef = useObjectRef(forwardedRef);
  const { labelProps, groupProps, inputProps } = useNumberField(
    props,
    state,
    inputRef
  );

  return (
    <div>
      <label {...labelProps} className={labelClassName}>
        {props.label}
      </label>
      <div {...groupProps} className="flex">
        <input
          {...mergeProps(inputProps, {
            onFocus: selectOnFocus
              ? () => {
                  inputRef?.current?.select();
                }
              : null,
          })}
          ref={inputRef}
          className={`h-[40px] rounded border-2 border-gray-300 shadow-none ${inputClassName}`}
        />
      </div>
    </div>
  );
});

export { ForwardedNumberField as NumberField };
