import { lbjAppSections } from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import { AppState } from '../../modules/flux-store';
import { filterFilters } from '../filters/common';

import { ANALYTICS_VIEW_PARAMS } from './query-params';

const { ANALYTICS } = lbjAppSections;

function filterAnalyticsFilters<
  OutK extends keyof FiltersBySection['ANALYTICS']
>(state: AppState, keys: OutK[]) {
  return filterFilters(state.filters.ANALYTICS, keys);
}

function mapStateToIssuesByCategoryFilters(state: AppState) {
  return filterAnalyticsFilters(state, [
    'group_by',
    'ordering',
    'date_lte',
    'date_gte',
    'state',
    'county',
    'precinct',
    'category',
    'subcategory',
    'view',
    'query_election_id',
  ]);
}

function mapStateToIssuesByRegionFilters(state: AppState) {
  return filterAnalyticsFilters(state, [
    'ordering',
    'date_lte',
    'date_gte',
    'state',
    'view',
    'query_election_id',
    // We have to include group_by here so we can overwrite it below.
    'group_by',
  ]).set('group_by', 'region');
}

function mapStateToIssueTablesFilters(state: AppState) {
  let outFilters = filterAnalyticsFilters(state, [
    'ordering',
    'date_lte',
    'date_gte',
    'state',
    'column',
    'view',
    'query_election_id',
    // We have to include group_by here so we can overwrite it below.
    'group_by',
  ]);

  if (!outFilters.has('column')) {
    outFilters = outFilters.set('column', 'status');
  }

  return outFilters.set('group_by', 'region');
}

/**
 * Filters the map of current “Analytics” filters down to those that are
 * relevant for the current Analytics “view.”
 */
export default function mapStateToAnalyticsFilters(state: AppState) {
  const filters = state.filters.get(ANALYTICS);

  switch (filters.get('view')) {
    case ANALYTICS_VIEW_PARAMS.issues_by_region:
      return mapStateToIssuesByRegionFilters(state);

    case ANALYTICS_VIEW_PARAMS.issue_tables:
      return mapStateToIssueTablesFilters(state);

    case ANALYTICS_VIEW_PARAMS.issues_by_category:
    default:
      return mapStateToIssuesByCategoryFilters(state);
  }
}
