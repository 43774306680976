import React from 'react';

type ActiveFilterLabel = {
  key: string;
  label: string;
};

/**
 * Sets up an object of label functions.
 *
 * For each key in the original object, can optionally provide a function that
 * will take the non-nullable version of that object and make a label out of it.
 *
 * @see makeActiveFilterLabels
 */
export type FilterLabelsFor<T extends { [key: string]: unknown }> = {
  [K in keyof T]?: (val: NonNullable<T[K]>) => string | null;
};

/**
 * Takes an object of filters and a {@link FilterLabelsFor} definition and
 * applies each label function in the definition to its matching filter value.
 *
 * If the filter’s value is null/undefined/""/false it doesn’t even call the
 * label definition. If the label function doesn’t return a truthy string it
 * also doesn’t include that label.
 *
 * Returns an array of the results.
 */
function makeActiveFilterLabels<T extends { [key: string]: unknown }>(
  filters: T,
  labels: FilterLabelsFor<T>
): ActiveFilterLabel[] {
  const activeFilterLabels: ActiveFilterLabel[] = [];

  for (const key in labels) {
    const filterKey = key as keyof T;
    const filterVal = filters[filterKey];

    if (filterVal || filterVal === 0) {
      // "any" is necessary here because we don’t have the associations among
      // the key, value type, and label function that the parameter types
      // enforce.
      const label = (labels[filterKey] as any)(filterVal);

      if (label) {
        activeFilterLabels.push({
          key: key,
          label: label,
        });
      }
    }
  }

  return activeFilterLabels;
}

/**
 * Slightly generic component to make a section of the page titled “Filters
 * Selected” with little labels for each of the active filters.
 *
 * Applies the given filters to the labels definitions to generate the UI.
 *
 * Renders nothing if there are no current filters.
 */
export function ActiveFilters<T extends { [key: string]: unknown }>({
  onClearAll,
  onClearFilter,
  filters,
  labels,
}: {
  onClearAll: () => void;
  onClearFilter: (key: keyof T) => void;
  filters: T;
  labels: FilterLabelsFor<T>;
}): React.ReactElement<any, any> | null {
  const activeFilterLabels = makeActiveFilterLabels(filters, labels);

  if (activeFilterLabels.length === 0) {
    return null;
  }

  return (
    <>
      <h4 className="font-bold">Filters Selected</h4>

      <div className="issue-filter-buttons">
        {activeFilterLabels.map(({ key, label }) => (
          <div
            className="issue-filter-remove-button cursor-pointer"
            key={key}
            onClick={() => onClearFilter(key)}
          >
            <span className="mr-2 text-base">&times;</span>

            {label}
          </div>
        ))}

        <div className="issue-clear-filters">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onClearAll();
            }}
          >
            Clear all filters
          </a>
        </div>
      </div>
    </>
  );
}

export default ActiveFilters;
