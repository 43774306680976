import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import Checkbox from '../form/checkbox';

export default class EmailNotificationToolbar extends Component {
  static propTypes = {
    queueCount: PropTypes.number.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onDeselectAll: PropTypes.func.isRequired,
    onBulkTaggerClick: PropTypes.func.isRequired,
    onCloneUsersClick: PropTypes.func.isRequired,
    onDisableUsersClick: PropTypes.func.isRequired,
    onEmailClick: PropTypes.func.isRequired,
    onExportUsersClick: PropTypes.func.isRequired,
    CTAText: PropTypes.string.isRequired,
    mailableUserCount: PropTypes.number.isRequired,
    showCloneUsers: PropTypes.bool.isRequired,
    showEmailUsers: PropTypes.bool.isRequired,
    showExportUsers: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    allSelected: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.onDeselectUsers = this.onDeselectUsers.bind(this);
  }

  onCheckboxChange(e) {
    const { onSelectAll, onDeselectAll } = this.props;
    const { checked } = e.target;

    if (checked) {
      onSelectAll();
    } else {
      onDeselectAll();
    }
  }

  onDeselectUsers() {
    const { onDeselectAll } = this.props;

    onDeselectAll();
  }

  onEmailClick(e) {
    e.preventDefault();
    this.props.onEmailClick();
  }

  renderQueueCountText() {
    const { allSelected, queueCount } = this.props;

    if (allSelected) {
      return 'Deselect all users';
    }

    return (
      <span>
        Deselect <span className="email-queue-count">{queueCount}</span> users
      </span>
    );
  }

  render() {
    const {
      allSelected,
      queueCount,
      CTAText,
      disabled,
      mailableUserCount,
      showCloneUsers,
      showEmailUsers,
      showExportUsers,
    } = this.props;
    const queueHasUsers = queueCount > 0;
    const containerClassName = cx(
      'lbj-column-label email-notification-toolbar',
      {
        'has-users-queued': queueHasUsers,
      }
    );
    const selectAllLabelClassName = cx({
      'is-disabled': disabled,
    });

    return (
      <div className={containerClassName}>
        <div className="cta-button-wrapper">
          {RIT(queueHasUsers, () => {
            return (
              <a
                className="email-queue-count-wrapper"
                onClick={this.onDeselectUsers}
              >
                {this.renderQueueCountText()}
              </a>
            );
          })}

          {RIT(showEmailUsers, () => {
            return (
              <button
                className="cta-button lbj-button-purple"
                onClick={this.onEmailClick.bind(this)}
                disabled={!queueHasUsers}
              >
                {CTAText}
              </button>
            );
          })}

          <button
            className="cta-button lbj-button-green"
            onClick={this.props.onBulkTaggerClick}
            disabled={!queueHasUsers}
          >
            Tag Users
          </button>

          {RIT(showCloneUsers, () => {
            return (
              <button
                className="cta-button lbj-button-blue"
                onClick={this.props.onCloneUsersClick}
                disabled={!queueHasUsers}
              >
                Add Users to Another Election
              </button>
            );
          })}

          {RIT(showCloneUsers, () => {
            return (
              <button
                className="cta-button lbj-button-red"
                onClick={this.props.onDisableUsersClick}
                disabled={!queueHasUsers}
              >
                Disable Users
              </button>
            );
          })}

          {RIT(showExportUsers, () => {
            return (
              <button
                className="cta-button lbj-button-purple"
                onClick={this.props.onExportUsersClick}
              >
                Download User Data
              </button>
            );
          })}
        </div>

        <div>
          <Checkbox
            name="invite_queue"
            onChange={this.onCheckboxChange.bind(this)}
            checked={allSelected}
            disabled={disabled}
          />
          <h4 className={`${selectAllLabelClassName} font-bold`}>
            Select {mailableUserCount} users
          </h4>
        </div>
      </div>
    );
  }
}
