import _ from 'lodash';

import { AssignmentViewType, assignmentViewTypes } from '../constants';
import { UserMappedAssignment } from '../utils/assignment/map-user-to-assignments';
import { DeepMerge, DeepMerge2 } from '../utils/types';

import ApiClient from './api-client';
import { ApiCheckin } from './checkin-service';
import { DateString, OffsetResponse, TimeString } from './common';
import {
  ApiBoardOfElections,
  ApiBoilerRoom,
  ApiLocation,
  ApiPrecinct,
} from './lbj-shared-service';
import {
  ApiUser,
  ExpandApiUserPii,
  WithApiUserCurrentBoilerRooms,
} from './user-service';

const {
  PRECINCTS,
  LOCATIONS,
  PEOPLE,
  BOILER_ROOMS,
  HOTLINES,
  BOARDS_OF_ELECTIONS,
} = assignmentViewTypes;

/**
 * Assignment to a polling location.
 */
export type ApiPollAssignment = {
  type: 'poll';

  id: number;
  /** User ID */
  user: number;
  /** Location ID */
  location: number | null;

  shift_date: DateString;
  start_time: TimeString;
  end_time: TimeString;

  // This appears in getCurrentUser.
  precinct?: null | number;
};

/**
 * Assignment to a boiler room or hotline center.
 */
export type ApiBoilerRoomAssignment = {
  type: 'boiler_room' | 'hotline_center';

  id: number;
  /** User ID */
  user: number;

  /** boiler room ID */
  boiler_room: number;

  shift_date: DateString;
  start_time: TimeString;
  end_time: TimeString;
};

/**
 * Assignment to the Board of Elections.
 */
export type ApiBoardOfElectionsAssignment = {
  type: 'board_of_elections';

  id: number;
  /** User ID */
  user: number;

  /** board of elections ID */
  board_of_elections: number;

  shift_date: DateString;
  start_time: TimeString;
  end_time: TimeString;
};

/**
 * Discriminated union of assignment types.
 */
export type ApiAssignment =
  | ApiPollAssignment
  | ApiBoilerRoomAssignment
  | ApiBoardOfElectionsAssignment;

export type ApiAssignmentType = ApiAssignment['type'];

export type ApiPollAssignmentWithRelatedFields = {
  assignment: ApiPollAssignment & WithApiAssignmentExtras;
  // These appear in getCurrentUser.
  precinct?: ApiPrecinct | null;
  location?: ApiLocation | null;
};

export type ApiBoardOfElectionsAssignmentWithRelatedFields = {
  assignment: ApiBoardOfElectionsAssignment & WithApiAssignmentExtras;
  // This appears in getCurrentUser.
  board_of_elections?: ApiBoardOfElections;
};

export type ApiBoilerRoomAssignmentWithRelatedFields = {
  assignment: ApiBoilerRoomAssignment & WithApiAssignmentExtras;
  boiler_room: ApiBoilerRoom;
};

/**
 * Sometimes assignments are returned with related values.
 */
export type ApiAssignmentWithRelatedFields =
  | ApiPollAssignmentWithRelatedFields
  | ApiBoardOfElectionsAssignmentWithRelatedFields
  | ApiBoilerRoomAssignmentWithRelatedFields;

export type ApiAssignmentPlaceStatus = 'inside' | 'outside';

/**
 * These seem to come up in the lists of assignments, even when they’re not part
 * of the assignment creation payloads.
 */
export type WithApiAssignmentExtras = {
  place_status: ApiAssignmentPlaceStatus;
  rover: boolean;
  notes: null;
};

export type WithApiAssignmentCheckinSet = {
  checkin_set: ApiCheckin[];
};

/**
 * Added to {@link ApiPrecinct} when `expand=congressional_districts`.
 */
export type ExpandApiPrecinctCongressionalDistricts = {
  us_house_districts: [];
  state_house_districts: [];
  state_senate_districts: [];
};

/**
 * Added to {@link ApiPrecinct} when `expand=related.assignment_set.user.pii`.
 */
export type ExpandApiPrecinctRelatedAssignmentSetUserPii = {
  related: {
    location: ApiLocation;
    // These assignments have the user ID from the normal API overwritten with the
    // expanded user.
    assignments: DeepMerge2<
      ApiPollAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser & ExpandApiUserPii;
        precinct: number | null;
      }
    >[];
  }[];
};

/**
 * Added to {@link ApiPrecinct} when `expand=related.assignment_set.checkin_set`.
 */
export type ExpandApiPrecinctRelatedAssignmentSetCheckinSet = {
  related: {
    location: ApiLocation;
    // These assignments have the user ID from the normal API overwritten with the
    // expanded user.
    assignments: DeepMerge2<
      ApiPollAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser;
        precinct: number | null;
        checkin_set: ApiCheckin[];
      }
    >[];
  }[];
};

/**
 * Record of hours and coverage for an {@link ApiLocation}.
 */
export type ApiLocationHours = {
  date: DateString;
  open_time: TimeString;
  close_time: TimeString;
  shift_change_time: TimeString | null;
  closed: boolean;
  coverage: 'full' | 'full_rover' | 'partial' | 'none';
  tracks_election_default: 'none' | 'ev' | 'eday';
  am_shift_count: number | null;
  pm_shift_count: number | null;
};

/**
 * VotingHour updates sent to LBJ Backend
 * Updateable fields:
 * - open/change/close times
 * - tracks election default
 * - closed
 *
 * Includes locationId to identify which
 * location the VotingHours update belongs to
 */
export type ApiLocationHourUpdates = Pick<
  ApiLocationHours,
  'date' | 'closed' | 'tracks_election_default'
> & {
  location_id: number;
  open_time?: TimeString;
  close_time?: TimeString;
  shift_change_time?: TimeString | null;
  am_shift_count?: number | null;
  pm_shift_count?: number | null;
};

/**
 * Added to {@link ApiLocation} when `expand=hours`.
 */
export type ExpandApiLocationHours = {
  hours: ApiLocationHours[];
};

/**
 * Added to {@link ApiLocation} when `expand=all_precincts`.
 */
export type ExpandApiAllPrecincts = {
  all_precincts: { [id: string]: ApiPrecinct };
};

/**
 * Added to {@link ApiLocation} when `expand=all_precincts_ids`.
 */
export type ExpandApiAllPrecinctsIds = {
  all_precincts_ids: number[];
};

/**
 * Added to {@link ApiLocation} when `expand=assignment_set`.
 */
export type ExpandApiLocationAssignmentSet = {
  assignment_set: {
    [id: string]: ApiPollAssignment & WithApiAssignmentExtras;
  };
};

/**
 * Added to {@link ApiLocation} when `expand=assignment_set.precinct`.
 */
export type ExpandApiLocationAssignmentSetPrecinct = {
  assignment_set: {
    [id: string]: ApiPollAssignment &
      WithApiAssignmentExtras & {
        precinct: null;
      };
  };
};

/**
 * Added to {@link ApiLocation} when `expand=assignment_set.user.pii`.
 */
export type ExpandApiLocationAssignmentSetUserPii = {
  assignment_set: {
    [id: string]: DeepMerge2<
      ApiPollAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser & ExpandApiUserPii;
        precinct: null;
      }
    >;
  };
};

/**
 * Added to {@link ApiLocation} when `expand=assignment_set.checkin_set`.
 */
export type ExpandApiLocationAssignmentSetCheckinSet = {
  assignment_set: {
    [id: string]: ApiPollAssignment &
      WithApiAssignmentExtras & {
        precinct: null;
        checkin_set: ApiCheckin[];
      };
  };
};

/**
 * Added to {@link ApiUser} when `expand=related.checkin_set`.
 */
export type ExpandApiUserRelatedCheckinSet = {
  related: DeepMerge<
    [
      ApiAssignmentWithRelatedFields,
      { assignment: { checkin_set: ApiCheckin[] } }
    ]
  >[];
};

/**
 * Added to {@link ApiBoilerRoom} when `related.assignment_set.checkin_set`
 */
export type ExpandApiBoilerRoomRelatedAssignmentSetCheckinSet = {
  related: {
    assignment: DeepMerge2<
      ApiBoilerRoomAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser;
        checkin_set: ApiCheckin[];
      }
    >;
  }[];
};

/**
 * Added to {@link ApiBoilerRoom} when `expand=related.assignment_set.user.pii`
 */
export type ExpandApiBoilerRoomRelatedAssignmentSetUserPii = {
  related: {
    assignment: DeepMerge2<
      ApiBoilerRoomAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser & ExpandApiUserPii;
      }
    >;
  }[];
};

/**
 * Added to {@link ApiBoardOfElections} when `related.assignment_set.checkin_set`
 */
export type ExpandApiBoardOfElectionsRelatedAssignmentSetCheckinSet = {
  related: {
    assignment: DeepMerge2<
      ApiBoardOfElectionsAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser;
        checkin_set: ApiCheckin[];
      }
    >;
  }[];
};

/**
 * Added to {@link ApiBoardOfElections} when `expand=related.assignment_set.user.pii`
 */
export type ExpandApiBoardOfElectionsRelatedAssignmentSetUserPii = {
  related: {
    assignment: DeepMerge2<
      ApiBoardOfElectionsAssignment & WithApiAssignmentExtras,
      {
        user: ApiUser & ExpandApiUserPii;
      }
    >;
  }[];
};

function getExpandParam(viewContext: AssignmentViewType) {
  switch (viewContext) {
    case PEOPLE:
      return 'related.checkin_set,pii';

    case LOCATIONS:
      return 'assignment_set.precinct,assignment_set.user.pii,hours,assignment_set.checkin_set';

    case PRECINCTS:
      return 'related.assignment_set.user.pii,related.assignment_set.checkin_set,congressional_districts';

    case BOILER_ROOMS:
    case HOTLINES:
    case BOARDS_OF_ELECTIONS:
      return 'related.assignment_set.user.pii,related.assignment_set.checkin_set';
  }
}

export type AssignmentContext = {
  listViewType: AssignmentViewType;
  resourceId: number;
};

export function getPathBaseForViewContextType({
  listViewType,
  resourceId,
}: AssignmentContext) {
  switch (listViewType) {
    case PRECINCTS:
      return `precinct/${resourceId}/`;

    case LOCATIONS:
      return `location/${resourceId}/`;

    case PEOPLE:
      return `user/${resourceId}/`;

    case BOILER_ROOMS:
    case HOTLINES:
      return `boiler_room/${resourceId}/`;

    case BOARDS_OF_ELECTIONS:
      return `board_of_elections/${resourceId}/`;
  }
}

export type ApiPrecinctWithAssignments = DeepMerge<
  [
    ApiPrecinct,
    ExpandApiPrecinctCongressionalDistricts,
    ExpandApiPrecinctRelatedAssignmentSetUserPii,
    ExpandApiPrecinctRelatedAssignmentSetCheckinSet
  ]
>;

/**
 * Returns a list of precincts with their assignments expanded.
 */
export function getPrecinctsWithAssignments(query = {}) {
  const params = _.assign(
    { expand: getExpandParam(PRECINCTS), size: 50 },
    query
  );

  return ApiClient<OffsetResponse<'precincts', ApiPrecinctWithAssignments>>(
    'precincts/',
    'GET',
    params
  );
}

export type ApiLocationWithAssignments = DeepMerge<
  [
    ApiLocation,
    ExpandApiLocationHours,
    ExpandApiLocationAssignmentSetPrecinct,
    ExpandApiLocationAssignmentSetCheckinSet,
    // Need this so that "user.pii" can overwrite the "user" field from ID to full object.
    ExpandApiLocationAssignmentSetUserPii
  ]
>;

export type ApiLocationWithHoursAndPrecincts = ApiLocation &
  ExpandApiLocationHours &
  ExpandApiAllPrecincts;

/**
 * Returns a list of locations with their assignments expanded.
 */
export function getLocationsWithAssignments(query = {}) {
  const params = _.assign(
    { expand: getExpandParam(LOCATIONS), size: 50 },
    query
  );

  return ApiClient<OffsetResponse<'locations', ApiLocationWithAssignments>>(
    'locations/',
    'GET',
    params
  );
}

/**
 * Returns a list of locations with their hours and assignments expanded, but
 * does not expand the users within those assignments or the checkins.
 *
 * Also does not expand precincts, as this can be disasterous for performance
 * (LBJ-793). Instead just expands their IDs.
 */
export function getLocationsWithHours(query: { size: number; offset: number }) {
  return ApiClient<
    OffsetResponse<
      'locations',
      ApiLocation & ExpandApiLocationHours & ExpandApiAllPrecinctsIds
    >
  >('locations/', 'GET', {
    size: query.size,
    offset: query.offset,
    // We don’t specify dates, so we’ll get hours for all days that the
    // location has them.
    expand: 'hours,all_precincts_ids',
  });
}

export type ApiUserWithAssignments = DeepMerge<
  [ApiUser, ExpandApiUserPii, ExpandApiUserRelatedCheckinSet]
>;

/**
 * Returns a list of users with their assignments expanded.
 *
 * Sorted by last name by default.
 */
export function getUsersWithAssignments(query = {}) {
  const params = _.assign(
    { expand: getExpandParam(PEOPLE), sort_by_last_name: true, size: 50 },
    query
  );

  return ApiClient<OffsetResponse<'users', ApiUserWithAssignments>>(
    'users/',
    'GET',
    params
  );
}

/**
 * Returns assignments belonging to a single user.
 */
export function getUserAssignments(user_id: number) {
  const today = new Date().toISOString().substring(0, 10); /* YYYY-MM-DD */
  const params = _.assign({
    sort_by_shift_date: true,
    today: today,
  });

  return ApiClient<OffsetResponse<'assignments', UserMappedAssignment>>(
    `user/${user_id}/assignments/upcoming`,
    'GET',
    params
  );
}

export type ApiBoilerRoomWithAssignments = DeepMerge<
  [
    ApiBoilerRoom,
    ExpandApiBoilerRoomRelatedAssignmentSetCheckinSet,
    ExpandApiBoilerRoomRelatedAssignmentSetUserPii
  ]
>;

/**
 * Returns boiler rooms with their assignments expanded.
 */
export function getBoilerRoomsWithAssignments(query = {}) {
  const params = _.assign(
    { expand: getExpandParam(BOILER_ROOMS), size: 50 },
    query
  );

  return ApiClient<
    OffsetResponse<'boiler_rooms', ApiBoilerRoomWithAssignments>
  >('boiler_rooms/', 'GET', params);
}

export type ApiBoardOfElectionsWithAssignments = DeepMerge<
  [
    ApiBoardOfElections,
    ExpandApiBoardOfElectionsRelatedAssignmentSetCheckinSet,
    ExpandApiBoardOfElectionsRelatedAssignmentSetUserPii
  ]
>;

/**
 * Returns boards of elections with their assignments expanded.
 */
export function getBoardsOfElectionsWithAssignments(query = {}) {
  const params = _.assign(
    { expand: getExpandParam(BOARDS_OF_ELECTIONS), size: 50 },
    query
  );

  return ApiClient<
    OffsetResponse<'boards_of_elections', ApiBoardOfElectionsWithAssignments>
  >('boards_of_elections/', 'GET', params);
}

/**
 * The response from this depends on what type of assignment was created and
 * also what “view” was passed during creation. This also has a lot of expanded
 * things…
 *
 * In the case of a delete, the `assignment` property is missing.
 */
export type ApiAssignmentCrudResponse =
  // Precinct assignment from the precinct list
  | {
      assignment?: DeepMerge<
        [
          ApiPollAssignment,
          WithApiAssignmentExtras,
          WithApiAssignmentCheckinSet,
          // Creation has all of these expanded
          {
            location: ApiLocation | null;
            precinct: ApiPrecinct &
              ExpandApiPrecinctCongressionalDistricts &
              ExpandApiPrecinctRelatedAssignmentSetCheckinSet;
            user: ApiUser & ExpandApiUserPii;
          }
        ]
      >;
      precinct: ApiPrecinctWithAssignments;
    }
  // Location assignment from the location list
  | {
      assignment?: DeepMerge<
        [
          ApiPollAssignment,
          WithApiAssignmentExtras,
          WithApiAssignmentCheckinSet,
          {
            location: ApiLocation;
            precinct: null;
            user: ApiUser & ExpandApiUserPii;
          }
        ]
      >;
      location: ApiLocationWithAssignments;
    }
  // Board of Elections assignment from the BoE list
  | {
      assignment?: DeepMerge<
        [
          ApiBoardOfElectionsAssignment,
          WithApiAssignmentExtras,
          {
            board_of_elections: ApiBoardOfElections;
            user: ApiUser & ExpandApiUserPii;
          }
        ]
      >;
      board_of_elections: ApiBoardOfElectionsWithAssignments;
    }
  // Boiler Room or Hotline Center assignment from one of those lists
  | {
      assignment?: DeepMerge<
        [
          ApiBoilerRoomAssignment,
          WithApiAssignmentExtras,
          {
            boiler_room: ApiBoilerRoom;
            user: ApiUser & ExpandApiUserPii;
          }
        ]
      >;
      boiler_room: ApiBoilerRoomWithAssignments;
    }
  // This is the case where the assignment was made from the user details
  // page, and it could be an assignment for any of the assignment types.
  | {
      assignment?: DeepMerge<
        [
          ApiAssignment,
          WithApiAssignmentExtras,
          WithApiAssignmentCheckinSet,
          // The user-specific case is complicated since it could be any of these
          // expanded.
          {
            location?: unknown;
            precinct?: unknown;
            board_of_elections?: unknown;
            boiler_room?: unknown;
            user: ApiUser & ExpandApiUserPii;
          }
        ]
      >;
      user: ApiUserWithAssignments & WithApiUserCurrentBoilerRooms;
    };

export type NewApiAssignment = (
  | Omit<ApiPollAssignment, 'id'>
  | Omit<ApiBoilerRoomAssignment, 'id'>
  | Omit<ApiBoardOfElectionsAssignment, 'id'>
) &
  // Not quite sure how this should work in with the other types, but not going
  // to sort through that now.
  Partial<Pick<WithApiAssignmentExtras, 'place_status'>>;

/**
 * Creates an assignment of the type given in `ctx.listViewType`.
 */
export function createAssignment(
  assignmentData: NewApiAssignment,
  ctx: AssignmentContext,
  query = {}
) {
  const params = _.assign({ expand: getExpandParam(ctx.listViewType) }, query);
  const path = `${getPathBaseForViewContextType(ctx)}assignment/`;

  return ApiClient<ApiAssignmentCrudResponse>(
    path,
    'POST',
    params,
    {},
    assignmentData
  );
}

export function updateAssignment(
  assignmentData: ApiAssignment,
  ctx: AssignmentContext,
  query = {}
) {
  const params = _.assign({ expand: getExpandParam(ctx.listViewType) }, query);
  const path = `${getPathBaseForViewContextType(ctx)}assignment/${
    assignmentData.id
  }/`;
  return ApiClient<ApiAssignmentCrudResponse>(
    path,
    'PUT',
    params,
    {},
    assignmentData
  );
}

export function deleteAssignment(
  assignmentData: Pick<ApiAssignment, 'id'>,
  ctx: AssignmentContext,
  query = {}
) {
  const params = _.assign({ expand: getExpandParam(ctx.listViewType) }, query);
  const path = `${getPathBaseForViewContextType(ctx)}assignment/${
    assignmentData.id
  }/`;
  return ApiClient<ApiAssignmentCrudResponse>(path, 'DELETE', params, {}, null);
}

export async function bulkCreateAssignments(assignments: NewApiAssignment[]) {
  // post to locations in all cases, accepts all kinds of assignments.
  return (
    await ApiClient<{ bulk_assignments: ApiAssignment[] }>(
      'locations/assignments/',
      'POST',
      {},
      {},
      { bulk_assignments: assignments }
    )
  )['bulk_assignments'];
}

/**
 * Tells the backend to prepare a CSV export and returns information on where it
 * can be downloaded from.
 */
export function getCSVExport(params: JQuery.PlainObject) {
  return ApiClient<{
    get_url: string;
    head_url: string;
  }>('users/assignment_csv_async/', 'POST', params);
}

export type ApiLocationBulkUpdate = {
  id: number;
  state_rank?: number | null;
};

/**
 * Sends a collection of updates to modify locations all at once.
 *
 * Must be a VPD or DVPD.
 */
export function bulkUpdateLocations(locationUpdates: ApiLocationBulkUpdate[]) {
  return ApiClient<{}>(
    'locations/bulk/',
    'POST',
    {},
    {},
    { locations: locationUpdates }
  );
}

/**
 * Sends a collection of updates to modify voting hours for location(s) all at once.
 *
 * Must be a VPD or DVPD.
 */
export function bulkUpdateVotingHours(
  votingHourUpdates: ApiLocationHourUpdates[]
) {
  return ApiClient<ApiLocationHours[]>(
    'locations/hours/',
    'POST',
    {},
    {},
    { hours: votingHourUpdates }
  );
}

export default {
  getPrecinctsWithAssignments,
  getLocationsWithAssignments,
  getUsersWithAssignments,
  getUserAssignments,
  getBoilerRoomsWithAssignments,
  getBoardsOfElectionsWithAssignments,
  createAssignment,
  updateAssignment,
  deleteAssignment,
  getCSVExport,
};
