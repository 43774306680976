/* Common types that are used among a lot of the API responses */

import moment, { ISO_8601 } from 'moment';

import { State } from '../constants';

// TODO(fiona): Would be cute to have these be more precise.
export type DateYear = number;
export type DateMonth =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12';
export type DateDay = number;

export type TimeHour = number;
export type TimeMinute = number;
export type TimeSecond = number;

export type DateString = `${DateYear}-${DateMonth}-${DateDay}`;
export type TimeString = `${TimeHour}:${TimeMinute}:${TimeSecond}`;

export const DATE_STRING_FORMAT = 'YYYY-MM-DD';
export const DATE_STRING_REGEXP = /(\d{4})-(\d{2})-(\d{2})/;
export const TIME_STRING_FORMAT = 'HH:mm:ss';

export const SHORT_MONTHS: { [month in DateMonth]: string } = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const FULL_MONTHS: { [month in DateMonth]: string } = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export function dateToDateString(d: Date): DateString {
  return moment(d).format(DATE_STRING_FORMAT) as DateString;
}

/**
 * Type predicate for {@link DateString}. Note that this is only _approximately_
 * correct because it doesn’t validate the range of months or days, but it’s
 * good enough for practical use.
 */
export function isDateString(str: string): str is DateString {
  // Check against [0]’s length to make sure that we’ve matched the entire
  // string and not just a {@link DateString} subset within it.
  return str.match(DATE_STRING_REGEXP)?.[0].length === str.length;
}

/**
 * Converts 12h time string (without seconds) to
 * a 24h time string (with seconds).
 */
export function amPmTimeTo24hTimeString(timeInput: string): TimeString {
  return moment(timeInput, 'HH:mm a').format(TIME_STRING_FORMAT) as TimeString;
}

/**
 * Function that calls its callback if the string parses to a date. Useful for
 * guarding update functions while a date is being modified.
 *
 * TODO(fiona): Rewrite DatetimeInput to be better about providing specific
 * dates and handling invalid formats.
 */
export function callIfDate(str: string, cb: (d: Date) => void) {
  const m = moment(str, ISO_8601);
  if (m.isValid()) {
    cb(m.toDate());
  }
}

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export type Iso8601Timezone = 'Z' | '+00:00';

export type Iso8601String =
  | `${DateString}T${TimeString}${Iso8601Timezone}`
  | `${DateString}T${TimeString}.${number}${Iso8601Timezone}`;

export type CountySlug = `${Lowercase<State>}-${string}`;

/**
 * Response that includes `size` and `offset` parameters for a kind of
 * pagination. Responses of this type have custom property names, which are
 * passed through the first type parameter.
 */
export type OffsetResponse<K extends string, T> = {
  [k in K]: T[];
} & {
  size: number;
  offset: number;
};

/**
 * Response for paginated responses with `next` and `previous` properties.
 */
export type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

/**
 * Type that comes from some dashboard APIs.
 */
export type CountedResponse<T> = {
  count: number;
  size: number;
  offset: number;
  results: T[];
};

export type FormatTimeOptions = {
  /** If true, does not include a space between the time and 'am' or 'pm' */
  tight?: boolean | undefined;
  noAmPm?: boolean | undefined;
};

/**
 * Function that formats a TimeString to h:mm a format (e.g. 2:45 pm), removing the
 * minutes component if it's 00.
 */
export function formatTime(time: TimeString, options: FormatTimeOptions = {}) {
  return moment(time, 'HH:mm:ss')
    .format(options.noAmPm ? `h:mm` : `h:mm${options.tight ? '' : ' '}a`)
    .replace(':00', '');
}
