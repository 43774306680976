import React from 'react';
import { AriaDialogProps, mergeProps, useDialog } from 'react-aria';

/**
 * Makes a react-aria Dialog. Suitable for putting in a Popover.
 *
 * If a title element is provided, it applies the ARIA title props to it.
 *
 * Is not modal by itself. Include in a {@link Popover} or a
 * {@link ModalOverlay}.
 *
 * @see https://react-spectrum.adobe.com/react-aria/useDialog.html
 */
export const Dialog: React.FunctionComponent<
  AriaDialogProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
      title?: React.ReactElement | string | undefined;
    }
> = ({ title, children, ...props }) => {
  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      ref={ref}
      {...mergeProps(dialogProps, props, {
        className: 'flex flex-col items-stretch',
      })}
    >
      {/* TODO(fiona): style this better? */}
      {typeof title === 'string' && (
        <h3 {...titleProps} className="mb-4 text-xl font-bold">
          {title}
        </h3>
      )}
      {typeof title === 'object' && React.cloneElement(title, titleProps)}
      {children}
    </div>
  );
};
