import {
  issueCreatePermissions,
  issueEditPermissions,
  IssuePermission,
  ownIssueEditPermissions,
  UserRole,
  US_NATIONAL_STATE_CODE,
} from '../../constants';
import { AppState } from '../../modules/flux-store';
import { IssueState } from '../../modules/issue/reducers';

// TODO: Probably will use this to gate certain sections of the app based on
//       role.
//
// TODO(fiona): Remove all of the “data” return values of this (e.g.
// currentUser) so that it’s just about permissions.
export default function mapStateToLbjPermissions(
  state: Pick<AppState, 'issue' | 'user'>
) {
  const { user, issue } = state;
  const currentUserRole = user.currentUser.userData?.get('role') as
    | UserRole
    | null
    | undefined;
  const hasSignedEula = !!user.currentUser.userData?.get('signed_eula');
  const issueInProgress = issue.get('issueInProgress');
  const userShouldSeeLocationNotifications =
    !!currentUserRole && !['none', 'poll_observer'].includes(currentUserRole);
  const currentUserState = user.getIn([
    'currentUser',
    'userData',
    'assignment_state',
  ]);
  const roleNotPollObserverOrHotlineWorker =
    currentUserRole !== 'poll_observer' && currentUserRole !== 'hotline_worker';
  const roleNotViewOnly = currentUserRole !== 'view_only';
  const canEditState = currentUserState === US_NATIONAL_STATE_CODE;
  const canSeeObserver = currentUserRole !== 'poll_observer';
  const canSeePriority = roleNotPollObserverOrHotlineWorker && roleNotViewOnly;
  const canExport = roleMayExport(currentUserRole);
  const hasAdminPermissions =
    currentUserRole === 'vpd' || currentUserRole === 'deputy_vpd';
  const canFilterByUser = roleMayFilterByUser(currentUserRole);
  const canEmailUsers =
    currentUserRole === 'vpd' || currentUserRole === 'deputy_vpd';
  const canUseElectionSpecificFilters =
    roleMayUseElectionSpecificFilters(currentUserRole);

  const enabledIssueFields = getEnabledIssueFields(
    currentUserRole,
    user.currentUser.userData?.get('id') as number,
    issueInProgress
  );

  const canCreateIssue = roleNotViewOnly;

  return {
    currentUser: user.currentUser.userData,
    currentUserElection: user.currentUser.currentUserElection,
    hasSignedEula,
    userShouldSeeLocationNotifications,
    hasAdminPermissions,
    enabledIssueFields,
    canEditState,
    canSeeObserver,
    canSeePriority,
    canEmailUsers,
    canExport,
    canFilterByUser,
    canUseElectionSpecificFilters,
    canCreateIssue,
  };
}

export function getEnabledIssueFields(
  currentUserRole: UserRole | null | undefined,
  currentUserId: number,
  issueInProgress: IssueState['issueInProgress'] | null = null
): IssuePermission[] {
  if (!currentUserRole) {
    return [];
  }

  if (issueInProgress?.get('id')) {
    // an id.
    const issueCreatorId = issueInProgress.getIn(['created_by', 'id']) as
      | number
      | undefined;
    const issueOwnerId = issueInProgress.getIn(['owner', 'id']) as
      | number
      | undefined;

    if (currentUserId === issueCreatorId || currentUserId === issueOwnerId) {
      return ownIssueEditPermissions[currentUserRole];
    } else {
      return issueEditPermissions[currentUserRole];
    }
  } else {
    return issueCreatePermissions[currentUserRole];
  }
}

/**
 * Returns true if the role exists but is not a poll observer or hotline worker
 * (who typically have much reduced view into issues).
 */
export function roleIsNotPollObserverOrHotlineWorker(
  role: UserRole | null | undefined
) {
  return !!role && role !== 'poll_observer' && role !== 'hotline_worker';
}

/** View only users are in a unique situation where they have both elevated and limited permissions.*/
export function roleIsNotViewOnly(role: UserRole | null | undefined) {
  return !!role && role !== 'view_only';
}

export function roleMayExport(role: UserRole | null | undefined) {
  return role === 'vpd';
}

export function roleMayUseElectionSpecificFilters(
  role: UserRole | null | undefined
) {
  return roleIsNotPollObserverOrHotlineWorker(role) && roleIsNotViewOnly(role);
}

export function roleMayFilterByUser(role: UserRole | null | undefined) {
  return roleIsNotPollObserverOrHotlineWorker(role) && roleIsNotViewOnly(role);
}

export function roleMayAssignIssue(role: UserRole | null | undefined) {
  return roleIsNotPollObserverOrHotlineWorker(role) && roleIsNotViewOnly(role);
}

export function roleMayEditBoilerRoom(role: UserRole | null | undefined) {
  return roleIsNotPollObserverOrHotlineWorker(role) && roleIsNotViewOnly(role);
}

export function roleMayEditElection(role: UserRole | null | undefined) {
  return (
    role === 'vpd' || role === 'deputy_vpd' || role === 'boiler_room_leader'
  );
}

export function roleMayEscalateIssue(role: UserRole | null | undefined) {
  return roleIsNotPollObserverOrHotlineWorker(role) && roleIsNotViewOnly(role);
}

/**
 * Returns false if the role is view-only. Returns true for all other user types.
 */
export function roleMayCreateIssue(role: UserRole | null | undefined) {
  return roleIsNotViewOnly(role);
}

/**
 * Returns false if the role is view-only. Returns true for all other user types.
 */
export function roleMayAddToIssue(role: UserRole | null | undefined) {
  return roleIsNotViewOnly(role);
}

export type LbjPermissions = Omit<
  ReturnType<typeof mapStateToLbjPermissions>,
  // Omit some of the data-specific values that aren’t permissions.
  'currentUser' | 'currentUserElection'
>;
