import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DayPicker from 'react-day-picker';

// http://arca-computing.github.io/MultipleDatePickerReact/
//
// see api ref: http://react-day-picker.js.org/
export default class CalendarFilter extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  onDayClick(day) {
    this.props.onFilterChange({
      target: {
        name: 'dates',
        value: this.getParsedDay(day),
      },
    });
  }

  getParsedDay(day) {
    return moment(day).format('YYYY-MM-DD');
  }

  dayIsSelected(day) {
    const dateFilter = this.props.filters.get('dates') || '';
    const selectedDates = dateFilter.split(',');
    return _.includes(selectedDates, this.getParsedDay(day));
  }

  render() {
    return (
      <div className="assignment-date-picker">
        <DayPicker
          enableOutsideDays
          onDayClick={this.onDayClick.bind(this)}
          selectedDays={this.dayIsSelected.bind(this)}
        />
      </div>
    );
  }
}
