import moment from 'moment';
import React from 'react';

import { MapFromJs } from '../../../utils/types';

import AssignmentTimelineV2, { AssignmentData } from './assignment-timeline-v2';

const AssignmentDetailsItem: React.FunctionComponent<{
  assignment: MapFromJs<AssignmentData>;
  onEditClick: (assignment: MapFromJs<AssignmentData>) => void;
  onDeleteClick: (assignment: MapFromJs<AssignmentData>) => void;
  selected: boolean;
  readOnly: boolean;
  now: moment.Moment;
}> = ({ assignment, onEditClick, onDeleteClick, selected, readOnly, now }) => {
  // AssignmentTimelineV2 was written with plain JS for better typing, so
  // convert the Immutable object here. useMemo since it could be a li’l
  // expensive.
  const assignmentData = React.useMemo<AssignmentData>(
    () => assignment.toJS() as AssignmentData,
    [assignment]
  );

  return (
    <div className={`assignment-details-item${selected ? ' selected' : ''}`}>
      <AssignmentTimelineV2
        assignmentData={assignmentData}
        now={now}
        timelineActionsEl={
          readOnly ? undefined : (
            <>
              <button
                title="Edit Assignment"
                className="assignment-button"
                onClick={() => onEditClick(assignment)}
                disabled={selected}
              >
                <span className="material-icons">edit</span>
              </button>
              <button
                title="Delete Assignment"
                className="assignment-button"
                onClick={() => onDeleteClick(assignment)}
                disabled={selected}
              >
                <span className="material-icons">delete</span>
              </button>
            </>
          )
        }
      />
    </div>
  );
};

export default AssignmentDetailsItem;
