import * as Immutable from 'immutable';

import { AppState } from '../../modules/flux-store';

import { ListRequestRecord } from './list-request-state-handler';

/**
 * Information about the pagination of a {@link ListRequestRecord} to support
 * pagination UI.
 *
 * (While this could ideally just be expressed as a typed JS object, we keep it
 * as an Immutable object since some callers outside of `PaginatedComponent` may
 * still be calling `.get` on it.)
 *
 * @see PaginatedComponent
 */
export class PaginationData extends Immutable.Record({
  offset: 0,
  size: 0,
  isFirstPage: false,
  isLastPage: false,
  listIsEmpty: false,
}) {}

/**
 * Given a `keyPath` into an {@link AppState} module, gets the
 * {@link ListRequestRecord} and builds a {@link PaginationData} from it.
 */
export default function mapStateToPagination(
  module: AppState['assignment' | 'notifications' | 'user'],
  keyPath: string[]
): PaginationData {
  const lbjListResponse = module.getIn(keyPath) as ListRequestRecord<unknown>;
  const listData = lbjListResponse.get('listData');
  const responseIsEmpty = listData.isEmpty();
  const offset = lbjListResponse.get('offset') || 0;
  const listIsEmpty = listData.isEmpty();

  const size = lbjListResponse.get('size') || 0;
  const isLastPage = responseIsEmpty || size > listData.count();
  const isFirstPage = offset === 0;

  return new PaginationData({
    size,
    offset,
    listIsEmpty,
    isLastPage,
    isFirstPage,
  });
}
