import { US_NATIONAL_STATE_CODE, State } from './states';

type coordinates = {
  south: number;
  west: number;
  north: number;
  east: number;
};

export const STATE_BBOXES: {
  [s in State]: coordinates;
} = {
  // US national state boundaries taken from individual states
  [US_NATIONAL_STATE_CODE]: {
    south: 18.86546, // taken from Hawaii
    west: -179.231086, // taken from Alaska
    north: 71.439786, // taken from Alaska
    east: -66.885444, // taken from Maine
  },
  // Democrats abroad don't have state boundaries
  DA: {
    south: 0,
    west: 0,
    north: 0,
    east: 0,
  },
  AL: {
    south: 30.144425,
    west: -88.473227,
    north: 35.008028,
    east: -84.888246,
  },
  AK: {
    south: 51.175092,
    west: -179.231086,
    north: 71.439786,
    east: 179.859681, // should this be -129.[...]?
  },
  AR: {
    south: 33.004282,
    west: -94.617859,
    north: 36.499749,
    east: -89.640997,
  },
  AZ: {
    south: 31.332341,
    west: -114.81630000000001,
    north: 37.003725,
    east: -109.045172,
  },
  CA: {
    south: 32.528832,
    west: -124.482003,
    north: 42.009503,
    east: -114.131211,
  },
  CO: {
    south: 36.992449,
    west: -109.060204,
    north: 41.00344400000001,
    east: -102.041522,
  },
  CT: {
    south: 40.950943,
    west: -73.727775,
    north: 42.050511,
    east: -71.787239,
  },
  DC: {
    south: 38.791645,
    west: -77.119759,
    north: 38.995845,
    east: -76.909393,
  },
  DE: {
    south: 38.451132,
    west: -75.789023,
    north: 39.839516,
    east: -74.984165,
  },
  FL: {
    south: 24.396308000000005,
    west: -87.634896,
    north: 31.000968000000004,
    east: -79.974306,
  },
  GA: {
    south: 30.355756999999997,
    west: -85.605165,
    north: 35.001303,
    east: -80.78296,
  },
  HI: {
    south: 18.86546,
    west: -178.443593,
    north: 28.517269,
    east: -154.755792,
  },
  ID: {
    south: 41.988182,
    west: -117.243027,
    north: 49.000846,
    east: -111.043495,
  },
  IL: {
    south: 36.970298,
    west: -91.513079,
    north: 42.50848100000001,
    east: -87.019935,
  },
  IN: {
    south: 37.77167200000001,
    west: -88.097892,
    north: 41.761368,
    east: -84.784535,
  },
  IA: {
    south: 40.37544,
    west: -96.639485,
    north: 43.501196,
    east: -90.140061,
  },
  KS: {
    south: 36.993016,
    west: -102.051769,
    north: 40.003166,
    east: -94.588387,
  },
  KY: {
    south: 36.497058,
    west: -89.571203,
    north: 39.147732,
    east: -81.964788,
  },
  LA: {
    south: 28.855127,
    west: -94.043352,
    north: 33.01962,
    east: -88.758388,
  },
  ME: {
    south: 42.917126,
    west: -71.083928,
    north: 47.45983300000001,
    east: -66.885444,
  },
  MD: {
    south: 37.886605,
    west: -79.487651,
    north: 39.723037,
    east: -74.98628200000002,
  },
  MA: {
    south: 41.187053000000006,
    west: -73.50821,
    north: 42.886778,
    east: -69.858861,
  },
  MI: {
    south: 41.696118,
    west: -90.418392,
    north: 48.306063,
    east: -82.122971,
  },
  MN: {
    south: 43.499361,
    west: -97.239093,
    north: 49.384479,
    east: -89.483385,
  },
  MS: {
    south: 30.139845,
    west: -91.655009,
    north: 34.996099,
    east: -88.097888,
  },
  MO: {
    south: 35.995685,
    west: -95.774704,
    north: 40.61364,
    east: -89.098968,
  },
  MT: {
    south: 44.357915,
    west: -116.04915300000002,
    north: 49.001109,
    east: -104.039694,
  },
  NE: {
    south: 39.999932,
    west: -104.053514,
    north: 43.001707,
    east: -95.30829000000001,
  },
  NV: {
    south: 35.001857,
    west: -120.00647300000001,
    north: 42.001842,
    east: -114.039461,
  },
  NH: {
    south: 42.697042,
    west: -72.557124,
    north: 45.305778,
    east: -70.575094,
  },
  NJ: {
    south: 38.788657,
    west: -75.563586,
    north: 41.357607,
    east: -73.88506,
  },
  NM: {
    south: 31.332160000000002,
    west: -109.050431,
    north: 37.000233,
    east: -103.002043,
  },
  NY: {
    south: 40.476578,
    west: -79.76259,
    north: 45.015865,
    east: -71.777491,
  },
  NC: {
    south: 33.752878,
    west: -84.321821,
    north: 36.588137,
    east: -75.400119,
  },
  ND: {
    south: 45.935072,
    west: -104.049012,
    north: 49.000561,
    east: -96.554411,
  },
  OH: {
    south: 38.403423,
    west: -84.820305,
    north: 42.327132,
    east: -80.518705,
  },
  OK: {
    south: 33.615787,
    west: -103.002413,
    north: 37.002312,
    east: -94.431014,
  },
  OR: {
    south: 41.992082,
    west: -124.70354100000002,
    north: 46.299099,
    east: -116.463262,
  },
  PA: {
    south: 39.719799,
    west: -80.519851,
    north: 42.516072,
    east: -74.68956100000001,
  },
  RI: {
    south: 41.095834,
    west: -71.907258,
    north: 42.018799,
    east: -71.088571,
  },
  SC: {
    south: 31.995954,
    west: -83.35392800000001,
    north: 35.215485,
    east: -78.499301,
  },
  SD: {
    south: 42.479686,
    west: -104.057879,
    north: 45.945377,
    east: -96.436472,
  },
  TN: {
    south: 34.982924,
    west: -90.310491,
    north: 36.678255,
    east: -81.6469,
  },
  TX: {
    south: 25.837164000000005,
    west: -106.645646,
    north: 36.500704,
    east: -93.50803900000001,
  },
  UT: {
    south: 36.997657,
    west: -114.052885,
    north: 42.001702,
    east: -109.041572,
  },
  VT: {
    south: 42.726933,
    west: -73.437905,
    north: 45.016658,
    east: -71.465047,
  },
  VA: {
    south: 36.540852,
    west: -83.675395,
    north: 39.466012,
    east: -75.166435,
  },
  WA: {
    south: 45.543541,
    west: -124.84897400000001,
    north: 49.002439,
    east: -116.91607100000002,
  },
  WV: {
    south: 37.20154,
    west: -82.644591,
    north: 40.638801,
    east: -77.719519,
  },
  WI: {
    south: 42.49172,
    west: -92.889433,
    north: 47.309822,
    east: -86.249548,
  },
  WY: {
    south: 40.994772,
    west: -111.05455800000001,
    north: 45.005815,
    east: -104.052245,
  },
  AS: {
    south: -14.601813000000002,
    west: -171.141907,
    north: -10.997203,
    east: -168.101612,
  },
  GU: {
    south: 13.182335,
    west: 144.563426,
    north: 13.706179,
    east: 145.009167,
  },
  MP: {
    south: 14.036565,
    west: 144.813338,
    north: 20.616556,
    east: 146.154418,
  },
  PR: {
    south: 17.831509,
    west: -67.998751,
    north: 18.568002,
    east: -65.168503,
  },
  VI: {
    south: 17.623468,
    west: -65.15411800000001,
    north: 18.464984,
    east: -64.512674,
  },
};

export function bboxToLatLngBounds(state: State) {
  const stateBbox = STATE_BBOXES[state];
  const stateBounds: google.maps.LatLngBoundsLiteral = {
    east: stateBbox.east,
    north: stateBbox.north,
    south: stateBbox.south,
    west: stateBbox.west,
  };
  return stateBounds;
}
