import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  lbjAppSections,
  userNotExcludableTags,
  userViewTypes,
} from '../../../constants';
import { removeAllUsersFromQueue } from '../../../modules/email/action-creators';
import RIT from '../../../utils/render-if-truthy';
import {
  queryToSearch,
  searchToQuery,
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import mapStateToUserFilters from '../../../utils/user/map-state-to-user-filters';
import { USER_DEFAULT_FILTERS } from '../../../utils/user/query-params';
import {
  convertToExcludedTag,
  extractExcludedTagName,
  getExcludedTags,
  getIncludedTags,
  isExcluded,
} from '../../../utils/user/tags';
import DebouncedInput from '../../presentational/form/debounced-input';
import EdayConfirmedInput from '../../presentational/form/eday-confirmed-input';
import OptionalField from '../../presentational/form/optional-field';
import Select from '../../presentational/form/select';
import StateSelect from '../../presentational/form/state-select';
import Toggle from '../../presentational/form/toggle';
import TrainingInput from '../../presentational/form/training-input';
import Sidebar from '../../presentational/lbj/sidebar';
import CountyFilterContainer from '../form/county-filter-container';
import StateFilterContainer from '../form/state-filter-container';
import TagPicker from '../form/tag-picker-container';

const { USER } = lbjAppSections;
const { ASSIGNMENT_NOTIFY } = userViewTypes;

export class UserFilterSidebarView extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    langFilters: PropTypes.string,
    ...WithRoutingPropTypes,
  };

  constructor(props) {
    super(props);

    this.onExcludeTags = this.onExcludeTags.bind(this);
    this.onUpdateTags = this.onUpdateTags.bind(this);
  }

  onExcludeTags(selectedTags) {
    const { filters } = this.props;
    const currentTags = filters.get('tags')
      ? filters.get('tags').split(',')
      : [];
    // we should never be selecting an included tag (this is a weird
    // side-effect of how existing tags are dealt with
    const negativeTags = selectedTags
      .toJS()
      .filter((tag) => !isExcluded(tag))
      .map((filteredTag) => convertToExcludedTag(filteredTag));

    // remove all previously excluded tags and the inclusion of tags
    // that we're excluding
    const newTags = currentTags
      .filter((tag) => {
        return (
          (!isExcluded(tag) ||
            userNotExcludableTags.includes(extractExcludedTagName(tag))) &&
          !negativeTags.includes(convertToExcludedTag(tag)) &&
          !negativeTags.includes(tag)
        );
      })
      .concat(negativeTags);
    this.handleFilterChange('tags', newTags.join(','));
  }

  onIncludeTags(selectedTags) {
    const { filters } = this.props;

    // we should never be selecting an excluded tag (this is a weird
    // side-effect of how existing tags are dealt with
    const filteredSelectedTags = selectedTags
      .toJS()
      .filter((tag) => !isExcluded(tag));
    const currentTags = filters.get('tags')
      ? filters.get('tags').split(',')
      : [];

    // remove all previously included tags, duplicates of selected
    // tags, and the inclusion of tags that we're excluding
    const newTags = currentTags
      .filter((tag) => {
        return (
          isExcluded(tag[0]) &&
          !filteredSelectedTags.includes(extractExcludedTagName(tag)) &&
          !filteredSelectedTags.includes(tag)
        );
      })
      .concat(filteredSelectedTags);
    this.handleFilterChange('tags', newTags.join(','));
  }

  onUpdateTags(selectedTags) {
    this.handleFilterChange('tags', selectedTags.toJS().join(','));
  }

  onFilterChange(e) {
    const { name, value } = e.target;
    this.handleFilterChange(name, value);
  }

  onFlagInputChange({ target }) {
    const { filters } = this.props;
    const { name, value } = target;
    const tagHasBeenSet = value === 'true' ? true : false;
    const currentTagsList = filters.get('tags')
      ? filters.get('tags').split(',')
      : [];
    const updatedTags = _.remove(currentTagsList, name);

    if (tagHasBeenSet) {
      updatedTags.push(name);
    }

    this.handleFilterChange('tags', updatedTags.join(','));
  }

  getDefaultTrainingValue(tags) {
    if (_.includes(tags, 'training_complete')) {
      return 'training_complete';
    }

    if (
      _.includes(tags, 'training_scheduled') &&
      _.includes(tags, '~training_complete')
    ) {
      return 'training_scheduled,~training_complete';
    }

    if (_.includes(tags, 'training_scheduled')) {
      return 'training_scheduled';
    }

    if (
      _.includes(tags, '~training_scheduled') ||
      _.includes(tags, '~training_complete')
    ) {
      return '~training_scheduled,~training_complete';
    }

    return '';
  }

  handleFilterChange(filterAttr, filterVal) {
    const { dispatch, navigate, location } = this.props;
    const { search, pathname } = location;
    const query = searchToQuery(search);
    const newQueryParams = _.omit(query, ['size', 'offset']);

    if (filterVal) {
      newQueryParams[filterAttr] = filterVal;
    } else if (
      Object.prototype.hasOwnProperty.call(USER_DEFAULT_FILTERS, filterAttr)
    ) {
      newQueryParams[filterAttr] = null;
    } else {
      delete newQueryParams[filterAttr];
    }

    dispatch(removeAllUsersFromQueue());
    navigate({ pathname, search: queryToSearch(newQueryParams) });
  }

  render() {
    const { filters, langFilters } = this.props;
    const allTagsList = filters.get('tags')
      ? filters.get('tags').split(',')
      : [];
    const langFiltersList = langFilters ? langFilters.split(',') : [];
    const userListViewType = filters.get('view');

    return (
      <Sidebar title="Search" collapsible>
        <div className="lbj-column-content">
          <DebouncedInput
            title="Search"
            name="search"
            placeholder="Find a user"
            onChange={this.onFilterChange.bind(this)}
            defaultValue={filters.get('search')}
          />
        </div>

        <div className="lbj-column-label lbj-divider">
          <h4>Filters</h4>
        </div>
        <div className="lbj-column-content">
          <Select
            name="assigned"
            title="Assigned"
            value={filters.get('assigned')}
            onChange={this.onFilterChange.bind(this)}
            choices={{
              '': '-',
              true: 'True',
              false: 'False',
            }}
          />

          {RIT(userListViewType === ASSIGNMENT_NOTIFY, () => {
            return (
              <Toggle
                title="Show only unsent assignments"
                name="needs_assignment_email"
                onChange={this.onFilterChange.bind(this)}
                checked={!!filters.get('needs_assignment_email')}
              />
            );
          })}

          <StateFilterContainer
            title="Allocated State"
            appSection={USER}
            name="assignment_state"
            includeNational
            onChange={this.onFilterChange.bind(this)}
          />

          <TagPicker
            title="Tags"
            name="included_tags"
            onChange={this.onIncludeTags.bind(this)}
            onlyTypes={['other', 'capability', 'travel_distance']}
            excludes={[
              'training_scheduled',
              'training_complete',
              'out_of_state',
              'confirmed',
            ]}
            defaultValue={getIncludedTags(allTagsList)}
            allTags={allTagsList}
          />

          <TagPicker
            title="Tags to exclude"
            name="excluded_tags"
            onChange={this.onExcludeTags}
            excludes={[
              'training_scheduled',
              'training_complete',
              'out_of_state',
              'confirmed',
            ]}
            defaultValue={getExcludedTags(allTagsList)}
            allTags={allTagsList}
          />

          <TagPicker
            title="Volunteer Roles"
            name="availability"
            onChange={this.onIncludeTags.bind(this)}
            onlyTypes={['availability']}
            defaultValue={getIncludedTags(allTagsList)}
            allTags={allTagsList}
          />

          <OptionalField label="Registered Location">
            <div>
              <StateSelect
                title="Registered State"
                appSection={USER}
                name="state"
                includeNational={false}
                value={filters.get('state')}
                onChange={this.onFilterChange.bind(this)}
              />
              <CountyFilterContainer
                title="Registered County"
                type="filter"
                appSection={USER}
                name="county"
                onChange={this.onFilterChange.bind(this)}
              />
            </div>
          </OptionalField>

          <OptionalField label="Languages">
            <TagPicker
              title="Languages"
              name="language"
              defaultValue={getIncludedTags(langFiltersList)}
              onlyTypes={['language']}
              onChange={this.onIncludeTags.bind(this)}
              allTags={allTagsList}
            />
          </OptionalField>

          <OptionalField label="Training">
            <TrainingInput
              defaultValue={this.getDefaultTrainingValue(allTagsList)}
              tags={Immutable.List(allTagsList)}
              onTagsChange={this.onUpdateTags}
              isFilter
            />
          </OptionalField>

          <OptionalField label="EDay Confirmed">
            <EdayConfirmedInput
              tags={Immutable.List(allTagsList)}
              onTagsChange={this.onUpdateTags}
              isFilter
            />
          </OptionalField>

          <OptionalField label="Has checked in">
            <Select
              name="has_checked_in"
              title="Has checked in"
              value={filters.get('has_checked_in')}
              onChange={this.onFilterChange.bind(this)}
              choices={{
                '': '-',
                false: 'Not checked in',
                true: 'Checked in',
              }}
            />
          </OptionalField>
        </div>
      </Sidebar>
    );
  }
}

export default connect((state) => {
  const { lbj } = state;
  const filters = mapStateToUserFilters(state);
  const selectedTags = filters.get('tags') || '';
  const langFilters = lbj
    .getIn(['tags', 'listData'])
    .filter((tag) => {
      return (
        tag.get('type') === 'language' &&
        _.includes(selectedTags.split(','), tag.get('name'))
      );
    })
    .map((tag) => tag.get('name'))
    .join(',');

  return {
    filters,
    langFilters,
  };
})(withRouting(UserFilterSidebarView));
