import React from 'react';

/**
 * Small alert message element.
 */
const EmptyAlert: React.FunctionComponent<{
  header?: React.ReactNode | undefined;
  description?: React.ReactNode | undefined;
}> = ({ header, description, children }) => (
  <div className="lbj-empty-alert">
    <h2 className="text-legacy-2xl font-extrabold text-accent">{header}</h2>

    {description && <p className="lbj-empty-description">{description}</p>}

    {React.Children.count(children) > 0 && (
      <div className="lbj-empty-description">{children}</div>
    )}
  </div>
);

export default EmptyAlert;
