import * as Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { formatPhoneNumber } from '../../../utils/user/phone-numbers';

export default class UserDetailsV2 extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: Immutable.Map({}),
  };

  getAddressLine2(user) {
    const parts = [];
    const city = user.get('city');
    const state = user.get('state') || '';
    const zip = user.get('zipcode') || '';
    if (city) {
      parts.push(city);
    }
    if (state || zip) {
      parts.push(`${state} ${zip}`);
    }
    // the array join allows the comma to appear only if both parts of the address are present
    return parts.join(', ');
  }

  render() {
    const { user } = this.props;
    const userName = `${user.get('first_name')} ${user.get('last_name')}`;
    const phoneNumber = user.get('phone_number');
    const email = user.get('email');
    const addressLine1 = user.get('address');
    const addressLine2 = this.getAddressLine2(user);

    return (
      <div className="assignment-user-details-v2">
        <ul className="lbj-detail-list">
          <li>
            <Link to={`users/${user.get('id')}`}>
              <h4 className="font-bold">{userName}</h4>
            </Link>
          </li>
          {phoneNumber && (
            <li>
              <span className="material-icons">phone</span>
              <span>{formatPhoneNumber(phoneNumber)}</span>
            </li>
          )}
          {email && (
            <li>
              <span className="material-icons">email</span>
              <span>{email}</span>
            </li>
          )}
          {(addressLine1 || addressLine2) && (
            <li>
              <span className="material-icons">place</span>
              <span>
                {addressLine1}
                {addressLine1 && <br />}
                {addressLine2}
              </span>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
