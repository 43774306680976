import $ from 'jquery';
import React, { Component } from 'react';

import Pagination from '../components/presentational/lbj/pagination';
import { PaginationData } from '../utils/lbj/map-state-to-pagination';
import RIT from '../utils/render-if-truthy';

export type PaginatedPage = {
  offset: number;
  size: number;
};

export type SetPageFn = (page: PaginatedPage) => void;

export type PaginationAction<P> = (props: P, page: PaginatedPage) => void;

/**
 * Component that renders a pagination control under its child.
 */
export class PaginationWrapper extends React.Component<{
  paginationData: PaginationData;
  setPage: SetPageFn;
}> {
  onGetNextPage() {
    const page = this.createNextPage();

    this.scrollToTop();
    return this.props.setPage(page);
  }

  onGetPreviousPage() {
    const page = this.createPreviousPage();

    this.scrollToTop();
    return this.props.setPage(page);
  }

  createNextPage() {
    const { paginationData } = this.props;
    const page: PaginatedPage = {
      offset: paginationData.offset + paginationData.size,
      size: paginationData.size,
    };

    return page;
  }

  createPreviousPage() {
    const { paginationData } = this.props;
    const page: PaginatedPage = {
      offset: Math.max(paginationData.offset - paginationData.size, 0),
      size: paginationData.size,
    };

    return page;
  }

  scrollToTop() {
    $('.lbj-column-content-wrapper').animate({ scrollTop: 0 }, 200);
  }

  render() {
    const { paginationData } = this.props;
    const isOnlyPage =
      !!paginationData.isFirstPage && !!paginationData.isLastPage;

    return (
      <div className="lbj-column-content-wrapper">
        {this.props.children}

        {RIT(!isOnlyPage, () => {
          return (
            <Pagination
              paginationData={paginationData}
              onGetPreviousPage={this.onGetPreviousPage.bind(this)}
              onGetNextPage={this.onGetNextPage.bind(this)}
            />
          );
        })}
      </div>
    );
  }
}

/**
 * Decorator that uses {@link PaginationWrapper} to add a pagination component
 * and call a {@link PaginationAction} function with the component’s props as
 * the first argument.
 *
 * @deprecated Use {@link PaginationWrapper} directly.
 */
export default function paginatedComponent<P>(
  paginationAction: PaginationAction<P>
) {
  return function wrapComponent(
    WrappedComponent: React.JSXElementConstructor<P>
  ) {
    class PaginatedComponent extends Component<
      P & {
        paginationData: PaginationData;
      }
    > {
      static displayName = `PaginatedComponent(${
        (WrappedComponent as any).displayName
      })`;

      render() {
        const { paginationData } = this.props;

        return (
          <PaginationWrapper
            paginationData={paginationData}
            setPage={(page) => paginationAction(this.props, page)}
          >
            <WrappedComponent {...this.props} />
          </PaginationWrapper>
        );
      }
    }

    return PaginatedComponent;
  };
}

/**
 * Given a current offset and size, returns {@link PaginationData} derived from
 * the latest array returned. Uses heuristics like “we’re on the last page if
 * the size of the array is less than the requested size.”
 */
export function arrayToPaginationData(
  arr: unknown[],
  { offset, size }: PaginatedPage
) {
  return new PaginationData({
    offset,
    size,
    isFirstPage: offset === 0,
    isLastPage: arr.length < size,
    listIsEmpty: offset === 0 && arr.length === 0,
  });
}
