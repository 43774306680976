import { METERS_TO_MILES_RATIO } from '../../constants';

/**
 * Utility functions for formatting distances for UI display.
 *
 * Note that the display config is subjective and may be changed based on
 * ongoing design recommendations.
 */

// The number of decimal places to display
const PRECISION = 0;

// The distance unit to display
const MILE_UNIT_LABEL = 'mi';

// The distance threshold for showing "less than" (ex: showing "<1" for 0.7)
const LESS_THAN_THRESHOLD = 1;

/**
 * Returns meters with the configured number of digits after the decimal.
 */
export function convertMetersToMiles(meters) {
  if (isNaN(parseFloat(meters))) {
    return null;
  }
  return parseFloat((meters / METERS_TO_MILES_RATIO).toFixed(PRECISION));
}

export function formatDistanceFromMiles(miles) {
  // We don't use a falsy comparison because the value could be 0,
  // which we still want to display
  if (isNaN(parseFloat(miles))) {
    return null;
  }
  let milesForDisplay = miles;
  if (miles < LESS_THAN_THRESHOLD) {
    milesForDisplay = '<' + LESS_THAN_THRESHOLD;
  }
  return `${milesForDisplay} ${MILE_UNIT_LABEL}`;
}

export function formatDistanceFromMeters(meters) {
  const miles = convertMetersToMiles(meters);
  return formatDistanceFromMiles(miles);
}
