import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

function isTruthy(val: React.ReactNode) {
  let ret = !!val;

  if (_.isString(val)) {
    ret = true;
  }

  return ret;
}

type RITCallback<T extends React.ReactNode[]> = (
  ...args: { [K in keyof T]: NonNullable<K> }
) => React.ReactNode;

/**
 * Copied over from form components (https://github.com/HillaryClinton/form-components/blob/master/lib/component-utils/render-if-truthy.js)
 * A JSX-specific util for rendering a component if some data value is truthy.
 * An unlimited number of arguments may be passed, with the last argument
 * always being a callback function. The supplied callback will be invoked with
 * arguments in the same order as passed, replacing non-truthy values with
 * null, and mainting truthy values.
 *
 * @param {*} obj The data value to check truthiness against
 * @param {componentCallback} cb
 *
 * @callback componentCallback
 * @param {*} obj The original data value
 *
 * @example
 * RIT('truthy!', val => <div>{val}</div>); // => <div>truthy!</div>
 * RIT(undefined, val => <div>{val}</div>); // => null
 */
export default function renderIfTruthy<T extends React.ReactNode[]>(
  ...argsArr: [...T, RITCallback<T>]
) {
  const allArgs = Immutable.List(argsArr);
  const cb = allArgs.last() as RITCallback<T>;

  const elementArgs = allArgs.pop() as Immutable.List<React.ReactNode>;
  const results = elementArgs.map((val) => isTruthy(val));

  // Giving up and doing as any here because we can’t really guarantee this with
  // the current pattern.
  return results.filter(isTruthy).size
    ? cb.apply(null, elementArgs.toArray() as any)
    : null;
}
