import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Provider } from 'react-redux';
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useRouteError,
} from 'react-router-dom';

import SurveyContainer, {
  loadForSurvey,
} from './components/containers/survey/survey-container';
import EmptyAlert from './components/presentational/lbj/empty-alert';
import NotFound from './components/presentational/lbj/not-found';
import fluxStore from './modules/flux-store';
import LoggedInApp, {
  loadForLoggedInApp,
  UserAuthBoundary,
} from './pages/LoggedInApp';
import BulkUploadAssignmentsPage, {
  loadBulkUploadAssignmentsPage,
} from './pages/assign/BulkUploadAssignmentsPage';
import LocationAssignmentPage, {
  loadLocationAssignmentPage,
} from './pages/assign/LocationAssignmentPage';
import AccountModal from './pages/auth/AccountModal';
import AuthCodeLandingPage from './pages/auth/AuthCodeLandingPage';
import LoginCodeFormPage from './pages/auth/LoginCodeFormPage';
import LoginFormPage from './pages/auth/LoginFormPage';
import CbcPage from './pages/cbc/CbcPage';
import DashboardLayout, {
  dashboardViewRedirector,
} from './pages/dashboard/DashboardLayout';
import DashboardLineLengthsPage, {
  loadDashboardLineLengthsPage,
} from './pages/dashboard/line_length_counts/DashboardLineLengthsPage';
import DashboardRecentLinesPage, {
  loadDashboardRecentLinesPage,
} from './pages/dashboard/recent_line_lengths/DashboardRecentLinesPage';
import DashboardIssuesPage, {
  loadDashboardIssuesPage,
} from './pages/dashboard/summary/DashboardIssuesPage';
import ElectionSettingsContainer, {
  loadElectionSettingsPage,
} from './pages/election/election-settings-container';
import VolunteerLandingContainer, {
  loadHomePage,
} from './pages/home/volunteer-landing-container';
import {
  IssuesIndexWithState,
  loadIssuesIndexPage,
} from './pages/issues-list/issue-index';
import {
  loadResultsIndexPage,
  ResultsIndexWithState,
} from './pages/issues-list/results-index';
import NewUserPage, { loadNewUserPage } from './pages/profile/NewUserPage';
import ReportsPage, { loadReportsPage } from './pages/reports/ReportsPage';
import { AnalyticsRoute } from './route-handlers/analytics';
import {
  HomeRouteRedirector,
  loadHomeRouteRedirector,
  rrte,
} from './route-handlers/app';
import { AssignmentsRoute } from './route-handlers/assignment';
import { FixAuthRoute } from './route-handlers/auth';
import { CheckInRoute } from './route-handlers/checkins';
import { NewIssueRoute, IssueDetailRoute } from './route-handlers/issue';
import {
  NotificationsRoute,
  LocationNotificationsRoute,
} from './route-handlers/notifications';
import {
  UsersRoute,
  UserDetailRoute,
  ProfileRoute,
} from './route-handlers/user';
import { ApiNotFoundError } from './services/api-client';
import * as LoginService from './services/login-service';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

/**
 * Error boundary for common errors that are not exceptions that should bubble
 * up to Sentry.
 */
const BaseErrorBoundary: React.FunctionComponent = () => {
  const error = useRouteError();

  if (error instanceof ApiNotFoundError) {
    return <NotFound />;
  } else {
    throw error;
  }
};

const router = sentryCreateBrowserRouter([
  {
    errorElement: <BaseErrorBoundary />,
    children: [
      // This is the route that hosts our login form.
      { path: 'login', element: <LoginFormPage /> },
      // This is the route that we link to from the login emails and from the Login
      // With Google button that processes 1-time codes into auth cookies.
      { path: 'auth', element: <AuthCodeLandingPage /> },
      // This is the route we tell people to use when we generate a code for them.
      { path: 'code', element: <LoginCodeFormPage /> },
      // This route will automatically log the user out in case there are issues
      // with their cookies and such.
      { path: 'fix', element: <FixAuthRoute /> },

      // ---- LOGGED IN USER ROUTES ----
      //
      // Each of these is wrapped in the <LoggedInApp> layout component, which
      // has a loader that will ensure that we’ve loaded a current user before
      // rendering the route elements.
      rrte({
        loader: (arg) => loadForLoggedInApp(fluxStore, arg),
        renderer: (props) => (
          <LoggedInApp {...props}>
            <Outlet />
          </LoggedInApp>
        ),

        // Necessary for ensuring that if the user is not logged in that we can get
        // them there.
        errorElement: <UserAuthBoundary />,

        children: [
          rrte({
            index: true,
            loader: (_arg) => loadHomeRouteRedirector(fluxStore),
            renderer: (props) => <HomeRouteRedirector {...props} />,
          }),

          rrte({
            path: 'survey',
            loader: () => loadForSurvey(fluxStore),
            renderer: (props) => <SurveyContainer {...props} />,
          }),

          rrte({
            path: 'reports/live',
            loader: () => loadReportsPage(fluxStore),
            renderer: (props) => <ReportsPage {...props} />,
          }),

          {
            path: 'dashboard',
            element: <DashboardLayout />,

            children: [
              {
                index: true,
                element: <Navigate to="summary" />,
                // Used to honor the old ?view=line_length_counts URLs
                loader: dashboardViewRedirector,
              },

              rrte({
                path: 'summary',
                loader: (arg) => loadDashboardIssuesPage(fluxStore, arg),
                renderer: (props) => <DashboardIssuesPage {...props} />,
              }),

              rrte({
                path: 'line_length_counts',
                loader: (arg) => loadDashboardLineLengthsPage(fluxStore, arg),
                renderer: (props) => <DashboardLineLengthsPage {...props} />,
              }),

              rrte({
                path: 'recent_line_lengths',
                loader: (arg) => loadDashboardRecentLinesPage(fluxStore, arg),
                renderer: (props) => <DashboardRecentLinesPage {...props} />,
              }),
            ],
          },

          { path: 'analytics', element: <AnalyticsRoute /> },
          rrte({
            path: '/home',
            loader: () => loadHomePage(fluxStore),
            renderer: (props) => <VolunteerLandingContainer {...props} />,
          }),
          rrte({
            path: 'issues',
            loader: (arg) => loadIssuesIndexPage(fluxStore, arg),
            renderer: (props) => <IssuesIndexWithState {...props} />,
          }),
          rrte({
            path: 'results',
            loader: (arg) => loadResultsIndexPage(fluxStore, arg),
            renderer: (props) => <ResultsIndexWithState {...props} />,
          }),
          { path: 'issues/new', element: <NewIssueRoute /> },
          { path: 'issues/:issueId', element: <IssueDetailRoute /> },
          { path: 'assignments', element: <AssignmentsRoute /> },
          { path: 'invite', element: <UsersRoute /> },
          rrte({
            path: 'new_user',
            loader: () => loadNewUserPage(fluxStore),
            renderer: (props) => <NewUserPage {...props} />,
          }),
          { path: 'users/:userId', element: <UserDetailRoute /> },
          { path: 'checkin', element: <CheckInRoute /> },
          { path: 'profile', element: <ProfileRoute /> },
          { path: 'notifications', element: <NotificationsRoute /> },
          {
            path: 'notifications/locations',
            element: <LocationNotificationsRoute />,
          },
          rrte({
            path: 'assign/locations',
            loader: (_arg) => loadLocationAssignmentPage(fluxStore),
            renderer: (props) => <LocationAssignmentPage {...props} />,
          }),
          rrte({
            path: 'assign/csv',
            loader: (_arg) => loadBulkUploadAssignmentsPage(fluxStore),
            renderer: (props) => <BulkUploadAssignmentsPage {...props} />,
          }),
          rrte({
            path: 'election-settings',
            loader: () => loadElectionSettingsPage(fluxStore),
            renderer: (props) => <ElectionSettingsContainer {...props} />,
          }),
        ],
      }),
      // temp page to trigger running cbc
      { path: 'cbc', element: <CbcPage /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]);

const ErrorAlert: React.FunctionComponent = () => (
  <EmptyAlert header="LBJ Internal Error">
    There was an error with LBJ. The development team has been notified. You can
    reload this page and try again, or contact{' '}
    <a href="mailto:lbj-help@dnc.org">lbj-help@dnc.org</a>.
  </EmptyAlert>
);

/**
 * Main app component.
 *
 * We put all of our app dependencies in this file, rather than index.tsx, so
 * that if there are hot changes during development they’ll get accepted at this
 * level and we can re-render things within React, rather than have them bubble
 * up to index.tsx, which contains a destructive call to `ReactDOM.render`.
 */
const App: React.FunctionComponent = () => (
  <Sentry.ErrorBoundary fallback={<ErrorAlert />}>
    <Provider store={fluxStore}>
      <GoogleOAuthProvider clientId={process.env['GOOGLE_CLIENT_ID']!}>
        <RouterProvider
          router={router}
          fallbackElement={
            <AccountModal
              status="loading"
              logOut={() => LoginService.logout()}
            />
          }
        />
      </GoogleOAuthProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;
