import { lbjAppSections } from '../../constants';

import { trustedLocationHoursForDate } from './location-hours';
import mapStateToDefaultShiftTimes from './map-state-to-default-shift-times';

export default function (state) {
  const { assignment } = state;

  // If the current assignment has shift times, return them
  if (assignment.hasIn(['assignmentEditor', 'parentResource', 'assignment'])) {
    return {
      startTime: assignment.getIn([
        'assignmentEditor',
        'parentResource',
        'assignment',
        'start_time',
      ]),
      endTime: assignment.getIn([
        'assignmentEditor',
        'parentResource',
        'assignment',
        'end_time',
      ]),
    };
  }

  // If the location has its own open and closed times, use those rather than
  // the election’s defaults.
  const location =
    assignment.getIn(['assignmentEditor', 'parentResource', 'location']) ??
    assignment.getIn(['assignmentEditor', 'updatedFields', 'location']);

  if (location) {
    const filters = state.filters.get(lbjAppSections.ASSIGNMENT);
    const date = filters.get('dates');
    const hours = trustedLocationHoursForDate(location, date);

    if (hours && hours.get('closed') === false) {
      return {
        startTime: hours.get('open_time'),
        endTime: hours.get('close_time'),
      };
    }
  }

  // Otherwise, return the default shift times for the election
  const { defaultStartTime, defaultEndTime } =
    mapStateToDefaultShiftTimes(state);
  return {
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  };
}
