import React from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../../components/presentational/form/input';

import { loginWithPasswordless } from '../../services/login-service';

import AuthUiModal from './AuthUiModal';

// TODO(fiona): Differentiate between connection errors and “wrong code”
// errors.
type CodeError = 'code';

export const LoginCodeForm: React.FunctionComponent<{
  code: string;
  setCode: (code: string) => void;
  logIn: () => void;
  isLoading: boolean;
  error?: CodeError | undefined;
}> = ({ code, setCode, logIn, isLoading, error }) => (
  <AuthUiModal>
    {error === 'code' && (
      <div className="c-alert-error">
        <p>There was a problem logging you in.</p>

        <p>The code you provided is incorrect or has already been used.</p>
      </div>
    )}

    <div className="lbj-login-form">
      <label htmlFor="code">Enter the one-time code provided to you:</label>

      <div className="lbj-modal-textbox">
        <Input
          autoFocus="autofocus"
          name="code"
          title=""
          required
          placeholder="00000000-0000-0000-0000-000000000000"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            setCode(ev.currentTarget.value)
          }
          value={code}
        />
      </div>

      <div className="lbj-modal-checkbox">
        <button
          type="button"
          className="c-button-large c-button-wide c-button-secondary"
          disabled={!code || isLoading}
          onClick={logIn}
        >
          {isLoading ? 'Logging In…' : 'Log In with Code'}
        </button>
      </div>
    </div>
  </AuthUiModal>
);

/**
 * This page is for handling when we need to give someone a login code directly,
 * rather than having them go through their email.
 *
 * It’s also useful for logging in as a user to try and reproduce issues.
 *
 * Codes can be generated by the LBJ Admin interface.
 */
const LoginCodeFormPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [code, setCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [error, setError] = React.useState<CodeError | undefined>(undefined);

  const logIn = async () => {
    if (!code) {
      return;
    }

    try {
      setIsLoading(true);
      setError(undefined);

      await loginWithPasswordless(code);

      // If the above didn’t throw then we’re logged in and can move along to
      // the app.
      navigate('/');
    } catch {
      setError('code');
      setIsLoading(false);
    }
  };

  return (
    <LoginCodeForm
      code={code}
      setCode={setCode}
      isLoading={isLoading}
      logIn={logIn}
      error={error}
    />
  );
};

export default LoginCodeFormPage;
