import ApiClient from './api-client';
import { ApiElection, ApiElectionUpdate } from './lbj-shared-service';

/**
 * Gets an election.
 */
export function getElection() {
  return ApiClient<ApiElection>(`election/`, 'GET');
}

/**
 * Modifies an election.
 */
export function updateElection(
  formValues: ApiElectionUpdate,
  electionId: number
) {
  return ApiClient<ApiElection>(
    `election/${electionId}/`,
    'PUT',
    {},
    {},
    formValues
  );
}

export default { getElection, updateElection };
