import React from 'react';
import { Item } from 'react-stately';
import useSWR from 'swr';

import { IconButton, Select, TextButton } from '../../components/form';

import { AppStore } from '../../modules/flux-store';
import { loadCurrentUser } from '../../route-handlers/app';

import ApiClient from '../../services/api-client';
import { Iso8601String } from '../../services/common';

type ReportRecord = {
  file_name: string;
  last_updated: Iso8601String;
  signed_url: string;
};

const BLANK_FRAME_SRC = 'about:blank';

/**
 * Page for the “live reports” that are generated by VoPro analytics and saved
 * to a GCS bucket.
 *
 * This is a secret, internal page for VoPro staff.
 */
const ReportsPage: React.FunctionComponent<{}> = () => {
  const reports = useSWR(
    ['ReportsPage#reports'] as const,
    async ([_]) => ApiClient<{ reports: ReportRecord[] }>('reports/', 'GET'),
    { refreshInterval: 60 * 1000 }
  ).data?.reports;

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  const [selectedReport, setSelectedReport] =
    React.useState<ReportRecord | null>(null);

  /**
   * Latest version of the selected {@link ReportRecord} from the server.
   *
   * This is important both to check the `last_updated` date but also so that
   * when we load this in a new window or refresh the iframe we have a version
   * with the latest signature (since they expire after 15 mins).
   */
  const serverReport =
    (selectedReport &&
      reports?.find((r) => r.file_name === selectedReport!.file_name)) ??
    null;

  const hasReportUpdated = !!(
    selectedReport &&
    serverReport &&
    selectedReport.last_updated !== serverReport.last_updated
  );

  // Automatically selects the first report if none is selected.
  React.useEffect(() => {
    if (reports && reports.length > 0 && selectedReport === null) {
      setSelectedReport(reports[0]!);
    }
  }, [reports, selectedReport]);

  const refreshFrame = () => {
    if (iframeRef.current && serverReport) {
      // We want to make sure to go to the serverReport, which will have the
      // freshest signed URL.
      iframeRef.current.src = serverReport.signed_url;
      setSelectedReport(serverReport);
    }
  };

  return (
    // calc’d height here is for smaller screens when the main LBJ container is
    // no longer a full-screen flex-col container. We subtract the heights of
    // the navbar at the top and the mobile bar at the bottom.
    //
    // (In non-mobile situations the flex-1 kicks in and this just fills the
    // full height.)
    <div className="reset-2023 clear-both flex h-[calc(100vh-45px-55px)] flex-1 flex-col items-stretch overflow-clip">
      <div className="flex gap-4 p-2">
        <div className="flex w-[400px] flex-col items-stretch">
          <Select
            aria-label="Choose a report"
            placeholder="Choose a report…"
            items={reports ?? []}
            selectedKey={selectedReport?.file_name ?? null}
            onSelectionChange={(key) => {
              const report = reports?.find(
                ({ file_name }) => file_name === key
              );
              setSelectedReport(report ?? null);
            }}
          >
            {({ file_name }) => <Item key={file_name}>{file_name}</Item>}
          </Select>
        </div>

        <IconButton
          title="Reload the report"
          icon="refresh"
          isSelected
          isDisabled={serverReport === null}
          onPress={refreshFrame}
        />

        <IconButton
          title="Open report in new window"
          icon="open_in_new"
          isSelected
          isDisabled={serverReport === null}
          onPress={() => window.open(serverReport!.signed_url, '_blank')}
        />

        {hasReportUpdated && (
          <div className="self-center">
            Report updated!{' '}
            <TextButton onPress={refreshFrame}>Click to refresh</TextButton>
          </div>
        )}
      </div>

      <iframe
        ref={iframeRef}
        className="block w-full flex-1 self-stretch"
        src={selectedReport?.signed_url ?? BLANK_FRAME_SRC}
      />
    </div>
  );
};

export default ReportsPage;

export async function loadReportsPage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof ReportsPage>> {
  await loadCurrentUser(fluxStore, {
    allowedRoles: ['vpd', 'deputy_vpd'],
  });

  return {};
}
