import _ from 'lodash';

import { State } from '../constants';
import { FiltersBySection } from '../modules/filters/action-creators';

import ApiClient from './api-client';
import { OffsetResponse } from './common';

export type NotificationsPayload = {
  [ackedState in 'seen' | 'acked' | 'unacked']?: number[];
};

// TODO(fiona): Make type for this
export type ApiTicketNotification = never;

export function getLocationChanges(filters = {}) {
  const expand = 'location,precinct';
  const params = _.assign({ expand: expand, size: 100 }, filters);
  // TODO(fiona): Test election doesn’t seem to have these so I’m not sure the
  // type.
  return ApiClient<OffsetResponse<'location_changes', unknown>>(
    'location_changes/',
    'GET',
    params
  );
}

export function notificationsExist(query = {}) {
  const params = _.assign({ acked: false }, query);
  // This isn’t called in the test election so I can’t type it, but its value
  // does flow into a state variable that’s initialized to `false` so my guess
  // is that it’s a boolean.
  return ApiClient<{ status: boolean }>('notifications_exist/', 'GET', params);
}

export function updateNotifications(changeIds: NotificationsPayload) {
  return ApiClient('update_notifications/', 'PUT', {}, {}, changeIds);
}

export function ackNotifications(changeIds: NotificationsPayload) {
  return ApiClient('ack_notifications/', 'PUT', {}, {}, changeIds);
}

/**
 * Fetches the number of location change notifications.
 */
export function getLocationChangeCount(filters: {
  acked: false;
  state: State;
}) {
  return ApiClient<{
    count: number;
    polling_interval: number;
  }>('location_changes/count/', 'GET', filters);
}

export type ApiTicketCounts = {
  tickets: number;
  location_change: number;
};

/**
 * Fetches the number of notifications that haven’t been seen by this user.
 */
export function getUnseenNotificationCounts() {
  return ApiClient<
    ApiTicketCounts & {
      polling_interval: number;
    }
  >('notifications/unseen/count/', 'GET');
}

export function getIssueNotifications(page: {
  offset?: number;
  size?: number;
}) {
  const payload = {
    expand: 'comment,updater,boiler_room',
    ...page,
  };

  return ApiClient<
    OffsetResponse<'ticket_notifications', ApiTicketNotification>
  >('ticket_notifications/', 'GET', payload);
}

export function updateIssueNotifications({
  seen,
  acked,
  unacked,
}: NotificationsPayload) {
  return ApiClient(
    'update_ticket_notifications/',
    'PUT',
    {},
    {},
    { seen, acked, unacked }
  );
}

export function markAllNotificationsAsSeen(
  filters: Partial<FiltersBySection['NOTIFICATIONS']>,
  queryParams: { bulk_update_location_changes?: boolean }
) {
  return ApiClient(
    'update_notifications/bulk/',
    'PUT',
    queryParams,
    {},
    filters
  );
}

export default {
  getLocationChanges,
  notificationsExist,
  updateNotifications,
  ackNotifications,
  getLocationChangeCount,
  getUnseenNotificationCounts,
  getIssueNotifications,
  updateIssueNotifications,
  markAllNotificationsAsSeen,
};
