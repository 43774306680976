import { Query } from 'history';
import keyMirror from 'key-mirror';
import _ from 'lodash';

import { issueViewTypes, IssueViewType } from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';

const { ALL } = issueViewTypes;

const STATIC_DEFAULTS = {
  ordering: '-id',
  group_by: 'precinct',
  view: ALL,
} as const;

const VIEW_PARAMS = {
  ALL: 'all',
  CREATED_BY_ME: 'created',
  ASSIGNED_TO_ME: 'assigned',
  BOILER_ROOM: 'boiler',
  ME_WATCHING: 'watchers',
  RESOLVED: 'resolved',
} as const;

export const USER_DEFAULT_FILTERS = keyMirror({
  state: null,
  start_date: null,
  end_date: null,
  location: null,
  county: null,
  region: null,
  status: null,
  query_election_id: null,
});

export const SYNCED_FILTERS = [
  'state',
  'county',
  'start_date',
  'end_date',
  'query_election_id',
] as const;

export function mapIssueFiltersToQueryParams(
  filters: FiltersBySection['ISSUE']
) {
  const omit: Array<keyof FiltersBySection['ISSUE']> = [
    'incident_time__lt',
    'incident_time__gte',
  ];

  // "as" because Object.keys is string[]
  (Object.keys(STATIC_DEFAULTS) as Array<keyof typeof STATIC_DEFAULTS>).forEach(
    (k) => {
      if (filters[k] === STATIC_DEFAULTS[k]) {
        omit.push(k);
      }
    }
  );

  const query: Query = _.omit(filters, omit);

  // transform view param constants into url params
  if (query['view']) {
    query['view'] = VIEW_PARAMS[query['view'] as IssueViewType];
  }

  // TODO(fiona): we probably don’t need this, since if `incident_time__gte` is
  // set then it should have been initialized from `start_date`.
  if (filters.incident_time__gte) {
    query['start_date'] = filters.incident_time__gte;
  }

  // TODO(fiona): This was broken, then TypeScript spotted the missing
  // underscore.
  //
  // We’re leaving it commented out since `incident_time__lt` is always
  // initialized as `end_date` + 1 day, so if this creates a feedback loop then
  // it will keep increasing `end_date`. This comes up between the issue list
  // and the issue details page’s “cancel changes” link.
  //
  // if (filters.incident_time_lt) {
  //   query.end_date = filters.incident_time__lt;
  // }

  return query;
}
