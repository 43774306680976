import React from 'react';

import { getLanguageIsoCode } from '../../../services/lbj-shared-service';
import { formatPhoneNumber } from '../../../utils/user/phone-numbers';

import { AssignmentUser } from '../assignment-state';
import {
  getVolunteerAttributes,
  volunteerLanguagesToLanguageNames,
} from '../assignment-utils';

export const PEOPLE_ROW_HEIGHT_PX = 79;

/**
 * Cell renderer for the 1st column in the person table, which names the person
 * and displays their address and such.
 */
const PersonRowHeader: React.FunctionComponent<{
  user: AssignmentUser;
}> = ({ user }) => {
  const fullName = `${user.first_name} ${user.last_name}`;

  const { isExperienced, isLegalCommunity, hasCarAccess, languageTags } =
    getVolunteerAttributes(user);
  const languageNames = volunteerLanguagesToLanguageNames(languageTags);

  const addressBits = [user.address?.trim(), user.city, user.state].filter(
    (b) => !!b
  );

  return (
    <div className="w-full px-2 text-gray-700">
      <div className="flex w-full items-baseline gap-2">
        <div className="flex flex-1 justify-between overflow-hidden">
          <a
            className="overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-bold leading-tight hover:underline"
            title="Open profile (in a new window)"
            href={`/users/${user.id}/?view=details`}
            target="_blank"
            rel="noreferrer"
          >
            {fullName}
          </a>

          <div>
            <a
              className="text-hyperlink"
              href={`tel:${
                user.phone_number?.startsWith('+')
                  ? user.phone_number
                  : `+1${user.phone_number}`
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {formatPhoneNumber(user.phone_number)}
            </a>
          </div>
        </div>
      </div>

      <div className="flex gap-2 overflow-hidden">
        {/* TODO(fiona): Add info on the other reasons for auto-assignment not working */}
        {user.coordinates === null && (
          <span
            className="material-icons cursor-pointer text-base text-error"
            title="Geolocation not found. Will not be able to suggest assignments for this person."
          >
            near_me_disabled
          </span>
        )}

        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-base leading-tight">
          {addressBits.length > 0 ? (
            addressBits.join(', ')
          ) : (
            <span className="italic">No address available</span>
          )}
          <br />
          {user.county_name && `${user.county_name} County`}
        </div>

        <div className="flex-1" />

        <div className="flex flex-col">
          <div>
            {isExperienced && (
              <span
                className="material-icons cursor-default align-bottom text-base leading-none"
                title="Experienced Observer"
              >
                military_tech
              </span>
            )}

            {isLegalCommunity && (
              <span
                className="material-icons cursor-default align-bottom text-base leading-none"
                title="Legal Community"
              >
                gavel
              </span>
            )}

            {hasCarAccess && (
              <span
                className="material-icons cursor-default align-bottom text-base leading-none"
                title="Has Car Access"
              >
                garage
              </span>
            )}

            {languageTags.length > 0 && (
              <>
                <span
                  className="material-icons cursor-default align-bottom text-base leading-none"
                  title={`Speaks ${languageNames.join(', ')}`}
                >
                  chat
                </span>
                <span
                  className="align-bottom leading-none"
                  title={`Speaks ${languageNames.join(', ')}`}
                >
                  {languageTags.length > 1
                    ? `${languageTags.length}`
                    : `${getLanguageIsoCode(languageTags[0])}`}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonRowHeader;
