import * as Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import inputProps from '../../../decorators/input-props';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import Autocomplete from '../../presentational/form/autocomplete';

const { ISSUE, ASSIGNMENT, DASHBOARD, ANALYTICS, PROFILE, USER } =
  lbjAppSections;

@connect((state, props) => {
  const { appSection } = props;
  const filters = state.filters.get(appSection);
  const countyFilter = filters.get('county');
  const isFetchingPrecincts =
    state.lbj.getIn(['precincts', countyFilter, 'requestIsPending']) || false;
  const electionFilter = filters.get('query_election_id');
  const precincts = electionFilter
    ? state.lbj.getIn(['precincts', countyFilter, 'listData'])
    : Immutable.fromJS([]);

  return {
    countyFilter,
    electionFilter,
    precinctFilter: filters.get('precinct'),
    precincts,
    isFetchingPrecincts,
  };
})
@inputProps() /* eslint-disable react/prop-types */
export default class PrecinctAutocompleteContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    countyFilter: PropTypes.string,
    electionFilter: PropTypes.string,
    precinctFilter: PropTypes.string,
    precincts: PropTypes.object,
    isFetchingPrecincts: PropTypes.bool,
    multi: PropTypes.bool,
    appSection: PropTypes.oneOf([
      ISSUE,
      ASSIGNMENT,
      DASHBOARD,
      ANALYTICS,
      PROFILE,
      USER,
    ]),
  };

  static defaultProps = {
    name: 'county',
    title: 'County',
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    const currentCountyFilter = this.props.countyFilter;
    const nextCountyFilter = nextProps.countyFilter;
    const nextPrecincts = nextProps.precincts;
    const currentElectionFilter = this.props.electionFilter;
    const nextElectionFilter = nextProps.electionFilter;

    // check if there's a new county filter and if we don't have that
    // county's precinct data, get it
    if (
      !!nextCountyFilter &&
      !!nextElectionFilter &&
      ((currentCountyFilter !== nextCountyFilter && !nextPrecincts) ||
        currentElectionFilter !== nextElectionFilter)
    ) {
      dispatch(
        lbjActionCreators.getPrecinctListAsync({
          county: nextCountyFilter,
          query_election_id: nextElectionFilter,
          size: 1000,
        })
      );
    }
  }

  getAutocompleteChoices() {
    const { precincts } = this.props;

    if (precincts) {
      return precincts.reduce((choices, precinct) => {
        choices[precinct.get('id')] = precinct.get('name');
        return choices;
      }, {});
    }

    return {};
  }

  render() {
    const { precinctFilter, isFetchingPrecincts, onChange, multi, required } =
      this.props;
    const precinctChoices = this.getAutocompleteChoices();

    return (
      <Autocomplete
        name="precinct"
        title="Precinct"
        placeholder="-"
        disabled={_.isEmpty(precinctChoices)}
        onChange={onChange}
        choices={precinctChoices}
        isLoading={isFetchingPrecincts}
        value={precinctFilter}
        required={required}
        multi={multi}
      />
    );
  }
}
