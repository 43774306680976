import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Select from './select';

export default class EdayConfirmedInput extends Component {
  static propTypes = {
    tags: PropTypes.object.isRequired,
    onTagsChange: PropTypes.func.isRequired,
    isFilter: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isFilter: false,
  };

  onChange({ target }) {
    const { tags, onTagsChange } = this.props;
    const { value } = target;
    const updatableTags = ['confirmed', '~confirmed'];
    let updatedTags = tags.filterNot((tag) => _.includes(updatableTags, tag));

    if (value) {
      updatedTags = updatedTags.push(value);
    }

    onTagsChange(updatedTags);
  }

  getDefaultValue() {
    const tags = this.props.tags.toJS();

    if (_.includes(tags, 'confirmed')) {
      return 'confirmed';
    }

    if (_.includes(tags, '~confirmed')) {
      return '~confirmed';
    }

    return '';
  }

  getChoices() {
    const { isFilter } = this.props;
    const choices = {
      '': '-',
      confirmed: 'Confirmed',
    };

    if (isFilter) {
      choices['~confirmed'] = 'Unconfirmed';
    }

    return choices;
  }

  render() {
    return (
      <Select
        name="confirmed"
        title="EDay Confirmed"
        value={this.getDefaultValue()}
        choices={this.getChoices()}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}
