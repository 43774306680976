import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import EmptyAlert from '../../../components/presentational/lbj/empty-alert';
import {
  PaginatedPage,
  PaginationWrapper,
} from '../../../decorators/paginated-component';
import { ApiLineLengthByHour } from '../../../services/dashboard-service';
import { PaginationData } from '../../../utils/lbj/map-state-to-pagination';
import { queryToSearch } from '../../../utils/routing-provider';

const VALUES_TO_BACKGROUND_COLOR: { [label: string]: string } = {
  '30 Minutes': '#cfedff',
  '1 Hour': '#ffffa9',
  '2 Hours': '#ffd8b6',
  '3+ Hours': '#ffcbcb',
};

export default class DashboardRecentLinesTable extends React.Component<{
  paginationData: PaginationData;
  setPage: (page: PaginatedPage) => void;

  results: ApiLineLengthByHour[];
  tableHeaderValues: (keyof ApiLineLengthByHour)[];

  sortSlug: string;
  sortDescending: boolean;
  toggleSort: (slug: string) => void;
}> {
  getHeaderValues() {
    const { tableHeaderValues } = this.props;
    const headersWithRepeatingLocations: (keyof ApiLineLengthByHour)[] = [];

    tableHeaderValues!.forEach((val, key) => {
      if (key % 5 === 0 && key > 0) {
        headersWithRepeatingLocations.push('Location');
      }

      headersWithRepeatingLocations.push(val);
    });

    return headersWithRepeatingLocations;
  }

  getSortAttributes(slug: string) {
    const { sortSlug, sortDescending, toggleSort } = this.props;

    if (sortSlug === slug) {
      return {
        'data-sort': sortDescending ? 'DESC' : 'ASC',
        onClick: () => toggleSort(slug),
      };
    } else {
      return {
        'data-sort': '',
        onClick: () => toggleSort(slug),
      };
    }
  }

  getCellStyleProps(value: any) {
    const backgroundColor = VALUES_TO_BACKGROUND_COLOR[value];

    return backgroundColor ? { backgroundColor } : {};
  }

  getLocationLabel(locationObject: ApiLineLengthByHour) {
    const name = locationObject.Location;
    const city = locationObject.City || '';
    const county = locationObject.County || '';
    const cityCounty = `${city}${city ? ', ' : ''}${county}`.trim();

    return cityCounty ? `${name} (${cityCounty})` : name;
  }

  renderTableHeaderCell(slug: string, key: string | number) {
    return (
      <th
        key={`${key}-heading`}
        data-slug={slug}
        {...this.getSortAttributes(slug)}
      >
        {slug}
      </th>
    );
  }

  renderLocationCell(location: ApiLineLengthByHour, key: string | number) {
    const tableHeaderValues = this.getHeaderValues();
    const locationLabel = this.getLocationLabel(location);
    const lastCheckin = location.last_checkin;
    const longestLine = location.longest_line;
    const lastReported = `${lastCheckin.wait_time} (${moment(
      lastCheckin.time
    ).format('LT')})`;
    const longestToday =
      // longestLine is not necessarily defined, in cases where the only
      // check-ins are of “complete”
      longestLine &&
      `${longestLine.wait_time} (${moment(longestLine.time).format('LT')})`;

    return (
      <tr title={locationLabel} key={`location-${key}`}>
        <td key="reported-location">
          <Link
            to={{
              pathname: '/issues',
              search: queryToSearch({
                location: location.id,
                state: location.State,
                county: location.county_slug,
              }),
            }}
            className="dashboard-location-link"
          >
            {locationLabel}
          </Link>
        </td>

        <td key="reported-state">{location.State}</td>

        <td key="reported-last">{lastReported}</td>

        <td key="reported-longest">{longestToday}</td>

        {tableHeaderValues.map((headerValue, key) => {
          const cellValue =
            headerValue === 'Location'
              ? locationLabel
              : location[headerValue] || '-';

          return (
            <td
              style={this.getCellStyleProps(cellValue)}
              key={`reported-${key}`}
            >
              {cellValue}
            </td>
          );
        })}
      </tr>
    );
  }

  render() {
    const { results, paginationData, setPage } = this.props;

    return (
      <PaginationWrapper paginationData={paginationData} setPage={setPage}>
        <div className="lbj-table-wrapper">
          <table className="lbj-table lbj-issue-list-table dashboard-table">
            <thead>
              <tr>
                {this.renderTableHeaderCell('Location', 'location')}
                {this.renderTableHeaderCell('State', 'state')}
                {this.renderTableHeaderCell('Last Reported', 'last-reported')}
                {this.renderTableHeaderCell('Longest Today', 'longest-today')}
                {this.getHeaderValues().map((val, idx) =>
                  this.renderTableHeaderCell(val, idx)
                )}
              </tr>
            </thead>

            <tbody>
              {results.map((r) => this.renderLocationCell(r, r.id))}
            </tbody>
          </table>

          {results.length === 0 && (
            <EmptyAlert
              header="There are no line length reports to show."
              description="Update the filters to check on another query."
            />
          )}
        </div>
      </PaginationWrapper>
    );
  }
}
