/**
 * Pedantic regex to match an email anywhere in a string.
 */
export const EMAIL_REGEX_STR = [
  '(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>(),[\\]\\.,;:\\s@\\"]+)*)',
  '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
  '[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+',
  '[a-zA-Z]{2,}))',
].join('');

/**
 * Regex for matching exactly an email.
 */
const IS_EMAIL_REGEX = new RegExp(`^${EMAIL_REGEX_STR}$`);

/**
 * Returns true if the string passed is exactly an email address.
 */
export function isValidEmail(s) {
  return IS_EMAIL_REGEX.test(s || '');
}
