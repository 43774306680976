import React from 'react';
import { useLabel, VisuallyHidden } from 'react-aria';

import { ActionButton, ActionButtonType } from './ActionButton';

/**
 * Action button that displays a file picker.
 *
 * Implemented as an inline-block label that wraps a hidden `<input type="file" />`
 * element.
 */
export const FileActionButton: React.FunctionComponent<{
  inputRef?: React.ForwardedRef<HTMLInputElement> | undefined;
  role?: ActionButtonType | undefined;
  accept?: string | undefined;
  isDisabled?: boolean;
  multiple?: boolean | undefined;
  icon?: string;
  /** Called when the user selects one or more files. */
  onFiles?: (files: [File, ...File[]]) => void;
}> = ({
  inputRef,
  children,
  role,
  accept,
  isDisabled,
  multiple,
  icon,
  onFiles,
}) => {
  const { labelProps, fieldProps } = useLabel({ label: children });

  return (
    <ActionButton
      role={role}
      isDisabled={isDisabled ?? false}
      onPress={() => {}}
      elementType="label"
      elementProps={{
        // Overrides LBJ default <label> styles
        className: 'mb-0 inline-block',
        ...labelProps,
      }}
      icon={icon ? icon : ''}
    >
      {children}

      <VisuallyHidden>
        <input
          type="file"
          ref={inputRef}
          accept={accept}
          multiple={multiple}
          onChange={(ev) => {
            const fileList = ev.currentTarget.files;

            if (fileList) {
              const files: File[] = [];
              for (let i = 0; i < fileList.length; ++i) {
                files.push(fileList.item(i)!);
              }

              if (files.length) {
                onFiles?.(files as [File, ...File[]]);
                ev.currentTarget.value = '';
              }
            }
          }}
          {...fieldProps}
        />
      </VisuallyHidden>
    </ActionButton>
  );
};
