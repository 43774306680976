import Cookie from 'js-cookie';

const cookieName = 'apiauth';
const path = '/';
const expires = 30; // in days

export type AuthCookieValue = {
  token: string;
};

export default {
  get(): AuthCookieValue | false {
    const cookieStr = Cookie.get(cookieName);

    if (cookieStr) {
      try {
        const cookieData = JSON.parse(cookieStr);

        if (cookieData.token) {
          return { token: cookieData.token.toString() };
        }
      } catch (err) {
        /** TODO: log this **/
      }
    }

    return false;
  },

  set(data: AuthCookieValue) {
    const secure = process.env['NODE_ENV'] === 'production';

    return Cookie.set(cookieName, data, { expires, path, secure });
  },

  clear() {
    return Cookie.remove(cookieName, { path });
  },
};
