import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import LoadingOverlay from '../lbj/loading';

export default class EditorSearchResults extends Component {
  static propTypes = {
    listData: PropTypes.object.isRequired,
    isLoadingList: PropTypes.bool.isRequired,
    onGetMoreSearchResults: PropTypes.func.isRequired,
    listElementRenderer: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      listSize: 25,
    };
  }

  renderList() {
    const { listData, listElementRenderer } = this.props;
    const { listSize } = this.state;

    return (
      <div>
        <ul className="assignment-creation-result-list">
          {listData.slice(0, listSize).map(listElementRenderer)}
          {RIT(
            listData.count() >= listSize,
            this.renderMoreResultsLink.bind(this)
          )}
        </ul>
      </div>
    );
  }

  renderMoreResultsLink() {
    return (
      <a
        className="assignment-search-results-more-link"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          const { listSize } = this.state;

          this.props.onGetMoreSearchResults({ offset: listSize });
          this.setState({ listSize: listSize + 25 });
        }}
      >
        Show more
      </a>
    );
  }

  renderEmptyState() {
    return <div className="no-results">No results found</div>;
  }

  render() {
    const { listData, isLoadingList } = this.props;
    const noResults = listData.isEmpty();

    return (
      <div>
        <div className="assignment-creation-search-results">
          {RIT(!noResults, this.renderList.bind(this))}
          {RIT(noResults, this.renderEmptyState.bind(this))}
          {RIT(isLoadingList, () => (
            <LoadingOverlay />
          ))}
        </div>
      </div>
    );
  }
}
