import React from 'react';

import ExportModal from '../../components/presentational/assignment/assignment-export-modal';
import { ToastData } from '../../modules/toast';

export class UnconnectedIssueListExport extends React.Component<
  {
    canExport: boolean;
    isExporting: boolean;
    showToast: (data: ToastData) => void;
    startExport: () => Promise<string | null>;
  },
  {
    showExportIssuesModal: boolean;
  }
> {
  constructor(props: UnconnectedIssueListExport['props']) {
    super(props);

    this.state = {
      showExportIssuesModal: false,
    };

    this.onClick = this.onClick.bind(this);
    this.renderExportIssuesModal = this.renderExportIssuesModal.bind(this);
    this.handleCloseExportIssuesModal =
      this.handleCloseExportIssuesModal.bind(this);
    this.handleExportIssues = this.handleExportIssues.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClick(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({ showExportIssuesModal: true });
  }

  // Used so we don’t trigger a CSV download after we leave the page.
  private _isMounted = false;

  handleExportIssues() {
    const { isExporting, showToast, startExport } = this.props;
    const download = this.download.bind(this);

    if (isExporting) {
      return;
    }

    startExport().then((url) => {
      if (!this._isMounted) {
        return;
      }

      if (url) {
        download(url);
        this.handleCloseExportIssuesModal();
      } else {
        const toastData: ToastData = {
          type: 'error',
          message: `There was an error exporting this data.`,
        };
        showToast(toastData);
      }
    });
  }

  handleCloseExportIssuesModal() {
    this.setState({
      showExportIssuesModal: false,
    });
  }

  download(url: string) {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = 'issues.csv';
      link.href = url;
      link.click();
      document.body.removeChild(link);
    } else {
      location.replace(url);
    }
  }
  renderExportIssuesModal() {
    const { isExporting } = this.props;

    return (
      <ExportModal
        isPending={isExporting}
        isVisible={this.state.showExportIssuesModal}
        onAccept={this.handleExportIssues}
        onRequestClose={this.handleCloseExportIssuesModal}
        type={'issues'}
      />
    );
  }

  render() {
    const { canExport } = this.props;

    if (!canExport) {
      return null;
    }

    return (
      <div>
        <button
          className="lbj-csv-export lbj-button-blue"
          onClick={this.onClick}
        >
          CSV
        </button>

        {this.state.showExportIssuesModal && this.renderExportIssuesModal()}
      </div>
    );
  }
}
