import { lbjAppSections } from '../../constants';

const DEFAULT_START_TIME = '08:00:00';
const DEFAULT_CHANGE_TIME = '12:00:00';
const DEFAULT_END_TIME = '20:00:00';

export default function (state) {
  const { user } = state;
  const currentElection = user.getIn(['currentUser', 'currentUserElection']);
  const filters = state.filters.get(lbjAppSections.ASSIGNMENT);
  const date = filters.get('dates');

  let defaultStartTime = DEFAULT_START_TIME;
  let defaultChangeTime = DEFAULT_CHANGE_TIME;
  let defaultEndTime = DEFAULT_END_TIME;

  if (currentElection) {
    const election = currentElection.get('election');
    const earlyVoteDays = election
      .get('days')
      .filter((day) => day.get('is_early_vote'));
    const electionDays = election
      .get('days')
      .filter((day) => !day.get('is_early_vote'));
    const todayIsEarlyVote = !!earlyVoteDays.find(
      (dateObject) => dateObject.get('date') === date
    );
    const todayIsElectionDay = !!electionDays.find(
      (dateObject) => dateObject.get('date') === date
    );

    if (todayIsEarlyVote) {
      defaultStartTime =
        election.get('ev_shift_start_time') || DEFAULT_START_TIME;
      defaultChangeTime =
        election.get('ev_shift_change_time') || DEFAULT_CHANGE_TIME;
      defaultEndTime = election.get('ev_shift_end_time') || DEFAULT_END_TIME;
    } else if (todayIsElectionDay) {
      defaultStartTime =
        election.get('eday_shift_start_time') || DEFAULT_START_TIME;
      defaultChangeTime =
        election.get('eday_shift_change_time') || DEFAULT_CHANGE_TIME;
      defaultEndTime = election.get('eday_shift_end_time') || DEFAULT_END_TIME;
    }
  }
  return { defaultStartTime, defaultChangeTime, defaultEndTime };
}
