import { Query } from 'history';
import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IssueClass,
  IssueStatus,
  requestStatuses,
  UserRole,
  US_NATIONAL_STATE_CODE,
} from '../../constants';
import { AppDispatch, AppState } from '../../modules/flux-store';
import { actionCreators as issueActionCreators } from '../../modules/issue';
import {
  DocumentUploadData,
  IssueErrorData,
  NewIssueInProgress,
  PhotoUploadData,
} from '../../modules/issue/reducers';
import { ToastRecord } from '../../modules/toast';
import { CurrentUser } from '../../modules/user/action-creators';
import { ApiNewIssue, updateIssue } from '../../services/issue-service';
import { ApiElection } from '../../services/lbj-shared-service';
import {
  getInitialFields,
  InitialFields,
} from '../../utils/issue/initial-fields';
import mapStateToIssueFilters from '../../utils/issue/map-state-to-issue-filters';
import { mapIssueFiltersToQueryParams } from '../../utils/issue/query-params';
import { renderErrorJs } from '../../utils/render-error';
import { queryToSearch, searchToQuery } from '../../utils/routing-provider';
import { MapFromJs } from '../../utils/types';

import IssueDetailForm, {
  CommonIssueFormActions,
  makeCommonIssueFormActions,
} from './issue-detail-form';
import { getRequiredFields } from './issue-utils';

type NewIssueActions = {
  createIssue: (
    issue: ApiNewIssue
  ) => Promise<issueActionCreators.CreateIssueAction>;
};

type NewIssueForm = InitialFields & {
  type?: IssueClass;
  voter_phone_number?: string;
  voter_email?: string;
  documents?: DocumentUploadData[];
  photos?: PhotoUploadData[];

  category: ApiNewIssue['category'] | null;
  category_2: ApiNewIssue['category_2'] | null;
  category_3: ApiNewIssue['category_3'] | null;
  sub_category: ApiNewIssue['sub_category'] | null;
  sub_category_2: ApiNewIssue['sub_category_2'] | null;
  sub_category_3: ApiNewIssue['sub_category_3'] | null;
};

export const NewIssueContainer: React.FunctionComponent<
  {
    canEditState: boolean;
    currentUserData: MapFromJs<CurrentUser>;
    electionIsTest: boolean;
    issueQueryParams: Query;
    errorData: IssueErrorData;
    requestIsPending: boolean;
    /**
     * This is distinct from the logged-in election because when you’re logged in to a permanent election the system will
     * try to post issues into the current general election for your selected state.
     */
    issueElectionId: number | null;
    toastData: ToastRecord;
    commonActions: CommonIssueFormActions;
  } & NewIssueActions
> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userId: number = props.currentUserData.get('id');
  const userRole: UserRole = props.currentUserData.get('role');

  const [formType, setFormType] = React.useState<'poll_observer' | 'hotline'>(
    userRole === 'poll_observer' ? 'poll_observer' : 'hotline'
  );

  const [commentText, setCommentText] = React.useState('');

  const [numCategories, setNumCategories] = React.useState(1);

  const [updatedFields, setUpdatedFields] = React.useState<NewIssueForm>({
    ...getInitialFields(props.currentUserData),
    category: null,
    category_2: null,
    category_3: null,
    sub_category: null,
    sub_category_2: null,
    sub_category_3: null,
  });

  /**
   * “Adding to a record” means marking the current user as a watcher on the
   * issue, then jumping to the issue’s page so that they can add new comments
   * and such.
   */
  async function onAddToRecord(phoneNumber: string, issueId: number) {
    try {
      await updateIssue(
        issueId,
        {
          subscribed: true as const,
          id: issueId,
        },
        {
          voter_phone_number__exact: phoneNumber,
        }
      );

      navigate('/issues/' + issueId);
    } catch {
      props.commonActions.showToast({
        type: 'error',
        message: 'There was an error adding to that record.',
      });
    }
  }

  function onCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setCommentText(value);
  }

  function onDocumentAdd(document: DocumentUploadData) {
    setUpdatedFields((prev) => ({
      ...prev,
      documents: [...(prev.documents ?? []), document],
    }));
  }

  function onDocumentDelete(document: DocumentUploadData) {
    setUpdatedFields((prev) => {
      const documents = [...(prev.documents ?? [])];
      const documentIdx = documents?.indexOf(document);

      if (documentIdx >= 0) {
        documents.splice(documentIdx, 1);
      }

      return {
        ...prev,
        documents,
      };
    });
  }

  function onPhotoAdd(photo: PhotoUploadData) {
    setUpdatedFields((prev) => ({
      ...prev,
      photos: [...(prev.photos ?? []), photo],
    }));
  }

  function onPhotoDelete(photo: PhotoUploadData) {
    setUpdatedFields((prev) => {
      const photos = [...(prev.photos ?? [])];
      const photoIdx = photos.indexOf(photo);

      if (photoIdx >= 0) {
        photos.splice(photoIdx, 1);
      }

      return {
        ...prev,
        photos,
      };
    });
  }

  function onInputChange(event: {
    target: {
      name: string;
      value: string | number | boolean | null | undefined;
    };
  }) {
    const { name } = event.target;
    let { value } = event.target;

    setUpdatedFields((prev) => {
      if (name === 'requires_followup') {
        value = !prev[name];
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function onSetFields(changes: { [field in keyof NewIssueInProgress]?: any }) {
    setUpdatedFields((prev) => {
      return {
        ...prev,
        ...changes,
      };
    });
  }

  function onFormTypeToggle(e: React.MouseEvent) {
    e.preventDefault();

    setFormType((oldType) =>
      oldType === 'poll_observer' ? 'hotline' : 'poll_observer'
    );
  }

  function getIssueData(): NewIssueInProgress {
    return {
      // This is seeded with what you get from mapStateToIssuePayload when the
      // current issue has been cleared, which is what happens when you visit
      // this route.
      comments: [],
      county: null,
      documents: [],
      location: '',
      photos: [],
      precinct: '',
      user: '',

      source: undefined,
      created_by: undefined,
      issue_election_id: undefined,

      type: undefined,

      ...updatedFields,
    };
  }

  async function postNewIssue(status: IssueStatus) {
    const { issueElectionId: issueElection } = props;
    const { SUCCESS, ERROR } = requestStatuses;

    const comments: ApiNewIssue['comments'] = [];

    if (commentText) {
      comments.push({
        text: commentText,
      });
    }

    if (
      updatedFields.requires_followup &&
      !(updatedFields.voter_phone_number || updatedFields.voter_email)
    ) {
      const errorData = {
        error_message:
          'If the issue requires followup, you must provide a phone number or email address.',
      };
      const errorMessage = renderErrorJs(errorData, 'creating');
      props.commonActions.showToast(errorMessage);

      return;
    }

    if (issueElection === null) {
      const errorData = {
        error_message:
          'Could not create issue because no election was found for the current state',
      };
      const errorMessage = renderErrorJs(errorData, 'creating');
      props.commonActions.showToast(errorMessage);

      return;
    }

    const issuePayload: ApiNewIssue = {
      ...updatedFields,

      // These will exist because we check required fields.
      state: updatedFields.state!,
      type: updatedFields.type!,

      status,

      created_by: userId,
      issue_election_id: issueElection,
      source: formType,
      comments,
    };

    const { data } = await props.createIssue(issuePayload);
    const isMobile = !!searchToQuery(location.search)['mobile'];

    switch (data.status) {
      case SUCCESS: {
        const id = data.issueResponse.id;
        const locationId = data.issueResponse.location;
        if (isMobile && locationId) {
          navigate({
            pathname: '/checkin',
            search: queryToSearch({ location: locationId }),
          });
        } else if (isMobile) {
          navigate('/checkin');
        } else {
          navigate(`/issues/${id}`);
        }
        props.commonActions.showToast({
          type: 'success',
          message: `Issue #${id} is submitted. Thank you for helping protect voter rights!`,
        });
        break;
      }

      case ERROR: {
        const errorMessage = renderErrorJs(data.errorData, 'creating');
        props.commonActions.showToast(errorMessage);
        break;
      }
    }
  }

  const {
    currentUserData,
    issueQueryParams,
    errorData,
    issueElectionId,
    canEditState,
    requestIsPending,
    electionIsTest,
    commonActions,
    toastData,
  } = props;

  const issueData = getIssueData();

  const requiredFields = getRequiredFields(
    formType,
    updatedFields.type ?? null,
    numCategories
  );

  const featureFlags =
    searchToQuery(location.search)['feature']?.split('|') ?? [];

  return (
    <IssueDetailForm
      action={'create'}
      currentUserData={currentUserData}
      issueData={issueData}
      issueElectionId={issueElectionId}
      issueQueryParams={issueQueryParams}
      toastData={toastData}
      onInputChange={onInputChange}
      onIssueSubmit={postNewIssue}
      addToIssueRecord={onAddToRecord}
      errorData={errorData}
      onCommentChange={onCommentChange}
      // Can’t post comments on the new issue form
      onCommentAdd={() => {}}
      onFormTypeToggle={onFormTypeToggle}
      onDocumentAdd={onDocumentAdd}
      onDocumentDelete={onDocumentDelete}
      onPhotoAdd={onPhotoAdd}
      onPhotoDelete={onPhotoDelete}
      commentText={commentText}
      // Photos and documents are posted with the issue itself (Base64 embedded
      // in the JSON) so they’re never uploading separately.`
      documentUploadIsPending={false}
      photoUploadIsPending={false}
      isFormTypeVisible={
        // These users are locked into the form type based on their role.
        //
        // TODO(fiona): Change this to be based on their latest assignment,
        // since the same user can do both poll observer and hotline worker
        // shifts.
        userRole !== 'poll_observer' &&
        userRole !== 'hotline_worker' &&
        userRole !== 'hotline_manager'
      }
      isStateReadOnly={!canEditState}
      isVoterImpactedReadOnly={false}
      isDescriptionReadOnly={false}
      onSetFields={onSetFields}
      requestIsPending={requestIsPending}
      electionIsTest={electionIsTest}
      formType={formType}
      requiredFields={Immutable.List(requiredFields)}
      numCategories={numCategories}
      setNumCategories={setNumCategories}
      commonActions={commonActions}
      featureFlags={featureFlags}
    />
  );
};

export default connect(
  (state: AppState) => {
    const { issue, user } = state;

    // If you’re seeing this page, there’s a current user and current UserElection.
    const currentUserData = user.currentUser.userData!;
    const loggedInElection = user.currentUser
      .currentUserElection!.get('election')
      .toJS() as ApiElection;
    const issueFilters = mapStateToIssueFilters(state);
    const queryParams = mapIssueFiltersToQueryParams(issueFilters.toJS());
    const issueElectionId = issue.issueElectionRequest.electionId;

    // We look at the `updatingIssue` state because that’s where the reducer
    // stores the status and errors for _all_ issue CRUD requests, not just
    // updates.
    const { errorData, requestIsPending } = issue.updatingIssue;

    const props: Omit<
      React.ComponentProps<typeof NewIssueContainer>,
      'commonActions' | keyof NewIssueActions
    > = {
      currentUserData,
      electionIsTest: !!loggedInElection.is_test,
      issueQueryParams: queryParams,
      errorData,
      requestIsPending,
      canEditState: loggedInElection.state === US_NATIONAL_STATE_CODE,
      issueElectionId,
      toastData: issue.toastData,
    };

    return props;
  },
  (
    dispatch: AppDispatch
  ): { commonActions: CommonIssueFormActions } & NewIssueActions => ({
    commonActions: makeCommonIssueFormActions(dispatch),

    createIssue: (payload) =>
      dispatch(issueActionCreators.createIssueAsync(payload)),
  })
)(NewIssueContainer);
