import React from 'react';
import { Item, Section } from 'react-stately';

import { ActionButton, Menu } from '../../../components/form';
import { LocationGroupBy, LocationSortBy } from '../assignment-utils';

/**
 * Button for altering the grouping and sorting of locations.
 */
const LocationGroupAndSort: React.FunctionComponent<{
  isDisabled?: boolean | undefined;
  groupBy: LocationGroupBy;
  sortBy: LocationSortBy;
  setGroupBy: (groupBy: LocationGroupBy) => void;
  setSortBy: (sortBy: LocationSortBy) => void;
  disabledGroupByOptions: LocationGroupBy[];
  disabledSortByOptions: LocationSortBy[];
}> = ({
  isDisabled = false,
  groupBy,
  sortBy,
  setGroupBy,
  setSortBy,
  disabledGroupByOptions,
  disabledSortByOptions,
}) => (
  <Menu
    trigger={(props, ref) => (
      <ActionButton
        icon="calendar_view_month"
        role="toolbar"
        buttonRef={ref}
        isDisabled={isDisabled}
        {...props}
      >
        Group &amp; Sort
      </ActionButton>
    )}
    onAction={(key) => {
      if (typeof key !== 'string') {
        return;
      }

      const [kind, value] = key.split('.');
      switch (kind) {
        case 'group':
          setGroupBy(value as LocationGroupBy);
          break;

        case 'sort':
          setSortBy(value as LocationSortBy);
          break;
      }
    }}
    closeOnSelect={false}
    selectionStyle="checkmark"
    selectedKeys={[`group.${groupBy}`, `sort.${sortBy}`]}
    selectionMode="multiple"
    disabledKeys={[
      ...disabledGroupByOptions.map((o) => `group.${o}`),
      ...disabledSortByOptions.map((o) => `sort.${o}`),
    ]}
  >
    <Section key="group" title="Group By">
      <Item key="group.none">None</Item>
      <Item key="group.tier">Tier</Item>
      <Item key="group.county">County</Item>
      <Item key="group.city">City</Item>
      <Item key="group.zip">ZIP Code</Item>
    </Section>

    <Section key="sort" title="Sort By">
      <Item key="sort.ranking">Ranking</Item>
      <Item key="sort.name">Name</Item>
    </Section>
  </Menu>
);

export default LocationGroupAndSort;
