/**
 * Returns the string version of a number with commas in the thousands place.
 *
 * Has an optional `precision` option that includes a fixed number of decimal
 * places.
 */
export function numberToStringWithCommas(
  num: number,
  opts: { precision?: number | undefined } = {}
): string {
  const out: string[] = [];

  let negative = false;
  if (num < 0) {
    num = -num;
    negative = true;
  }

  let decimal = '';

  // Check for a fractional element
  if (num % 1 !== 0) {
    // If precision is exactly 0 then we don’t want to show any decimal point or
    // anything after it.
    if (opts.precision !== 0) {
      decimal = (num % 1)
        .toString()
        .substring(
          // 1 to get rid of the leading "0"
          1,
          // + 2 to account for the leading "0" and "."
          opts.precision !== undefined ? opts.precision + 2 : undefined
        )
        // Add extra zeros to get to precision. + 1 to account for the decimal.
        .padEnd((opts.precision ?? 0) + 1, '0');
    }
    num = Math.floor(num);
  }

  do {
    let str = (num % 1000).toString();
    num = Math.floor(num / 1000);

    if (num > 0) {
      str = str.padStart(3, '0');
    }

    out.unshift(str);
  } while (num > 0);

  return (negative ? '-' : '') + out.join(',') + decimal;
}
