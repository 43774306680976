import { State } from '../constants';
import authCookie from '../utils/auth-cookie';

import ApiClient from './api-client';
import * as LocalStoreService from './local-store-service';

export type AuthTokenResponse = { token: string };

/**
 * Parses groundwork login response and sets the apiauth cookie.
 */
function storeLoginCookie(response: AuthTokenResponse) {
  authCookie.set({ token: response.token });
}

export type GoogleTokenCredentials = {
  googleToken: string;
};

/**
 * Login to auth service with google token
 */
export async function loginWithGoogleToken(
  credentials: GoogleTokenCredentials
) {
  const resp = await ApiClient<AuthTokenResponse>(
    'get_auth_token',
    'POST',
    {},
    {},
    credentials
  );

  storeLoginCookie(resp);
}

/**
 * Login to auth service with email and nonce. If successful, sets a login
 * cookie so that future API requests are authenticated.
 */
export async function loginWithPasswordless(loginCode: string) {
  const resp = await ApiClient<AuthTokenResponse>(
    'get_auth_token',
    'POST',
    {},
    {},
    { nonce: loginCode }
  );

  storeLoginCookie(resp);
}

/**
 * Just clears auth cookie and local data. Callers should redirect or otherwise
 * reload after calling this, as the app is not guaranteed to support recovering
 * from this state.
 */
export function clearLoginData() {
  authCookie.clear();
  LocalStoreService.purgeAll();
}

/**
 * Clears our login cookie, completely resets local storage, and reloads the
 * page (which should lead to the login form).
 */
export function logout() {
  clearLoginData();
  window.location.reload();
}

/**
 * Check for GW cookie
 */
export function isLoggedIn() {
  return !!authCookie.get();
}

/**
 * Used to indicate that the user is trying to log in to the “Westeros” test
 * election.
 *
 * See LBJ-248
 */
export const WESTEROS_STATE_CODE = 'westeros';

export type ApiSendLoginEmailPayload = {
  email?: string;
  state: State | typeof WESTEROS_STATE_CODE;
  emailToken?: string;
};

export type ApiSendLoginSuccessResponse = {};

export type ApiSendLoginEmailErrorCode =
  | 'EMAIL_NOT_FOUND'
  | 'NO_ELECTIONS'
  | 'WRONG_STATE'
  | 'ALTERNATE_EMAIL'
  | 'NO_NONCE';

export type ApiSendLoginErrorResponse =
  | {
      code: Exclude<ApiSendLoginEmailErrorCode, 'ALTERNATE_EMAIL'>;
      errorMessage?: string;
    }
  | {
      code: 'ALTERNATE_EMAIL';
      errorMessage?: undefined;
      email: string;
      emailToken: string;
    };

export type SendLoginEmailResult =
  | { status: 'success' }
  | { status: 'error'; error: ApiSendLoginErrorResponse };

export async function sendLoginEmail(
  email: string,
  state: State | typeof WESTEROS_STATE_CODE,
  emailToken?: string
): Promise<SendLoginEmailResult> {
  const payload: ApiSendLoginEmailPayload = emailToken
    ? {
        emailToken,
        state,
      }
    : {
        email,
        state,
      };

  try {
    await ApiClient('users/login_email/', 'POST', {}, {}, payload);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'error', error };
  }
}
