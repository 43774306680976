import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import mapStateToAssignmentFilters from '../../../utils/assignment/map-state-to-assignment-filters';
import { loadGoogleMaps } from '../../../utils/google-maps';
import { queryToSearch } from '../../../utils/routing-provider';
import GoogleMapContainer from '../../containers/maps/google-map-container';

@connect((state) => {
  return {
    filters: mapStateToAssignmentFilters(state),
  };
})
export default class LocationDetailsV2 extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      mapExpanded: false,
      googleMaps: null,
    };

    loadGoogleMaps().then((googleMaps) => this.setState({ googleMaps }));
  }

  removeOffSetFilter(filters) {
    return _.omit(filters.toJS(), ['offset', 'size']);
  }

  renderMap() {
    const { filters, location } = this.props;
    const { mapExpanded, googleMaps } = this.state;
    const filtersWithoutOffset = this.removeOffSetFilter(filters);
    const precinctArgs = Object.assign(filtersWithoutOffset, {
      view: 'precincts',
      location: location.get('id'),
    });
    const isGeoCoded = location.hasIn(['coordinates', 0]);

    const showHide = (
      <button
        className="location-map-buttons__showhide link-button"
        onClick={() => this.setState({ mapExpanded: !mapExpanded })}
      >
        {mapExpanded ? '- Hide Map' : '+ See Map'}
      </button>
    );

    return (
      <div>
        {mapExpanded && googleMaps && (
          <GoogleMapContainer
            center={location.get('coordinates')}
            googleMaps={googleMaps}
          />
        )}
        <div className="location-map-buttons">
          {isGeoCoded && showHide}
          <Link
            className="location-map-buttons__precincts link-button"
            to={{
              pathname: '/assignments',
              search: queryToSearch(precinctArgs),
            }}
          >
            Precincts Nearby
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const { location } = this.props;

    return (
      <div className="assignment-location-details-v2">
        <ul className="lbj-detail-list">
          <li>
            <h4 className="font-bold">{location.get('name')}</h4>
          </li>
          <li>
            {location.get('address')}
            <br />
            {location.get('address_line_2')}{' '}
            {location.get('address_line_2') ? <br /> : null}
            {location.get('city')}, {location.getIn(['county', 'state'])}{' '}
            {location.get('zipcode')}
          </li>
          <li>{location.getIn(['county', 'name'])} County</li>
        </ul>
        {this.renderMap()}
      </div>
    );
  }
}
