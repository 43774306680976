import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import AddButton from '../form/add-button';

import AssignmentTimeline from './assignment-timeline';

export default class AssignmentRelatedObject extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    assignments: PropTypes.object.isRequired,
    onShowResourceDetails: PropTypes.func.isRequired,
    onOpenNewAssignmentEditor: PropTypes.func.isRequired,
    onOpenExistingAssignmentEditor: PropTypes.func.isRequired,
    parentEntityClass: PropTypes.string,
    isShowingDetails: PropTypes.bool,
    isAssigning: PropTypes.bool,
    pendingAssignment: PropTypes.object,
  };

  renderAssignment(assignment, key) {
    const { onOpenExistingAssignmentEditor, isAssigning, pendingAssignment } =
      this.props;
    const isEditingAssignment =
      isAssigning &&
      !!pendingAssignment &&
      pendingAssignment.get('id') === assignment.get('id');

    return (
      <AssignmentTimeline
        key={key}
        isAssigning={isEditingAssignment}
        assignmentData={assignment}
        isExistingAssignment
        onClick={() => onOpenExistingAssignmentEditor(assignment)}
      />
    );
  }

  renderEmptyAssignment() {
    const { onOpenNewAssignmentEditor, isAssigning } = this.props;

    return (
      <AssignmentTimeline
        isAssigning={isAssigning}
        onClick={onOpenNewAssignmentEditor}
        isExistingAssignment={false}
      />
    );
  }

  renderAddNewAssignmentButton() {
    return (
      <AddButton
        title="Add another assignment"
        onClick={this.props.onOpenNewAssignmentEditor}
      />
    );
  }

  render() {
    const {
      label,
      assignments,
      parentEntityClass,
      onShowResourceDetails,
      isShowingDetails,
      isAssigning,
      pendingAssignment,
    } = this.props;
    const noAssignments = assignments.isEmpty();
    const containerClassName = cx(
      `assignment-related-object-row ${parentEntityClass}`,
      {
        'is-assigning': isAssigning || isShowingDetails,
      }
    );

    return (
      <div className={containerClassName}>
        <div className="row">
          <div className="col-small-11 assignment-cols">
            <div className="assignment-add-button-container">
              {RIT(
                !noAssignments,
                this.renderAddNewAssignmentButton.bind(this)
              )}
            </div>
            <div
              className="assignment-related-object-secondary-label"
              onClick={onShowResourceDetails}
            >
              {label}
            </div>
            <div className="assignment-timelines">
              {RIT(noAssignments, this.renderEmptyAssignment.bind(this))}
              {RIT(!noAssignments, () => {
                return assignments.map(this.renderAssignment.bind(this));
              })}
              {RIT(
                isAssigning && !pendingAssignment && assignments.count() > 0,
                this.renderEmptyAssignment.bind(this)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
