import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class Sidebar extends Component {
  static propTypes = {
    title: PropTypes.node,
    subTitle: PropTypes.node,
    collapsible: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    isCollapsed: PropTypes.bool,
    onOpenFromForcedCollapse: PropTypes.func,
    showMemorial: PropTypes.bool,
  };

  static defaultProps = {
    showMemorial: true,
  };

  constructor(props) {
    super(props);

    if (props.collapsible) {
      this.state = {
        isCollapsedLarge: false,
        isCollapsedSmall: true,
      };
    } else {
      this.state = {};
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      Object.prototype.hasOwnProperty.call(nextProps, 'isCollapsed') &&
      nextProps.isCollapsed
    ) {
      const key =
        window.innerWidth < 840 ? 'isCollapsedSmall' : 'isCollapsedLarge';
      const newState = {};

      newState[key] = true;
      this.setState(newState);
    }
  }

  toggle() {
    const { onOpenFromForcedCollapse } = this.props;
    const key =
      window.innerWidth < 840 ? 'isCollapsedSmall' : 'isCollapsedLarge';
    const newState = {};

    newState[key] = !this.state[key];

    this.setState(newState);

    if (onOpenFromForcedCollapse) {
      onOpenFromForcedCollapse();
    }
  }

  renderMemorial() {
    return (
      <div className="lbj-memorial">
        <p className="lbj-memorial-content">In loving memory of Seth Rich</p>
      </div>
    );
  }

  render() {
    const { title, subTitle, collapsible, children, className, showMemorial } =
      this.props;
    const { isCollapsedLarge, isCollapsedSmall } = this.state;
    const onTitleClick = collapsible ? this.toggle.bind(this) : null;
    const containerClassName = cx(`lbj-sidebar ${className}`, {
      'lbj-sidebar-collapsible': collapsible,
      'is-collapsed-large': isCollapsedLarge,
      'is-collapsed-small': isCollapsedSmall,
      'is-showing-memorial': showMemorial,
    });

    return (
      <div className={containerClassName}>
        {RIT(title || collapsible, () => {
          return (
            <div
              className="lbj-sidebar-title lbj-column-label"
              onClick={onTitleClick}
            >
              {RIT(collapsible, () => {
                return <span className="lbj-sidebar-toggle"></span>;
              })}
              {RIT(title, () => {
                return (
                  <h4>
                    {title}
                    {subTitle}
                  </h4>
                );
              })}
            </div>
          );
        })}
        <div className="lbj-column-content-wrapper">
          {children}

          {RIT(showMemorial, this.renderMemorial)}
        </div>
      </div>
    );
  }
}
