import cx from 'classnames';
import { Immutable } from 'immer';
import moment from 'moment';
import React from 'react';
import { FocusScope, mergeProps, useButton, useFocusRing } from 'react-aria';

import { ActionButton, IconButton, TextButton } from '../../../components/form';
import { usePrecincts } from '../../../components/hooks/lbj-data';
import { ModalDialog } from '../../../components/layout';
import { ApiLocationHours } from '../../../services/assignment-service';

import { DateString, formatTime, TimeString } from '../../../services/common';
import {
  ApiLocationTierConfiguration,
  getLanguageIsoCode,
} from '../../../services/lbj-shared-service';
import { useStateWithDeps } from '../../../utils/hooks';
import { formatPhoneNumber } from '../../../utils/user/phone-numbers';
import { BulkEditModeType } from '../LocationAssignmentPage';
import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentUser,
} from '../assignment-state';
import {
  calculateAssignmentShiftCoverage,
  extractTextHours,
  getVolunteerAttributes,
  shiftRowsFromAssignments,
  volunteerLanguagesToLanguageNames,
} from '../assignment-utils';
import {
  describeDistanceMi,
  iconForDistanceMi,
  makeGoogleDirectionsUrl,
} from '../location-table/location-table-utils';

export type EditAssignmentRecord = {
  location: Omit<AssignmentLocation, 'hours'>;
  /** Specific {@link ApiLocationHours} for this location/day */
  hours: ApiLocationHours;
  assignment?: (AssignmentRecord & { type: 'poll' }) | undefined | null;
  defaultStartTime?: TimeString | undefined;
  defaultEndTime?: TimeString | undefined;
  defaultUserId?: number | undefined;
};

/**
 * Total number of precincts to show before truncating and offering the dialog.
 */
const MAX_PRECINCTS = 6;

/**
 * Component for the sidebar on the location assigments page that appears when
 * you click on a specific location/date.
 */
const LocationDayDetailPane: React.FunctionComponent<{
  electionId: number;
  /**
   * If locationId is null it means we opened the detail pane without selecting
   * a location, likely by clicking on the date header. */
  locationId: number | null;
  /**
   * If locationId is not null but location is, it means that we’re loading the location.
   */
  location: AssignmentLocation | null;
  date: DateString;
  /**
   * True if the date we’re looking at is an early vote date.
   */
  dateIsEv: boolean;
  /**
   * The users in our system. Immutable since this will come directly from
   * assignment state.
   */
  usersById: Immutable<Map<number, AssignmentUser>>;
  /** Assignments scoped to the selected date */
  assignmentsByLocationId: Map<number, (AssignmentRecord & { type: 'poll' })[]>;
  tierConfiguration: ApiLocationTierConfiguration[];
  bulkEditModeType: BulkEditModeType | null;
  onClose: () => void;
  onEditAssignment: (rec: EditAssignmentRecord) => void;
  onEditHours: (rec: {
    location: AssignmentLocation;
    hours: ApiLocationHours;
  }) => void;
  acceptAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  rejectAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  getDistanceMi: LookUpDistanceFn;
}> = ({
  electionId,
  locationId,
  location,
  date,
  dateIsEv,
  assignmentsByLocationId,
  usersById,
  tierConfiguration,
  bulkEditModeType,
  onEditAssignment,
  onEditHours,
  acceptAssignment,
  rejectAssignment,
  onClose,
  getDistanceMi,
}) => {
  return (
    // Brings focus into the sidebar when it opens.
    // TODO(fiona): Send focus back to the table when it closes?
    <FocusScope autoFocus>
      {/* negative left margin to put this underneath the table so that their borders
          merge into one solid line, no matter which is taller. (Table is given a higher z-index
          so that this doesn’t overlap when its “selected” outline spills over the border.) */}
      <section className="relative z-0 ml-[-1px] mr-4 flex w-[38%] min-w-[350px] max-w-[600px] flex-col items-stretch overflow-y-auto border-t border-r border-l border-gray-300 bg-white">
        <IconButton
          title="Close sidebar"
          icon="cancel"
          className="absolute top-4 right-4"
          onPress={onClose}
        />

        {location === null && (
          <>
            {/* When the sidebar is opened because of clicking on the day header */}
            {locationId === null && (
              <div className="flex flex-col gap-4 px-4">
                <h2 className="mt-24 text-center font-bold text-gray-700">
                  <div className="mb-0.5 text-center text-sm uppercase leading-none">
                    {moment(date).format('dddd')}
                  </div>

                  <div className="text-xl">
                    {/* non-breaking space between month and day */}
                    {moment(date).format('MMMM\u00A0D')}
                  </div>
                </h2>

                <div className="mt-10 text-center leading-normal">
                  Select a cell to the left to see details here.
                </div>
              </div>
            )}

            {/* If a location ID is selected but location is null then it hasn’t loaded yet.
              Technically shouldn’t happen in the course of things because locations need to
              load before you can interact with the page. */}
            {locationId !== null && (
              <div className="mt-24 flex flex-col">
                <h2 className="text-center text-lg font-bold text-gray-700">
                  Loading location…
                </h2>

                <div className="lbj-loading-icon" />
              </div>
            )}
          </>
        )}

        {location !== null && (
          <LocationDayDetailPaneContent
            electionId={electionId}
            location={location}
            date={date}
            dateIsEv={dateIsEv}
            assignmentsByLocationId={assignmentsByLocationId}
            usersById={usersById}
            tierConfiguration={tierConfiguration}
            bulkEditModeType={bulkEditModeType}
            onEditAssignment={onEditAssignment}
            onEditHours={onEditHours}
            acceptAssignment={acceptAssignment}
            rejectAssignment={rejectAssignment}
            getDistanceMi={getDistanceMi}
          />
        )}
      </section>
    </FocusScope>
  );
};

export default LocationDayDetailPane;

/**
 * Content of {@link LocationDayDetailPane} when there’s a selected location.
 */
const LocationDayDetailPaneContent: React.FunctionComponent<{
  electionId: number;
  location: AssignmentLocation;
  date: DateString;
  /**
   * True if the date we’re looking at is an early vote date.
   */
  dateIsEv: boolean;
  /**
   * The users in our system. Immutable since this will come directly from
   * assignment state.
   */
  usersById: Immutable<Map<number, AssignmentUser>>;
  /** Assignments scoped to the selected date */
  assignmentsByLocationId: Map<number, (AssignmentRecord & { type: 'poll' })[]>;
  tierConfiguration: ApiLocationTierConfiguration[];
  bulkEditModeType: BulkEditModeType | null;
  onEditAssignment: (rec: EditAssignmentRecord) => void;
  onEditHours: (rec: {
    location: AssignmentLocation;
    hours: ApiLocationHours;
  }) => void;
  acceptAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  rejectAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  getDistanceMi: LookUpDistanceFn;
}> = ({
  electionId,
  location,
  date,
  dateIsEv,
  assignmentsByLocationId,
  usersById,
  tierConfiguration,
  bulkEditModeType,
  onEditAssignment,
  onEditHours,
  acceptAssignment,
  rejectAssignment,
  getDistanceMi,
}) => {
  const hours = location.hours.find((h) => h.date === date);
  const assignments = assignmentsByLocationId.get(location.id) ?? [];

  const precincts = usePrecincts(electionId, {
    locationId: location.id,
  });

  const [showPrecinctsModal, setShowPrecinctsModal] = React.useState(false);

  return (
    <>
      {/* pr-8 leaves space for the close box, which is absolutely positioned in the upper right*/}
      <div className="mx-4 flex items-start gap-2 border-b border-gray-300 py-4 pr-8">
        <div className="flex flex-1 flex-col gap-2  text-sm leading-snug text-gray-700">
          <h2 className="text-lg font-bold text-gray-700">{location.name}</h2>

          <div>
            {location.address}, {location.city}, {location.county.state}{' '}
            {location.zipcode} – {location.county.name} County
          </div>

          {precincts !== undefined ? (
            <div>
              <strong>
                {precincts.length === 1 ? 'Precinct:' : 'Precincts:'}
              </strong>{' '}
              {precincts
                .slice(0, MAX_PRECINCTS)
                .map(({ name }) => name)
                .join(', ')}
              {precincts.length > MAX_PRECINCTS && (
                <>
                  {', and '}
                  <TextButton
                    size="unset"
                    className="cursor-pointer font-normal hover:underline"
                    onPress={() => setShowPrecinctsModal(true)}
                  >
                    {precincts.length - MAX_PRECINCTS} more…
                  </TextButton>
                </>
              )}
            </div>
          ) : (
            <strong>Loading precincts…</strong>
          )}

          {location.state_rank !== null && (
            // TODO(fiona): Include tier info
            <div>
              <strong>Location Rank:</strong> #{location.state_rank}
            </div>
          )}

          <div>
            <strong>Voting Hours:</strong>{' '}
            {extractTextHours(location, dateIsEv)}
            {/* (
              <a href="javascript:void()" className="text-accent">
                Where does this come from?
              </a>
              ) */}
          </div>
        </div>
      </div>

      {hours ? (
        <>
          {hours.closed && (
            <div className="mt-20 flex flex-col items-center justify-center gap-6 text-gray-700">
              <div className="material-icons text-[90px] leading-none text-gray-500">
                event_busy
              </div>

              <div className="center text-sm italic">
                Location is closed.{' '}
                <a href="javascript:void()" className="text-accent">
                  Edit
                </a>
              </div>
            </div>
          )}

          <div className="overflow-y-auto px-4 pt-4">
            {/* 
              We don’t show the shift table if the location is closed, unless there happen to be
              assignments made to it, just so those aren’t completely hidden.
            */}
            {(!hours.closed || assignments.length > 0) && (
              <ShiftTable
                location={location}
                hours={hours}
                usersById={usersById}
                assignments={assignments}
                tierConfiguration={tierConfiguration}
                onEditAssignment={onEditAssignment}
                acceptAssignment={acceptAssignment}
                rejectAssignment={rejectAssignment}
                getDistanceMi={getDistanceMi}
              />
            )}

            <div className="sticky bottom-0 flex items-center justify-between gap-4 bg-white py-4">
              <ActionButton
                onPress={() => onEditAssignment({ location, hours })}
                icon="person_add"
                iconPosition="start"
                role="secondary"
                isDisabled={hours.closed}
              >
                Assign Volunteer
              </ActionButton>

              <div className="text-center text-base leading-none">
                <TextButton
                  onPress={() => onEditHours({ location, hours })}
                  // Don’t allow the shifts dialog if we’re bulk editing, since
                  // it has unsaved state that would conflict with what this
                  // dialog shows.
                  isDisabled={bulkEditModeType === 'shifts'}
                >
                  <span className="material-icons align-middle text-lg leading-none">
                    edit_calendar
                  </span>{' '}
                  Configure shifts and times
                </TextButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        // We might be missing an `ApiLocationHours` record for this
        // location/date if the date is EV and the location is not, or the
        // date is EDay and the location is not, or if the election’s dates or
        // locations have recently changed and we’re waiting for the async
        // hours update process to complete.
        <div className="my-4 px-8 text-base leading-normal text-gray-700">
          {dateIsEv && !location.early_vote ? (
            <>
              This location is not activated for early vote. If this is
              incorrect, please contact{' '}
              <a href="mailto:lbj-help@dnc.org" className="text-accent">
                lbj-help@dnc.org
              </a>
              .
            </>
          ) : !dateIsEv && !location.election_day ? (
            <>
              This location is not activated for election day voting. If this is
              incorrect, please contact{' '}
              <a href="mailto:lbj-help@dnc.org" className="text-accent">
                lbj-help@dnc.org
              </a>
              .
            </>
          ) : (
            // This case shouldn’t really come up, but might if we’re in the
            // middle of changing early vote dates or something and the async
            // process hasn’t fully completed.
            <>
              We’re currently unable to add assignments to this location on this
              day. Please try again later. If this problem lasts more than an
              hour, contact{' '}
              <a href="mailto:lbj-help@dnc.org" className="text-accent">
                lbj-help@dnc.org
              </a>
              .
            </>
          )}
        </div>
      )}

      {showPrecinctsModal && (
        <ModalDialog
          doClose={() => setShowPrecinctsModal(false)}
          title={`Precincts for ${location.name}`}
          showClose
          aboveCenter
        >
          <div className="relative max-h-[600px] min-h-[300px] w-[600px] overflow-y-scroll">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="sticky top-0 bg-white">Name</th>
                  <th className="sticky top-0 bg-white">County</th>
                </tr>
              </thead>

              <tbody>
                {precincts?.map((precinct, idx) => (
                  <tr
                    key={precinct.id}
                    className={cx({
                      'bg-white': idx % 2 === 0,
                      'bg-gray-200': idx % 2 === 1,
                    })}
                  >
                    <td className="py-1">{precinct.name}</td>
                    <td className="py-1">{precinct.county.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalDialog>
      )}
    </>
  );
};

/**
 * 2-column grid of assignments and unassigned shifts.
 */
const ShiftTable: React.FunctionComponent<{
  location: AssignmentLocation;
  hours: ApiLocationHours;
  usersById: Immutable<Map<number, AssignmentUser>>;
  /** Assigments for this location on this day. */
  assignments: (AssignmentRecord & { type: 'poll' })[];
  tierConfiguration: ApiLocationTierConfiguration[];
  onEditAssignment: (rec: EditAssignmentRecord) => void;
  acceptAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  rejectAssignment: (assignment: AssignmentRecord & { type: 'poll' }) => void;
  getDistanceMi: LookUpDistanceFn;
}> = ({
  hours,
  usersById,
  assignments,
  location,
  tierConfiguration,
  onEditAssignment,
  acceptAssignment,
  rejectAssignment,
  getDistanceMi,
}) => {
  // Prevents reordering of assignments / ShiftBubbles
  // when clicking accept on suggested assignments (VS-1075)
  const [assignmentOrder] = useStateWithDeps(
    assignments.map((assignment) => assignment.userId),
    [location.id, hours.date]
  );
  const sortedAssignments = [...assignments];
  sortedAssignments.sort((a, b) => {
    const aInitialOrder = assignmentOrder.indexOf(a.userId);
    const bInitialOrder = assignmentOrder.indexOf(b.userId);
    // Force newly added assignments to bottom of list
    if (aInitialOrder === -1 && bInitialOrder !== -1) {
      return 1;
    } else if (bInitialOrder === -1 && aInitialOrder !== -1) {
      return -1;
    } else {
      // Otherwise base on initial order
      return aInitialOrder - bInitialOrder;
    }
  });

  const openTimeStr = formatTime(hours.open_time, { tight: true });
  const closeTimeStr = formatTime(hours.close_time, { tight: true });
  const shiftChangeTimeStr =
    hours.shift_change_time === null
      ? null
      : formatTime(hours.shift_change_time, { tight: true });

  const { fullRows, amRows, pmRows } = shiftRowsFromAssignments(
    location,
    sortedAssignments,
    hours,
    tierConfiguration
  );

  return (
    <>
      <div className="flex pb-2 text-sm font-bold text-gray-700">
        {shiftChangeTimeStr ? (
          // These are the “headers” for the two columns. We want to be a little
          // fuzzy feeling with them because we don’t precisely align the start
          // and end of the bubbles with precise horizontal distance
          // representing their start and end times.
          <>
            <span className="flex-1 text-center">
              {openTimeStr}–{shiftChangeTimeStr}
            </span>
            <span className="flex-1 text-center">
              {shiftChangeTimeStr}–{closeTimeStr}
            </span>
          </>
        ) : (
          <span className="flex-1 text-center">
            {openTimeStr}–{closeTimeStr}
          </span>
        )}
      </div>

      <div className="my-2 flex flex-col gap-2">
        {fullRows.map((assignment, idx) => {
          const [amCoverage, pmCoverage] = assignment
            ? calculateAssignmentShiftCoverage(hours, assignment)
            : (['full', 'full'] as const);

          return (
            <div className="flex w-full" key={idx}>
              <ShiftBubble
                location={location}
                assignment={assignment}
                user={(assignment && usersById.get(assignment.userId)) ?? null}
                unassignedStartTime={hours.open_time}
                unassignedEndTime={hours.close_time}
                partialStart={amCoverage === 'partial'}
                partialEnd={pmCoverage === 'partial'}
                openAssignmentEditor={() =>
                  onEditAssignment({
                    location,
                    assignment,
                    hours,
                    defaultStartTime: hours.open_time,
                    defaultEndTime: hours.close_time,
                  })
                }
                doAccept={assignment && (() => acceptAssignment(assignment))}
                doReject={assignment && (() => rejectAssignment(assignment))}
                getDistanceMi={getDistanceMi}
              />
            </div>
          );
        })}

        {[...Array(Math.max(amRows.length, pmRows.length))].map((_, idx) => (
          // Renders (potentially) two shift bubbles side-by side, one for am and one for pm.
          <div key={idx} className="flex gap-2">
            {idx < amRows.length ? (
              <ShiftBubble
                location={location}
                assignment={amRows[idx] ?? null}
                user={
                  (amRows[idx] && usersById.get(amRows[idx]!.userId)) ?? null
                }
                unassignedStartTime={hours.open_time}
                unassignedEndTime={hours.shift_change_time ?? hours.close_time}
                openAssignmentEditor={() =>
                  onEditAssignment({
                    location,
                    assignment: amRows[idx],
                    hours,
                    defaultStartTime: hours.open_time,
                    defaultEndTime: hours.shift_change_time ?? hours.close_time,
                  })
                }
                getDistanceMi={getDistanceMi}
                doAccept={
                  (amRows[idx] ?? null) &&
                  (() => acceptAssignment(amRows[idx]!))
                }
                doReject={
                  (amRows[idx] ?? null) &&
                  (() => rejectAssignment(amRows[idx]!))
                }
              />
            ) : (
              // Take up space even if there’s no shift bubble
              <div className="flex-1" />
            )}

            {idx < pmRows.length ? (
              <ShiftBubble
                location={location}
                assignment={pmRows[idx] ?? null}
                user={
                  (pmRows[idx] && usersById.get(pmRows[idx]!.userId)) ?? null
                }
                unassignedStartTime={hours.shift_change_time ?? hours.open_time}
                unassignedEndTime={hours.close_time}
                openAssignmentEditor={() =>
                  onEditAssignment({
                    location,
                    assignment: pmRows[idx],
                    hours,
                    defaultStartTime:
                      hours.shift_change_time ?? hours.open_time,
                    defaultEndTime: hours.close_time,
                  })
                }
                getDistanceMi={getDistanceMi}
                doAccept={
                  (pmRows[idx] ?? null) &&
                  (() => acceptAssignment(pmRows[idx]!))
                }
                doReject={
                  (pmRows[idx] ?? null) &&
                  (() => rejectAssignment(pmRows[idx]!))
                }
              />
            ) : (
              // Take up space even if there’s no shift bubble
              <div className="flex-1" />
            )}
          </div>
        ))}
      </div>

      {fullRows.length + amRows.length + pmRows.length === 0 && (
        <div className="my-8 text-center text-base italic">
          No shifts on this date
        </div>
      )}
    </>
  );
};

export type LookUpDistanceFn = (
  locationId: number,
  userId: number
) => number | null;

/**
 * Component to render the assignment or “unassigned shift” buttons within the
 * grid view of the detail pane.
 */
const ShiftBubble: React.FunctionComponent<{
  assignment: AssignmentRecord | null;
  user: AssignmentUser | null;
  location: AssignmentLocation;
  unassignedStartTime: TimeString;
  unassignedEndTime: TimeString;
  /**
   * True if this assignment begins after the start time for the
   * shift and should be indented some.
   */
  partialStart?: boolean | undefined;
  /**
   * True if the assignment ends before the end time for the shift and shouldn’t
   * go all the way to the end of the row.
   */
  partialEnd?: boolean | undefined;
  /**
   * Open the editor for this bubble.
   */
  openAssignmentEditor: () => void;
  getDistanceMi: LookUpDistanceFn;

  doAccept: null | (() => void);
  doReject: null | (() => void);
}> = ({
  location,
  assignment,
  user,
  openAssignmentEditor,
  unassignedEndTime,
  unassignedStartTime,
  partialStart = false,
  partialEnd = false,
  doAccept,
  doReject,
  getDistanceMi,
}) => {
  const startTime = assignment?.startTime ?? unassignedStartTime;
  const endTime = assignment?.endTime ?? unassignedEndTime;

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const { buttonProps, isPressed } = useButton(
    {
      onPress: openAssignmentEditor,
    },
    buttonRef
  );

  const { isFocused, focusProps } = useFocusRing({});

  const distanceMi =
    assignment?.type === 'poll' && user
      ? getDistanceMi(assignment.locationId, user.id)
      : null;

  return (
    <div
      className={cx(
        'flex flex-1 flex-col items-stretch self-stretch overflow-hidden',
        {
          'ml-[25%]': partialStart,
          'mr-[25%]': partialEnd,
        }
      )}
    >
      {React.createElement(
        // The entire bubble is clickable if it’s a “Missing Coverage”
        // unassigned shift, but if there’s an assignment we just render it as a
        // div so we can put clickable things inside it.
        assignment ? 'div' : 'button',
        {
          className: cx(
            'relative flex flex-1 flex-col items-stretch gap-1 overflow-hidden p-2 text-left font-normal',
            {
              'cursor-pointer rounded': !assignment,
              'rounded-t rounded-b-none': assignment,
              // TODO(fiona): Switch this to Biden blue #1 class
              'bg-[#DAE6FB]':
                assignment?.source && assignment.source !== 'auto',
              'bg-purple-300 text-gray-700': assignment?.source === 'auto',
              'bg-gray-100 text-gray-700': !assignment && !isPressed,
              'bg-primary text-white': !assignment && isPressed,
              'outline outline-hyperlink': isFocused,
            }
          ),
          ...(!assignment
            ? mergeProps(buttonProps, focusProps, { ref: buttonRef })
            : {}),
        },

        <div className="flex flex-1 flex-col items-stretch gap-2">
          <div className="flex items-center gap-2">
            <div className="flex-1 text-xs">
              {formatTime(startTime, { tight: true })}–
              {formatTime(endTime, { tight: true })}
            </div>

            {assignment?.source === 'auto' && (
              <div className="flex items-center gap-1 px-1 text-xs">
                <span className="material-icons text-sm">smart_toy</span>{' '}
                <span className="font-bold">Suggested</span>
              </div>
            )}

            {assignment?.source === 'manual' && (
              <div className="flex items-center gap-1 px-1 text-xs">
                <span className="italic">unsaved</span>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <a
              className={cx(
                'overflow-hidden overflow-ellipsis whitespace-nowrap text-base',
                {
                  'font-bold': !!assignment,
                  'hover:underline': !!user,
                }
              )}
              target="_blank"
              {...(user
                ? {
                    href: `/users/${user.id}/?view=details`,
                    title: 'Open profile (in a new window)',
                  }
                : {})}
            >
              {assignment ? (
                user ? (
                  `${user.first_name} ${user.last_name}`
                ) : (
                  'Unknown Volunteer'
                )
              ) : (
                <span className="italic">Missing coverage</span>
              )}
            </a>

            {user?.phone_number && (
              <div className="text-xs">
                {formatPhoneNumber(user.phone_number)}
              </div>
            )}
          </div>
        </div>
      )}

      {user &&
        assignment &&
        (() => {
          const {
            isExperienced,
            isLegalCommunity,
            hasCarAccess,
            languageTags,
          } = getVolunteerAttributes(user);
          const languageNames = volunteerLanguagesToLanguageNames(languageTags);

          const buttonClassName =
            'flex w-full cursor-pointer items-center justify-center gap-1 text-xs uppercase text-gray-700';

          return (
            <>
              <div
                className={cx(
                  'flex self-stretch border-t p-2 text-sm text-gray-700',
                  {
                    'border-purple-500 bg-purple-300':
                      assignment.source === 'auto',
                    'border-gray-500 bg-[#DAE6FB]':
                      assignment.source !== 'auto',
                  }
                )}
              >
                <div>
                  <span className="material-icons align-bottom text-base leading-none">
                    {iconForDistanceMi(distanceMi)}
                  </span>{' '}
                  <a
                    href={makeGoogleDirectionsUrl(user, location)}
                    target="_blank"
                    title="Open Google travel directions (in a new window)"
                    className="hover:underline focus:underline"
                    rel="noreferrer"
                  >
                    {describeDistanceMi(distanceMi, user.max_distance_miles)}
                  </a>
                </div>

                <div className="flex-1" />

                <div className="flex">
                  {isExperienced && (
                    <span
                      className="material-icons cursor-default align-bottom text-base leading-none"
                      title="Experienced Observer"
                    >
                      military_tech
                    </span>
                  )}

                  {isLegalCommunity && (
                    <span
                      className="material-icons cursor-default align-bottom text-base leading-none"
                      title="Legal Community"
                    >
                      gavel
                    </span>
                  )}

                  {hasCarAccess && (
                    <span
                      className="material-icons cursor-default align-bottom text-base leading-none"
                      title="Has Car Access"
                    >
                      garage
                    </span>
                  )}

                  {languageTags.length > 0 && (
                    <>
                      <span
                        className="material-icons cursor-default align-bottom text-base leading-none"
                        title={`Speaks ${languageNames.join(', ')}`}
                      >
                        chat
                      </span>
                      <span
                        className="align-bottom leading-none"
                        title={`Speaks ${languageNames.join(', ')}`}
                      >
                        {languageTags.length > 1
                          ? `${languageTags.length}`
                          : `${getLanguageIsoCode(languageTags[0])}`}
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div
                className={cx(
                  'flex items-stretch rounded-b border-2 border-t-0 ',
                  {
                    'border-purple-300': assignment.source === 'auto',
                    'border-[#DAE6FB]': assignment.source !== 'auto',
                  }
                )}
              >
                {assignment.source === 'auto' && (
                  <>
                    <div className="flex-1 p-1">
                      <TextButton
                        size="unset"
                        className={buttonClassName}
                        onPress={doAccept || (() => {})}
                      >
                        <span className="material-icons text-base text-gray-500">
                          check_circle_outline
                        </span>{' '}
                        Accept
                      </TextButton>
                    </div>

                    <div className="border-l-2 border-[inherit]" />

                    <div className="flex-1 p-1">
                      <TextButton
                        size="unset"
                        className={buttonClassName}
                        onPress={doReject || (() => {})}
                      >
                        <span className="material-icons text-base text-gray-500">
                          remove_circle_outline
                        </span>{' '}
                        Remove
                      </TextButton>
                    </div>
                  </>
                )}

                {assignment.source !== 'auto' && (
                  <div className="flex-1 p-1">
                    <TextButton
                      size="unset"
                      className={buttonClassName}
                      onPress={openAssignmentEditor}
                    >
                      <span className="material-icons text-base text-gray-500">
                        edit
                      </span>{' '}
                      Edit Assignment
                    </TextButton>
                  </div>
                )}
              </div>
            </>
          );
        })()}
    </div>
  );
};
