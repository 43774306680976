import React from 'react';

import { State } from '../../constants';
import { CountySlug } from '../../services/common';
import {
  ApiCounty,
  ApiElection,
  ApiLocation,
} from '../../services/lbj-shared-service';

import { useStateSpecificElectionId } from './issue-data';

import { useCounties, useLocations } from './lbj-data';

export type LocationPickerState = {
  selectedState: State | null;
  selectedCounty: CountySlug | null;

  /**
   * Counties for the selectedState. `null` if no state is selected, `undefined`
   * if counties are loading.
   */
  counties: ApiCounty[] | null | undefined;

  /**
   * Locations for the selectedCounty. `null` if no county is selected,
   * `undefined` if locations are loading.
   */
  locations: ApiLocation[] | null | undefined;

  /**
   * True if the user should be allowed to change the state. (Relevant for when
   * the national election is selected.)
   */
  mayChangeState: boolean;
};

export type LocationPickerAction =
  | { type: 'SET_STATE'; state: State | null }
  | { type: 'SET_COUNTY'; county: CountySlug | null };

export type LocationPickerDispatch = (action: LocationPickerAction) => void;

/**
 * We use combined state for these since we need them to change together:
 * changing the state should null out the county.
 */
type InternalLocationPickerState = Pick<
  LocationPickerState,
  'selectedCounty' | 'selectedState'
>;

/**
 * Maintains a {@link LocationPickerState} object and
 * {@link LocationPickerDispatch} function for powering a location picker that
 * allows filtering (optionally) by state and county.
 */
export function useLocationPicker(
  election: Pick<ApiElection, 'id' | 'state'>
): [LocationPickerState, LocationPickerDispatch] {
  const [internalState, setInternalState] =
    React.useState<InternalLocationPickerState>(
      makeDefaultState(election.state)
    );

  React.useEffect(() => {
    setInternalState(makeDefaultState(election.state));
  }, [election.state]);

  // otherwise the state is locked in to the one from the election
  const mayChangeState = election.state === 'US';

  const locationElectionId = useStateSpecificElectionId(
    election,
    internalState.selectedState
  );

  const counties = useCounties(internalState.selectedState);
  const locations = useLocations(locationElectionId ?? undefined, {
    // [] for counties is a signal to useLocations not to load anything
    counties: internalState.selectedCounty
      ? [internalState.selectedCounty]
      : [],
  });

  const state = React.useMemo<LocationPickerState>(
    () => ({
      ...internalState,

      counties:
        internalState.selectedState === null ||
        internalState.selectedState === 'US'
          ? null
          : counties,
      locations: internalState.selectedCounty === null ? null : locations,

      mayChangeState,
    }),
    [internalState, counties, locations, mayChangeState]
  );

  const dispatch = React.useCallback<LocationPickerDispatch>(
    (action) =>
      setInternalState((prevState) => {
        switch (action.type) {
          case 'SET_COUNTY':
            if (prevState.selectedCounty === action.county) {
              return prevState;
            }

            return {
              ...prevState,
              selectedCounty: action.county,
            };

          case 'SET_STATE':
            if (prevState.selectedState === action.state) {
              return prevState;
            }

            return {
              selectedState: action.state,
              selectedCounty: null,
            };

          default:
            return prevState;
        }
      }),
    []
  );

  return [state, dispatch];
}

/**
 * Factored out so we can be consistent when resetting state for election
 * changes.
 */
const makeDefaultState = (
  currentElectionState: State
): InternalLocationPickerState => ({
  selectedState: currentElectionState === 'US' ? null : currentElectionState,
  selectedCounty: null,
});
