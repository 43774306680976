import { Query } from 'history';
import _ from 'lodash';
import moment from 'moment';

import {
  FILTERS_EXISTING,
  assignmentViewTypes,
  AssignmentViewType,
  State,
} from '../../constants';
import { FiltersBySection } from '../../modules/filters/action-creators';
import fluxStore from '../../modules/flux-store';
import { DateString } from '../../services/common';

import { mapStateToAssignmentFiltersForView } from './map-state-to-assignment-filters';

const {
  PRECINCTS,
  LOCATIONS,
  BOILER_ROOMS,
  BOARDS_OF_ELECTIONS,
  HOTLINES,
  PEOPLE,
} = assignmentViewTypes;

const VIEW_PARAMS = {
  PRECINCTS: 'precincts',
  LOCATIONS: 'locations',
  BOILER_ROOMS: 'boiler_rooms',
  HOTLINES: 'hotline_centers',
  BOARDS_OF_ELECTIONS: 'board_of_elections',
  PEOPLE: 'people',
} as const;

const STATIC_DEFAULTS = {
  dates: moment().format('YYYY-MM-DD') as DateString,
  view: LOCATIONS,
} as const;

const SYNCED_FILTERS = [
  'state',
  'county',
  'assignment_state',
  'dates',
] as const;

function _getUserDefaults(view: string | string[] | null | undefined) {
  const { getState } = fluxStore;

  const currentUser = getState().user.currentUser.userData;
  const state = currentUser?.get('assignment_state') as
    | State
    | null
    | undefined;

  return view === VIEW_PARAMS.PEOPLE
    ? ({
        assignment_state: state,
      } as const)
    : ({
        state,
      } as const);
}

/**
 * Converts from the `view` query parameter (all lowercase) to a
 * {@link AssignmentViewType}.
 */
function _getActiveView(query: Query): AssignmentViewType {
  const view = query['view'];

  switch (view) {
    case VIEW_PARAMS.BOILER_ROOMS:
      return BOILER_ROOMS;

    case VIEW_PARAMS.HOTLINES:
      return HOTLINES;

    case VIEW_PARAMS.PEOPLE:
      return PEOPLE;

    case VIEW_PARAMS.BOARDS_OF_ELECTIONS:
      return BOARDS_OF_ELECTIONS;

    case VIEW_PARAMS.PRECINCTS:
      return PRECINCTS;

    case VIEW_PARAMS.LOCATIONS:
    default:
      return LOCATIONS;
  }
}

export function mapQueryParamsToAssignmentFilters(
  query: Query
): FiltersBySection['ASSIGNMENT'] {
  const view = _getActiveView(query);

  if (query['filters'] === FILTERS_EXISTING) {
    const fromState: Partial<FiltersBySection['ASSIGNMENT']> =
      mapStateToAssignmentFiltersForView(fluxStore.getState(), view).toJS();

    const fromQuery = _.omit(query, ['filters']);
    const syncedFilters = _.pick(fromState, SYNCED_FILTERS);

    return {
      ...syncedFilters,
      ...fromQuery,
      ...{ view },
    };
  }

  const userDefaults = _getUserDefaults(query['view']);

  return {
    ...STATIC_DEFAULTS,
    ...userDefaults,
    // TODO(fiona): VALIDATE THIS!!
    ...query,
    ...{ view },
  };
}

/**
 * Converts filter state back to query params by lowercasing the
 * {@link AssignmentViewType}.
 */
export function mapAssignmentFiltersToQueryParams(
  filters: FiltersBySection['ASSIGNMENT']
): Query {
  return {
    ...filters,
    ...{ view: VIEW_PARAMS[filters.view] },
  };
}
