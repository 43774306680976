import keyMirror from 'key-mirror';

export const WAIT_TIME_KEYS = keyMirror({
  no_line: null,
  thirty_mins: null,
  one_hour: null,
  two_hours: null,
  three_hours: null,
  four_hours: null,
  five_hours: null,
  six_hours: null,
  seven_hours: null,
  eight_hours: null,
  eight_plus_hours: null,
  unknown: null,
  complete: null,
});

export const WAIT_TIME_CHOICES: { [k in keyof typeof WAIT_TIME_KEYS]: string } =
  {
    [WAIT_TIME_KEYS.no_line]: 'No Line',
    [WAIT_TIME_KEYS.thirty_mins]: '30 minutes',
    [WAIT_TIME_KEYS.one_hour]: '1 hour',
    [WAIT_TIME_KEYS.two_hours]: '2 hours',
    [WAIT_TIME_KEYS.three_hours]: '3 hours',
    [WAIT_TIME_KEYS.four_hours]: '4 hours',
    [WAIT_TIME_KEYS.five_hours]: '5 hours',
    [WAIT_TIME_KEYS.six_hours]: '6 hours',
    [WAIT_TIME_KEYS.seven_hours]: '7 hours',
    [WAIT_TIME_KEYS.eight_hours]: '8 hours',
    [WAIT_TIME_KEYS.eight_plus_hours]: 'More than 8 hours',
    [WAIT_TIME_KEYS.unknown]: 'Not available',
    [WAIT_TIME_KEYS.complete]: 'Voting has finished',
  };

export const WAIT_TIME_DISPLAY: { [k in keyof typeof WAIT_TIME_KEYS]: string } =
  {
    [WAIT_TIME_KEYS.no_line]: 'no line',
    [WAIT_TIME_KEYS.thirty_mins]: 'average 30-minute wait time',
    [WAIT_TIME_KEYS.one_hour]: 'average 1-hour wait time',
    [WAIT_TIME_KEYS.two_hours]: 'average 2-hour wait time',
    [WAIT_TIME_KEYS.three_hours]: 'average 3-hour wait time',
    [WAIT_TIME_KEYS.four_hours]: 'average 4-hour wait time',
    [WAIT_TIME_KEYS.five_hours]: 'average 5-hour wait time',
    [WAIT_TIME_KEYS.six_hours]: 'average 6-hour wait time',
    [WAIT_TIME_KEYS.seven_hours]: 'average 7-hour wait time',
    [WAIT_TIME_KEYS.eight_hours]: 'average 8-hour wait time',
    [WAIT_TIME_KEYS.eight_plus_hours]: '8+ hour wait times on average',
    [WAIT_TIME_KEYS.unknown]: 'not available',
    [WAIT_TIME_KEYS.complete]: 'voting has finished',
  };

export const YES_NO_UNKNOWN_KEYS = keyMirror({
  yes: null,
  no: null,
  unknown: null,
});

export type YesNoUnknown = keyof typeof YES_NO_UNKNOWN_KEYS;

export const YES_NO_UNKNOWN_CHOICES: {
  [k in YesNoUnknown]: string;
} = {
  [YES_NO_UNKNOWN_KEYS.yes]: 'Yes',
  [YES_NO_UNKNOWN_KEYS.no]: 'No',
  [YES_NO_UNKNOWN_KEYS.unknown]: 'Unknown',
};

export type CheckinType = 'checkin' | 'checkout';
