import _ from 'lodash';

import moment from 'moment-timezone';

import {
  CallerRelationship,
  IncidentIssueCategories,
  InquiryIssueCategories,
  IssueClass,
  IssuePriority,
  IssueScope,
  IssueSource,
  IssueStatus,
  State,
  UserRole,
  VoteStatus,
} from '../constants';
import { DocumentUploadData, PhotoUploadData } from '../modules/issue/reducers';
import { ADDRESS_COMPONENT_TYPE } from '../pages/issue/issue-utils';
import { UpdateCursor } from '../utils/issue/polling-updates';
import { DeepMerge } from '../utils/types';

import ApiClient from './api-client';
import { ExpandApiPrecinctCongressionalDistricts } from './assignment-service';
import { CountySlug, Iso8601String, PaginatedResponse } from './common';
import {
  ApiBoilerRoom,
  ApiCounty,
  ApiLocation,
  ApiPrecinct,
  WithApiCountyRegions,
} from './lbj-shared-service';
import UploadClient from './upload-client';
import { ApiUser, ExpandApiUserPii } from './user-service';

export type ApiIssue = {
  id: number;
  type: IssueClass;
  source: IssueSource;
  resolved: Iso8601String | null;
  state: State;
  zipcode: string;
  category: InquiryIssueCategories | IncidentIssueCategories | '';
  sub_category: string;
  category_2: InquiryIssueCategories | IncidentIssueCategories | '';
  sub_category_2: string;
  category_3: InquiryIssueCategories | IncidentIssueCategories | '';
  sub_category_3: string;
  priority: `${IssuePriority}` | '';
  status: IssueStatus;
  vote_status: VoteStatus | '';
  scope: IssueScope;
  voter_first_name: string;
  voter_last_name: string;
  voter_phone_number: string;
  voter_email: string;
  voter_address: string;
  voter_street_address: string;
  voter_street_address_2: string;
  voter_city: string;
  voter_state: State | '';
  voter_zipcode: string;
  voter_address_coordinates:
    | [number, number] // format: [longitude, latitude]
    | null;
  voter_address_components: [] | null;
  voter_address_components_type: typeof ADDRESS_COMPONENT_TYPE | null;
  caller_relationship: CallerRelationship | '';
  from_permanent: boolean;
  requires_followup: boolean;
  issue_election_id: number;
  qa_reviewed: boolean;
  last_modified: Iso8601String | null;
  incident_time: Iso8601String;
  report_time: Iso8601String;
  submitted: Iso8601String;
  created_by: number;
  owner: number | null;
  user: number | null;
  boiler_room: number;
  county: CountySlug | null;
  precinct: number | null;
  location: number | null;
};

/**
 * Type for fields used to perform actions on issue updates.
 */
export type ApiIssueActionUpdates = {
  /** If true, adds user to the `watchers` field. If false, removes them. */
  subscribed?: boolean;
  status?: ApiIssue['status'];
  owner?: number;
};

export type ApiNewIssue = Pick<
  ApiIssue,
  | 'created_by'
  | 'incident_time'
  | 'issue_election_id'
  | 'report_time'
  | 'requires_followup'
  | 'source'
  | 'state'
  | 'type'
> & {
  status?: ApiIssue['status'];

  category: InquiryIssueCategories | IncidentIssueCategories | null;
  sub_category: string | null;
  category_2: InquiryIssueCategories | IncidentIssueCategories | null;
  sub_category_2: string | null;
  category_3: InquiryIssueCategories | IncidentIssueCategories | null;
  sub_category_3: string | null;

  // <NewIssueContainer> / initial-fields.ts saves these as strings
  county?: CountySlug | null | undefined;
  location?: string | null | undefined;
  precinct?: string | null | undefined;
  zipcode?: string | null | undefined;

  scope?: ApiIssue['scope'];
  vote_status?: ApiIssue['vote_status'];

  voter_first_name?: ApiIssue['voter_first_name'];
  voter_last_name?: ApiIssue['voter_last_name'];
  voter_address?: ApiIssue['voter_address'];
  voter_street_address?: ApiIssue['voter_street_address'];
  voter_street_address_2?: ApiIssue['voter_street_address_2'];
  voter_city?: ApiIssue['voter_city'];
  voter_state?: ApiIssue['voter_state'];
  voter_zipcode?: ApiIssue['voter_zipcode'];
  voter_address_coordinates?: ApiIssue['voter_address_coordinates'];
  voter_address_components?: ApiIssue['voter_address_components'];
  voter_address_components_type?: ApiIssue['voter_address_components_type'];

  voter_phone_number?: ApiIssue['voter_phone_number'];
  voter_email?: ApiIssue['voter_email'];

  caller_voter_impacted?: 'yes' | 'no';
  caller_relationship?: ApiIssue['caller_relationship'] | null;

  /**
   * Comments attached to new tickets just need text. The ticket ID and
   * commenter are added automatically.
   */
  comments: { text: string }[];

  photos?: PhotoUploadData[];
  documents?: DocumentUploadData[];
};

export type ExpandApiIssueBoilerRoom = {
  boiler_room: ApiBoilerRoom;
};

export type ExpandApiIssueCounty = {
  county: ApiCounty & WithApiCountyRegions;
};

export type ExpandApiIssueCreatedBy = {
  created_by: ApiUser;
};

export type ExpandApiIssuePrecinct = {
  precinct: ApiPrecinct | null;
};

export type ExpandApiIssuePrecinctCongressionalDistricts = {
  precinct: (ApiPrecinct & ExpandApiPrecinctCongressionalDistricts) | null;
};

export type ExpandApiIssueUser = {
  user: ApiUser | null;
};

export type ExpandApiIssueOwner = {
  owner: ApiUser | null;
};

export type ExpandApiIssueLocation = {
  location: ApiLocation | null;
};

export type ExpandApiIssueWatchers = {
  watchers: ApiUser[];
};

export type ExpandApiIssueComments = {
  comments: ApiIssueComment[];
};

export type ApiIssueComment = {
  id: number;
  ticket: number;
  commenter: number;
  text: string;
  parent: null | number;
  timestamp: Iso8601String;
};

export type ExpandApiIssueCommentCommenter = {
  commenter: ApiUser;
};

// OK just giving up on being nit-picky with expanding at this point.
export type ApiIssueAuditLogEntryWithUserOwnerBoilerRoomExpanded = {
  timestamp: Iso8601String;
  action: 'creation' | 'modification';

  // Some of the subfields in here may only be present if action is
  // `modification`.
  //
  // TODO(fiona): There are probably more in here as well.
  changes: {
    boiler_room?: {
      previous?: ApiBoilerRoom | null;
      current: ApiBoilerRoom | null;
    };
    owner?: {
      previous?: ApiUser | null;
      current: ApiUser | null;
    };
    user?: {
      previous?: ApiUser | null;
      current: ApiUser | null;
    };
    watchers?: {
      previous: number[];
      current: number[];
    };
    last_modified?: {
      previous?: Iso8601String | null;
      current: Iso8601String;
    };
  };

  user: ApiUser;
};

export type ExpandApiIssueAuditLogEntryUserPii = {
  user: ApiUser & ExpandApiUserPii;
};

export type ApiIssueDocument = {
  id: number;
  ticket: number;
  user: number;
  /** URL that the document can be found at */
  document: string;
};

export type ApiIssuePhoto = {
  id: number;
  ticket: number;
  user: number;
  photo: string;
};

export const DEFAULT_ISSUE_LIST_SIZE = 50;

const defaultListParams = {
  expand: 'county,precinct,created_by,owner,boiler_room',
  size: DEFAULT_ISSUE_LIST_SIZE,
  type__in: 'incident,inquiry',
};

const defaultDetailParams = {
  expand:
    'user,owner,created_by,county,precinct.congressional_districts,location,watchers',
};

const defaultCommentParams = {
  expand: 'commenter',
  ordering: 'timestamp',
  size: 100,
};

const defaultCreateParams = {
  expand: 'comments',
};

const defaultDocumentParams = {
  size: 50,
};

const defaultPhotoParams = {
  size: 50,
};

function getDefaultExportParams() {
  return {
    expand:
      'user,owner,created_by,county,precinct,location,group,comments,comments.commenter',
    format: 'csv',
    size: 1000000,
    tz: moment.tz.guess(),
  };
}

/**
 * Type of expanded issue as it is returned by {@link getIssues}.
 */
export type IssueListApiIssue = DeepMerge<
  [
    ApiIssue,
    // We can use the default expands from defaultListParams for these, they
    // don’t seem to be overriden.
    ExpandApiIssueCreatedBy,
    ExpandApiIssueBoilerRoom,
    ExpandApiIssueCounty,
    ExpandApiIssuePrecinct,
    ExpandApiIssueOwner
  ]
>;

/**
 * Type of filters allowed on loading issues lists or querying for updates.
 *
 * @see getIssues
 * @see getIssuesLastUpdated
 */
export type ApiIssueListParams = {
  ordering?: string;

  search_term?: string;
  voter_name?: string;
  voter_phone_number?: string;

  state?: State | undefined;
  query_election_id?: number | undefined;

  /** Comma-separated string of boiler room IDs */
  boiler_room?: string | undefined;
  boiler_room__level?: string | undefined;

  /** Comma-separated string of region IDs */
  region?: string | undefined;
  /** Comma-separated string of `CountySlug`s */
  county?: string | undefined;

  /** String of precinct ID */
  precinct?: string | undefined;
  /** Comma-separated string of location IDs */
  location?: string;

  /** Comma-separated string of district IDs */
  us_house?: string | undefined;
  /** Comma-separated string of district IDs */
  state_house?: string | undefined;
  /** Comma-separated string of district IDs */
  state_senate?: string | undefined;

  incident_time__gte?: Iso8601String | undefined;
  incident_time__lt?: Iso8601String | undefined;

  status?: IssueStatus | undefined;
  source?: IssueSource | undefined;
  type__in?: IssueClass | undefined;
  vote_status?: VoteStatus | undefined;
  /** Comma-separated string of issue scopes */
  scope?: string | undefined;
  priority?: IssuePriority | undefined;
  qa_reviewed?: 'true' | 'false' | undefined;

  owner?: string | number | undefined;
  watchers?: string | number | undefined;
  user?: string | number | undefined;
  owner__isnull?: 'True' | undefined;
  from_permanent?: 'True' | undefined;
  requires_followup?: 'True' | undefined;

  category?: string | undefined;
  sub_category?: string | undefined;
  /** Pipe-separated category and optional sub-category to exclude from the
   * response. Used to keep Results tickets only on the results page. */
  exclude_category?: string | undefined;
};

/**
 * Returns issues. If the current user is a Hotline Worker or Poll Worker,
 * filters those issues to ones that they are allowed to view.
 */
export function getIssues(
  user: Pick<ApiUser, 'role' | 'id'>,
  query: ApiIssueListParams & { size?: number; offset?: number } = {}
) {
  const params: JQuery.PlainObject = _.assign({}, defaultListParams, query);

  if (user.role === 'hotline_worker' || user.role === 'poll_observer') {
    params['viewed_by'] = user.id;
  }

  if (params['region']) {
    params['county__regions'] = params['region'];
    delete params['region'];
  }

  return ApiClient<PaginatedResponse<IssueListApiIssue>>(
    'tickets/',
    'GET',
    params
  );
}

/**
 * Issues list for phone number is always called with comments expanded.
 */
export type IssuesByPhoneNumberApiIssue = DeepMerge<
  [ApiIssue, ExpandApiIssueComments]
>;

/**
 * Returns 5 issues that match the query, which typically is to look up issues
 * by phone number, expanded to include comments.
 */
export function getIssuesByPhoneNumber(query: {
  voter_phone_number__exact: string;
  state?: State;
  query_election_id?: number | string;
  expand: 'comments';
}) {
  const params = _.assign({}, { size: 5, ordering: '-report_time' }, query);

  return ApiClient<PaginatedResponse<IssuesByPhoneNumberApiIssue>>(
    'tickets/',
    'GET',
    params
  );
}

/**
 * Called at a polling interval to see if there are new issues to load in.
 */
export function getIssuesLastUpdated(
  user: Pick<ApiUser, 'role' | 'id'>,
  cursor: UpdateCursor,
  query: ApiIssueListParams = {}
) {
  const params: JQuery.PlainObject = _.assign({}, defaultListParams, query);
  delete params['expand'];

  if (user.role === 'hotline_worker' || user.role === 'poll_observer') {
    params['viewed_by'] = user.id;
  }

  params['cursor_min_id'] = cursor.minIssueId;
  params['cursor_max_id'] = cursor.maxIssueId;
  params['cursor_last_modified'] = cursor.maxLastModified;

  return ApiClient<{
    needs_update: boolean;
    polling_interval: number;
  }>('tickets/cursor_with_interval/', 'GET', params);
}

/**
 * Sets up a CSV export of issues and returns the URLs it can be accessed at.
 */
export function getIssuesExportAsync(
  user: Pick<ApiUser, 'id' | 'role'>,
  query: ApiIssueListParams = {}
) {
  const params: JQuery.PlainObject = _.assign(
    {},
    getDefaultExportParams(),
    query
  );
  delete params['format']; // this is the async version so we just use json

  if (user.role === 'hotline_worker' || user.role === 'poll_observer') {
    params['viewed_by'] = user.id;
  }

  return ApiClient<{
    get_url: string;
    head_url: string;
  }>('ticket_csv_async/', 'POST', params);
}

export type DetailedApiIssue = DeepMerge<
  [
    ApiIssue,
    ExpandApiIssueUser,
    ExpandApiIssueOwner,
    ExpandApiIssueCreatedBy,
    ExpandApiIssueCounty,
    ExpandApiIssueLocation,
    ExpandApiIssuePrecinctCongressionalDistricts,
    ExpandApiIssueWatchers
  ]
>;

export function getIssue(id: number, query = {}) {
  const params = _.assign({}, defaultDetailParams, query);
  // This uses the default expand. No one is calling this function with a query
  // right now.
  //
  // expand=user,owner,created_by,county,precinct.congressional_districts,location,watchers
  return ApiClient<DetailedApiIssue>(`tickets/${id}/`, 'GET', params);
}

export function createIssue(issueData: ApiNewIssue, query = {}) {
  const params = _.assign({}, defaultCreateParams, query);
  return ApiClient<ApiIssue>('tickets/', 'POST', params, {}, issueData);
}

export function escalateIssue(id: number) {
  return ApiClient<ApiIssue>(`ticket/${id}/escalate/`, 'PUT', {}, {}, {});
}

export function updateIssue(
  id: number | string,
  issueData: DeepMerge<[Partial<ApiIssue>, { id: number | string }]> &
    ApiIssueActionUpdates,
  query: {} | { voter_phone_number__exact: string } = {}
) {
  return ApiClient(`tickets/${id}/`, 'PUT', query, {}, issueData);
}

export function updateViewOnlySubscribeIssue(
  id: number | string,
  issueData: { id: number | string } & Pick<ApiIssueActionUpdates, 'subscribed'>
) {
  return ApiClient(`ticket/${id}/subscribe/`, 'PUT', {}, {}, issueData);
}

/**
 * {@link ApiIssueComment} as it’s returned by {@link getIssueComments}.
 */
export type ApiExpandedIssueComment = DeepMerge<
  [ApiIssueComment, ExpandApiIssueCommentCommenter]
>;

/**
 * Returns the comments for an issue, with the commenter expanded.
 */
export function getIssueComments(query = {}) {
  _.assign(query, defaultCommentParams);

  return ApiClient<PaginatedResponse<ApiExpandedIssueComment>>(
    'ticket_comments/',
    'GET',
    query
  );
}

/**
 * Type for new issue comments. Allows strings for the IDs.
 */
export type ApiNewIssueComment = {
  ticket: string | number;
  commenter: string | number;
  text: string;
  label?: string;
} & Partial<Pick<ApiIssueComment, 'parent'>>;

/**
 * Creates a comment on an issue.
 */
export function createIssueComment(commentData: ApiNewIssueComment) {
  return ApiClient('ticket_comments/', 'POST', {}, {}, commentData);
}

export type ApiIssueHistory =
  | ApiIssueAuditLogEntryWithUserOwnerBoilerRoomExpanded
  | DeepMerge<
      [
        ApiIssueAuditLogEntryWithUserOwnerBoilerRoomExpanded,
        ExpandApiIssueAuditLogEntryUserPii
      ]
    >;

/**
 * Returns an audit log that includes PII if the user is not a poll observer or
 * hotline worker.
 */
export function getIssueHistory(userRole: UserRole, id: number, query = {}) {
  let defaultExpand: string;

  if (userRole !== 'poll_observer' && userRole !== 'hotline_worker') {
    defaultExpand = 'changing_user.pii,user,owner,boiler_room';
  } else {
    defaultExpand = 'changing_user,user,owner,boiler_room';
  }

  const params = _.assign({}, { expand: defaultExpand, size: 1000000 }, query);

  return ApiClient<PaginatedResponse<ApiIssueHistory>>(
    `ticket_audit_log/${id}/`,
    'GET',
    params
  );
}

/**
 * Fetches info about the PDF attachments for an issue.
 */
export function getIssueDocuments(query = {}) {
  _.assign(query, defaultDocumentParams);
  return ApiClient<PaginatedResponse<ApiIssueDocument>>(
    'ticket_documents/',
    'GET',
    query
  );
}

export function uploadIssueDocument(documentData: {
  ticket: string;
  document: File;
}) {
  return UploadClient<ApiIssueDocument>('ticket_documents/', documentData);
}

export function deleteIssueDocument(documentData: ApiIssueDocument) {
  return ApiClient(`ticket_documents/${documentData.id}/`, 'DELETE');
}

/**
 * Fetches info about the photo attachments for an issue.
 */
export function getIssuePhotos(query = {}) {
  _.assign(query, defaultPhotoParams);
  return ApiClient<PaginatedResponse<ApiIssuePhoto>>(
    'ticket_photos/',
    'GET',
    query
  );
}

export function uploadIssuePhoto(photoData: { ticket: string; photo: Blob }) {
  return UploadClient<ApiIssuePhoto>('ticket_photos/', photoData);
}

export function deleteIssuePhoto(photoData: ApiIssuePhoto) {
  return ApiClient(`ticket_photos/${photoData.id}/`, 'DELETE');
}

/**
 * Given a state, returns the election that should be used for filing tickets to
 * it. Used when logged in to the National election so that tickets can be filed
 * to the current state-specific elections.
 */
export function getIssueElection(state: State | '') {
  return ApiClient<{ election_id: number }>('ticket/election/', 'GET', {
    state,
  });
}

export default {
  getIssues,
  getIssuesByPhoneNumber,
  getIssuesExportAsync,
  getIssue,
  createIssue,
  escalateIssue,
  updateIssue,
  getIssueComments,
  createIssueComment,
  getIssueHistory,
  getIssueDocuments,
  uploadIssueDocument,
  deleteIssueDocument,
  getIssuePhotos,
  uploadIssuePhoto,
  deleteIssuePhoto,
  getIssuesLastUpdated,
  getIssueElection,
};
