import React from 'react';

import { SelectableUser } from '../../components/presentational/form/user-autocomplete';
import { UserRole, US_NATIONAL_STATE_CODE } from '../../constants';
import { LbjInputEvent } from '../../decorators/input-props';

import { ApiBoilerRoom, ApiElection } from '../../services/lbj-shared-service';
import { IssueInProgressPayload } from '../../utils/issue/map-state-to-issue-payload';
import { MapFromJs } from '../../utils/types';

import IssueAssignment from './issue-assignment';
import { CommonIssueFormActions } from './issue-detail-form';

/**
 * Shows an assignment change form when editing the issue.
 */
export default class IssueAssignmentContainer extends React.Component<{
  boilerRoomList: Immutable.List<MapFromJs<ApiBoilerRoom>>;
  electionList: Immutable.List<MapFromJs<ApiElection>>;
  currentUserRole: UserRole;
  issueData: Pick<
    IssueInProgressPayload,
    'boiler_room' | 'issue_election_id' | 'owner' | 'state'
  >;
  onInputChange: (
    param: LbjInputEvent<string | number | null | undefined>
  ) => void;
  onClaimIssue: () => void;
  onEscalateIssue: () => void;
  errors: { [key: string]: string[] };
  preloadedUsers: SelectableUser[];
  actions: Pick<CommonIssueFormActions, 'updateIssueElection'>;
}> {
  constructor(props: IssueAssignmentContainer['props']) {
    super(props);
    this.onBoilerRoomSelect = this.onBoilerRoomSelect.bind(this);
    this.onElectionSelect = this.onElectionSelect.bind(this);
  }

  onBoilerRoomSelect({ target }: LbjInputEvent<string | null | undefined>) {
    const room = target.value;
    const { onInputChange } = this.props;

    onInputChange({
      target: {
        name: 'boiler_room',
        value: room,
      },
    });
    onInputChange({
      target: {
        name: 'owner',
        value: '',
      },
    });
  }

  onElectionSelect({ target }: LbjInputEvent<string | null | undefined>) {
    const electionId = Number(target.value);
    const { onInputChange, actions } = this.props;

    onInputChange({
      target: {
        name: 'issue_election_id',
        value: electionId,
      },
    });
    onInputChange({
      target: {
        name: 'boiler_room',
        value: '',
      },
    });
    onInputChange({
      target: {
        name: 'owner',
        value: '',
      },
    });
    onInputChange({
      target: {
        name: 'precinct',
        value: '',
      },
    });
    onInputChange({
      target: {
        name: 'location',
        value: '',
      },
    });

    actions.updateIssueElection(electionId);
  }

  render() {
    const {
      boilerRoomList,
      electionList,
      onClaimIssue,
      onEscalateIssue,
      onInputChange,
      issueData,
      currentUserRole,
      errors,
      preloadedUsers,
    } = this.props;

    return (
      <IssueAssignment
        currentUserRole={currentUserRole}
        onBoilerRoomSelect={this.onBoilerRoomSelect}
        onClaimIssue={onClaimIssue}
        onEscalateIssue={onEscalateIssue}
        onInputChange={onInputChange}
        issueData={issueData}
        errors={errors}
        boilerRoomList={boilerRoomList.filter(
          (room) =>
            room.get('state') === issueData.state ||
            room.get('state') === US_NATIONAL_STATE_CODE
        )}
        onElectionSelect={this.onElectionSelect}
        electionList={electionList}
        preloadedUsers={preloadedUsers}
      />
    );
  }
}
