import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionCreators as userActionCreators } from '../../../modules/user';
import Autocomplete from '../../presentational/form/autocomplete';

@connect((state) => {
  const { user } = state;

  return {
    userList: user.getIn(['users', 'autocomplete', 'listData']),
  };
})
export default class UserReplytoContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userList: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchFilters: PropTypes.object,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    searchFilters: {},
    placeholder: 'Start typing...',
    defaultValue: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.defaultValue,
    };
  }

  componentWillMount() {
    const { dispatch, searchFilters } = this.props;
    const { getUserAutocompleteListAsync } = userActionCreators;

    dispatch(getUserAutocompleteListAsync(searchFilters));
  }

  getChoices() {
    const { userList } = this.props;

    return userList.reduce((choices, item) => {
      choices[item.get('id')] = {
        name: `${item.get('first_name')} ${item.get('last_name')}`,
        email: item.get('email'),
      };
      return choices;
    }, {});
  }

  handleChange(userId) {
    const { userList, onChange } = this.props;
    const user = userList.find(
      (user) => user.get('id') === parseInt(userId, 10)
    );

    this.setState({ selectedValue: userId });
    const email = user ? user.get('email') : '';
    return onChange(email);
  }

  filterOption(option, filterValue = '') {
    const { label } = option || {};
    const name = label.name || '';
    const email = label.email || '';

    if (!filterValue) {
      return true;
    }

    return (
      name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
      email.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    );
  }

  renderValue(option) {
    return <span>{option.label.name}</span>;
  }

  renderOption(option) {
    const { email, name } = option.label;

    if (email) {
      return (
        <span>
          {name}
          <br />
          {email}
        </span>
      );
    }

    return <span>{name}</span>;
  }

  render() {
    const { selectedValue } = this.state;
    const { placeholder } = this.props;

    return (
      <Autocomplete
        type="text"
        name="replyTo"
        title="Reply-to"
        choices={this.getChoices()}
        placeholder={placeholder}
        handleChange={this.handleChange.bind(this)}
        optionRenderer={this.renderOption.bind(this)}
        valueRenderer={this.renderValue.bind(this)}
        filterOption={this.filterOption.bind(this)}
        value={selectedValue}
      />
    );
  }
}
