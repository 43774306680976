import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DebouncedInput from '../form/debounced-input';
import HiddenInput from '../form/hidden-input';

export default class BoardOfElectionsSearchForm extends Component {
  static propTypes = {
    onSearchForBoardsOfElections: PropTypes.func.isRequired,
  };

  onChange({ target }) {
    const { value } = target;

    return this.props.onSearchForBoardsOfElections({ name: value });
  }

  render() {
    return (
      <div className="lbj-column-content">
        <HiddenInput ctaText="Select a Board of Elections Location">
          <DebouncedInput
            title="Boards of Elections"
            name="name"
            onChange={this.onChange.bind(this)}
          />
        </HiddenInput>
      </div>
    );
  }
}
