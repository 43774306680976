import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import mapStateToNotificationsFilters from '../../../utils/notifications/map-state-to-notifications-filters';
import Sidebar from '../../presentational/lbj/sidebar';
import CountyFilterContainer from '../form/county-filter-container';
import StateFilterContainer from '../form/state-filter-container';

@connect((state) => {
  return {
    filters: mapStateToNotificationsFilters(state),
  };
})
export default class NotificationsFilterSidebar extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  render() {
    const { onFilterChange } = this.props;
    const { NOTIFICATIONS } = lbjAppSections;

    return (
      <Sidebar title="View By" collapsible>
        <div className="lbj-column-content">
          <StateFilterContainer
            type="filter"
            appSection={NOTIFICATIONS}
            includeNational={false}
            onChange={onFilterChange}
          />
          <CountyFilterContainer
            type="filter"
            appSection={NOTIFICATIONS}
            onChange={onFilterChange}
          />
        </div>
      </Sidebar>
    );
  }
}
