import { Query } from 'history';
import * as Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { requestStatuses, State, UserRole } from '../../constants';
import { AppDispatch, AppState } from '../../modules/flux-store';
import { actionCreators as issueActionCreators } from '../../modules/issue';
import { IssueCrudData } from '../../modules/issue/action-creators';
import {
  ExistingIssueInProgress,
  IssueErrorData,
} from '../../modules/issue/reducers';
import { actionCreators as lbjActionCreators } from '../../modules/lbj';
import { ToastRecord } from '../../modules/toast';
import { ImmutableCurrentUserElection } from '../../modules/user/reducers';
import {
  ApiIssue,
  ApiIssueActionUpdates,
  ApiIssueComment,
  ApiIssueDocument,
  ApiIssuePhoto,
  ApiNewIssueComment,
} from '../../services/issue-service';
import { ApiBoilerRoom, ApiElection } from '../../services/lbj-shared-service';
import { ApiCurrentUser, ApiUser } from '../../services/user-service';
import mapStateToIssueFilters from '../../utils/issue/map-state-to-issue-filters';
import mapStateToIssuePayload, {
  IssueInProgressPayload,
} from '../../utils/issue/map-state-to-issue-payload';
import { mapIssueFiltersToQueryParams } from '../../utils/issue/query-params';
import { renderErrorJs } from '../../utils/render-error';
import { searchToQuery } from '../../utils/routing-provider';
import { MapFromJs } from '../../utils/types';
import mapStateToLbjPermissions from '../../utils/user/map-state-to-lbj-permissions';

import IssueDetailForm, {
  CommonIssueFormActions,
  makeCommonIssueFormActions,
} from './issue-detail-form';
import { getRequiredFields } from './issue-utils';

type IssueDetailActions = {
  fetchBoilerRooms(issueElection: number): void;
  fetchElectionList(state: State): void;

  fetchIssueComments(id: number): void;
  createIssueComment(comment: ApiNewIssueComment): Promise<unknown> | void;

  uploadDocument(issueId: number, document: File): void;
  deleteDocument(document: ApiIssueDocument): void;

  uploadPhoto(issueId: number, photo: Blob): void;
  deletePhoto(photo: ApiIssuePhoto): void;

  escalateIssue(id: number): Promise<issueActionCreators.EscalateIssueAction>;
  updateIssue(
    issueData: Partial<
      Omit<ApiIssue, 'id'> & {
        comments?: ApiIssueComment[];
      }
    > & { id: string } & ApiIssueActionUpdates
  ): Promise<issueActionCreators.UpdateIssueAction>;
  updateViewOnlySubscribeIssue(
    issueData: { id: string } & ApiIssueActionUpdates
  ): Promise<issueActionCreators.UpdateIssueAction>;
  refreshIssue(id: number, userRole: UserRole): void | Promise<void>;
};

const IssueDetail: React.FunctionComponent<
  {
    issueInProgress: MapFromJs<ExistingIssueInProgress>;
    issuePayload: IssueInProgressPayload;
    currentUserData: MapFromJs<ApiCurrentUser>;
    currentUserID: string;
    existingID: string;
    issueQueryParams: Query;
    errorData: IssueErrorData;
    isFetching: boolean;
    requestIsPending: boolean;
    documentUploadIsPending: boolean;
    photoUploadIsPending: boolean;
    isObserverReadOnly: boolean;
    isVoterImpactedReadOnly: boolean;
    isDescriptionReadOnly: boolean;
    canEditState: boolean;
    canSeePriority: boolean;
    issueElection: number | null;
    historyIsLoading: boolean;
    currentUserElection: ImmutableCurrentUserElection;
    commonActions: CommonIssueFormActions;
    boilerRoomList: Immutable.List<MapFromJs<ApiBoilerRoom>>;
    electionList: Immutable.List<MapFromJs<ApiElection>>;
    toastData: ToastRecord;
  } & IssueDetailActions
> = (props) => {
  const location = useLocation();

  const [numCategories, setNumCategories] = React.useState(
    props.issueInProgress.get('category_3')
      ? 3
      : props.issueInProgress.get('category_2')
      ? 2
      : 1
  );

  const [commentText, setCommentText] = React.useState('');

  const [updatedFields, setUpdatedFields] = React.useState<{
    [field in keyof ExistingIssueInProgress]?:
      | string
      | number
      | boolean
      | null
      | undefined;
  }>({});

  React.useEffect(
    () => {
      if (props.issueElection) {
        props.fetchBoilerRooms(props.issueElection);
      }
    },
    // Disabling the check since we don’t want to rely on fetchBoilerRooms.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.issueElection]
  );

  const issueState = getIssueData().state;

  React.useEffect(
    () => {
      if (issueState) {
        props.fetchElectionList(issueState);
      }
    },
    // Disabling the check since we don’t want to rely on fetchElectionList.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [issueState]
  );

  async function onIssueSave() {
    // It’s okay for these two calls to happen simultaneously. Unlike when
    // creating a new issue, adding a comment to an existing issue can use the
    // ID that’s already there.

    if (commentText) {
      onCommentAdd();
    }

    if (await handleIssueCRUD('UPDATE')) {
      props.commonActions.showToast({
        type: 'success',
        message: `Issue #${existingID} has been updated successfully.`,
      });
    }
  }

  function onInputChange(event: {
    target: {
      name: string;
      value: string | boolean | number | undefined | null;
    };
  }) {
    const { name, value } = event.target;

    // Need to use the updater pattern since this can be called multiple times
    // in a row w/o a re-render between.
    setUpdatedFields((prev) => {
      const newUpdatedFields = {
        ...prev,
        [name]: value,
      };

      if (name === 'category') {
        newUpdatedFields.sub_category = '';
      }

      if (name === 'category_2') {
        newUpdatedFields.sub_category_2 = '';
      }

      if (name === 'category_3') {
        newUpdatedFields.sub_category_3 = '';
      }

      return newUpdatedFields;
    });
  }

  function onSetFields(changes: {
    [field in keyof ExistingIssueInProgress]?: any;
  }) {
    setUpdatedFields((prev) => {
      return {
        ...prev,
        ...changes,
      };
    });
  }

  function onCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCommentText(event.target.value);
  }

  async function onCommentAdd() {
    const { existingID, currentUserID } = props;

    if (!commentText) {
      return;
    }

    await props.createIssueComment({
      ticket: existingID,
      commenter: currentUserID,
      text: commentText,
    });

    props.fetchIssueComments(Number(existingID));

    setCommentText('');
  }

  /** Comment on an existing comment (a reply) */
  async function onReplyAdd(parentId: number, replyText: string) {
    const { existingID, currentUserID } = props;

    await props.createIssueComment({
      ticket: existingID,
      commenter: currentUserID,
      parent: parentId,
      text: replyText,
    });

    props.fetchIssueComments(Number(existingID));
  }

  function onDocumentAdd(document: File) {
    props.uploadDocument(Number(props.existingID), document);
  }

  function onDocumentDelete(document: ApiIssueDocument) {
    props.deleteDocument(document);
  }

  function onPhotoAdd(photo: Blob) {
    props.uploadPhoto(Number(props.existingID), photo);
  }

  function onPhotoDelete(photo: ApiIssuePhoto) {
    props.deletePhoto(photo);
  }

  async function onResolveButtonClick() {
    const { status } = getIssueData();
    const newStatus = status === 'resolved' ? 'open' : 'resolved';

    if (
      await handleIssueCRUD('UPDATE', {
        status: newStatus,
      })
    ) {
      if (newStatus === 'resolved') {
        props.commonActions.showToast({
          type: 'success',
          message: `Nice work. You’ve successfully resolved issue #${existingID}.`,
        });
      } else if (newStatus === 'open') {
        props.commonActions.showToast({
          type: 'success',
          message: `Issue #${existingID} has been marked as unresolved.`,
        });
      }
    }
  }

  async function onSubscribeButtonClick(action: 'UPDATE' | 'VIEW_ONLY_UPDATE') {
    const { existingID } = props;

    const newSubscribed = !isSubscribed();

    if (
      await handleIssueCRUD(action, {
        subscribed: newSubscribed,
      })
    ) {
      if (newSubscribed) {
        props.commonActions.showToast({
          type: 'success',
          message: `You're now subscribed to notifications for issue #${existingID}.`,
        });
      } else {
        props.commonActions.showToast({
          type: 'success',
          message: `Unsubscribed from notifications on issue #${existingID}.`,
        });
      }
    }
  }

  async function onClaimIssue() {
    const { currentUserID } = props;

    if (await handleIssueCRUD('UPDATE', { owner: Number(currentUserID) })) {
      props.commonActions.showToast({
        type: 'success',
        message: `Issue #${existingID} has been updated successfully.`,
      });
    }
  }

  async function onEscalateIssue() {
    if (await handleIssueCRUD('ESCALATE')) {
      props.commonActions.showToast({
        type: 'success',
        message: `Issue #${existingID} has been updated successfully.`,
      });
    }
  }

  function getIssueData() {
    const { issuePayload } = props;

    return {
      ...issuePayload,
      ...updatedFields,
    } as typeof issuePayload;
  }

  /**
   * Updates the current issue.
   *
   * @returns true if it was done successfully.
   */
  async function handleIssueCRUD(
    action: 'ESCALATE' | 'UPDATE' | 'VIEW_ONLY_UPDATE',
    /** Overrides based on what button was clicked to submit the form.  */
    actionUpdates: ApiIssueActionUpdates = {}
  ): Promise<boolean> {
    const { existingID, currentUserData } = props;
    const { SUCCESS, ERROR } = requestStatuses;
    const userRole: UserRole = currentUserData.get('role');

    let data: IssueCrudData;

    switch (action) {
      case 'ESCALATE':
        data = (await props.escalateIssue(Number(existingID))).data;
        break;

      case 'VIEW_ONLY_UPDATE': {
        const subscribePayload = {
          ...actionUpdates,
          id: existingID,
        };
        data = (await props.updateViewOnlySubscribeIssue(subscribePayload))
          .data;
        break;
      }

      case 'UPDATE': {
        const issuePayload = {
          ...updatedFields,
          ...actionUpdates,

          id: existingID,
          current_owner: undefined as string | undefined,
        };

        if ('owner' in issuePayload) {
          const currentOwnerId = props.issueInProgress.getIn([
            'owner',
            'id',
          ]) as number | undefined;

          issuePayload.current_owner = currentOwnerId
            ? currentOwnerId.toString()
            : '';
        }

        // yeah we just have to hack this rn. Too much imprecision around
        // string vs. number and null vs. undefined that the backend papers
        // over.
        data = (await props.updateIssue(issuePayload as any)).data;
        break;
      }
    }

    switch (data.status) {
      case SUCCESS:
        await props.refreshIssue(Number(existingID), userRole);

        // We do this after refreshIssue resolves to avoid a flash of the
        // pre-updated issue while the loading is going on.
        setUpdatedFields({});

        return true;

      case ERROR: {
        const errorMessage = renderErrorJs(
          typeof data.errorData === 'string'
            ? data.errorData
            : 'error_message' in data.errorData
            ? data.errorData
            : {},
          'updating'
        );

        props.commonActions.showToast(errorMessage);
        return false;
      }

      case 'PENDING':
        // This won’t actually be returned from updateIssue, but we include it
        // for type completeness.
        return false;
    }
  }

  function isQaVisible() {
    const { currentUserData, currentUserElection } = props;
    if (currentUserElection.getIn(['election', 'state']) !== 'US') {
      return false;
    }
    if (
      currentUserData.get('role') === 'poll_observer' ||
      currentUserData.get('role') === 'hotline_worker'
    ) {
      return false;
    }
    return true;
  }

  function isSubscribed() {
    const { currentUserID } = props;
    const { watchers } = getIssueData();
    const subscribed = _.filter(watchers, (watcher) => {
      return watcher.id.toString() === currentUserID;
    });
    return subscribed.length > 0;
  }

  const {
    existingID,
    currentUserData,
    issueElection,
    issueQueryParams,
    errorData,
    documentUploadIsPending,
    photoUploadIsPending,
    isObserverReadOnly,
    isVoterImpactedReadOnly,
    isDescriptionReadOnly,
    isFetching,
    canEditState,
    canSeePriority,
    issueInProgress,
    historyIsLoading,
    requestIsPending,
    commonActions,
    boilerRoomList,
    electionList,
    toastData,
  } = props;

  const issueData = getIssueData();

  const formType =
    issueData.source === 'poll_observer' ? 'poll_observer' : 'hotline';

  const requiredFields = getRequiredFields(
    formType,
    issueData.type,
    numCategories
  );

  const featureFlags =
    searchToQuery(location.search)['feature']?.split('|') ?? [];

  return (
    <IssueDetailForm
      action={'edit'}
      currentUserData={currentUserData}
      issueElectionId={issueElection}
      issueData={issueData}
      existingID={existingID}
      issueQueryParams={issueQueryParams}
      toastData={toastData}
      onInputChange={onInputChange}
      onIssueSave={onIssueSave}
      errorData={errorData}
      onClaimIssue={onClaimIssue}
      onCommentChange={onCommentChange}
      onCommentAdd={onCommentAdd}
      onDocumentAdd={onDocumentAdd}
      onDocumentDelete={onDocumentDelete}
      onEscalateIssue={onEscalateIssue}
      requestIsPending={requestIsPending}
      onPhotoAdd={onPhotoAdd}
      onPhotoDelete={onPhotoDelete}
      onReplyAdd={onReplyAdd}
      onResolveButtonClick={onResolveButtonClick}
      onSubscribeButtonClick={onSubscribeButtonClick}
      isSubscribed={isSubscribed.bind(this)}
      commentText={commentText}
      documentUploadIsPending={documentUploadIsPending}
      photoUploadIsPending={photoUploadIsPending}
      isFormTypeVisible={false}
      isObserverReadOnly={isObserverReadOnly}
      isPriorityVisible={canSeePriority}
      isStateReadOnly={!canEditState}
      isVoterImpactedReadOnly={isVoterImpactedReadOnly}
      isDescriptionReadOnly={isDescriptionReadOnly}
      isObserverVisible
      isFetching={isFetching}
      location={location}
      issueInProgress={issueInProgress}
      historyIsLoading={historyIsLoading}
      formType={formType}
      requiredFields={Immutable.List(requiredFields)}
      numCategories={numCategories}
      setNumCategories={setNumCategories}
      canSeeQa={isQaVisible()}
      boilerRoomList={boilerRoomList}
      electionList={electionList}
      commonActions={commonActions}
      preloadedUsers={[
        ...(issueInProgress.get('owner')
          ? [issueInProgress.get('owner').toJS() as ApiUser]
          : []),
        ...(issueInProgress.get('user')
          ? [issueInProgress.get('user').toJS() as ApiUser]
          : []),
      ]}
      onSetFields={onSetFields}
      featureFlags={featureFlags}
    />
  );
};

export default connect(
  (state: AppState) => {
    const { issue, user, lbj } = state;

    const currentUserData = user.currentUser.userData!;
    const currentUserElection = user.currentUser.currentUserElection!;
    const currentUserID: number = currentUserData.get('id');
    const existingID = String(issue.issueInProgress.get('id'));
    const issueInProgress = issue.issueInProgress;
    const issuePayload = mapStateToIssuePayload(state);
    const issueFilters = mapStateToIssueFilters(state);
    const queryParams = mapIssueFiltersToQueryParams(issueFilters.toJS());
    const {
      canEditState,
      canSeePriority,
      enabledIssueFields = [],
    } = mapStateToLbjPermissions(state);
    const isFetching = !!(
      issue.issueDetail.isFetching || issue.issueComments?.isFetching
    );
    const requestIsPending =
      issue.updatingIssue.requestIsPending ||
      issue.creatingComment.requestIsPending;
    const documentUploadIsPending = issue.uploadDocument.requestIsPending;
    const photoUploadIsPending = issue.uploadingPhoto.requestIsPending;
    const isObserverReadOnly = !enabledIssueFields.includes('observer');
    const isVoterImpactedReadOnly = !enabledIssueFields.includes('voter');
    const isDescriptionReadOnly = !enabledIssueFields.includes('description');
    const issueElection = issue.issueElectionRequest.electionId;

    const boilerRoomList = lbj.boiler_rooms.listData;
    const electionList = lbj.elections.listData;

    const props: Omit<
      React.ComponentProps<typeof IssueDetail>,
      'commonActions' | keyof IssueDetailActions
    > = {
      issueInProgress,
      issuePayload,
      currentUserData,
      currentUserID: currentUserID ? currentUserID.toString() : '',
      existingID,
      issueQueryParams: queryParams,
      errorData: issue.updatingIssue.errorData,
      isFetching,
      requestIsPending,
      documentUploadIsPending,
      photoUploadIsPending,
      isObserverReadOnly,
      isVoterImpactedReadOnly,
      isDescriptionReadOnly,
      canEditState,
      canSeePriority,
      issueElection,
      historyIsLoading: issue.issueHistoryRequest.requestIsPending,
      currentUserElection,
      boilerRoomList,
      electionList,
      toastData: issue.toastData,
    };

    return props;
  },
  (
    dispatch: AppDispatch
  ): { commonActions: CommonIssueFormActions } & IssueDetailActions => ({
    commonActions: makeCommonIssueFormActions(dispatch),

    fetchBoilerRooms(issueElection) {
      dispatch(
        lbjActionCreators.getBoilerRoomListAsync({
          query_election_id: issueElection,
          size: 20,
        })
      );
    },

    fetchElectionList(state) {
      dispatch(lbjActionCreators.getElectionListAsync(state));
    },

    fetchIssueComments(id) {
      dispatch(issueActionCreators.getIssueCommentsAsync(id));
    },

    createIssueComment(comment) {
      return dispatch(issueActionCreators.createIssueCommentAsync(comment));
    },

    uploadDocument(issueId, document) {
      dispatch(
        issueActionCreators.uploadIssueDocumentAsync({
          ticket: issueId.toString(),
          document,
        })
      );
    },
    deleteDocument(document) {
      dispatch(issueActionCreators.deleteIssueDocumentAsync(document));
    },

    uploadPhoto(issueId, photo) {
      dispatch(
        issueActionCreators.uploadIssuePhotoAsync({
          ticket: issueId.toString(),
          photo,
        })
      );
    },
    deletePhoto(photo) {
      dispatch(issueActionCreators.deleteIssuePhotoAsync(photo));
    },

    escalateIssue(id) {
      return dispatch(issueActionCreators.escalateIssueAsync(id));
    },

    updateIssue(issueData) {
      return dispatch(issueActionCreators.updateIssueAsync(issueData));
    },

    updateViewOnlySubscribeIssue(issueData) {
      return dispatch(
        issueActionCreators.updateViewOnlySubscribeIssueAsync(issueData)
      );
    },

    async refreshIssue(id, userRole) {
      await Promise.all([
        dispatch(issueActionCreators.getIssueAsync(id)),
        dispatch(issueActionCreators.getIssueHistoryAsync(userRole, id)),
      ]);
    },
  })
)(IssueDetail);
