import cx from 'classnames';
import React, { Component } from 'react';

import inputProps from '../../../decorators/input-props';
import RIT from '../../../utils/render-if-truthy';

@inputProps() /* eslint-disable react/prop-types */
export default class ToggleInput extends Component {
  static defaultProps = {
    type: 'checkbox',
  };

  handleChange(event) {
    const { name, onChange } = this.props;
    const { checked } = event.target;

    if (onChange) {
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    }
  }

  renderInput() {
    const { name, title, value, errors, ...rest } = this.props;

    return (
      <label className="lbj-toggle-wrapper">
        <input
          id={name}
          name={name}
          {...rest}
          onChange={this.handleChange.bind(this)}
        />
        <span className="lbj-toggle-indicator" />
        <span className="lbj-toggle-title">{title}</span>
      </label>
    );
  }

  renderError(error, key) {
    return (
      <p className="c-error-message" key={key}>
        {error}
      </p>
    );
  }

  render() {
    const { name, errors } = this.props;
    const className = cx('lbj-input', 'lbj-input-toggle', `lbj-input-${name}`);

    return (
      <div className={className}>
        {this.renderInput()}
        {RIT(errors.length, () => {
          return errors.map((error, key) => this.renderError(error, key));
        })}
      </div>
    );
  }
}
