import * as Immutable from 'immutable';

import { requestStatuses, State } from '../../constants';
import * as NotificationsService from '../../services/notifications-service';
import {
  ListRequestData,
  ListResponse,
} from '../../utils/lbj/list-request-state-handler';
import getDefaultNotificationFilters from '../../utils/notifications/default-filters';
import mapStateToNotificationsFilters from '../../utils/notifications/map-state-to-notifications-filters';
import { FiltersBySection } from '../filters/action-creators';
import { AppState, AppThunk } from '../flux-store';

import actionTypes from './action-types';

const { PENDING, SUCCESS, ERROR } = requestStatuses;

const {
  GET_LOCATION_CHANGES,
  GET_NOTIFICATIONS_EXIST,
  GET_UNACKED_LOCATION_CHANGE_COUNT,
  GET_UNSEEN_NOTIF_COUNT,
  GET_ISSUE_NOTIFICATIONS,
  STORE_POLLING_INTERVAL,
  BACK_OFF_POLLING_INTERVAL,
} = actionTypes;

export type Action =
  | GetLocationChangesAction
  | GetNotificationsExistAction
  | GetIssueNotificationsAction
  | GetUnseenNotificationCount
  | GetUnackedLocationChangeCountAction
  | StorePollingIntervalAction
  | BackOffPollingIntervalAction;

export type GetLocationChangesAction = {
  type: typeof GET_LOCATION_CHANGES;
  // TODO(fiona): Still haven’t seen what a location change notification looks
  // like.
  data: ListRequestData<{}>;
};

function requestLocationChanges(): GetLocationChangesAction {
  return {
    type: GET_LOCATION_CHANGES,
    data: {
      status: PENDING,
    },
  };
}

function receiveLocationChanges(
  listResponse: ListResponse<{}>
): GetLocationChangesAction {
  return {
    type: GET_LOCATION_CHANGES,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingLocationChanges(): GetLocationChangesAction {
  return {
    type: GET_LOCATION_CHANGES,
    data: {
      status: ERROR,
    },
  };
}

export type GetNotificationsExistAction = {
  type: typeof GET_NOTIFICATIONS_EXIST;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        notificationsExist: boolean;
      }
    | { status: typeof ERROR };
};

function requestNotificationsExist(): GetNotificationsExistAction {
  return {
    type: GET_NOTIFICATIONS_EXIST,
    data: {
      status: PENDING,
    },
  };
}

function receiveNotificationsExist(response: {
  status: boolean;
}): GetNotificationsExistAction {
  return {
    type: GET_NOTIFICATIONS_EXIST,
    data: {
      status: SUCCESS,
      notificationsExist: response.status,
    },
  };
}

function errorGettingNotificationsExist(): GetNotificationsExistAction {
  return {
    type: GET_NOTIFICATIONS_EXIST,
    data: {
      status: ERROR,
    },
  };
}

export type GetIssueNotificationsAction = {
  type: typeof GET_ISSUE_NOTIFICATIONS;
  data: ListRequestData<NotificationsService.ApiTicketNotification>;
};

function requestIssueChanges(): GetIssueNotificationsAction {
  return {
    type: GET_ISSUE_NOTIFICATIONS,
    data: {
      status: PENDING,
    },
  };
}

function receiveIssueChanges(
  listResponse: ListResponse<NotificationsService.ApiTicketNotification>
): GetIssueNotificationsAction {
  return {
    type: GET_ISSUE_NOTIFICATIONS,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingIssueChanges(error: {
  detail: string;
}): GetIssueNotificationsAction {
  return {
    type: GET_ISSUE_NOTIFICATIONS,
    data: {
      status: ERROR,
      error,
    },
  };
}

export type GetUnseenNotificationCount = {
  type: typeof GET_UNSEEN_NOTIF_COUNT;
  data:
    | { status: typeof PENDING }
    | {
        status: typeof SUCCESS;
        counts: NotificationsService.ApiTicketCounts;
      }
    | { status: typeof ERROR };
};

function requestUnseenNotificationCounts(): GetUnseenNotificationCount {
  return {
    type: GET_UNSEEN_NOTIF_COUNT,
    data: {
      status: PENDING,
    },
  };
}

function receiveUnseenNotificationCounts({
  tickets,
  location_change,
}: NotificationsService.ApiTicketCounts): GetUnseenNotificationCount {
  return {
    type: GET_UNSEEN_NOTIF_COUNT,
    data: {
      status: SUCCESS,
      counts: {
        tickets,
        location_change,
      },
    },
  };
}

function errorReceivingNotificationCounts(): GetUnseenNotificationCount {
  return {
    type: GET_UNSEEN_NOTIF_COUNT,
    data: {
      status: ERROR,
    },
  };
}

export type GetUnackedLocationChangeCountAction = {
  type: typeof GET_UNACKED_LOCATION_CHANGE_COUNT;
  data: {
    status: typeof SUCCESS;
    count: number;
  };
};

function receiveUnackedLocationChangeCount(
  count: number
): GetUnackedLocationChangeCountAction {
  return {
    type: GET_UNACKED_LOCATION_CHANGE_COUNT,
    data: {
      status: SUCCESS,
      count,
    },
  };
}

export type StorePollingIntervalAction = {
  type: typeof STORE_POLLING_INTERVAL;
  data: {
    pollingInterval: number;
  };
};

export function storePollingInterval(
  pollingInterval: number
): StorePollingIntervalAction {
  return {
    type: STORE_POLLING_INTERVAL,
    data: { pollingInterval },
  };
}

export type BackOffPollingIntervalAction = {
  type: typeof BACK_OFF_POLLING_INTERVAL;
};

export function backoffPollingInterval(): BackOffPollingIntervalAction {
  return {
    type: BACK_OFF_POLLING_INTERVAL,
  };
}

export function getNotificationsAsync(
  /**
   * getNotificationsAsync is passed an Immutable.Map, unlike other async
   * functions that tend to take a plain JS object for filters.
   */
  filters: Immutable.Map<
    keyof FiltersBySection['NOTIFICATIONS'],
    string | null | undefined
  >
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(requestLocationChanges());

      return NotificationsService.getLocationChanges(filters.toJS()).then(
        ({ offset, size, location_changes }) => {
          const listResponse = {
            offset,
            size,
            listData: location_changes as {}[],
          };
          dispatch(receiveLocationChanges(listResponse));
          resolve(getState());
        },
        () => dispatch(errorReceivingLocationChanges())
      );
    });
  };
}

export function getNotificationsExist(): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    const filters = getDefaultNotificationFilters(getState());

    return new Promise((resolve) => {
      if (!filters.state) {
        dispatch(errorGettingNotificationsExist());
        resolve(getState());
      } else {
        dispatch(requestNotificationsExist());

        NotificationsService.notificationsExist(filters).then(
          (notificationsExist) => {
            dispatch(receiveNotificationsExist(notificationsExist));
            resolve(getState());
          },
          () => {
            dispatch(errorGettingNotificationsExist());
            resolve(getState());
          }
        );
      }
    });
  };
}

export function updateLocationChanges(
  ackedState: 'seen' | 'acked' | 'unacked',
  locationChangeIds: Immutable.List<number>
): AppThunk<Promise<unknown>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const payload = {
        [ackedState]: locationChangeIds.toArray(),
      };

      NotificationsService.updateNotifications(payload).then(() => {
        const filters = mapStateToNotificationsFilters(getState());
        dispatch(getNotificationsAsync(filters));
        dispatch(getNotificationsExist());
        resolve(getState());
      });
    });
  };
}

export function getIssueNotificationsAsync(
  page: { offset?: number; size?: number } = {}
): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(requestIssueChanges());

      NotificationsService.getIssueNotifications(page).then(
        ({ offset, size, ticket_notifications }) => {
          const listResponse = {
            offset,
            size,
            listData: ticket_notifications,
          };
          dispatch(receiveIssueChanges(listResponse));
          resolve(getState());
        },
        (error) => {
          dispatch(errorReceivingIssueChanges(error));
          resolve(getState());
        }
      );
    });
  };
}

export function getUnseenNotificationCounts(): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch(requestUnseenNotificationCounts());

      NotificationsService.getUnseenNotificationCounts().then(
        (counts) => {
          dispatch(receiveUnseenNotificationCounts(counts));
          dispatch(storePollingInterval(counts.polling_interval));
          resolve(getState());
        },
        () => {
          dispatch(errorReceivingNotificationCounts());
          resolve(getState());
        }
      );
    });
  };
}

export function getUnackedLocationChangeCount(): AppThunk<Promise<AppState>> {
  return (dispatch, getState) => {
    const assignmentState = getState().user.getIn([
      'currentUser',
      'userData',
      'assignment_state',
    ]) as State;

    return new Promise((resolve) => {
      NotificationsService.getLocationChangeCount({
        acked: false,
        state: assignmentState,
      }).then(({ count }) => {
        dispatch(receiveUnackedLocationChangeCount(count));
        resolve(getState());
      });
    });
  };
}

export function ackIssueNotifications(
  notificationIds: number[]
): AppThunk<Promise<unknown>> {
  return () => {
    return NotificationsService.updateIssueNotifications({
      acked: notificationIds,
    });
  };
}

export function markIssuesAsSeen(
  filters: Partial<FiltersBySection['NOTIFICATIONS']>,
  queryParams: { bulk_update_location_changes?: boolean }
): AppThunk<Promise<unknown>> {
  return () => {
    return NotificationsService.markAllNotificationsAsSeen(
      filters,
      queryParams
    );
  };
}
