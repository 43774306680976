import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';

export default class AssignmentTimeline extends Component {
  static propTypes = {
    assignmentData: PropTypes.object,
    timelineStartHour: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    isAssigning: PropTypes.bool,
    isExistingAssignment: PropTypes.bool,
    showTimesInLabel: PropTypes.bool,
    showLabelAsCaption: PropTypes.bool,
  };

  static defaultProps = {
    timelineStartHour: 5,
    showTimesInLabel: false,
    showLabelAsCaption: false,
    onClick: () => {},
  };

  getAssignmentLabel() {
    const { assignmentData } = this.props;
    const precinctName = assignmentData.getIn(['precinct', 'name']);
    const locationName = assignmentData.getIn(['location', 'name']);

    if (assignmentData.hasIn(['user', 'first_name'])) {
      return `${assignmentData.getIn([
        'user',
        'first_name',
      ])} ${assignmentData.getIn(['user', 'last_name'])}`;
    }

    if (precinctName && !locationName) {
      return `${precinctName} - No Location`;
    }

    if (!precinctName && locationName) {
      return `${locationName} - All Precincts`;
    }

    if (precinctName && locationName) {
      return `${precinctName} - ${locationName}`;
    }

    if (assignmentData.hasIn(['boiler_room', 'name'])) {
      return `${assignmentData.getIn(['boiler_room', 'name'])}`;
    }

    if (assignmentData.hasIn(['board_of_elections', 'name'])) {
      return `${assignmentData.getIn(['board_of_elections', 'name'])}`;
    }
  }

  getIsCheckedIn() {
    const { assignmentData } = this.props;

    return (
      assignmentData.has('checkin_set') &&
      !assignmentData.get('checkin_set').isEmpty()
    );
  }

  renderAssignment() {
    const {
      assignmentData,
      timelineStartHour,
      showTimesInLabel,
      showLabelAsCaption,
    } = this.props;
    const shiftStart = moment(assignmentData.get('start_time'), 'HH:mm:ss');
    const shiftEnd = moment(assignmentData.get('end_time'), 'HH:mm:ss');
    const shiftStartHours = shiftStart.hours();
    const shiftEndHours = shiftEnd.hours();
    const widthClass = `timeline-width-${shiftEndHours - shiftStartHours}`;
    const offsetClass = `timeline-offset-${
      shiftStartHours - timelineStartHour
    }`;
    const className = cx(
      'assignment-timeline-object',
      widthClass,
      offsetClass,
      {
        'is-rover': assignmentData.get('rover'),
        'is-checked-in': this.getIsCheckedIn(),
      }
    );

    return (
      <div className={className}>
        <span
          className="assignment-data-label"
          title={this.getAssignmentLabel()}
        >
          {showTimesInLabel &&
            `${this.renderShortTime(shiftStart)}–${this.renderShortTime(
              shiftEnd
            )}`}
          {showTimesInLabel && !showLabelAsCaption && ': '}
          {!showLabelAsCaption && this.getAssignmentLabel()}
        </span>
      </div>
    );
  }

  renderCaption() {
    return <div>{this.getAssignmentLabel()}</div>;
  }

  /**
   * @param {moment.Moment} time Moment to render as a string.
   * @returns {string} The time value of the given Moment as a string of either
   * _e.g._ “6am” if the minutes are 0, or “6:30am” if they’re not.
   */
  renderShortTime(time) {
    if (time.minutes() === 0) {
      return time.format('ha');
    } else {
      return time.format('h:mma');
    }
  }

  renderAssignmentPlaceholder() {
    return (
      <div className="assignment-timeline-placeholder">
        <span className="assignment-data-label">New Assignment</span>
      </div>
    );
  }

  renderTimelineWindow(startTimeLabel, endTimeLabel) {
    return (
      <span className="assignment-timeline-window">
        <span className="window-start">{startTimeLabel}</span>

        {RIT(endTimeLabel, () => {
          return <span className="window-end">{endTimeLabel}</span>;
        })}
      </span>
    );
  }

  renderTimeline() {
    return (
      <div className="assignment-timeline">
        {this.renderTimelineWindow('5a')}
        {this.renderTimelineWindow('6a')}
        {this.renderTimelineWindow('7a')}
        {this.renderTimelineWindow('8a')}
        {this.renderTimelineWindow('9a')}
        {this.renderTimelineWindow('10a')}
        {this.renderTimelineWindow('11a')}
        {this.renderTimelineWindow('12p')}
        {this.renderTimelineWindow('1p')}
        {this.renderTimelineWindow('2p')}
        {this.renderTimelineWindow('3p')}
        {this.renderTimelineWindow('4p')}
        {this.renderTimelineWindow('5p')}
        {this.renderTimelineWindow('6p')}
        {this.renderTimelineWindow('7p')}
        {this.renderTimelineWindow('8p')}
        {this.renderTimelineWindow('9p')}
        {this.renderTimelineWindow('10p')}
        {this.renderTimelineWindow('11p', '12a')}
      </div>
    );
  }

  render() {
    const { isAssigning, isExistingAssignment, onClick, showLabelAsCaption } =
      this.props;
    const className = cx('assignment-timeline-wrapper', {
      'is-assigning': isAssigning,
    });

    return (
      <div className={className}>
        <div onClick={onClick}>
          {this.renderTimeline()}
          <div className="assignment-data">
            {RIT(
              isAssigning && !isExistingAssignment,
              this.renderAssignmentPlaceholder.bind(this)
            )}
            {RIT(isExistingAssignment, this.renderAssignment.bind(this))}
          </div>
          {RIT(showLabelAsCaption, this.renderCaption.bind(this))}
        </div>
      </div>
    );
  }
}
