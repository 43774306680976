import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class AssignmentGroupHeader extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
  };

  render() {
    const { label, ...rest } = this.props;

    return (
      <div className="row" {...rest}>
        <div className="col-small-12">
          <span className="assignment-related-object-primary-label">
            {label}
          </span>
        </div>
      </div>
    );
  }
}
