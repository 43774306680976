import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import { FILTERS_EXISTING } from '../../../constants';
import { queryToSearch } from '../../../utils/routing-provider';

export default class NavLink extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.any,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    /** Pass true to keep from adding "?filters=existing" to the link */
    noFilters: PropTypes.bool,
  };

  render() {
    const { children, className, to, noFilters } = this.props;
    const pathname = typeof to === 'string' ? to : to.pathname;
    const query = typeof to === 'string' ? {} : to.query || {};

    if (!noFilters) {
      // TODO(fiona): DON’T MUTATE PROPS
      query.filters = FILTERS_EXISTING;
    }

    return (
      <ReactRouterNavLink
        className={({ isActive }) => cx(className, { 'is-active': isActive })}
        to={{ pathname, search: queryToSearch(query) }}
      >
        {children}
      </ReactRouterNavLink>
    );
  }
}
