import moment from 'moment';
import React from 'react';

import { ApiElectionDay } from '../../../services/lbj-shared-service';
import { numberToStringWithCommas } from '../../../utils/strings';

export const HEADER_ROW_HEIGHT_PX = 88;

export type AssignmentUserCounts = {
  assignedUserCount: number;
  suggestedUserCount: number;
  totalUserCount: number;

  suggestionCount: number;
  suggestedLocationCount: number;
};

export const DAY_COLUMN_MIN_WIDTH_PX = 96;
export const DAY_COLUMN_MIN_EXPANDED_WIDTH_PX = 260;

export const DateColumnHeader: React.FunctionComponent<{
  day: ApiElectionDay;
  userCounts: AssignmentUserCounts | null;
  suggestionCount: number;
}> = ({ day, userCounts, suggestionCount }) => (
  <div className="flex h-full w-full cursor-pointer items-stretch justify-between">
    <div className="flex w-full flex-1 flex-col justify-center text-left text-gray-700">
      <div className="flex justify-between">
        <div>
          <div className="mb-0.5 text-xs uppercase leading-none">
            {moment(day.date).format('ddd')}
          </div>

          <div className="text-lg">
            {/* non-breaking space between month and day */}
            {moment(day.date).format('MMM\u00A0D')}
          </div>
        </div>

        {userCounts === null && suggestionCount > 0 && (
          <div className="flex items-center gap-1 self-center rounded-sm bg-purple-300 p-1 leading-none">
            <span className="material-icons text-sm text-gray-700">
              smart_toy
            </span>{' '}
            <span className="font-bold">{suggestionCount}</span>
          </div>
        )}
      </div>

      <div
        className={`${
          day.is_early_vote ? 'bg-green-500' : 'bg-accent'
        } mt-2 w-full max-w-[140px] whitespace-nowrap rounded-full text-center text-xs font-normal leading-relaxed text-white`}
      >
        {day.is_early_vote ? 'Early Vote' : 'Election Day'}
      </div>
    </div>

    {userCounts !== null && userCounts.totalUserCount > 0 && (
      <div className="flex flex-col justify-center gap-1">
        <div className="text-sm font-bold text-gray-700">
          {userCounts.assignedUserCount}/{userCounts.totalUserCount} volunteers
          assigned
        </div>

        <div className="flex h-6 items-stretch self-stretch border border-gray-500 bg-gray-200">
          <div
            className="bg-primary"
            style={{
              width: `${Math.floor(
                (userCounts.assignedUserCount / userCounts.totalUserCount) * 100
              )}%`,
            }}
          />
          <div
            className="bg-purple-500"
            style={{
              width: `${Math.floor(
                (userCounts.suggestedUserCount / userCounts.totalUserCount) *
                  100
              )}%`,
            }}
          />
        </div>
      </div>
    )}
  </div>
);

/**
 * Shows the number of locations.
 */
export const LocationsColumnHeader: React.FunctionComponent<{
  locationCount: number;
}> = ({ locationCount }) => {
  return (
    <div className="mb-1 self-end text-base font-bold leading-none text-gray-700">
      {numberToStringWithCommas(locationCount)} locations
    </div>
  );
};

/**
 * Shows the number of people.
 */
export const PeopleColumnHeader: React.FunctionComponent<{
  peopleCount: number;
}> = ({ peopleCount }) => {
  return (
    <div className="mb-1 self-end text-base font-bold leading-none text-gray-700">
      {numberToStringWithCommas(peopleCount)} people
    </div>
  );
};
