import keyMirror from 'key-mirror';

export default keyMirror({
  ALL: null,
  CREATED_BY_ME: null,
  ASSIGNED_TO_ME: null,
  BOILER_ROOM: null,
  ME_WATCHING: null,
  RESOLVED: null,
});
