import { requestStatuses } from '../../constants';
import * as DashboardService from '../../services/dashboard-service';
import {
  ListRequestData,
  ListResponse,
} from '../../utils/lbj/list-request-state-handler';
import { FiltersBySection } from '../filters/action-creators';
import { AppThunk } from '../flux-store';

import actionTypes from './action-types';

const { GET_INCIDENT_ANALYTICS } = actionTypes;

const { PENDING, SUCCESS, ERROR } = requestStatuses;

export type Action = GetIncidentAnalyticsAction;

export type GetIncidentAnalyticsAction = {
  type: typeof GET_INCIDENT_ANALYTICS;
  data: ListRequestData<DashboardService.ApiTicketSummaryResult>;
};

function requestIncidentAnalytics(): GetIncidentAnalyticsAction {
  return {
    type: GET_INCIDENT_ANALYTICS,
    data: {
      status: PENDING,
    },
  };
}

function receiveIncidentAnalytics(
  listResponse: ListResponse<DashboardService.ApiTicketSummaryResult>
): GetIncidentAnalyticsAction {
  return {
    type: GET_INCIDENT_ANALYTICS,
    data: {
      status: SUCCESS,
      listResponse,
    },
  };
}

function errorReceivingIncidentAnalytics(): GetIncidentAnalyticsAction {
  return {
    type: GET_INCIDENT_ANALYTICS,
    data: {
      status: ERROR,
    },
  };
}

export function getIncidentAnalyticsAsync(
  filters: {
    size: number | string;
  } & FiltersBySection['ANALYTICS']
): AppThunk<Promise<unknown>> {
  return (dispatch) => {
    dispatch(requestIncidentAnalytics());

    // TODO(fiona): Make these query params typesafe when moving off of Redux.
    return DashboardService.getSummaryList(filters as any).then(
      ({ results, size, offset }) => {
        const listResponse = {
          listData: results,
          size,
          offset,
        };
        return dispatch(receiveIncidentAnalytics(listResponse));
      },
      () => dispatch(errorReceivingIncidentAnalytics())
    );
  };
}
