import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { assignmentAvailabilityTags } from '../../../constants';
import RIT from '../../../utils/render-if-truthy';
import {
  isExcluded,
  convertToExcludedTag,
  extractExcludedTagName,
  getExcludedTags,
  getIncludedTags,
} from '../../../utils/user/tags';
import TagPicker from '../../containers/form/tag-picker-container';
import Checkbox from '../form/checkbox';
import DebouncedInput from '../form/debounced-input';
import HiddenInput from '../form/hidden-input';

export default class UserSearchForm extends Component {
  static propTypes = {
    county: PropTypes.object,
    stateFilter: PropTypes.string,
    shiftDate: PropTypes.string.isRequired,
    assignmentType: PropTypes.string,
    onSearchForUsers: PropTypes.func.isRequired,
    onShowOutOfRange: PropTypes.func.isRequired,
    voteDays: PropTypes.object.isRequired,
    defaultSearchFilters: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      availability_checked: false,
    };

    this.searchFilters = {
      tags: [], // VPDs are not regularly using user tags so none should appear by default
    };

    this.onShowOutOfRange = this.onShowOutOfRange.bind(this);
  }

  componentDidMount() {
    this.props.onSearchForUsers(this.searchFilters);
  }

  componentDidUpdate(prevProps) {
    const currentSelectedLocationId =
      this.props.defaultSearchFilters.sort_from_location;
    const previousSelectedLocationId =
      prevProps.defaultSearchFilters.sort_from_location;

    if (currentSelectedLocationId !== previousSelectedLocationId) {
      this.props.onSearchForUsers(this.searchFilters);
    }
  }

  onSearchChange({ target }) {
    const searchFilters = this.searchFilters;
    searchFilters.name_prefix = target.value;

    this.searchFilters = searchFilters;
    this.props.onSearchForUsers(this.searchFilters);
  }

  onShowOutOfRange({ target }) {
    const { onShowOutOfRange } = this.props;

    let checked = false;
    if (target.checked) {
      checked = true;
    }
    this.setState({ showOutOfRange: checked });
    onShowOutOfRange(checked);
  }

  onTagPickerChange(selectedTags) {
    // don't eliminate excluded tags unless its inversion is in the
    // selected tags
    this.searchFilters.tags.forEach((tag) => {
      if (
        isExcluded(tag) &&
        !selectedTags.toJS().includes(extractExcludedTagName(tag))
      ) {
        selectedTags.push(tag);
      }
    });
    this.searchFilters.tags = selectedTags.toJS();

    this.props.onSearchForUsers(this.searchFilters);
  }

  onTagPickerExclude(selectedTags) {
    const invertedTags = selectedTags
      .toJS()
      .map((tag) => convertToExcludedTag(tag));
    this.searchFilters.tags.forEach((tag) => {
      if (
        (!isExcluded(tag) &&
          !invertedTags.includes(convertToExcludedTag(tag))) ||
        assignmentAvailabilityTags.includes(tag)
      ) {
        invertedTags.push(tag);
      }
    });
    this.searchFilters.tags = invertedTags;

    this.props.onSearchForUsers(this.searchFilters);
  }

  onOnlyAvailableChange({ target }) {
    if (target.checked) {
      this.searchFilters.tags = this.searchFilters.tags.concat(
        assignmentAvailabilityTags
      );
      this.setState({ availability_checked: true });
    } else {
      this.searchFilters.tags = this.searchFilters.tags.filter(
        (tag) => !assignmentAvailabilityTags.includes(tag)
      );
      this.setState({ availability_checked: false });
    }
    this.props.onSearchForUsers(this.searchFilters);
  }

  onCountyInputChange({ target }) {
    const { name } = target;
    const { county } = this.props;

    if (target.checked) {
      this.searchFilters[name] = county.get('slug');
    } else {
      delete this.searchFilters[name];
    }

    this.props.onSearchForUsers(this.searchFilters);
  }

  onStateInputChange({ target }) {
    const { stateFilter } = this.props;

    if (target.checked) {
      this.searchFilters.state = stateFilter;
    } else {
      delete this.searchFilters.state;
    }

    this.props.onSearchForUsers(this.searchFilters);
  }

  getDefaultVolRoleTag(shiftDate, assignmentType, voteDays) {
    // TODO: handle this better, but for now satisfy
    //       product req to by default filter users based
    //       on their assigned availability role.
    const isElectionDay = voteDays
      .filter((d) => !d.get('is_early_vote'))
      .map((d) => d.get('date'))
      .contains(shiftDate);

    switch (assignmentType) {
      case 'hotline_center':
      case 'hotline_manager':
        if (isElectionDay) {
          return ['election_day_hotline_worker_volunteer'];
        }

        return ['early_vote_hotline_worker_volunteer'];

      case 'boiler_room':
        if (isElectionDay) {
          return ['election_day_boiler_room_volunteer'];
        }

        return ['early_vote_boiler_room_volunteer'];

      case 'board_of_elections':
      case 'poll':
      default:
        if (isElectionDay) {
          return ['election_day_poll_observer_volunteer'];
        }

        return ['early_vote_poll_observer_volunteer'];
    }
  }

  shouldBeChecked(filter) {
    return _.includes(Object.keys(this.searchFilters), filter);
  }

  searchFilters = {};

  render() {
    const { county } = this.props;
    return (
      <div>
        <div className="lbj-column-label">
          <h4>User</h4>
        </div>

        <div className="lbj-column-content">
          <TagPicker
            title="Tags"
            name="tag"
            onChange={this.onTagPickerChange.bind(this)}
            excludes={[]}
            defaultValue={getIncludedTags(this.searchFilters.tags)}
          />

          <TagPicker
            title="Tags to exclude"
            name="excluded_tags"
            onChange={this.onTagPickerExclude.bind(this)}
            excludes={[]}
            defaultValue={getExcludedTags(this.searchFilters.tags)}
          />

          <div className="row">
            <div className="col-small-12">
              <Checkbox
                showLabel
                title="Available all day"
                name="availablity"
                checked={this.state.availability_checked}
                onChange={this.onOnlyAvailableChange.bind(this)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-small-12">
              <Checkbox
                showLabel
                title="Show out of range"
                name="distance"
                id="distance" // for testing
                checked={this.state.showOutOfRange}
                onChange={this.onShowOutOfRange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-small-12">
              <Checkbox
                showLabel
                title="Registered in state"
                name="state"
                checked={this.shouldBeChecked('state')}
                onChange={this.onStateInputChange.bind(this)}
              />
            </div>
          </div>

          {RIT(county, () => {
            return (
              <div>
                <div className="row">
                  <div className="col-small-12">
                    <Checkbox
                      showLabel
                      title="Registered in county"
                      name="county"
                      checked={this.shouldBeChecked('county')}
                      onChange={this.onCountyInputChange.bind(this)}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <HiddenInput ctaText="Select a user">
            <DebouncedInput
              title="Find a user"
              name="name_prefix"
              placeholder="Name"
              onChange={this.onSearchChange.bind(this)}
            />
          </HiddenInput>
        </div>
      </div>
    );
  }
}
