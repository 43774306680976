import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PageHeader } from '../../common';

import NotificationListWrapper from '../../presentational/notifications/notification-list-wrapper';

import IssueNotificationList from './issue-notification-paginated-list';

@connect((state) => {
  const { notifications } = state;

  return {
    notificationListIsEmpty: notifications
      .getIn(['issue', 'changeList', 'listData'])
      .isEmpty(),
    isLoadingNotifications: notifications.getIn([
      'issue',
      'changeList',
      'requestIsPending',
    ]),
  };
})
export default class IssueNotificationLanding extends Component {
  static propTypes = {
    notificationListIsEmpty: PropTypes.bool.isRequired,
    isLoadingNotifications: PropTypes.bool.isRequired,
  };

  render() {
    const { notificationListIsEmpty, isLoadingNotifications } = this.props;

    return (
      <div className="lbj-page-wrapper">
        <PageHeader title="Issue Notifications" />

        <div className="lbj-page-columns">
          <div className="lbj-main">
            <div className="lbj-column-content-wrapper">
              <NotificationListWrapper
                notificationListIsEmpty={notificationListIsEmpty}
                isLoadingNotifications={isLoadingNotifications}
              >
                <IssueNotificationList />
              </NotificationListWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
