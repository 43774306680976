import { FormState, FORM_ERROR } from 'final-form';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader, PageTitle2 } from '../../components/common';
import { ActionButton } from '../../components/form';
import { ModalDialog } from '../../components/layout';
import { US_NATIONAL_STATE_CODE } from '../../constants';

import { AppStore } from '../../modules/flux-store';
import { loadCurrentUser } from '../../route-handlers/app';
import lbjSharedService, {
  ApiElection,
  ApiUserTag,
} from '../../services/lbj-shared-service';
import userService, { ApiUser } from '../../services/user-service';
import { formatPhoneNumber } from '../../utils/user/phone-numbers';

import UserForm, {
  UserFormValues,
  UserUpdates,
  USER_FIELD_LABELS,
} from './UserForm';

const USER_FORM_ID = 'new-user-form';

/**
 * Type for managing the case when a user already exists in the DB with the same
 * email address.
 *
 * This object includes confirm/cancel functions because the display of the
 * modal is intercepting a specific form submission; calling these functions
 * resolve a promise that continues that submission.
 */
type DuplicateUserInfo = {
  /** The user we’re submitting. */
  newUser: UserUpdates;
  /** Values from the existing user, as returned by the backend. */
  differences: {
    [key in keyof UserUpdates]: string;
  };
  /** Re-submit the duplicate user. */
  confirm: () => void;
  /** Ignore and cancel the dialog. */
  cancel: () => void;
};

/**
 * Page for adding a new user to the election.
 */
const NewUserPage: React.FunctionComponent<{
  currentElection: ApiElection;
  tagData: ApiUserTag[];
}> = ({ currentElection, tagData }) => {
  const navigate = useNavigate();

  /**
   * User passed in to the form. We keep this in state so that we can clear the
   * form after success.
   */
  const [formUser, setFormUser] = React.useState<Required<UserUpdates>>(
    makeBlankUser(currentElection)
  );

  /**
   * {@link ApiUser} object of the most recently-created user.
   */
  const [createdUser, setCreatedUser] = React.useState<ApiUser | null>(null);

  /**
   * State for when a new user is passed in with the same email address as an
   * existing user. The admin is asked to confirm updating the existing user via
   * a modal.
   */
  const [duplicateUserInfo, setDuplicateUserInfo] =
    React.useState<DuplicateUserInfo | null>(null);

  const [
    { hasValidationErrors, submitFailed, submitting, submitError, pristine },
    setFormState,
  ] = React.useState<
    Pick<
      FormState<UserFormValues>,
      | 'hasValidationErrors'
      | 'submitFailed'
      | 'submitting'
      | 'submitError'
      | 'pristine'
    >
  >({
    hasValidationErrors: false,
    submitFailed: false,
    submitting: false,
    submitError: null,
    // pristine defaults to true
    pristine: true,
  });

  /**
   * onSubmit callback for Final Form. Its signature is that an undefined return
   * value is a success, whereas an object return value is a map of errors.
   */
  const onSubmit = React.useCallback(
    async (newUser: UserUpdates & { confirmed?: boolean }) => {
      // clear the old success message
      setCreatedUser(null);

      try {
        const result = await userService.createUser({
          ...newUser,
          // we know role will be defined because we’re passing mayEditRoleFields
          role: newUser.role!,
          election_id: currentElection.id,
        });

        // This resets the form so we can make more users! We do it on
        // setImmediate so that the form’s submit handler can complete before we
        // start resetting its state.
        setImmediate(() => setFormUser(makeBlankUser(currentElection)));

        setCreatedUser(result.user);

        return undefined;
      } catch (e: any) {
        let formError = 'There was an unknown error creating that user.';

        // Sometimes the backend returns an array of error message strings.
        if (Array.isArray(e) && typeof e[0] === 'string') {
          formError = e.join(' ');
        } else if (e && typeof e === 'object' && 'error' in e) {
          switch (e['error']) {
            case 'Duplicate error':
              // The backend handles the case where you’re creating a user with
              // the same email address as an existing user. It errors and
              // returns an object of the “differences” between the existing
              // user and the submission. We special case this with a modal that
              // requests whether or not to update this existing user.
              //
              // We pause the form submission by returning a new Promise that
              // will resolve based on how the user reacts to the duplicate user
              // modal that we put up.
              return new Promise<Object | undefined>((resolve, reject) => {
                setDuplicateUserInfo({
                  newUser,
                  differences: e['differences'],
                  cancel: () => {
                    setDuplicateUserInfo(null);
                    // on cancel we resolve with an empty error object to
                    // indicate that the submit wasn’t successful, but there
                    // isn’t really any error to display.
                    resolve({});
                  },
                  confirm: () => {
                    // Recur so we get the same error handling as before.
                    // Because we set `confirmed` we won’t infinite loop on the
                    // same “Duplicate error” case.
                    onSubmit({ ...newUser, confirmed: true })
                      .then(resolve, reject)
                      .finally(() => setDuplicateUserInfo(null));
                  },
                });
              });

            default:
              formError = e['detail'] || e['error'] || formError;
          }
        }

        // TODO(fiona): look for cases where the backend might error but the
        // frontend won’t for particular fields.
        return { [FORM_ERROR]: formError };
      }
    },
    [currentElection]
  );

  return (
    <div className="reset-2023 flex  w-full flex-col overflow-y-clip">
      <PageHeader
        title={<PageTitle2 uiVersion="2023">Add New User</PageTitle2>}
      />

      <div className="flex flex-1 flex-col overflow-auto p-4">
        <UserForm
          id={USER_FORM_ID}
          user={formUser}
          tagData={tagData}
          wide
          onSubmit={onSubmit}
          formSubscription={{
            hasValidationErrors: true,
            submitFailed: true,
            submitting: true,
            submitError: true,
            pristine: true,
          }}
          onFormChange={({
            hasValidationErrors,
            submitFailed,
            submitting,
            submitError,
            pristine,
          }) =>
            setFormState({
              hasValidationErrors,
              submitFailed,
              submitting,
              submitError,
              pristine,
            })
          }
          showAddressFields
          showExperienceFields
          showInPersonVolunteeringFields
          showRoleFields
          mayEditRoleFields
          showProgramFields
        />
      </div>

      <div className="background-white flex items-center gap-4 border-t border-t-gray-300 p-4">
        <div>
          {submitError && (
            <div className="flex items-center gap-2 text-red-700">
              <span className="material-icons">error</span>

              <span>{submitError}</span>
            </div>
          )}

          {hasValidationErrors && submitFailed && (
            <div className="flex items-center gap-2 text-red-700">
              <span className="material-icons">error</span>

              <span>
                There are errors on the form. Please correct them, then try
                again.
              </span>
            </div>
          )}

          {createdUser && (
            <div className="flex items-center gap-2 text-green-500">
              <span className="material-icons">mood</span>

              <span>
                Successfully created an account for{' '}
                <strong>
                  {createdUser.first_name} {createdUser.last_name}
                </strong>
                !
              </span>
            </div>
          )}
        </div>

        <div className="flex-1" />

        <div className="flex gap-4">
          <ActionButton role="secondary" onPress={() => navigate('/invite')}>
            Cancel
          </ActionButton>

          <ActionButton
            role="primary"
            type="submit"
            elementProps={{ form: USER_FORM_ID }}
            isDisabled={submitting || pristine}
          >
            Add User
          </ActionButton>
        </div>
      </div>

      {duplicateUserInfo && (
        <DuplicateUserModal duplicateUserInfo={duplicateUserInfo} />
      )}
    </div>
  );
};

/**
 * Modal to show the difference between the existing user and the values from
 * the current form, with buttons to either confirm or cancel.
 */
const DuplicateUserModal: React.FunctionComponent<{
  duplicateUserInfo: DuplicateUserInfo;
}> = ({ duplicateUserInfo: { newUser, differences, confirm, cancel } }) => {
  return (
    <ModalDialog title="Duplicate E-mail Address" doClose={() => cancel()}>
      <div className="flex w-[500px] flex-col gap-8">
        <div className="text-base leading-normal">
          There is already a user with the email address{' '}
          <strong>{newUser.email}</strong>. Would you like to update their
          existing information?
        </div>

        <table>
          <tr>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>

          {Object.entries(differences).map(([key, oldValue]) => (
            <tr key={key}>
              <td>{USER_FIELD_LABELS[key as keyof UserUpdates] ?? key}</td>
              <td>
                {key === 'phone_number'
                  ? formatPhoneNumber(oldValue)
                  : oldValue}
              </td>
              <td>
                {((newValue) =>
                  key === 'phone_number'
                    ? formatPhoneNumber(newValue as string)
                    : newValue)(newUser[key as keyof UserUpdates])}
              </td>
            </tr>
          ))}
        </table>

        <div className="flex flex-row-reverse gap-2">
          <ActionButton role="primary" onPress={() => confirm()}>
            Update
          </ActionButton>

          <ActionButton role="secondary" onPress={() => cancel()}>
            Cancel
          </ActionButton>
        </div>
      </div>
    </ModalDialog>
  );
};

/**
 * Creates a fresh, empty poll_observer user record with the election’s state.
 */
function makeBlankUser(
  currentElection: Pick<ApiElection, 'state' | 'type'>
): Required<UserUpdates> {
  return {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address: '',
    city: '',
    county: '',
    state: '',
    tags: [],
    confirmed_email: false,
    zipcode: '',
    role:
      currentElection.type === 'permanent' ? 'hotline_worker' : 'poll_observer',
    ...(currentElection.state !== US_NATIONAL_STATE_CODE
      ? {
          state: currentElection.state,
        }
      : {}),
  };
}

/**
 * react-router loader for {@link NewUserPage}.
 */
export async function loadNewUserPage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof NewUserPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: ['vpd', 'deputy_vpd'],
  });

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const tagDataPromise = lbjSharedService.getUserTags();

  return {
    currentElection,
    tagData: (await tagDataPromise).tags,
  };
}

export default NewUserPage;
