import { IssuePermission, UserRole } from '.';

export const _issueEditPermissions: {
  [role in UserRole | 'none']: IssuePermission[];
} = {
  vpd: ['observer', 'voter', 'location', 'description', 'details', 'comments'],
  deputy_vpd: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  boiler_room_leader: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  boiler_room_user: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  hotline_manager: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  hotline_worker: ['comments', 'status'],
  poll_observer: ['comments', 'status'],
  view_only: [],
  none: [],
};

export const _ownIssueEditPermissions: {
  [role in UserRole | 'none']: IssuePermission[];
} = {
  vpd: ['observer', 'voter', 'location', 'description', 'details', 'comments'],
  deputy_vpd: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  boiler_room_leader: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  boiler_room_user: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  hotline_manager: [
    'observer',
    'voter',
    'location',
    'description',
    'details',
    'comments',
  ],
  hotline_worker: ['voter', 'location', 'description', 'comments', 'status'],
  poll_observer: ['voter', 'location', 'description', 'comments', 'status'],
  view_only: [],
  none: [],
};
