import moment from 'moment';
import React from 'react';
import { LoaderFunctionArgs, useNavigation } from 'react-router-dom';

import Autocomplete from '../../../components/presentational/form/autocomplete';
import CountySelect, {
  countiesToChoices,
} from '../../../components/presentational/form/county-select';
import DatetimeInput from '../../../components/presentational/form/datetime';
import SelectInput from '../../../components/presentational/form/select';
import StateSelect from '../../../components/presentational/form/state-select';
import ToggleInput from '../../../components/presentational/form/toggle';
import LoadingOverlay from '../../../components/presentational/lbj/loading';
import Sidebar from '../../../components/presentational/lbj/sidebar';
import {
  issueCategories,
  State,
  states,
  US_NATIONAL_STATE_CODE,
} from '../../../constants';
import { LbjInputEvent } from '../../../decorators/input-props';
import { arrayToPaginationData } from '../../../decorators/paginated-component';

import { AppStore } from '../../../modules/flux-store';
import { loadCurrentUser } from '../../../route-handlers/app';
import {
  callIfDate,
  CountySlug,
  dateToDateString,
  DATE_STRING_FORMAT,
} from '../../../services/common';
import {
  ApiTicketSummaryResult,
  ApiTicketSummaryGroupBy,
} from '../../../services/dashboard-service';
import * as DashboardService from '../../../services/dashboard-service';
import {
  ApiCounty,
  ApiElection,
  ApiPrecinct,
} from '../../../services/lbj-shared-service';
import * as LbjSharedService from '../../../services/lbj-shared-service';

import { orderingToSortProps } from '../../../utils/list-helpers';
import {
  MakeQueryFieldsFn,
  parseRequestQueryState,
  UpdateQueryStateFn,
  useRouterQueryState,
} from '../../../utils/query-state';

import { DASHBOARD_USER_ROLES } from '../dashboard-utils';

import DashboardIssuesTable from './DashboardIssuesTable';

export const DASHBOARD_ISSUES_TABLE_LABELS = [
  ...new Set([
    ...Object.keys(issueCategories.inquiry),
    ...Object.keys(issueCategories.incident),
  ]),
] as (keyof ApiTicketSummaryResult)[];

/**
 * Subset of {@link ApiTicketSummaryGroupBy} that we show on the Dashboard page.
 */
type DashboardSummaryGroupBy = Exclude<
  ApiTicketSummaryGroupBy,
  'category' | 'region'
>;

export const VIEW_BY_CHOICES: {
  [key in DashboardSummaryGroupBy]: string;
} = {
  state: 'State',
  county: 'County',
  precinct: 'Precinct',
  location: 'Location',
};

export const VIEW_BY_LABELS: {
  [key in DashboardSummaryGroupBy]: string;
} = {
  state: 'States',
  county: 'Counties',
  precinct: 'Precincts',
  location: 'Locations',
};

const DEFAULT_ISSUES_SUMMARY_SIZE = 10;

export type DashboardIssuesPageFilters = {
  offset: number;
  size: number;

  state: State | undefined;
  county: CountySlug | undefined;
  precinct: number | undefined;
  query_election_id: number | undefined;

  from_permanent: 'True' | undefined;
  requires_followup: 'True' | undefined;

  date_gte: Date;
  date_lte: Date;

  ordering: string;
  group_by: DashboardSummaryGroupBy;
};

function makeDashboardIssuesFiltersQueryFields(
  currentElection: ApiElection
): MakeQueryFieldsFn<DashboardIssuesPageFilters> {
  return (q) => ({
    offset: q.number(0),
    size: q.number(DEFAULT_ISSUES_SUMMARY_SIZE),

    // If the current election is national, allow any state to be chosen.
    // Otherwise silently force the state of the current election.
    state:
      currentElection.state === US_NATIONAL_STATE_CODE
        ? q.state({ ignoreUs: true })
        : q.silent(currentElection.state),
    county: q.county(),
    precinct: q.number(),

    from_permanent: q.oneOf(['True']),
    requires_followup: q.oneOf(['True']),

    query_election_id:
      currentElection.state === US_NATIONAL_STATE_CODE
        ? q.number()
        : q.silent(currentElection.id),

    group_by: q.oneOf(VIEW_BY_CHOICES, 'county').required(),
    ordering: q.string('Name').required(),

    date_gte: q.date(DATE_STRING_FORMAT, new Date()).required(),
    date_lte: q.date(DATE_STRING_FORMAT, new Date()).required(),
  });
}

const DashboardIssuesPage: React.FunctionComponent<{
  currentElection: ApiElection;
  results: ApiTicketSummaryResult[];
  counties: ApiCounty[] | undefined;
  elections: ApiElection[];
  precincts: ApiPrecinct[] | undefined;
}> = (props) => {
  const { state: navigationState } = useNavigation();
  const isLoading = navigationState === 'loading';

  const [filters, updateFilters] =
    useRouterQueryState<DashboardIssuesPageFilters>(
      makeDashboardIssuesFiltersQueryFields(props.currentElection)
    );

  return (
    <DashboardIssuesPageView
      {...props}
      isLoading={isLoading}
      filters={filters}
      updateFilters={updateFilters}
    />
  );
};

export const DashboardIssuesPageView: React.FunctionComponent<{
  currentElection: ApiElection;
  counties: ApiCounty[] | undefined;
  elections: ApiElection[];
  precincts: ApiPrecinct[] | undefined;
  results: ApiTicketSummaryResult[];
  filters: DashboardIssuesPageFilters;
  updateFilters: UpdateQueryStateFn<DashboardIssuesPageFilters>;
  isLoading: boolean;
}> = ({
  filters,
  updateFilters,
  currentElection,
  counties,
  elections,
  isLoading,
  precincts,
  results,
}) => {
  return (
    <div className="lbj-page-columns">
      <Sidebar title="View By" collapsible>
        <div className="lbj-column-content">
          <SelectInput
            name="group_by"
            title="View By"
            value={filters.group_by}
            choices={VIEW_BY_CHOICES}
            onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
              updateFilters({
                group_by: ev.currentTarget.value as DashboardSummaryGroupBy,
              });
            }}
          />
        </div>

        <div className="lbj-column-label lbj-divider">
          <h4>Filters</h4>
        </div>

        <div className="lbj-column-content">
          <DatetimeInput
            name="date_gte"
            title="Start Date"
            type="date"
            value={filters.date_gte}
            dateFormat="MMMM Do, YYYY"
            onChange={(ev) => {
              callIfDate(ev.target.value, (d) => {
                updateFilters({ date_gte: d });
              });
            }}
          />

          <DatetimeInput
            name="date_lte"
            title="End Date"
            type="date"
            value={filters.date_lte}
            dateFormat="MMMM Do, YYYY"
            onChange={(ev) => {
              callIfDate(ev.target.value, (d) => {
                updateFilters({ date_lte: d });
              });
            }}
          />

          <StateSelect
            disabled={currentElection.state !== US_NATIONAL_STATE_CODE}
            includeNational={false}
            value={filters.state ?? null}
            onChange={(ev) =>
              updateFilters({
                state: ev.target.value ?? undefined,
                // reset dependent filters
                county: undefined,
                precinct: undefined,
                query_election_id: undefined,
              })
            }
          />

          <CountySelect
            disabled={
              filters.state === undefined || !counties || counties.length === 0
            }
            counties={countiesToChoices(counties)}
            value={filters.county ?? null}
            onChange={(ev) => {
              updateFilters({
                county: ev.target.value ?? undefined,
                // reset dependent filters
                precinct: undefined,
              });
            }}
          />

          {/* TODO(fiona): Only render this if a national or permanent election is chosen? */}
          <Autocomplete
            name="query_election_id"
            title="Election"
            value={filters.query_election_id?.toString() ?? ''}
            onChange={(ev) =>
              updateFilters({
                query_election_id: ev.target.value
                  ? Number(ev.target.value)
                  : undefined,
              })
            }
            choices={elections.reduce<{ [id: number]: string }>(
              (acc, el) => ({
                ...acc,
                [el.id]: el.name,
              }),
              {}
            )}
            isLoading={isLoading}
            disabled={currentElection.state !== US_NATIONAL_STATE_CODE}
          />

          <Autocomplete
            name="precinct"
            title="Precinct"
            placeholder="-"
            disabled={!precincts?.length}
            onChange={(ev) =>
              updateFilters({
                precinct: ev.target.value ? Number(ev.target.value) : undefined,
              })
            }
            choices={
              precincts?.reduce<{ [id: string]: string }>(
                (choices, precinct) => {
                  choices[precinct.id] = precinct.name;
                  return choices;
                },
                {}
              ) ?? {}
            }
            isLoading={isLoading}
            value={filters.precinct?.toString() ?? ''}
          />

          <ToggleInput
            name="from_permanent"
            title="Show only tickets from a permanent hotline"
            checked={filters.from_permanent === 'True'}
            onChange={(ev: LbjInputEvent<string>) => {
              updateFilters({
                // We don’t set to false, just indeterminate
                from_permanent: ev.target.value ? 'True' : undefined,
              });
            }}
          />

          <ToggleInput
            name="requires_followup"
            title="Show only tickets that require follow up"
            checked={filters.requires_followup === 'True'}
            onChange={(ev: LbjInputEvent<string>) => {
              updateFilters({
                // We don’t set to false, just indeterminate
                requires_followup: ev.target.value ? 'True' : undefined,
              });
            }}
          />
        </div>
      </Sidebar>

      <div className="lbj-main">
        <div className="lbj-main-title lbj-column-label">
          <DashboardIssuesActiveFilters
            filters={filters}
            counties={counties}
            precincts={precincts}
          />
        </div>

        <div className="lbj-column-content-wrapper">
          {isLoading && <LoadingOverlay />}

          <DashboardIssuesTable
            results={results}
            paginationData={arrayToPaginationData(results, filters)}
            setPage={({ offset, size }) => updateFilters({ offset, size })}
            {...orderingToSortProps(filters, updateFilters)}
            viewByValue={VIEW_BY_CHOICES[filters.group_by]}
            tableHeadingLabels={DASHBOARD_ISSUES_TABLE_LABELS}
            tableHeadingOrderingValues={DASHBOARD_ISSUES_TABLE_LABELS}
          />
        </div>
      </div>
    </div>
  );
};

const DashboardIssuesActiveFilters: React.FunctionComponent<{
  filters: Pick<
    DashboardIssuesPageFilters,
    'group_by' | 'date_gte' | 'date_lte' | 'state' | 'county' | 'precinct'
  >;
  counties: ApiCounty[] | undefined;
  precincts: ApiPrecinct[] | undefined;
}> = ({ filters, counties, precincts }) => {
  const labels: string[] = [];

  labels.push(VIEW_BY_LABELS[filters.group_by]);

  const startDate = moment(filters.date_gte).format('MMM D, YYYY');
  const endDate = moment(filters.date_lte).format('MMM D, YYYY');
  labels.push(`${startDate} - ${endDate}`);

  if (filters.state) {
    labels.push(states[filters.state]);
  }

  if (filters.county !== undefined && counties) {
    const county = counties.find(({ slug }) => slug === filters.county);
    if (county) {
      labels.push(county.name);
    }
  }

  if (filters.precinct !== undefined && precincts) {
    const precinct = precincts.find(({ id }) => id === filters.precinct);
    if (precinct) {
      labels.push(`Precinct ${precinct.name}`);
    }
  }

  return (
    <h4 className="font-bold">
      Showing:{' '}
      {labels.map((label, idx) => (
        <span key={idx}>{label}</span>
      ))}
    </h4>
  );
};

export async function loadDashboardIssuesPage(
  fluxStore: AppStore,
  { request }: LoaderFunctionArgs
): Promise<React.ComponentProps<typeof DashboardIssuesPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: DASHBOARD_USER_ROLES,
  });

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  const filters = parseRequestQueryState<DashboardIssuesPageFilters>(
    request,
    makeDashboardIssuesFiltersQueryFields(currentElection)
  );

  const summaryResp = DashboardService.getSummaryList({
    offset: filters.offset,
    size: filters.size,
    group_by: filters.group_by,
    ordering: filters.ordering,
    ...(filters.query_election_id !== undefined
      ? { query_election_id: filters.query_election_id }
      : {}),
    ...(filters.state !== undefined ? { state: filters.state } : {}),
    ...(filters.county !== undefined ? { county: filters.county } : {}),
    ...(filters.precinct !== undefined ? { precinct: filters.precinct } : {}),
    ...(filters.from_permanent ? { from_permanent: 'True' } : {}),
    ...(filters.requires_followup ? { requires_followup: 'True' } : {}),
    date_gte: dateToDateString(filters.date_gte),
    date_lte: dateToDateString(filters.date_lte),
  });

  const countiesResp = filters.state
    ? LbjSharedService.getCounties(filters.state)
    : undefined;

  const electionsResp = LbjSharedService.getElections(filters.state);

  const precinctsResp =
    filters.query_election_id && filters.county
      ? LbjSharedService.getPrecincts({
          county: filters.county,
          query_election_id: filters.query_election_id,
          size: 1000,
        })
      : undefined;

  return {
    currentElection,
    results: (await summaryResp).results,
    counties: (await countiesResp)?.counties,
    elections: (await electionsResp).results,
    precincts: (await precinctsResp)?.precincts,
  };
}

export default DashboardIssuesPage;
