import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { actionCreators as userActionCreators } from '../../../modules/user';
import RIT from '../../../utils/render-if-truthy';
import {
  withRouting,
  WithRoutingPropTypes,
} from '../../../utils/routing-provider';
import Checkbox from '../../presentational/form/checkbox';
import Modal from '../../presentational/lbj/modal';

@connect((state) => {
  const { user } = state;
  const currentUser = user.getIn(['currentUser', 'userData']);
  const requestIsPending = user.getIn(['updatingUser', 'requestIsPending']);
  const requestErred = user.getIn(['updatingUser', 'requestErred']);

  return {
    currentUser,
    requestIsPending,
    requestErred,
  };
})
@withRouting
export default class EulaModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    requestIsPending: PropTypes.bool.isRequired,
    requestErred: PropTypes.bool.isRequired,
    ...WithRoutingPropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      eulaChecked: false,
      privacyPolicyChecked: false,
      screen: 'eula',
    };
    this.onTransmitAcceptance = this.onTransmitAcceptance.bind(this);
    this.renderEula = this.renderEula.bind(this);
    this.renderVoterProtectionAgreement =
      this.renderVoterProtectionAgreement.bind(this);
  }

  onTransmitAcceptance() {
    const { dispatch, currentUser, navigate } = this.props;
    const { updateUserAsync } = userActionCreators;
    dispatch(
      updateUserAsync(
        currentUser.get('id'),
        { signed_eula: true },
        currentUser.get('id')
      )
    ).then(() => {
      // Reloads the page so that we can re-run the loaders and see a user
      // object who has signed the EULA.
      navigate(0);
    });
  }

  renderEula() {
    const submitDisabled =
      !this.state.privacyPolicyChecked || !this.state.eulaChecked;

    return (
      <Modal dismissable={false} headerText="End User License Agreement">
        <div className="lbj-login-form">
          <div className="lbj-modal-small-text">
            <p>
              Before you can use LBJ, you must accept the{' '}
              <a
                className="lbj-eula-link"
                target="_blank"
                href="/static/DNC_LBJ_EULA_2022.09.28.pdf"
                rel="noreferrer"
              >
                End User License Agreement
              </a>{' '}
              and acknowledge the{' '}
              <a
                className="lbj-eula-link"
                target="_blank"
                href="https://democrats.org/privacy-policy"
                rel="noreferrer"
              >
                privacy policy
              </a>
              .
            </p>
          </div>
          <div className="lbj-modal-checkbox lbj-eula-modal">
            <Checkbox
              name="eula"
              checked={this.state.eulaChecked}
              onChange={() =>
                this.setState({ eulaChecked: !this.state.eulaChecked })
              }
              title="I have read and I accept the linked End User License Agreement."
              showLabel
            />
            <Checkbox
              name="privacy_policy"
              checked={this.state.privacyPolicyChecked}
              onChange={() =>
                this.setState({
                  privacyPolicyChecked: !this.state.privacyPolicyChecked,
                })
              }
              title="I acknowledge the linked privacy policy."
              showLabel
            />
            <br />
          </div>
          <button
            type="button"
            className="c-button-large c-button-secondary"
            disabled={submitDisabled}
            onClick={() => {
              this.setState({ screen: 'voter_protection_agreement' });
            }}
          >
            Accept EULA
          </button>
        </div>
      </Modal>
    );
  }

  renderVoterProtectionAgreement() {
    const { requestIsPending, requestErred } = this.props;
    const submitDisabled = requestIsPending;

    return (
      <Modal dismissable={false} headerText="Voter Protection Agreement">
        <div className="lbj-login-form">
          {RIT(requestErred, () => (
            <div className="c-alert-error">
              Error accepting Voter Protection Agreement. Contact
              lbj-help@dnc.org.
            </div>
          ))}
          <div className="lbj-modal-vpa-text">
            <p>
              To demonstrate a commitment to upholding the Democratic Party’s
              shared voter protection values, I pledge to assist any voter who
              seeks assistance.
            </p>
          </div>
          <button
            type="button"
            className="c-button-large c-button-secondary"
            disabled={submitDisabled}
            onClick={this.onTransmitAcceptance}
          >
            {requestIsPending ? 'Transmitting Acceptance...' : 'I Accept'}
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    if (this.state.screen === 'eula') {
      return this.renderEula();
    }
    return this.renderVoterProtectionAgreement();
  }
}
