import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { lbjAppSections } from '../../../constants';
import inputProps from '../../../decorators/input-props';
import { actionCreators as lbjActionCreators } from '../../../modules/lbj';
import mapStateToCounties from '../../../utils/filters/map-state-to-counties';
import Autocomplete from '../../presentational/form/autocomplete';

const {
  ISSUE,
  ASSIGNMENT,
  CHECKINS,
  DASHBOARD,
  ANALYTICS,
  PROFILE,
  USER,
  NOTIFICATIONS,
} = lbjAppSections;

@connect((state, props) => {
  const { appSection } = props;
  const filters = state.filters.get(appSection);
  const name = props.name || 'county';
  const countyData = mapStateToCounties(state, appSection, 'state');

  return {
    name: name,
    stateFilter: filters.get('state'),
    countyFilter: filters.get(name),
    regionFilter: filters.get('region'),
    counties: countyData?.get('listData'),
    isFetchingCounties: countyData?.get('requestIsPending') || false,
  };
})
@inputProps() /* eslint-disable react/prop-types */
export default class CountyAutocompleteContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    stateFilter: PropTypes.string,
    countyFilter: PropTypes.string,
    regionFilter: PropTypes.string,
    counties: PropTypes.object,
    isFetchingCounties: PropTypes.bool,
    multi: PropTypes.bool,
    name: PropTypes.string,
    preferredChoices: PropTypes.array,
    appSection: PropTypes.oneOf([
      ISSUE,
      ASSIGNMENT,
      CHECKINS,
      DASHBOARD,
      ANALYTICS,
      PROFILE,
      USER,
      NOTIFICATIONS,
    ]),
    issueInProgress: PropTypes.object,
    election: PropTypes.number,
  };

  static defaultProps = {
    title: 'County',
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    const currentStateFilter = this.props.stateFilter;
    const nextStateFilter = nextProps.stateFilter;
    const currentRegionFilter = this.props.regionFilter;
    const nextRegionFilter = nextProps.regionFilter;
    const nextCounties = nextProps.counties;

    // check if there's a new state filter and if we don't have that
    // state's county data, get it
    if (currentStateFilter !== nextStateFilter && !nextCounties) {
      dispatch(lbjActionCreators.getCountyListAsync(nextStateFilter));
    }

    // check to see if the region filter has changed
    if (currentRegionFilter !== nextRegionFilter) {
      dispatch(
        lbjActionCreators.getCountyListAsync(
          currentStateFilter,
          nextRegionFilter
        )
      );
    }
  }

  getAutocompleteChoices() {
    const { counties } = this.props;
    let countyChoices = {};

    if (counties) {
      countyChoices = counties.reduce((choices, county) => {
        choices[county.get('slug')] = county.get('name');
        return choices;
      }, {});
    }

    return countyChoices;
  }

  handleChange(event) {
    const { onChange } = this.props;

    onChange(event);
  }

  render() {
    const {
      name,
      title,
      countyFilter,
      value,
      isFetchingCounties,
      multi,
      preferredChoices,
      required,
      disabled,
      type,
      errors,
      className,
    } = this.props;
    const countyChoices = this.getAutocompleteChoices();
    const val = typeof value === 'undefined' ? countyFilter : value;
    const isDisabled = disabled || _.isEmpty(countyChoices);

    return (
      <Autocomplete
        className={className}
        name={name}
        title={title}
        disabled={isDisabled}
        onChange={this.handleChange.bind(this)}
        choices={countyChoices}
        isLoading={isFetchingCounties}
        value={val}
        required={required}
        multi={multi}
        preferredChoices={preferredChoices || []}
        type={type}
        errors={errors}
      />
    );
  }
}
