import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { dismissToast } from '../../../modules/user/action-creators';
import Modal from '../../presentational/lbj/modal';
import TagPicker from '../form/tag-picker-container';

import UserToastContainer from './user-toast-container';

@connect((state) => ({
  isPending: state.user.get('isBulkTagging'),
  hasError: state.user.get('bulkTagError'),
}))
export default class BulkTaggerModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    isPending: PropTypes.bool,
    isVisible: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    handleTagUsers: PropTypes.func.isRequired,
    userCount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
    };

    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.isVisible !== this.props.isVisible) {
      this.reset();
    }

    const success = !props.isPending && this.props.isPending && !props.hasError;

    if (success) {
      this.reset();
    }
  }

  onClose() {
    const { onRequestClose } = this.props;
    this.reset();

    onRequestClose();
  }

  onSubmitTags() {
    const { tags } = this.state;
    if (tags.length === 0) {
      return;
    }

    this.props.handleTagUsers(tags);
  }

  onTagsChange(tags) {
    this.setState({ tags });
  }

  reset() {
    this.props.dispatch(dismissToast());
    this.setState({ tags: [] });
  }

  render() {
    const { isPending, isVisible, userCount } = this.props;
    const { tags } = this.state;

    if (!isVisible) {
      return null;
    }

    const descriptionText = `Apply tags to ${userCount} user${
      userCount > 1 ? 's' : ''
    }`;
    const disabled = tags.length === 0;
    const spinner = isPending ? (
      <div className="lbj-loading-overlay">
        <div className="lbj-loading-icon" />
      </div>
    ) : null;

    return (
      <Modal
        classes="UserActionModal UserModal"
        dismissable
        headerText="Bulk tag users"
        onClose={this.onClose}
      >
        <div className="userActionContent userModalContent">
          <UserToastContainer />
          <div className="user-action-description">{descriptionText}</div>
          <div className="userActionField">
            <TagPicker
              title="Tags"
              name="tag"
              multi
              onChange={this.onTagsChange.bind(this)}
              defaultValue={tags.join(',')}
              allTags={tags}
            />
          </div>
          <div className="buttonRow">
            <button
              type="submit"
              className="c-button-large c-button-secondary"
              onClick={this.onSubmitTags.bind(this)}
              disabled={disabled}
            >
              Add tags
            </button>
          </div>
          {spinner}
        </div>
      </Modal>
    );
  }
}
