import { parseDate } from '@internationalized/date';
import cx from 'classnames';
import { Immutable } from 'immer';
import moment from 'moment';
import React from 'react';
import { useId } from 'react-aria';

import {
  ActionButton,
  Checkbox,
  IconButton,
  Radio,
  RadioGroup,
  RangeCalendar,
  TextButton,
  TextField,
  TextRadioGroup,
} from '../../../components/form';
import { downloadLocalCsv } from '../../../components/hooks/papa-parse';
import { ModalDialog, Popover } from '../../../components/layout';

import { State, EDAY_ROLES } from '../../../constants';
import { DateString, formatTime } from '../../../services/common';
import {
  ApiElectionDay,
  ApiLocationTierConfiguration,
  PollObserverRegistrationRequirement,
} from '../../../services/lbj-shared-service';
import { ApiVolunteerAvailability } from '../../../services/volunteer-availability-service';
import { assertUnreachable } from '../../../utils/types';
import { useAssignmentSolver } from '../assignment-solving';
import {
  AssignmentLoadingStatus,
  AssignmentRecord,
  AssignmentState,
  AssignmentUser,
  getAssignmentsByUserIdForDates,
  LOADING_STAGE_TO_NOUN,
} from '../assignment-state';
import {
  LocationFilterOptions,
  ShiftScoreOptions,
  getVolunteerAttributes,
  splitElectionDays,
} from '../assignment-utils';
import {
  LocationMunicipalities,
  MunicipalityFilters,
  TierRankingFilters,
} from '../location-table/LocationFilter';

export type AutoAssignmentStep =
  | 'dates'
  | 'options'
  | 'locations'
  | 'people'
  | 'assigning'
  | 'no_assignments'
  | 'error';

/**
 * Dialog for running auto-assignments.
 */
const AutoAssignmentDialog: React.FunctionComponent<{
  assignmentState: AssignmentState;
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement;
  electionDays: ApiElectionDay[];
  electionState: State;
  defaultDate: DateString;
  defaultLocationFilters: LocationFilterOptions;
  locationTierConfiguration: ApiLocationTierConfiguration[];
  maxRanking: number | null;
  municipalities: LocationMunicipalities;
  reviewAutoAssignments: (
    locationFilters: LocationFilterOptions,
    assignments: (AssignmentRecord & { source: 'auto' })[]
  ) => void;
  doClose: () => void;
  assignmentStateLoadingStatus: AssignmentLoadingStatus;
  reloadServerState: () => void;
}> = ({
  assignmentState,
  pollObserverRegistrationRequirement,
  defaultLocationFilters,
  locationTierConfiguration,
  maxRanking,
  municipalities,
  reviewAutoAssignments,
  doClose,
  defaultDate,
  electionDays,
  electionState,
  assignmentStateLoadingStatus,
  reloadServerState,
}) => {
  React.useEffect(
    () => {
      if (
        // Only reload if our data is more than an hour out-of-date, since it’s
        // a bit slow to do.
        assignmentState.lastLoadTime === null ||
        moment(assignmentState.lastLoadTime).diff(moment(), 'hours') >= 1
      ) {
        reloadServerState();
      }
    },
    // We only want to check for load on dialog open.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [locationFilters, setLocationFilters] =
    React.useState<LocationFilterOptions>({
      ...defaultLocationFilters,
      locationType: 'all',
      withSuggestedAssignments: false,
    });

  const [shiftScoreOptions, setShiftScoreOptions] =
    React.useState<ShiftScoreOptions>({
      favorExperience: true,
      favorLegalCommunity: true,
    });

  const [userIdsToExclude, setUserIdsToExclude] = React.useState<Set<number>>(
    new Set()
  );

  const [makeAutoAssignments, autoAssignmentStatus] = useAssignmentSolver(
    assignmentState,
    locationTierConfiguration,
    shiftScoreOptions,
    pollObserverRegistrationRequirement,
    (assignments) => {
      return assignments.length > 0
        ? reviewAutoAssignments(locationFilters, assignments)
        : null;
    }
  );

  const [step, setStep] = React.useState<AutoAssignmentStep>('dates');

  /**
   * Date range for auto-assignment. Note that this will either be _just_
   * election day or a range during EV. We don’t support doing auto-assignment
   * across EV and EDay.
   */
  const [dateRange, setDateRange] = React.useState<[DateString, DateString]>([
    defaultDate,
    defaultDate,
  ]);

  React.useEffect(() => {
    if (autoAssignmentStatus === 'error') {
      setStep('error');
    } else if (autoAssignmentStatus === 'no_assignments') {
      setStep('no_assignments');
    }
  }, [autoAssignmentStatus]);

  let doNext: () => void;
  let doPrev: () => void;

  switch (step) {
    case 'dates':
      doNext = () => setStep('options');
      doPrev = doClose;
      break;

    case 'options':
      doNext = () => setStep('locations');
      doPrev = () => setStep('dates');
      break;

    case 'locations':
      doNext = () => setStep('people');
      doPrev = () => setStep('options');
      break;

    case 'people':
      doNext = () => {
        setStep('assigning');
        makeAutoAssignments(
          electionDays.filter(
            (d) => dateRange[0] <= d.date && d.date <= dateRange[1]
          ),
          locationFilters,
          userIdsToExclude
        );
      };
      doPrev = () => setStep('locations');
      break;

    case 'assigning':
      doNext = () => {};
      doPrev = () => {};
      break;

    case 'error':
      doNext = () => {};
      doPrev = doClose;
      break;

    case 'no_assignments':
      doNext = doClose;
      doPrev = () => setStep('people');
      break;

    default:
      assertUnreachable(step);
  }

  return (
    <AutoAssignmentDialogView
      step={step}
      assignmentStateLoadingStatus={assignmentStateLoadingStatus}
      usersById={assignmentState.usersById}
      availabilityByUserId={assignmentState.availabilityByUserId}
      assignmentsByDate={assignmentState.assignmentsByDate}
      pollObserverRegistrationRequirement={pollObserverRegistrationRequirement}
      userIdsToExclude={userIdsToExclude}
      setUserIdsToExclude={setUserIdsToExclude}
      electionDays={electionDays}
      dateRange={dateRange}
      setDateRange={setDateRange}
      locationTierConfiguration={locationTierConfiguration}
      maxRanking={maxRanking}
      municipalities={municipalities}
      locationFilters={locationFilters}
      setLocationFilters={setLocationFilters}
      shiftScoreOptions={shiftScoreOptions}
      setShiftScoreOptions={setShiftScoreOptions}
      electionState={electionState}
      doNext={doNext}
      doPrev={doPrev}
      doClose={doClose}
    />
  );
};

export default AutoAssignmentDialog;

/**
 * Stateless view of {@link AutoAssignmentDialog} so we can more easily
 * Storybook it.
 */
export const AutoAssignmentDialogView: React.FunctionComponent<{
  step: AutoAssignmentStep;
  assignmentStateLoadingStatus: AssignmentLoadingStatus;

  usersById: Immutable<Map<number, AssignmentUser>>;
  availabilityByUserId: Immutable<Map<number, ApiVolunteerAvailability[]>>;
  assignmentsByDate: Immutable<Map<DateString, AssignmentRecord[]>>;

  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement;
  userIdsToExclude: Set<number>;
  setUserIdsToExclude: React.Dispatch<React.SetStateAction<Set<number>>>;

  dateRange: [DateString, DateString];
  setDateRange: (range: [DateString, DateString]) => void;
  electionDays: ApiElectionDay[];

  electionState: State;

  locationTierConfiguration: ApiLocationTierConfiguration[];
  maxRanking: number | null;
  municipalities: LocationMunicipalities;

  locationFilters: LocationFilterOptions;
  setLocationFilters: React.Dispatch<
    React.SetStateAction<LocationFilterOptions>
  >;

  shiftScoreOptions: ShiftScoreOptions;
  setShiftScoreOptions: React.Dispatch<React.SetStateAction<ShiftScoreOptions>>;

  doNext: () => void;
  doPrev: () => void;
  doClose: () => void;
}> = ({
  step,
  assignmentStateLoadingStatus,
  usersById,
  availabilityByUserId,
  assignmentsByDate,
  pollObserverRegistrationRequirement,
  userIdsToExclude: userIdsToExclude,
  setUserIdsToExclude,
  electionState,
  locationTierConfiguration,
  maxRanking,
  municipalities,
  dateRange,
  setDateRange,
  electionDays,
  locationFilters,
  setLocationFilters,
  shiftScoreOptions,
  setShiftScoreOptions,
  doNext,
  doPrev,
  doClose,
}) => {
  return (
    <ModalDialog
      title="Suggest Assignments"
      doClose={doClose}
      aboveCenter
      showClose
    >
      <div className="flex min-h-[400px] w-[700px] flex-col gap-4">
        {step === 'dates' && (
          <p className="text-base leading-relaxed">
            Follow each step to set up this run of the Suggest Assignments tool.
            You will have an opportunity to review the assignments that LBJ
            suggests before they are saved.
          </p>
        )}

        {step === 'dates' && (
          <AutoAssignmentDialogViewDatesStep
            dateRange={dateRange}
            setDateRange={setDateRange}
            electionDays={electionDays}
            // quick-and-dirty check… this doesn’t guarantee that soemone has
            // uploaded availability for any of the specific days that are
            // chosen, but it’s enough of a “do you know about uploading
            // availabilty, it’s a requirement to get this to work” speedbump.
            hasEvAvailability={availabilityByUserId.size > 0}
          />
        )}

        {step === 'options' && (
          <AutoAssignmentDialogViewOptionsStep
            shiftScoreOptions={shiftScoreOptions}
            setShiftScoreOptions={setShiftScoreOptions}
          />
        )}

        {step === 'locations' && (
          <AutoAssignmentDialogViewLocationsStep
            locationTierConfiguration={locationTierConfiguration}
            maxRanking={maxRanking}
            municipalities={municipalities}
            locationFilters={locationFilters}
            setLocationFilters={setLocationFilters}
          />
        )}

        {step === 'people' && (
          <AutoAssignmentDialogViewPeopleStep
            assignmentsByDate={assignmentsByDate}
            dateRange={dateRange}
            electionDays={electionDays}
            usersById={usersById}
            availabilityByUserId={availabilityByUserId}
            pollObserverRegistrationRequirement={
              pollObserverRegistrationRequirement
            }
            electionState={electionState}
            userIdsToExclude={userIdsToExclude}
            setUserIdsToExclude={setUserIdsToExclude}
          />
        )}

        {step === 'assigning' && <AutoAssignmentDialogViewAssigningStep />}

        {step === 'error' && <AutoAssignmentDialogViewErrorStep />}

        {step === 'no_assignments' && (
          <AutoAssignmentDialogViewNoAssignmentsStep />
        )}

        <div className="flex gap-4">
          <div className="flex-1 self-center text-sm">
            {assignmentStateLoadingStatus.status === 'loading' && (
              <div className="mb-2 flex items-center gap-1 leading-none">
                <span className="material-icons animate-spin text-base text-gray-700">
                  autorenew
                </span>
                <span className="font-bold">
                  Refreshing{' '}
                  {LOADING_STAGE_TO_NOUN[assignmentStateLoadingStatus.stage]}…
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-1 justify-end gap-2 self-end">
            <ActionButton
              onPress={doPrev}
              role="secondary"
              isDisabled={step === 'assigning'}
            >
              {step === 'dates' || step === 'error'
                ? 'Cancel'
                : 'Previous Step'}
            </ActionButton>

            <ActionButton
              onPress={doNext}
              role="primary"
              isDisabled={
                step === 'assigning' ||
                step === 'error' ||
                // We don’t want to go to assigning mode while still waiting for
                // refreshed data.
                (step === 'people' &&
                  assignmentStateLoadingStatus.status === 'loading')
              }
            >
              {step === 'people' &&
              assignmentStateLoadingStatus.status === 'loading'
                ? 'Please Wait…'
                : step === 'people' || step === 'assigning' || step === 'error'
                ? 'Generate Suggestions'
                : step === 'no_assignments'
                ? 'Close'
                : 'Next Step'}
            </ActionButton>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

/**
 * Contents of {@link AutoAssignmentDialog} when on the “dates” step.
 */
const AutoAssignmentDialogViewDatesStep: React.FunctionComponent<{
  electionDays: ApiElectionDay[];
  dateRange: [DateString, DateString];
  setDateRange: (range: [DateString, DateString]) => void;
  hasEvAvailability: boolean;
}> = ({ dateRange, setDateRange, electionDays, hasEvAvailability }) => {
  const { electionDay, evDays } = splitElectionDays(electionDays);

  const isElectionDay =
    dateRange[0] === electionDay.date && dateRange[1] === electionDay.date;

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-base">
        <strong>Step 1 of 4:</strong> Select dates for assignments
      </div>

      <div className="text-sm italic">
        Election day and early vote suggestions have to be made separately.
      </div>

      <div className="flex flex-col gap-4 self-stretch">
        <RadioGroup
          aria-label="Date"
          value={isElectionDay ? 'eday' : 'ev'}
          onChange={(val) =>
            setDateRange(
              val === 'eday'
                ? [electionDay.date, electionDay.date]
                : // We know there will be at least one element, otherwise Early
                  // Vote option is disabled.
                  [evDays[0]!.date, evDays[evDays.length - 1]!.date]
            )
          }
        >
          <Radio value="eday">
            <span>
              <strong>Election Day</strong> —{' '}
              {moment(electionDay.date).format('LL')}
            </span>
          </Radio>

          <Radio
            value="ev"
            isDisabled={!hasEvAvailability || evDays.length === 0}
          >
            <strong>Early Vote</strong>
          </Radio>
        </RadioGroup>

        <div className="self-center border border-gray-300 p-4">
          <RangeCalendar
            monthCount={isElectionDay ? 1 : 2}
            aria-label="Date Range"
            value={{
              start: parseDate(dateRange[0]),
              end: parseDate(dateRange[1]),
            }}
            // We are very specifically not allowing election day in the
            // selectable range, since we don’t support mixed EV and EDay
            // solving.
            minValue={
              isElectionDay
                ? parseDate(electionDay.date)
                : parseDate(evDays[0]!.date)
            }
            maxValue={
              isElectionDay
                ? parseDate(electionDay.date)
                : parseDate(evDays[evDays.length - 1]!.date)
            }
            onChange={({ start, end }) =>
              setDateRange([
                start.toString() as DateString,
                end.toString() as DateString,
              ])
            }
            emphasizedDates={[parseDate(electionDay.date)]}
          />
        </div>

        {!hasEvAvailability && (
          <div className="text-sm italic">
            Upload availability CSV files in order to make non–election day
            suggestions.
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Contents of {@link AutoAssignmentDialog} when on the “options” step.
 */
const AutoAssignmentDialogViewOptionsStep: React.FunctionComponent<{
  shiftScoreOptions: ShiftScoreOptions;
  setShiftScoreOptions: React.Dispatch<React.SetStateAction<ShiftScoreOptions>>;
}> = ({ shiftScoreOptions, setShiftScoreOptions }) => {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-base">
        <strong>Step 2 of 4:</strong> Prioritize volunteer experience
      </div>

      <p className="text-sm italic leading-relaxed">
        Select these options to place these volunteers at higher-priority
        locations, even if they are farther away from where they would otherwise
        be assigned.{' '}
        <Popover
          type="dialog"
          placement="right"
          trigger={(props, ref) => (
            <IconButton
              buttonRef={ref}
              icon="help"
              size="small"
              className="align-baseline"
              {...props}
            />
          )}
        >
          <div className="flex max-w-md flex-col gap-2 bg-white p-4 [&_p]:text-sm [&_p]:leading-relaxed">
            <p>
              <strong>How it works:</strong> Suggested assignments will be
              created for empty shifts and take into account the prioritization
              of location and proximity (closeness) a volunteer is to that
              location. Suggestions also take into account the volunteer’s
              willingness to travel* and election-day availability.
            </p>

            <p className="italic">
              * Distances are measured “as the crow flies” between the
              volunteer’s address and the polling place. The actual travel
              distance may be farther than the volunteer is willing to go.
            </p>
          </div>
        </Popover>
      </p>

      <div className="flex flex-col gap-2">
        <Checkbox
          isSelected={shiftScoreOptions.favorExperience}
          onChange={(newVal) =>
            setShiftScoreOptions((prev) => ({
              ...prev,
              favorExperience: newVal,
            }))
          }
        >
          <span className="leading-tight">
            <span className="material-icons text-sm" aria-hidden>
              military_tech
            </span>
            &nbsp;
            <strong>Experienced poll observers</strong>
          </span>
        </Checkbox>

        <Checkbox
          isSelected={shiftScoreOptions.favorLegalCommunity}
          onChange={(newVal) =>
            setShiftScoreOptions((prev) => ({
              ...prev,
              favorLegalCommunity: newVal,
            }))
          }
        >
          <span className="leading-tight">
            <span className="material-icons text-sm" aria-hidden>
              gavel
            </span>
            &nbsp;<strong>Members of the legal community</strong>
          </span>
        </Checkbox>
      </div>
    </div>
  );
};

/**
 * Contents of {@link AutoAssignmentDialog} when on the “locations” step.
 */
const AutoAssignmentDialogViewLocationsStep: React.FunctionComponent<{
  locationTierConfiguration: ApiLocationTierConfiguration[];
  maxRanking: number | null;
  municipalities: LocationMunicipalities;
  locationFilters: LocationFilterOptions;
  setLocationFilters: React.Dispatch<
    React.SetStateAction<LocationFilterOptions>
  >;
}> = ({
  maxRanking,
  locationTierConfiguration,
  municipalities,
  locationFilters,
  setLocationFilters,
}) => {
  const locationsHeaderId = useId();

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-base">
        <strong>Step 3 of 4:</strong> Choose locations
      </div>

      <p className="text-sm italic leading-relaxed">
        Use this to focus on a specific priority or locality. Note that
        volunteers will be better allocated the more locations you do at once.
      </p>

      <div className="flex gap-4">
        <div className="flex max-w-md flex-1 flex-col gap-3">
          <div className="font-bold">Ranking:</div>

          {maxRanking !== null && (
            <TierRankingFilters
              showAllOption
              locationFilters={locationFilters}
              locationTierConfiguration={locationTierConfiguration}
              maxRanking={maxRanking}
              setLocationFilters={setLocationFilters}
            />
          )}

          {maxRanking === null && (
            <>
              <div className="italic">
                All locations selected. Upload location rankings to use this
                feature.
              </div>
            </>
          )}
        </div>

        <div className="flex flex-1 flex-col gap-3">
          <div className="font-bold" id={locationsHeaderId}>
            Locations:
          </div>

          <MunicipalityFilters
            aria-labelledby={locationsHeaderId}
            municipalities={municipalities}
            locationFilters={locationFilters}
            setLocationFilters={setLocationFilters}
          />
        </div>
      </div>
    </div>
  );
};

type UserViewOption = 'eligible' | 'ineligible';

const TAG_ROLE_LABELS = {
  election_day_hotline_worker_volunteer: 'Hotline Worker',
  election_day_boiler_room_volunteer: 'Boiler Room',
  election_day_poll_observer_volunteer: 'Poll Observer',
};

/**
 * Contents of {@link AutoAssignmentDialog} when on the “people” step.
 */
const AutoAssignmentDialogViewPeopleStep: React.FunctionComponent<{
  dateRange: [DateString, DateString];
  electionDays: ApiElectionDay[];
  usersById: Immutable<Map<number, AssignmentUser>>;
  availabilityByUserId: Immutable<Map<number, ApiVolunteerAvailability[]>>;
  assignmentsByDate: Immutable<Map<DateString, AssignmentRecord[]>>;
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement;
  electionState: State;
  userIdsToExclude: Set<number>;
  setUserIdsToExclude: React.Dispatch<React.SetStateAction<Set<number>>>;
}> = ({
  dateRange,
  electionDays,
  usersById,
  availabilityByUserId,
  assignmentsByDate,
  pollObserverRegistrationRequirement,
  electionState,
  userIdsToExclude,
  setUserIdsToExclude,
}) => {
  const { electionDay, evDays } = splitElectionDays(electionDays);

  const isEday =
    dateRange[0] === electionDay.date && dateRange[1] === electionDay.date;

  /**
   * We warn about users being assigned (and therefore ineligible for
   * auto-assignment) only if the date range is for a single day.
   *
   * (We won’t assign people on days they have assignments anyway, but that’s
   * sorted out in the solver.)
   */
  const singleDayAssignmentsByUserId = React.useMemo<
    Map<number, AssignmentRecord[]>
  >(
    () =>
      dateRange[0] === dateRange[1]
        ? getAssignmentsByUserIdForDates(
            {
              assignmentsByDate,
              usersById,
            },
            [dateRange[0]]
          )
        : new Map(),
    [assignmentsByDate, usersById, dateRange]
  );

  /**
   * List of poll observers, as well as anyone explicitly tagged with the right
   * tag for this date. We surface all poll observers so that VPDs can see if
   * there’s anyone not tagged that should be in the “ineligible” list.
   */
  const users = React.useMemo(
    () =>
      [...usersById.values()].filter((u) => {
        const { isEdayVolunteer, isEvVolunteer } = getVolunteerAttributes(u);

        return (
          u.role === 'poll_observer' ||
          (isEday && isEdayVolunteer) ||
          (!isEday && isEvVolunteer)
        );
      }),
    [usersById, isEday]
  );

  users.sort((u1, u2) => {
    return (
      (u1.last_name ?? '').localeCompare(u2.last_name ?? '') ||
      (u1.first_name ?? '').localeCompare(u2.first_name ?? '')
    );
  });

  const [userView, setUserView] = React.useState<UserViewOption>('eligible');

  function userHasAssignments(u: AssignmentUser) {
    return !!singleDayAssignmentsByUserId.get(u.id)?.length;
  }

  function userRegisteredToVoteInState(u: AssignmentUser) {
    return u.state === electionState;
  }

  function userHasAvailability(
    u: AssignmentUser
  ): [userIsAvailable: boolean, userNeedsLocationAndDistanceTag: boolean] {
    const { edayAvailability } = getVolunteerAttributes(u);

    if (isEday) {
      return [!!edayAvailability, true];
    } else {
      // Slightly generous test. Sees if there’s any availability for any of our dates,
      // even if it might not match with the locations being auto-assigned to.
      const userAvailability = (availabilityByUserId.get(u.id) ?? []).find(
        (a) => !!evDays.find((d) => d.date === a.date)
      );

      return [
        !!userAvailability,
        !!userAvailability?.respect_travel_distance_tag,
      ];
    }
  }

  function userHasDistance(u: AssignmentUser) {
    return typeof u.max_distance_miles === 'number';
  }

  function userHasLocation(u: AssignmentUser) {
    return u.coordinates !== null;
  }

  function userHasTag(u: AssignmentUser) {
    const { isEdayVolunteer, isEvVolunteer } = getVolunteerAttributes(u);
    return (isEday && isEdayVolunteer) || (!isEday && isEvVolunteer);
  }

  // TODO(fiona): Align this with {@link CANT_ASSIGN_REASONS}, though those are
  // location-specific and these are general.
  function userIsAvailable(u: AssignmentUser) {
    const [userIsAvailable, userNeedsLocationAndDistanceTag] =
      userHasAvailability(u);
    return (
      !userHasAssignments(u) &&
      userIsAvailable &&
      ((userHasDistance(u) && userHasLocation(u)) ||
        !userNeedsLocationAndDistanceTag) &&
      userHasTag(u) &&
      (pollObserverRegistrationRequirement === 'none' ||
        userRegisteredToVoteInState(u))
    );
  }

  const availableUsers = users.filter(userIsAvailable);
  const unavaliableUsers = users.filter((u) => !userIsAvailable(u));

  const thClasses =
    'sticky top-0 border-b border-t border-gray-300 bg-white p-2 text-base font-bold';

  const tdClasses = 'p-2 text-base';

  const [userNameFilter, setUserNameFilter] = React.useState('');

  const friendlyTagName = isEday
    ? 'Election Day Poll Observer'
    : 'Early Vote Poll Observer';

  function getIneligibleReasonText(user: AssignmentUser) {
    const assignments = singleDayAssignmentsByUserId.get(user.id);

    if (assignments && assignments.length > 0) {
      return `Assigned ${assignments
        .map(
          (a) =>
            `${formatTime(a.startTime, {
              tight: true,
            })}–${formatTime(a.endTime, {
              tight: true,
            })}`
        )
        .join(', ')}`;
    } else if (!userHasTag(user)) {
      return `Not tagged with “${friendlyTagName}”`;
    } else if (!userHasAvailability(user)[0]) {
      return 'No availability information';
    } else if (!userHasDistance(user)) {
      return 'No distance preference';
    } else if (!userHasLocation(user)) {
      return 'Address not geocoded';
    } else if (
      pollObserverRegistrationRequirement &&
      !userRegisteredToVoteInState(user)
    ) {
      return 'User not registered to vote in state';
    }
  }

  const downloadUserCsv = (eligible = true) => {
    downloadLocalCsv(
      'users.csv',
      [
        ...(userView === 'eligible'
          ? availableUsers
          : unavaliableUsers
        ).values(),
      ].map((user) => ({
        'LBJ ID': user.id,
        'First Name': user.first_name,
        'Last Name': user.last_name,
        Address: user.address,
        City: user.city,
        State: user.state,
        'ZIP Code': user.zipcode,
        'County Name': user.county_name,
        Role: user.role,
        Coordinates: user.coordinates,
        Email: user.email,
        'Phone Number': user.phone_number,
        Tags: user.tags,
        'Max Distance Miles': user.max_distance_miles,
        'Van ID': user.van_id,
        ...(eligible
          ? {}
          : { 'Ineligible Reason': getIneligibleReasonText(user) }),
      }))
    );
  };

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="text-base">
        <strong>Step 4 of 4:</strong> Review volunteers
      </div>

      <div className="text-sm italic leading-relaxed">
        There are <strong>{availableUsers.length} volunteers</strong> who are
        eligible to assign on the selected dates. You can uncheck volunteers to
        exclude them from recommendations.
      </div>

      <div className="flex flex-1 flex-col gap-2">
        <div className="flex items-center gap-2">
          <TextField
            aria-label="Search Volunteers by Name"
            placeholder="Search names…"
            value={userNameFilter}
            onChange={(val) => setUserNameFilter(val)}
            icon="search"
            variant="small"
            inputClassName="w-80"
          />

          <div className="flex-1" />

          <div>
            <span id="volunteer-table-label" className="font-bold">
              Filter:
            </span>{' '}
            <div className="inline-block">
              <TextRadioGroup
                aria-labelledby="volunteer-table-label"
                value={userView}
                onChange={(v) => setUserView(v)}
                options={{
                  eligible: `Eligible (${availableUsers.length})`,
                  ineligible: `Ineligible (${unavaliableUsers.length})`,
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex min-h-0 flex-1 basis-0 flex-col gap-2">
          <div className="h-full overflow-y-scroll border-b border-gray-300">
            <table className="relative w-full border-separate border-l  border-gray-300">
              <thead>
                <tr>
                  {/* Empty header to create column for 'include this user' checkboxes */}
                  {userView === 'eligible' && (
                    <th className={`${thClasses} w-0`} />
                  )}
                  <th className={thClasses}>Name</th>
                  <th className={thClasses}>Role</th>

                  {userView === 'ineligible' && (
                    <>
                      <th className={thClasses}>Reason</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {(userView === 'eligible' ? availableUsers : unavaliableUsers)
                  .filter(
                    (u) =>
                      userNameFilter === '' ||
                      (u.first_name ?? '')
                        .toLowerCase()
                        .startsWith(userNameFilter.toLowerCase()) ||
                      (u.last_name ?? '')
                        .toLowerCase()
                        .startsWith(userNameFilter.toLowerCase())
                  )
                  .map((user, idx) => (
                    <tr
                      key={user.id}
                      className={cx({
                        'bg-white': idx % 2 === 0,
                        'bg-gray-100': idx % 2 === 1,
                      })}
                    >
                      {userView === 'eligible' && (
                        <td className={tdClasses}>
                          <Checkbox
                            aria-label="Include user in auto assignment"
                            isSelected={!userIdsToExclude.has(user.id)}
                            onChange={(checked) => {
                              if (checked) {
                                setUserIdsToExclude((prevSet) => {
                                  prevSet.delete(user.id);
                                  return new Set(prevSet);
                                });
                              } else {
                                setUserIdsToExclude(
                                  (prevSet) => new Set([...prevSet, user.id])
                                );
                              }
                            }}
                          />
                        </td>
                      )}

                      <td className={tdClasses}>
                        {user.first_name} {user.last_name}
                      </td>

                      <td className={tdClasses}>
                        {/* TODO(fiona): Fix this to support EV */}
                        {user.tags
                          .filter(
                            (t): t is keyof typeof EDAY_ROLES => t in EDAY_ROLES
                          )
                          .map((t) => TAG_ROLE_LABELS[t])
                          .join(', ')}
                      </td>

                      {userView === 'ineligible' ? (
                        <>
                          <td className={tdClasses}>
                            {getIneligibleReasonText(user)}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <TextButton
            onPress={() => downloadUserCsv(userView === 'eligible')}
            icon="download"
            size="small"
            className="self-end"
          >
            Download Table as CSV
          </TextButton>
        </div>
      </div>
    </div>
  );
};

/**
 * Contents of {@link AutoAssignmentDialog} when the auto-assignments are
 * calculating.
 */
const AutoAssignmentDialogViewAssigningStep: React.FunctionComponent<{}> =
  () => {
    return (
      <div className="flex flex-1 flex-col items-center justify-center text-base">
        <div className="lbj-loading-icon" />
        <div>
          Generating suggested assignments. This may take a few minutes.
        </div>
      </div>
    );
  };

/**
 * Contents of {@link AutoAssignmentDialog} when there was an error in
 * calculating them.
 */
const AutoAssignmentDialogViewErrorStep: React.FunctionComponent<{}> = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4 text-base">
      <div>
        There was an error generating your assignments. Our engineering team has
        been notified.
      </div>

      <div className="font-bold">
        You can try using Tiers to reduce the number of locations to consider.
      </div>

      <div>
        If this problem persists, please contact{' '}
        <a href="mailto:lbj-help@dnc.org" className="text-primary">
          lbj-help@dnc.org
        </a>
        .
      </div>
    </div>
  );
};

/**
 * Contents of {@link AutoAssignmentDialog} when there are no suggested assignments.
 */
const AutoAssignmentDialogViewNoAssignmentsStep: React.FunctionComponent<{}> =
  () => {
    return (
      <div className="flex flex-1 flex-col items-center justify-center gap-2 text-base">
        <div>No assignments were generated.</div>

        <div>
          It may be that none of the eligible volunteers were available at any
          of the remaining shifts, or they were too far away from them.
        </div>
      </div>
    );
  };
