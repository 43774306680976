import React from 'react';

import { Dialog, ModalOverlay } from '../../components/layout';
import { TextButton } from '../form';

/**
 * Renders a modal dialog with a semi-transparent backdrop.
 *
 * Does not have an open boolean. To hide it, stop rendering it.
 */
export const ModalDialog: React.FunctionComponent<{
  title: string;
  showClose?: boolean | undefined;
  aboveCenter?: boolean | undefined;
  doClose: () => void;
}> = ({ doClose, showClose, aboveCenter, title, children }) => {
  return (
    <ModalOverlay
      aboveCenter={aboveCenter}
      state={{
        isOpen: true,
        close: doClose,
        open: () => {},
        setOpen: (isOpen) => {
          if (!isOpen) {
            doClose();
          }
        },
        toggle: doClose,
      }}
    >
      <Dialog
        title={title}
        // min-h-0 is here so that the flexbox algorithm will let it grow to its
        // contents’ size, but not exceed the parent, which is ModalOverlay’s
        // full-screen-minus-padding flex container.
        className="relative min-h-0 max-w-full rounded bg-white p-4 shadow-md"
      >
        {showClose && (
          <TextButton
            onPress={doClose}
            title="Close Dialog"
            className="absolute top-0 right-2 "
          >
            {/* TODO(fiona): Proper X icon */}
            <span className="text-legacy-2xl leading-none text-black">
              &times;
            </span>
          </TextButton>
        )}
        {children}
      </Dialog>
    </ModalOverlay>
  );
};
