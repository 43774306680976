export function isExcluded(tagName) {
  return tagName[0] === '~';
}

export function convertToExcludedTag(tag) {
  return isExcluded(tag) ? tag : '~' + tag;
}

export function extractExcludedTagName(excludedTag) {
  return isExcluded(excludedTag) ? excludedTag.substring(1) : excludedTag;
}

export function getExcludedTags(tags) {
  // returns excluded tags without the negation symbol at the beginning
  return tags
    ? tags
        .filter((tag) => isExcluded(tag))
        .map((negTag) => extractExcludedTagName(negTag))
        .join(',')
    : '';
}

export function getIncludedTags(tags) {
  return tags ? tags.filter((tag) => !isExcluded(tag)).join(',') : '';
}

export default {
  isExcluded,
  convertToExcludedTag,
  extractExcludedTagName,
  getExcludedTags,
  getIncludedTags,
};
