import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RIT from '../../../utils/render-if-truthy';
import Autocomplete from '../form/autocomplete';
import CountySelect from '../form/county-select';
import DebouncedInput from '../form/debounced-input';
import HiddenInput from '../form/hidden-input';

export default class PollingSearchForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    hiddenInputTitle: PropTypes.node,
    onChange: PropTypes.func,
    counties: PropTypes.object,
    showCounty: PropTypes.bool,
    showTier: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchFields: {},
    };
  }

  onChange({ target }) {
    const { onChange } = this.props;
    const { name, value } = target;
    const { searchFields } = this.state;

    if (!value) {
      delete searchFields[name];
    } else {
      searchFields[name] = value;
    }

    onChange(searchFields);
    this.setState({ searchFields });
  }

  render() {
    const { title, hiddenInputTitle, counties, showTier, showCounty } =
      this.props;
    const { searchFields } = this.state;

    return (
      <div>
        {RIT(showCounty, () => {
          return (
            <CountySelect
              defaultValue={searchFields.county}
              choices={counties}
              onChange={this.onChange.bind(this)}
            />
          );
        })}

        {RIT(showTier, () => {
          return (
            <Autocomplete
              name="tier"
              title="Precinct Tier"
              defaultValue={searchFields.tier}
              onChange={this.onChange.bind(this)}
              multi
              choices={{
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
              }}
            />
          );
        })}

        <HiddenInput ctaText={hiddenInputTitle}>
          <DebouncedInput
            name="name"
            title={title}
            onChange={this.onChange.bind(this)}
          />
        </HiddenInput>
      </div>
    );
  }
}
