export const _EDAY_ROLES = {
  election_day_hotline_worker_volunteer: 'HW',
  election_day_boiler_room_volunteer: 'BR',
  election_day_poll_observer_volunteer: 'PO',
};

export const _EVOTE_ROLES = {
  early_vote_hotline_worker_volunteer: 'HW',
  early_vote_boiler_room_volunteer: 'BR',
  early_vote_poll_observer_volunteer: 'PO',
};
